import React, { FC } from "react";
import { Text } from "@fluentui/react";

import "./Pill.scss";

export interface IPillProps {
    text: string;
    color?: string;
    onClick?: () => void;
    className?: string;
}

export const Pill: FC<IPillProps> = (props) => {
    return (
        <div className={`c-pill ${props.className}`}>
            <Text>{props.text}</Text>
        </div>
    )
}