import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import { RunnerRequestsActions } from "../../redux/RunnerRequestsActions";
import * as RunnerRequestsSelectors from "../../redux/RunnerRequestsSelectors";
import { IconButton, Modal, PrimaryButton, SpinnerSize, TextField } from "@fluentui/react";
import { IRetailStoreDto } from "../../../../contracts/swagger/_generated";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";

import "./EnableDisableRequestsModal.scss";

interface IEnableDisableRequestsModalProvidedProps extends IRapComponentProperties { }

interface IEnableDisableRequestsModalOwnProps { 
    displayEnableDisableRequestsModal: boolean;
    selectedRetailStore: IRetailStoreDto;
}

export type IEnableDisableRequestsModalProps = ConnectedProps<typeof connector> & IEnableDisableRequestsModalProvidedProps;


const EnableDisableRequestsModalInitializer: FC<IEnableDisableRequestsModalProps> = (props) => {
    const [aliasInput, setAliasInput] = React.useState<string>();
    const [modalHeader, setModalHeader] = React.useState<string>();
    const [modalMessage, setModalMessage] = React.useState<string>();
    const [isUpdateInProgress, setIsUpdateInProgress] = React.useState(false);
    const {EnableDisableRequestsModal: locStrings} = localizedStrings.RunnerRequestsView;

    const _onHideModal = () => {
        props.updateModals({ displayEnableDisableRequestsModal: false });
    }

    const _onChangeAliasInput = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAliasInput(newValue);
    }

    const _onAcceptClick = () => {
        setIsUpdateInProgress(true);
        props.updateStoreStatus({
            storeNumber: props.selectedRetailStore.storeNumber,
            updateStoreAlias: aliasInput,
            storeStatus: !props.selectedRetailStore.isAvailable
        }).finally(() => {
            _onHideModal();
            setAliasInput("");
            setIsUpdateInProgress(false);
        });
    }

    const _onModalMount = () => {
        // We set the modal header + message when the modal mounts based on whether requests are being enabled or disabled
        setModalHeader(props.selectedRetailStore.isAvailable ? locStrings.disableRequests : locStrings.enableRequests);
        setModalMessage(props.selectedRetailStore.isAvailable ? locStrings.disableMessage : locStrings.enableMessage);
    }

    return (
        <>
            <Modal
                isOpen={props.displayEnableDisableRequestsModal}
                onDismiss={_onHideModal}
                containerClassName="c-enable-disable-modal"
                layerProps={{
                    onLayerDidMount: _onModalMount
                }}
                scrollableContentClassName={isUpdateInProgress ? "c-scrollable-container" : ""}
            >
                <LoadingContainer isLoading={isUpdateInProgress} spinnerClassName={"c-spinner"} spinnerSize={SpinnerSize.large}>
                    <div className="flex-column">
                        <div className="flex-row c-modal-header">
                            <DialogHeader title={modalHeader}/>
                            <IconButton
                                className="c-icon-cancel"
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel={localizedStrings.RunnerRequestsView.close}
                                onClick={_onHideModal}
                            />
                        </div>
                        <div className="flex-row c-modal-row c-modal-message">
                            {modalMessage}
                        </div>
                        <div className="flex-row">
                            <TextField 
                                label={locStrings.enterYourName}
                                className="flex-grow"
                                value={aliasInput}
                                onChange={_onChangeAliasInput}
                            />
                        </div>
                        <div className="flex-row c-accept-btn">
                            <PrimaryButton 
                                text={locStrings.accept}
                                disabled={!aliasInput || aliasInput.length < 0}
                                onClick={_onAcceptClick}
                            />
                        </div>
                    </div>
                </LoadingContainer>                
            </Modal>
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IEnableDisableRequestsModalProvidedProps
): Partial<IEnableDisableRequestsModalOwnProps> {
    return {
        ...providedProps,
        displayEnableDisableRequestsModal: PageSelectors.getModals(state)?.displayEnableDisableRequestsModal,
        selectedRetailStore: RunnerRequestsSelectors.getSelectedRetailStore(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    updateStoreStatus: RunnerRequestsActions.updateStoreStatus
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const EnableDisableRequestsModal = connector(EnableDisableRequestsModalInitializer);
