import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dialog, DialogType, TextField, DialogFooter, PrimaryButton, SpinnerSize, Dropdown, MaskedTextField, IDropdownOption } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../platform/Layout";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { IAppointmentDetail, ILeadForCreationDto, IStoreAssociateDto } from "../../../../contracts/swagger/_generated";
import { isValidEmail, isValidPhone } from "../../../../platform/core/util/Util";

import { AppointmentDetailsActions } from "../../redux/AppointmentDetailsActions";

import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors"
import * as AppointmentDetailsSelectors from "../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";

import * as Constants from "../../../../common/Constants";

import "./CreateLeadModal.scss";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";

interface ICreateLeadForm extends ILeadForCreationDto {
    selectedCountry: string;
}

//Props passed by parent component
interface ICreateLeadModalProvidedProps extends IRapComponentProperties {

}

interface ICreateLeadModalOwnProps {
    isCreateLeadOpen: boolean;
    selectedAppointment: IAppointmentDetail;
    user: IStoreAssociateDto;
}

export type ICreateLeadModalProps = ConnectedProps<typeof connector> & ICreateLeadModalProvidedProps

const CreateLeadModalInitializer: FC<ICreateLeadModalProps> = (props) =>  {
    const setInitialFormValues = (): Partial<ICreateLeadForm> => {
        let apptType = props.selectedAppointment?.appointmentDetails?.appointmentType?.name?.toLowerCase();
        let campaignName = Constants.LeadCampaigns.Post;
        
        if(apptType?.includes(Constants.LeadKeyWords.Education)) {
            campaignName = Constants.LeadCampaigns.Education;
        }
        else if(apptType?.includes(Constants.LeadKeyWords.Consultation)) {
            campaignName = Constants.LeadCampaigns.Business;
        }

        let companyResponse = props.selectedAppointment?.appointmentDetails?.customResponses?.find(x => x.questionText?.toLowerCase()?.includes(Constants.LeadKeyWords.Company));

        return {
            selectedCountry: Constants.UnitedStatesDisplay,
            ownerEmailOrTeam: apptType?.includes(Constants.LeadKeyWords.Consultation) ? props.user?.email : undefined,
            campaignName: campaignName,
            companyName: companyResponse ? companyResponse.response : undefined,
            topic: `${props.selectedAppointment?.appointmentDetails?.subject}: ${props.selectedAppointment?.appointmentDetails?.customerFirstName} ${props.selectedAppointment?.appointmentDetails?.customerLastName}` 
        }
    }
    
    const [form, setForm] = useState<Partial<ICreateLeadForm>>(setInitialFormValues());
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.selectedAppointment) { 
            let { selectedCountry, phoneNumber } = props.selectedAppointment?.appointmentDetails?.customerPhone ? 
                setInitialPhoneNumber(props.selectedAppointment?.appointmentDetails?.customerPhone) : { selectedCountry: undefined, phoneNumber: undefined };

            setForm({
                ...form,
                firstName: props.selectedAppointment?.appointmentDetails?.customerFirstName,
                lastName: props.selectedAppointment?.appointmentDetails?.customerLastName,
                email: props.selectedAppointment?.appointmentDetails?.customerEmail,
                phoneNumber: phoneNumber ? phoneNumber : undefined,
                selectedCountry: selectedCountry ? selectedCountry : form.selectedCountry
            });
        }
    }, [props.selectedAppointment]);


    const setInitialPhoneNumber = (phone: string): { selectedCountry: string, phoneNumber: string } => {
        let result: { selectedCountry: string, phoneNumber: string } = { selectedCountry: undefined, phoneNumber: phone };

        Constants.PhoneCountryCodes.forEach((value: string, key: string) => {
            if(phone.substring(0, value.length) == value) {
                result = { selectedCountry: key, phoneNumber: phone.substring(value.length)};
            }
        });

        return result;
    }

    const _onDismiss = () => {
        props.updateModals({ displayCreateLeadModal: false });
    }

    const _onSave = () => {
        let formToSubmit = form as ILeadForCreationDto;
        if(formToSubmit.phoneNumber) {
            let countryCode = Constants.PhoneCountryCodes.get(form.selectedCountry);
            formToSubmit.phoneNumber = countryCode + form.phoneNumber;
        }
        setIsLoading(true);
        props.setAppointmentUpdates(); //clear any updates that might be queued up
        props.createLead(formToSubmit).then(res => {
            props.setAppointmentUpdates({ leadId: res.leadNumber }).then(() => {
                props.updateAppointment().then(() => {
                    props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView.modals.createLeadModal.leadCreatedSuccessfully});
                }).catch(() => {
                    props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView.modals.createLeadModal.updateError});
                });
            });
        }).catch(error => {
            props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView.modals.createLeadModal.leadError});
        }).finally(() => {
            setIsLoading(false);
            props.updateModals({ displayCreateLeadModal: false });
        })
    }

    const _getOptions = (): IDropdownOption[] =>  {
        let options: IDropdownOption[] = [];
        Constants.PhoneCountryCodes.forEach((value: string, key: string) => {
            options.push({
                key: value,
                text: key,
                isSelected: (key === form.selectedCountry)
            });
        });
        return options;
    }

    const updateForm = (val: Partial<ICreateLeadForm>) => {
        setForm({...form, ...val});
    }

    const _validateFirstName = (name: string) => {
        let errorMessage = _validateName(name);
        return errorMessage;
    }

    const _validateLastName = (name: string) => {
        let errorMessage = _validateName(name);
        return errorMessage;
    }

    const _validateName = (name: string) => {
        name = name.trim();
        let errorMessage = "";
        if (name === "") {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.nameIsEmptyError;
        } else if (name.length > Constants.NAME_MAX_CHAR) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.nameTooLargeError;
        } else if (name.endsWith(".")) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.nameEndingInPeriodError;
        } else if (!!Constants.InvalidCharsRegex.test(name)) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.nameIllegalCharactersError;
        } else if (!!Constants.NonPrintableCharsRegex.test(name)) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.nameNonPrintableCharactersError;
        }
        return errorMessage;
    };

    const _validateEmail = (value: string) => {
        let errorMessage = "";
        if (isValidEmail(value) !== true) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.emailError;
        } 
        return errorMessage;
    };

    const _validatePhone = (value: string) => {
        let errorMessage = "";
        if (Constants.NonEmptyPhoneRegex.test(value) && !isValidPhone(value)) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.mobileError;
        }
        return errorMessage;
    };

    const _validateTopic = (value: string) => {
        let errorMessage = "";
        let reg = /.+/
        if(!reg.test(value.trim())) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.topicError
        }
        return errorMessage;
    }

    const _validateCompany = (value: string) => {
        let errorMessage = "";
        let reg = /.+/
        if(!reg.test(value.trim())) {
            errorMessage = localizedStrings.AppointmentDetailsView.modals.createLeadModal.companyNameError
        }
        return errorMessage;
    }

    const _onCountryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        updateForm({ selectedCountry: option.text });
    };

    const _onPhoneChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const unformattedPhone = newValue ? newValue.replace(Constants.NonNumbersRegex, "") : undefined;
        updateForm({ phoneNumber: unformattedPhone });
    }

    const _isSaveButtonEnabled = () => {
        const isTopicValid = form.topic && _validateTopic(form.topic) == "";
        const isFirstNameValid = form.firstName && _validateName(form.firstName) == "";
        const isLastNameValid = form.lastName && _validateName(form.lastName) == "";
        const isEmailValid = form.email && isValidEmail(form.email);
        const isCompanyValid = form.companyName && _validateCompany(form.companyName) == "";
        const isPhoneValid = Constants.NonEmptyPhoneRegex.test(form.phoneNumber) ? 
            Constants.PhoneNumberRegexMap.get(Constants.PhoneCountryCodes.get(form.selectedCountry))?.test(form.phoneNumber) : 
            true;

        return isTopicValid && isFirstNameValid && isLastNameValid && isEmailValid && isCompanyValid && isPhoneValid;
    }

    return (
        <Dialog 
            hidden={!props.isCreateLeadOpen}
            onDismiss={_onDismiss}
            minWidth={600}
            dialogContentProps={{
                type: DialogType.close,
                title: <DialogHeader title={localizedStrings.AppointmentDetailsView?.modals?.createLeadModal?.title as string}/>,
                closeButtonAriaLabel: localizedStrings.AppointmentDetailsView?.modals?.createLeadModal?.close as string}}
            className="c-lead"
        >
            <div className="flex-row">
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.topic}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.topic}
                    className="flex"
                    value={form.topic}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ topic: newValue }) 
                    }
                    onGetErrorMessage={_validateTopic}
                    validateOnFocusOut
                    validateOnLoad={false}
                    required
                />
            </div>
            <div className="flex-row">
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.firstName}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.firstName}
                    className="flex c-field left"
                    value={form.firstName}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ firstName: newValue }) 
                    }
                    onGetErrorMessage={_validateFirstName}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                />
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.lastName}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.lastName}
                    className="flex c-field right"
                    value={form.lastName}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ lastName: newValue }) 
                    }
                    onGetErrorMessage={_validateLastName}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                />
            </div>
            <div className="flex-row">
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.companyName}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.companyName}
                    value={form.companyName}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ companyName: newValue }) 
                    }
                    className="flex"
                    onGetErrorMessage={_validateCompany}
                    validateOnFocusOut
                    validateOnLoad={false}
                    required
                />
            </div>
            <div className="flex-row">
                <Dropdown
                    className="flex c-field left"
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.selectCountry}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.selectCountry}
                    options={_getOptions()}
                    onChange={_onCountryChange}
                />
                <MaskedTextField
                    className="flex c-field right"
                    mask={Constants.PhoneNumberMasks.get(form.selectedCountry)}
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.mobile}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.mobile}
                    onGetErrorMessage={_validatePhone}
                    value={form.phoneNumber}
                    onChange={_onPhoneChange}
                    validateOnFocusOut
                    validateOnLoad={false}
                />
            </div>
            <div className="flex-row">
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.email}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.email}
                    className="flex"
                    value={form.email}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ email: newValue }) 
                    }
                    onGetErrorMessage={_validateEmail}
                    validateOnFocusOut
                    validateOnLoad={false}
                    required
                />
            </div>
            <div className="flex-row">
                <TextField 
                    label={localizedStrings.AppointmentDetailsView.modals.createLeadModal.otherDetails}
                    ariaLabel={localizedStrings.AppointmentDetailsView.modals.createLeadModal.otherDetails}
                    value={form.otherDetails}
                    className="flex"
                    multiline
                    rows={4}
                    onChange={
                        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => updateForm({ otherDetails: newValue })
                    }
                />
            </div>
            <DialogFooter>
                <PrimaryButton onClick={_onSave} disabled={isLoading || !_isSaveButtonEnabled()}>
                    <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.small}>
                        <>{localizedStrings.AppointmentDetailsView?.modals?.createLeadModal?.save}</>
                    </LoadingContainer>
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICreateLeadModalProvidedProps): Partial<ICreateLeadModalOwnProps> {
    return {
        ...providedProps,
        isCreateLeadOpen: PageSelectors.getModals(state)?.displayCreateLeadModal,
        selectedAppointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails,
        user: PageSelectors.getUserData(state)?.user
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setAppointmentUpdates: AppointmentDetailsActions.setAppointmentUpdates,
    updateAppointment: AppointmentDetailsActions.updateAppointment,
    updateMessages: PageActions.updateMessages,
    createLead: AppointmentDetailsActions.createLead,
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CreateLeadModal = connector(CreateLeadModalInitializer)