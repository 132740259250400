/// <amd-module name="VSS/Platform/Telemetry" />
import { IRapObservableService, IRapPageContext, ServiceOptions, Services, RapObservableService } from "../Context";
import { CustomerIntelligenceEvent, IClientelingApiClient, ICustomerIntelligenceEvent, TelemetryPostObject } from "../../contracts/swagger/_generated";
import { groupBy } from "../core/util/Array";
import { tryGetLocalStorageObject, trySetLocalStorageObject } from "../core/util/Storage";

/**
 * The telemetry service is used to publish telemetry events back to the hosting
 * service.
 *
 * It also SHOULD surface IObservableEvents for events that are published. This
 * allows other services to observe the events locally.
 *
 * Subscribable events:
 *  action: publish --> ITelemetryEvent
 */
export interface IRapTelemetryService extends IRapObservableService<CustomerIntelligenceEvent, "publish"> {
    /**
     * publishEvent is used to create and send events to the telemetry service endpoint.
     *
     * Events are made up of two primary keys, the area and feature that describe the
     * event that has occurred. Each event generator can then add an object that has
     * any properties it cares to add. On the receiving side of the service the data
     * will be stored and future telemetry queries can parse and utuilize the values
     * sent as name value pairs.
     *
     * @param area - The area defines a large scope of features under a unique name.
     *
     * @param feature - The feature defines a fine grained scope of a single feature.
     *  It should be unique within the area, and the properties should be used to
     *  distinguish how the feature was used.
     *
     * @param properties - An dictionary of name/value pairs with details about the
     *  event. This MAY contain simple or complex values.
     *
     * @param serviceInstanceType The optional id of the service instance to send the telemetry to.
     */
    publishEvent(area: string, feature: string, storeId: string, properties: { [key: string]: any }, serviceInstanceType?: string): void;
}

export interface ClientCustomerIntelligenceEvent extends CustomerIntelligenceEvent {
    serviceInstanceType?: string;
}

export class RapTelemetryService extends RapObservableService<CustomerIntelligenceEvent, "publish"> implements IRapTelemetryService {
    private publishInterval: number;
    private sessionId?: string;
    private hostId?: string;
    private events: ClientCustomerIntelligenceEvent[] = [];
    private unloading: boolean;

    public _serviceStart(pageContext: IRapPageContext) {
        super._serviceStart(pageContext);

        // Make sure we know about the session and host ids for the current page.
        this._initializeService();

        // Setup an interval that is used to push any telemetry events that have been
        // published since we last published them.
        this.publishInterval = window.setInterval(this._sendEvents.bind(this), 1000);

        // Setup listener for the window unload event. Once this happens, we will save any pending events
        // to local storage and send them on the next page load.
        window.addEventListener("unload", this._onWindowUnload);

        // Send previously-saved events from local storage -- best effort
        const previousEvents = this._readEventsFromLocalStorage();
        if (previousEvents) {
            this.events.push(...previousEvents);
        }
    }

    public _serviceRestart(pageContext: IRapPageContext) {
        super._serviceRestart(pageContext);

        // Send any pending events just before we restart with the new page.
        this._sendEvents();

        // We have restarted on a new page we need to get the updated session and host ids.
        this._initializeService();
    }

    public _serviceEnd(pageContext: IRapPageContext) {
        // Make sure our timed publishing of events no longer happens
        if (this.publishInterval) {
            window.clearInterval(this.publishInterval);
            this.publishInterval = 0;
        }

        window.removeEventListener("unload", this._onWindowUnload);

        super._serviceEnd(pageContext);
    }

    public publishEvent(area: string, feature: string, storeId: string, properties: { [key: string]: any }, serviceInstanceType?: string): void {
        // We are going to add general properties to the event so we will make a copy first.
        const eventProperties = { ...properties };

        // Add our SessionId to the event properties for future correlation.
        if (this.sessionId) {
            eventProperties["SessionId"] = this.sessionId;
        }

        // Add the timestamp of when this event occurred -- which may be significantly different
        // from when it gets pushed to the server.
        eventProperties["EventTimestamp"] = new Date().getTime();

        // Telemetry events require both an area and feature
        if (!area) {
            console.warn(`Publishing telemetry event with unknown area. Feature: ${feature}. Properties: ${JSON.stringify(eventProperties)}`);
            area = "Unknown";
        }

        if (!feature) {
            console.warn(`Publishing telemetry event with unknown feature. Area: ${area}. Properties: ${JSON.stringify(eventProperties)}`);
            feature = "Unknown";
        }

        // Create a new telemetry event
        let event: ClientCustomerIntelligenceEvent = new CustomerIntelligenceEvent({ area, feature, storeId, properties: eventProperties });
        event.serviceInstanceType = serviceInstanceType;

        // Notify any subscribers of the event being published. Subscribers can add properties to the event.
        this._notify(event, "publish");

        // Save the event
        if (this.unloading) {
            // The window is unloading. Save the event to local storage so that it can be sent on the next page load
            this._writeEventsToLocalStorage(event);
        } else {
            this.events.push(event);
        }
    }

    private _initializeService(): void { }

    private _sendEvents(): void {
        // Events may contain the service instance to which they should be sent through.
        // We must honor this and create REST clients that connect to the proper service instance
        // so that the event will flow through that service instance.

        // Step 1: Group all of the events by service instance type.
        const eventsByService = groupBy(this.events, event => event.serviceInstanceType);

        // Step 2: Clear the current event list before returning because we have drained the queue
        this.events = [];

        // Step 3: Publish the events.
        Object.keys(eventsByService).forEach(key => {
            try {
                //TODO: add rest client handler here?
                this.pageContext.getRestClient<IClientelingApiClient>("IClientelingApiClient").then((clientelingClient: IClientelingApiClient) => {
                    let requestBody = new TelemetryPostObject({ events: eventsByService[key] });
                    clientelingClient.publishEvents(requestBody);
                }).catch(e => {
                    console.error(e);
                });
            } catch (exception) {
                console.error(exception);
            }
        });
    }

    private _onWindowUnload = (): void => {
        this.unloading = true;

        if (this.events.length) {
            // Save unsent events to local storage so that they may be sent on the next page load
            this._writeEventsToLocalStorage(...this.events);
            this.events = [];
        }
    };

    private _readEventsFromLocalStorage(): CustomerIntelligenceEvent[] | undefined {
        let events: CustomerIntelligenceEvent[] | undefined;

        const itemKey = `ciEvents/${this.hostId}`;
        events = tryGetLocalStorageObject(itemKey);
        if (events) {
            trySetLocalStorageObject(itemKey, undefined);
        }

        return events;
    }

    private _writeEventsToLocalStorage(...events: CustomerIntelligenceEvent[]) {
        let eventsToWrite = events;

        const previousEvents = this._readEventsFromLocalStorage();
        if (previousEvents) {
            eventsToWrite = [...previousEvents, ...eventsToWrite];
        }

        trySetLocalStorageObject(`ciEvents/${this.hostId}`, eventsToWrite);
    }
}

Services.add("IRapTelemetryService", { serviceFactory: RapTelemetryService, options: ServiceOptions.Persistant });
