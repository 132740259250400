import React, { useEffect, FC, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DetailsList, SelectionMode, IColumn, CheckboxVisibility } from "@fluentui/react/lib/DetailsList";
import { ErrorBoundary } from "react-error-boundary";

import { IRapComponentProperties } from "../../../../platform/Layout";
import { AppointmentModel } from "../../../../contracts/swagger/_generated";
import { MRSEmployee } from "../../../../contracts/swagger/_generated";
import { AppointmentUtils } from "../../../../common/AppointmentUtils";
import { ConnectedAppointmentButtons } from "../../../../common/components/AppointmentButtons/AppointmentButtons";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { ReservationsActions } from "../../redux/ReservationsActions";
import { AppointmentStatus } from "../../../../common/components/AppointmentStatus/AppointmentStatus"
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { LoadingSpinner } from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import { NoResults } from "../../../../common/components/NoResults/NoResults";
import { DetailListColumnSizes, DetailListColumnSizes_Max } from "../../../../common/Constants";
import { AssignedTo } from "../AssignedTo/AssignedTo";
import { ErrorBoundaryFallback } from "../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback"

import * as LocaleUtils from "../../../../platform/core/util/Locale";

import "./AppointmentList.scss";

//Props passed by parent component
interface IAppointmentListProvidedProps extends IRapComponentProperties {
    appointments: AppointmentModel[];
    isLoading: boolean;
    noResultsMessage: string; 
}

interface IAppointmentListOwnProps extends IAppointmentListProvidedProps {
}

//Props mapped from state object
interface IAppointmentListState {
    items: IListItemProps[];
    columns: IColumn[];
    announcedMessage?: string;
}

export type IAppointmentListProps = IAppointmentListOwnProps & typeof ActionsToDispatch;

interface IListItemProps {
    key?: string;
    time: string;
    confirmationNumber: string;
    startDate: string;
    type: string;
    category: string;
    customerName: string;
    assignedTo?: MRSEmployee;
    assignedToSortField: string;
    status: string;
    appointment?: AppointmentModel;
}

const AppointmentList: FC<IAppointmentListProps> = (props) => {
    const appointmentUtils = new AppointmentUtils();
    const [items, setItems] = useState<IListItemProps[]>();
    const [columns, setColumns] = useState<IColumn[]>();
    const [announcedMessage, setAnnouncedMessage] = useState<string>();

    const navigate = useNavigate();
 
    useEffect(() => {
        setItems(_generateItems());
        setColumns(_onInitializeColumnHeaders());
    }, [props.appointments]);

    const _onItemInvoked = (item: any): void => {
        if (item && item.appointment) {
            props.onAppointmentSelected(item.appointment.appointmentDetails);
            navigate(`/${LocaleUtils.getLocale()}/reservations/${item.appointment.appointmentDetails.confirmationNumber}`);
        }
    }

    const _onInitializeColumnHeaders = (): IColumn[] => {
        const columns: IColumn[] = [];
        columns.push({
            key: "column1",
            fieldName: "startDate",
            name: localizedStrings.AppointmentList.time,
            minWidth: DetailListColumnSizes.medium,
            maxWidth: DetailListColumnSizes_Max.small,
            onRender: (item: any) => {
                return <div className="c-listitem-time">{item.time}</div>;
            },
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: _onColumnClick,
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column2",
            fieldName: "confirmationNumber",
            name: localizedStrings.AppointmentList.confirmationNumber,
            minWidth: DetailListColumnSizes.small,
            maxWidth: DetailListColumnSizes_Max.small,
            onRender: (item: any) => {
                return <div className="c-listitem-time">{item.confirmationNumber}</div>;
            },
            data: "string",
            onColumnClick: _onColumnClick,
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column3",
            name: localizedStrings.AppointmentList.type,
            fieldName: "type",
            minWidth: DetailListColumnSizes.large,
            maxWidth: DetailListColumnSizes_Max.large,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                return <div className="c-listitem-type">{item.type}</div>;
            },
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column4",
            name: localizedStrings.AppointmentList.category,
            fieldName: "category",
            minWidth: DetailListColumnSizes.small,
            maxWidth: DetailListColumnSizes_Max.large,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                return <div className="c-listitem-category">{item.category}</div>;
            },
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column5",
            name: localizedStrings.AppointmentList.customerName,
            fieldName: "customerName",
            minWidth: DetailListColumnSizes.medium,
            maxWidth: DetailListColumnSizes_Max.medium,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                return <div className="c-listitem-category">{item.customerName}</div>;
            },
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column6",
            name: localizedStrings.AppointmentList.assignedTo,
            fieldName: "assignedToSortField",
            minWidth: DetailListColumnSizes.medium,
            maxWidth: DetailListColumnSizes_Max.medium,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: IListItemProps) => {
                return <AssignedTo appointment={item.appointment}/>
            },
            isResizable: true,
            flexGrow: 1
        });
        columns.push({
            key: "column7",
            name: localizedStrings.AppointmentList.status,
            fieldName: "status",
            minWidth: DetailListColumnSizes.small,
            maxWidth: DetailListColumnSizes_Max.medium,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                return <div className="c-listitem-status">
                    <AppointmentStatus 
                        statusReason={item.appointment?.appointmentDetails?.statusReason}
                        actualStartDate={item.appointment?.appointmentDetails?.actualStartDate}
                        appointmentDate={item.appointment?.appointmentDetails?.appointmentDate}
                        scheduledEndDate={item.appointment?.appointmentDetails?.scheduledEndDate} />
                    </div>;
            },
            isResizable: true,
            flexGrow: 1,
        });
        columns.push({
            key: "column8",
            name: localizedStrings.AppointmentList.actions,
            fieldName: "",
            minWidth: DetailListColumnSizes.medium,
            data: 'string',
            onColumnClick: _onColumnClick,
            onRender: (item: any) => {
                return <ConnectedAppointmentButtons className="c-listitem-status" appointment={item.appointment}/>;
            },
            isResizable: true,
            flexGrow: 1
        });
        return columns;
    }

    const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const newColumns = _updateColumnsState(column);
        const newItems = _copyAndSort(items, column.fieldName!, !column.isSortedDescending);
        setColumns(newColumns);
        setItems(newItems);
    };

    const _updateColumnsState = (column: IColumn) => {
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                setAnnouncedMessage(`${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}`);
            } else {
                newCol.isSorted = false;
            }
        });
        return newColumns;
    }

    const _copyAndSort = (items: IListItemProps[], columnKey: string, isSortedDescending?: boolean): IListItemProps[] => {
        const key = columnKey as keyof IListItemProps;
        const sortedItems = items.slice(0).sort((a, b) => {
            if (a[key] === b[key]) {
                return ((isSortedDescending ? a.startDate < b.startDate : a.startDate > b.startDate) ? 1 : -1);
            } else {
                return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
            }
        });
        return sortedItems;
    }

    const _generateItems = () => {
        const items: IListItemProps[] = [];
        props.appointments?.forEach((appointment: AppointmentModel, index) => {
            items.push({
                key: index.toString(),
                confirmationNumber: appointment.appointmentDetails?.confirmationNumber,
                type: appointmentUtils.getAppointmentType(appointment),
                category: appointmentUtils.getAppointmentCategory(appointment),
                customerName: appointmentUtils.getCustomerName(appointment),
                assignedTo: appointment.assignedTo,
                assignedToSortField: _getAssignedToSortField(appointment),
                time: appointmentUtils.getAppointmentTime(appointment),
                startDate: appointment.appointmentDetails.appointmentDate.toString(),
                status: appointment.appointmentDetails.status,
                appointment: appointment
            })
        });
        return items;
    }

    const _getAssignedToSortField = (item: AppointmentModel): string => {
        let assignedTo = item.assignedTo;
        if (assignedTo && assignedTo.userInfo) {
            return assignedTo.userInfo.fullName;
        }
        return "";
    }

    if (props.isLoading) {
        return <LoadingSpinner />
    }

    if(props.appointments?.length == 0) {
        return (<NoResults description={props.noResultsMessage} />);
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
            {items && items.length > 0 ? (
                <div className="c-details-list">
                    <DetailsList
                        items={items}
                        columns={columns}
                        checkButtonAriaLabel="checkbox"
                        checkboxVisibility={CheckboxVisibility.hidden}
                        checkboxCellClassName="c-listitem-checkbox"
                        selectionMode={SelectionMode.multiple}
                        isHeaderVisible={true}
                        onItemInvoked={_onItemInvoked}
                    />
                </div>
            ) : null}
        </ErrorBoundary>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentListProvidedProps): Partial<IAppointmentListOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    onAppointmentSelected: ReservationsActions.onAppointmentSelected,
};

export const ConnectedAppointmentList = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentList);
