import { IFeatureManagementAwareState, IFeatureManagementViewState } from "../Contracts";

export function isFeatureFlagEnabled(state: IFeatureManagementAwareState, featureFlagName: string, defaultValue?: boolean): boolean {
    if (state && state.featureManagementViewState && state.featureManagementViewState.featureFlags) {
        const flag = state.featureManagementViewState.featureFlags.filter(x => x.name == featureFlagName);
        if (flag.length > 0) {
            return flag[0].value;
        }
    }
    return defaultValue ? defaultValue : false;
}

export function isFeatureFlagLoading(state: IFeatureManagementAwareState): boolean {
    if (state && state.featureManagementViewState.featureFlags) {
        return false;
    } else {
        return true;
    }
}