import React from "react";
import { connect } from "react-redux";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { Dropdown, IDropdownOption } from "@fluentui/react";
import { ReservationsActions } from "../../../../../views/ReservationsView/redux/ReservationsActions";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../../platform/Layout";
import { IAppointmentType } from "../../../../../contracts/swagger/_generated";
import { AppointmentModalActions } from "../../redux/AppointmentModalActions";

import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";
import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors"


//Props passed by parent component
interface IAppointmentTypeOptionPickerProvidedProps extends IRapComponentProperties {
    onSelect: (type: IDropdownOption) => void;
    onBlur?: () => void;
    value?: string;
    disabled?: boolean;
}

//Props mapped from state object
interface IAppointmentTypeOptionPickerOwnProps extends IAppointmentTypeOptionPickerProvidedProps {
    storeId: string;
    appointmentTypes: IAppointmentType[];
}

// eslint-disable-next-line
export type IAppointmentTypeOptionPickerProps = IAppointmentTypeOptionPickerOwnProps & typeof ActionsToDispatch;

export class AppointmentTypeOptionPicker extends RapComponent<IAppointmentTypeOptionPickerProps, {}> {
    constructor(props: IAppointmentTypeOptionPickerProps, context: IRapComponentContext) {
        super(props, context);
        if (!this.props.appointmentTypes) {
            this.props.fetchAppointmentTypes(this.props.storeId);
        }
    }

    public render() {
        return (
            <>
                <Dropdown
                    label={localizedStrings.CreateEditAppointmentModal.type}
                    options={this._getOptions(this.props.appointmentTypes, this.props.value)}
                    onChange={this._onAppointmentTypeChange}
                    required={true}
                    onBlur={this.props.onBlur}
                    selectedKey={this.props.value ? this.props.value : null}
                    disabled={this.props.disabled}
                />
            </>
        );
    }

    private _onAppointmentTypeChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) =>  {
        this.props.onSelect(option);
        this.props.fetchAppointmentCategories(this.props.storeId, option.key.toString());
    };

    private _getOptions(types: IAppointmentType[], appointmentTypeId: string): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        if (types) {
            types.map(type => {
                options.push({
                    key: type.appointmentTypeId,
                    text: type.name,
                    isSelected: appointmentTypeId ? appointmentTypeId === type.appointmentTypeId : false
                });
            });
        }
        return options;
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(
    state: IClientelingViewState,
    providedProps: IAppointmentTypeOptionPickerProvidedProps
): Partial<IAppointmentTypeOptionPickerOwnProps> {
    return {
        ...providedProps,
        storeId: PageSelectors.getViewOptions(state).selectedStore,
        appointmentTypes: AppointmentModalSelectors.getResources(state).appointmentTypes,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    //updateAppointmentType: AppointmentsActions.updateAppointmentType,
    fetchAppointmentTypes: ReservationsActions.fetchAppointmentTypes,
    fetchAppointmentCategories: AppointmentModalActions.fetchAppointmentCategories
};

export const AppointmentTypeOptionPickerInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentTypeOptionPicker);
