import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { ReservationsActions, ReservationsActionsType, ReservationsActionTypes } from "./ReservationsActions";
import { AppointmentsTelemetryLogger } from "../AppointmentsTelemetryLogger";
import { IRapPageContext } from "../../../platform/Context";
import * as PageSelectors from "../../../platform/components/Page/redux/PageSelectors";
import {
    IClientelingApiClient,
    AppointmentsView,
    AppointmentCountDto,
    OptionSetAttribute,
    IAppointmentModel,
    IMRSEmployee,
} from "../../../contracts/swagger/_generated";
import moment from "moment";
import * as LocalConstants from "../../../common/Constants";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { ISelectedAppointmentType, IViewOptions } from "../../../platform/components/Page/Contracts";
import * as CommonSagaFunctions from "../../../common/CommonSagaFunctions";
import { PageActions } from "../../../platform/components/Page/redux/PageActions";
import { FeatureManagementActions } from "../../FeatureManagement/redux/FeatureManagementActions";
import { ContactsActions } from "../../ContactsView/redux/ContactsActions";
import { AppointmentModalActions } from "../../../common/components/CreateEditAppointmentModal/redux/AppointmentModalActions";

const takeEvery: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;

export function* reservationsSaga(): SagaIterator {
    yield takeEvery(ReservationsActionTypes.InitReservationsView, initReservationsView)
    yield takeEvery(ReservationsActionTypes.FetchAppointmentsData, fetchAppointmentsData);
    yield takeEvery(ReservationsActionTypes.LogTelemetry, logTelemetry);
    yield takeEvery(ReservationsActionTypes.FetchAppointmentCountByState, fetchAppointmentCountByState);
    yield takeEvery(ReservationsActionTypes.FetchAppointmentTypes, fetchAppointmentTypes);
    yield takeEvery(ReservationsActionTypes.FetchAppointmentStatusReason, fetchAppointmentStatusReasons);
    yield takeEvery(ReservationsActionTypes.FetchAppointmentStatus, fetchAppointmentStatus);
    yield takeEvery(ReservationsActionTypes.UpdateAppointment, updateAppointment);
    yield takeEvery(ReservationsActionTypes.SearchForAppointments, searchForAppointments);
    yield takeEvery(ReservationsActionTypes.FetchEmployeeInfo, fetchEmployeeInfo);
}

export function* initReservationsView(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.InitReservationsView>): SagaIterator {
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        try {
            yield put(PageActions.fetchPageData());
            yield put(PageActions.fetchFeedbackURL());
            yield put(PageActions.fetchStores());
            yield put(ReservationsActions.fetchAppointmentStatus());
            yield put(ReservationsActions.fetchAppointmentStatusReasons());

            const viewOptions = ((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions;
            if(viewOptions.selectedStore) {
                // Initial pull happens at here but will sometimes happen too quickly so store Id is not set
                // Will pull associates again in case it wasn't set (in AssignedToSearchBox.tsx)
                yield put(ContactsActions.fetchAssociateData(parseInt(viewOptions.selectedStore)));
                yield put(FeatureManagementActions.fetchFeatureFlags(viewOptions.selectedStore));
            }   
        } catch (error) {
            console.error(error);
        }
    }
}

export function* fetchAppointmentsData(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchAppointmentsData>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const viewOptions = ((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions;
            if (viewOptions?.selectedStore) {
                const startDate = moment(viewOptions?.selectedDate).toDate();
                const endDate = moment(viewOptions?.selectedDate).add(1, "day").toDate();
                const typeIds = _getSelectedTypeIdsFilter(viewOptions);

                const appointmentsData: AppointmentsView = ((yield call(
                    [clientelingClient, clientelingClient.getAppointments],
                    startDate,
                    endDate,
                    viewOptions?.selectedStore,
                    typeIds,
                    5000, // no support for paging
                    1,
                    viewOptions?.selectedAssignStatus,
                    undefined,
                    viewOptions?.selectedAppointmentState
                )) as unknown) as AppointmentsView;

                yield put(ReservationsActions.fetchAppointmentsDataSuccess(appointmentsData));
            }
           
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(ReservationsActions.fetchAppointmentsDataFailure(message));
        }
    } else {
        yield put(ReservationsActions.fetchAppointmentsDataFailure("IdToken token is invalid."));
    }
}

export function* logTelemetry(telemetryAction: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.LogTelemetry>): SagaIterator {
    const { feature, action, properties, errorMessage } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        const storeId = (((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions)?.selectedStore;
        var telemetryLogger: AppointmentsTelemetryLogger = new AppointmentsTelemetryLogger(pageContext, feature, action, storeId);

        try {
            if (errorMessage && errorMessage.length > 0) {
                yield call(telemetryLogger.captureError, errorMessage);
            } else {
                yield call(telemetryLogger.captureTelemetry, properties ? properties : {});
            }
        } catch (error) {
            console.log("Unable to log telemetry");
        }
    }
}

export function* fetchAppointmentCountByState(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchAppointmentCountByState>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const viewOptions = ((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions;
            if (viewOptions?.selectedStore) {
                const startDate = moment(viewOptions?.selectedDate).toDate();
                const endDate = moment(viewOptions?.selectedDate).add(1, "day").toDate();
                const typeIds = _getSelectedTypeIdsFilter(viewOptions);

                const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
                const appointmentCounts: AppointmentCountDto[] = ((yield call(
                    [clientelingClient, clientelingClient.getAppointmentCountByStatus],
                    viewOptions?.selectedStore,
                    startDate,
                    endDate,
                    typeIds,
                    viewOptions?.selectedAssignStatus
                )) as unknown) as AppointmentCountDto[];

                yield put(ReservationsActions.fetchAppointmentCountByStateSuccess(appointmentCounts));
            }
        } catch (error) {
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(ReservationsActions.fetchAppointmentCountByStateFailure(message));
        }
    } else {
        yield put(ReservationsActions.fetchAppointmentCountByStateFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentTypes(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchAppointmentTypes>): SagaIterator {
    const storeId = action.payload;
    if (storeId) {
        yield call(CommonSagaFunctions.fetchAppointmentTypes,
            storeId,
            [ReservationsActions.fetchAppointmentTypesSuccess, AppointmentModalActions.fetchAppointmentTypesSuccess],
            ReservationsActions.fetchAppointmentTypesFailure
        )
    }
}

export function* searchForAppointments(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.SearchForAppointments>): SagaIterator {
    const {storeId, searchText} = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const appts: IAppointmentModel[] = ((yield call(
                [clientelingClient, clientelingClient.searchAppointments],
                storeId,
                searchText
            )) as unknown) as IAppointmentModel[];

            yield put(ReservationsActions.searchForAppointmentsSuccess(appts));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(ReservationsActions.searchForAppointmentsFailure(message));
        }
    } else {
        yield put(ReservationsActions.searchForAppointmentsFailure("IdToken token is invalid."));
    }
}

export function _getSelectedTypeIdsFilter(viewOptions: IViewOptions): string {
    const ids: string[] = [];
    viewOptions?.selectedAppointmentTypes?.map((appointmentType: ISelectedAppointmentType) => {
        if (appointmentType.isChecked === true) {
            ids.push(appointmentType.id);
        }
    });
    return ids.join(',');
}

export function* fetchAppointmentStatus(
    action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchAppointmentStatus>
): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: OptionSetAttribute[] = ((yield call([
                clientelingClient,
                clientelingClient.getAppointmentsStatuses
            ])) as unknown) as OptionSetAttribute[];

            yield put(ReservationsActions.fetchAppointmentStatusSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(ReservationsActions.fetchAppointmentStatusFailure(message));
        }
    } else {
        yield put(ReservationsActions.fetchAppointmentStatusFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentStatusReasons(
    action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchAppointmentStatusReason>
): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: OptionSetAttribute[] = ((yield call([
                clientelingClient,
                clientelingClient.getAppointmentsStatusReasons
            ])) as unknown) as OptionSetAttribute[];

            yield put(ReservationsActions.fetchAppointmentStatusReasonSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(ReservationsActions.fetchAppointmentStatusReasonFailure(message));
        }
    } else {
        yield put(ReservationsActions.fetchAppointmentStatusReasonFailure("IdToken token is invalid."));
    }
}

export function* fetchEmployeeInfo(
    action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.FetchEmployeeInfo>
): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const { userObjectIdOrEmail, appointmentId } = action.payload;
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: IMRSEmployee = ((yield call(
                [clientelingClient, clientelingClient.getEmployeeInfo],
                userObjectIdOrEmail
            )) as unknown) as IMRSEmployee;

            action.resolve(response);
            yield put(ReservationsActions.fetchEmployeeInfoSuccess(response, appointmentId));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ReservationsActions.fetchEmployeeInfoFailure(message));
        }
    } else {
        yield put(ReservationsActions.fetchEmployeeInfoFailure("IdToken token is invalid."));
    }
}

export function* updateAppointment(action: ActionsOfType<ReservationsActionsType, ReservationsActionTypes.UpdateAppointment>): SagaIterator {
    const { storeId, appointmentId, appt, silentFetch } = action.payload;
    yield call(CommonSagaFunctions.updateAppointment,
        storeId,
        appointmentId,
        appt,
        silentFetch,
        ReservationsActions.updateAppointmentSuccess,
        ReservationsActions.updateAppointmentFailure
    )

    action.resolve();
    yield put(ReservationsActions.fetchAppointmentsData(silentFetch));
}
