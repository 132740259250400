import React from "react";
import { RapComponent } from "../../../platform/Layout";

import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";

import "./LoadingSpinner.scss";

export class LoadingSpinner extends RapComponent<{}> {
    public render() {
        return (
            <div className="c-loading flex-row flex-grow justify-center">
                <Spinner className="c-loading-spinner" size={SpinnerSize.large} />
            </div>
        );
    }
}
