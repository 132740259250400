import React, { useCallback, useState } from "react";
import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ChoiceGroup, DefaultButton, IChoiceGroupOption, IStackItemStyles, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { CancelReasonMaxLength, RunnerRequestCancelOptions } from "../../../../common/Constants";

import "./CancelRunnerRequest.scss";


interface ICancelRunnerRequestInitializerProvidedProps extends IRapComponentProperties {
    onClickBack: () => void;
    onConfirmCancel: (cancelReason: string) => void;
}

interface ICancelRunnerRequestInitializerOwnProps {
}

export type ICancelRunnerRequestInitializerProps = ConnectedProps<typeof connector> & ICancelRunnerRequestInitializerProvidedProps;

const CancelRunnerRequestInitializer: FC<ICancelRunnerRequestInitializerProps> = props => {
    const [selectedOption, setSelectedOption] = useState<IChoiceGroupOption>();
    const [customCancelOption, setCustomCancelOption] = useState("");
    const { CancelRunnerRequest: locStrings } = localizedStrings.RunnerRequestsView;

    const cancelOptions: IChoiceGroupOption[] = [
        { key: RunnerRequestCancelOptions.NotInStock, text: locStrings.notInStock },
        { key: RunnerRequestCancelOptions.OnHold, text: locStrings.onHold },
        { key: RunnerRequestCancelOptions.Other, text: locStrings.other },
    ];

    const _onChangeCancelOption = useCallback((ev: React.FormEvent<HTMLElement>, option: IChoiceGroupOption) => {
        setSelectedOption(option);
    }, []);

    const _onChangeCustomOption = useCallback((event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue || newValue.length <= CancelReasonMaxLength) {
            setCustomCancelOption(newValue);
        }
    }, []);

    const _onClickSubmit = useCallback(() => {
        const cancelReason = selectedOption.key === RunnerRequestCancelOptions.Other ? customCancelOption : selectedOption.text;
        props.onConfirmCancel(cancelReason);
    }, [props.onConfirmCancel, selectedOption, customCancelOption])

    const _isSumbitButtonDisabled = () => {
        return !selectedOption || (selectedOption.key === RunnerRequestCancelOptions.Other && customCancelOption.length === 0);
    }

    return (
        <Stack className="c-cancel-container" tokens={{ childrenGap: 16}}>
            <ChoiceGroup 
                selectedKey={selectedOption?.key} 
                options={cancelOptions} 
                onChange={_onChangeCancelOption} 
                label={locStrings.selectAReason}
                required
            />
            <TextField
                multiline
                autoAdjustHeight
                resizable={false}
                rows={3}
                value={customCancelOption}
                onChange={_onChangeCustomOption}
                disabled={selectedOption?.key !== RunnerRequestCancelOptions.Other}
            />
            <StackItem grow align="end">
                <Stack horizontal tokens={{ childrenGap: 16}}>
                    <DefaultButton
                        text={locStrings.back}
                        onClick={props.onClickBack}
                    />
                    <PrimaryButton
                        text={locStrings.submit}
                        allowDisabledFocus
                        disabled={_isSumbitButtonDisabled()}
                        onClick={_onClickSubmit}
                    />
                </Stack>
            </StackItem>
        </Stack>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICancelRunnerRequestInitializerProvidedProps): Partial<ICancelRunnerRequestInitializerOwnProps> {
    return {
        ...providedProps
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const CancelRunnerRequest = connector(CancelRunnerRequestInitializer);