import "./Surface.scss";
import * as React from "react";

import { ISurfaceContext, ISurfaceProps, SurfaceBackground, Spacing } from "./Surface.Props";

export const SurfaceContext = React.createContext<ISurfaceContext>({
    background: SurfaceBackground.normal,
    horizontalClassName: undefined,
    spacing: undefined,
    verticalClassName: undefined
});

export const Surface = (props: ISurfaceProps & Readonly<{ children?: React.ReactNode }>) => {
    return (
        <SurfaceContext.Provider
            value={{
                background: props.background,
                horizontalClassName: getHorizontalSpacingClassName(props.spacing),
                spacing: props.spacing
            }}
        >
            {props.children}
        </SurfaceContext.Provider>
    );
};

function getHorizontalSpacingClassName(spacing: Spacing | undefined): string | undefined {
    if (spacing !== undefined) {
        return horizontalSpacingClassNames[spacing];
    }
    return undefined;
}

const horizontalSpacingClassNames: string[] = [
    "bolt-condensed-horizontal-spacing",
    "bolt-default-horizontal-spacing",
    "bolt-relaxed-horizontal-spacing"
];
