import { ActionsUnion, createAction } from "../../../redux/ActionHelper";
import { PageFeature, Themes } from "../../../../common/Constants";
import { IMessages, IContactPages, IModals, IPanels, IViewOptions, UserRole, NavMenuItem } from "../Contracts";
import { IStoreDto, Page, StoreAssociateDto } from "../../../../contracts/swagger/_generated";
import { AuthenticationResult } from "@azure/msal-browser";

/**
 * Actions for Page view
 */
export enum PageActionTypes {
    InitPage = "Page/InitPage",

    FetchFeedbackURL = "Page/FetchFeedbackURL",
    FetchFeedbackURLSuccess = "Page/FetchFeedbackURLSuccess",
    FetchFeedbackURLFailure = "Page/FetchFeedbackURLFailure",

    LogTelemetry = "Page/LogTelemetry",

    FetchPageData = "Page/FetchPageData",
    FetchPageDataSuccess = "Page/FetchPageDataSuccess",
    FetchPageDataFailure = "Page/FetchPageDataFailure",

    OverrideUserRole = "Page/OverrideUserRole",

    FetchStores = "Page/FetchStores",
    FetchStoresSuccess = "Page/FetchStoresSuccess",
    FetchStoresFailure = "Page/FetchStoresFailure",

    UpdateTokenSuccess = "Page/UpdateTokenSucccess",
    UpdateTokenFailure = "Page/UpdateTokenFailure",

    FetchAssociateDetailsSuccess = "Page/FetchAssociateDetailsSuccess",

    SetTheme = "Page/SetTheme",

    ChangeContactPage = "Page/ChangeContactPage",

    UpdatePanels = "Page/UpdatePanels",
    UpdateViewOptions = "Page/UpdateViewOptions",
    UpdateModals = "Page/updateModals",
    UpdateMessages = "Page/updateMessages",
    UpdateNavMenuItems = "Page/UpdateNavMenuItems",

    //Signalr
    ListenToAppointmentUpsertMessage = "Page/ListenToAppointmentUpsertMessage",
    ReceivedAppointmentUpsertMessage = "Page/ReceivedAppointmentUpsertMessage"
}

const PageStandardActions = {
    fetchFeedbackURL: () => createAction(PageActionTypes.FetchFeedbackURL),
    fetchFeedbackURLSuccess: (url: string) => createAction(PageActionTypes.FetchFeedbackURLSuccess, url),
    fetchFeedbackURLFailure: (error: string) => createAction(PageActionTypes.FetchFeedbackURLFailure, error),
    initPage: () => createAction(PageActionTypes.InitPage),
    logTelemetry: (feature: PageFeature, action: string, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(PageActionTypes.LogTelemetry, { feature, action, properties, errorMessage }),
    fetchPageData: () => createAction(PageActionTypes.FetchPageData),
    fetchPageDataSuccess: (pageData: Page) => createAction(PageActionTypes.FetchPageDataSuccess, pageData),
    fetchPageDataFailure: (error: string) => createAction(PageActionTypes.FetchPageDataFailure, error),
    overrideUserRole: (userRole: UserRole) => createAction(PageActionTypes.OverrideUserRole, userRole),
    fetchStores: () => createAction(PageActionTypes.FetchStores),
    fetchStoresSuccess: (storesData: IStoreDto[]) => createAction(PageActionTypes.FetchStoresSuccess, storesData),
    fetchStoresFailure: (error: string) => createAction(PageActionTypes.FetchStoresFailure, error),
    updateTokenSuccess: (message: AuthenticationResult) => createAction(PageActionTypes.UpdateTokenSuccess, message),
    updateTokenFailure: (message: AuthenticationResult) => createAction(PageActionTypes.UpdateTokenFailure, message),
    fetchAssociateDetailsSuccess: (associateDetails: StoreAssociateDto) => createAction(PageActionTypes.FetchAssociateDetailsSuccess, associateDetails),
    setTheme: (theme: Themes) => createAction(PageActionTypes.SetTheme, theme),
    changeContactPage: (contactPages: IContactPages) => createAction(PageActionTypes.ChangeContactPage, contactPages),
    updatePanels: (panels: IPanels) => createAction(PageActionTypes.UpdatePanels, panels),
    updateViewOptions: (options: IViewOptions) => createAction(PageActionTypes.UpdateViewOptions, options),
    updateModals: (modals: IModals) => createAction(PageActionTypes.UpdateModals, modals),
    updateMessages: (messages: IMessages) => createAction(PageActionTypes.UpdateMessages, messages),
    updateNavMenuItems: (navMenuItems: NavMenuItem[]) => createAction(PageActionTypes.UpdateNavMenuItems, navMenuItems),
    
    listenToAppointmentUpsertMessage: () => createAction(PageActionTypes.ListenToAppointmentUpsertMessage)
};

const PageThunkActions = {

}

const PageThunkActionsTypes = {

}

export const PageActions = {...PageStandardActions, ...PageThunkActions};
const AllActionsTypes = {...PageStandardActions, ...PageThunkActionsTypes};
export type PageActionsType = ActionsUnion<typeof AllActionsTypes>;
