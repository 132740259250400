import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { Text, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import InfiniteScroll from "react-infinite-scroll-component"; 

import { IAppointmentDto, AppointmentDetail, ISurveyResponseDto } from "../../../../../../../../contracts/swagger/_generated";
import { NoResults } from "../../../../../../../../common/components/NoResults/NoResults";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { AppointmentUtils, getCSATColor } from "../../../../../../../../common/AppointmentUtils";
import { TextSizes } from "../../../../../../../../common/Constants";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { AppointmentDetailsActions } from "../../../../../../../AppointmentDetailsView/redux/AppointmentDetailsActions";
import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IViewOptions } from "../../../../../../../../platform/components/Page/Contracts";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";
import * as AppointmentDetailsSelectors from "../../../../../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";
import * as PageSelectors from "../../../../../../../../platform/components/Page/redux/PageSelectors";
import * as  FeatureManagementSelectors from "../../../../../../../FeatureManagement/redux/FeatureManagementSelectors";

import "./ContactAppointmentList.scss";


//Props passed by parent component
interface IContactAppointmentListProvidedProps extends IRapComponentProperties {
}

interface IContactAppointmentListOwnProps {
    appointmentList: IAppointmentDto[];
    selectedAppointmentDetails: AppointmentDetail;
    selectedAppointmentId: string;
    viewOptions: IViewOptions;
    enableCSAT: boolean;
    surveys?: ISurveyResponseDto[];
}

export type IContactAppointmentListProps = ConnectedProps<typeof connector> & IContactAppointmentListProvidedProps;

const ContactAppointmentListInitializer:FC<IContactAppointmentListProps> = (props) => {
    let { contactId } = useParams<any>();
    const [areAppointmentsLoading, setAreAppointmentsLoading] = useState(false);
    const [areSurveysLoading, setAreSurveysLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    //Current selected appointment could be blank (as it is a contact redux specific value)
    useEffect(() => {
        //Set current selected appointment by looking at current selected
        if (!props.selectedAppointmentId && props.selectedAppointmentDetails) {
            props.setSelectedAppointmentId(props.selectedAppointmentDetails.appointmentDetails.appointmentId)
        }
        //Set first item in appointmentList to selected appointment and fetch details
        else if (!props.selectedAppointmentId && props.appointmentList) {
            setAppointmentId_fetchAppointmentDetails(props.appointmentList[0].appointmentId);
        }
    }, []);

    useEffect(() => {
        if(contactId && !props.appointmentList) {
            setAreAppointmentsLoading(true);
            props.fetchContactAppointmentList(contactId).finally(() => {
                setAreAppointmentsLoading(false);
            }); 
        }
    }, [contactId]);

    useEffect(() => {
        if(contactId && !props.surveys) {
            setAreSurveysLoading(true);
            props.fetchContactSurveys(contactId).finally(() => {
                setAreSurveysLoading(false);
            });
        }
    }, [contactId]);

    const appointmentUtils = useRef(new AppointmentUtils());

    const getTimeFrame = (appt: IAppointmentDto) => {
        let startTime = moment(appt?.appointmentDate);
        let endTime = moment(appt?.scheduledEndDate);

        return `${startTime.tz(moment.tz.guess()).format("MMM Do, YYYY h:mm A")} - ${endTime.tz(moment.tz.guess()).format("h:mm A z")}`
    }

    const setAppointmentId_fetchAppointmentDetails = (appointmentId: string) => {
        props.setSelectedAppointmentId(appointmentId)
        props.fetchAppointmentDetails(props.viewOptions.selectedStore, appointmentId);
    }

    const getCSATScore = (appt: IAppointmentDto) => {
        if(props.surveys && props.surveys.length > 0) {
            let score = props.surveys?.find(s => s.appointmentId == appt.appointmentId)?.csat;
            if(score != undefined) {
                return <Text className="flex-row flex-end bold" variant={TextSizes.smallPlus} style={{"color": getCSATColor(score)}}>
                            {score}
                        </Text>
            }
        }
        return <></>;
    }

    const onLoadNextAppointmentPage = () => {
        props.fetchContactAppointmentList(contactId, currentPage+1, true).then((appts) => {
            setCurrentPage(currentPage + 1);
            if(appts.length == 0) {
                setHasMore(false);
            }
        }).catch(() => {
            setHasMore(false);
        });
    }

    return (
        <LoadingContainer 
            isLoading={areAppointmentsLoading} 
            spinnerClassName="flex-center c-spinner-padding" 
            spinnerSize={SpinnerSize.large}
        >
            <div className="c-appointmentList">
                <div className="c-header">
                    <Text className="c-header-text">{`${localizedStrings.ContactsView.contactAppointments.appointments} (${props.appointmentList?.length})`}</Text>
                </div>
                {props.contactAppointments?.length === 0 && (
                    <div className="flex-column flex-grow">
                        <NoResults description={localizedStrings.ContactsView.contactAppointments.notFound} />
                    </div>
                )}
                <InfiniteScroll 
                    className="c-appointments"
                    dataLength={props.appointmentList ? props.appointmentList?.length : 0}
                    hasMore={hasMore}
                    loader={<Spinner size={SpinnerSize.medium}/>}
                    next={onLoadNextAppointmentPage}
                    scrollableTarget="scrollableDiv"
                >
                    {props.appointmentList?.map((appt: IAppointmentDto) => 
                        <div 
                            className={"flex-row c-appointment-container" + (props.selectedAppointmentId === appt?.appointmentId ? " c-selected-appointment" : "")}
                            onClick={() => setAppointmentId_fetchAppointmentDetails(appt?.appointmentId)}
                        >
                            <div className="c-icon-container">
                                <div className="c-icon">
                                    <Icon 
                                        iconName={appointmentUtils.current.getAppointmentIconName(appt?.appointmentType)} 
                                    />
                                </div>
                            </div>
                            <div className="c-appointment-information flex-column flex flex-2">
                                <Text className="flex c-appointment-title" variant={TextSizes.medium} nowrap>
                                    {appt?.appointmentType + ":"}
                                </Text>

                                <Text className="flex c-appointment-title" variant={TextSizes.medium} nowrap>
                                    {appt?.appointmentCategory}
                                </Text>

                                <Text className="flex" variant={TextSizes.smallPlus}>
                                    {getTimeFrame(appt)}
                                </Text>

                                <Text className="flex" variant={TextSizes.smallPlus}>
                                    {appt?.confirmationNumber}
                                </Text>
                            </div>
                            <div className="flex-column flex flex-1">
                                <Text className="flex-row flex-end" variant={TextSizes.smallPlus}>
                                    {appt?.statusReason}
                                </Text>

                                {props.enableCSAT ? (
                                    <>
                                        {getCSATScore(appt)}
                                    </>
                                ) : null }
                            </div>
                        </div>
                    )}
                </InfiniteScroll>
            </div>
        </LoadingContainer>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactAppointmentListProvidedProps): Partial<IContactAppointmentListOwnProps> {
    return {
        ...providedProps,
        appointmentList: ContactsSelectors.getSelectedContactInfo(state)?.contactAppointments,
        selectedAppointmentDetails: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state).selectedAppointmentDetails,
        selectedAppointmentId: ContactsSelectors.getSelectedAppointmentId(state),
        viewOptions: PageSelectors.getViewOptions(state),
        enableCSAT: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCSAT", false),
        surveys: ContactsSelectors.getCustomerInsightsData(state)?.surveys,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactAppointmentList: ContactsActions.fetchContactAppointments,
    setSelectedAppointmentId: ContactsActions.setSelectedAppointmentId,
    fetchAppointmentDetails: AppointmentDetailsActions.fetchAppointmentDetail,
    fetchContactSurveys: ContactsActions.fetchContactSurveys
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const ContactAppointmentList = connector(ContactAppointmentListInitializer);
