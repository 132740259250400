import { Icon, PersonaSize } from "@fluentui/react";
import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import { IAppointmentModel } from "../../../../contracts/swagger/_generated";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { ReservationsActions } from "../../redux/ReservationsActions";
import { UserPersona } from "../../../../common/components/UserPersona/UserPersona";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { EventsApiProdEmail } from "../../../../common/Constants";

import * as StringUtil from "../../../../platform/core/util/String";

import "./AssignedTo.scss";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IAssignedToProvidedProps extends IRapComponentProperties {
    appointment: IAppointmentModel;
}

//Props mapped from state object
interface IAssignedToOwnProps {
    
}

// eslint-disable-next-line
export type IAssignedToProps = ConnectedProps<typeof connector> & IAssignedToProvidedProps;

export const AssignedToInitializer: FC<IAssignedToProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
        return () => { isMounted.current = false }
    }, []);

    useEffect(() => {
        if(!props.appointment?.assignedTo && props.appointment?.appointmentDetails?.assignedTo && props.appointment.appointmentDetails.assignedTo != EventsApiProdEmail) {
            setIsLoading(true);
            props.fetchEmployeeInfo(props.appointment.appointmentDetails.assignedTo, props.appointment.appointmentDetails.appointmentId)
            .catch(error => {
                if(isMounted.current == true) {
                    console.error(error);
                    setFetchError(true);
                }
            })
            .finally(() => {
                if(isMounted.current == true) {
                    setIsLoading(false);
                }
            });
        }
    }, [props.appointment])

    let assignedTo = props.appointment?.assignedTo;
    return (
        <LoadingContainer isLoading={isLoading}>
            <>
                {assignedTo && assignedTo.userInfo ? (
                    <div className="flex-row">
                        <UserPersona
                            imageUrl={assignedTo.profileImage as string}
                            imageInitials={assignedTo.userInfo.initials as string}
                            fullName={assignedTo.userInfo.fullName as string}
                            renderDetails={false}
                            personaSize={PersonaSize.size24}
                        />
                        <div className="c-assignedto">{StringUtil.format("{0}", assignedTo.userInfo.fullName)}</div>
                    </div>
                ) : (
                    <div className="flex-row">
                        <div className="flex-column c-icon-col">
                            <Icon className="c-warning-icon" iconName="Warning" />
                        </div>
                        <div className="flex-column c-unassigned-text">{fetchError ? 
                            localizedStrings.AppointmentCardItem?.assignedToError : 
                            localizedStrings.AppointmentCardItem?.unassigned }
                        </div>
                    </div>
                )}
            </>
        </LoadingContainer>
    )
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAssignedToProvidedProps): Partial<IAssignedToOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchEmployeeInfo: ReservationsActions.fetchEmployeeInfo
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const AssignedTo = connector(AssignedToInitializer);


