import React, { FC } from "react";
import { connect } from "react-redux";

import { IClientelingViewState } from "../../../pages/Contracts";
import { IRapComponentProperties } from "../../Layout";
import { UserSettingsPanel } from "./components/UserSettingsPanel/UserSettingsPanel";
import { FilterPanel } from "./components/FilterPanel/FilterPanel";
import { AboutPanel } from "./components/AboutPanel/AboutPanel";

interface IPanelHandlerProvidedProps extends IRapComponentProperties {
 
}

interface IPanelHandlerOwnProps extends IPanelHandlerProvidedProps {
    
}

export type IPanelHandlerProps = IPanelHandlerOwnProps & typeof ActionsToDispatch;

export const PanelHandlerInitializer: FC<IPanelHandlerProps> = (props) => {


    return (
        <>
            <UserSettingsPanel/>
            <FilterPanel/>
            <AboutPanel/>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IPanelHandlerProvidedProps): Partial<IPanelHandlerOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export const PanelHandler = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(PanelHandlerInitializer);

