import { IRapPageContext, IRapService, Services, RapService } from "../Context";
import { Page, UserInfo, UserProfileImage, IClientelingApiClient, IPageDataError } from "../../contracts/swagger/_generated";
import * as LocalConstants from "../../common/Constants";
import Resources from "../Resources.json";

/**
 * This is the basic data that describes the state of the page being executed.
 *  It has basic information about the service and the entities accessed.
 */
export interface IPageData {
    /**
     * Client version number.
     */
    versionNumber: string;

    /**
     * Does the user have admin permissions.
     */
    isAdmin: boolean;

    /**
     * Does the user have runner request permissions.
     */
    isRunnerRequestRoleMember: boolean;

    /**
     * Defines whether or not the page is being created in debug mode.
     */
    isDebugMode?: boolean;

    /**
     * Defines whether or not the page is coming from a devfabric environment.
     */
    isDevfabric?: boolean;

    /**
     * Defines whether or not the page is coming from a Production of Dev environment.
     */
    isProduction?: boolean;

    /**
     * Information for current logged in user
     */
    userInfo?: UserInfo;

    /**
     * Profile image of logged in user
     */
    userProfileImage?: UserProfileImage;

    /**
     *  Store that user is assigned to (in the format of ####)
     */
    userDefaultStore?: string;

    /**
     *  Retail Events Url
     */
    retailEventsUrl?: string;

    /**
     *  Exception details for page data
     */
    pageDataError?: IPageDataError;
}

export interface IRapPageService extends IRapService {
    getData(): Promise<IPageData>;
}

export class RapPageService extends RapService implements IRapPageService {
    private _pageData: IPageData;
    private _isLoaded: boolean;
    private _loadFailed: boolean;

    public _serviceStart(pageContext: IRapPageContext) {
        super._serviceStart(pageContext);

        this._pageData = { 
            versionNumber: "", 
            isAdmin: false, 
            isRunnerRequestRoleMember: false, 
            userInfo: undefined, 
            userProfileImage: undefined, 
            pageDataError: undefined 
        };

        this._isLoaded = false;
        this._loadFailed = false;

        this._initializeService();
    }

    private _initializeService(): void {
        this._fetchPageData();
    }

    private async _fetchPageData() {
        try {
            const clientelingClient: IClientelingApiClient = await this.pageContext.getRestClient<IClientelingApiClient>("IClientelingApiClient");
            await clientelingClient
                .getPageData()
                .then((value: Page) => {
                    this._pageData.versionNumber = value.versionNumber;
                    this._pageData.isAdmin = value.isAdmin;
                    this._pageData.isRunnerRequestRoleMember = value.isRunnerRequestRoleMember;
                    this._pageData.userInfo = value.userInfo;
                    this._pageData.userProfileImage = value.userProfileImage;
                    this._pageData.userDefaultStore = value.userDefaultStore;
                    this._pageData.retailEventsUrl = value.retailEventsUrl;
                    this._pageData.pageDataError = value.pageDataError;
                })
                .finally(() => {
                    this._isLoaded = true;
                    this._loadFailed = false;
                });
        } catch (exception) {
            this._isLoaded = true;
            this._loadFailed = true;
            this._pageData.pageDataError = exception;
            if (this._pageData.pageDataError.status === LocalConstants.HttpErrorCodes.UnAuthorized) {
                this._pageData.pageDataError.message = Resources.UnAuthorizedError;
            }
        }
    }

    public async getData(): Promise<IPageData> {
        if (this._loadFailed) {
            this._isLoaded = false;
            this._loadFailed = false;
            await this._fetchPageData();
        }

        //Wait for data to be loaded
        while (!this._isLoaded) {
            await new Promise((resolve, reject) => setTimeout(resolve, 50));
        }

        return this._pageData;
    }
}

Services.add("IRapPageService", { serviceFactory: RapPageService });
