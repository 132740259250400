import { IRunnerRequestDto } from "./../contracts/swagger/_generated";
import { RunnerRequestState, RunnerRequestStatus } from "./Constants";

// Map runner request status value to corresponding state used by the state filter
export const getRunnerRequestState = (runnerRequestStatus: RunnerRequestStatus): RunnerRequestState | undefined => {
    switch(runnerRequestStatus) {
        case RunnerRequestStatus.Active:
            return RunnerRequestState.ActionNeeded;
        case RunnerRequestStatus.InProgress:
            return RunnerRequestState.InProgress;
        case RunnerRequestStatus.Canceled:
            return RunnerRequestState.Canceled
        case RunnerRequestStatus.Complete:
            return RunnerRequestState.Complete;
        default:
            return undefined;
    }
}

// Used to check if the substring is found in the runner request SKU description(s) or requester name
export const findSubstringInRunnerRequest = (runnerRequest: IRunnerRequestDto, substr: string): boolean => {
    console.log("searching");
    let lowerCaseSubstr = substr.toLowerCase();
    if (runnerRequest.fohAlias.toLowerCase().includes(lowerCaseSubstr)) { return true; }

    for (const skuItem of runnerRequest.skuItems) {
        if (skuItem.skuDescription.toLowerCase().includes(lowerCaseSubstr)) { return true; }
    }

    return false;
}