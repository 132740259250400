import React from "react";
import { connect } from "react-redux";
import { Checkbox, Icon, Label, Link, Text } from "@fluentui/react";
import moment from "moment-timezone";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { Card } from "../../../../../../common/components/Card/Card";
import { AppointmentState, TextSizes } from "../../../../../../common/Constants";
import { UserPersona } from "../../../../../../common/components/UserPersona/UserPersona";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { AppointmentCustomResponseDto, AppointmentDetail } from "../../../../../../contracts/swagger/_generated";
import { AppointmentStatus } from "../../../../../../common/components/AppointmentStatus/AppointmentStatus";
import { PageActions } from "../../../../../../platform/components/Page/redux/PageActions";
import { PermissionGate } from "../../../../../../common/components/PermissionGate/PermissionGate";
import { UserRole } from "../../../../../../platform/components/Page/Contracts";
import * as FeatureManagementSelectors from "../../../../../../views/FeatureManagement/redux/FeatureManagementSelectors";
import * as AppointmentDetailsSelectors from "../../../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";

import "./AppointmentDetails.scss";

//Props passed by parent component
interface IAppointmentDetailsProvidedProps extends IRapComponentProperties {
}

interface IAppointmentDetailsOwnProps extends IAppointmentDetailsProvidedProps {
    selectedAppointment: AppointmentDetail;
    enableBsl: boolean;
}

interface IAppointmentDetailsState {
}

export type IAppointmentDetailsProps = IAppointmentDetailsOwnProps & typeof ActionsToDispatch;

class AppointmentDetailsInitializer extends RapComponent<IAppointmentDetailsProps, IAppointmentDetailsState> {
    constructor(props: IAppointmentDetailsProps, context: IRapComponentContext) {
        super(props, context);
    }

    private getCustomQuestions = () => {
        let appt = this.props.selectedAppointment.appointmentDetails;

        if(appt.customResponses) {
            return appt.customResponses.map((resp: AppointmentCustomResponseDto, index: number) => {
                return (
                    <div className="flex-row c-row" key={resp.customResponseId}>
                        <div className="flex-grow">
                            <Label className="c-header">{`Q${index + 1}: ${resp.questionText}`}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{resp.response}</Text>
                        </div>
                    </div>
                )
            });
        }
    }

    private isAppointmentEditable = () => {
        let status = this.props.selectedAppointment?.appointmentDetails?.status?.value;
        return status != AppointmentState.Canceled && status != AppointmentState.Closed;
    }

    public render() {
        if(!this.props.selectedAppointment) {
            return <></>
        }

        let appt = this.props.selectedAppointment.appointmentDetails;
        let assignedTo = this.props.selectedAppointment.assignedTo;
        return (
            <Card>
                <div className="c-appointment-details">
                    <div className="flex-row">
                        <div className="flex-5">
                            <Text variant={TextSizes.xLarge} className="c-header">
                                {`${appt.subject}: ${appt.customerFirstName} ${appt.customerLastName}`}
                            </Text>
                            <Text variant={TextSizes.medium} className="grey-text">
                                {`${moment(appt.appointmentDate).tz(moment.tz.guess()).format("MMMM Do YYYY, h:mm:ss a")} - 
                                ${moment(appt.scheduledEndDate).tz(moment.tz.guess()).format("h:mm:ss a z")}`}
                            </Text>
                            <Text variant={TextSizes.medium} className="grey-text">
                                {appt.confirmationNumber}
                            </Text>
                        </div>
                        <div className="flex">
                            <AppointmentStatus
                                className="c-status-item grey-text"
                                statusReason={appt.statusReason.label}
                                actualStartDate={appt.actualStartDate}
                                appointmentDate={appt.appointmentDate}
                                scheduledEndDate={appt.scheduledEndDate}
                            />
                        </div>
                        <div className="c-edit">
                            {this.isAppointmentEditable() && (
                                <PermissionGate minimumUserRole={UserRole.Associate}>
                                    <Link className="c-status-item" onClick={() => this.props.updateModals({ displayEditAppointmentModal: true })}>
                                        <Icon iconName="Edit" ariaLabel={localizedStrings.AppointmentDetailsView.AppointmentDetails.editLabel}/>
                                    </Link>
                                </PermissionGate>      
                            )}
                        </div>
                    </div>
                    <div className="flex-row c-row">
                        <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.type}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{appt.appointmentType.name}</Text>
                        </div>
                        <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.topic}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{appt.appointmentCategory.name}</Text>
                        </div>
                        <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.sms}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">
                                {appt.customerOptIntoSms == undefined ?
                                    localizedStrings.AppointmentDetailsView.AppointmentDetails.notSet : 
                                    appt.customerOptIntoSms ? 
                                        localizedStrings.AppointmentDetailsView.AppointmentDetails.optIn : 
                                        localizedStrings.AppointmentDetailsView.AppointmentDetails.optOut}
                            </Text>
                        </div>
                        <div className="c-flex-fill"></div>
                    </div>
                    <div className="flex-row c-row">
                        <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.description}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{appt.descriptionOfIssue}</Text>
                        </div>
                    </div>
                    <div className="flex-row c-row">
                        <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.assignedTo}</Label>
                            <div className="c-persona">
                                {assignedTo && assignedTo.userInfo ? (
                                    <UserPersona 
                                        imageUrl={assignedTo.profileImage}
                                        imageInitials={assignedTo.userInfo.initials}
                                        fullName={assignedTo.userInfo.fullName}
                                        renderDetails={true}
                                    />
                                ) : (
                                    <div className="flex-row">
                                        <div className="flex-column c-icon-col">
                                            <Icon className="c-warning-icon" iconName="Warning" />
                                        </div>
                                        <div className="flex-column c-unassigned-text grey-text">{ localizedStrings.AppointmentCardItem.unassigned }</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {appt.poboOrderId && (
                        <div className="flex-row c-row">
                            <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.poboOrderId}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{appt.poboOrderId}</Text>
                            </div>
                        </div>
                    )}
                    {this.getCustomQuestions()}
                    {appt.leadId && (
                        <div className="flex-row c-row">
                            <div className="flex-grow">
                            <Label className="c-header">{localizedStrings.AppointmentDetailsView.AppointmentDetails.leadId}</Label>
                            <Text variant={TextSizes.medium} className="grey-text">{appt.leadId}</Text>
                            </div>
                        </div>
                    )}
                    {this.props.enableBsl && (
                        <div className="flex-row c-row">
                            <div className="flex-grow">
                                <Label htmlFor="support-box" className="c-header">
                                    {localizedStrings.AppointmentDetailsView.AppointmentDetails.accommodations }
                                </Label>
                                <Checkbox
                                    name="bsl-box"
                                    className="c-form-item grey-text"
                                    label={localizedStrings.AppointmentDetailsView.AppointmentDetails.bslInterpreterRequested}
                                    checked={appt.bslInterpreterRequested}
                                    disabled
                                />
                                <Checkbox 
                                    name="support-box" 
                                    className="c-form-item grey-text" 
                                    label={localizedStrings.AppointmentDetailsView.AppointmentDetails.support} 
                                    checked={appt.accessibilityRequested} 
                                    disabled
                                />
                            </div>
                        </div>
                    )}
                    {appt.accessibilityDescription && (
                        <div className="flex-row c-row">
                            <div className="flex-grow">
                                <Text variant={TextSizes.medium} className="grey-text">{appt.accessibilityDescription}</Text>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentDetailsProvidedProps): Partial<IAppointmentDetailsOwnProps> {
    return {
        ...providedProps,
        selectedAppointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails,
        enableBsl: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableBsl", false),
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentDetailsInitializer);
