import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";

import { localizedStrings } from "./localization/LocalizedStrings";
import { IClientelingApiClient, IAppointmentForUpdateDto, IAppointmentType, ITimeSlotDto, IAppointmentTimeslots, AppointmentType, ICase } from "../contracts/swagger/_generated";
import { IRapPageContext } from "../platform/Context";

import * as LocalConstants from "./Constants";
import { stringify } from "querystring";

const all: any = Effects.all;
const takeEvery: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;
const apply: any = Effects.apply;
const take: any = Effects.take;

export function* updateAppointment(storeNumber: string, appointmentId: string, appt: IAppointmentForUpdateDto, 
    successCallback: () => any, failureCallback: (error: string) => any, resolve?: () => any, reject?: (error: string) => any)
: SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: void = ((yield call(
                [clientelingClient, clientelingClient.updateAppointment],
                storeNumber, 
                appointmentId,
                appt
            )) as unknown) as void;

            if(resolve) {
                resolve();
            }

            yield put(successCallback());
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            if(reject) {
                reject(message);
            }
            yield put(failureCallback(message));
        }
    } else {
        yield put(failureCallback("IdToken token is invalid."));
    }
}

export function* fetchAppointmentTypes(storeId: string,
    successCallback: ((types: IAppointmentType[]) => any) | ([(types: IAppointmentType[]) => any]), failureCallback: (error: string) => any, resolve?: (types: IAppointmentType[]) => any, reject?: (error: string) => any)
: SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: IAppointmentType[] = ((yield call(
                [clientelingClient, clientelingClient.getAppointmentTypes],
                storeId
            )) as unknown) as IAppointmentType[];

            if(resolve) {
                resolve(response);
            }
            if(Array.isArray(successCallback)) {
                yield all(successCallback.map((c: ((t: IAppointmentType[]) => any)) => {
                    return put(c(response));
                }));
            }
            else {
                yield put(successCallback(response));
            }
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            if(reject) {
                reject(message);
            }
            yield put(failureCallback(message));
        }
    } else {
        yield put(failureCallback("IdToken token is invalid."));
    }
}