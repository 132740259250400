import React, {FC} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Checkbox, SpinnerSize } from "@fluentui/react";
import moment from "moment-timezone";

import { ICustomerInsightsDto, AppointmentDetail } from "../../../../../../../../contracts/swagger/_generated";

import { TextSizes } from "../../../../../../../../common/Constants";
import { LoadingSpinner } from "../../../../../../../../common/components/LoadingSpinner/LoadingSpinner";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";

import { IClientelingViewState } from "../../../../../../../../pages/Contracts";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";

import * as AppointmentDetailsSelectors from "../../../../../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";

import "./ContactAppointmentDetails.scss";
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";


interface IContactAppointmentDetailsProvidedProps extends IRapComponentProperties {
}

interface IContactAppointmentDetailsOwnProps extends IContactAppointmentDetailsProvidedProps {
    selectedAppointmentDetails: AppointmentDetail;
    appointmentDetailsIsLoading: boolean;
    selectedContactInsights: ICustomerInsightsDto;
}

export type IContactAppointmentDetailsProps = ConnectedProps<typeof connector> & IContactAppointmentDetailsOwnProps;

const ContactAppointmentDetailsInitializer: FC<IContactAppointmentDetailsProps> = props => {
    const getTimeFrame = () => {
        let startTime = moment(props.selectedAppointmentDetails.appointmentDetails.appointmentDate);
        let endTime = moment(props.selectedAppointmentDetails.appointmentDetails.scheduledEndDate);

        return `${startTime.tz(moment.tz.guess()).format("MMM Do, YYYY h:mm A")} - ${endTime.tz(moment.tz.guess()).format("h:mm A z")}`
    }

    return (
        <LoadingContainer 
            isLoading={props.appointmentDetailsIsLoading} 
            spinnerSize={SpinnerSize.large} 
            spinnerClassName="flex-center c-spinner-padding"
        >
            <div className="flex c-contact-appt-details">
                <div className="flex-row c-title">
                    <Text className="flex" variant={TextSizes.xLarge} nowrap>
                        {props.selectedAppointmentDetails.appointmentDetails.subject}
                    </Text>
                </div>

                <div className="flex-row c-subtitle">
                    <Text className="flex" variant={TextSizes.medium} nowrap>
                        {getTimeFrame()}
                    </Text>
                </div>

                <div className="flex-row">
                    <div className="flex-column c-condensed-two-column">
                        <div className="flex-row">
                            <Text className="flex c-header" variant={TextSizes.large} nowrap>
                                {localizedStrings.ContactsView.reservationsPage.appointmentDetails.typeOfService}
                            </Text>
                        </div>

                        <div className="flex-row">
                            <Text className="flex c-content" variant={TextSizes.medium} nowrap>
                                {props.selectedAppointmentDetails.appointmentDetails.appointmentType.name}
                            </Text>
                        </div>
                    </div>

                    <div className="flex-column">
                        <div className="flex-row">
                            <Text className="flex c-header" variant={TextSizes.large} nowrap>
                                {localizedStrings.ContactsView.reservationsPage.appointmentDetails.topic}
                            </Text>
                        </div>

                        <div className="flex-row">
                            <Text className="flex c-content" variant={TextSizes.medium} nowrap>
                                {props.selectedAppointmentDetails.appointmentDetails.appointmentCategory.name}
                            </Text>
                        </div>
                    </div>
                </div>

                <div className="flex-row">
                    <Text className="flex c-header" variant={TextSizes.large} nowrap>
                        {localizedStrings.ContactsView.reservationsPage.appointmentDetails.description}
                    </Text>
                </div>

                <div className="flex-row">
                    <Text className="flex c-content" variant={TextSizes.medium}>
                        {props.selectedAppointmentDetails.appointmentDetails.descriptionOfIssue}
                    </Text>
                </div>

                <div className="flex-row">
                    <Text className="flex c-header" variant={TextSizes.large} nowrap>
                        {localizedStrings.ContactsView.reservationsPage.appointmentDetails.accommodations }
                    </Text>
                </div>

                <div className="flex-row">
                    <Checkbox disabled checked={props.selectedAppointmentDetails.appointmentDetails.accessibilityRequested} />
                        
                    <Text className="flex c-extra-buffer" variant={TextSizes.medium} nowrap>
                        {localizedStrings.ContactsView.reservationsPage.appointmentDetails.accessibilityAccommodations }
                    </Text>
                </div>

                <div className="flex-row"> 
                    <Text className="flex c-header" variant={TextSizes.large} nowrap>
                        {localizedStrings.ContactsView.reservationsPage.appointmentDetails.associateNotes}
                    </Text>
                </div>

                <div className="flex-row">
                    <Text className="flex" variant={TextSizes.medium}>
                        {props.selectedAppointmentDetails.appointmentDetails.associateNotes}
                    </Text>
                </div>
            </div>
        </LoadingContainer>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactAppointmentDetailsProvidedProps): Partial<IContactAppointmentDetailsOwnProps> {
    return {
        ...providedProps,
        selectedAppointmentDetails: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state).selectedAppointmentDetails,
        appointmentDetailsIsLoading: AppointmentDetailsSelectors.getIsLoading(state),
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactAppointmentDetails = connector(ContactAppointmentDetailsInitializer);
