import React, {FC} from "react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { connect } from "react-redux";

import { IClientelingViewState } from "../../../pages/Contracts";
import { IRapComponentProperties } from "../../Layout";

interface IErrorHandlerProvidedProps extends IRapComponentProperties {
    errorMessage: string;
    onReset: () => void;
}

interface IErrorHandlerOwnProps extends IErrorHandlerProvidedProps {

}

export type IErrorHandlerProps = IErrorHandlerOwnProps & typeof ActionsToDispatch;

const ErrorHandlerInitializer: FC<IErrorHandlerProps> = (props) => {
    if(props.errorMessage && props.errorMessage != "") {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                isMultiline={false}
                onDismiss={props.onReset}
                dismissButtonAriaLabel={"Close"}
            >
                {props.errorMessage}
            </MessageBar>
        )
    }
    return <></>
}

function mapStateToProps(state: IClientelingViewState, providedProps: IErrorHandlerProvidedProps): Partial<IErrorHandlerOwnProps> {
    return {
        ...providedProps,
    };
}

const ActionsToDispatch = {
    
};

export const ErrorHandler = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ErrorHandlerInitializer);