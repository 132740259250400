import { IDatePickerStrings } from "@fluentui/react/lib/DatePicker";
import { localizedStrings } from "./localization/LocalizedStrings";

export const DefaultLocale = "en-us";
export const AustraliaLocale = "en-au";
export const UnitedStatesLocale = "en-us";
export const CanadaLocale = "en-ca";
export const UnitedKingdomLocale = "en-gb";

export const AustraliaDisplay = "Australia (+61)";
export const UnitedStatesDisplay = "United States (+1)";
export const CanadaDisplay = "Canada (+1)";
export const UnitedKingdomDisplay = "United Kingdom (+44)";

export const EventsApiProdEmail = "eventsapiprod@microsoft.com";

export const NACountryCode = "+1";
export const AustraliaCountryCode = "+61";
export const UnitedKingdomCountryCode = "+44";
export const NAPhoneMask = "(999) 999-9999";
export const AustraliaPhoneMask = "999 999 999";
export const UnitedKingdomPhoneMask = "9999 999999";
export const NAPhoneRegex = /^(\+1|)?(\d{3})(\d{3})(\d{4})$/;
export const AustraliaPhoneRegex = /^(\+61|)?(\d{3})(\d{3})(\d{3})/;
export const UnitedKingdomPhoneRegex = /^(\+44|)?(\d{4})(\d{6})/;
export const EmailRegex = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PhoneFieldRegex = /^[\d() -]{11,}$/;
export const NonNumbersRegex = /[^\d]/g;
export const InvalidCharsRegex = /[,"/\\[\]:|<>+=;?*]/g;
export const NonPrintableCharsRegex = /[^ -~]+/g;
export const NonEmptyPhoneRegex = /[0-9]+/;

export const NAME_MAX_CHAR = 265;

export interface AuthToken {
    aio: string;
    aud: string;
    exp: number;
    iat: number;
    iss: string;
    name: string;
    nbf: number;
    nonce: string;
    oid: string;
    preferred_username: string;
    rh: string;
    sub: string;
    tid: string;
    uti: string;
    ver: string;
}

export enum AppointmentFlows {
    Create,
    Edit
}

export enum ActivitiesFeature {
    None,
    EventsList,
    AppointmentsList,
    CommandBar,
    ContactsView
}

export enum AppointmentsFeature {
    None,
    AppointmentsList,
    AppointmentStateFilter,
    CommandBar,
    ContactsView
}

export enum AttendeeListFeature {
    None
}

export enum CreateEditFormSections {
    Type,
    Topic,
    Date,
    Time,
    Info,
    All
}

export enum PageFeature {
    None,
    BackPane,
    NavBar,
    ToolBar,
    Authentication,
    NavMenu,
    SettingsMenu,
    UserSettings,
    Footer,
    CustomerLookup,
    NewAppointment,
    EventsCheckin,
    StoreSelection,
    ChangeView,
    ClientelingCommandBar,
    ErrorBoundary,
    RunnerRequestCommandBar
}

export enum ContactsFeature {
    None,
    ContactSearch,
    ContactList,
    CreateContact,
    ContactsView
}

export enum ErrorCode {
    NoUserFound = 1,
    UnAuthorizedUser = 2,
    GenericError = 3,
    StoreMetricsError = 4
}

export enum RegistrationStatus {
    Registered = "Registered",
    CheckedIn = "CheckedIn",
    Waitlisted = "Waitlisted"
}

export enum Themes {
    Default,
    Dark,
    HighContrast
}

export enum TextSizes {
    tiny = "tiny",
    xSmall = "xSmall",
    small = "small",
    smallPlus = "smallPlus",
    medium = "medium",
    mediumPlus = "mediumPlus",
    large = "large",
    xLarge = "xLarge",
    xxLarge = "xxLarge",
    mega = "mega"
}

export class AppointmentStatusReasons {
    public static Pending = "Pending";
    public static Reserved = "Reserved";
    public static Scheduled = "Scheduled";
    public static InProgress = "In Progress";
    public static CheckedIn = "Checked In";
    public static Requested = "Requested";
    public static Tentative = "Tentative";
    public static Cancelled = "Canceled"; //Dynamics returns "Canceled" as status, but we want to use "Cancelled"
    public static NoShow = "No Show";
    public static Completed = "Completed";
    public static Junk = "Junk";
    public static OutOfArea = "Out of Area";
    public static OutOfScopeGeneral = "Out of Scope - general";
    public static OutOfScopeCommerialSupport = "Out of Scope - needs commercial support";
    public static OutOfScopePersonalSupport = "Out of Scope - needs personal support​";
    public static TechSupport = "Tech Support";
    public static Staffing = "Staffing";
}

export enum AppointmentStatus {
    Open = 0,
    Closed = 1,
    Cancelled = 2,
    Scheduled = 3
}

export const HardwareAppointmentType = "Microsoft Service & Hardware Support";

export enum FilterTypes {
    Registrations = "Registrations",
    Waitlists = "Waitlists"
}

export enum AttendeeTypes {
    Anonymous = "Anonymous",
    Youth = "Youth"
}

export enum ViewType {
    Card = "1",
    List = "2"
}

export enum AssignTo {
    AssignedToMe = "1",
    Unassigned = "2",
    All = "3"
}

export enum DashboardPivotItems {
    Reservations = "Reservations"
}

export enum EventDetailsPivotItems {
    Details = "Details",
    Attendee = "Attendee"
}

export enum SortTypes {
    Alphabetical = "Alphabetical",
    Date = "Date"
}

export const Mode_FullScreen = "mode-fullscreen";

export const Theme_Default = "theme-default";
export const Theme_Dark = "theme-dark";
export const Theme_HighContrast = "theme-highcontrast";

export const Theme_Default_Key = "default";
export const Theme_Dark_Key = "dark";
export const Theme_HighContrast_Key = "contrast";

export const AppointmentType_HardwareSupport = "microsoft service & hardware support";
export const AppointmentType_PersonalTraining = "personal training";
export const AppointmentType_PersonalTrainingForBusiness = "personal  training for business";
export const AppointmentType_BusinessConsultation = "business consultation";
export const AppointmentType_PersonalShopping = "personal shopping assistance";
export const AppointmentType_PersonalSetup = "personal setup";
export const AppointmentType_EducationConsulation = "education consultation";

export const CategoryType_PickUp = "pick up";

export const AppointmentTypeIcon_HardwareSupport = "Devices2";
export const AppointmentTypeIcon_PersonalTraining = "Certificate";
export const AppointmentTypeIcon_PersonalTrainingForBusiness = "Certificate";
export const AppointmentTypeIcon_BusinessConsultation = "Telemarketer";
export const AppointmentTypeIcon_PersonalShopping = "Shop";
export const AppointmentTypeIcon_PersonalSetup = "CheckList";
export const AppointmentTypeIcon_EducationConsulation = "Education";

export const AppointmentIcon_OpenCaseLink = "Toolbox";

export enum BusinessAppointmentTypes {
    UK = "Business consultation - UK",
    EducationConsulationUK = "Education consultation - UK"
}

export const dayPickerStrings: IDatePickerStrings = {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

    shortMonths: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

    shortDays: ["S", "M", "T", "W", "T", "F", "S"],

    goToToday: "Go to today",
    prevMonthAriaLabel: "Go to previous month",
    nextMonthAriaLabel: "Go to next month",
    prevYearAriaLabel: "Go to previous year",
    nextYearAriaLabel: "Go to next year",
    closeButtonAriaLabel: "Close date picker"
};

export enum ContactSearchType {
    None = 0,
    Name = 1,
    Phone = 2,
    Email = 3,
    Invalid = 4
}

export const SelectedStoreId = "SelectedStoreId";
export const SelectedDate = "SelectedDate";
export const SelectedAppointmentTypes = "SelectedAppointmentTypes";
export const SelectedAssignStatus = "SelectedAssignStatus";
export const SelectedAppointmentState = "SelectedAppointmentState";
export const SelectedView = "SelectedView";
export const Cookie_Theme = "SelectedTheme";

export enum HttpErrorCodes {
    UnAuthorized = 403,
    NotFound = 404,
    Duplicate = 409
}

export const UnAuthorizedUser = 403;
export const NotFound = 404;

export const AddPanel_TypeOfService_FormItemKey = "typeofservice";
export const AddPanel_Type_FormItemKey = "type";
export const AddPanel_Mail_FormItemKey = "mail";
export const AddPanel_FirstName_FormItemKey = "firstName";
export const AddPanel_LastName_FormItemKey = "lastName";
export const AddPanel_DateTime_FormItemKey = "dateTime";
export const AddPanel_Description_FormItemKey = "description";
export const AddPanel_AssignTo_FormItemKey = "assignTo";
export const AddPanel_Notes_FormItemKey = "notes";
export const AddPanel_Devices_FormItemKey = "devices";
export const AddPanel_Title_FormItemKey = "title";
export const AddPanel_CompanyName_FormItemKey = "companyname";
export const AddPanel_CompanySize_FormItemKey = "companysize";
export const AddPanel_Phone_FormItemKey = "phone";
export const AddPanel_ProductInterests_FormItemKey = "productinterests";
export const AddPanel_AdditionalDetails_FormItemKey = "additionaldetails";
export const AddPanel_DynamicsCase_FormItemKey = "dynamicsCase";

export const AddPanel_QuestionBoolean_FormItemKey = "boolean";
export const AddPanel_QuestionSimpleText_FormItemKey = "simpletext";
export const AddPanel_QuestionSingleChoice_FormItemKey = "singlechoice";
export const AddPanel_QuestionMultipleChoice_FormItemKey = "multiplechoice";

export const ContactPanel_Appointments = "appointments";
export const ContactPanel_Devices = "devices";
export const ContactPanel_Edit = "edit";
export const ContactPanel_Events = "events";
export const ContactPanel_Notes = "notes";
export const ContactPanel_Invoices = "invoices";
export const ContactPanel_Incidents = "incidents";

export const DynamicsCaseTable_Headers_CaseId = "Case id";
export const DynamicsCaseTable_Headers_Model = "Model";
export const DynamicsCaseTable_Headers_SerialNumber = "Serial number";
export const DynamicsCaseTable_Headers_Description = "Description";
export const DynamicsCaseTable_Headers_Scope = "Scope of work";
export const DynamicsCaseUrl = "{0}main.aspx?appid=9ff4055c-b660-e811-a95c-000d3a1beaae&pagetype=entityrecord&etn=incident&id={1}";

export const TimeFormat = "h:mm A";
export const DateFormat = "YYYY-MM-DD";
export const FriendlyDateFormat = "MMMM D, YYYY" // e.g. January 25, 2023
export const DateTimeFormat = "YYYY-MM-DD hh:mm";
export const DaysInAdvance = 14;
export const TimeslotBuffer = 60;
export const JoinBuffer = 20;
export const TimeDiffRequirement = 10;
export enum AppointmentState {
    Open = 0,
    Closed = 1,
    Canceled = 2,
    Scheduled = 3,
    All = 999 // This does not exist in Dynamics
}

export const WalkInAppointmentTypeIds = [
    "b6354881-4bcd-eb11-bacd-0022482218bb", // Microsoft Service & Hardware Support Walk In - PPE5
    "9b61c1a5-7ab0-ec11-983f-002248260914" //  Microsoft Service & Hardware Support Walk In - PPE3/PROD
];

export const SearchBoxResolveDelay = 400;
export const SearchBoxResultsLimit = 4;

export const NoteTitle = "Note Title";

export const CustomResponse_Boolean = "Boolean";
export const CustomResponse_Text = "SimpleText";
export const CustomResponse_SingleChoice = "SingleChoice";
export const CustomResponse_MultiChoice = "MultipleChoice";

export const DescriptionCharLength = 1999;
export const CustomResponseCharLength = 849;
export const PoboCharLength = 99;
export const AssociateNotesCharLength = 3999;

export const NumberOfRecommendations = 3;

export enum DetailListColumnSizes {
    xsmall = 100,
    small = 132,
    medium = 172,
    large = 240
}

export enum DetailListColumnSizes_Max {
    small = 250,
    medium = 425,
    large = 500
}

export const PhoneCountryCodes = new Map([
    [AustraliaDisplay, AustraliaCountryCode],
    [CanadaDisplay, NACountryCode],
    [UnitedKingdomDisplay, UnitedKingdomCountryCode],
    [UnitedStatesDisplay, NACountryCode]
]);

export const PhoneNumberMasks = new Map([
    [UnitedStatesDisplay, NAPhoneMask],
    [CanadaDisplay, NAPhoneMask],
    [UnitedKingdomDisplay, UnitedKingdomPhoneMask],
    [AustraliaDisplay, AustraliaPhoneMask]
]);

//number of digits in the phone number per country code, not including the country code
export const PhoneNumberRegexMap = new Map([
    [AustraliaCountryCode, /[0-9]{9}/],
    [NACountryCode, /[0-9]{10}/],
    [UnitedKingdomCountryCode, /[0-9]{10}/]
]);

export enum LeadCampaigns {
    Business = "ASIA - Majorel Clark - Organic - Business Consultation - Outbound Leads",
    Education = "MRS - Business Consultation EDU",
    Post = "DSS - WSS post sale"
}

export enum LeadKeyWords {
    Training = "training",
    Business = "business",
    Consultation = "consultation",
    Education = "education",
    Company = "company"
}

export enum RunnerRequestState {
    ActionNeeded = 0,
    InProgress = 1,
    Canceled = 2,
    Complete = 3,
    All = 4
}

export enum RunnerRequestStatus {
    Active,
    Canceled,
    Complete,
    InProgress,
    ReadyForPickup
}

export enum RunnerRequestView {
    Runner,
    FrontOfHouse
}

export const RunnerRequestDashboardDefaultStore = "0085";
export const RunnerRequestRefreshTime = 10000; // In milliseconds
export const MaxItemsInRequest = 10;
export const ActiveRequestTimer = 45 // In seconds
export const CancelReasonMaxLength = 255;

export const RunnerRequestStatusLabels = [
    localizedStrings.RunnerRequestsView.RunnerRequestList.active,
    localizedStrings.RunnerRequestsView.RunnerRequestList.canceled,
    localizedStrings.RunnerRequestsView.RunnerRequestList.complete,
    localizedStrings.RunnerRequestsView.RunnerRequestList.inProgress,
    localizedStrings.RunnerRequestsView.RunnerRequestList.readyForPickup,
];

export const RunnerRequestFloorLabels = [
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.firstFloor,
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.secondFloor,
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.thirdFloor,
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.fourthFloor,
];

export const RunnerRequestOrderTypeLabels = [
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.inStoreOrder,
    localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.onlineOrder
];

export enum RunnerRequestOrderType {
    InStoreOrder = 0,
    OnlineOrder = 1,
    DBSOrder = 2
}

export enum RunnerRequestCancelOptions {
    NotInStock = "1",
    OnHold = "2",
    Other = "3"
}
