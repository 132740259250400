import React, { FC } from "react";
import { DefaultButton, Modal, PrimaryButton } from "@fluentui/react";
import { connect, ConnectedProps } from "react-redux";

import { IAppointmentDto, IAppointmentDetailDto, AppointmentDetailDto, IOptionSetAttribute } from "./../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../localization/LocalizedStrings";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { ReservationsActions } from "../../../../views/ReservationsView/redux/ReservationsActions";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { AppointmentUtils } from "../../../../common/AppointmentUtils";
import { IViewOptions } from "../../../../platform/components/Page/Contracts";
import { AppointmentStatusReasons, JoinBuffer } from "../../../Constants";

import * as ReservationsSelectors from "../../../../views/ReservationsView/redux/ReservationsSelectors";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { useBoolean } from "@uifabric/react-hooks";
import { AppointmentDetailsActions } from "../../../../views/AppointmentDetailsView/redux/AppointmentDetailsActions";


//Props passed by parent component
interface IAppointmentPrimaryButtonProvidedProps extends IRapComponentProperties {
    appointment: IAppointmentDto | IAppointmentDetailDto;
    className?: string;
}

interface IAppointmentPrimaryButtonOwnProps {
    appointmentStatus: IOptionSetAttribute[];
    appointmentStatusReasons: IOptionSetAttribute[];
    viewOptions: IViewOptions;

}

export type IAppointmentPrimaryButtonProps = ConnectedProps<typeof connector> & IAppointmentPrimaryButtonProvidedProps;


const AppointmentPrimaryButton: FC<IAppointmentPrimaryButtonProps> = (props) => {
    const [isModalOpen, {setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const _onCheckinAppointmentClick = (appointmentDetails: IAppointmentDto | IAppointmentDetailDto): void => {
        let appointmentUtils = new AppointmentUtils();
        if (appointmentDetails) {
            let statusId;
            let statusReasonId;
            if (appointmentDetails.isVirtualAppointment) {
                statusId = appointmentUtils.getAppointmentStatusId(AppointmentStatusReasons.InProgress.toString(), props.appointmentStatus);
                statusReasonId = appointmentUtils.getAppointmentStatusReasonId(AppointmentStatusReasons.InProgress.toString(), props.appointmentStatusReasons);
            } else {
                statusId = appointmentUtils.getAppointmentStatusId(AppointmentStatusReasons.CheckedIn.toString(), props.appointmentStatus);
                statusReasonId = appointmentUtils.getAppointmentStatusReasonId(AppointmentStatusReasons.CheckedIn.toString(), props.appointmentStatusReasons);
                
            }
            _updateAppointment(appointmentDetails, statusId, statusReasonId);
        }
    };
    
    
    const _onStartAppointmentClick = (appointmentDetails: IAppointmentDto | IAppointmentDetailDto): void => {
        let appointmentUtils = new AppointmentUtils();
        const statusId = appointmentUtils.getAppointmentStatusId(AppointmentStatusReasons.InProgress.toString(), props.appointmentStatus);
        const statusReasonId = appointmentUtils.getAppointmentStatusReasonId(AppointmentStatusReasons.InProgress.toString(), props.appointmentStatusReasons);
        _updateAppointment(appointmentDetails, statusId, statusReasonId);
    };

    const _onCompleteAppointmentClick = (appointmentDetails: IAppointmentDto | IAppointmentDetailDto): void => {
        hideModal();
        let appointmentUtils = new AppointmentUtils();
        const statusId = appointmentUtils.getAppointmentStatusId(AppointmentStatusReasons.Completed.toString(), props.appointmentStatus);
        const statusReasonId = appointmentUtils.getAppointmentStatusReasonId(AppointmentStatusReasons.Completed.toString(), props.appointmentStatusReasons);
        _updateAppointment(appointmentDetails, statusId, statusReasonId);
    };

    const _updateAppointment = (appointmentDetails: IAppointmentDto | IAppointmentDetailDto, statusId: number, statusReasonId: number): void => {
        if (appointmentDetails) {
            props.onAppointmentSelected(appointmentDetails as IAppointmentDto);
            props.updateAppointment(
                props.viewOptions.selectedStore,
                appointmentDetails.appointmentId,
                { statusId: statusId, statusReasonId: statusReasonId },
                true).then(() => {
                    props.updateMessages({ toastMessage: localizedStrings.AppointmentButtons.updateSuccessful });
                    props.fetchAppointmentsData(true);
                    props.fetchAppointmentCountByState();
                    props.fetchAppointmentDetails(props.viewOptions.selectedStore,appointmentDetails.appointmentId, true);
                });
        }
    }
    
    let isVirtualAppointment = props.appointment?.isVirtualAppointment ? true : false;
    const apptStatusReason = props.appointment instanceof AppointmentDetailDto ? props.appointment?.statusReason?.label : props.appointment?.statusReason;
    const currentDate = new Date();
    const appointmentStartTime = props.appointment?.appointmentDate;
    const diffInMilliseconds = appointmentStartTime && appointmentStartTime instanceof Date? appointmentStartTime.getTime() - currentDate.getTime() : 0;
    const diffInMins = Math.floor((diffInMilliseconds / 1000) / 60);

    let primaryButton = <></>;
    if (apptStatusReason === AppointmentStatusReasons.InProgress) {
        primaryButton = (
            <div>
                <PrimaryButton
                    className={`c-appointment-button ${props.className}`}
                    text={localizedStrings.AppointmentButtons.complete}
                    primary={true}
                    onClick={showModal}
                />
                <Modal
                    isOpen={isModalOpen}
                    isBlocking={false}
                    containerClassName="c-modal"
                    >
                    <div className="c-modal-container">
                        <div className="c-modal-header">
                            <div>{localizedStrings.ConfirmCompletedModal?.title}</div>
                        </div>
                        <div className="c-modal-body">
                            <div className="c-modal-subtitle">
                                {localizedStrings.ConfirmCompletedModal?.subtitle}
                            </div>
                        </div>

                        <div className="c-modal-footer">
                            <div className="c-modal-footer-item">
                                <DefaultButton className="t-default-button c-button-cancel c-modal-button" text={localizedStrings.ConfirmCompletedModal?.cancel} onClick={hideModal}/>
                            </div>
                            <div className="c-modal-footer-item">
                                <DefaultButton className="t-primary-button c-modal-button" text={localizedStrings.ConfirmCompletedModal?.confirm} onClick={() => _onCompleteAppointmentClick(props.appointment)}/>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
);
    } else if (apptStatusReason === AppointmentStatusReasons.CheckedIn) {
        primaryButton = (
            <PrimaryButton
                className={`c-appointment-button ${props.className}`}
                text={localizedStrings.AppointmentButtons.startAppointment}
                primary={false}
                onClick={() => _onStartAppointmentClick(props.appointment)}
            />);
    } else if (apptStatusReason === AppointmentStatusReasons.Reserved)  { 
        if (isVirtualAppointment) { 
            if (diffInMins <= JoinBuffer) { // show the Join button if we are within x minutes of appointment start time
                primaryButton = (
                    <PrimaryButton
                        className={`c-appointment-button ${props.className}`}
                        text={localizedStrings.AppointmentButtons.join}
                        primary={true}
                        onClick={() => _onCheckinAppointmentClick(props.appointment)}
                        href={props.appointment.teamsDialIn}
                        target="_blank" 
                    />);
            }
            // else, don't show Join button yet
        } else { // always show Check In button for in person appointments (no time limit)
            primaryButton = (
                <PrimaryButton
                    className={`c-appointment-button ${props.className}`}
                    text={localizedStrings.AppointmentButtons.customerCheckin}
                    primary={true}
                    onClick={() => _onCheckinAppointmentClick(props.appointment)} 
                />);
        }
    }
    return (<>
        {primaryButton}
    </>);
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentPrimaryButtonProvidedProps): Partial<IAppointmentPrimaryButtonOwnProps> {
    return {
        ...providedProps,
        appointmentStatus: ReservationsSelectors.getResources(state)?.appointmentStatuses,
        appointmentStatusReasons: ReservationsSelectors.getResources(state)?.appointmentStatusReasons,
        viewOptions: PageSelectors.getViewOptions(state),
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    onAppointmentSelected: ReservationsActions.onAppointmentSelected,
    updateAppointment: ReservationsActions.updateAppointment,
    fetchAppointmentsData: ReservationsActions.fetchAppointmentsData,
    fetchAppointmentCountByState: ReservationsActions.fetchAppointmentCountByState,
    updateMessages: PageActions.updateMessages,
    fetchAppointmentDetails: AppointmentDetailsActions.fetchAppointmentDetail
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(AppointmentPrimaryButton);