import React, {FC, useEffect, useState} from "react";
import { connect } from "react-redux";
import { produce } from "immer";

import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { TextField, Dropdown, IDropdownOption } from "@fluentui/react";
import { IRapComponentProperties } from "../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import * as Constants from "../../../../Constants";
import { IAppointmentCustomResponseDto } from "../../../../../contracts/swagger/_generated";

import "./AppointmentResponses.scss";

interface IAppointmentCustomResponse_Dropdown_Generation {
    name: string;
    options?: IDropdownOption[];
    response: string;
    multiResponse?: string[];
}

interface IAppointmentResponseProvidedProps extends IRapComponentProperties {
    appointmentCustomResponses: IAppointmentCustomResponseDto[];
    onBlur?: () => void;
    onSelect?: (value: IAppointmentCustomResponseDto[]) => void; // If field is updated send entire UPDATED object back
    isDisabled?: boolean;
}

interface IAppointmentResponseOwnProps extends IAppointmentResponseProvidedProps {
}

export type IAppointmentResponseInitializerProps = IAppointmentResponseOwnProps & typeof ActionsToDispatch;

const ApppointmentResponseFunction : FC<IAppointmentResponseInitializerProps> = (props) => {
    const [allOptionSets, setAllOptionSets] = useState<IAppointmentCustomResponse_Dropdown_Generation[]>(null);

    const [initialOptionSets, setInitialOptionSets] = useState<IAppointmentCustomResponse_Dropdown_Generation[]>();

    const [updatedCustomResponses, setUpdatedCustomResponses] = useState<IAppointmentCustomResponseDto[]>(props.appointmentCustomResponses);

    const specialResponseString = "Yes, my device was purchased within 45 days.*";

    useEffect(() => {
        setAllOptionSets(_createAllOptionSets(props.appointmentCustomResponses));
    }, [props.appointmentCustomResponses]);

    useEffect(() => {
        setInitialOptionSets(_createInitialOptionSets(props.appointmentCustomResponses));
    }, []);

    useEffect(() => {
        props.onSelect(updatedCustomResponses);
    }, [updatedCustomResponses]);

    const _createAllOptionSets = (appointmentCustomResponsesGeneration: IAppointmentCustomResponseDto[]) => {
        let createOptionSets: IAppointmentCustomResponse_Dropdown_Generation[] = [];
        
        appointmentCustomResponsesGeneration?.map(item => {
            if (item.responseType === Constants.CustomResponse_Boolean) {
                let optionSet: IDropdownOption[] = [];

                optionSet.push({key: localizedStrings.CreateEditAppointmentModal.customResponseTrue, text: localizedStrings.CreateEditAppointmentModal.customResponseTrue});
                optionSet.push({key: localizedStrings.CreateEditAppointmentModal.customResponseFalse, text: localizedStrings.CreateEditAppointmentModal.customResponseFalse});
    
                let newOption = {
                    name: item.customQuestionId,
                    options: optionSet
                } as IAppointmentCustomResponse_Dropdown_Generation;

                createOptionSets.push(newOption);
            }

            if (item.responseType === Constants.CustomResponse_SingleChoice || item.responseType === Constants.CustomResponse_MultiChoice) {
                let optionSet: IDropdownOption[] = [];
                item.choices.forEach((xItem) => {
                    optionSet.push({key: xItem, text: xItem})
                });
                
                let newOption: IAppointmentCustomResponse_Dropdown_Generation;
                if (item.responseType === Constants.CustomResponse_MultiChoice && item.response) {
                    //create array of responses
                    let allResponses = item.response.split(',');
                    newOption = {
                        name: item.customQuestionId,
                        options: optionSet,
                        multiResponse: allResponses
                    } as IAppointmentCustomResponse_Dropdown_Generation;
                }
                else {
                    newOption = {
                        name: item.customQuestionId,
                        options: optionSet
                    } as IAppointmentCustomResponse_Dropdown_Generation;
                }

                createOptionSets.push(newOption);
            }
        });

        return createOptionSets;
    }

    const _createInitialOptionSets = (initialAppointmentCustomResponses: IAppointmentCustomResponseDto[]) => {
        let initialOptionSets: IAppointmentCustomResponse_Dropdown_Generation[] = [];

        initialAppointmentCustomResponses?.map(item => {
            if (item.responseType === Constants.CustomResponse_Boolean || item.responseType === Constants.CustomResponse_Text) {
                let newOption = {
                    name: item.customQuestionId,
                    response: (item.response ? item.response : null)
                } as IAppointmentCustomResponse_Dropdown_Generation;
                initialOptionSets.push(newOption);
            }

            if (item.responseType === Constants.CustomResponse_SingleChoice) {
                let newOption = {
                    name: item.customQuestionId,
                    response: (item.response ? item.response : null)
                } as IAppointmentCustomResponse_Dropdown_Generation;
                initialOptionSets.push(newOption);
            }

            if (item.responseType === Constants.CustomResponse_MultiChoice && item.response)
            {
                let newOption: IAppointmentCustomResponse_Dropdown_Generation;

                //create array of responses
                //NOTE: Specific response is listed out as it includes a comma character that messes with the logic used to generate the response array
                if(item.response && item.response !== null && item.response !== specialResponseString)
                {
                    let allResponses = item.response.split(',');
                    newOption = {
                        name: item.customQuestionId,
                        multiResponse: allResponses
                    } as IAppointmentCustomResponse_Dropdown_Generation;
                }
                else if (item.response === specialResponseString)
                {
                    let specificResponse: string[] = [];
                    specificResponse.push(item.response);

                    newOption = {
                        name: item.customQuestionId,
                        multiResponse: specificResponse
                    } as IAppointmentCustomResponse_Dropdown_Generation;
                }
                else {
                    newOption = {
                        name: item.customQuestionId,
                        multiResponse: null
                    } as IAppointmentCustomResponse_Dropdown_Generation;
                }
                
                initialOptionSets.push(newOption);
            }
        });
        
        return initialOptionSets;
    }

    const _setUpdatedCustomResponses = (key: string, value: string) => {
        if (updatedCustomResponses)
        {
            const index: number = updatedCustomResponses.findIndex(x => x.customQuestionId === key);

            //MultiSelect cannot be be updated wholesale
            if (updatedCustomResponses[index].responseType === Constants.CustomResponse_MultiChoice)
            {
                //Response already exists
                if (updatedCustomResponses[index].response === "" || updatedCustomResponses[index].response === undefined)
                {
                    setUpdatedCustomResponses(produce((draft) => {
                        draft[index].response = value;
                    }));
                }
                else
                {
                    let newObject: IAppointmentCustomResponseDto[] = JSON.parse(JSON.stringify(updatedCustomResponses));
                    // Parse based on ',' into array and then check array and remove existing/add if not there
                    // Array.toString() will automatically add a comma between each element
                    let responseArray: string[] = newObject[index].response.split(',');
                    let indexOfValue: number = responseArray.indexOf(value);
                    if (indexOfValue !== -1) //-1 means not found
                    {
                        responseArray.splice(indexOfValue, 1);
                        newObject[index].response = responseArray.toString();
                        setUpdatedCustomResponses(produce((draft) => {
                            draft[index].response = responseArray.toString()
                        }));
                    }
                    else
                    {
                        responseArray.push(value);
                        newObject[index].response = responseArray.toString();
                        setUpdatedCustomResponses(produce((draft) => {
                            draft[index].response = responseArray.toString()
                        }));
                    }
                }
            }
            else
            {
                setUpdatedCustomResponses(produce((draft) => {
                    draft[index].response = value;
                }));
            }
        }
    }

    const getResponses = () => {
        return props.appointmentCustomResponses?.map((item: IAppointmentCustomResponseDto) => {
            if (item.responseType === Constants.CustomResponse_Boolean && allOptionSets) {
                return (
                    <div className="flex-row c-row">
                        <Dropdown
                            key={item.customQuestionId}
                            required={item.isRequired}
                            label={item.questionText}
                            className="c-column-wide"
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={(event, val) => _setUpdatedCustomResponses(item.customQuestionId, val.text)}
                            disabled={props.isDisabled}
                            options={allOptionSets.find(x => x.name === item.customQuestionId).options}
                            onBlur={props.onBlur}
                            defaultSelectedKey={initialOptionSets.length !== 0 ? initialOptionSets.find(x => x.name === item.customQuestionId).response : ""}
                        />
                    </div>
                )
            }

            if (item.responseType === Constants.CustomResponse_Text) {
                return (
                    <div className="flex-row c-row" key={item.customQuestionId}>
                        <TextField
                            maxLength={Constants.CustomResponseCharLength}
                            key={item.customQuestionId}
                            required={item.isRequired}
                            label={item.questionText}
                            className="c-column-wide"
                            onChange={(event, val) => _setUpdatedCustomResponses(item.customQuestionId, val)}
                            disabled={props.isDisabled}
                            onBlur={props.onBlur}
                            defaultValue={item.response}
                        />
                    </div>
                )
            }

            if (item.responseType === Constants.CustomResponse_SingleChoice && allOptionSets) {
                return (
                    <div className="flex-row c-row" key={item.customQuestionId}>
                        <Dropdown
                            key={item.customQuestionId}
                            required={item.isRequired}
                            label={item.questionText}
                            className="c-column-wide"
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={(event, val) => _setUpdatedCustomResponses(item.customQuestionId, val.text)}
                            disabled={props.isDisabled}
                            options={allOptionSets.find(x => x.name === item.customQuestionId).options}
                            onBlur={props.onBlur}
                            defaultSelectedKey={initialOptionSets.length !== 0 ? initialOptionSets.find(x => x.name === item.customQuestionId).response : ""}
                        />
                    </div>
                )
            }

            if (item.responseType === Constants.CustomResponse_MultiChoice && allOptionSets) {
                return (
                    <div className="flex-row c-row">
                        <Dropdown
                            key={item.customQuestionId}
                            required={item.isRequired}
                            label={item.questionText}
                            className="c-column-wide"
                            // eslint-disable-next-line react/jsx-no-bind
                            onChange={(event, val) => _setUpdatedCustomResponses(item.customQuestionId, val.text)}
                            disabled={props.isDisabled}
                            options={allOptionSets.find(x => x.name === item.customQuestionId).options}
                            onBlur={props.onBlur}
                            defaultSelectedKeys={initialOptionSets.length !== 0 ? initialOptionSets.find(x => x.name === item.customQuestionId).multiResponse : []}
                            multiSelect
                        />
                    </div>
                )
            }
        });
    }

    return (
        <div className="c-appointment-custom-responses flex-column">
            { getResponses() }
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentResponseProvidedProps): Partial<IAppointmentResponseOwnProps> {
    return {
        ...providedProps
    };
}

const ActionsToDispatch = {
};

export const AppointmentCustomResponse = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ApppointmentResponseFunction);