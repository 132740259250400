import "./Callout.scss";
import * as React from "react";

import { FocusWithin } from "./../FocusWithin/FocusWithin";
import { IFocusWithinEvents } from "./../FocusWithin/FocusWithin.Props";
import { FocusZone } from "./../FocusZone/FocusZone";
import { Portal } from "./../Portal/Portal";
import { css, getSafeId, KeyCode } from "./../../../core/util/Util";
import { Location, position, updateLayout } from "./../../../core/util/Position";
import { TimerManagement } from "./../../../core/TimerManagement";
import { SurfaceContext } from "./../Surface/Surface";
import { SurfaceBackground } from "./../Surface/Surface.Props";
import { ContentJustification, ContentLocation, ContentOrientation, ContentSize, ICallout, ICalloutProps } from "./Callout.Props";

export class Callout extends React.Component<ICalloutProps> implements ICallout {
    public static defaultProps: Partial<ICalloutProps> = {
        blurDismiss: false,
        viewportChangeDismiss: true
    };

    private calloutContent = React.createRef<CalloutContent>();

    public render() {
        const { portalProps } = this.props;

        return (
            <Portal {...portalProps} className={css(portalProps && portalProps.className, this.props.anchorElement && "bolt-layout-relative")}>
                <CalloutContent ref={this.calloutContent} {...this.props} />
            </Portal>
        );
    }

    public componentWillUnmount() {
        // We need to let the content handle the WillUnmount before the Portal, this
        // will ensure the the callout can deal with unmounting content that still has
        // focus. Otherwise the root will be detached from the document and focus will
        // have moved to the body.
        if (this.calloutContent.current) {
            this.calloutContent.current.portalWillUnmount();
        }
    }

    public updateLayout() {
        if (this.calloutContent.current) {
            this.calloutContent.current.updateLayout();
        }
    }
}

class CalloutContent extends React.Component<ICalloutProps> implements ICallout {
    private calloutElement = React.createRef<HTMLDivElement>();
    private contentElement: React.RefObject<HTMLDivElement>;
    private focusElement: HTMLElement | undefined;

    private relayoutTimer: TimerManagement = new TimerManagement();

    private scrollListen: boolean = false;
    private scrollEvent: Event | null = null;

    constructor(props: ICalloutProps) {
        super(props);

        // Track the element that had focus when we mounted.
        this.focusElement = document.activeElement as HTMLElement;
        this.contentElement = props.contentRef || React.createRef<HTMLDivElement>();
    }

    public render(): JSX.Element {
        const { contentJustification, contentLocation, contentOrientation, lightDismiss, modal, onAnimationEnd, anchorElement } = this.props;

        let content: JSX.Element;

        // If we have both a FocusWithin and a FocusZone we need to use the functional version
        // of the FocusWithin to allow the FocusZone to contain the content directly.
        if (this.props.blurDismiss && this.props.focuszoneProps) {
            content = (
                <FocusWithin onBlur={this.onBlur} updateStateOnFocusChange={false}>
                    {(props: IFocusWithinEvents) => (
                        <FocusZone {...this.props.focuszoneProps}>{this.renderContent(props.onFocus, props.onBlur)}</FocusZone>
                    )}
                </FocusWithin>
            );
        } else {
            content = this.renderContent();

            // Add the focus tracker to dismiss the callout if we are dismissing on blur.
            if (this.props.blurDismiss) {
                content = (
                    <FocusWithin onBlur={this.onBlur} updateStateOnFocusChange={false}>
                        {content}
                    </FocusWithin>
                );
            }

            // Add focus zone if focuszoneProperties are specified
            if (this.props.focuszoneProps) {
                content = <FocusZone {...this.props.focuszoneProps}>{content}</FocusZone>;
            }
        }

        const lightDismissDiv: JSX.Element | null = lightDismiss ? (
            <div className={css("absolute-fill bolt-light-dismiss", modal && "bolt-callout-modal")} onClick={this.onClick} />
        ) : null;

        // The callout is wrapped in a floating element in the portal.
        // If lightDismiss is enabled we will create an absolute-fill div to capture onClick events.
        return (
            <SurfaceContext.Provider value={{ background: SurfaceBackground.callout }}>
                <div>
                    <div
                        className={css(
                            this.props.className,
                            "bolt-callout absolute",
                            contentLocation !== undefined && "absolute-fill",
                            contentJustification === ContentJustification.Start && "justify-start",
                            contentJustification === ContentJustification.Center && "justify-center",
                            contentJustification === ContentJustification.End && "justify-end",
                            contentLocation === ContentLocation.Start && "flex-start",
                            contentLocation === ContentLocation.Center && "flex-center",
                            contentLocation === ContentLocation.End && "flex-end",
                            contentOrientation === ContentOrientation.Column && "flex-column",
                            contentOrientation !== ContentOrientation.Column && "flex-row",
                            modal && !lightDismiss && "bolt-callout-modal"
                        )}
                        id={getSafeId(this.props.id)}
                        onAnimationEnd={onAnimationEnd}
                        onKeyDown={this.onKeyDown}
                        ref={this.calloutElement}
                        role={this.props.role}
                    >
                        {!anchorElement && lightDismissDiv}
                        {content}
                    </div>
                    {!!anchorElement && lightDismissDiv}
                </div>
            </SurfaceContext.Provider>
        );
    }

    public componentDidMount() {
        this.updateLayout();

        // If this is an element relative layout we need to listen for scroll events
        // on the document and dismiss the callout if the scroll event didnt pass
        // through the callout.
        if (this.props.anchorElement) {
            window.addEventListener("resize", this.onResize);
            document.addEventListener("scroll", this.onScrollDocument, true);
            this.scrollListen = true;
        }
    }

    public componentDidUpdate() {
        if (this.props.updateLayout) {
            this.updateLayout();
        }
    }

    public componentWillUnmount() {
        if (this.scrollListen) {
            document.removeEventListener("scroll", this.onScrollDocument, true);
            window.removeEventListener("resize", this.onResize);
        }
        if (this.relayoutTimer) {
            this.relayoutTimer.clearAllTimers();
        }
    }

    public portalWillUnmount() {
        const contentElement = this.contentElement.current;
        const { focusElement } = this;

        // If the callout has focus when unmounted we need to set focus back to the last element with focus.
        // Need to wait for next tick otherwise focus/blur events are not fired.
        if (focusElement && contentElement && contentElement.contains(document.activeElement)) {
            window.setTimeout(() => {
                // We need to make sure the active element is portal after the timeout.
                // It may have moved through other means before the timeout expires.
                // Set focus to the focusElement if our element contains focus, or if the focus has gone back to the document body
                if (contentElement.contains(document.activeElement) || document.activeElement === document.body || document.activeElement === null) {
                    focusElement.focus();
                }
            }, 0);
        }
    }

    public updateLayout() {
        if (this.props.contentLocation === undefined) {
            if (this.calloutElement.current) {
                // Position the element based on the initial layout parameters.
                position(
                    this.calloutElement.current,
                    this.props.calloutOrigin || { horizontal: Location.start, vertical: Location.start },
                    this.props.anchorOffset,
                    this.props.anchorElement,
                    this.props.anchorOrigin,
                    this.props.anchorPoint
                );

                // Now that the component is placed at the requested location, update
                // the layout if the caller didnt request a fixed layout.
                if (!this.props.fixedLayout) {
                    updateLayout(
                        this.calloutElement.current,
                        this.props.calloutOrigin || { horizontal: Location.start, vertical: Location.start },
                        this.props.anchorOffset,
                        this.props.anchorElement,
                        this.props.anchorOrigin,
                        this.props.anchorPoint
                    );
                }
            }
        }
    }

    private onBlur = () => {
        this.props.onDismiss && this.props.onDismiss();
    };

    private onClick = (event: React.MouseEvent<HTMLDivElement>) => {
        // If we click on the light dismiss div we will dismiss it.
        if (this.props.lightDismiss && !event.defaultPrevented) {
            if (this.props.onDismiss) {
                this.props.onDismiss();
            }

            event.preventDefault();
        }
    };

    private onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        // If we press escape from within the callout this will dismiss it.
        if (this.props.escDismiss && event.which === KeyCode.escape && !event.defaultPrevented) {
            if (this.props.onDismiss) {
                this.props.onDismiss();
            }

            event.preventDefault();
        }
    };

    private onResize = () => {
        if (this.props.viewportChangeDismiss === true) {
            this.props.onDismiss && this.props.onDismiss();
        } else if (this.props.updateLayout) {
            this.relayoutTimer.clearAllTimers();
            this.relayoutTimer.setTimeout(() => {
                this.updateLayout();
            }, 200);
        }
    };

    private onScroll = (event: React.SyntheticEvent<HTMLElement>) => {
        if (this.scrollListen) {
            this.scrollEvent = event.nativeEvent;
        }
    };

    private onScrollDocument = (event: Event) => {
        if (this.scrollListen) {
            if (event === this.scrollEvent) {
                this.scrollEvent = null;
            } else {
                if (this.props.viewportChangeDismiss === true) {
                    const { anchorElement } = this.props;

                    // If the element containing the anchor is scrolled dismiss the callout.
                    if (event.target && anchorElement && (event.target as HTMLElement).contains(anchorElement)) {
                        this.props.onDismiss && this.props.onDismiss();
                    }
                } else if (this.props.updateLayout) {
                    this.relayoutTimer.setTimeout(() => {
                        this.updateLayout();
                    }, 50);
                }
            }
        }
    };

    private renderContent(onFocus?: (event: React.FocusEvent<HTMLElement>) => void, onBlur?: (event: React.FocusEvent<HTMLElement>) => void) {
        const { contentJustification, contentOrientation, contentSize } = this.props;

        return (
            <div
                aria-describedby={getSafeId(this.props.ariaDescribedBy)}
                aria-label={this.props.ariaLabel}
                aria-labelledby={getSafeId(this.props.ariaLabelledBy)}
                className={css(
                    this.props.contentClassName,
                    "bolt-callout-content",
                    this.props.contentShadow && "bolt-callout-shadow",
                    contentJustification === ContentJustification.Stretch && "flex-grow",
                    contentOrientation === ContentOrientation.Column && "flex-column",
                    contentOrientation === ContentOrientation.Row && "flex-row",
                    contentSize === ContentSize.Small && "bolt-callout-small",
                    contentSize === ContentSize.Medium && "bolt-callout-medium",
                    contentSize === ContentSize.Large && "bolt-callout-large",
                    contentSize === ContentSize.Auto && "bolt-callout-auto"
                )}
                onBlur={onBlur}
                onFocus={onFocus}
                onScroll={this.onScroll}
                ref={this.contentElement}
                role={this.props.role || "dialog"}
            >
                {this.props.children}
            </div>
        );
    }
}
