import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { IPersonaProps, IBasePickerSuggestionsProps } from "@fluentui/react";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../../platform/Layout";
import { BasePeoplePicker, IPeoplePickerObject } from "../BasePeoplePicker/BasePeoplePicker";
import { IContactDto } from "../../../../../contracts/swagger/_generated";
import { AppointmentModalActions } from "../../redux/AppointmentModalActions";
import { SearchBoxResultsLimit } from "../../../../Constants";
import { localizedStrings } from "../../../../localization/LocalizedStrings";

import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";
import * as FeatureManagementSelectors from "../../../../../views/FeatureManagement/redux/FeatureManagementSelectors";

import "./ClientSearchBox.scss";

//Props passed by parent component
interface IClientSearchBoxProvidedProps extends IRapComponentProperties {
    value: IContactDto;
    onBlur?: () => void;
    onSelect?: (item: IContactDto) => void;
    disabled?: boolean;
}

//Props mapped from state object
interface IClientSearchBoxInitializerOwnProps {
    contacts?: IContactDto[];
    enableScheduleAutoAssign: boolean;
}

// eslint-disable-next-line
export type IClientSearchBoxInitializerProps = ConnectedProps<typeof connector> & IClientSearchBoxProvidedProps;

const ClientSearchBoxInitializer: FC<IClientSearchBoxInitializerProps> = (props) => {
    const [contactList, setContactList] = useState<IPeoplePickerObject[]>();

    useEffect(() => {
        if(props.value) {
            setContactList(getContactList([props.value]));
        }
    }, [props.value]);

    useEffect(() => {
        if(props.contacts) {
            setContactList(getContactList(props.contacts));
        }
    }, [props.contacts])

    const getContactList = (contacts: IContactDto[]) => {
        let isCurrentValueInContactList = false;
        let newContactList = contacts?.map(user => {

            if(user.contactId == props.value?.contactId) {
                isCurrentValueInContactList = true;
            }

            return {
                persona: {
                    imageUrl: user.profileImage,
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: user.email,
                    className: "c-dropdown-option"
                },
                id: user.contactId,
                data: { user: user }
            } as IPeoplePickerObject
        });

        // currently selected contact needs to be in the list in order to display in the box
        if(!isCurrentValueInContactList && props.value) {
            newContactList.push(getCurrentValueAsPeoplePickerItem());
        }

        return newContactList;
    }

    const onContactSelected = (item: IPeoplePickerObject) => {
        props.onSelect(item?.data?.user);
    }

    const getCurrentValueAsPeoplePickerItem = () => {
        return {
            persona: {
                imageUrl: props.value?.profileImage,
                text: `${props.value?.firstName} ${props.value?.lastName}`,
                secondaryText: props.value?.email,
                className: "c-dropdown-option"
            },
            id: props.value?.contactId,
            data: { user: props.value }
        } as IPeoplePickerObject
    }

    const onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
        return new Promise<IPersonaProps[]>((resolve, reject) => {
            props.fetchContacts(filterText, filterText, undefined, undefined, true).then((payload: IContactDto[]) => {
                let personas: IPersonaProps[] = payload.map(contact => {
                    return {
                        imageUrl: contact.profileImage,
                        text: `${contact.firstName} ${contact.lastName}`,
                        secondaryText: contact.email,
                        className: "c-dropdown-option"
                    }
                });

                if(limitResults) {
                    resolve(personas.slice(0, limitResults));
                }
                else {
                    resolve(personas);
                }
            }).catch(() => {
                reject();
            });
        });
    }

    const suggestionProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: localizedStrings.CreateEditAppointmentModal.noSearchResults,
      };

    return (
        <BasePeoplePicker
            options={contactList}
            onSelect={onContactSelected}
            onBlur={props.onBlur}
            value={props.value?.contactId}
            onFilterChanged={onFilterChanged}
            limitResults={SearchBoxResultsLimit}
            className={"c-client-search"}
            disabled={props.disabled}
            pickerSuggestions={suggestionProps}
        />
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IClientSearchBoxProvidedProps): Partial<IClientSearchBoxInitializerOwnProps> {
    return {
        ...providedProps,
        contacts: AppointmentModalSelectors.getResources(state)?.contacts,
        enableScheduleAutoAssign: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableScheduleAutoAssignment", false),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContacts: AppointmentModalActions.fetchContacts
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const ClientSearchBox = connector(ClientSearchBoxInitializer)
