import { produce } from "immer";

import { IAppointmentModalViewState } from "../Contracts";
import { AppointmentModalActionsType, AppointmentModalActionTypes } from "./AppointmentModalActions";
import {
    AppointmentsView,
    AppointmentModel,
    IAppointmentsView,
    IAppointmentCustomResponseDto
} from "../../../../contracts/swagger/_generated";

export function appointmentModalReducer(state: IAppointmentModalViewState, action: AppointmentModalActionsType): IAppointmentModalViewState {
    return produce(state || ({} as IAppointmentModalViewState), draft => {
        switch (action.type) {
            case AppointmentModalActionTypes.InitAppointmentModalView: {
                draft.data = {
                    resources: {},
                };

                draft.ux = {
                    messages: {}
                };
                break;
            }
    
            case AppointmentModalActionTypes.CreateAppointmentSuccess: {
                draft.ux.isLoading = false;
                break;
            }

            case AppointmentModalActionTypes.FetchAppointmentTypesSuccess: {
                draft.data.resources.appointmentTypes = action.payload;
                break;
            }

            case AppointmentModalActionTypes.UpdateAppointmentSuccess: {
                draft.ux.isLoading = false;
                break;
            }

            case AppointmentModalActionTypes.FetchContactsSuccess: {
                draft.data.resources.contacts = action.payload;
                break;
            }

            case AppointmentModalActionTypes.FetchDynamicsCasesFailure:
            case AppointmentModalActionTypes.FetchContactsFailure:
            case AppointmentModalActionTypes.FetchAppointmentTimeslotsFailure:
            case AppointmentModalActionTypes.CreateAppointmentFailure:
            case AppointmentModalActionTypes.FetchAppointmentCategoriesFailure:
            case AppointmentModalActionTypes.UpdateAppointmentFailure:
            case AppointmentModalActionTypes.FetchAppointmentCustomResponsesFailure:
            case AppointmentModalActionTypes.FetchAppointmentTypesFailure: {
                draft.ux.messages.errorMessage = action.payload;
                draft.ux.isLoading = false;
                break;
            }

            case AppointmentModalActionTypes.FetchAppointmentCategoriesSuccess: {
                draft.data.resources.appointmentCategories = action.payload;
                break;
            }

            case AppointmentModalActionTypes.FetchAppointmentTimeslotsSuccess: {
                draft.data.resources.appointmentTimeslots = action.payload;
                break;
            }

            case AppointmentModalActionTypes.FetchAppointmentCustomResponsesSuccess: {
                let customQuestions = action.payload;

                let customResponse = [] as IAppointmentCustomResponseDto[];
                customQuestions.forEach((item: IAppointmentCustomResponseDto) => {
                    let newResponse = {} as IAppointmentCustomResponseDto;
                    newResponse.customQuestionId = item.customQuestionId;
                    newResponse.questionText = item.questionText;
                    newResponse.isRequired = item.isRequired;
                    newResponse.choices = item.choices;
                    newResponse.responseType = item.responseType;
                    newResponse.response = item.response;
                    customResponse.push(newResponse);
                });

                draft.data.createAppointmentResponses = customResponse;
                break;
            }

            case AppointmentModalActionTypes.ClearAppointmentCustomResponses: {
                draft.data.createAppointmentResponses = [];
                break;
            }

            case AppointmentModalActionTypes.FetchDynamicsCasesSuccess: {
                draft.data.resources.dynamicsCases = action.payload;
                break;
            }

            // TODO: figure out this mess once we want to support upsert
            // case AppointmentModalActionTypes.ReceivedAppointmentUpsertMessage: {
            //     const updatedAppointmentModel: AppointmentModel = action.payload;

            //     if(!updatedAppointmentModel || !updatedAppointmentModel.appointmentDetails || !draft.selectedStore
            //         || !draft.selectedStartDate || !draft.selectedEndDate) {
            //         break;
            //     }

            //     if(draft.selectedStore != updatedAppointmentModel.appointmentDetails.storeNumber) {
            //         break;
            //     }

            //     if(moment(draft.selectedStartDate).isAfter(updatedAppointmentModel.appointmentDetails.appointmentDate)  
            //         || moment(draft.selectedEndDate).isBefore(updatedAppointmentModel.appointmentDetails.appointmentDate)) {
            //         break;
            //     }

            //     let shouldAddNull = false;
            //     let appointmentModels: AppointmentModel[] = [];

            //     for(var appointmentModel of draft.appointments.appointments) {
            //         if(!appointmentModel?.appointmentDetails) {
            //             shouldAddNull = true;
            //             continue;
            //         }

            //         if(appointmentModel.appointmentDetails.appointmentId == updatedAppointmentModel.appointmentDetails.appointmentId)
            //         {
            //             appointmentModel = updatedAppointmentModel;
            //         }
                    
            //         appointmentModels.push(appointmentModel);
            //     }

            //     if(updatedAppointmentModel.operationType == "Create") {
            //         appointmentModels.push(updatedAppointmentModel);
            //     }

            //     appointmentModels = appointmentModels.slice().sort( (a, b) => {
            //         let aDate = moment(a?.appointmentDetails?.appointmentDate);
            //         let bDate = moment(b?.appointmentDetails?.appointmentDate);
            //         let ret = aDate.diff(bDate);
            //         return ret;
            //     });

            //     if(shouldAddNull) {
            //         appointmentModels.push(null);
            //     }

            //     let data: IAppointmentsView = {
            //         appointments: appointmentModels,
            //         errorMessage: draft.appointments.errorMessage,
            //         paging: draft.appointments.paging
            //     };

            //     draft.appointments = new AppointmentsView(data);
            //     break;
            // }

            default:
                return state;
        }
    });
}

function _addLoadMoreRow(appointmentsData: AppointmentsView): AppointmentModel[] {
    let mergedAppointments: AppointmentModel[] = appointmentsData.appointments;
    if (appointmentsData.paging && appointmentsData.paging.totalPages > appointmentsData.paging.currentPage) {
        mergedAppointments.push(null); //Indication to render more rows
    }
    return mergedAppointments;
}

function _mergeAppointments(currentAppointments: IAppointmentsView, appointmentsData: AppointmentsView): AppointmentModel[] {
    let mergedAppointments: AppointmentModel[] = [];
    if (currentAppointments && currentAppointments.appointments) {
        mergedAppointments = currentAppointments.appointments
            .slice(0, currentAppointments.appointments.length - 1) //Remove loading row from last page
            .concat(appointmentsData.appointments);
    } else {
        mergedAppointments = mergedAppointments.concat(appointmentsData.appointments);
    }

    if (appointmentsData.paging && appointmentsData.paging.totalPages > appointmentsData.paging.currentPage) {
        mergedAppointments.push(null); //Indication to render more rows
    }

    return mergedAppointments;
}
