import React, {FC, useEffect} from "react";
import { connect } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import { ViewType } from "../../common/Constants";
import { IRapComponentProperties } from "../../platform/Layout";
import { AppointmentGrid } from "./components/AppointmentGrid/AppointmentGrid";
import { ConnectedAppointmentList } from "./components/AppointmentList/AppointmentList";
import { Error } from "../../common/components/Error/Error";
import { AppointmentCountDto, AppointmentModel, UserInfo } from "../../contracts/swagger/_generated";
import { IClientelingViewState } from "../../pages/Contracts";
import { ConnectedClientelingCommandBar } from "../../platform/components/ClientelingCommandBar/ClientelingCommandBar";
import { IViewOptions, IPanels } from "../../platform/components/Page/Contracts";
import { PageActions } from "../../platform/components/Page/redux/PageActions";
import { PanelHandler } from "../../platform/components/PanelHandler/PanelHandler";
import { ModalHandler } from "../../platform/components/ModalHandler/ModalHandler";
import { AppointmentStateFilterComponent } from "../../platform/components/PanelHandler/components/AppointmentStateFilter/AppointmentStateFilter";
import { ToastHandler } from "../../platform/components/ToastHandler/ToastHandler";
import { ReservationsActions } from "./redux/ReservationsActions";
import { ErrorHandler } from "../../platform/components/ErrorHandler/ErrorHandler";
import { ErrorBoundaryFallback } from "../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";
import * as ReservationsSelectors from "./redux/ReservationsSelectors";

import "./ReservationsView.scss";

//Props passed by parent component
interface IReservationsViewProvidedProps extends IRapComponentProperties {
}

//Props mapped from state object
interface IReservationsViewInitializerOwnProps extends IReservationsViewProvidedProps {
    appointments: AppointmentModel[];
    appointmentCount: AppointmentCountDto[];
    isLoading: boolean;
    viewOptions: IViewOptions;
    panelState: IPanels;
    pageDataErrorMessage: string;
    userInfo: UserInfo;
    errorMessage: string;
    noResultsMessage: string;
}

// eslint-disable-next-line
export type IReservationsViewInitializerProps = IReservationsViewInitializerOwnProps & typeof ActionsToDispatch;

const ReservationsViewInitializer: FC<IReservationsViewInitializerProps> = (props) => {

    useEffect(() => {
        props.fetchAppointmentTypes(props.viewOptions?.selectedStore);
    }, [props.viewOptions?.selectedStore]);

    useEffect(() => {
        props.fetchAppointmentsData();
    }, [props.viewOptions?.selectedStore, props.viewOptions?.selectedDate, props.viewOptions?.selectedAppointmentTypes,
        props.viewOptions?.selectedAssignStatus, props.viewOptions?.selectedAppointmentState]);

    useEffect(() => {
        props.fetchAppointmentCountByState();
    }, [props.viewOptions?.selectedStore, props.viewOptions?.selectedDate, props.viewOptions?.selectedAppointmentTypes,
    props.viewOptions?.selectedAssignStatus, props.viewOptions?.selectedAppointmentState]);

    const _onSelectedDateChanged = (newDate: any) => {
        props.updateViewOptions({ selectedDate: newDate });
    };

    const handleErrors = () => {
        if(props.pageDataErrorMessage && props.pageDataErrorMessage.length > 0) {
            return <Error description={props.pageDataErrorMessage} />
        }
    }

    const getContent = () => {
        return (
            <>
                <ConnectedClientelingCommandBar
                    commandBarItems={null}
                    onSelectedDateChanged={_onSelectedDateChanged} 
                />
                <ErrorHandler errorMessage={props.errorMessage} onReset={() => props.setMessages({ errorMessage: undefined })}/>
                <div className="is-flex-state-filter">
                    {props.panelState?.displayAppointmentStateFilter ? (
                        <div className="flex-column">
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <AppointmentStateFilterComponent appointmentCount={props.appointmentCount} />
                            </ErrorBoundary>
                        </div>
                    ) : null}
                    
                    <div className="flex-column flex c-appointment-container">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            {props.viewOptions?.selectedView === ViewType.Card ?
                                <AppointmentGrid appointments={props.appointments} isLoading={props.isLoading} noResultsMessage={props.noResultsMessage} /> :
                                <ConnectedAppointmentList appointments={props.appointments} isLoading={props.isLoading} noResultsMessage={props.noResultsMessage} />
                            }
                        </ErrorBoundary>
                        <ModalHandler/>
                        <PanelHandler/>
                        <ToastHandler/>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {handleErrors()}
            {getContent()}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IReservationsViewProvidedProps): Partial<IReservationsViewInitializerOwnProps> {
    return {
        ...providedProps,
        viewOptions: PageSelectors.getViewOptions(state),
        panelState: PageSelectors.getPanels(state),
        pageDataErrorMessage: PageSelectors.getPageData(state)?.pageDataError?.message,
        userInfo: PageSelectors.getPageData(state)?.userInfo,
        noResultsMessage: ReservationsSelectors.getMessages(state)?.noResultsMessage,
        errorMessage: ReservationsSelectors.getMessages(state)?.errorMessage,
        appointments: ReservationsSelectors.getAppointmentsData(state)?.appointments,
        appointmentCount: ReservationsSelectors.getAppointmentCountByState(state),
        isLoading: ReservationsSelectors.getIsLoading(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAppointmentsData: ReservationsActions.fetchAppointmentsData,
    fetchAppointmentCountByState: ReservationsActions.fetchAppointmentCountByState,
    fetchAppointmentTypes: ReservationsActions.fetchAppointmentTypes,
    logTelemetry: ReservationsActions.logTelemetry,
    updateViewOptions: PageActions.updateViewOptions,
    initReservationsView: ReservationsActions.initReservationsView,
    setMessages: ReservationsActions.setMessages
};

export const ConnectedReservationsViewInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ReservationsViewInitializer);
