import React, { FC } from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";

export interface ILoadingContainerProps {
    isLoading: boolean;
    spinnerClassName?: string; // styles for spinner
    spinnerSize?: SpinnerSize;
}

// Shows a loading spinner until the isLoading prop is set to false, then it shows the content passed as children
export const LoadingContainer: FC<ILoadingContainerProps> = (props) => {
    
    return (
        <>
            {props.isLoading ? (
                <Spinner size={props.spinnerSize ? props.spinnerSize : SpinnerSize.medium} className={props.spinnerClassName}/>
            ) : (
                <>
                    {props.children}
                </>
            )}
        </>
    );
}