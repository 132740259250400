import { DeepPartial } from "redux";
import { IRapPageContext } from "../../../Context";

export interface IClientelingSagaContext {}

export interface IStoreProviderProps<SagaContext, State> {
    /**
     * Initial state for the store
     */
    initialState: DeepPartial<State>;

    /**
     * Context for the sagas
     */
    sagaContext: SagaContext;
}

export function getClientelingStoreProviderProps(pageContext: IRapPageContext): IStoreProviderProps<IClientelingSagaContext, any> {
    const initialState = {};

    const sagaContext = { pageContext };

    return {
        initialState,
        sagaContext,
    };
}
