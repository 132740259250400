import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../platform/redux/ActionHelper";
import { ContactsFeature } from "../../../common/Constants";
import {
    IContactForCreationDto,
    ITitleBodyRequestJson,
    IContactForUpdateDto,
    EventModel,
    DeviceDto,
    ICustomerInsightsDto,
    ContactDto,
    IAssociate,
    IContactNote,
    IIdResponseDto,
    INoteDto,
    NoteDto,
    IAppointmentDto,
    ICoreInsightsDto,
    IPurchaseHistoryDto,
    ISurveyResponseDto,
    ICSATDto,
    IProductRecommendationsItem,
    ISurveyQuestionDto,
    IStoreAssociateDto
} from "../../../contracts/swagger/_generated";
import { IContactDto } from "../../../contracts/swagger/_generated";
import { IFilterItem } from "../../../common/components/Filter/Filter";
import { IMessages } from "../Contracts";

/**
 * Actions for Contacts view
 */
export enum ContactsActionTypes {
    LogTelemetry = "Contacts/LogTelemetry",

    FetchContactById = "Contacts/FetchContactById",
    FetchContactByIdSuccess = "Contacts/FetchContactByIdSuccess",
    FetchContactByIdFailure = "Contacts/FetchContactByIdFailure",

    CreateContact = "Contacts/CreateContact",
    CreateContactSuccess = "Contacts/CreateContactSuccess",
    CreateContactFailure = "Contacts/CreateContactFailure",

    EditContact = "Contacts/EditContact",
    EditContactSuccess = "Contacts/EditContactSuccess",
    EditContactFailure = "Contacts/EditContactFailure",

    FetchContactDevices = "Contacts/FetchContactDevices",
    FetchContactDevicesSuccess = "Contacts/FetchContactDevicesSuccess",
    FetchContactDevicesFailure = "Contacts/FetchContactDevicesFailure",

    FetchContactCheckedInEvents = "Contacts/FetchContactCheckedInEvents",
    FetchContactCheckedInEventsSuccess = "Contacts/FetchContactCheckedInEventsSuccess",
    FetchContactCheckedInEventsFailure = "Contacts/FetchContactCheckedInEventsFailure",

    FetchContactRegisteredEvents = "Contacts/FetchContactRegisteredEvents",
    FetchContactRegisteredEventsSuccess = "Contacts/FetchContactRegisteredEventsSuccess",
    FetchContactRegisteredEventsFailure = "Contacts/FetchContactRegisteredEventsFailure",

    FetchContactAppointments = "Contacts/FetchContactAppointments",
    FetchContactAppointmentsSuccess = "Contacts/FetchContactAppointmentsSuccess",
    FetchContactAppointmentsFailure = "Contacts/FetchContactAppointmentsFailure",

    FetchContactNotes = "Contacts/FetchContactNotes",
    FetchContactNotesSuccess = "Contacts/FetchContactNotesSuccess",
    FetchContactNotesFailure = "Contacts/FetchContactNotesFailure",

    CreateContactNote = "Contacts/CreateContactNote",
    CreateContactNoteSuccess = "Contacts/CreateContactNoteSuccess",
    CreateContactNoteFailure = "Contacts/CreateContactNoteFailure",

    FetchAssociateData = "Contacts/FetchAssociateData",
    FetchAssociateDataSuccess = "Contacts/FetchAssociateDataSuccess",
    FetchAssociateDataFailure = "Contacts/FetchAssociateDataFailure",

    FetchCustomerInsights = "Contacts/FetchCustomerInsights",
    FetchCustomerInsightsSuccess = "Contacts/FetchCustomerInsightsSuccess",
    FetchCustomerInsightsFailure = "Contacts/FetchCustomerInsightsFailure",

    InitContacts = "Contacts/InitContacts",
    ResetContacts = "Contacts/ResetContacts",

    SearchAssociateData = "Contacts/SearchAssociateData",
    OnAssociateDataSearchClear = "Contacts/OnSearchClear",
    OnAssociateSelected = "Contacts/OnAssociateSelected",

    HideConfirmationMessages = "Contacts/HideConfirmationMessages",
    OnContactSelected = "Contacts/OnContactSelected",
    UpdateGlobalSearchTerm = "Contacts/UpdateGlobalSearchTerm",
    UpdateContactSearchState = "Contacts/UpdateContactSearchState",

    UpdateFirstAndLastName = "Contacts/UpdateContactFirstAndLastName",
    UpdateContactPhone = "Contacts/UpdateContactPhone",
    UpdateContactEmail = "Contacts/UpdateContactEmail",

    ResetGlobalSearch = "Contacts/ResetGlobalSearch",
    OnGlobalSearchTermChange = "Contacts/OnGlobalSearchTermChange",

    SetSelectedAppointmentId = "Contacts/SetSelectedAppointmentId",

    SetFocusedItem = "Contacts/SetFocusedItem",
    UpdateActivityFilters = "Contacts/UpdateActivityFilters",

    DeleteContactNote = "Contacts/DeleteContactNote",
    DeleteContactNoteSuccess = "Contacts/DeleteContactNoteSuccess",
    DeleteContactNoteFailure = "Contacts/DeleteContactNoteFailure",

    EditContactNote = "Contacts/EditContactNote",
    EditContactNoteSuccess = "Contacts/EditContactNoteSuccess",
    EditContactNoteFailure = "Contacts/EditContactNoteFailure",

    SetMessages = "Contacts/SetMessages",

    FetchContactInsights = "Contacts/FetchContactInsights",
    FetchContactInsightsSuccess = "Contacts/FetchContactInsightsSuccess",
    FetchContactInsightsFailure = "Contacts/FetchContactInsightsFailure",

    FetchContactCoreInsights = "Contacts/FetchContactCoreInsights",
    FetchContactCoreInsightsSuccess = "Contacts/FetchContactCoreInsightsSuccess",
    FetchContactCoreInsightsFailure = "Contacts/FetchContactCoreInsightsFailure",

    FetchContactPurchaseHistory = "Contacts/FetchContactPurchaseHistory",
    FetchContactPurchaseHistorySuccess = "Contacts/FetchContactPurchaseHistorySuccess",
    FetchContactPurchaseHistoryFailure = "Contacts/FetchContactPurchaseHistoryFailure",

    FetchContactSurveys = "Contacts/FetchContactSurveys",
    FetchContactSurveysSuccess = "Contacts/FetchContactSurveysSuccess",
    FetchContactSurveysFailure = "Contacts/FetchContactSurveysFailure",

    FetchContactCSATScores = "Contacts/FetchContactCSATScores",
    FetchContactCSATScoresSuccess = "Contacts/FetchContactCSATScoresSuccess",
    FetchContactCSATScoresFailure = "Contacts/FetchContactCSATScoresFailure",

    FetchProductRecommendations = "Contacts/FetchProductRecommendations",
    FetchProductRecommendationsSuccess = "Contacts/FetchProductRecommendationsSuccess",
    FetchProductRecommendationsFailure = "Contacts/FetchProductRecommendationsFailure",

    FetchSurveyQuestion = "Contacts/FetchSurveyQuestion",
    FetchSurveyQuestionSuccess = "Contacts/FetchSurveyQuestionSuccess",
    FetchSurveyQuestionFailure = "Contacts/FetchSurveyQuestionFailure",

    // this one searches for all associates regardless of store
    SearchForAssociate = "Contacts/SearchForAssociate",
    SearchForAssociateSuccess = "Contacts/SearchForAssociateSuccess",
    SearchForAssociateFailure = "Contacts/SearchForAssociateFailure",

    // this one searches for only associates of a certain store
    SearchForStoreAssociate = "Contacts/SearchForStoreAssociate"
}

const ContactsStandardActions = {
    logTelemetry: (feature: ContactsFeature, action: string, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(ContactsActionTypes.LogTelemetry, { feature, action, properties, errorMessage }),

    fetchContactById: (contactId: string) => createAction(ContactsActionTypes.FetchContactById, contactId),
    fetchContactByIdSuccess: (contact: IContactDto) => createAction(ContactsActionTypes.FetchContactByIdSuccess, contact),
    fetchContactByIdFailure: (error: string, notFound?: boolean) => createAction(ContactsActionTypes.FetchContactByIdFailure, { error, notFound }),

    createContactSuccess: (contact: IContactDto) => createAction(ContactsActionTypes.CreateContactSuccess, contact),
    createContactFailure: (errorMessage: string) => createAction(ContactsActionTypes.CreateContactFailure, errorMessage),

    editContact: (contactId: string | undefined, contactData: IContactForUpdateDto) =>
        createAction(ContactsActionTypes.EditContact, { contactId, contactData }),
    editContactSuccess: (contact: ContactDto) => createAction(ContactsActionTypes.EditContactSuccess, contact),
    editContactFailure: (errorMessage: string) => createAction(ContactsActionTypes.EditContactFailure, errorMessage),

    hideConfirmationMessages: () => createAction(ContactsActionTypes.HideConfirmationMessages),

    fetchContactNotesSuccess: (contactNotes: IContactNote[]) => createAction(ContactsActionTypes.FetchContactNotesSuccess, contactNotes),
    fetchContactNotesFailure: (errorMessage: string) => createAction(ContactsActionTypes.FetchContactNotesFailure, errorMessage),

    createContactNoteSuccess: (noteId: string) => createAction(ContactsActionTypes.CreateContactNoteSuccess, noteId),
    createContactNoteFailure: (errorMessage: string) => createAction(ContactsActionTypes.CreateContactNoteFailure, errorMessage),

    onContactSelected: (contact: IContactDto) => createAction(ContactsActionTypes.OnContactSelected, contact),
    updateGlobalSearchTerm: (searchTerm: string) => createAction(ContactsActionTypes.UpdateGlobalSearchTerm, searchTerm),

    fetchAssociateData: (storeNumber: number) => createAction(ContactsActionTypes.FetchAssociateData, { storeNumber }),
    fetchAssociateDataSuccess: (contactsData: IAssociate[]) => createAction(ContactsActionTypes.FetchAssociateDataSuccess, contactsData),
    fetchAssociateDataFailure: (error: string) => createAction(ContactsActionTypes.FetchAssociateDataFailure, error),

    fetchCustomerInsights: (email: string) => createAction(ContactsActionTypes.FetchCustomerInsights, email),
    fetchCustomerInsightsSuccess: (customerInsights: ICustomerInsightsDto) =>
        createAction(ContactsActionTypes.FetchCustomerInsightsSuccess, customerInsights),
    fetchCustomerInsightsFailure: (errorMessage: string) => createAction(ContactsActionTypes.FetchCustomerInsightsFailure, errorMessage),

    initContacts: () => createAction(ContactsActionTypes.InitContacts),
    resetContacts: () => createAction(ContactsActionTypes.ResetContacts),

    searchAssociateData: (newFilterValue: string) => createAction(ContactsActionTypes.SearchAssociateData, { newFilterValue }),
    onAssociateDataSearchClear: () => createAction(ContactsActionTypes.OnAssociateDataSearchClear),
    onAssociateSelected: (selectedContact: IContactDto) => createAction(ContactsActionTypes.OnAssociateSelected, selectedContact),

    updateFirstAndLastName: (result: boolean) => createAction(ContactsActionTypes.UpdateFirstAndLastName, result),
    updateContactEmail: (result: boolean) => createAction(ContactsActionTypes.UpdateContactEmail, result),
    updateContactPhone: (result: boolean) => createAction(ContactsActionTypes.UpdateContactPhone, result),

    resetGlobalSearch: () => createAction(ContactsActionTypes.ResetGlobalSearch),

    onGlobalSearchTermChange: (searchTerm: string) => createAction(ContactsActionTypes.OnGlobalSearchTermChange, searchTerm),

    fetchContactCheckedInEvents: (contactId: string) => createAction(ContactsActionTypes.FetchContactCheckedInEvents, contactId),
    fetchContactCheckedInEventsSuccess: (contactCheckedInEvents: EventModel[]) =>
        createAction(ContactsActionTypes.FetchContactCheckedInEventsSuccess, contactCheckedInEvents),
    fetchContactCheckedInEventsFailure: (errorMessage: string) => createAction(ContactsActionTypes.FetchContactCheckedInEventsFailure, errorMessage),

    fetchContactRegisteredEvents: (contactId: string) => createAction(ContactsActionTypes.FetchContactRegisteredEvents, contactId),
    fetchContactRegisteredEventsSuccess: (contactRegisteredEvents: EventModel[]) =>
        createAction(ContactsActionTypes.FetchContactRegisteredEventsSuccess, contactRegisteredEvents),
    fetchContactRegisteredEventsFailure: (errorMessage: string) =>
        createAction(ContactsActionTypes.FetchContactRegisteredEventsFailure, errorMessage),

    fetchContactAppointmentsSuccess: (contactAppointments: IAppointmentDto[], appendList?: boolean) =>
        createAction(ContactsActionTypes.FetchContactAppointmentsSuccess, { contactAppointments, appendList }),
    fetchContactAppointmentsFailure: (errorMessage: string) => createAction(ContactsActionTypes.FetchContactAppointmentsFailure, errorMessage),

    fetchContactDevices: (contactId: string) => createAction(ContactsActionTypes.FetchContactDevices, contactId),
    fetchContactDevicesSuccess: (contactDevices: DeviceDto[]) => createAction(ContactsActionTypes.FetchContactDevicesSuccess, contactDevices),
    fetchContactDevicesFailure: (errorMessage: string) => createAction(ContactsActionTypes.FetchContactDevicesFailure, errorMessage),

    setSelectedAppointmentId: (appointmentId: string) => createAction(ContactsActionTypes.SetSelectedAppointmentId, appointmentId),

    setFocusedItem: (focusedItem: string) => createAction(ContactsActionTypes.SetFocusedItem, focusedItem),
    updateActivityFilters: (filters: IFilterItem[]) => createAction(ContactsActionTypes.UpdateActivityFilters, filters),

    deleteContactNoteSuccess: (resp: IIdResponseDto) => createAction(ContactsActionTypes.DeleteContactNoteSuccess, resp),
    deleteContactNoteFailure: (error: string) => createAction(ContactsActionTypes.DeleteContactNoteFailure, error),

    editContactNoteSuccess: (note: INoteDto) => createAction(ContactsActionTypes.EditContactNoteSuccess, note),
    editContactNoteFailure: (error: string) => createAction(ContactsActionTypes.EditContactNoteFailure, error),

    setMessages: (messages: IMessages) => createAction(ContactsActionTypes.SetMessages, messages),

    fetchContactInsightsSuccess: (insights?: ICustomerInsightsDto) => createAction(ContactsActionTypes.FetchContactInsightsSuccess, insights),
    fetchContactInsightsFailure: (error: string) => createAction(ContactsActionTypes.FetchContactInsightsFailure, error),

    fetchContactCoreInsightsSuccess: (insights?: ICoreInsightsDto) => createAction(ContactsActionTypes.FetchContactCoreInsightsSuccess, insights),
    fetchContactCoreInsightsFailure: (error: string) => createAction(ContactsActionTypes.FetchContactCoreInsightsFailure, error),

    fetchContactPurchaseHistorySuccess: (history: IPurchaseHistoryDto[]) =>
        createAction(ContactsActionTypes.FetchContactPurchaseHistorySuccess, history),
    fetchContactPurchaseHistoryFailure: (error: string) => createAction(ContactsActionTypes.FetchContactPurchaseHistoryFailure, error),

    fetchContactSurveySuccess: (surveys?: ISurveyResponseDto[]) => createAction(ContactsActionTypes.FetchContactSurveysSuccess, surveys),
    fetchContactSurveyFailure: (error: string) => createAction(ContactsActionTypes.FetchContactSurveysFailure, error),

    fetchContactCSATScoresSuccess: (scores?: ICSATDto) => createAction(ContactsActionTypes.FetchContactCSATScoresSuccess, scores),
    fetchContactCSATScoresFailure: (error: string) => createAction(ContactsActionTypes.FetchContactCSATScoresFailure, error),

    fetchProductRecommendationsSuccess: (items: IProductRecommendationsItem[]) =>
        createAction(ContactsActionTypes.FetchProductRecommendationsSuccess, items),
    fetchProductRecommendationsFailure: (error: string) => createAction(ContactsActionTypes.FetchProductRecommendationsFailure, error),

    fetchSurveyQuestionSuccess: (question: ISurveyQuestionDto) => createAction(ContactsActionTypes.FetchSurveyQuestionSuccess, question),
    fetchSurveyQuestionFailure: (error: string) => createAction(ContactsActionTypes.FetchSurveyQuestionFailure, error),

    searchForAssociateSuccess: (associates: IAssociate[]) => createAction(ContactsActionTypes.SearchForAssociateSuccess, associates),
    searchForAssociateFailure: (error: string) => createAction(ContactsActionTypes.SearchForAssociateFailure, error)
};

const ContactsThunkActions = {
    createContact: (contactData: IContactForCreationDto) => createActionWithCallback<IContactDto>(ContactsActionTypes.CreateContact, contactData),
    fetchContactNotes: (contactId: string, silentFetch?: boolean) =>
        createActionWithCallback<IContactNote[]>(ContactsActionTypes.FetchContactNotes, { contactId, silentFetch }),
    createContactNote: (contactId: string, note: ITitleBodyRequestJson) =>
        createActionWithCallback<NoteDto>(ContactsActionTypes.CreateContactNote, { contactId, note }),
    fetchContactAppointments: (contactId: string, pageNumber?: number, appendList?: boolean) =>
        createActionWithCallback<IAppointmentDto[]>(ContactsActionTypes.FetchContactAppointments, { contactId, pageNumber, appendList }),
    deleteContactNote: (contactId: string, noteId: string) =>
        createActionWithCallback<IIdResponseDto>(ContactsActionTypes.DeleteContactNote, { contactId, noteId }),
    editContactNote: (contactId: string, noteId: string, note: ITitleBodyRequestJson) =>
        createActionWithCallback<INoteDto>(ContactsActionTypes.EditContactNote, { contactId, noteId, note }),
    editContact: (contactId: string | undefined, contactData: IContactForUpdateDto) =>
        createActionWithCallback<IContactDto>(ContactsActionTypes.EditContact, { contactId, contactData }),

    fetchContactInsights: (contactEmail: string) =>
        createActionWithCallback<ICustomerInsightsDto>(ContactsActionTypes.FetchContactInsights, contactEmail),
    fetchContactCoreInsights: (contactEmail: string) =>
        createActionWithCallback<ICoreInsightsDto>(ContactsActionTypes.FetchContactCoreInsights, contactEmail),
    fetchContactPurchaseHistory: (contactEmail: string) =>
        createActionWithCallback<IPurchaseHistoryDto[]>(ContactsActionTypes.FetchContactPurchaseHistory, contactEmail),

    fetchContactSurveys: (contactId: string) => createActionWithCallback<ISurveyResponseDto[]>(ContactsActionTypes.FetchContactSurveys, contactId),
    fetchContactCSATScores: (contactId: string) => createActionWithCallback<ICSATDto>(ContactsActionTypes.FetchContactCSATScores, contactId),

    fetchProductRecommendations: (email: string, count: number, getPopular: boolean) =>
        createActionWithCallback<IProductRecommendationsItem[]>(ContactsActionTypes.FetchProductRecommendations, { email, count, getPopular }),

    fetchSurveyQuestion: (questionId: string) => createActionWithCallback<ISurveyQuestionDto>(ContactsActionTypes.FetchSurveyQuestion, questionId),

    searchForAssociate: (searchString: string) => createActionWithCallback<IAssociate[]>(ContactsActionTypes.SearchForAssociate, searchString),
    searchForStoreAssociate: (searchString: string, storeNumber: number) =>
        createActionWithCallback<IAssociate[]>(ContactsActionTypes.SearchForStoreAssociate, { searchString, storeNumber })
};

const ContactsThunkActionsTypes = {
    createContact: (contactData: IContactForCreationDto) => createThunkActionType(ContactsActionTypes.CreateContact, contactData),
    fetchContactNotes: (contactId: string, silentFetch?: boolean) =>
        createThunkActionType(ContactsActionTypes.FetchContactNotes, { contactId, silentFetch }),
    createContactNote: (contactId: string, note: ITitleBodyRequestJson) =>
        createThunkActionType(ContactsActionTypes.CreateContactNote, { contactId, note }),

    fetchContactAppointments: (contactId: string, pageNumber?: number, appendList?: boolean) =>
        createThunkActionType(ContactsActionTypes.FetchContactAppointments, { contactId, pageNumber, appendList }),
    deleteContactNote: (contactId: string, noteId: string) => createThunkActionType(ContactsActionTypes.DeleteContactNote, { contactId, noteId }),
    editContactNote: (contactId: string, noteId: string, note: ITitleBodyRequestJson) =>
        createThunkActionType(ContactsActionTypes.EditContactNote, { contactId, noteId, note }),
    editContact: (contactId: string | undefined, contactData: IContactForUpdateDto) =>
        createThunkActionType(ContactsActionTypes.EditContact, { contactId, contactData }),

    fetchContactInsights: (contactEmail: string) => createThunkActionType(ContactsActionTypes.FetchContactInsights, contactEmail),
    fetchContactCoreInsights: (contactEmail: string) => createThunkActionType(ContactsActionTypes.FetchContactCoreInsights, contactEmail),
    fetchContactPurchaseHistory: (contactEmail: string) => createThunkActionType(ContactsActionTypes.FetchContactPurchaseHistory, contactEmail),

    fetchContactSurveys: (contactId: string) => createThunkActionType(ContactsActionTypes.FetchContactSurveys, contactId),
    fetchContactCSATScores: (contactId: string) => createThunkActionType(ContactsActionTypes.FetchContactCSATScores, contactId),

    fetchProductRecommendations: (email: string, count: number, getPopular: boolean) =>
        createThunkActionType(ContactsActionTypes.FetchProductRecommendations, { email, count, getPopular }),

    fetchSurveyQuestion: (questionId: string) => createThunkActionType(ContactsActionTypes.FetchSurveyQuestion, questionId),

    searchForAssociate: (searchString: string) => createThunkActionType(ContactsActionTypes.SearchForAssociate, searchString),
    searchForStoreAssociate: (searchString: string, storeNumber: number) =>
        createThunkActionType(ContactsActionTypes.SearchForStoreAssociate, { searchString, storeNumber })
};
export const ContactsActions = { ...ContactsStandardActions, ...ContactsThunkActions };
const AllActionsTypes = { ...ContactsStandardActions, ...ContactsThunkActionsTypes };
export type ContactsActionsType = ActionsUnion<typeof AllActionsTypes>;
