import { produce } from "immer";

import { IContactsViewState } from "../Contracts";
import { ContactsActionsType, ContactsActionTypes } from "./ContactsActions";
import { IAppointmentDto, IDeviceDto, IEventModel, INoteDto } from "../../../contracts/swagger/_generated";
import { ContactActivitiesUtils } from "../components/ContactActivities/ContactActivitiesUtils";
import { IFilterItem } from "../../../common/components/Filter/Filter";
import { ActivityType, IActivityDto } from "../../../contracts/swagger/_generated";

const Resources = require("./../components/Resources.json");
let contactActivitiesUtils: ContactActivitiesUtils = new ContactActivitiesUtils();

export function contactsReducer(state: IContactsViewState, action: ContactsActionsType): IContactsViewState {
    return produce(state || ({} as IContactsViewState), draft => {
        switch (action.type) {
            case ContactsActionTypes.InitContacts: {
                draft.data = {
                    selectedContactInfo: {},
                    selectedAppointmentId: null,
                    customerInsightsData: {}
                };
                draft.ux = {
                    isLoading: false,
                    messages: {}
                };
                break;
            }
            case ContactsActionTypes.FetchContactById: {
                draft.ux.isLoading = true;
                draft.data.selectedContactInfo = {};
                draft.data.customerInsightsData.surveys = undefined;
                break;
            }
            case ContactsActionTypes.FetchContactByIdSuccess: {
                draft.ux.isLoading = false;
                draft.data.selectedContactInfo.selectedContact = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactInsightsSuccess: {
                draft.data.customerInsightsData.legacyCustomerInsights = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactCoreInsightsSuccess: {
                draft.data.customerInsightsData.coreInsights = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactPurchaseHistorySuccess: {
                draft.data.customerInsightsData.purchaseHistory = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactByIdFailure: {
                const { error, notFound } = action.payload;
                draft.ux.isLoading = false;

                if (notFound) {
                    draft.data.selectedContactInfo.selectedContact = null;
                } else {
                    draft.ux.messages.errorMessage = error;
                }
                break;
            }
            case ContactsActionTypes.DeleteContactNoteSuccess: {
                let newNotes = state.data.selectedContactInfo.contactNotes.filter(n => n.noteId != action.payload.id);
                draft.data.selectedContactInfo.contactNotes = newNotes;
                break;
            }
            case ContactsActionTypes.FetchContactNotes: {
                if (!action.payload.silentFetch) {
                    draft.data.selectedContactInfo.contactNotes = undefined;
                }
                break;
            }
            case ContactsActionTypes.FetchContactNotesSuccess: {
                draft.data.selectedContactInfo.contactNotes = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactAppointments: {
                const { appendList} = action.payload;
                if(!appendList) {
                    draft.data.selectedContactInfo.contactAppointments = undefined;
                }
                break;
            }
            case ContactsActionTypes.FetchContactAppointmentsSuccess: {
                const { contactAppointments, appendList} = action.payload;
                if(appendList) {
                    let existingAppointments = state.data?.selectedContactInfo?.contactAppointments;
                    draft.data.selectedContactInfo.contactAppointments = existingAppointments.concat(contactAppointments);;
                }
                else {
                    draft.data.selectedContactInfo.contactAppointments = contactAppointments;
                }
                break;
            }
            case ContactsActionTypes.FetchAssociateDataSuccess: {
                draft.data.storeAssociates = action.payload;
                break;
            }
            case ContactsActionTypes.SearchForAssociate: {
                draft.data.storeAssociates = undefined;
                break;
            }
            case ContactsActionTypes.SearchForAssociateSuccess: {
                draft.data.storeAssociates = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactSurveys: {
                draft.data.customerInsightsData.surveys = undefined;
                break;
            }
            case ContactsActionTypes.FetchContactSurveysSuccess: {
                draft.data.customerInsightsData.surveys = action.payload;
                break;
            }
            case ContactsActionTypes.FetchContactCSATScores: {
                draft.data.customerInsightsData.csatScores = undefined;
                break;
            }
            case ContactsActionTypes.FetchContactCSATScoresSuccess: {
                draft.data.customerInsightsData.csatScores = action.payload;
                break;
            }
            case ContactsActionTypes.FetchSurveyQuestionFailure:
            case ContactsActionTypes.FetchContactCSATScoresFailure:
            case ContactsActionTypes.FetchContactSurveysFailure:
            case ContactsActionTypes.FetchContactPurchaseHistoryFailure:
            case ContactsActionTypes.EditContactNoteFailure:
            case ContactsActionTypes.DeleteContactNoteFailure:
            case ContactsActionTypes.FetchContactNotesFailure:
            case ContactsActionTypes.FetchContactAppointmentsFailure:
            case ContactsActionTypes.FetchAssociateDataFailure: {
                draft.ux.messages.errorMessage = action.payload;
                draft.ux.isLoading = false;
                break;
            }
            case ContactsActionTypes.SetSelectedAppointmentId: {
                draft.data.selectedAppointmentId = action.payload;
                break;
            }
            case ContactsActionTypes.SetMessages: {
                draft.ux.messages = { ...state.ux.messages, ...action.payload };
                break;
            }
            default:
                return state;
        }
    });
}

function createActivitiesList(
    devices: IDeviceDto[],
    events: IEventModel[],
    appointments: IAppointmentDto[],
    notes: INoteDto[],
    incidents: IActivityDto[],
    invoices: IActivityDto[],
    invoiceDetails: IActivityDto[],
    equipments: IActivityDto[]
): IActivityDto[] {
    let contactActivitiesUtils: ContactActivitiesUtils = new ContactActivitiesUtils();
    let activities: IActivityDto[] = [];
    if (devices) {
        devices.map((device: IDeviceDto) => {
            activities.push(contactActivitiesUtils.createDeviceActivitiesItem(device));
        });
    }
    if (equipments) {
        equipments.map((equipment: IActivityDto) => {
            activities.push(contactActivitiesUtils.createEquipmentPurchasedActivitiesItem(equipment));
        });
    }
    if (events) {
        events.map((event: IEventModel) => {
            activities.push(contactActivitiesUtils.createEventActivitiesItem(event));
        });
    }

    if (appointments) {
        appointments.map((appointment: IAppointmentDto) => {
            activities.push(contactActivitiesUtils.createAppointmentActivitiesItem(appointment));
        });
    }

    if (notes) {
        notes.map((note: INoteDto) => {
            activities.push(contactActivitiesUtils.createNoteActivitiesItem(note));
        });
    }

    if (incidents) {
        incidents.map((incident: IActivityDto) => {
            activities.push(contactActivitiesUtils.createIncidentActivitiesItem(incident));
        });
    }

    if (invoices) {
        invoices.map((invoice: IActivityDto) => {
            activities.push(contactActivitiesUtils.createInvoiceActivitiesItem(invoice, invoiceDetails));
        });
    }
    return contactActivitiesUtils.sortActivities(activities);
}

function createUpcomingAppointmentActivities(appointments: IAppointmentDto[]): IActivityDto[] {
    return contactActivitiesUtils.createUpcomingAppointments(appointments);
}

function createUpcomingEventActivities(events: IEventModel[]): IActivityDto[] {
    return contactActivitiesUtils.createUpcomingEvents(events);
}

function applyActivityFilters(activities: IActivityDto[], filters: IFilterItem[]): IActivityDto[] {
    let filteredActivities: IActivityDto[] = [];
    let matches = activities.filter(x => filters.filter(y => x === null || (y.key === x.type.toString() && y.isChecked === true)).length > 0);
    matches.forEach(activity => {
        filteredActivities.push(activity);
    });
    return filteredActivities;
}

function createActivitiesFilters(
    currentFilters: IFilterItem[],
    devices: IDeviceDto[],
    events: IEventModel[],
    appointments: IAppointmentDto[],
    notes: INoteDto[],
    incidents: IActivityDto[],
    invoices: IActivityDto[],
    equipments: IActivityDto[]
): IFilterItem[] {
    let filters: IFilterItem[] = [];
    let contactActivitiesUtils: ContactActivitiesUtils = new ContactActivitiesUtils();
    if ((devices && devices.length > 0) || (equipments && equipments.length > 0)) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.Device.toString() && y.isChecked === true).length > 0,
            key: ActivityType.Device.toString(),
            text: Resources.Filter_Devices,
            iconName: contactActivitiesUtils.getIconName(ActivityType.Device),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Devices
        });
    }
    if (events && events.length > 0) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.Event.toString() && y.isChecked === true).length > 0,
            key: ActivityType.Event.toString(),
            text: Resources.Filter_Events,
            iconName: contactActivitiesUtils.getIconName(ActivityType.Event),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Events
        });
    }

    if (appointments && appointments.length > 0) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.Appointment.toString() && y.isChecked === true).length > 0,
            key: ActivityType.Appointment.toString(),
            text: Resources.Filter_Appointments,
            iconName: contactActivitiesUtils.getIconName(ActivityType.Appointment),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Appointments
        });
    }

    if (notes && notes.length > 0) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.Other.toString() && y.isChecked === true).length > 0,
            key: ActivityType.Other.toString(),
            text: Resources.Filter_Notes,
            iconName: contactActivitiesUtils.getIconName(ActivityType.Other),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Notes
        });
    }

    if (incidents && incidents.length > 0) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.Incident.toString() && y.isChecked === true).length > 0,
            key: ActivityType.Incident.toString(),
            text: Resources.Filter_Incidents,
            iconName: contactActivitiesUtils.getIconName(ActivityType.Incident),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Incidents
        });
    }

    if (invoices && invoices.length > 0) {
        filters.push({
            isChecked: currentFilters.filter(y => y.key === ActivityType.InvoiceDetail.toString() && y.isChecked === true).length > 0,
            key: ActivityType.InvoiceDetail.toString(),
            text: Resources.Filter_Invoices,
            iconName: contactActivitiesUtils.getIconName(ActivityType.InvoiceDetail),
            ariaLabel: Resources.Filter_CheckboxAriaLabel_Invoices
        });
    }

    return filters;
}
