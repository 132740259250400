import { format } from "./String";

export function createCookie(cookieName: string, res: string, durationInHours: number) {
    var date = new Date();
    date.setTime(date.getTime() + durationInHours * 60 * 60 * 1000);
    document.cookie = format("{0}={1};expires={2};SameSite=None; Secure", cookieName, res, date.toUTCString());
}

export function getCookie(cookieName: string) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
