import React from "react";
import { connect } from "react-redux";
import { Icon } from "@fluentui/react/lib/Icon";
import { format } from "@fluentui/react/lib/Utilities";
import { PersonaSize } from "@fluentui/react/lib/Persona";
import { ErrorBoundary } from "react-error-boundary";

import { IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { AppointmentUtils } from "../../../../common/AppointmentUtils";
import { ConnectedAppointmentButtons } from "../../../../common/components/AppointmentButtons/AppointmentButtons";
import { UserPersona } from "../../../../common/components/UserPersona/UserPersona";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { AppointmentStatus } from "../../../../common/components/AppointmentStatus/AppointmentStatus";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IAppointmentModel } from "../../../../contracts/swagger/_generated";
import { AssignedTo } from "../AssignedTo/AssignedTo";
import { ErrorBoundaryFallback } from "../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback"

import * as StringUtil from "../../../../platform/core/util/String";

import "./AppointmentCardItem.scss";

export interface IAppointmentCardItemProvidedProps extends IRapComponentProperties {
    appointment: IAppointmentModel;
}

interface IAppointmentCardItemOwnProps extends IAppointmentCardItemProvidedProps {}

export interface IAppointmentCardItemState {}

export type IAppointmentCardItemProps = IAppointmentCardItemOwnProps & typeof ActionsToDispatch;

export class AppointmentCardItem extends RapComponent<IAppointmentCardItemProps, IAppointmentCardItemState> {
    private appointmentUtils = new AppointmentUtils();
    constructor(props: IAppointmentCardItemProps) {
        super(props);
    }

    public render() {
        if (this.props.appointment && this.props.appointment.appointmentDetails) {
            let appointmentType = this.props.appointment.appointmentDetails.appointmentType;
            return (
                <>
                    {this.props.appointment ? (
                        <div className="c-appointment-item flex-row" aria-label={this._generateAppointmentAriaLabel()}>
                            <div className="c-appointment-card flex-column flex-grow">
                                <div className="flex-row">
                                    <div className="c-appointment-icon-col flex-column">
                                        <Icon iconName={this.appointmentUtils.getAppointmentTypeIcon(appointmentType)}></Icon>
                                    </div>
                                    <div className="c-appointment-title flex-column">
                                        {this.appointmentUtils.getAppointmentType(this.props.appointment)}
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column flex-grow c-appointment-tertiary-text">
                                        {this.appointmentUtils.getAppointmentTime(this.props.appointment)}
                                    </div>
                                    <div className="flex-column flex-end">
                                        <AppointmentStatus
                                            statusReason={this.props.appointment?.appointmentDetails?.statusReason}
                                            actualStartDate={this.props.appointment?.appointmentDetails?.actualStartDate}
                                            appointmentDate={this.props.appointment?.appointmentDetails?.appointmentDate}
                                            scheduledEndDate={this.props.appointment?.appointmentDetails?.scheduledEndDate} />
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column flex-grow c-appointment-tertiary-text">
                                        {this.props.appointment?.appointmentDetails?.confirmationNumber}
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column c-appointment-category-label">{localizedStrings.AppointmentCardItem.category}</div>
                                    <div className="c-appointment-category">
                                        {this.appointmentUtils.getAppointmentCategory(this.props.appointment)}
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column c-appointment-label">{localizedStrings.AppointmentCardItem.customer}</div>
                                    <div className="c-appointment-customerName">{this._getCustomer()}</div>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-column c-appointment-label">{localizedStrings.AppointmentCardItem.assignedTo}</div>
                                    <div className="c-appointment-assignedto">
                                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                            <AssignedTo appointment={this.props.appointment}/>
                                        </ErrorBoundary>
                                    </div>
                                </div>
                                <div className="flex-row">
                                    <ConnectedAppointmentButtons appointment={this.props.appointment} />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            );
        }
    }

    private _generateAppointmentAriaLabel = (): string => {
        return format(
            "{0} {1} {2} {3} {4}",
            this.appointmentUtils.getAppointmentType(this.props.appointment),
            this.appointmentUtils.getAppointmentTime(this.props.appointment),
            this.props.appointment?.appointmentDetails?.confirmationNumber,
            this.appointmentUtils.getAppointmentCategory(this.props.appointment),
            this._getCustomer(),
            this._getAssignedTo(),
            this.props.appointment.appointmentDetails.statusReason
        );
    };

    private _getAssignedTo = (): JSX.Element => {
        let assignedTo = this.props.appointment.assignedTo;
        return (
            <>
                {assignedTo && assignedTo.userInfo ? (
                    <div className="flex-row">
                        <UserPersona
                            imageUrl={assignedTo.profileImage}
                            imageInitials={assignedTo.userInfo.initials}
                            fullName={assignedTo.userInfo.fullName}
                            renderDetails={false}
                            personaSize={PersonaSize.size24}
                        />
                        <div className="c-assignedto">{StringUtil.format("{0}", assignedTo.userInfo.fullName)}</div>
                    </div>
                ) : (
                    <div className="flex-row">
                        <div className="flex-column c-icon-col">
                            <Icon className="c-warning-icon" iconName="Warning" />
                        </div>
                        <div className="flex-column c-unassigned-text">{localizedStrings.AppointmentCardItem.unassigned}</div>
                    </div>
                )}
            </>
        );
    };

    private _getCustomer = (): JSX.Element => {
        const appointmentDetails = this.props.appointment.appointmentDetails;
        return (
            <>
                {appointmentDetails && appointmentDetails.customerFirstName ? (
                    <div className="flex-row">
                        <UserPersona
                            imageUrl={this.props.appointment.customerProfileImage}
                            imageInitials=""
                            fullName={appointmentDetails.customerFirstName + " " + appointmentDetails.customerLastName}
                            renderDetails={false}
                            personaSize={PersonaSize.size24}
                        />
                        <div className="c-customer">{appointmentDetails.customerFirstName + " " + appointmentDetails.customerLastName}</div>
                    </div>
                ) : null}
            </>
        );
    };
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentCardItemProvidedProps): Partial<IAppointmentCardItemOwnProps> {
    return {
        ...providedProps
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {};

export const ConnectedAppointmentCardItem = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentCardItem);
