/**
 * Takes an array of items, and groups the items by the given selector, returning a dictionary
 * mapping keys to the subset of items associated with that key.
 *
 * For example:
 *
 *    const cards = [
 *        { name: "card1", color: "red" },
 *        { name: "card2", color: "blue" },
 *        { name: "card3", color: "red" },
 *    ];
 *
 *    groupBy(cards, (card) => card.color);
 *
 *    returns: {
 *        "red": [{ name: "card1", color: "red" }, { name: "card3", color: "red" }],
 *        "blue": [{ name: "card2", color: "blue" }]
 *    }
 *
 * @param items Array of items to group
 * @param keySelector Delegate to obtain the key for the given item
 */
export function groupBy<T>(items: T[], keySelector: (item: T) => string | undefined): { [key: string]: T[] } {
    const result: { [key: string]: T[] } = {};
    for (const item of items) {
        const key = keySelector(item) || "";
        const itemsForGroup = result[key];
        if (itemsForGroup) {
            itemsForGroup.push(item);
        } else {
            result[key] = [item];
        }
    }

    return result;
}

/**
 * Build a mapping of items from a list.
 * The last object with a given key is returned for the key
 *
 * @param items The items
 * @param keySelector Selector to obtain a key for an item
 * @param valueSelector Optional selector to map the item to the value stored in the returned object.
 */
export function toLookup<T, V = T>(items: T[], keySelector: (item: T) => string, valueSelector?: (item: T) => V): { [key: string]: V } {
    const result: { [key: string]: V } = {};

    for (const item of items) {
        const key = keySelector(item);
        result[key] = valueSelector ? valueSelector(item) : ((item as unknown) as V);
    }

    return result;
}

/**
 * Implements a stable sort. Uses merge sort, uses O(n) additional storage.
 *
 * Standard JS sorting is not stable in all browsers
 * adapted from http://en.literateprograms.org/Merge_sort_%28JavaScript%29
 */
export function stableSort<T>(arr: T[], cmpFunc: (a: T, b: T) => number): T[] {
    const scratch = new Array(arr.length) as T[];
    msort(arr, 0, arr.length);
    return arr;

    function msort(array: T[], begin: number, end: number): void {
        const size = end - begin;
        if (size < 2) {
            return;
        }

        const beginRight = begin + Math.floor(size / 2);

        msort(array, begin, beginRight);
        msort(array, beginRight, end);
        merge(array, begin, beginRight, end);
    }

    function merge(array: T[], begin: number, beginRight: number, end: number): void {
        let i = begin;
        let j = beginRight;

        // merge into scratch array
        let dest = 0;
        while (i < beginRight && j < end) {
            if (cmpFunc(array[i], array[j]) <= 0) {
                scratch[dest] = array[i];
                i++;
            } else {
                scratch[dest] = array[j];
                j++;
            }
            dest++;
        }

        // copy leftovers into scratch
        while (i < beginRight) {
            scratch[dest] = array[i];
            dest++;
            i++;
        }
        while (j < end) {
            scratch[dest] = array[j];
            dest++;
            j++;
        }

        // copy out of scratch
        for (i = 0; i < end - begin; i++) {
            array[i + begin] = scratch[i];
        }
    }
}

export function uniqueSort<T>(array: T[], comparer: (a: T, b: T) => number = defaultComparer): T[] {
    array.sort(comparer);

    for (let i = 1, l = array.length; i < l; i++) {
        if (comparer(array[i], array[i - 1]) === 0) {
            array.splice(i--, 1);
            l--;
        }
    }

    return array;
}

export function defaultComparer<T>(a: T, b: T): number {
    if (a === b) {
        return 0;
    } else if (a > b) {
        return 1;
    } else {
        return -1;
    }
}
