import { IRapPageContext, IRapService, Services, RapService } from "../Context";
/**
 * This is the basic data that describes the state of the page being executed.
 *  It has basic information about the service and the entities accessed.
 */
export interface IAuthenticationData {
    /**
     * Authentication token.
     */
    idToken: string;

    /**
     * Whether we are running inside of MS Teams.
     */
    isRunningInTeams: boolean;

    /**
     * Current MS Teams context.
     */
    // @ts-ignore
    teamsContext?: microsoftTeams.Context;

    /**
     * Whether we are in full screen mode inside of Teams.
     */
    isFullScreen: boolean | undefined;
}

export interface IRapAuthenticationService extends IRapService {
    setIdToken(idToken: string): void;
    // @ts-ignore
    setMSTeamsContext(context: microsoftTeams.Context): void;
    getData(): IAuthenticationData;
}

export class RapAuthenticationService extends RapService implements IRapAuthenticationService {
    private authData: IAuthenticationData;

    public _serviceStart(pageContext: IRapPageContext) {
        super._serviceStart(pageContext);

        // Fetch authorization token for page
        this._initializeService();
    }

    private _initializeService(): void {
        this.authData = { idToken: "", isRunningInTeams: false, teamsContext: undefined, isFullScreen: undefined };
    }

    public setIdToken(idToken: string): void {
        this.authData.idToken = idToken;
    }

    // @ts-ignore
    public setMSTeamsContext(context: microsoftTeams.Context): void {
        this.authData.teamsContext = context;
        this.authData.isRunningInTeams = true;
        this.authData.isFullScreen = context.isFullScreen;
    }

    public getData(): IAuthenticationData {
        return this.authData;
    }
}

Services.add("IRapAuthenticationService", { serviceFactory: RapAuthenticationService });
