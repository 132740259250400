import React, { FC } from "react";
import { IconButton, Panel, PanelType } from "@fluentui/react";
import { Link } from "@fluentui/react";
import { connect } from "react-redux";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../Layout";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { PageActions } from "../../../Page/redux/PageActions";
import { PageFeature } from "../../../../../common/Constants";

import * as PageSelectors from "../../../Page/redux/PageSelectors";

import "./AboutPanel.scss";

interface IAboutPanelProvidedProps extends IRapComponentProperties {
 
}

interface IAboutPanelOwnProps extends IAboutPanelProvidedProps {
    displayAboutPanel: boolean | undefined;
}

export type IAboutPanelProps = IAboutPanelOwnProps & typeof ActionsToDispatch;

const AboutPanelInitializer: FC<IAboutPanelProps> = (props) => {
    
    const _onCloseAboutPanel = () => {
        props.logTelemetry(PageFeature.ClientelingCommandBar, "Close about panel clicked");
        props.updatePanels({ displayAboutPanel: !props.displayAboutPanel });
    };

    const _renderAboutPanelHeader = (): JSX.Element => {
        return (
            <div className="c-header-nav flex-row">
                <div className="c-panel-header flex-start flex-grow" role="heading" aria-level={1}>{localizedStrings.CommandBar?.aboutPanelTitle}</div>
                <IconButton
                    iconProps={{ iconName: "Cancel", style: { margin: "10px" } }}
                    className="c-cancel-button flex-end"
                    onClick={_onCloseAboutPanel}
                    title={localizedStrings.CommandBar?.close}
                    ariaLabel={localizedStrings.CommandBar?.close}
                ></IconButton>
            </div>
        );
    }

    return (
        <>
            <Panel
                isOpen={props.displayAboutPanel}
                type={PanelType.custom}
                customWidth={"320px"}
                isLightDismiss={true}
                hasCloseButton={false}
                isFooterAtBottom={false}
                className="c-about-panel"
                onDismiss={_onCloseAboutPanel}
                onRenderHeader={_renderAboutPanelHeader}
            >
                <div className="c-about-container">
                    <div>{localizedStrings.About.detail}</div>
                    <div>{localizedStrings.About.contact}</div>
                </div>
                <div className="c-about-link">
                    <Link href="https://privacy.microsoft.com/en-US/data-privacy-notice" target="_blank">{localizedStrings.About.privacyPolicy}</Link>
                </div>
            </Panel>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAboutPanelProvidedProps): Partial<IAboutPanelOwnProps> {
    return {
        ...providedProps,
        displayAboutPanel: PageSelectors.getPanels(state)?.displayAboutPanel
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updatePanels: PageActions.updatePanels
};

export const AboutPanel = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AboutPanelInitializer);

