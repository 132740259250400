import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { CreateEditAppointmentModal, ICreateEditAppointmentFormValues  } from "../../../../common/components/CreateEditAppointmentModal/CreateEditAppointmentModal";
import { AppointmentDetail, IAppointmentForUpdateDto } from "../../../../contracts/swagger/_generated";

import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import * as AppointmentDetailsSelectors from "../../redux/AppointmentDetailsSelectors";
import { AppointmentFlows } from "../../../../common/Constants";
import { AppointmentDetailsActions } from "../../redux/AppointmentDetailsActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

//Props passed by parent component
interface IEditAppointmentProvidedProps extends IRapComponentProperties {
}

interface IEditAppointmentOwnProps {
    isEditAppointmentOpen: boolean;
    selectedAppointment: AppointmentDetail;
}

interface IEditAppointmentState {
    isLoading: boolean;
}

export type IEditAppointmentProps = ConnectedProps<typeof connector> & IEditAppointmentProvidedProps;

class EditAppointmentInitializer extends RapComponent<IEditAppointmentProps, IEditAppointmentState> {
    constructor(props: IEditAppointmentProps, context: IRapComponentContext) {
        super(props, context);
        this.state = { isLoading: false };
    }

    public render() {
        return (
            <CreateEditAppointmentModal 
                onDismiss={this._onDismissEditAppointment}
                onSave={this._onSaveEditAppointment}
                onUpdate={this._onUpdateEditAppointment}
                isOpen={this.props.isEditAppointmentOpen}
                defaultValues={this.props.selectedAppointment}
                isLoading={this.state.isLoading}
                flow={AppointmentFlows.Edit}
            />
        );
    }

    private _onUpdateEditAppointment = (val: Partial<ICreateEditAppointmentFormValues>, then?: () => void) => {
        let form = val as IAppointmentForUpdateDto;
        this.props.setAppointmentUpdates(form).then(then);
    }

    private _onDismissEditAppointment = () => {
        this.props.updateModals({ displayEditAppointmentModal: false });
    }

    private _onSaveEditAppointment = () => {
        this.setState({ isLoading: true });
        this.props.updateAppointment().then(() => {
            this.setState({ isLoading: false });
            this.props.updateModals({ displayEditAppointmentModal: false });
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal.updateSuccess});
        }).catch((error: string) => {
            this.setState({ isLoading: false});
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal.updateError});
        });
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IEditAppointmentProvidedProps): Partial<IEditAppointmentOwnProps> {
    return {
        ...providedProps,
        isEditAppointmentOpen: PageSelectors.getModals(state)?.displayEditAppointmentModal,
        selectedAppointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setAppointmentUpdates: AppointmentDetailsActions.setAppointmentUpdates,
    updateAppointment: AppointmentDetailsActions.updateAppointment,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const EditAppointmentModal = connector(EditAppointmentInitializer)