/**
 * When a promise is made cancelable we return a CancelablePromise<T>.
 */
export interface ICancelablePromise<T> {
    promise: Promise<T>;
    cancel: () => void;
}

/**
 * When a cancelable promise is used it may return an ICancelReason or the underlying
 * rejection from the wrapped promise.
 */
export interface ICancelReason {
    isCanceled: boolean;
}

/**
 * makeCancelable is used to wrap an existing promise and support canceling
 * the promise. This doesnt actually stop the promise from completing, instead
 * it will send an isCanceled value to the resolve and reject methods when
 * the promise is canceled.
 */
export const makeCancelable = <T>(promise: Promise<T>) => {
    let isCanceled = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        const rejectIfCanceled = (): boolean => {
            if (isCanceled) {
                // Add an empty reject handler to avoid browser console errors
                // every time a promise is canceled.
                wrappedPromise.catch(() => {});
                reject({ isCanceled: true });
            }
            return isCanceled;
        };

        promise.then(val => rejectIfCanceled() || resolve(val));
        promise.catch(error => rejectIfCanceled() || reject(error));
    });

    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        }
    } as ICancelablePromise<T>;
};

/**
 * Returns a promise that, if the given promise resolves in less than timeoutMs, resolves to the
 * resolution (or rejection) of the given promise. If the given promise does not resolve in less
 * than timeoutMs, reject with the given message.
 * @param promise
 * @param timeoutMs
 * @param message message to send with the rejection when the timeout expires
 */
export function timeout<T>(promise: PromiseLike<T>, timeoutMs: number, message?: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const timeoutHandle = setTimeout(() => {
            reject(message == null ? `Timed out after ${timeoutMs} ms.` : message);
        }, timeoutMs);

        // Maybe use finally when it's available.
        promise.then(
            result => {
                resolve(result);
                clearTimeout(timeoutHandle);
            },
            reason => {
                reject(reason);
                clearTimeout(timeoutHandle);
            }
        );
    });
}

/**
 * Returns a promise that resolves after timeoutMs.
 * @param timeoutMs
 */
export async function wait(timeoutMs: number): Promise<void> {
    return new Promise<void>(resolve => {
        setTimeout(resolve, timeoutMs);
    });
}

/**
 * Return a promise that resolves at least delayMs from now. Rejection happens immediately.
 */
export async function delay<T>(promise: Promise<T>, delayMs: number): Promise<T> {
    return (await Promise.all([promise, wait(delayMs)]))[0];
}

export interface IResolvedPromiseResult<T> {
    state: "fulfilled";
    value: T;
}
export interface IRejectedPromiseResult {
    state: "rejected";
    reason: any;
}
export type IPromiseResult<T = any> = IResolvedPromiseResult<T> | IRejectedPromiseResult;

/**
 * Returns a promise that resolves after all given promises have either resolved or rejected.
 * @param promises
 */
export function allSettled<T = any>(promises: PromiseLike<T>[]): Promise<IPromiseResult<T>[]> {
    const results = new Array(promises.length);
    return new Promise(resolve => {
        let count = 0;
        for (let i = 0; i < promises.length; ++i) {
            const promise = promises[i];
            promise
                .then(
                    result => {
                        results[i] = {
                            state: "fulfilled",
                            value: result
                        };
                    },
                    reason => {
                        results[i] = {
                            state: "rejected",
                            reason: reason
                        };
                    }
                )
                // eslint-disable-next-line
                .then(() => {
                    if (++count === promises.length) {
                        resolve(results);
                    }
                });
        }
    });
}
