import { IMessages, IPageAwareState, IPageResources, IContactPages, IPanels, IModals, IUserData, IViewOptions, NavMenuItem, UserRole } from "../Contracts";
import { IStoreDto, Page } from "../../../../contracts/swagger/_generated";
import { Themes } from "../../../../common/Constants";

export function getMenuItems(state: IPageAwareState): NavMenuItem[] {
    if (!state || !state.pageViewState || !state.pageViewState.ux || !state.pageViewState.ux.menuItems) {
        return [];
    }

    //Filter menu items by user role
    return state.pageViewState.ux.menuItems.navMenuItems.filter(x => x.minimumRequiredUserRole <= state.pageViewState.data.userData.userRole);
}

export function getPageData(state: IPageAwareState): Page | undefined {
    if(!state || !state.pageViewState.data || !state.pageViewState.data.userData) {
        return undefined;
    }

    return state.pageViewState.data.userData.pageData;
}

export function getViewOptions(state: IPageAwareState): IViewOptions {
    if (!state || !state.pageViewState || !state.pageViewState.ux) {
        return undefined;
    }
    
    return state.pageViewState.ux.viewOptions;
}

export function getStores(state: IPageAwareState): IStoreDto[] {
    if (!state || !state.pageViewState || !state.pageViewState.data) {
        return [];
    }
    
    return state.pageViewState.data.stores;
}

export function getSettingsMenuItems(state: IPageAwareState): NavMenuItem[] {
    if (!state || !state.pageViewState || !state.pageViewState.ux || !state.pageViewState.ux.menuItems) {
        return [];
    }

    //This is here because it's possible for an Admin to override the userRole
    if (state.pageViewState.data?.userData && state.pageViewState.data.userData?.pageData?.isAdmin) {
        return state.pageViewState.ux.menuItems.settingsMenuItems.filter(x => x.minimumRequiredUserRole <= UserRole.Admin);
    }
    return state.pageViewState.ux.menuItems.settingsMenuItems.filter(x => x.minimumRequiredUserRole <= state.pageViewState.data.userData.userRole);
}

export function getContactPage(state: IPageAwareState): IContactPages | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.ux) {
        return undefined;
    }
    
    return state.pageViewState.ux.contactPages;
}

export function getPanels(state: IPageAwareState): IPanels | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.ux) {
        return undefined;
    }

    return state.pageViewState.ux.panels;
}

export function getModals(state: IPageAwareState): IModals | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.ux) {
        return undefined;
    }

    return state.pageViewState.ux.modals;
}

export function getTheme(state: IPageAwareState): Themes {
    if (!state || !state.pageViewState || !state.pageViewState.ux || !state.pageViewState.ux.theme) {
        return Themes.Default;
    }

    return state.pageViewState.ux.theme;
}

export function getUserData(state: IPageAwareState) : IUserData | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.data || !state.pageViewState.data.userData) {
        return undefined;
    }

    return state.pageViewState.data.userData;
}

export function getMessages(state: IPageAwareState) : IMessages | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.ux) {
        return undefined;
    }

    return state.pageViewState.ux.messages;
}

export function getResources(state: IPageAwareState): IPageResources | undefined {
    if(!state || !state.pageViewState || !state.pageViewState.data) {
        return undefined;
    }

    return state.pageViewState.data.resources;
}
