import React, {FC, useEffect, useState} from "react";
import { connect } from "react-redux";
import { DatePicker, Dropdown, IDropdownOption, Label, Stack } from "@fluentui/react";
import TimezoneSelect, { allTimezones, ITimezoneOption } from "react-timezone-select";
import moment from "moment-timezone";

import { IRapComponentProperties } from "../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { ITimeSlotDto } from "../../../../../contracts/swagger/_generated";
import { AppointmentModalActions } from "../../redux/AppointmentModalActions";

import * as Constants from "../../../../../common/Constants";
import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";
import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors";

import "./AppointmentDatePicker.scss";

// Props passed by parent component
interface IAppointmentDatePickerProvidedProps extends IRapComponentProperties {
    flow: Constants.AppointmentFlows
    onTimezoneSelect: (tz: ITimezoneOption) => void;
    onTimeSelect: (timeslot: ITimeSlotDto) => void;
    appointmentTypeId: string;

    onDateSelect?: (date: Date) => void;
    onBlur?: () => void;
    defaultDate?: Date;
    defaultTime?: Date;
    defaultTimezone?: string;
    timeValue?: string;
    disabled?: boolean;
}

// Props mapped from state object
interface IAppointmentDatePickerInitializerOwnProps extends IAppointmentDatePickerProvidedProps {
    timeslots: ITimeSlotDto[];
    storeId: string;
}

export type IAppointmentDatePickerInitializerProps = IAppointmentDatePickerInitializerOwnProps & typeof ActionsToDispatch;

const AppointmentDatePickerInitializer: FC<IAppointmentDatePickerInitializerProps> = (props) => {
    const [selectedTimezone, setSelectedTimezone] = useState(props.defaultTimezone ? props.defaultTimezone : moment.tz.guess());
    const [selectedDate, setSelectedDate] = useState(props.defaultDate ? props.defaultDate : moment().toDate());
    const [times, setTimes] = useState<IDropdownOption[]>();

    useEffect(() => {
        if(props.storeId && props.appointmentTypeId) {
            props.fetchTimeslots(props.storeId, props.appointmentTypeId);
        }
    }, [props.appointmentTypeId]);

    useEffect(() => {
        setTimes(getTimes(selectedDate));
    }, [props.timeslots, selectedDate, selectedTimezone]);

    const getMinDate = () => {
        return new Date(moment().tz(selectedTimezone).startOf("day").format(Constants.DateTimeFormat));
    }

    const getMaxDate = () => {
        return new Date(moment().tz(selectedTimezone).startOf("day").add(Constants.DaysInAdvance, "days").format(Constants.DateTimeFormat));
    }

    const getTimes = (selectedDate: Date): IDropdownOption[] => {
        const { timeValue, timeslots, defaultTime } = props;
        let times: IDropdownOption[] = [];

        if (!selectedDate) {
            return [];
        }

        let startOfSelectedDay = moment(selectedDate).tz(selectedTimezone).startOf("day");
        if (moment().tz(selectedTimezone).startOf("day").isSame(startOfSelectedDay) && !Constants.WalkInAppointmentTypeIds.includes(props.appointmentTypeId)) {
            // If the selected day is the current day, we need to use a buffered time (unless the appointment is a walk-in type)
            startOfSelectedDay = moment().tz(selectedTimezone).add(Constants.TimeslotBuffer, "minutes");
        }
        const endOfSelectedDay = moment(selectedDate).tz(selectedTimezone).endOf("day");
        
        let isSameDayAsDefault: boolean;
        if(props.defaultTime) {
            isSameDayAsDefault = moment(props.defaultTime).tz(selectedTimezone).isSame(startOfSelectedDay, "day");
        }

        let isDefaultTimeFound = false;
        if (timeslots) {
            timeslots.forEach((timeslot: ITimeSlotDto) => {
                if(timeslot.startTime && 
                    moment(timeslot.startTime).tz(selectedTimezone) >= startOfSelectedDay && 
                    moment(timeslot.startTime).tz(selectedTimezone) < endOfSelectedDay
                ) {
                    times.push(
                        {
                            text: moment(timeslot.startTime).tz(selectedTimezone).format(Constants.TimeFormat),
                            key: timeslot.startTime.toUTCString(),
                            data: { timeslot: timeslot }
                        }
                    ); 

                    if(isSameDayAsDefault && moment(timeslot.startTime).isSame(props.defaultTime)) {
                        isDefaultTimeFound = true;
                    }
                }              
            });
        }

        if(isSameDayAsDefault && !isDefaultTimeFound) {
            times.push(
            {
                text: moment(props.defaultTime).tz(selectedTimezone).format(Constants.TimeFormat),
                key: props.defaultTime.toUTCString()
            })
        }
        return times;
    }

    const getRestrictedDates = (): Date[] => {
        if (!props.timeslots) {
            return [];
        }

        let restrictedDates = [];

        // Get date value from timeslots
        const timeslotDates = (props.timeslots as Array<ITimeSlotDto>).map((timeslot) => {
            return moment(timeslot.startTime).tz(selectedTimezone).startOf("day").format(Constants.DateFormat);
        });

        if(props.defaultTime) {
            timeslotDates.push(moment(props.defaultTime).tz(selectedTimezone).startOf("day").format(Constants.DateFormat));
        }
        
        const uniqueTimeslotDates = new Set(timeslotDates);

        const today = moment().tz(selectedTimezone).startOf("day");
        for (let i = 0; i <= Constants.DaysInAdvance; i++) {
            const dateMoment = moment(today).tz(selectedTimezone).add(i, "days").startOf("day");
            const dateStr = dateMoment.format(Constants.DateFormat);
            if (!uniqueTimeslotDates.has(dateStr)) {
                restrictedDates.push(new Date(dateMoment.format(Constants.DateTimeFormat)));
            }            
        }

        return restrictedDates;
    }

    const onTimezoneSelected = (timezone: ITimezoneOption) => {
        setSelectedTimezone(timezone.value);
        props.onTimezoneSelect(timezone);
        props.onTimeSelect(undefined);
        getTimes(selectedDate);
    }

    const onDateSelected = (date: Date) => {
        setSelectedDate(date);
        if(props.onDateSelect) {
            props.onDateSelect(date);
        }
    }

    const onTimeSelected = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number) => {
        if(option.data?.timeslot) {
            props.onTimeSelect(option.data.timeslot);
        }
    }

    return (
        <>
            <div className="flex-row c-row">
                <Stack className="c-column-1">
                    <Stack.Item>
                        <Label htmlFor="timezonepicker" className="c-timezone-label" required>
                                {localizedStrings.CreateEditAppointmentModal.appointmentDate}
                        </Label>
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker
                            id={"datepicker"}
                            ariaLabel={localizedStrings.CreateEditAppointmentModal.appointmentDate}
                            onSelectDate={onDateSelected}
                            isMonthPickerVisible={false}
                            value={selectedDate}
                            minDate={getMinDate()}
                            maxDate={getMaxDate()}
                            calendarProps={{ restrictedDates: getRestrictedDates() }}
                            disabled={props.disabled}
                        />
                    </Stack.Item>
                </Stack>
                <div className="flex-column c-column-2">
                    <Dropdown
                        label={localizedStrings.CreateEditAppointmentModal.appointmentTime}
                        options={times}
                        disabled={props.disabled || !times || times.length == 0}
                        required={true}
                        onChange={onTimeSelected}
                        onBlur={props.onBlur}
                        selectedKey={props.timeValue ? props.timeValue : null}
                    />
                </div>
            </div>
            <div className="flex-row c-row">
                <Stack className="c-column-wide">
                    <Stack.Item>
                        <Label htmlFor="timezonepicker" className="c-timezone-label" required>
                            {localizedStrings.CreateEditAppointmentModal.timeZone}
                        </Label>
                    </Stack.Item>
                    <Stack.Item>
                        <TimezoneSelect
                            id="timezonepicker"
                            value={selectedTimezone}
                            onChange={onTimezoneSelected}
                            timezones={allTimezones}
                            className="c-column-wide c-timezone-dropdown"
                            classNamePrefix="c-timezone-dropdown-prefix"
                            aria-label={localizedStrings.CreateEditAppointmentModal.timeZone}
                            isDisabled={props.disabled}
                        />
                    </Stack.Item>
                </Stack>
            </div>
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(
    state: IClientelingViewState,
    providedProps: IAppointmentDatePickerProvidedProps
): Partial<IAppointmentDatePickerInitializerOwnProps> {
    return {
        ...providedProps,
        timeslots: AppointmentModalSelectors.getResources(state)?.appointmentTimeslots,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore
    };
}

const ActionsToDispatch = {
    fetchTimeslots: AppointmentModalActions.fetchAppointmentTimeslots
}

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentDatePickerInitializer);