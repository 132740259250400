import { IRapAuthenticationService } from "./Authentication";
import { IRestClientOptionsProviderService, IRapRestClientOptions, Services, RapService } from "./../Context";

export class RestClientOptionsProviderService extends RapService implements IRestClientOptionsProviderService {
    public populateRestClientOptions(instanceOptions: IRapRestClientOptions | undefined): IRapRestClientOptions {
        const options: IRapRestClientOptions = { ...instanceOptions };

        const data = this.pageContext.getService<IRapAuthenticationService>("IRapAuthenticationService").getData();

        if (data) {
            options.authData = data;
        }

        return options;
    }
}

Services.add("IRestClientOptionsProviderService", { serviceFactory: RestClientOptionsProviderService });
