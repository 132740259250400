import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { ActionsOfType } from "../../../../platform/redux/ActionHelper";
import { AppointmentModalActions, AppointmentModalActionsType, AppointmentModalActionTypes } from "./AppointmentModalActions";
import { IRapPageContext } from "../../../../platform/Context";
import { ReservationsActions } from "../../../../views/ReservationsView/redux/ReservationsActions";
import * as AppointmentModalSelectors from "./AppointmentModalSelectors";
import {
    IClientelingApiClient,
    AppointmentCategory,
    IAppointmentDto,
    AppointmentCustomQuestionDto,
    IAppointmentType,
    IAppointmentTimeslots,
    ITimeSlotDto,
    ICase,
    IContactDto,
    ContactsView
} from "../../../../contracts/swagger/_generated";
import * as LocalConstants from "../../../../common/Constants";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import * as CommonSagaFunctions from "../../../../common/CommonSagaFunctions";
import { isFeatureFlagEnabled } from "../../../../views/FeatureManagement/redux/FeatureManagementSelectors";
import { IAppointmentModalResources } from "../Contracts";

const takeEvery: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;
const apply: any = Effects.apply;
const take: any = Effects.take;

export function* appointmentModalSaga(): SagaIterator {
    yield takeEvery(AppointmentModalActionTypes.FetchAppointmentTypes, fetchAppointmentTypes);
    yield takeEvery(AppointmentModalActionTypes.FetchAppointmentCategories, fetchAppointmentCategories);
    yield takeEvery(AppointmentModalActionTypes.CreateAppointment, createAppointment);
    yield takeEvery(AppointmentModalActionTypes.UpdateAppointment, updateAppointment);
    yield takeEvery(AppointmentModalActionTypes.FetchAppointmentTimeslots, fetchAppointmentTimeslots);
    yield takeEvery(AppointmentModalActionTypes.FetchAppointmentCustomResponses, fetchCreateAppointmentCustomResponses);
    yield takeEvery(AppointmentModalActionTypes.FetchDynamicsCases, fetchContactDynamicsCases);
    yield takeEvery(AppointmentModalActionTypes.FetchContacts, fetchContacts);
}


export function* fetchAppointmentTypes(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchAppointmentTypes>): SagaIterator {
    const storeId = action.payload;
    yield call(CommonSagaFunctions.fetchAppointmentTypes,
        storeId,
        AppointmentModalActions.fetchAppointmentTypesSuccess,
        AppointmentModalActions.fetchAppointmentTypesFailure
    ) 
}

export function* fetchAppointmentCategories(
    action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchAppointmentCategories>
): SagaIterator {
    const { storeId, typeId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const types: AppointmentCategory[] = ((yield call(
                [clientelingClient, clientelingClient.getAppointmentCategories],
                storeId,
                typeId
            )) as unknown) as AppointmentCategory[];

            yield put(AppointmentModalActions.fetchAppointmentCategoriesSuccess(types));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors?.serverError as string;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? 
                localizedStrings.App?.unAuthorizedMessage as string : 
                localizedStrings.Errors?.serverError as string;
            yield put(AppointmentModalActions.fetchAppointmentCategoriesFailure(message));
        }
    } else {
        yield put(AppointmentModalActions.fetchAppointmentCategoriesFailure("IdToken token is invalid."));
    }
}

export function* createAppointment(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.CreateAppointment>): SagaIterator {
    const { storeId, body } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: IAppointmentDto = ((yield call(
                [clientelingClient, clientelingClient.createAppointment],
                storeId,
                body
            )) as unknown) as IAppointmentDto;
            
            yield put(AppointmentModalActions.createAppointmentSuccess(response));
            action.resolve(response);

            yield put(ReservationsActions.fetchAppointmentsData(true));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors?.serverError as string;
            action.reject();
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? 
                localizedStrings.App?.unAuthorizedMessage as string : 
                localizedStrings.Errors?.serverError as string;
            yield put(AppointmentModalActions.createAppointmentFailure(message));
        }
    } else {
        action.reject();
        yield put(AppointmentModalActions.createAppointmentFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentTimeslots(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchAppointmentTimeslots>): SagaIterator {
    const context: any = yield getContext("pageContext");
    const { storeId, typeId } = action.payload;
    if (context) {
        try {
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;

            let EnableScheduleAutoAssignment = ((yield select(isFeatureFlagEnabled, "EnableScheduleAutoAssignment")) as unknown) as boolean;
            let serviceTypes = (((yield select(AppointmentModalSelectors.getResources)) as unknown) as IAppointmentModalResources).appointmentTypes;

            if(!serviceTypes || serviceTypes.length == 0) {
                serviceTypes = ((yield call(
                    [clientelingClient,
                    clientelingClient.getAppointmentTypes],
                    storeId
                )) as unknown) as IAppointmentType[];
            }

            let label = serviceTypes.find((type: IAppointmentType) => { return type.appointmentTypeId == typeId;})?.name;
            var timeslots: IAppointmentTimeslots;

            if(EnableScheduleAutoAssignment && label != LocalConstants.AppointmentType_HardwareSupport) {
                timeslots = ((yield call(
                    [clientelingClient,
                    clientelingClient.getVirtualAppointmentTimeslots],
                    storeId,
                    typeId
                )) as unknown) as IAppointmentTimeslots;
            }
            else {
                timeslots = ((yield call(
                    [clientelingClient,
                    clientelingClient.getInStoreAppointmentTimeslots],
                    storeId,
                    typeId
                )) as unknown) as IAppointmentTimeslots;
            }
            yield put(AppointmentModalActions.fetchAppointmentTimeslotsSuccess(timeslots.timeslots as ITimeSlotDto[]));
        } catch (error) {
            yield put(AppointmentModalActions.fetchAppointmentTimeslotsFailure((error as any).message || error));
        }
    } else {
        yield put(AppointmentModalActions.fetchAppointmentTimeslotsFailure("IdToken token is invalid."));
    }
}

export function* updateAppointment(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.UpdateAppointment>): SagaIterator {
    const { storeId, appointmentId, appt, silentFetch } = action.payload;
    yield call(CommonSagaFunctions.updateAppointment,
        storeId,
        appointmentId,
        appt,
        silentFetch,
        AppointmentModalActions.updateAppointmentSuccess,
        AppointmentModalActions.updateAppointmentFailure
    )

    action.resolve();
    yield put(ReservationsActions.fetchAppointmentsData(silentFetch));
}

export function* fetchCreateAppointmentCustomResponses(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchAppointmentCustomResponses>): SagaIterator {
    const { storeId, typeId, categoryId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: AppointmentCustomQuestionDto[] = ((yield call(
                [clientelingClient, clientelingClient.getAppointmentQuestions],
                storeId,
                typeId,
                categoryId
            )) as unknown) as AppointmentCustomQuestionDto[];

            yield put(AppointmentModalActions.fetchAppointmentCustomResponsesSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors?.serverError as string;
            if (errorCode) message = errorCode == LocalConstants.UnAuthorizedUser ? 
                localizedStrings.App?.unAuthorizedMessage as string : 
                localizedStrings.Errors?.serverError as string;
            yield put(AppointmentModalActions.fetchAppointmentCustomResponsesFailure(message));
        }
    } else {
        yield put(AppointmentModalActions.fetchAppointmentCustomResponsesFailure("IdToken token is invalid."));
    }
}

export function* fetchContactDynamicsCases(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchDynamicsCases>)
: SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const contactId = action.payload;
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: ICase[] = ((yield call(
                [clientelingClient, clientelingClient.getContactDynamicsCases],
                contactId
            )) as unknown) as ICase[];

            if(action.resolve) {
                action.resolve(response);
            }
            yield put(AppointmentModalActions.fetchDynamicsCasesSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors?.serverError as string;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? 
                localizedStrings.App?.unAuthorizedMessage as string : 
                localizedStrings.Errors?.serverError as string;
            if(action.reject) {
                action.reject(message);
            }
            yield put(AppointmentModalActions.fetchDynamicsCasesFailure(message));
        }
    } else {
        yield put(AppointmentModalActions.fetchDynamicsCasesFailure("IdToken token is invalid."));
    }
}

export function* fetchContacts(action: ActionsOfType<AppointmentModalActionsType, AppointmentModalActionTypes.FetchContacts>): SagaIterator {
    const { name, email, phone, select, searchExclusively } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;

            // NOTE: If this isnt performant enough we'll need to make some changes to stores api to allow to query the parameters exclusively. 
            if(searchExclusively) {
                let contacts: IContactDto[] = [];

                const nameResults: ContactsView = ((yield call(
                    [clientelingClient, clientelingClient.getContacts],
                    name ? name : "",
                    undefined,
                    undefined,
                    undefined
                )) as unknown) as ContactsView;
                contacts = nameResults.contacts;

                const emailResults: ContactsView = ((yield call(
                    [clientelingClient, clientelingClient.getContacts],
                    undefined,
                    email ? email : "",
                    undefined,
                    undefined
                )) as unknown) as ContactsView;

                emailResults.contacts?.forEach(c => {
                    if(!contacts.find(user => { return user.contactId == c.contactId })) {
                        contacts.push(c);
                    }
                });
                action.resolve(contacts);
                yield put(AppointmentModalActions.fetchContactsSuccess(contacts));
            } else {
                const contactsData: ContactsView = ((yield call(
                    [clientelingClient, clientelingClient.getContacts],
                    name ? name : "",
                    email ? email : "",
                    phone ? phone : "",
                    select ? select : ""
                )) as unknown) as ContactsView;
                
                action.resolve(contactsData.contacts);
                yield put(AppointmentModalActions.fetchContactsSuccess(contactsData.contacts));
            }
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject();
            yield put(AppointmentModalActions.fetchContactsFailure(message));
        }
    } else {
        yield put(AppointmentModalActions.fetchContactsFailure("IdToken token is invalid."));
    }
}