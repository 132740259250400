import React from "react";
import { RapComponent } from "../../../platform/Layout";
import { Image, Text } from "@fluentui/react";
import noResultsImg from "../../../styling/images/calendar.png";
import "./NoResults.scss";
import { TextSizes } from "../../Constants";
import { localizedStrings } from "../../localization/LocalizedStrings";

export interface INoResultsProps {
    description: string;
    imageSource?: string;
}

export class NoResults extends RapComponent<INoResultsProps> {
    public render() {
        return (
            <div className="c-no-results">
                <Image
                    className="c-no-results-img-box"
                    src={this.props.imageSource ? this.props.imageSource : noResultsImg}
                    alt={localizedStrings.App.noResultsAltText} />
                <Text className="c-no-results-text" role="alert">{this.props.description}</Text>
            </div>
        );
    }
}
