import * as React from "react";
import { IObservableValue, ObservableLike } from "../../Observable";
import { IObservableExpression, IObserverProps } from "./Observer.Props";

export interface IObserverState {
    values: { [propName: string]: any };
    oldProps: IObserverProps;
}

interface ISubscribable {
    subscribe(propName: string, props: IObserverProps): void;
    unsubscribe(propName: string, props: IObserverProps): void;
}

interface ISubscription {
    delegate: (value: any, action?: string) => void;
    action: string | undefined;
}

/**
 * Handles subscription to properties that are IObservableValues, so that components don't have to handle on their own.
 *
 * Usage:
 *
 * <Observer myObservableValue={observableValue} className='foo'>
 *     <MyComponent myObservableValue='' />
 * </Observer>
 *
 * Your component will get re-rendered with the new value of myObservableValue whenever that value changes.
 * Additionally, any additional props set on the Observer will also get passed down.
 */
export class Observer extends React.Component<IObserverProps, IObserverState> {
    public static getDerivedStateFromProps(props: Readonly<IObserverProps>, state: Readonly<IObserverState>): Partial<IObserverState> {
        const newState = updateSubscriptionsAndState(state.oldProps, props, state);
        if (newState != null) {
            return { ...newState, oldProps: props };
        }

        return { oldProps: props };
    }

    private subscribedProps: IObserverProps;
    private subscriptions: { [propName: string]: ISubscription };

    constructor(props: Readonly<IObserverProps>) {
        super(props);

        this.subscriptions = {};

        // Initialize the state with the initial value of the observable.
        const state: IObserverState = { values: {}, oldProps: {} };
        for (const propName in props) {
            state.values[propName] = getPropValue(props[propName]);
        }

        this.state = state;
    }

    public render(): JSX.Element {
        const newProps: IObserverProps = {};

        // Copy over any properties from the observable component to the children.
        for (const key in this.state.values) {
            if (key !== "children") {
                newProps[key] = this.state.values[key];
            }
        }

        if (typeof this.props.children === "function") {
            const child: (props: IObserverProps) => JSX.Element = this.props.children as any;
            return child(newProps);
        } else {
            const child = React.Children.only(this.props.children) as React.DetailedReactHTMLElement<any, HTMLElement>;
            return React.cloneElement(child, { ...child.props, ...newProps }, child.props.children);
        }
    }

    public componentDidMount(): void {
        this.updateSubscriptionsAndStateAfterRender();
    }

    public componentDidUpdate(): void {
        this.updateSubscriptionsAndStateAfterRender();

        if (this.props.onUpdate) {
            this.props.onUpdate();
        }
    }

    public componentWillUnmount(): void {
        // Unsubscribe from any of the observable properties.
        for (const propName in this.subscribedProps) {
            this.unsubscribe(propName, this.subscribedProps);
        }
    }

    public subscribe(propName: string, props: IObserverProps) {
        if (propName !== "children") {
            let observableExpression: IObservableExpression | undefined;
            let observableValue = props[propName];
            let action: string | undefined;

            // If this is an observableExpression, we need to subscribe to the value
            // and execute the filter on changes.
            if (observableValue && (observableValue as IObservableExpression).observableValue !== undefined) {
                observableExpression = observableValue as IObservableExpression;
                observableValue = observableExpression.observableValue;
                action = observableExpression.action;
            }

            if (ObservableLike.isObservable(observableValue)) {
                const delegate = this.onValueChanged.bind(this, propName, observableValue, observableExpression);
                ObservableLike.subscribe(observableValue, delegate, action);
                this.subscriptions[propName] = { delegate, action };
            }
        }
    }

    public unsubscribe(propName: string, props: IObserverProps) {
        if (propName !== "children") {
            const observableValue = getObservableValue(props[propName]);

            if (ObservableLike.isObservable(observableValue)) {
                const subscription = this.subscriptions[propName];
                ObservableLike.unsubscribe(observableValue, subscription.delegate, subscription.action);
                delete this.subscriptions[propName];
            }
        }
    }

    private updateSubscriptionsAndStateAfterRender() {
        const newState = updateSubscriptionsAndState(this.subscribedProps, this.props, this.state, this as ISubscribable);
        if (newState != null) {
            this.setState(newState);
        }

        this.subscribedProps = { ...this.props };
    }

    private onValueChanged(
        propName: string,
        observableValue: IObservableValue<any>,
        observableExpression: IObservableExpression | undefined,
        value: any,
        action: string
    ) {
        let setState = true;

        if (!(propName in this.subscriptions)) {
            return;
        }

        // If this is an ObservableExpression we will call the filter before setting state.
        if (observableExpression && observableExpression.filter) {
            setState = observableExpression.filter(value, action);
        }
        if (setState) {
            this.setState((prevState: Readonly<IObserverState>, props: Readonly<IObserverProps>) => {
                return {
                    values: {
                        ...prevState.values,
                        [propName]: observableValue.value || value
                    }
                };
            });
        }
    }
}

function getObservableValue(propValue: IObservableValue<any> | IObservableExpression | any): IObservableValue<any> | any {
    if (propValue && propValue.observableValue !== undefined) {
        return propValue.observableValue;
    }

    return propValue;
}

function getPropValue(propValue: IObservableValue<any> | IObservableExpression | any): any {
    return ObservableLike.getValue(getObservableValue(propValue));
}

function updateSubscriptionsAndState(
    oldProps: IObserverProps,
    newProps: IObserverProps,
    state: IObserverState,
    component?: ISubscribable
): IObserverState | null {
    // We need to unsubscribe from any observable values on old props and
    // subscribe to any observable values on new props.
    // In addition, if any of the values of the observables on the new props
    // differ from the value on the state, then we need to update the state.
    // This is possible if the value of the observable changed while the value
    // was being rendered, but before we had set up the subscription.
    // If we want to unsubscribe/resubscribe, then a component should be passed,
    // since this method is always called statically.

    const newState: IObserverState = { ...state };
    let stateChanged = false;
    if (oldProps) {
        for (const propName in oldProps) {
            const oldValue = getObservableValue(oldProps[propName]);
            const newValue = getObservableValue(newProps[propName]);

            if (oldValue !== newValue) {
                component && component.unsubscribe(propName, oldProps);
                if (newValue === undefined) {
                    delete newState.values[propName];
                    stateChanged = true;
                }
            }
        }
    }

    for (const propName in newProps) {
        const oldValue = oldProps && getObservableValue(oldProps[propName]);
        const newValue = getObservableValue(newProps[propName]);

        if (oldValue !== newValue) {
            component && component.subscribe(propName, newProps);

            // Look for changes in the observables between creation and now.
            if (state.values[propName] !== getPropValue(newValue)) {
                newState.values[propName] = getPropValue(newValue);
                stateChanged = true;
            }
        }
    }

    // If any state updates occurred update the state now.
    if (stateChanged) {
        return newState;
    }

    return null;
}
