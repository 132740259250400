import { SagaIterator } from "redux-saga";
import { takeEvery, call, put, getContext, select } from "redux-saga/effects";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { FeatureManagementActions, FeatureManagementActionsType, FeatureManagementActionTypes } from "./FeatureManagementActions";
import { IRapPageContext } from "./../../../platform/Context";
import * as PageSelectors from "./../../../platform/components/Page/redux/PageSelectors";
import { IClientelingApiClient, FeatureFlag } from "../../../contracts/swagger/_generated";
import * as LocalConstants from "../../../common/Constants";
import { IViewOptions } from "../../../platform/components/Page/Contracts";

const Resources = require("../Resources.json");

export function* featureManagementSaga(): SagaIterator {
    yield takeEvery(FeatureManagementActionTypes.FetchFeatureFlags, fetchFeatureFlags);
}

export function* fetchFeatureFlags(
    action: ActionsOfType<FeatureManagementActionsType, FeatureManagementActionTypes.FetchFeatureFlags>
): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const context = pageContext as IRapPageContext;
            const client: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const storeId = action.payload ? action.payload : (((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions).selectedStore;
            const featureFlags: FeatureFlag[] = ((yield call([client, client.getFlags], storeId)) as unknown) as FeatureFlag[];
            yield put(FeatureManagementActions.fetchFeatureFlagsSuccess(featureFlags));
        }
    } catch (error) {
        let actualError = error as any;
        var errorCode = actualError.status;
        if (errorCode) actualError.message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? Resources.UnAuthorizedError : actualError.message;
        yield put(FeatureManagementActions.fetchFeatureFlagsFailure(actualError.message || error));
    }
}
