import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { createTheme, ThemeProvider as FluentThemeProvider} from "@fluentui/react";
import * as microsoftTeams from "@microsoft/teams-js";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../Layout";
import { Themes } from "../../../common/Constants";
import { IClientelingViewState } from "../../../pages/Contracts";
import { PageActions } from "../../components/Page/redux/PageActions";
import { darkTheme, defaultTheme, highContrastTheme } from "../../../styling/themes";

import * as Cookie_Util from "../../core/util/Cookie";
import * as PageSelectors from "../Page/redux/PageSelectors";
import * as Constants from "../../../common/Constants";


class ThemeProvider extends RapComponent<IThemeProviderProps> {
    constructor(props: IThemeProviderProps, context: IRapComponentContext) {
        super(props, context);
        if(this.props.inTeams) {
            microsoftTeams.getContext(context => {
                this.setTheme(context.theme);
                this.setThemeState(context.theme);
            });

            microsoftTeams.registerOnThemeChangeHandler(this.setTheme);
        }
        else {
            this.setThemeFromCookie();
        }
    }

    public componentDidUpdate(prevProps: Readonly<IThemeProviderOwnProps>): void {
        if(prevProps.theme != this.props.theme) {
            switch(this.props.theme) {
                case Themes.Dark: {
                    this.setThemeClassName(Constants.Theme_Dark_Key);
                    break;
                }
                case Themes.HighContrast: {
                    this.setThemeClassName(Constants.Theme_HighContrast_Key);
                    break;
                }
                case Themes.Default: {
                    this.setThemeClassName(Constants.Theme_Default_Key);
                    break;
                }
            }
        }
    }

    private createFluentTheme = () => {
        switch(this.props.theme) {
            case Themes.Default: {
                return createTheme(defaultTheme);
            }
            case Themes.Dark: {
                return createTheme(darkTheme);
            }
            case Themes.HighContrast: {
                return createTheme(highContrastTheme);
            }
        }
    }

    private setTheme = (theme: any) => {
        //Get the default font size
        let pageFontSize = 0;

        let sizeStr = window.getComputedStyle(document.getElementsByTagName("html")[0]).getPropertyValue("font-size");
        sizeStr = sizeStr.replace("px", "");
        pageFontSize = parseInt(sizeStr, 10);
        if (!pageFontSize) {
            pageFontSize = 16;
        }

        this.setThemeClassName(theme);
    }

    //Intended to be used when running outside of teams
    private setThemeFromCookie = () => {
        var themeCookie = Cookie_Util.getCookie(Constants.Cookie_Theme);
        this.setThemeState(themeCookie);
    }

    private setThemeState = (theme: string) => {
        if (theme && theme.length > 0) {
            this.setThemeClassName(theme);
            switch (theme) {
                case Constants.Theme_Dark_Key: {
                    this.props.setTheme(Themes.Dark);
                    break;
                }
                case Constants.Theme_Default_Key: {
                    this.props.setTheme(Themes.Default);
                    break;
                }
                case Constants.Theme_HighContrast_Key: {
                    this.props.setTheme(Themes.HighContrast);
                    break;
                }
            }
        }
    }

    private setThemeClassName = (theme: string) => {
        let $body = $("body");
        if ($body) {
            $body.removeClass(Constants.Theme_Dark);
            $body.removeClass(Constants.Theme_HighContrast);
            $body.removeClass(Constants.Theme_Default);
    
            switch (theme) {
                case Constants.Theme_Default_Key:
                    $body.addClass(Constants.Theme_Default);
                    Cookie_Util.createCookie(Constants.Cookie_Theme, Constants.Theme_Default_Key, 1);
                    break;
                case Constants.Theme_HighContrast_Key:
                    $body.addClass(Constants.Theme_HighContrast);
                    Cookie_Util.createCookie(Constants.Cookie_Theme, Constants.Theme_HighContrast_Key, 1);
                    break;
                case Constants.Theme_Dark_Key:
                    $body.addClass(Constants.Theme_Dark);
                    Cookie_Util.createCookie(Constants.Cookie_Theme, Constants.Theme_Dark_Key, 1);
                    break;
            }
        }
    }
    
    private getSelectedThemeKey = (): string => {
        let $body = $("body");
        if ($body) {
            if ($body.hasClass(Constants.Theme_Dark)) {
                return Constants.Theme_Dark_Key;
            }
    
            if ($body.hasClass(Constants.Theme_Default)) {
                return Constants.Theme_Default_Key;
            }
    
            if ($body.hasClass(Constants.Theme_HighContrast)) {
                return Constants.Theme_HighContrast_Key;
            }
        }
    
        return "";
    }

    public render() {
        return (
            <FluentThemeProvider theme={this.createFluentTheme()}>
                {this.props.children}
            </FluentThemeProvider>
        );
    }
}


interface IThemeProviderProvidedProps extends IRapComponentProperties {
    inTeams: boolean;
}

interface IThemeProviderOwnProps extends IThemeProviderProvidedProps {
    theme: Constants.Themes;
}

export type IThemeProviderProps = IThemeProviderOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IThemeProviderProvidedProps): Partial<IThemeProviderOwnProps> {
    return {
        ...providedProps,
        theme: PageSelectors.getTheme(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    setTheme: PageActions.setTheme
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ThemeProvider);

