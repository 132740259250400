import * as React from "react";
import { connect } from "react-redux";
import { PageFeature } from "../../../../common/Constants";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PageActions } from "../../../components/Page/redux/PageActions";

import PageError from "../../../components/PageError/PageError";
import { IRapComponentProperties } from "../../../Layout";

interface IErrorBoundaryState {
    hasError?: boolean;
    error?: Error;
    errorInfo?: React.ErrorInfo;
}

//Props passed by parent component
interface IErrorBoundaryProvidedProps extends IRapComponentProperties {
    
}

//Props mapped from state object
interface IErrorBoundaryOwnProps extends IErrorBoundaryProvidedProps {}

// eslint-disable-next-line
export type IErrorBoundaryProps = IErrorBoundaryOwnProps & typeof ActionsToDispatch;

class ErrorBoundaryInitializer extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    public render() {
        const { hasError, error } = this.state;

        if (hasError) {
            return <PageError title="Something went wrong." details={error ? error.message + "\n" + error.stack : undefined} />;
        }

        return this.props.children;
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.props.logTelemetry(PageFeature.ErrorBoundary, "Unknown", {}, "[Unhandled UI Exception] " + error.message);
        this.setState({ hasError: true, error, errorInfo });
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IErrorBoundaryProvidedProps): Partial<IErrorBoundaryOwnProps> {
    return {
        ...providedProps
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry
};

const ErrorBoundary = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ErrorBoundaryInitializer);

export default ErrorBoundary;