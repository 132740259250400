import { RunnerRequestState, RunnerRequestView } from "../../../common/Constants";
import { IRetailStoreDto, IRunnerRequestDto } from "./../../../contracts/swagger/_generated";
import { IRunnerRequestsAwareState } from "./../Contracts";

export function getErrorMessage(state: IRunnerRequestsAwareState): string | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.errorMessage;
}

export function getRetailStores(state: IRunnerRequestsAwareState): IRetailStoreDto[] | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.retailStores;
}

export function getCreatedRunnerRequest(state: IRunnerRequestsAwareState): IRunnerRequestDto | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.createdRunnerRequest;
}

export function getRunnerRequests(state: IRunnerRequestsAwareState): IRunnerRequestDto[] | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.runnerRequests;
}

export function getSelectedRunnerRequest(state: IRunnerRequestsAwareState): IRunnerRequestDto | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.selectedRunnerRequest;
}

export function getSelectedRetailStore(state: IRunnerRequestsAwareState): IRetailStoreDto | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.selectedRetailStore;
}

export function getCanceledRunnerRequest(state: IRunnerRequestsAwareState): IRunnerRequestDto | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.canceledRunnerRequest;
}

export function getConfirmedRunnerRequest(state: IRunnerRequestsAwareState): IRunnerRequestDto | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.confirmedRunnerRequest;
}


export function getCompletedRunnerRequest(state: IRunnerRequestsAwareState): IRunnerRequestDto | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.completedRunnerRequest;
}

export function getSelectedFilterState(state: IRunnerRequestsAwareState): RunnerRequestState | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.selectedFilterState;
}

export function getUpdatedRetailStore(state: IRunnerRequestsAwareState): IRetailStoreDto | undefined {
    if (!state?.runnerRequestsViewState?.data) {
        return undefined;
    }

    return state.runnerRequestsViewState.data.updatedRetailStore;
}

export function getSelectedView(state: IRunnerRequestsAwareState): RunnerRequestView | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.selectedView;
}

export function getSearchTerm(state: IRunnerRequestsAwareState): string | undefined {
    if (!state?.runnerRequestsViewState?.ux) {
        return undefined;
    }

    return state.runnerRequestsViewState.ux.searchTerm;
}
