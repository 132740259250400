import React, { FC, useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Text } from "@fluentui/react";

import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { ISurveyResponseListItem } from "../../../../../../../../contracts/swagger/_generated";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { TextSizes } from "../../../../../../../../common/Constants";

import "./SurveyQuestion.scss";

interface ISurveyQuestionProvidedProps extends IRapComponentProperties {
    question: ISurveyResponseListItem;
}

interface ISurveyQuestionOwnProps {

}

export type ISurveyQuestionProps = ConnectedProps<typeof connector> & ISurveyQuestionProvidedProps;

const SurveyQuestionInitializer: FC<ISurveyQuestionProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [questionText, setQuestionText] = useState<string>(undefined);

    useEffect(() => {
        setIsLoading(true);
        props.fetchSurveyQuestion(props.question.questionId).then(q => {
            setQuestionText(q.questionText);
        }).catch(e => {
            console.error(e);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [props.question]);

    return (
        <LoadingContainer isLoading={isLoading} spinnerClassName="c-question-spinner">
            <div className="flex-row">
                <Text className="flex c-header" variant={TextSizes.large} nowrap>
                    {localizedStrings?.ContactsView?.survey?.q}{questionText}
                </Text>
            </div>

            <div className="flex-row">
                <Text className="flex c-content" variant={TextSizes.medium} nowrap>
                    {localizedStrings?.ContactsView?.survey?.a}{props.question.response}
                </Text>
            </div>
        </LoadingContainer>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: ISurveyQuestionProvidedProps): Partial<ISurveyQuestionOwnProps> {
    return {
        ...providedProps,
    };
}

const ActionsToDispatch = {
    fetchSurveyQuestion: ContactsActions.fetchSurveyQuestion
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const SurveyQuestion = connector(SurveyQuestionInitializer);