import { IPartialTheme } from "@fluentui/react"

// themes for fluent ui components, corresponding to the themes in _teams.scss
// themes can be created here https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html
export const defaultTheme: IPartialTheme = {
    palette: {
        themePrimary: '#6264a7',
        themeLighterAlt: '#f7f7fb',
        themeLighter: '#e1e1f1',
        themeLight: '#c8c9e4',
        themeTertiary: '#989ac9',
        themeSecondary: '#7173b0',
        themeDarkAlt: '#585a95',
        themeDark: '#4a4c7e',
        themeDarker: '#37385d',
        neutralLighterAlt: '#f8f8f8',
        neutralLighter: '#f4f4f4',
        neutralLight: '#eaeaea',
        neutralQuaternaryAlt: '#dadada',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c8c8',
        neutralTertiary: '#595959',
        neutralSecondary: '#373737',
        neutralPrimaryAlt: '#2f2f2f',
        neutralPrimary: '#000000',
        neutralDark: '#151515',
        black: '#0b0b0b',
        white: '#ffffff',
    },
    semanticColors: {
        inputBorder: "#e1dfdd",
    }
}

export const darkTheme: IPartialTheme = {
    palette: {
      themePrimary: '#7a7ccf',
      themeLighterAlt: '#050508',
      themeLighter: '#131421',
      themeLight: '#25253e',
      themeTertiary: '#494a7c',
      themeSecondary: '#6b6cb6',
      themeDarkAlt: '#8587d3',
      themeDark: '#9697da',
      themeDarker: '#b0b0e4',
      neutralLighterAlt: '#1c1a19',
      neutralLighter: '#252321',
      neutralLight: '#34322f',
      neutralQuaternaryAlt: '#3d3a37',
      neutralQuaternary: '#45423f',
      neutralTertiaryAlt: '#65615d',
      neutralTertiary: '#f6f6f5',
      neutralSecondary: '#f8f7f6',
      neutralPrimaryAlt: '#f9f9f8',
      neutralPrimary: '#f3f2f1',
      neutralDark: '#fcfcfb',
      black: '#fdfdfd',
      white: '#11100f',
    },
    semanticColors: {
        inputBorder: "#7a7ccf"
    }
}

export const highContrastTheme: IPartialTheme = {
    palette: {
      themePrimary: '#ffff00',
      themeLighterAlt: '#06040a',
      themeLighter: '#181027',
      themeLight: '#2d1e49',
      themeTertiary: '#5b3c93',
      themeSecondary: '#8558d7',
      themeDarkAlt: '#ffff00',
      themeDark: '#af88f7',
      themeDarker: '#c4a7f9',
      neutralLighterAlt: '#0b0b0b',
      neutralLighter: '#151515',
      neutralLight: '#252525',
      neutralQuaternaryAlt: '#2f2f2f',
      neutralQuaternary: '#373737',
      neutralTertiaryAlt: '#595959',
      neutralTertiary: '#c8c8c8',
      neutralSecondary: '#d0d0d0',
      neutralPrimaryAlt: '#dadada',
      neutralPrimary: '#ffffff',
      neutralDark: '#f4f4f4',
      black: '#f8f8f8',
      white: '#000000',
    },
    semanticColors: {
        inputBorder: "#ffffff"
    }
}