import React, { FC, useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";
import { NormalPeoplePicker, IPersonaProps, IBasePickerSuggestionsProps } from "@fluentui/react";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../../platform/Layout";
import { SearchBoxResolveDelay } from "../../../../Constants";
import { localizedStrings } from "../../../../localization/LocalizedStrings";

import "./BasePeoplePicker.scss";

//Props passed by parent component
interface IBasePeoplePickerProvidedProps extends IRapComponentProperties {
    options: IPeoplePickerObject[];
    value: string;
    onBlur?: () => void;
    onSelect?: (item: IPeoplePickerObject) => void;
    onFilterChanged?: (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number) => IPersonaProps[] | Promise<IPersonaProps[]>;
    limitResults?: number;
    className?: string;
    disabled?: boolean;
    pickerSuggestions?: IBasePickerSuggestionsProps;
    onEmptyResolveSuggestions?: (currentPersonas: IPersonaProps[]) => IPersonaProps[] | Promise<IPersonaProps[]>;
}

//Props mapped from state object
interface IBasePeoplePickerInitializerOwnProps extends IBasePeoplePickerProvidedProps {
}

export interface IPeoplePickerObject {
    persona: IPersonaProps;
    id: string;
    bookableResourceId?: string;
    data?: any;
}

// eslint-disable-next-line
export type IBasePeoplePickerInitializerProps = IBasePeoplePickerInitializerOwnProps & typeof ActionsToDispatch;

const BasePeoplePickerInitializer: FC<IBasePeoplePickerInitializerProps> = (props) => {

    useEffect(() => {
        // need this function to be on a timeout because the button has to render before we can set the on click function
        setTimeout(() => {
            // there's no prop to pass a callback for the remove button, so we need to add it manually
            // needs to be re-set every time the value changes
            $(`.${props.className} .ms-PickerItem-removeButton`).on('click', () => {
                if(props.onSelect) {
                    props.onSelect(undefined);
                }
            });
        }, 200);

        setTimeout(() => {
            // for some reason the people picker always has the 
            // aria-activedescendant attribute set to "sug-noResultsFound"
            // which is invalid and violates accessibility. So we need to remove it. 
            $(`.${props.className} .ms-BasePicker-input`).removeAttr("aria-activedescendant");
        }, 500)
    }, [props.value]);

    // when someone types into the search box
    const onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): IPersonaProps[] | Promise<IPersonaProps[]> => {
        //if the parent component provided an override, use that instead
        if(props.onFilterChanged) {
            return props.onFilterChanged(filterText, currentPersonas, props.limitResults);
        }

        // otherwise, filter the provided options based on the text
        if (filterText) {
            let filteredPersonas: IPersonaProps[] = [];
            
            props.options?.forEach(user => {
                if(doesTextStartWith(user.persona?.text, filterText)) {
                    filteredPersonas.push(user.persona);
                }
            });
            
            if(props.limitResults && filteredPersonas) {
                return filteredPersonas.slice(0, props.limitResults);
            }
            return filteredPersonas;
        } else {
            return [];
        }
    };

    // search through the options array to find the currently selected item. 
    // see below comment for why every object needs to be in the objects array, 
    // even though you can get options to show up just by returning them from the onFilterChanged function. 
    const getSelectedItem = () => {
        let item = props.options?.find(user => {
            return user.id == props.value
        });

        if(!item) {
            return [];
        }
        return [item?.persona];
    }

    // Its important to note that the persona props object that 
    // gets selected isnt actually what we're returning here. 
    // The user selects a persona props, and then we search through 
    // the provided options to find the matching object.
    // This allows us to return a more complex object that has other data on it unlike the persona props.
    // What this means though is that every selectable object needs to be in the options array. 
    // If you just return a bunch of stuff from the onFilterChanged function but those items arent in the 
    // options array, when you click on the option it wont show up in the box. 
    const onItemSelected = (item: IPersonaProps) => {
        let associate = props.options?.find(user => {
            return user.persona.secondaryText == item.secondaryText && user.persona.text == item.text;
        });

        if(associate && props.onSelect) {
            props.onSelect(associate);
        }
        return item;
    }

    return (
        <NormalPeoplePicker
            onResolveSuggestions={onFilterChanged}
            className={`c-column-1 ${props.className}`}
            key={'normal'}
            inputProps={{
                onBlur: (ev: React.FocusEvent<HTMLInputElement>) => props.onBlur(),
                'aria-label': localizedStrings.CreateEditAppointmentModal.peoplePickerLabel,
            }}
            selectionAriaLabel={localizedStrings.CreateEditAppointmentModal.selectedAriaLabel}
            removeButtonAriaLabel={localizedStrings.CreateEditAppointmentModal.removeAriaLabel}
            onItemSelected={onItemSelected}
            resolveDelay={SearchBoxResolveDelay}
            selectedItems={getSelectedItem()}
            disabled={props.disabled}
            onEmptyResolveSuggestions={props.onEmptyResolveSuggestions}
            pickerSuggestionsProps={props.pickerSuggestions}
        />
    );
}

function doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IBasePeoplePickerProvidedProps): Partial<IBasePeoplePickerInitializerOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    
};

export const BasePeoplePicker = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(BasePeoplePickerInitializer);
