import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import $ from "jquery";

import { IRapComponentProperties } from "../../../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../../../pages/Contracts";
import { HtmlEditor } from "../../../../../../../../../../platform/widgets/components/HtmlEditor/HtmlEditor";
import { IUserData } from "../../../../../../../../../../platform/components/Page/Contracts";
import { ContactsActions } from "../../../../../../../../redux/ContactsActions";
import { Themes } from "../../../../../../../../../../common/Constants";

import * as PageSelectors from "../../../../../../../../../../platform/components/Page/redux/PageSelectors";

import "./ContactNoteEditor.scss";

//Props passed by parent component
interface IContactNoteEditorProvidedProps extends IRapComponentProperties {
    htmlContent: string;
    isLoading: boolean;
    onChange: (val: string) => void;
    className?: string;
    disabled?: boolean;
    isFocused?: boolean;
}

interface IContactNoteEditorOwnProps {
    userInfo?: IUserData;
    theme?: Themes;
}

export type IContactNoteEditorProps = ConnectedProps<typeof connector> & IContactNoteEditorProvidedProps;

const ContactNoteEditorInitializer: FC<IContactNoteEditorProps> = (props) => {
    const _editor = useRef<HtmlEditor>();
    const _originalHtml = useRef<string>();
    const _editorContainer = useRef<HTMLDivElement>();
    const _maxEditorWidth = useRef<number>();

    //htmlContent has html tags in it
    const [isDirty, setIsDirty] = useState(false);

    // once the components loads we need to save the width of the editor
    // the width gets set as the max width so that it expands downward not outward
    useEffect(() => {
        if(props.isLoading == false) {
            setTimeout(() => {
                if(_editorContainer?.current) {
                    _maxEditorWidth.current = _editorContainer.current?.offsetWidth;
                }
            }, 500);
        }
    }, [props.isLoading]);


    // for some reason mixins dont work with the ::before or ::after tags.
    // So in order to theme the placeholder text in the editor, we have to add the styling manually.
    // The colors being used here are the secondary-text-colors in the teams styling file. 
    useEffect(() => {
        switch(props.theme) {
            case Themes.Dark: {
                $("<style type='text/css'> .lean-rooster.show-placeholder::before {color: #bebbb8;} </style>").appendTo("head");
                break;
            }
            case Themes.HighContrast: {
                $("<style type='text/css'> .lean-rooster.show-placeholder::before {color: #fefe00;} </style>").appendTo("head");
                break;
            }
            case Themes.Default: {
                $("<style type='text/css'> .lean-rooster.show-placeholder::before {color: #484644;} </style>").appendTo("head");
                break;
            }
        }
    }, [props.theme]);

    const _editorRef = (ref: HtmlEditor): void => {
        _editor.current = ref;
        if (ref) {
            _originalHtml.current = ref.htmlContent;
        }
    };

    const note = () => {
        let note = "";
        if (_editor) {
            note = _editor.current?.htmlContent;
        }
        return note;
    }

    const _checkIsDirty = (): boolean => {
        //all other topics (only has note field)
        if (_editor) {
            //if there is no difference between original note and current content always return false
            if (note() === props.htmlContent) {
                setIsDirty(false);
                return false;
            }

            let editorBoolean = _editor.current?.isDirty();
            setIsDirty(true);
            props.onChange(_editor.current?.htmlContent);
            return editorBoolean;
        }
        return false;
    };

    return (
        <div ref={_editorContainer} style={{maxWidth: _maxEditorWidth.current ? _maxEditorWidth.current : "none" }} className="flex-grow">
            <HtmlEditor 
                htmlContent={props.htmlContent}
                ref={_editorRef}
                showChromeBorder={true}
                editOnlyMode={false}
                mentionsEnabled={true}
                placeholder={"Enter notes here."}
                onIsDirty={_checkIsDirty}
                className="c-editor"
                autoFocus={props.isFocused}
                ariaLabel="Create note"
            />
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactNoteEditorProvidedProps): Partial<IContactNoteEditorOwnProps> {
    return {
        ...providedProps,
        userInfo: PageSelectors.getUserData(state),
        theme: PageSelectors.getTheme(state)
        
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    ContactNoteEditor: ContactsActions.createContactNote
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ContactNoteEditorInitializer);