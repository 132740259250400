import React from "react";
import { RapComponent } from "../../../platform/Layout";

import ErrorImg from "../../../styling/images/issue-tortoise.png";
import "./Error.scss";

export interface IErrorProps {
    description: string;
}

export class Error extends RapComponent<IErrorProps> {
    public render() {
        return (
            <div className="c-error flex-column flex-grow">
                <div className="c-error-img-box">
                    <img alt="Error" className="c-error-img" src={ErrorImg} />
                </div>
                <div className="c-error-text" role="alert">
                    {this.props.description}
                </div>
            </div>
        );
    }
}
