import React, { FC, useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import * as RunnerRequestsSelectors from "../../redux/RunnerRequestsSelectors";
import { RunnerRequestsActions } from "../../redux/RunnerRequestsActions";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { IconButton, Modal, SpinnerSize, TextField } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IRunnerRequestDto } from "../../../../contracts/swagger/_generated";
import { 
    FriendlyDateFormat, 
    RunnerRequestFloorLabels, 
    RunnerRequestOrderTypeLabels, 
    RunnerRequestStatus, 
    RunnerRequestStatusLabels, 
    RunnerRequestView, 
    TimeFormat 
} from "../../../../common/Constants";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";
import { IUserData } from "../../../../platform/components/Page/Contracts";
import { CancelRunnerRequest } from "../../components/CancelRunnerRequest/CancelRunnerRequest";
import moment from "moment";

import "./ViewRunnerRequestModal.scss";

const MultilineThreshold = 56;

interface IViewRunnerRequestModalProvidedProps extends IRapComponentProperties { }

interface IViewRunnerRequestModalOwnProps { 
    displayViewRunnerRequestModal: boolean;
    selectedRunnerRequest: IRunnerRequestDto;
    selectedView: RunnerRequestView;
    userData: IUserData;
}

export type IViewRunnerRequestModalProps = ConnectedProps<typeof connector> & IViewRunnerRequestModalProvidedProps;

const ViewRunnerRequestModalInitializer: FC<IViewRunnerRequestModalProps> = (props) => {
    const [ userAlias, setUserAlias ] = useState("");
    const [ isActionInProgress, setIsActionInProgress ] = useState(false);
    const [ isCanceling, setIsCanceling ] = useState(false);
    
    const _onCloseViewRunnerRequestModal = () => {
        props.updateModals({ displayViewRunnerRequestModal: false });
        props.resetSelectedRunnerRequest();
        setIsCanceling(false);
    }

    const _onChangeUserAlias = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setUserAlias(newValue);
    }

    const _onActionComplete = () => {
        props.updateModals({ displayViewRunnerRequestModal: false });
        setIsActionInProgress(false);
        setIsCanceling(false);
    }

    const _onClickPickRequest = () => {
        setIsActionInProgress(true);
        props.confirmRunnerRequest({
            runnerRequestId: props.selectedRunnerRequest.id,
            bohAlias: userAlias
        }).finally(_onActionComplete);
    }

    const _onClickBack = useCallback(() => {
        setIsCanceling(false);
    }, []);

    const _onConfirmCancel = useCallback((cancelReason: string) => {
        setIsActionInProgress(true);
        props.cancelRunnerRequest({
            runnerRequestId: props.selectedRunnerRequest.id,
            cancelAlias: userAlias,
            cancelReason: cancelReason
        }).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.RunnerRequestViewModal.cancelSuccess});  
        }).catch((resp: {errorCode: number, error: string}) => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.RunnerRequestViewModal.cancelFailure});  
        }).finally(_onActionComplete);
    }, [props.selectedRunnerRequest]);

    const _onClickCompleteRequest = () => {
        setIsActionInProgress(true);
        props.completeRunnerRequest({
            transactionOrderId: props.selectedRunnerRequest.transactionOrderId,
            fohAlias: userAlias
        }).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.RunnerRequestViewModal.completeSuccess});  
        }).catch((resp: {errorCode: number, error: string}) => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.RunnerRequestViewModal.completeFailure});  
        }).finally(_onActionComplete);
    }

    const {RunnerRequestViewModal: locStrings} = localizedStrings.RunnerRequestsView;
    useEffect(() => {
        setUserAlias(props.userData?.user ? `${props.userData.user.firstName} ${props.userData.user.lastName}` : "");
    }, [props.userData]);

    const _renderModalContent = () => {
        if (isCanceling) {
            return <CancelRunnerRequest onClickBack={_onClickBack} onConfirmCancel={_onConfirmCancel} />
        }

        return (
            <>
                <div className="flex-row c-modal-row">
                    <TextField 
                        label={locStrings.type}
                        disabled
                        className="c-modal-field"
                        defaultValue={RunnerRequestOrderTypeLabels[props.selectedRunnerRequest.orderType]}
                    />
                    <TextField 
                        label={locStrings.status}
                        disabled
                        className="c-modal-field"
                        defaultValue={RunnerRequestStatusLabels[props.selectedRunnerRequest.status]}
                    />
                </div>
                {props.selectedRunnerRequest.status === RunnerRequestStatus.Canceled && props.selectedRunnerRequest.cancelReason && (
                    <div className="flex-row c-modal-row">
                        <TextField
                            label={locStrings.cancelReason}
                            className="c-modal-field c-modal-large-field"
                            disabled
                            multiline={props.selectedRunnerRequest.cancelReason.length > MultilineThreshold}
                            autoAdjustHeight
                            defaultValue={props.selectedRunnerRequest.cancelReason}
                        />
                    </div>
                )}
                <div className="flex-row c-modal-row">
                    <TextField 
                        label={locStrings.requestDate}
                        disabled
                        className="c-modal-field"
                        defaultValue={moment(props.selectedRunnerRequest.createdTime).format(FriendlyDateFormat)}
                    />
                    <TextField 
                        label={locStrings.requestTime}
                        disabled
                        className="c-modal-field"
                        defaultValue={moment(props.selectedRunnerRequest.createdTime).format(TimeFormat)}
                    />
                </div>
                <div className="flex-row c-modal-row">
                    <TextField 
                        label={locStrings.requester}
                        disabled
                        className="c-modal-field"
                        defaultValue={props.selectedRunnerRequest.fohAlias}
                    />
                    <TextField 
                        label={locStrings.owner}
                        disabled
                        className="c-modal-field"
                        defaultValue={props.selectedRunnerRequest.bohAlias}
                    />
                </div>
                <div className="flex-row c-modal-row">
                    <TextField 
                        label={locStrings.location}
                        disabled
                        className="c-modal-field"
                        defaultValue={RunnerRequestFloorLabels[props.selectedRunnerRequest.floorLevel - 1]}
                    />
                    <TextField 
                        label={locStrings.locationNote}
                        disabled
                        className="c-modal-field"
                        defaultValue={props.selectedRunnerRequest.location}
                    />
                </div>
                <div className="c-item-list">
                    {props.selectedRunnerRequest.skuItems.map((skuItem, index) => {
                        return (
                            <div key={index} className="flex-row c-modal-row">
                                <TextField 
                                    label={locStrings.itemDescription}
                                    disabled
                                    className="c-modal-field c-modal-large-field"
                                    defaultValue={skuItem.skuDescription}
                                    multiline={skuItem.skuDescription.length > MultilineThreshold}
                                    autoAdjustHeight
                                />
                                <TextField 
                                    label={locStrings.qty}
                                    disabled
                                    className="c-modal-field"
                                    defaultValue={skuItem.quanitity.toString()}
                                />
                            </div>
                        )
                    })}
                </div>
                {props.selectedRunnerRequest.status !== RunnerRequestStatus.Canceled &&
                    props.selectedRunnerRequest.status !== RunnerRequestStatus.Complete && (
                        <>
                            <div className="flex-row c-modal-row">
                                <TextField 
                                    label={locStrings.alias}
                                    required
                                    className="c-modal-field"
                                    onChange={_onChangeUserAlias}
                                    defaultValue={props.userData?.user ? `${props.userData.user.firstName} ${props.userData.user.lastName}` : ""}
                                />
                            </div>
                            <div className="flex-row c-modal-row c-modal-buttons">
                                {props.selectedView === RunnerRequestView.Runner ? (
                                    <PrimaryButton 
                                        text={locStrings.pick}
                                        disabled={!userAlias ||userAlias.length === 0 || props.selectedRunnerRequest.status === RunnerRequestStatus.InProgress}
                                        onClick={_onClickPickRequest}
                                        allowDisabledFocus  
                                        className="c-modal-field"
                                    />
                                ) : (
                                    <PrimaryButton 
                                        text={locStrings.complete}
                                        onClick={_onClickCompleteRequest}
                                        allowDisabledFocus  
                                        disabled={!userAlias || userAlias.length === 0}
                                        className="c-modal-field"
                                    />
                                )}
                                <DefaultButton 
                                    text={locStrings.cancelRequest} 
                                    onClick={() => setIsCanceling(true)}
                                    disabled={!userAlias || userAlias.length === 0}
                                    allowDisabledFocus 
                                    className="c-modal-field"
                                />
                            </div>
                        </>
                    )
                }
            </>
        )
    }

     return (       
        <>
            {props.selectedRunnerRequest ? (
                <Modal  
                    isOpen={props.displayViewRunnerRequestModal}
                    containerClassName="c-rr-view-modal"
                    scrollableContentClassName={isActionInProgress || isCanceling ? "c-scrollable-container" : ""}
                >
                    <LoadingContainer isLoading={isActionInProgress} spinnerClassName={"c-spinner"} spinnerSize={SpinnerSize.large}>
                        <div className="c-modal-content">
                            <div className="flex-row c-modal-header">
                                <DialogHeader title={locStrings.runnerRequest}/>
                                <IconButton
                                    className="c-icon-cancel"
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel={"Close"}
                                    onClick={_onCloseViewRunnerRequestModal}
                                />
                            </div>
                            {_renderModalContent()}
                        </div>
                    </LoadingContainer>
                </Modal>
            ): (
                <></>
            )}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IViewRunnerRequestModalProvidedProps
): Partial<IViewRunnerRequestModalOwnProps> {
    return {
        ...providedProps,
        displayViewRunnerRequestModal: PageSelectors.getModals(state)?.displayViewRunnerRequestModal,
        selectedRunnerRequest: RunnerRequestsSelectors.getSelectedRunnerRequest(state),
        selectedView: RunnerRequestsSelectors.getSelectedView(state),
        userData: PageSelectors.getUserData(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    resetSelectedRunnerRequest: RunnerRequestsActions.resetSelectedRunnerRequest,
    cancelRunnerRequest: RunnerRequestsActions.cancelRunnerRequest,
    confirmRunnerRequest: RunnerRequestsActions.confirmRunnerRequest,
    completeRunnerRequest: RunnerRequestsActions.completeRunnerRequest,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const ViewRunnerRequestModal = connector(ViewRunnerRequestModalInitializer);
