import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Icon, Link, Persona, PersonaSize, Spinner, SpinnerSize } from "@fluentui/react";
import $ from "jquery";

import { IRapComponentProperties } from "../../../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../../../pages/Contracts";
import { IUserData } from "../../../../../../../../../../platform/components/Page/Contracts";
import { PageActions } from "../../../../../../../../../../platform/components/Page/redux/PageActions";
import { localizedStrings } from "../../../../../../../../../../common/localization/LocalizedStrings";
import { NoteTitle } from "../../../../../../../../../../common/Constants";
import { ContactsActions } from "../../../../../../../../redux/ContactsActions";

import ContactNoteEditor from "../ContactNoteEditor/ContactNoteEditor";

import * as PageSelectors from "../../../../../../../../../../platform/components/Page/redux/PageSelectors";

import "./CreateNote.scss";
import { LoadingContainer } from "../../../../../../../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface ICreateNoteProvidedProps extends IRapComponentProperties {
    contactId: string;
    className?: string;
}

interface ICreateNoteOwnProps {
    userInfo: IUserData;
}

export type ICreateNoteProps = ConnectedProps<typeof connector> & ICreateNoteProvidedProps;

const CreateNoteInitializer: FC<ICreateNoteProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState("");

    const _onCreateNote = () => {
        setIsLoading(true);
        props.createNote(props.contactId, { title: NoteTitle, body: htmlContent}).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.createSuccess });
            resetEditor();
        }).catch((error: string) => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.createFailure });
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    // theres no way to reset the value of the value of the editor as the value is controlled internally
    // need to reset it manually
    const resetEditor = () => {
        $('.rooster-editor > div').remove();
        $('.rooster-editor').addClass('empty view-mode show-placeholder');
    }

    return (
        <div className="c-editor-container flex-row">
            <Persona 
                imageInitials={`${props.userInfo?.user?.firstName?.charAt(0)}${props.userInfo?.user?.lastName?.charAt(0)}`}
                imageAlt={"user image"}
                imageUrl={props.userInfo?.pageData?.userProfileImage?.profileImage}
                size={PersonaSize.size32}
                className="c-persona"
            />
            <ContactNoteEditor 
                isLoading={isLoading}
                htmlContent={htmlContent}
                onChange={ (val: string) => { setHtmlContent(val) }}
            />
            <LoadingContainer isLoading={isLoading}>
                <Link className="c-save-link" disabled={isLoading} onClick={_onCreateNote}>
                    <Icon
                        iconName="send"
                        aria-label={localizedStrings.ContactsView.contactNotes.createNote}
                        role="img"
                    />
                </Link>
            </LoadingContainer>
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICreateNoteProvidedProps): Partial<ICreateNoteOwnProps> {
    return {
        ...providedProps,
        userInfo: PageSelectors.getUserData(state)
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateMessages: PageActions.updateMessages,
    createNote: ContactsActions.createContactNote
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(CreateNoteInitializer)