import { IReservationsAwareState, IAppointmentResources, ICreateAppointmentModel, IMessages } from "./../Contracts";
import {
    AppointmentCountDto,
    IAppointmentForCreationDto,
    IAppointmentsView,
} from "./../../../contracts/swagger/_generated";

export function getAppointmentsData(state: IReservationsAwareState): IAppointmentsView | undefined {
    if (!state || !state.reservationsViewState || !state.reservationsViewState.data) {
        return undefined;
    }

    return state.reservationsViewState.data.appointments;
}

export function getIsLoading(state: IReservationsAwareState): boolean {
    if (!state || !state.reservationsViewState || !state.reservationsViewState.ux) {
        return false;
    }

    return state.reservationsViewState.ux.isLoading;
}

export function getIsAppointmentTypesLoading(state: IReservationsAwareState): boolean {
    if (!state || !state.reservationsViewState || !state.reservationsViewState.ux) {
        return false;
    }

    return state.reservationsViewState.ux.isAppointmentTypesLoading;
}

export function getResources(state: IReservationsAwareState): IAppointmentResources | undefined {
    if (!state || !state.reservationsViewState || !state.reservationsViewState.data) {
        return undefined;
    }

    return state.reservationsViewState.data.resources;
}

export function getMessages(state: IReservationsAwareState): IMessages {
    if(!state || !state.reservationsViewState || !state.reservationsViewState.ux) {
        return undefined;
    }

    return state.reservationsViewState.ux.messages;
}

export function getAppointmentCountByState(state: IReservationsAwareState): AppointmentCountDto[] | undefined {
    if (!state || !state.reservationsViewState|| !state.reservationsViewState.ux) {
        return undefined;
    }
    return state.reservationsViewState.ux.appointmentCounts;
}

export function getCreateAppointmentModel(state: IReservationsAwareState): ICreateAppointmentModel | undefined {
    if(!state || !state.reservationsViewState || !state.reservationsViewState.data) {
        return undefined;
    }

    return state.reservationsViewState.data.createAppointmentModel;
}

export function getAppointmentForCreation(state: IReservationsAwareState) : IAppointmentForCreationDto | undefined {
    if(!state || !state.reservationsViewState || !state.reservationsViewState.data || !state.reservationsViewState.data.createAppointmentModel) {
        return undefined;
    }

    return state.reservationsViewState.data.createAppointmentModel.appointment;
}
