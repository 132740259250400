import { Dropdown, IconButton, IDropdownOption, PrimaryButton, SearchBox } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IClientelingViewState } from "../../../pages/Contracts";
import { IRapComponentProperties } from "../../Layout";
import { PageActions } from "../Page/redux/PageActions";
import { RunnerRequestsActions } from "../../../views/RunnerRequestsView/redux/RunnerRequestsActions";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { PageFeature, RunnerRequestView } from "../../../common/Constants";
import { IRetailStoreDto } from "../../../contracts/swagger/_generated";
import * as RunnerRequestsSelectors from "../../../views/RunnerRequestsView/redux/RunnerRequestsSelectors";
import * as PageSelectors from "../Page/redux/PageSelectors";
import audio from "../../../styling/sounds/Teams_UI_Sound_Completion.mp3";

import "./RunnerRequestCommandBar.scss";

interface IRunnerRequestCommandBarProvidedProps extends IRapComponentProperties {
}

interface IRunnerRequestCommandBarOwnProps extends IRunnerRequestCommandBarProvidedProps {
    displayRunnerRequestStateFilter: boolean;
    retailStores: IRetailStoreDto[];
    selectedRetailStore: IRetailStoreDto;
    selectedView: RunnerRequestView;
    isRunnerRequestRoleMember: boolean;
    isAdmin: boolean;
}

export type IRunnerRequestCommandBarProps = ConnectedProps<typeof connector> & IRunnerRequestCommandBarProvidedProps;

const RunnerRequestCommandBarInititalizer: FC<IRunnerRequestCommandBarProps> = (props) => {
    const [storeDropdownOptions, setStoreDropdownOptions] = useState<IDropdownOption<IRetailStoreDto>[]>();
    const newRequestAudio = new Audio(audio);
    
    useEffect(() => {
        let options: IDropdownOption<IRetailStoreDto>[] = [];
        if (props.retailStores && props.retailStores.length > 0) {
            props.retailStores.forEach(retailStore => {
                options.push({
                    key: retailStore.storeNumber,
                    text: `${retailStore.storeNumber} - ${retailStore.storeName}`,
                    data: retailStore
                });
            });
        }
        setStoreDropdownOptions(options);
    }, [props.retailStores]);

    const viewOptions: IDropdownOption[] = [
        { key: RunnerRequestView.Runner, text: localizedStrings.CommandBar.runner },
        { key: RunnerRequestView.FrontOfHouse, text: localizedStrings.CommandBar.frontOfHouse}
    ];

    const _onChangeRetailStore = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption<IRetailStoreDto>) => {
        props.setSelectedRetailStore(item.data);
    }

    const _onChangeView = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        props.setSelectedView(item.key as RunnerRequestView);
    }

    const _onRunnerRequestStateFilterClick = () => {
        props.logTelemetry(PageFeature.RunnerRequestCommandBar, "Runner request state filter clicked");
        props.updatePanels({ displayRunnerRequestStateFilter: !props.displayRunnerRequestStateFilter});
    }

    const _onClickRefresh = () => {
        if (props.selectedRetailStore) {
            props.fetchRunnerRequests(props.selectedRetailStore.storeNumber).then(runnerRequests => {
                // Play notification audio if there are any new requests
                if(props.runnerRequests?.length < runnerRequests.length) {
                    newRequestAudio.play();
                }
            });
        }
    }

    const _onChangeSearchTerm = useCallback((_, newValue?: string) => {
        props.updateSearchTerm(newValue);
    }, []);

    const _onClickNewRequest = () => {
        props.logTelemetry(PageFeature.RunnerRequestCommandBar, "New runner request button clicked");
        if (props.selectedRetailStore.isAvailable) {
            props.updateModals({ displayCreateRunnerRequestModal: true });
        } else {
            props.updateMessages({ 
                toastMessage: localizedStrings.formatString(
                    localizedStrings.CommandBar.queueIsClosed, 
                    props.selectedRetailStore.storeNumber, 
                    props.selectedRetailStore.storeName
                ) as string
            });
        }
    }

    return (
        <div className="c-rr-cmdbar flex-row">
            <div className="flex-column">
                <IconButton
                    ariaLabel={localizedStrings.CommandBar.stateFilter}
                    className="c-nav-icon"
                    iconProps={{ iconName: 'GlobalNavButton' }}
                    onClick={_onRunnerRequestStateFilterClick}
                />
            </div>
            <div className="flex-column c-dropdown">
                <Dropdown
                    ariaLabel={localizedStrings.CommandBar.storeSelector}
                    selectedKey={props.selectedRetailStore ? props.selectedRetailStore.storeNumber : undefined}
                    dropdownWidth={"auto"}
                    options={storeDropdownOptions}
                    onChange={_onChangeRetailStore}
                />
            </div>
            <div className="flex-column c-dropdown">
                <Dropdown
                    ariaLabel={localizedStrings.CommandBar.selectedView}
                    selectedKey={props.selectedView}
                    dropdownWidth={"auto"}
                    options={viewOptions}
                    onChange={_onChangeView}
                />
            </div>
            <div className="flex-column flex-grow" />
            <div className="flex-column c-refresh">
                <IconButton
                    ariaLabel={localizedStrings.CommandBar.refresh}
                    iconProps={{ iconName: "Refresh"}}
                    onClick={_onClickRefresh}
                />
            </div>
            <div className="flex-column c-search-box">
                <SearchBox 
                    placeholder={localizedStrings.CommandBar.find} 
                    styles={{ root: { flexDirection: "row-reverse" }}}
                    onChange={_onChangeSearchTerm}
                />
            </div>
            <div className="flex-column c-newrr-button">
                {props.selectedView === RunnerRequestView.FrontOfHouse &&
                    <PrimaryButton
                        disabled={!(props.isRunnerRequestRoleMember || props.isAdmin)}
                        text={localizedStrings.CommandBar.newRunnerRequest} 
                        onClick={_onClickNewRequest}
                    />  
               }                    
            </div>
        </div>
    )
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestCommandBarProvidedProps): Partial<IRunnerRequestCommandBarOwnProps> {
    return {
        ...providedProps,
        displayRunnerRequestStateFilter: PageSelectors.getPanels(state)?.displayRunnerRequestStateFilter,
        runnerRequests: RunnerRequestsSelectors.getRunnerRequests(state),
        retailStores: RunnerRequestsSelectors.getRetailStores(state),
        selectedRetailStore: RunnerRequestsSelectors.getSelectedRetailStore(state),
        selectedView: RunnerRequestsSelectors.getSelectedView(state),
        isRunnerRequestRoleMember: PageSelectors.getPageData(state)?.isRunnerRequestRoleMember,
        isAdmin: PageSelectors.getPageData(state)?.isAdmin
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updatePanels: PageActions.updatePanels,
    updateModals: PageActions.updateModals,
    updateMessages: PageActions.updateMessages,
    fetchRunnerRequests: RunnerRequestsActions.fetchRunnerRequests,
    setSelectedRetailStore: RunnerRequestsActions.setSelectedRetailStore,
    setSelectedView: RunnerRequestsActions.setSelectedView,
    updateSearchTerm: RunnerRequestsActions.updateSearchTerm
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const RunnerRequestCommandBar = connector(RunnerRequestCommandBarInititalizer);
