import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { connect } from "react-redux";

import AppointmentContainer from "./components/AppointmentContainer/AppointmentContainer";
import { ConnectedAppointmentCommandBar } from "../../platform/components/AppointmentCommandBar/AppointmentCommandBar";
import { IRapComponentProperties } from "../../platform/Layout";
import { IClientelingViewState } from "../../pages/Contracts";
import { AppointmentDetailsActions } from "../AppointmentDetailsView/redux/AppointmentDetailsActions";
import { AppointmentDetail } from "../../contracts/swagger/_generated";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import { PanelHandler } from "../../platform/components/PanelHandler/PanelHandler";
import { ModalHandler } from "../../platform/components/ModalHandler/ModalHandler";
import { ToastHandler } from "../../platform/components/ToastHandler/ToastHandler";
import { ErrorHandler } from "../../platform/components/ErrorHandler/ErrorHandler";
import { ErrorBoundaryFallback } from "../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

import * as AppointmentDetailsSelectors from "../AppointmentDetailsView/redux/AppointmentDetailsSelectors";
import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";

import "./AppointmentDetailsView.scss";

//Props passed by parent component
interface IAppointmentViewProvidedProps extends IRapComponentProperties {
}

interface IAppointmentViewOwnProps extends IAppointmentViewProvidedProps {
    selectedAppointmentDetails: AppointmentDetail;
    storeId: string;
    isLoading: boolean;
    errorMessage: string;
}

export type IAppointmentViewProps = IAppointmentViewOwnProps & typeof ActionsToDispatch;

const AppointmentView: FC<IAppointmentViewProps> = (props) => {
    let { confirmno } = useParams<any>();

    //fetch appointment when storeid loads or when selected appointment is changed
    useEffect(() => {
        if(props.storeId && props.selectedAppointmentDetails?.appointmentDetails?.confirmationNumber != confirmno) {
            props.fetchAppointmentDetail(props.storeId, confirmno);
        }
    }, [confirmno, props.storeId]);
    

    if(props.isLoading) {
        return (
            <div className="c-appointment-container">
                <LoadingSpinner/>
            </div>
        )
    }
    return (
        <>
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <ConnectedAppointmentCommandBar selectedAppointment = {props.selectedAppointmentDetails?.appointmentDetails} />
            </ErrorBoundary>
            <ErrorHandler errorMessage={props.errorMessage} onReset={() => props.setMessages({ errorMessage: undefined })} />
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                <AppointmentContainer/>
            </ErrorBoundary>
            <ModalHandler/>
            <PanelHandler/>
            <ToastHandler/>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentViewProvidedProps): Partial<IAppointmentViewOwnProps> {
    return {
        ...providedProps,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        selectedAppointmentDetails: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails,
        isLoading: AppointmentDetailsSelectors.getIsLoading(state),
        errorMessage: AppointmentDetailsSelectors.getMessages(state)?.errorMessage
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAppointmentDetail: AppointmentDetailsActions.fetchAppointmentDetail,
    setMessages: AppointmentDetailsActions.setMessages
};

export const ConnectedAppointmentView = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentView);