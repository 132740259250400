import { PublicClientApplication,Configuration, PopupRequest } from "@azure/msal-browser";

let location = window.location.toString();
let id = location.includes("localhost") ? "b4962548-bd64-4190-ab8e-b376c7e3b84c" : location.includes("dev") ? "9fd551fe-4a41-4625-a1da-934a78cfea07" : location.includes("ppe") ? "102ea622-e712-4a14-be1f-e65d7b210a3f" : "67b23b34-3627-41f1-ac47-e11e434d6cc3";


export const msalConfig: Configuration = {
    auth: {
        clientId: id,
        authority: "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    }
}

export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

export const msalInstance = new PublicClientApplication(msalConfig);
