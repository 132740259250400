import { produce } from "immer";
import { AppointmentDetailDto, AppointmentModel, IAppointmentDetailDto } from "../../../contracts/swagger/_generated";

import { IAppointmentDetailsViewState } from "../Contracts";
import { AppointmentDetailsActionsType, AppointmentDetailsActionTypes } from "./AppointmentDetailsActions";

export function appointmentDetailsReducer(state: IAppointmentDetailsViewState, action: AppointmentDetailsActionsType): IAppointmentDetailsViewState {
    return produce(state || ({} as IAppointmentDetailsViewState), draft => {
        switch (action.type) {
            case AppointmentDetailsActionTypes.InitAppointmentDetailsView: {
                draft.ux = {
                    isEditModeEnabled: false,
                    isDirty: false,
                    isLoading: false,
                    messages: {}
                }

                draft.data = {
                    selectedAppointment: {},
                    updateAppointment: {}
                }
                break;
            }

            case AppointmentDetailsActionTypes.CreateLeadSuccess: {
                break;
            }

            case AppointmentDetailsActionTypes.UpdateAppointmentSuccess: {
                draft.ux.isLoading = false;
                draft.data.updateAppointment.updates = {};
                break;
            }

            case AppointmentDetailsActionTypes.FetchAppointmentDetail: {
                draft.ux.isLoading = action.payload.silentFetch ? false : true;
                break;
            }

            case AppointmentDetailsActionTypes.FetchAppointmentDetailSuccess: {
                const { appointment } = action.payload;
                draft.data.selectedAppointment.selectedAppointmentDetails = appointment;
                draft.ux.isLoading = false;
                draft.data.updateAppointment.appointmentId = appointment.appointmentDetails.appointmentId;
                draft.data.updateAppointment.storeNumber = appointment.appointmentDetails.storeNumber;
                break;
            }

            case AppointmentDetailsActionTypes.CreateLeadFailure:
            case AppointmentDetailsActionTypes.FetchAppointmentQuestionsFailure:
            case AppointmentDetailsActionTypes.FetchAppointmentDetailFailure: {
                draft.ux.messages.errorMessage = action.payload;
                draft.ux.isLoading = false;
                break;
            }

            case AppointmentDetailsActionTypes.FetchAppointmentQuestionsSuccess: {                
                draft.data.selectedAppointment.appointmentQuestions = action.payload;
                break;
            }

            case AppointmentDetailsActionTypes.SetAppointmentUpdates: {
                if(action.payload) {
                    draft.data.updateAppointment.updates = {...state.data.updateAppointment.updates, ...action.payload};
                }
                else {
                    draft.data.updateAppointment.updates = {};
                }
                action.resolve();
                break;
            }

            case AppointmentDetailsActionTypes.SetMessages: {
                draft.ux.messages = {...state.ux.messages, ...action.payload};
                break;
            }

            case AppointmentDetailsActionTypes.ReceivedAppointmentUpsertMessage: {
                const updatedAppointmentModel: AppointmentModel = action.payload;

                if (!updatedAppointmentModel || !updatedAppointmentModel.appointmentDetails)
                {
                    break;
                }

                if (!draft.data.selectedAppointment.selectedAppointmentDetails || !draft.data.selectedAppointment.selectedAppointmentDetails.appointmentDetails)
                {
                    break;
                }

                if (updatedAppointmentModel.appointmentDetails.appointmentId != draft.data.selectedAppointment.selectedAppointmentDetails.appointmentDetails.appointmentId)
                {
                    break;
                }
                
                if (updatedAppointmentModel.appointmentDetails)
                {
                    let appointmentInterface = draft.data.selectedAppointment.selectedAppointmentDetails.appointmentDetails as IAppointmentDetailDto;

                    appointmentInterface.statusReason.label = updatedAppointmentModel.appointmentDetails.statusReason;

                    appointmentInterface.accessibilityDescription = updatedAppointmentModel.appointmentDetails.accessibilityDescription;
                    appointmentInterface.accessibilityRequested = updatedAppointmentModel.appointmentDetails.accessibilityRequested;

                    appointmentInterface.appointmentCategory.name = updatedAppointmentModel.appointmentDetails.appointmentCategory;
                    appointmentInterface.appointmentCategory.categoryId = updatedAppointmentModel.appointmentDetails.appointmentCategoryId;
                    appointmentInterface.appointmentType.name = updatedAppointmentModel.appointmentDetails.appointmentType;
                    appointmentInterface.appointmentType.appointmentTypeId = updatedAppointmentModel.appointmentDetails.appointmentTypeId;

                    appointmentInterface.appointmentDate = new Date(updatedAppointmentModel.appointmentDetails.appointmentDate);

                    appointmentInterface.scheduledEndDate = new Date(updatedAppointmentModel.appointmentDetails.scheduledEndDate);

                    appointmentInterface.actualStartDate = new Date(updatedAppointmentModel.appointmentDetails.actualStartDate);
                    appointmentInterface.actualEndDate = new Date(updatedAppointmentModel.appointmentDetails.actualEndDate);

                    appointmentInterface.timeZoneInfoId = updatedAppointmentModel.appointmentDetails.timeZoneInfoId;

                    appointmentInterface.smsAppointmentConfirmed = updatedAppointmentModel.appointmentDetails.smsAppointmentConfirmed;
                    appointmentInterface.customerOptIntoSms = updatedAppointmentModel.appointmentDetails.customerOptIntoSms;

                    appointmentInterface.smsAppointmentConfirmed = updatedAppointmentModel.appointmentDetails.smsAppointmentConfirmed;

                    appointmentInterface.assignedTo = updatedAppointmentModel.appointmentDetails.assignedTo;

                    appointmentInterface.poboOrderId = updatedAppointmentModel.appointmentDetails.poboOrderId;
                    
                    draft.data.selectedAppointment.selectedAppointmentDetails.appointmentDetails = new AppointmentDetailDto({...state.data.selectedAppointment.selectedAppointmentDetails.appointmentDetails, ...appointmentInterface});
                    draft.data.selectedAppointment.selectedAppointmentDetails.assignedTo = updatedAppointmentModel.assignedTo;
                }
                break;
            }

            default:
                return state;
        }
    });
}
