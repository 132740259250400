import React, { FC, useEffect } from "react";
import { Icon, Text } from "@fluentui/react";

import "./ErrorBoundaryFallback.scss";

export interface IErrorBoundaryFallbackProps {
    error?: any;
    resetErrorBoundary?: any;
}

export const ErrorBoundaryFallback: FC<IErrorBoundaryFallbackProps> = (props) => {
    useEffect(() => {
        console.error(props.error);
    }, [])
    
    return (
        <div className="errorContainer">
            <Icon iconName="IncidentTriangle"/>
            <Text>Error in component</Text>
        </div>
    )
}