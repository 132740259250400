import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Spinner, SpinnerSize, Text, Image } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { Pill } from "../../../../../../../../common/components/Pill/Pill";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { IContactDto, ICoreInsightsDto } from "../../../../../../../../contracts/swagger/_generated";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";

import "./PillHandler.scss";

//Props passed by parent component
interface IPillHandlerProvidedProps extends IRapComponentProperties {
    className?: string;
}

interface IPillHandlerOwnProps {
    coreInsights: ICoreInsightsDto;
    selectedContact: IContactDto;
}

export type IPillHandlerProps = ConnectedProps<typeof connector> & IPillHandlerProvidedProps;

const PillHandlerInitializer: FC<IPillHandlerProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if(props.selectedContact?.email && !props.coreInsights) {
            setIsLoading(true);
            props.fetchContactCoreInsights(props.selectedContact?.email).catch(e => {
                // empty catch to stop 404's from propogating upwards
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [props.selectedContact?.email]);

    const checkForStudentPill = () => {
        const studentSegment = localizedStrings.ContactsView?.segment.student as string;
        if(props.selectedContact && props.selectedContact?.email?.slice(-4) == localizedStrings.ContactsView?.contactDetails?.EduEnding) {
            return <Pill text={studentSegment} className={studentSegment.toLowerCase().replace(/\s/g,'')} />;
        }
    }

    const getPills = () => {
        if(props.coreInsights?.customerSegments) {
            return props.coreInsights?.customerSegments.map(s => {
                return <Pill text={s} className={s.toLowerCase()}/>
            });
        }
    }

    return (
        <div className="c-pill-container flex-row">
            {isLoading ? (
                <>
                </>
            ) : (
                <>
                    
                    {checkForStudentPill()}
                    {getPills()}
                </>
            )}
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IPillHandlerProvidedProps): Partial<IPillHandlerOwnProps> {
    return {
        ...providedProps,
        coreInsights: ContactsSelectors.getCustomerInsightsData(state)?.coreInsights,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactCoreInsights: ContactsActions.fetchContactCoreInsights
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const PillHandler = connector(PillHandlerInitializer);