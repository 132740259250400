import React, { FC, FormEvent, useState } from "react";
import { connect } from "react-redux";
import { ICommandBarItemProps, IDropdownOption} from "@fluentui/react";

import { IRapComponentProperties } from "../../Layout";
import { SearchBar } from "./components/SearchBar/SearchBar";
import { StandardBar } from "./components/StandardBar/StandardBar";
import { PageFeature, ViewType } from "../../../common/Constants";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { PageActions } from "../Page/redux/PageActions";
import { AppointmentModel, IAppointmentsView } from "../../../contracts/swagger/_generated";
import { IClientelingViewState } from "../../../pages/Contracts";
import { ReservationsActions } from "../../../views/ReservationsView/redux/ReservationsActions";

import * as ReservationsSelectors from "../../../views/ReservationsView/redux/ReservationsSelectors";

import "./ClientelingCommandBar.scss";

interface IClientelingCommandBarProvidedProps extends IRapComponentProperties {
    commandBarItems: ICommandBarItemProps[];
    onSelectedDateChanged: (newDate: any) => void;
}

interface IClientelingCommandBarOwnProps extends IClientelingCommandBarProvidedProps {
    appointmentsView: IAppointmentsView;
}

export type IClientelingCommandBarProps = IClientelingCommandBarOwnProps & typeof ActionsToDispatch;

const ClientelingCommandBar: FC<IClientelingCommandBarProps> = (props) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [autoFocusOnSearch, setAutoFocusOnSearch] = useState(false);
    const [oldAppointments, setOldAppointments] = useState<AppointmentModel[]>();

    const viewOptions: IDropdownOption[] = [
        { key: ViewType.Card, text: localizedStrings.CommandBar.cardView },
        { key: ViewType.List, text: localizedStrings.CommandBar.listView },
    ];

    const _onChangeView = (event: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption<any>, index?: number) => {
        props.logTelemetry(PageFeature.ChangeView, "Changed view to " + selectedOption.text);
        const selectedKey: string = selectedOption ? (selectedOption.key as string) : "";
        props.updateViewOptions({ selectedView: selectedKey });
    }

    const _onActivateSearch = (isSearchOpen: boolean) => {
        setIsSearchOpen(isSearchOpen);
        if(isSearchOpen) {
            setAutoFocusOnSearch(true);
            setOldAppointments(props.appointmentsView?.appointments);
        }
        else {
            props.updateAppointmentsView({ appointments: oldAppointments});
        }
    }
    
    if(isSearchOpen) {
        return <SearchBar
                    viewDropdownOptions={viewOptions}
                    onChangeView={_onChangeView}
                    onSearchSelected={() => _onActivateSearch(false)}
                />
    }
    return <StandardBar 
                commandBarItems={props.commandBarItems} 
                onSelectedDateChanged={props.onSelectedDateChanged} 
                onSearchSelected={() => _onActivateSearch(true)}
                viewDropdownOptions={viewOptions}
                onChangeView={_onChangeView}
                autoFocusOnSearch={autoFocusOnSearch}
            />
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IClientelingCommandBarProvidedProps): Partial<IClientelingCommandBarOwnProps> {
    return {
        ...providedProps,
        appointmentsView: ReservationsSelectors.getAppointmentsData(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updateViewOptions: PageActions.updateViewOptions,
    updateAppointmentsView: ReservationsActions.updateAppointmentsView
};

export const ConnectedClientelingCommandBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ClientelingCommandBar);
