import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Link, Icon, TextField, PrimaryButton } from "@fluentui/react";

import { IContactDto, IContactForUpdateDto } from "../../../../../../../../contracts/swagger/_generated";

import { Card } from "../../../../../../../../common/components/Card/Card";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { PermissionGate } from "../../../../../../../../common/components/PermissionGate/PermissionGate";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../../../../../platform/Layout";
import { UserRole } from "../../../../../../../../platform/components/Page/Contracts";

import { IClientelingViewState } from "../../../../../../../../pages/Contracts";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import "../../../ContactCard.scss"
import { PageActions } from "../../../../../../../../platform/components/Page/redux/PageActions";


interface IContactDemographicsProvidedProps extends IRapComponentProperties {
}

interface IContactDemographicsOwnProps extends IContactDemographicsProvidedProps {
    selectedContact?: IContactDto;
}

interface IContactDemographicsState {
    form: IContactForUpdateDto;
    editing: boolean;
}

export type IContactDemographicsProps = ConnectedProps<typeof connector> & IContactDemographicsProvidedProps;

class ContactDemographicsInitializer extends RapComponent<IContactDemographicsProps, IContactDemographicsState>{
    constructor(props: IContactDemographicsProps){
        super(props);
        this.state = {
            form: {
                jobTitle: this.props.selectedContact?.jobTitle ? this.props.selectedContact?.jobTitle : "",
                company: this.props.selectedContact?.company ? this.props.selectedContact?.company: ""
            },
            editing: false
        }
    }

    private onSave = () => {
        this.props.editContact(this.props.selectedContact.contactId, this.state.form).then(() => {
            this.props.updateMessages({ toastMessage: localizedStrings.ContactsView?.profilePage.demographics.success });
            this.setState({editing: !this.state.editing});
        })
        .catch(() => {
            this.props.updateMessages({ toastMessage: localizedStrings.ContactsView?.profilePage.demographics.error });
            this.updateForm({jobTitle: this.props.selectedContact?.jobTitle, company: this.props.selectedContact?.company});
        });
    }

    private updateForm = (val: Partial<IContactForUpdateDto>) => {
        this.setState({form: {...this.state.form, ...val}});
    }

    public render(){

        return (
            <>
                <Card className="flex c-card">
                    <div className="flex-row">
                        <div className="c-card-header">
                            <div>
                                {localizedStrings.ContactsView?.profilePage.demographics.title}
                            </div>
                            <div className="c-left-icon">
                                <PermissionGate minimumUserRole={UserRole.Associate}>
                                    {!this.state.editing ? (
                                    <Link onClick={() => this.setState({editing: !this.state.editing})}>
                                        <Icon iconName="Edit" ariaLabel={localizedStrings.AppointmentDetailsView.AppointmentDetails.editLabel}/>
                                    </Link>
                                    ) : (
                                        <PrimaryButton onClick={() => this.onSave()}>{localizedStrings.ContactsView?.profilePage.demographics.save}</PrimaryButton>
                                    )}
                                </PermissionGate>      
                            </div>
                        </div>
                    </div>

                    <div className="c-card-body">
                        <div className="flex-row c-card-body-row">
                            <div className="flex-column c-card-content-title">
                                <div className="c-demographic-title">
                                    {localizedStrings.ContactsView?.profilePage.demographics.jobTitle}
                                </div>
                            </div>
                            <div className="flex-column c-card-content-content">
                                {this.state.editing ? (
                                    <TextField onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.updateForm({jobTitle: newValue})} 
                                    defaultValue={this.state.form.jobTitle} /> 
                                ) : (
                                    <Text>{this.state.form.jobTitle ? this.state.form.jobTitle : localizedStrings.ContactsView?.contactDetails?.noValue}</Text>
                                )}
                            </div>
                        </div>

                        <div className="flex-row c-card-body-row"></div>

                        <div className="flex-row c-card-body-row">
                            <div className="flex-column c-card-content-title">
                                <div className="c-demographic-title">
                                    {localizedStrings.ContactsView?.profilePage.demographics.company}
                                </div>
                            </div>
                            <div className="flex-column c-card-content-content">
                            {this.state.editing ? (
                                <TextField onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => this.updateForm({company: newValue})}
                                defaultValue={this.state.form.company} /> 
                            ) : (
                                <Text>{this.state.form.company ? this.state.form.company : localizedStrings.ContactsView?.contactDetails?.noValue}</Text>
                            )}
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        );
    }
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactDemographicsProvidedProps): Partial<IContactDemographicsOwnProps> {
    return {
        ...providedProps,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    editContact: ContactsActions.editContact,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactDemographics = connector(ContactDemographicsInitializer);
