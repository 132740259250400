import {
    IAppointmentDto,
    IAssociate,
    IContactDto,
    IContactNote,
    ICoreInsightsDto,
    ICSATDto,
    ICustomerInsightsDto,
    IProductRecommendationsItem,
    IPurchaseHistoryDto,
    ISurveyResponseDto
} from "../../contracts/swagger/_generated";

// Contacts view contracts here
export interface IContactsAwareState {
    contactsViewState: IContactsViewState;
}

export interface IContactsViewState {
    data?: IContactsDataViewState;
    ux?: IContactsUxViewState;
}

export interface IContactsDataViewState {
    storeAssociates?: IAssociate[];
    selectedContactInfo?: ISelectedContactInfo;
    selectedAppointmentId?: string;
    customerInsightsData?: ICustomerInsightsData;
}

export interface ISelectedContactInfo {
    selectedContact?: IContactDto;
    contactAppointments?: IAppointmentDto[];
    contactNotes?: IContactNote[];
}

export interface ICustomerInsightsData {
    legacyCustomerInsights?: ICustomerInsightsDto;
    coreInsights?: ICoreInsightsDto;
    purchaseHistory?: IPurchaseHistoryDto[];
    surveys?: ISurveyResponseDto[];
    csatScores?: ICSATDto;
}

export interface IContactsUxViewState {
    isLoading?: boolean;
    messages?: IMessages;
}

export interface IMessages {
    errorMessage?: string;
}

export class Area {
    public static Contacts = "Contacts";
}

export class TelemetryProperties {
    public static Action = "Action";
    public static ErrorMessage = "ErrorMessage";
}

export enum AssociateSearchType {
    None = 0,
    Name = 1,
    Email = 2,
    Invalid = 3
}
