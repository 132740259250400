/**
 * Maximum number of messages to have in the containers that announce() uses.
 */
const MaxAnnounceChildren = 1;
/**
 * Maximum number of containers for announce() to have per assertiveness level.
 */
const MaxAnnounceContainers = 10;

/**
 * Default number of milliseconds to wait before announcing the start of an operation.
 */
const DefaultAnnounceDelay = 1000;

/**
 * ID of the container for the announce() containers.
 */
const ParentContainerId = "utils-accessibility-announce";

let nextId = 0;

/**
 * Gets the parent container for all the announce containers.
 */
function getAnnounceContainer(): HTMLElement {
    let container = document.getElementById(ParentContainerId);
    if (!container) {
        container = document.createElement("div");
        container.id = ParentContainerId;
        container.classList.add("visually-hidden");
        document.body.appendChild(container);
    }
    return container;
}

/**
 * Causes screen readers to read the given message.
 * @param message
 * @param assertive if true, the screen reader will read the announcement immediately, instead of waiting for "the next graceful opportunity"
 */
export function announce(message: string | undefined, assertive = false, pause = 100) {
    if (!message) {
        return;
    }
    const assertiveness = assertive ? "assertive" : "polite";
    const parentContainer = getAnnounceContainer();
    const containerList = parentContainer.getElementsByClassName(assertiveness);

    let container = (containerList.length > 0 ? containerList[containerList.length - 1] : null) as HTMLElement;

    if (!container || container.childElementCount >= MaxAnnounceChildren) {
        container = document.createElement("div");
        container.id = ParentContainerId + nextId++;
        container.setAttribute("aria-live", assertiveness);
        container.classList.add(assertiveness);
        container.setAttribute("aria-relevant", "additions");
        parentContainer.appendChild(container);
        // getElementsByClassName() returns a live list so the new container is already in this list
        if (containerList.length > MaxAnnounceContainers) {
            // remove old containers
            parentContainer.removeChild(containerList[0]);
        }
        window.setTimeout(() => {
            // live regions get announced on update not create, so wait a bit and then update
            announce(message, assertive);
        }, pause);
    } else {
        const child = document.createElement("p");
        child.textContent = message;
        container.appendChild(child);
        // toggling the visibility like this seems to help Edge
        container.style.visibility = "hidden";
        container.style.visibility = "visible";
    }
}

export interface ProgressAnnouncerOptions {
    /**
     * The amount of time to wait after the operation has begun before announcing the start (in
     * milliseconds).
     */
    announceStartDelay?: number;
    /**
     * The message to announce to the user at the start of the operation. Leave blank or undefined
     * for no announcement.
     */
    announceStartMessage?: string;
    /**
     * The message to announce to the user at the end of the operation. Leave blank or undefined
     * for no announcement.
     */
    announceEndMessage?: string;
    /**
     * The message to announce to the user if the operation fails. Leave blank or undefined for no
     * announcement.
     */
    announceErrorMessage?: string;
}

/**
 * Class for announcing, through a screen reader, when a single operation begins and ends. Supports
 * a delay before the starting announcement so that quick operations don't trigger announcements.
 *
 * To use, create a ProgressAnnouncer, and call completed()
 */
export class ProgressAnnouncer {
    /**
     * Create a ProgressAnnouncer for a promise that will announce promise start and completion/rejection.
     * @param promise
     * @param options
     */
    public static forPromise<T>(promise: Promise<T>, options: ProgressAnnouncerOptions) {
        const announcer = new ProgressAnnouncer(options);
        promise.then(
            () => {
                announcer.announceCompleted();
            },
            () => {
                announcer.announceError();
            }
        );
        return announcer;
    }
    private _options: ProgressAnnouncerOptions;
    private _startAnnounced = false;
    private _completed = false;

    public constructor(options: ProgressAnnouncerOptions) {
        this._options = options;
        this._start();
    }

    /**
     * Call this method when the operation has completed. This will cause the end message to be
     * announced if the start message was announced.
     */
    public announceCompleted() {
        if (!this._completed) {
            this._completed = true;

            if (this._startAnnounced) {
                announce(this._options.announceEndMessage);
            }
        }
    }

    /**
     * Call this method if the operation completes with an error. This will cause the error message
     * to be announced regardless of whether or not the start message was announced.
     */
    public announceError() {
        if (!this._completed) {
            this._completed = true;
            announce(this._options.announceErrorMessage);
        }
    }

    /**
     * Call this method to stop any announcements from being made
     */
    public cancel() {
        this._completed = true;
    }

    private _start() {
        // this._announceDelay = Utils_Core.delay(this, this._options.announceStartDelay !== undefined ? this._options.announceStartDelay : DefaultAnnounceDelay, () => {
        window.setTimeout(
            () => {
                if (!this._completed) {
                    announce(this._options.announceStartMessage);
                }
                this._startAnnounced = true;
            },
            this._options.announceStartDelay !== undefined ? this._options.announceStartDelay : DefaultAnnounceDelay
        );
    }
}