import { FC } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRapComponentProperties } from "../../Layout";
import { AppointmentDetail } from "../../../contracts/swagger/_generated";
import { ActionButton, IconButton, format, Text } from "@fluentui/react";

import { IClientelingViewState } from "../../../pages/Contracts";
import { PageFeature } from "../../../common/Constants";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";

import { IPanels } from "../Page/Contracts";
import { PageActions } from "../Page/redux/PageActions";
import * as PageSelectors from "../Page/redux/PageSelectors";

import * as LocaleUtils from "../../../platform/core/util/Locale";

import * as  FeatureManagementSelectors from "../../../views/FeatureManagement/redux/FeatureManagementSelectors";

import "./ContactCommandBar.scss";

//Props passed by parent component
interface IContactCommandBarProvidedProps extends IRapComponentProperties {
    selectedAppointment?: AppointmentDetail;
}

interface IContactCommandBarOwnProps extends IContactCommandBarProvidedProps {
    panels: IPanels;
    enableContact_V2: boolean;
}

export type IContactCommandBarProps = IContactCommandBarOwnProps & typeof ActionsToDispatch;

const ContactCommandBar: FC<IContactCommandBarProps> = (props) => {
    const navigate = useNavigate();

    const _onClientelingPanelMenuClick = () => {
        props.logTelemetry(PageFeature.ClientelingCommandBar, "Contact Panel Menu clicked");
        props.updatePanels({ displayContactMenuPanel: !props.panels.displayContactMenuPanel });
    }

    const getCustomerFullName = (appointment: AppointmentDetail): string => {
        if (appointment && appointment.appointmentContact && appointment.appointmentContact.firstName && appointment.appointmentContact.lastName) {
            return format("{0} {1}", appointment.appointmentContact.firstName, appointment.appointmentContact.lastName);
        }
        return "";
    };

    return (
        <>
            {props.selectedAppointment ? (
                <>
                    <div className="c-contact-commandBar flex-row">
                        {props.enableContact_V2 ? (
                            <div className="flex-column">
                                <IconButton
                                    ariaLabel="Contact View Dropdown"
                                    className="c-hamburger-icon"
                                    iconProps={{ iconName: 'GlobalNavButton' }}
                                    onClick={_onClientelingPanelMenuClick}
                                />
                            </div>
                        ) : null}
                        <div className="flex-column">
                            <ActionButton
                                className="c-reservations-button"
                                ariaLabel={localizedStrings.AppointmentCommandBar?.reservations}
                                onClick={() => navigate(`/${LocaleUtils.getLocale()}/reservations`)}>
                                {localizedStrings.AppointmentCommandBar?.reservations} {'>'}
                            </ActionButton>
                        </div>
                        <div className="flex-column">
                            <ActionButton
                                className="c-appointment-title"
                                ariaLabel={props.selectedAppointment?.appointmentDetails?.appointmentType?.name}
                                onClick={() => navigate(`/${LocaleUtils.getLocale()}/reservations/${props.selectedAppointment?.appointmentDetails?.confirmationNumber}`)}>
                                {props.selectedAppointment?.appointmentDetails?.appointmentType?.name} {'>'}
                            </ActionButton>
                        </div>
                        <div className="flex-column">
                            <Text className="c-contact-name">{getCustomerFullName(props.selectedAppointment)}</Text>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div>
                        Error
                    </div>
                </>
            )}
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactCommandBarProvidedProps): Partial<IContactCommandBarOwnProps> {
    return {
        ...providedProps,
        panels: PageSelectors.getPanels(state),
        enableContact_V2: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableContact_V2", false),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updatePanels: PageActions.updatePanels
};

export const ConnectedContactCommandBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ContactCommandBar);
