import React from "react";
import { connect } from "react-redux";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../../platform/Layout";
import { IAppointmentCategory } from "../../../../../contracts/swagger/_generated";
import { AppointmentModalActions } from "../../redux/AppointmentModalActions";

import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";
import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors";

//Props passed by parent component
interface IAppointmentCategoryOptionPickerProvidedProps extends IRapComponentProperties {
    onSelect: (val: IDropdownOption) => void;
    appointmentTypeId: string;
    onBlur?: () => void;
    value?: string;
    disabled?: boolean;
}

//Props mapped from state object
interface IAppointmentCategoryOptionPickerOwnProps extends IAppointmentCategoryOptionPickerProvidedProps {
    appointmentCategories: IAppointmentCategory[];
    storeId: string;
}

// eslint-disable-next-line
export type IAppointmentCategoryOptionPickerProps = IAppointmentCategoryOptionPickerOwnProps & typeof ActionsToDispatch;

class AppointmentCategoryOptionPicker extends RapComponent<IAppointmentCategoryOptionPickerProps, {}> {
    constructor(props: IAppointmentCategoryOptionPickerProps, context: IRapComponentContext) {
        super(props, context);
        if(!this.props.appointmentCategories && this.props.appointmentTypeId) {
            this.props.fetchAppointmentCategories(props.storeId, this.props.appointmentTypeId);
        }
    }

    public render() {
        return (
            <>
                <Dropdown
                    label={localizedStrings.CreateEditAppointmentModal.topic}
                    options={this._getOptions(this.props.appointmentCategories, this.props.value)}
                    onChange={this._onCategoryChange}
                    required={true}
                    onBlur={this.props.onBlur}
                    selectedKey={this.props.value ? this.props.value : null}
                    disabled={this.props.disabled ? this.props.disabled : !this.props.appointmentCategories}
                />
            </>
        );
    }

    private _onCategoryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        this.props.onSelect(option);
    };

    private _getOptions(categories: IAppointmentCategory[], categoryId: string): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        if (categories) {
            categories.forEach((category: IAppointmentCategory) => {
                options.push({
                    key: category.categoryId,
                    text: category.name,
                    title: category.name
                });
            });
        }
        return options;
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(
    state: IClientelingViewState,
    providedProps: IAppointmentCategoryOptionPickerProvidedProps
): Partial<IAppointmentCategoryOptionPickerOwnProps> {
    return {
        ...providedProps,
        appointmentCategories: AppointmentModalSelectors.getResources(state)?.appointmentCategories,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAppointmentCategories: AppointmentModalActions.fetchAppointmentCategories
};

export const AppointmentCategoryOptionPickerInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentCategoryOptionPicker);
