import { format } from "office-ui-fabric-react/lib/Utilities";
import { INoteDto, IDeviceDto, IEventModel, IAppointmentDto, IActivityDto, ActivityType } from "../../../../contracts/swagger/_generated";
import moment from "moment";

const Resources = require("./../Resources.json");
export interface IContactActivitiesUtils {
    createEventActivitiesItem(event: IEventModel): IActivityDto;
    createDeviceActivitiesItem(device: IDeviceDto): IActivityDto;
    createAppointmentActivitiesItem(appointment: IAppointmentDto): IActivityDto;
    createNoteActivitiesItem(note: INoteDto): IActivityDto;
    getIconName(type: ActivityType): string;
}

export class ContactActivitiesUtils implements IContactActivitiesUtils {
    public createEventActivitiesItem = (event: IEventModel): IActivityDto => {
        return {
            title: event.event.name,
            name: format(
                "{0} {1}-{2}",
                moment(event.event.startDate).format("ddd, MMMM Do, YYYY"),
                moment(event.event.startDate).format("h:mm A"),
                moment(event.event.endDate).format("h:mm A")
            ),
            type: ActivityType.Event,
            startTime: event.event.startDate,
            endTime: event.event.endDate
        };
    };

    public createDeviceActivitiesItem = (device: IDeviceDto): IActivityDto => {
        return {
            title: device.make,
            name: format(
                "{0}: {1}, {2}: {3}, {4}: {5}",
                Resources.Model,
                device.model,
                Resources.SerialNumber,
                device.serialNumber,
                Resources.Condition,
                device.condition
            ),
            type: ActivityType.Device,
            startTime: null,
            endTime: null
        };
    };

    public createEquipmentPurchasedActivitiesItem = (equipment: IActivityDto): IActivityDto => {
        const title = equipment.title? equipment.title : "";
        const name = equipment.name? equipment.name: "";

        return {
            title: name + " " + title,
            name: format(
                "{0} {1}",
                moment(equipment.activityTime).format("ddd, MMMM Do, YYYY"),
                moment(equipment.activityTime).format("h:mm A")
            ),
            type: ActivityType.Device,
            startTime: equipment.startTime,
            endTime: equipment.endTime
        };
    };

    public createAppointmentActivitiesItem = (appointment: IAppointmentDto): IActivityDto => {
        const startDate = appointment.actualStartDate ? appointment.actualStartDate : appointment.appointmentDate;
        const endDate = appointment.actualEndDate ? appointment.actualEndDate : appointment.scheduledEndDate;
        return {
            title: appointment.subject ? appointment.subject : "",
            name: format(
                "{0} {1}-{2}",
                moment(startDate).format("ddd, MMMM Do, YYYY"),
                moment(startDate).format("h:mm A"),
                moment(endDate).format("h:mm A")
            ),
            type: ActivityType.Appointment,
            startTime: startDate,
            endTime: endDate
        };
    };

    public createNoteActivitiesItem = (note: INoteDto): IActivityDto => {
        const createdDate = note.createdOn ? note.createdOn : moment().toDate();
        const createdHtml = format(
            "<br>{0} {1} {2} {3}</br>",
            moment(createdDate).format("ddd, MMMM Do, YYYY"),
            moment(createdDate).format("h:mm A"),
            Resources.By,
            note.creator && note.creator.email ? note.creator.email : ""
        );
        return {
            title: note.title ? note.title : "",
            name: note.body + createdHtml,
            type: ActivityType.Other,
            startTime: createdDate,
            endTime: null
        };
    };

    public createIncidentActivitiesItem = (incident: IActivityDto): IActivityDto => {
        const title = incident.title? incident.title : "";
        const name = incident.name? incident.name: "";

        return {
            title: title + " " + name,
            name: format(
                "{0} {1}",
                moment(incident.activityTime).format("ddd, MMMM Do, YYYY"),
                moment(incident.activityTime).format("h:mm A")
            ),
            type: ActivityType.Incident,
            startTime: incident.activityTime,
            endTime: null
        };
    };

    public createInvoiceActivitiesItem = (invoice: IActivityDto, invoiceDetails: IActivityDto[]): IActivityDto => {
        const invoiceTitle = invoice.title? invoice.title : "" + " " +
                    invoice.name? invoice.name: "";

        let display = "";
        const matches = invoiceDetails.filter(x => x.actualActivityId === invoice.actualActivityId);
        for (const invoiceDetail of matches) {
            const title = invoiceDetail.title? invoiceDetail.title : "";
            const name = invoiceDetail.name? invoiceDetail.name: "";
            display += title + " " + name + "<br>";
        }

        display += format(
            "{0} {1}",
            moment(invoice.activityTime).format("ddd, MMMM Do, YYYY"),
            moment(invoice.activityTime).format("h:mm A")
        );

        return {
            title: invoiceTitle,
            name: display,
            type: ActivityType.InvoiceDetail,
            startTime: invoice.activityTime,
            endTime: null
        };
    };

    public createUpcomingAppointments = (appointments: IAppointmentDto[]): IActivityDto[] => {
        if (appointments == null)
            return null;

        const upcomingAppointments = [];
        const todayDate = moment();
        for (const appointment of appointments) {
            const startDate = appointment.actualStartDate ? appointment.actualStartDate : appointment.appointmentDate;
            if (todayDate.isBefore(startDate)) {
                upcomingAppointments.push(this.createAppointmentActivitiesItem(appointment));
            }
        }

        return upcomingAppointments;
    };

    public createUpcomingEvents = (events: IEventModel[]): IActivityDto[] => {
        if (events == null)
            return null;

        const upcomingEvents = [];
        const todayDate = moment();
        for (const event of events) {
            const startDate = event.event.startDate;
            if (todayDate.isBefore(startDate)) {
                upcomingEvents.push(this.createEventActivitiesItem(event));
            }
        }

        return upcomingEvents;
    };

    public mergeActivities = (activities: IActivityDto[], activitesToAdd: IActivityDto[]) => {
        if (activitesToAdd && activitesToAdd.length > 0) {
            activitesToAdd.forEach((activity: IActivityDto) => {
                activities.push(activity);
            });
        }
    }

    public sortActivities = (activities: IActivityDto[]): IActivityDto[] => {
        if (activities == null) {
            return null;
        }

        let sortedActivities = activities.sort((a, b) => {
            if (a.startTime == null || b.startTime == null) {
                return -1;
            }
            return a.startTime.valueOf() - b.startTime.valueOf();
        });
        return sortedActivities;
    };

    public getIconName = (type: ActivityType): string => {
        if (type) {
            switch (type) {
                case ActivityType.Device:
                    return "Devices2";
                case ActivityType.Event:
                    return "Event";
                case ActivityType.Appointment:
                    return "Calendar";
                case ActivityType.Incident:
                    return "Repair";
                case ActivityType.InvoiceDetail:
                    return "Shop";
                case ActivityType.Other:
                    return "QuickNote";
            }
        }
        return "";
    };
}
