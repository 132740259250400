import { buffers, EventChannel, eventChannel, SagaIterator } from "redux-saga";
import { takeEvery, call, put, getContext, select, take, apply } from "redux-saga/effects";
import { RapPageService, IRapPageService } from "../../../services/Page";
import { ActionsOfType } from "../../../redux/ActionHelper";
import { PageActions, PageActionsType, PageActionTypes } from "./PageActions";
import { PageTelemetryLogger } from "../PageTelemetryLogger";
import { IRapPageContext } from "../../../Context";
import { AppointmentModel, ClientelingApiClient, IClientelingApiClient, Page, StoreAssociateDto, StoreDto } from "../../../../contracts/swagger/_generated";
import * as PageSelectors from "./../../Page/redux/PageSelectors";
import * as LocalConstants from "../../../../common/Constants";
import { IRapAuthenticationService } from "../../../services/Authentication";
import { AuthenticationResult } from "@azure/msal-browser";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IViewOptions } from "../Contracts";
import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

import { AppointmentDetailsActions } from "../../../../views/AppointmentDetailsView/redux/AppointmentDetailsActions";

import { ReservationsActions } from "../../../../views/ReservationsView/redux/ReservationsActions";

export function* pageSaga(): SagaIterator {
    yield takeEvery(PageActionTypes.FetchFeedbackURL, fetchFeedbackURL);
    yield takeEvery(PageActionTypes.FetchStores, fetchStores);
    yield takeEvery(PageActionTypes.LogTelemetry, logTelemetry);
    yield takeEvery(PageActionTypes.FetchPageData, fetchPageData);
    yield takeEvery(PageActionTypes.UpdateTokenSuccess, updateTokenSuccess);
    yield takeEvery(PageActionTypes.ListenToAppointmentUpsertMessage, listenToAppointmentUpsertMessage);
}

export function* fetchStores(action: ActionsOfType<PageActionsType, PageActionTypes.FetchStores>): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const storesData = ((yield call(
                [clientelingClient, clientelingClient.getStores],
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            )) as unknown) as StoreDto[];
            yield put(PageActions.fetchStoresSuccess(storesData));
        } else {
            yield put(PageActions.fetchStoresFailure("Page context is invalid."));
        }
    } catch (error) {
        console.error(error);
        var errorCode = (error as any).status;
        let message: string = localizedStrings.Errors.serverError;
        if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
        yield put(PageActions.fetchStoresFailure(message));
    }
}

export function* fetchFeedbackURL(action: ActionsOfType<PageActionsType, PageActionTypes.FetchFeedbackURL>): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const feedbackData = ((yield call([clientelingClient, clientelingClient.getFeedbackURL])) as unknown) as string;
            yield put(PageActions.fetchFeedbackURLSuccess(feedbackData));
        } else {
            yield put(PageActions.fetchFeedbackURLFailure("Page context is invalid."));
        }
    } catch (error) {
        console.error(error);
        var errorCode = (error as any).status;
        let message: string = localizedStrings.Errors.serverError;
        if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
        yield put(PageActions.fetchFeedbackURLFailure(message));
    }
}

export function* logTelemetry(telemetryAction: ActionsOfType<PageActionsType, PageActionTypes.LogTelemetry>): SagaIterator {
    const { feature, action, properties, errorMessage } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        const storeId = (((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions)?.selectedStore;
        var telemetryLogger: PageTelemetryLogger = new PageTelemetryLogger(pageContext, feature, action, storeId);

        try {
            if (errorMessage && errorMessage.length > 0) {
                yield call(telemetryLogger.captureError, errorMessage);
            } else {
                yield call(telemetryLogger.captureTelemetry, properties ? properties : {});
            }
        } catch (error) {
            yield call(telemetryLogger.captureError, errorMessage);
        }
    }
}

export function* fetchPageData(action: ActionsOfType<PageActionsType, PageActionTypes.FetchPageData>): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        if (pageContext) {
            const pageService: IRapPageService = ((yield call(
                [pageContext, pageContext.getService],
                "IRapPageService"
            )) as unknown) as RapPageService;
            const pageData: Page = ((yield call([pageService, pageService.getData])) as unknown) as Page;
            if (pageData.pageDataError && pageData.pageDataError.status === LocalConstants.HttpErrorCodes.UnAuthorized) {
                yield put(PageActions.fetchPageDataFailure(pageData.pageDataError.message));
            }
            var alias = pageData?.userInfo?.preferredAliasEmail != null ? pageData?.userInfo?.preferredAliasEmail : pageData?.userInfo?.fullEmail;
            const clientelingClient: IClientelingApiClient = ((yield call([pageContext, pageContext.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            if (pageData && pageData.userInfo && alias) {
                const associateDetails = ((yield call(
                    [clientelingClient, clientelingClient.getAssociateDetails],
                    alias,
                )) as unknown) as StoreAssociateDto;
                yield put(PageActions.fetchAssociateDetailsSuccess(associateDetails));
            }

            yield put(PageActions.fetchPageDataSuccess(pageData));
        }
    } catch (error) {
        console.error(error);
        var errorCode = (error as any).status;
        let message: string = localizedStrings.Errors.serverError;
        if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
        yield put(PageActions.fetchPageDataFailure(message));
    }
}

export function* updateTokenSuccess(action: ActionsOfType<PageActionsType, PageActionTypes.UpdateTokenSuccess>): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");
        const payload: AuthenticationResult = action.payload;
        if (pageContext) {
            const authService: IRapAuthenticationService = ((yield call(
                [pageContext, pageContext.getService],
                "IRapAuthenticationService"
            )) as unknown) as IRapAuthenticationService;

            authService.setIdToken(payload.idToken);
        }
    } catch (error) {
        console.error(error);
        var errorCode = (error as any).status;
        let message: string = localizedStrings.Errors.serverError;
        if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
        yield put(PageActions.fetchPageDataFailure(message));
    }
}

/////////////
// Signalr //
/////////////
function createSocket(idtoken: string): HubConnection {
    var hubUrl = "/hubs/clientelinghub";
    const connection = new HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(hubUrl, { accessTokenFactory: () => idtoken })
        .configureLogging(LogLevel.Information)
        .build();

    return connection;
}

function subscribeToAppointmentUpsertMessage(connection: HubConnection): EventChannel<any> {
    const receiveAppointmentUpsertMessage = "ReceiveAppointmentUpsertMessage"; // hub method name

    return eventChannel(emit => {
        function handleIncomingMessage(receivedMessage: string) {
            emit(receivedMessage);
        }

        function unsubscribe() {
            connection.off(receiveAppointmentUpsertMessage);
        }

        connection.on(receiveAppointmentUpsertMessage, handleIncomingMessage);

        return unsubscribe;
    }, buffers.expanding());
}

export function* listenToAppointmentUpsertMessage(action: ActionsOfType<PageActionsType, PageActionTypes.ListenToAppointmentUpsertMessage>
): SagaIterator {
    try {
        const pageContext: any = yield getContext("pageContext");

        //TODO: Unable to get flag, will default to false as 1st if statement (in isFeatureFlagEnabled method) fails 
        let EnableSignalr = false;//((yield select(FeatureManagementSelectors.isFeatureFlagEnabled, "EnableSignalr")) as unknown) as boolean;

        //Feature Flag for Signalr
        if (EnableSignalr === false)
        {
            return;
        }
        else 
        {
            if (pageContext) {
                const context = pageContext as IRapPageContext;
                const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
                const idToken: string = (clientelingClient as ClientelingApiClient).rawIdToken();
                const socket: HubConnection = createSocket(idToken);
                const socketChannel: any = yield call(subscribeToAppointmentUpsertMessage, socket);
                yield apply(socket, HubConnection.prototype.start, []);

                try {
                    while (true) {
                        const updatedAppointment: AppointmentModel = ((yield take(socketChannel)) as unknown) as AppointmentModel;

                        // dispatch update to all areas where appointments are being used
                        yield put(AppointmentDetailsActions.receivedAppointmentUpsertMessage(updatedAppointment)); //Filter logic included separately in reducer

                        const viewOptions = yield select(PageSelectors.getViewOptions);
                        if(!updatedAppointment || !updatedAppointment.appointmentDetails || !viewOptions.selectedStore) {
                            return;
                        }
        
                        if(viewOptions.selectedStore !== updatedAppointment.appointmentDetails.storeNumber) {
                            return;
                        }

                        yield put(ReservationsActions.receivedAppointmentUpsertMessage(updatedAppointment));
                    }
                } finally {
                    yield apply(socket, HubConnection.prototype.stop, []);
                }
            } else {
                console.log("Invalid Id token");
            }
        }
    } catch (error) {
        console.log("SIGNALR Listening Message Error");
        console.error(error);
    }
}
