import { FC } from "react";
import { connect } from "react-redux";
import { DefaultButton } from "@fluentui/react";
import { useNavigate } from "react-router-dom";

import AppointmentPrimaryButton from "./components/AppointmentPrimaryButton";

import { IRapComponentProperties } from "../../../platform/Layout";
import { IAppointmentDto, IAppointmentModel } from "./../../../contracts/swagger/_generated";
import { localizedStrings } from "../../localization/LocalizedStrings";
import { IClientelingViewState } from "../../../pages/Contracts";
import { ReservationsActions } from "../../../views/ReservationsView/redux/ReservationsActions";

import * as LocaleUtils from "../../../platform/core/util/Locale";

import "./AppointmentButtons.scss";
import { PermissionGate } from "../PermissionGate/PermissionGate";
import { UserRole } from "../../../platform/components/Page/Contracts";

//Props passed by parent component
interface IAppointmentButtonsProvidedProps extends IRapComponentProperties {
    appointment?: IAppointmentModel;
}

interface IAppointmentButtonsOwnProps extends IAppointmentButtonsProvidedProps {
}

export type IAppointmentButtonsProps = IAppointmentButtonsOwnProps & typeof ActionsToDispatch;

const AppointmentButtons: FC<IAppointmentButtonsProps> = (props) => {
    const navigate = useNavigate();

    const _onClickViewAppointment = (appointment: IAppointmentDto) => {
        props.onAppointmentSelected(appointment);
        navigate(`/${LocaleUtils.getLocale()}/reservations/${appointment.confirmationNumber}`);
    }

    return (
        <div className="flex-row c-appointment-buttons">
            <PermissionGate minimumUserRole={UserRole.Associate}>
                <AppointmentPrimaryButton appointment={props.appointment?.appointmentDetails}/>
            </PermissionGate>
            <DefaultButton
                className="c-appointment-button"
                text={localizedStrings.AppointmentButtons.view}
                onClick={() => _onClickViewAppointment(props.appointment.appointmentDetails)}
                primary={false}
            />
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentButtonsProvidedProps): Partial<IAppointmentButtonsOwnProps> {
    return {
        ...providedProps,
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    onAppointmentSelected: ReservationsActions.onAppointmentSelected,
};

export const ConnectedAppointmentButtons = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentButtons);