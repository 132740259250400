import * as React from "react";

export interface IPageErrorProps {
    title: string;
    details?: string;
}

class PageError extends React.Component<IPageErrorProps> {
    public render() {
        return (
            <div className="page-error">
                <h1>{this.props.title}</h1>
                {this.props.details && (
                    <>
                        <h3>Details:</h3>
                        <pre>{this.props.details}</pre>
                    </>
                )}
            </div>
        );
    }
}

export default PageError;
