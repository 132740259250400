import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Spinner, SpinnerSize, Text } from "@fluentui/react";

import { IContactDto, ICSATDto, ICustomerInsightsDto } from "../../../../../../../../contracts/swagger/_generated";
import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { TextSizes } from "../../../../../../../../common/Constants";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { getCSATColor } from "../../../../../../../../common/AppointmentUtils";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";

import "../../../ContactCard.scss";
import "./ContactCSAT.scss";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";

interface IContactCSATProvidedProps extends IRapComponentProperties {
}

interface IContactCSATOwnProps {
    selectedContactInsights: ICustomerInsightsDto;
    selectedContact?: IContactDto;
    csatScores?: ICSATDto;
}

export type IContactCSATProps = ConnectedProps<typeof connector> & IContactCSATProvidedProps;

const ContactCSATInitializer: FC<IContactCSATProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.selectedContact) {
            setIsLoading(true);
            props.fetchContactCSATScores(props.selectedContact.contactId).finally(() => {
                setIsLoading(false);
            });
        }
    }, [props.selectedContact?.contactId]);

    const areScoresValid = () => {
        return props.csatScores && 
                props.csatScores.averageCSAT != null && 
                props.csatScores.maximumCSAT != null && 
                props.csatScores.minimumCSAT != null;
    }

    return (
        <>
            <div className="flex">
                <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                    <div className="c-card-body c-csat-body flex-row">
                        <div className="flex-2">
                            <Text className="bold">{localizedStrings.ContactsView?.csat?.header}</Text>
                            <Text className="c-text large" variant={TextSizes.xxLarge}>
                                {areScoresValid() ? (
                                    <>
                                        <span className="inline" style={{ "color": getCSATColor(props.csatScores?.averageCSAT)}}>{props.csatScores.averageCSAT}</span>
                                        /5.0
                                    </>
                                ) : (
                                    <>
                                        <span className="c-no-data">{localizedStrings.ContactsView?.csat?.noData}</span>
                                    </>
                                )}
                            </Text>
                            <Text>{localizedStrings.ContactsView?.csat?.personalAverage}</Text>
                        </div>
                        <div className="flex">
                            <Text className="bold">
                                {areScoresValid() ? (
                                    <>
                                        <span style={{ "color": getCSATColor(props.csatScores?.maximumCSAT)}}>{props.csatScores?.maximumCSAT}</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="c-no-data">-</span>
                                    </>
                                )}
                            </Text>
                            <Text className="top">{localizedStrings.ContactsView?.csat?.overallHighest}</Text>
                            <Text className="bold">
                                {areScoresValid() ? (
                                    <>
                                        <span style={{ "color": getCSATColor(props.csatScores?.minimumCSAT)}}>{props.csatScores?.minimumCSAT}</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="c-no-data">-</span>
                                    </>
                                )}
                            </Text>
                            <Text>{localizedStrings?.ContactsView?.csat?.overallLowest}</Text>
                        </div>
                    </div>
                </LoadingContainer>
            </div>
        </>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactCSATProvidedProps): Partial<IContactCSATOwnProps> {
    return {
        ...providedProps,
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights,
        csatScores: ContactsSelectors.getCustomerInsightsData(state)?.csatScores,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactCSATScores: ContactsActions.fetchContactCSATScores
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const ContactCSAT = connector(ContactCSATInitializer);
