import { produce } from "immer";

import { IFeatureManagementState } from "../../../platform/components/Page/Contracts";
import { FeatureManagementActionsType, FeatureManagementActionTypes } from "./FeatureManagementActions";

export function featureManagementReducer(state: IFeatureManagementState, action: FeatureManagementActionsType): IFeatureManagementState {
    return produce(state || ({} as IFeatureManagementState), draft => {
        switch (action.type) {
            case FeatureManagementActionTypes.FetchFeatureFlagsSuccess: {
                draft.featureFlags = action.payload;
                break;
            }

            case FeatureManagementActionTypes.FetchFeatureFlagsFailure: {
                draft.featureFlags = [];
                break;
            }
        }
    });
}
