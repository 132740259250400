import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { Pivot, PivotItem } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";

import { IAppointmentDto } from "../../../../../../contracts/swagger/_generated";
import { Card } from "../../../../../../common/components/Card/Card";
import { ComingSoon } from "../../../../../../common/components/ComingSoon/ComingSoon";
import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { UserRole } from "../../../../../../platform/components/Page/Contracts";
import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { ContactsActions } from "../../../../redux/ContactsActions";
import { ContactCSAT } from "./components/ContactCSAT/ContactCSAT";
import { ContactAppointmentList } from "./components/ContactAppointmentList/ContactAppointmentList";
import { ContactAppointmentDetails } from "./components/ContactAppointmentDetails/ContactAppointmentDetails";
import { ContactSurveyResults } from "./components/ContactSurveyResults/ContactSurveyResults";
import { ErrorBoundaryFallback } from "../../../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

import * as  FeatureManagementSelectors from "../../../../../FeatureManagement/redux/FeatureManagementSelectors";
import * as ContactsSelectors from "../../../../redux/ContactsSelectors";
import * as PageSelectors from "../../../../../../platform/components/Page/redux/PageSelectors";

//import "./ContactReservations.scss";
import "../ContactCard.scss"


interface IContactReservationsProvidedProps extends IRapComponentProperties {
}

interface IContactReservationsOwnProps extends IContactReservationsProvidedProps {
    displayContactMenuReservations: boolean;
    enableCSAT: boolean;
    enableSurveyResults: boolean;
    userRole?: UserRole;
    contactAppointments: IAppointmentDto[];
}

export type IContactReservationsProps = ConnectedProps<typeof connector> & IContactReservationsProvidedProps;

const ContactReservationsInitializer: FC<IContactReservationsProps> = props => {
    let { contactId } = useParams<any>();
    const [isAppointmentListLoading, setIsAppointmentListLoading] = useState(false);

    useEffect(() => {
        if(contactId && !props.contactAppointments) {
            setIsAppointmentListLoading(true);
            props.fetchContactAppointments(contactId).finally(() => {
                setIsAppointmentListLoading(false);
            }); 
        }
    }, [contactId]);

    return (
        <>
            <Card className="flex c-card" id={"scrollableDiv"}>
                <div className="flex-row">
                    <div className="c-card-header">
                        <div>
                            Satisfaction{/* {localizedStrings.ContactsView?.profilePage.purchaseHistory.title} */}
                        </div>
                    </div>
                </div>

                <div className="c-card-body">
                    {/* TODO: Remove Coming Soon when data is generally available */}
                    <div className="flex-row">
                        <div className="flex-column flex">
                            <div className="flex-row">
                                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                    {props.enableCSAT ? (
                                        <ContactCSAT />
                                    ) : (
                                        <ComingSoon description="CSAT is coming soon!" />
                                    )}
                                </ErrorBoundary>
                            </div>

                            <div className="flex-row">
                                <div className="c-card-separator-horizontal">
                                </div>
                            </div>

                            <div className="flex-row">
                                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                    <ContactAppointmentList />
                                </ErrorBoundary>
                            </div>
                        </div>

                        <div className="flex-column">
                            <div className="c-card-separator-vertical">
                            </div>
                        </div>

                        <div className="flex-column flex-2">
                            <div className="flex-row">
                                <Pivot aria-label="Basic Pivot Example">
                                    <PivotItem
                                        headerText="Appointment Details"
                                        headerButtonProps={{
                                        'data-order': 1,
                                        'data-title': 'Appointment Details Title',
                                        }}
                                    >
                                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                            <ContactAppointmentDetails />
                                        </ErrorBoundary>
                                    </PivotItem>
                                    {props.enableSurveyResults || props.userRole == UserRole.Admin ?(
                                        <PivotItem headerText="Survey Results">
                                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                                <ContactSurveyResults />
                                            </ErrorBoundary>
                                        </PivotItem>
                                    ) : null }
                                </Pivot>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactReservationsProvidedProps): Partial<IContactReservationsOwnProps> {
    return {
        ...providedProps,
        contactAppointments: ContactsSelectors.getSelectedContactInfo(state)?.contactAppointments,
        enableCSAT: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCSAT", false),
        enableSurveyResults: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableSurveyResults", false),
        userRole: PageSelectors.getUserData(state)?.userRole
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactAppointments: ContactsActions.fetchContactAppointments
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactReservations = connector(ContactReservationsInitializer);
