import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../platform/Layout";
import { PageActions } from "../../platform/components/Page/redux/PageActions";
import { IClientelingViewState } from "../Contracts";
import { ConnectedAppointmentView } from "../../views/AppointmentDetailsView/AppointmentDetailsView";
import { AppointmentDetailsActions } from "../../views/AppointmentDetailsView/redux/AppointmentDetailsActions";
import { ReservationsActions } from "../../views/ReservationsView/redux/ReservationsActions";
import { IAppointmentResources } from "../../views/ReservationsView/Contracts";
import { ContactsActions } from "../../views/ContactsView/redux/ContactsActions";
import { IAssociate } from "../../contracts/swagger/_generated";

import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";
import * as ReservationsSelectors from "../../views/ReservationsView/redux/ReservationsSelectors";
import * as ContactsSelectors from "../../views/ContactsView/redux/ContactsSelectors";

import "./AppointmentHub.scss";

interface IAppointmentHubInitializerProvidedProps extends IRapComponentProperties { }

interface IAppointmentHubInitializerOwnProps extends IAppointmentHubInitializerProvidedProps { 
    selectedStore: string;
    resources: IAppointmentResources;
    storeAssociates: IAssociate[];
}

export type IAppointmentHubInitializerProps = IAppointmentHubInitializerOwnProps & typeof ActionsToDispatch;

export class AppointmentHubInitializer extends RapComponent<IAppointmentHubInitializerProps> {
    constructor(props: IAppointmentHubInitializerProps, context: IRapComponentContext) {
        super(props, context);

        if(!this.props.selectedStore) {
            this.props.fetchPageData();
        }

        if(!this.props.resources.appointmentStatuses || !this.props.resources.appointmentStatusReasons) {
            this.props.fetchAppointmentStatuses();
            this.props.fetchAppointmentStatusReasons();
        }

        if(!this.props.storeAssociates) {
            this.props.fetchStoreAssociates(parseInt(this.props.selectedStore));
        }    
    }

    public render() {
        return (
            <>
                <ConnectedAppointmentView />
            </>
        );
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(
    state: IClientelingViewState,
    providedProps: IAppointmentHubInitializerProvidedProps
): Partial<IAppointmentHubInitializerOwnProps> {
    return {
        ...providedProps,
        selectedStore: PageSelectors.getViewOptions(state)?.selectedStore,
        resources: ReservationsSelectors.getResources(state),
        storeAssociates: ContactsSelectors.getStoreAssociates(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    fetchAppointmentStatuses: ReservationsActions.fetchAppointmentStatus,
    fetchAppointmentStatusReasons: ReservationsActions.fetchAppointmentStatusReasons,
    fetchStoreAssociates: ContactsActions.fetchAssociateData,
    fetchPageData: PageActions.fetchPageData
};

export const ConnectedAppointmentHubInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentHubInitializer);
