import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { IClientelingApiClient, AppointmentDetail, AppointmentCustomQuestionDto, ILeadPromoCodeResponse, ILead } from "../../../contracts/swagger/_generated";
import { IRapPageContext } from "../../../platform/Context";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { AppointmentDetailsActionTypes, AppointmentDetailsActionsType, AppointmentDetailsActions } from "../../AppointmentDetailsView/redux/AppointmentDetailsActions";
import * as LocalConstants from "../../../common/Constants";
import { getAppointmentUpdateModel } from "./AppointmentDetailsSelectors";
import { IUpdateAppointmentModel } from "../Contracts";
import * as CommonSagaFunctions from "../../../common/CommonSagaFunctions";

const takeEvery: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;
const apply: any = Effects.apply;
const take: any = Effects.take;

export function* appointmentDetailsSaga(): SagaIterator {
    yield takeEvery(AppointmentDetailsActionTypes.FetchAppointmentDetail, fetchAppointmentDetail);
    yield takeEvery(AppointmentDetailsActionTypes.FetchAppointmentQuestions, fetchAppointmentQuestions);
    yield takeEvery(AppointmentDetailsActionTypes.UpdateAppointment, updateAppointment);
    yield takeEvery(AppointmentDetailsActionTypes.CreateLead, createLead);
    yield takeEvery(AppointmentDetailsActionTypes.FetchLeadPromoCodes, fetchLeadPromoCodes);
    yield takeEvery(AppointmentDetailsActionTypes.UpdateClientedAppointmentFieldToTrue, updateClientedAppointmentFieldToTrue);
}

export function* fetchAppointmentDetail(
    action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.FetchAppointmentDetail>
): SagaIterator {
    const { storeId, appointmentId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;

            const appointmentsData: AppointmentDetail = ((yield call(
                [clientelingClient, clientelingClient.getAppointmentDetail],
                storeId,
                appointmentId
            )) as unknown) as AppointmentDetail;

            yield put(AppointmentDetailsActions.fetchAppointmentDetailSuccess(appointmentsData));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(AppointmentDetailsActions.fetchAppointmentDetailFailure(message));
        }
    } else {
        yield put(AppointmentDetailsActions.fetchAppointmentDetailFailure("IdToken token is invalid."));
    }
}

export function* fetchAppointmentQuestions(
    action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.FetchAppointmentQuestions>
): SagaIterator {
    const { storeId, typeId, categoryId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: AppointmentCustomQuestionDto[]= ((yield call(
                [clientelingClient, clientelingClient.getAppointmentQuestions],
                storeId,
                typeId,
                categoryId
            )) as unknown) as AppointmentCustomQuestionDto[];

            yield put(AppointmentDetailsActions.fetchAppointmentQuestionsSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(AppointmentDetailsActions.fetchAppointmentQuestionsFailure(message));
        }
    } else {
        yield put(AppointmentDetailsActions.fetchAppointmentQuestionsFailure("IdToken token is invalid."));
    }
}

export function* updateAppointment(action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.UpdateAppointment>): SagaIterator {
    const updateModel: IUpdateAppointmentModel = ((yield select(getAppointmentUpdateModel)) as unknown) as IUpdateAppointmentModel;
    yield call(CommonSagaFunctions.updateAppointment,
        updateModel.storeNumber, 
        updateModel.appointmentId, 
        updateModel.updates, 
        AppointmentDetailsActions.updateAppointmentSuccess, 
        AppointmentDetailsActions.updateAppointmentFailure,
        action.resolve,
        action.reject
    );
    yield put(AppointmentDetailsActions.fetchAppointmentDetail(updateModel.storeNumber, updateModel.appointmentId, true));
}

export function* createLead(action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.CreateLead>): SagaIterator {
    const { lead } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: ILead = ((yield call(
                [clientelingClient, clientelingClient.createLead],
                lead
            )) as unknown) as ILead;

            action.resolve(response);
            yield put(AppointmentDetailsActions.createLeadSuccess());
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(AppointmentDetailsActions.createLeadFailure(message));
        }
    } else {
        yield put(AppointmentDetailsActions.createLeadFailure("IdToken token is invalid."));
    }
}

export function* fetchLeadPromoCodes(
    action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.FetchLeadPromoCodes>
): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const response: ILeadPromoCodeResponse = ((yield call([
                clientelingClient,
                clientelingClient.getLeadPromoCodes
            ])) as unknown) as ILeadPromoCodeResponse;

            yield put(AppointmentDetailsActions.fetchLeadPromoCodesSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == LocalConstants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(AppointmentDetailsActions.fetchLeadPromoCodesFailure(message));
        }
    } else {
        yield put(AppointmentDetailsActions.fetchLeadPromoCodesFailure("IdToken token is invalid."));
    }
}

export function* updateClientedAppointmentFieldToTrue(
    action: ActionsOfType<AppointmentDetailsActionsType, AppointmentDetailsActionTypes.UpdateClientedAppointmentFieldToTrue>
): SagaIterator {
    const { storeId, appointmentId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            yield call(
                [clientelingClient, clientelingClient.updateClienteledAppointment],
                storeId,
                appointmentId
            );
        } catch (error) {
            console.error(error);
        }
    } else {
        console.error("IdToken token is invalid.");
    }
}
