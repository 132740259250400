import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { ContactsActions, ContactsActionsType, ContactsActionTypes } from "./ContactsActions";
import { ContactsTelemetryLogger } from "./../ContactsTelemetryLogger";
import { IRapPageContext } from "../../../platform/Context";
import {
    AppointmentDto,
    ContactDto,
    ContactsView,
    CustomerInsightsDto,
    DeviceDto,
    EventModel,
    IAppointmentDto,
    IAssociate,
    IContactDto,
    IContactNote,
    ICoreInsightsDto,
    ICSATDto,
    ICustomerInsightsDto,
    IIdResponseDto,
    INoteDto,
    IProductRecommendationsItem,
    IPurchaseHistoryDto,
    IStoreAssociateDto,
    ISurveyQuestionDto,
    ISurveyResponseDto,
    NoteDto
} from "../../../contracts/swagger/_generated";
import * as PageSelectors from "../../../platform/components/Page/redux/PageSelectors";
import { IClientelingApiClient } from "../../../contracts/swagger/_generated";
import * as LocalConstants from "../../../common/Constants";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { IViewOptions } from "../../../platform/components/Page/Contracts";

const takeEvery: any = Effects.takeEvery;
const takeLatest: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;

export function* contactsSaga(): SagaIterator {
    yield takeLatest(ContactsActionTypes.FetchAssociateData, fetchAssociateData);
    yield takeEvery(ContactsActionTypes.CreateContact, createContact);
    yield takeEvery(ContactsActionTypes.FetchContactNotes, fetchContactNotes);
    yield takeEvery(ContactsActionTypes.CreateContactNote, createContactNote);
    yield takeEvery(ContactsActionTypes.DeleteContactNote, deleteContactNote);
    yield takeEvery(ContactsActionTypes.EditContactNote, editContactNote);
    yield takeEvery(ContactsActionTypes.LogTelemetry, logTelemetry);
    yield takeEvery(ContactsActionTypes.EditContact, editContact);
    yield takeEvery(ContactsActionTypes.FetchCustomerInsights, fetchCustomerInsights);
    yield takeEvery(ContactsActionTypes.FetchContactCheckedInEvents, fetchContactCheckedInEvents);
    yield takeEvery(ContactsActionTypes.FetchContactRegisteredEvents, fetchContactRegisteredEvents);
    yield takeEvery(ContactsActionTypes.FetchContactAppointments, fetchContactAppointments);
    yield takeEvery(ContactsActionTypes.FetchContactDevices, fetchContactDevices);
    yield takeEvery(ContactsActionTypes.FetchContactById, fetchContactById);
    yield takeEvery(ContactsActionTypes.FetchContactInsights, fetchContactInsights);
    yield takeEvery(ContactsActionTypes.FetchContactCoreInsights, fetchContactCoreInsights);
    yield takeEvery(ContactsActionTypes.FetchContactPurchaseHistory, fetchContactPurchaseHistory);
    yield takeEvery(ContactsActionTypes.FetchContactSurveys, fetchContactSurveys);
    yield takeEvery(ContactsActionTypes.FetchContactCSATScores, fetchContactCSATScores);
    yield takeEvery(ContactsActionTypes.FetchProductRecommendations, fetchProductRecommendations);
    yield takeEvery(ContactsActionTypes.FetchSurveyQuestion, fetchSurveyQuestion);
    yield takeEvery(ContactsActionTypes.SearchForAssociate, searchForAssociate);
    yield takeEvery(ContactsActionTypes.SearchForStoreAssociate, searchForStoreAssociate);
}

export function* fetchAssociateData(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchAssociateData>): SagaIterator {
    const { storeNumber } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactsData: IAssociate[] = ((yield call(
                [clientelingClient, clientelingClient.getAssociates],
                storeNumber
            )) as unknown) as IAssociate[];
            yield put(ContactsActions.fetchAssociateDataSuccess(contactsData));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            yield put(ContactsActions.fetchAssociateDataFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchAssociateDataFailure("IdToken token is invalid."));
    }
}

export function* fetchContactById(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactById>): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactsData: IContactDto = ((yield call(
                [clientelingClient, clientelingClient.getContactById],
                contactId
            )) as unknown) as IContactDto;

            yield put(ContactsActions.fetchContactByIdSuccess(contactsData));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : errorCode == LocalConstants.HttpErrorCodes.NotFound
                        ? localizedStrings.Errors.contactNotFound
                        : localizedStrings.Errors.serverError;

            yield put(ContactsActions.fetchContactByIdFailure(message, errorCode == LocalConstants.HttpErrorCodes.NotFound));
        }
    } else {
        yield put(ContactsActions.fetchContactByIdFailure("IdToken token is invalid."));
    }
}

export function* fetchContactInsights(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactInsights>): SagaIterator {
    const customerEmail = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const insightsData: ICustomerInsightsDto = ((yield call(
                [clientelingClient, clientelingClient.getContactInsightsData],
                customerEmail
            )) as unknown) as ICustomerInsightsDto;

            action.resolve(insightsData);
            yield put(ContactsActions.fetchContactInsightsSuccess(insightsData));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : errorCode == LocalConstants.HttpErrorCodes.NotFound
                        ? localizedStrings.Errors.contactNotFound
                        : localizedStrings.Errors.serverError;

            if (errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                // no insights found shouldn't be an error message
                yield put(ContactsActions.fetchContactInsightsSuccess(undefined));
            } else {
                yield put(ContactsActions.fetchContactInsightsFailure(message));
            }
            action.reject(message);
        }
    } else {
        yield put(ContactsActions.fetchContactInsightsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactCoreInsights(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactCoreInsights>): SagaIterator {
    const customerEmail = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const insightsData: ICoreInsightsDto = ((yield call(
                [clientelingClient, clientelingClient.getContactCoreInsightsData],
                customerEmail
            )) as unknown) as ICoreInsightsDto;

            action.resolve(insightsData);
            yield put(ContactsActions.fetchContactCoreInsightsSuccess(insightsData));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : errorCode == LocalConstants.HttpErrorCodes.NotFound
                        ? localizedStrings.Errors.contactNotFound
                        : localizedStrings.Errors.serverError;

            if (errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                // no insights found shouldn't be an error message
                yield put(ContactsActions.fetchContactCoreInsightsSuccess(undefined));
            } else {
                yield put(ContactsActions.fetchContactCoreInsightsFailure(message));
            }
            action.reject(message);
        }
    } else {
        yield put(ContactsActions.fetchContactCoreInsightsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactPurchaseHistory(
    action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactPurchaseHistory>
): SagaIterator {
    const customerEmail = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IPurchaseHistoryDto[] = ((yield call(
                [clientelingClient, clientelingClient.getContactPurchaseHistory],
                customerEmail
            )) as unknown) as IPurchaseHistoryDto[];

            action.resolve(response);
            yield put(ContactsActions.fetchContactPurchaseHistorySuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : errorCode == LocalConstants.HttpErrorCodes.NotFound
                        ? localizedStrings.Errors.contactNotFound
                        : localizedStrings.Errors.serverError;

            if (errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                // no insights found shouldn't be an error message
                yield put(ContactsActions.fetchContactPurchaseHistorySuccess([]));
            } else {
                yield put(ContactsActions.fetchContactPurchaseHistoryFailure(message));
            }
            action.reject(message);
        }
    } else {
        yield put(ContactsActions.fetchContactPurchaseHistoryFailure("IdToken token is invalid."));
    }
}

export function* fetchContactNotes(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactNotes>): SagaIterator {
    const { contactId } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactNotes: IContactNote[] = ((yield call(
                [clientelingClient, clientelingClient.getContactNotes],
                contactId
            )) as unknown) as IContactNote[];

            action.resolve(contactNotes);
            yield put(ContactsActions.fetchContactNotesSuccess(contactNotes));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(
                ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch notes failed for " + contactId, [], error as any)
            );
            yield put(ContactsActions.fetchContactNotesFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchContactNotesFailure("IdToken token is invalid."));
    }
}

export function* fetchContactCheckedInEvents(
    action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactCheckedInEvents>
): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactCheckedInEvents: EventModel[] = ((yield call(
                [clientelingClient, clientelingClient.getContactCheckedInEvents],
                contactId
            )) as unknown) as EventModel[];

            yield put(ContactsActions.fetchContactCheckedInEventsSuccess(contactCheckedInEvents));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            yield put(
                ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch events failed for " + contactId, [], error as any)
            );
            yield put(ContactsActions.fetchContactCheckedInEventsFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchContactCheckedInEventsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactRegisteredEvents(
    action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactRegisteredEvents>
): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactRegisteredEvents: EventModel[] = ((yield call(
                [clientelingClient, clientelingClient.getContactRegisteredEvents],
                contactId
            )) as unknown) as EventModel[];

            yield put(ContactsActions.fetchContactRegisteredEventsSuccess(contactRegisteredEvents));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            yield put(
                ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch events failed for " + contactId, [], error as any)
            );
            yield put(ContactsActions.fetchContactRegisteredEventsFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchContactRegisteredEventsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactAppointments(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactAppointments>): SagaIterator {
    const {contactId, pageNumber, appendList} = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactAppointments: IAppointmentDto[] = ((yield call(
                [clientelingClient, clientelingClient.getContactAppointments],
                contactId,
                pageNumber
            )) as unknown) as IAppointmentDto[];

            action.resolve(contactAppointments);
            yield put(ContactsActions.fetchContactAppointmentsSuccess(contactAppointments, appendList));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(
                ContactsActions.logTelemetry(
                    LocalConstants.ContactsFeature.ContactsView,
                    "Fetch appointments failed for " + contactId,
                    [],
                    error as any
                )
            );
            yield put(ContactsActions.fetchContactAppointmentsFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchContactAppointmentsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactDevices(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactDevices>): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactDevices: DeviceDto[] = ((yield call(
                [clientelingClient, clientelingClient.getContactDevices],
                contactId
            )) as unknown) as DeviceDto[];

            yield put(ContactsActions.fetchContactDevicesSuccess(contactDevices));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            yield put(
                ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch devices failed for " + contactId, [], error as any)
            );
            yield put(ContactsActions.fetchContactDevicesFailure(message));
        }
    } else {
        yield put(ContactsActions.fetchContactDevicesFailure("IdToken token is invalid."));
    }
}

export function* fetchCustomerInsights(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchCustomerInsights>): SagaIterator {
    const email = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const contactCustomerInsights: CustomerInsightsDto = ((yield call(
                [clientelingClient, clientelingClient.getCustomerInsights],
                email
            )) as unknown) as CustomerInsightsDto;

            yield put(ContactsActions.fetchCustomerInsightsSuccess(contactCustomerInsights));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch customer insights succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            
            if(errorCode && errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                yield put(ContactsActions.fetchCustomerInsightsSuccess(undefined));
            }
            else {
                yield put(ContactsActions.fetchCustomerInsightsFailure(message));
                yield put(
                    ContactsActions.logTelemetry(
                        LocalConstants.ContactsFeature.ContactsView,
                        "Fetch customer insights failed",
                        [],
                        error as any
                    )
                );
            }
        }
    } else {
        yield put(ContactsActions.fetchCustomerInsightsFailure("IdToken token is invalid."));
    }
}

export function* fetchContactSurveys(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactSurveys>): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: ISurveyResponseDto[] = ((yield call(
                [clientelingClient, clientelingClient.getContactSurveys],
                contactId
            )) as unknown) as ISurveyResponseDto[];

            action.resolve(response);
            yield put(ContactsActions.fetchContactSurveySuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch contact surveys succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;

            if(errorCode && errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                action.reject("Contact surveys not found");
                yield put(ContactsActions.fetchContactSurveySuccess(undefined));
            }
            else {
                action.reject(message);
                yield put(ContactsActions.fetchContactSurveyFailure(message));
                yield put(
                    ContactsActions.logTelemetry(
                        LocalConstants.ContactsFeature.ContactsView,
                        "Fetch contact surveys failed for " + contactId,
                        [],
                        error as any
                    )
                );
            }
        }
    } else {
        yield put(ContactsActions.fetchContactSurveyFailure("IdToken token is invalid."));
    }
}

export function* fetchContactCSATScores(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchContactCSATScores>): SagaIterator {
    const contactId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: ICSATDto = ((yield call([clientelingClient, clientelingClient.getContactCSATScores], contactId)) as unknown) as ICSATDto;

            action.resolve(response);
            yield put(ContactsActions.fetchContactCSATScoresSuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch csat scores succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;

            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            
            if(errorCode && errorCode == LocalConstants.HttpErrorCodes.NotFound) {
                action.reject("CSAT Scores not found");
                yield put(ContactsActions.fetchContactCSATScoresSuccess(undefined));
            }
            else {
                action.reject(message);
                yield put(ContactsActions.fetchContactCSATScoresFailure(message));
                yield put(
                    ContactsActions.logTelemetry(
                        LocalConstants.ContactsFeature.ContactsView,
                        "Fetch csat scores failed for " + contactId,
                        [],
                        error as any
                    )
                );
            }
        }
    } else {
        yield put(ContactsActions.fetchContactCSATScoresFailure("IdToken token is invalid."));
    }
}

export function* fetchProductRecommendations(
    action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchProductRecommendations>
): SagaIterator {
    const { email, count, getPopular } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IProductRecommendationsItem[] = ((yield call(
                [clientelingClient, clientelingClient.getProductRecommendations],
                email,
                count,
                getPopular
            )) as unknown) as IProductRecommendationsItem[];
            action.resolve(response);
            yield put(ContactsActions.fetchProductRecommendationsSuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch product recommendations succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;

            action.reject(message);
            yield put(ContactsActions.fetchProductRecommendationsFailure(message));
            yield put(
                ContactsActions.logTelemetry(
                    LocalConstants.ContactsFeature.ContactsView,
                    "Fetch product recommendations failed for " + email,
                    [],
                    error as any
                )
            );
        }
    } else {
        action.reject("IdToken token is invalid");
        yield put(ContactsActions.fetchProductRecommendationsFailure("IdToken token is invalid."));
    }
}

export function* fetchSurveyQuestion(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.FetchSurveyQuestion>): SagaIterator {
    const questionId = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: ISurveyQuestionDto = ((yield call(
                [clientelingClient, clientelingClient.getSurveyQuestionText],
                questionId
            )) as unknown) as ISurveyQuestionDto;

            action.resolve(response);
            yield put(ContactsActions.fetchSurveyQuestionSuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Fetch contact survey question succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.fetchContactSurveyFailure("Unable to fetch survey question"));
            yield put(
                ContactsActions.logTelemetry(
                    LocalConstants.ContactsFeature.ContactsView,
                    "Fetch contact survey question failed for " + questionId,
                    [],
                    error as any
                )
            );
        }
    } else {
        yield put(ContactsActions.fetchContactSurveyFailure("IdToken token is invalid."));
    }
}

export function* searchForAssociate(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.SearchForAssociate>): SagaIterator {
    const searchString = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IAssociate[] = ((yield call(
                [clientelingClient, clientelingClient.getAssociateWithSearch],
                searchString
            )) as unknown) as IAssociate[];

            action.resolve(response);
            yield put(ContactsActions.searchForAssociateSuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Search for associate succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.searchForAssociateFailure("Unable to fetch associates"));
            yield put(
                ContactsActions.logTelemetry(
                    LocalConstants.ContactsFeature.ContactsView,
                    "Search for associate failed for " + searchString,
                    [],
                    error as any
                )
            );
        }
    } else {
        yield put(ContactsActions.searchForAssociateFailure("IdToken token is invalid."));
    }
}

export function* searchForStoreAssociate(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.SearchForStoreAssociate>): SagaIterator {
    const { searchString, storeNumber } = action.payload;
    const pageContext: any = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IAssociate[] = ((yield call(
                [clientelingClient, clientelingClient.getStoreAssociateWithSearch],
                searchString,
                storeNumber
            )) as unknown) as IAssociate[];

            action.resolve(response);
            yield put(ContactsActions.searchForAssociateSuccess(response));
            yield put(ContactsActions.logTelemetry(LocalConstants.ContactsFeature.ContactsView, "Search for store associate succeeded."));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.searchForAssociateFailure("Unable to fetch store associates"));
            yield put(
                ContactsActions.logTelemetry(
                    LocalConstants.ContactsFeature.ContactsView,
                    "Search for store associate failed for " + searchString,
                    [],
                    error as any
                )
            );
        }
    } else {
        yield put(ContactsActions.searchForAssociateFailure("IdToken token is invalid."));
    }
}

export function* createContactNote(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.CreateContactNote>): SagaIterator {
    const { contactId, note } = action.payload;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: NoteDto = ((yield call([clientelingClient, clientelingClient.createContactNote], contactId, note)) as unknown) as NoteDto;

            action.resolve(response);
            yield put(ContactsActions.createContactNoteSuccess(response.noteId));
            yield put(ContactsActions.fetchContactNotes(contactId, true));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.createContactNoteFailure(message));
        }
    } else {
        action.reject("IdToken token is invalid");
        yield put(ContactsActions.createContactNoteFailure("IdToken token is invalid."));
    }
}

export function* deleteContactNote(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.DeleteContactNote>): SagaIterator {
    const { contactId, noteId } = action.payload;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IIdResponseDto = ((yield call(
                [clientelingClient, clientelingClient.deleteContactNote],
                contactId,
                noteId
            )) as unknown) as IIdResponseDto;

            action.resolve(response);
            yield put(ContactsActions.deleteContactNoteSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.deleteContactNoteFailure(message));
        }
    } else {
        action.reject("IdToken token is invalid");
        yield put(ContactsActions.deleteContactNoteFailure("IdToken token is invalid."));
    }
}

export function* editContactNote(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.EditContactNote>): SagaIterator {
    const { contactId, noteId, note } = action.payload;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: INoteDto = ((yield call(
                [clientelingClient, clientelingClient.editContactNote],
                contactId,
                noteId,
                note
            )) as unknown) as INoteDto;

            action.resolve(response);
            yield put(ContactsActions.editContactNoteSuccess(response));
            yield put(ContactsActions.fetchContactNotes(contactId, true));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(ContactsActions.editContactNoteFailure(message));
        }
    } else {
        action.reject("IdToken token is invalid");
        yield put(ContactsActions.editContactNoteFailure("IdToken token is invalid."));
    }
}

export function* logTelemetry(telemetryAction: ActionsOfType<ContactsActionsType, ContactsActionTypes.LogTelemetry>): SagaIterator {
    const { feature, action, properties, errorMessage } = telemetryAction.payload;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        const storeId = (((yield select(PageSelectors.getViewOptions)) as unknown) as IViewOptions).selectedStore;
        var telemetryLogger: ContactsTelemetryLogger = new ContactsTelemetryLogger(pageContext, feature, action, storeId);

        try {
            if (errorMessage && errorMessage.length > 0) {
                yield call(telemetryLogger.captureError, errorMessage);
            } else {
                yield call(telemetryLogger.captureTelemetry, properties ? properties : {});
            }
        } catch (error) {
            console.log("Unable to log telemetry");
        }
    }
}

export function* createContact(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.CreateContact>): SagaIterator {
    const contactData = action.payload;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: IContactDto = ((yield call([clientelingClient, clientelingClient.createContact], contactData)) as unknown) as IContactDto;

            action.resolve(response);
            yield put(ContactsActions.createContactSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject({ errorCode, error });
            yield put(ContactsActions.createContactFailure(message));
        }
    } else {
        yield put(ContactsActions.createContactFailure("IdToken token is invalid."));
    }
}

export function* editContact(action: ActionsOfType<ContactsActionsType, ContactsActionTypes.EditContact>): SagaIterator {
    const contactId = action.payload.contactId;
    const contactData = action.payload.contactData;
    const pageContext = yield getContext("pageContext");
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call(
                [context, context.getRestClient],
                "IClientelingApiClient"
            )) as unknown) as IClientelingApiClient;
            const response: ContactDto = ((yield call(
                [clientelingClient, clientelingClient.editContact],
                contactId,
                contactData
            )) as unknown) as ContactDto;

            action.resolve(response);
            yield put(ContactsActions.editContactSuccess(response));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode)
                message =
                    errorCode == LocalConstants.HttpErrorCodes.UnAuthorized
                        ? localizedStrings.App.unAuthorizedMessage
                        : localizedStrings.Errors.serverError;
            action.reject({ errorCode, error });
            yield put(ContactsActions.editContactFailure(message));
        }
    } else {
        yield put(ContactsActions.editContactFailure("IdToken token is invalid."));
    }
}
