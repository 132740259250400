import { threadId } from "worker_threads";
import { getMsalAuthResult } from "../App";
import { IClientelingApiClient } from "../contracts/swagger/_generated";
import { IObjectProperty, IObservable, IObservableObject, IObservableValue, Observable, ObservableObject, ObservableValue } from "./core/Observable";
import { IAuthenticationData, IRapAuthenticationService } from "./services/Authentication";

/**
 * All services registered with the IRapPageContext.registerService MUST implement
 * IRapService and MAY implement a _serviceStart method which is made when the service
 * is first created. This gives the service the chance to capture and work with the
 * IRapPageContext they are tracked with.
 */
export interface IRapService {
    /**
     * serviceStart is called when a service is retrieved from the IRapPageContext
     * and the service implements IRapService.
     */
    _serviceStart?: (pageContext: IRapPageContext) => void;

    /**
     * serviceEnd is called when the pageContext is being unloaded, this gives services
     * the opportunity to clean up anything they need to.
     */
    _serviceEnd?: (pageContext: IRapPageContext) => void;
}

/**
 * An IRapPersistantService is a service that is not recreated when a new page context
 * is created instead the service is kept around and is "restarted".
 */
export interface IRapPersistantService {
    _serviceRestart: (pageContext: IRapPageContext) => void;
}

/**
 * The RapService is a base class that services can chose to use when implementing
 * their services. It adds some core framework abilities to services like observability.
 * If your service acts like a store and wants to allow for external notifications
 * when state is changed this class offers a built in observable.
 */
export abstract class RapService implements IRapService {
    protected pageContext: IRapPageContext;

    public _serviceStart(pageContext: IRapPageContext): void {
        this.pageContext = pageContext;
    }

    public _serviceEnd(pageContext: IRapPageContext): void { }

    public _serviceRestart(pageContext: IRapPageContext): void {
        this.pageContext = pageContext;
    }
}

/**
 * An IRapObservableService<T> is a standard service that offers an observable as part
 * of the service itself. If the service wants to publish events this is an easy
 * model to follow.
 */
export interface IRapObservableService<T, TAction extends string = string> extends IRapService, IObservable<T, TAction> { }

/**
 * An ObservableService is a service that allows callers to signup for notifications when
 * data within the service is changed.
 */
export abstract class RapObservableService<T, TAction extends string = string> extends RapService implements IRapObservableService<T, TAction> {
    private observable = new Observable<T>();

    public subscribe(observer: (value: T, action: TAction) => void, action?: string): void {
        this.observable.subscribe(observer, action);
    }

    public unsubscribe(observer: (value: T, action: TAction) => void, action?: string): void {
        this.observable.unsubscribe(observer, action);
    }

    protected _notify(value: T, action: TAction, persistEvent?: boolean) {
        this.observable.notify(value, action, persistEvent);
    }
}

/**
 * A service factory is the method used to create and initialize an IRapService.
 */
export type ServiceFactory = new () => IRapService;

/**
 * ServiceOptions are optional values that can be used to describe services
 * registered with the core service provider.
 */
export enum ServiceOptions {
    /**
     * Services registered with the Remotable flag may be retrieved and called across
     * the XDM message channel. Ensure no methods are exposed unintentionally on
     * remotable services.
     */
    Remotable = 0x1,

    /**
     * Persistant services are not restarted when a new page context is created. They
     * are only ever created once. This is used to maintain state across FPS navigations.
     * This should generally NOT be used. An example where it is used it the content
     * service that tracks the set of scripts that have been loaded.
     */
    Persistant = 0x2,

    /**
     * Services can request to be always available for a given PageContext. This means
     * that they will be created as soon as they are registered for any PageContext
     * instances that already exist, and new ones will be created as they are
     * registered.
     */
    Startup = 0x4,

    /**
     * Private services are usually startup services that perform some background task
     * but are not accessible through the GetService API. A unique name is still required
     * for private services.
     */
    Private = 0x8
}

/**
 * Details that describe how a service is created and its capabilities.
 */
export interface IServiceDefinition {
    /**
     * This is the constructor used to create an instance of the service.
     */
    serviceFactory: ServiceFactory;

    /**
     * Services may support specific capabilities that the platform tracks.
     *
     * An example is whether or not a service is "remotable" or not. Remotable services
     * can be used across the frame boundary while non-remotable may only be used within.
     * the frame they are defined.
     */
    options?: ServiceOptions;
}

/**
 * Create and expose the service registry to the platform for developers to register
 * their services.
 */
export const Services: IObservableObject<IServiceDefinition> = new ObservableObject<IServiceDefinition>();

/**
 * Serialization settings when sending an object across iframe boundaries
 */
export interface IRemoteSerializationSettings {
    /**
     * If true, functions on this object of type "function" will have proxy functions inserted on the
     * object in the remote iframe which will invoke the method in this frame.
     *
     * For security reasons, this should ONLY be set to true if you trust arbitrary third-party extensions
     * to call any method on the object
     */
    proxyFunctions?: boolean;

    /**
     * If true, serialize properties that start with an underscore. By default properties that start with
     * an underscore are not serialized across iframe boundaries.
     */
    serializeUnderscoreProperties?: boolean;

    /**
     * If specified, the property names that map to true in the provided dictionary will not be
     * serialized across the iframe boundary
     */
    ignoredProperties?: { [propertyName: string]: boolean };
}

/**
 * The key that will be examined on any object being sent across the iframe boundary. If present,
 * it will override the default serialization settings.
 */
export const RemoteSerializationSettingsKey = "__remoteSerializationSettings";

/**
 * Interface for a class that can retrieve authorization tokens to be used in fetch requests.
 */
export interface IAuthorizationTokenProvider {
    /**
     * Gets the authorization header to use in a fetch request
     *
     * @param forceRefresh If true, indicates that we should get a new token, if applicable for current provider.
     * @return the value to use for the Authorization header in a request.
     */
    getAuthorizationHeader(forceRefresh?: boolean): Promise<string>;
}

/**
 * A rest client provides methods that issue remote REST calls.
 */
export interface IRapRestClient { }

/**
 * A REST client factory is the method used to create and initialize an IRapRestClient.
 */
export type RestClientFactory = new (options: IRapRestClientOptions) => IRapRestClient;

/**
 * Options for a specific instance of a REST client.
 */
export interface IRapRestClientOptions {
    authData?: IAuthenticationData;
}

/**
 * Service for resolving registered REST client options for the current page context
 */
export interface IRestClientOptionsProviderService extends IRapService {
    /**
     * Fills in REST client options relevant to the current page context such auth data
     */
    populateRestClientOptions(instanceOptions: IRapRestClientOptions | undefined): IRapRestClientOptions;
}

/**
 * Details that describe how a REST client is created and its capabilities.
 */
export interface IRestClientDefinition {
    /**
     * This is the constructor used to create an instance of the service.
     */
    factory: RestClientFactory;

    options?: IRapRestClientOptions;
}

/**
 * Create and expose the service registry to the platform for developers to register
 * their services.
 */
export const RestClients: IObservableObject<IRestClientDefinition> = new ObservableObject<IRestClientDefinition>();

/**
 * The IRapPageContext is at the core of the RAP platform. It acts as the
 * service provider for the page. Callers should register and interact with
 * API's through the pageContext instead of dealing directly with instances.
 * This supports a better seperation of concerns and encapsulation within
 * service objects through interfaces.
 */
export interface IRapPageContext {
    /**
     * Get a service by service name. The caller supplies the 'expected' type and
     * this method returns it as this type. There is no actual validation so it is
     * the responsibility of the caller to ensure the type is correct.
     *
     * @param serviceName - name of the service to retrieve.
     */
    getService: <T extends IRapService>(serviceName: string) => T;

    /**
     * Get a REST client by its registered name. The caller supplies the
     * 'expected' type and this method returns it as this type. There is no actual
     * validation so it is the responsibility of the caller to ensure the type is correct.
     */
    getRestClient: <T extends IRapRestClient>(restClientName: string, options?: IRapRestClientOptions) => Promise<T>;

    /**
     * Observable value tracking whether or not startup services have been started yet.
     * This happens during DOM Content loaded for the initial page load. On FPS events this
     * is reset when an FPS starts, and set to true once services have been (re-)started.
     */
    platformInitialized: IObservableValue<boolean>;

    /**
     * For callers that want a unique number for this page. They can call getUniqueNumber().
     */
    getUniqueNumber(): number;
}

export interface IRapPageContextInternal {
    /**
     * Get a service by service name. The caller supplies the 'expected' type and
     * this method returns it as this type. There is no actual validation so it is
     * the responsibility of the caller to ensure the type is correct.
     *
     * @param serviceName - name of the service to retrieve.
     * @param remoteOnly - If true, only return services marked as Remotable.
     * @param noThrowIfUndefined - If true, return undefined if the service is not defined rather than throwing.
     */
    getService<T extends IRapService>(serviceName: string, remoteOnly?: boolean, noThrowIfUndefined?: boolean): T | undefined;
}

/**
 * Core WebContext used by the web platform to manage the page being executed
 */
export class RapPageContext implements IRapPageContext {
    // State information used to track services being initialized and destroyed.
    private initializationInProgress: { [serviceName: string]: boolean } = {};
    private contextUnloadInProgress: boolean;
    private contentLoaded: boolean;
    private delayedServices: string[] = [];

    // Details about the registered and active services.
    private serviceInstances: { [serviceName: string]: IRapService } = {};
    private services: IObservableObject<IServiceDefinition>;

    // Details about the registered rest clients
    private restClients: IObservableObject<IRestClientDefinition>;

    // Unique counter for the pageContext.
    private uniqueNumber: number = 0;

    public platformInitialized = new ObservableValue(false);

    constructor(serviceRegistry: IObservableObject<IServiceDefinition>, restClientRegistry: IObservableObject<IRestClientDefinition>) {
        this.services = serviceRegistry;
        this.restClients = restClientRegistry;

        // Start any new services that are registered after context creation that are startup
        const subscriber = (entry: IObjectProperty<IServiceDefinition>, action: string) => {
            if (action === "add") {
                if (entry && entry.value && entry.value.options && (entry.value.options & ServiceOptions.Startup) === ServiceOptions.Startup) {
                    if (this.contentLoaded) {
                        this.getService<IRapService>(entry.key);
                    } else {
                        this.delayedServices.push(entry.key);
                    }
                }
            }
        };

        // Subscribe to service changes to ensure we start any persistant ones upon registration
        this.services.subscribe(subscriber);

        // Wait until the document is loaded before starting up services
        document.addEventListener("DOMContentLoaded", () => {
            this.contentLoaded = true;

            // Start any services that were registered before DOM Content loaded
            for (const delayedServiceName of this.delayedServices) {
                this.getService<IRapService>(delayedServiceName);
            }

            // Startup services are now running
            this.platformInitialized.value = true;
        });
    }

    public getService<T extends IRapService>(serviceName: string): T;
    public getService<T extends IRapService>(serviceName: string, remoteOnly?: boolean, noThrowIfUndefined?: boolean): T | undefined {
        if (!this.contextUnloadInProgress) {
            let registeredService = this.serviceInstances[serviceName];

            // If no service is available, we will start an instance of the service.
            if (!registeredService) {
                const serviceDefinition = this.services.get(serviceName);
                if (serviceDefinition) {
                    if (!serviceDefinition.options || (serviceDefinition.options & ServiceOptions.Private) !== ServiceOptions.Private) {
                        // Check service options if we are only fetching remotable services
                        if (
                            remoteOnly &&
                            serviceDefinition.options &&
                            (serviceDefinition.options & ServiceOptions.Remotable) !== ServiceOptions.Remotable
                        ) {
                            if (!noThrowIfUndefined) {
                                throw new Error(`The ${serviceName} service is not marked as remotable.`);
                            }
                        } else {
                            registeredService = this._loadService(serviceName, serviceDefinition);
                        }
                    }
                }

                if (!registeredService && !noThrowIfUndefined) {
                    throw new Error(`No service has been registered with the name "${serviceName}".`);
                }
            }

            return registeredService as T;
        } else {
            throw new Error("Services can't be requested while a shutdown is in progress");
        }
    }

    public async getRestClient<T extends IRapRestClient = IClientelingApiClient>(restClientName: string, options?: IRapRestClientOptions): Promise<T> {
        // Lookup a definition based on client name.
        const clientDefinition = this.restClients.get(restClientName);
        if (!clientDefinition) {
            throw new Error(`No REST client has been registered with the name "${restClientName}".`);
        }

        // Merge options into the default options and create a new client
        const optionsProviderService = this.getService<IRestClientOptionsProviderService>("IRestClientOptionsProviderService");
        const authService = this.getService<IRapAuthenticationService>("IRapAuthenticationService");

        //We don't have a token yet so lets wait for it and then return the rest client
        while (authService.getData().idToken === "") {
            await new Promise(f => setTimeout(f, 100));
        }

        await getMsalAuthResult();

        const resolvedOptions = optionsProviderService.populateRestClientOptions(clientDefinition.options);

        const clientInstance = new clientDefinition.factory(resolvedOptions);
        return clientInstance as T;
    }

    public getUniqueNumber(): number {
        return ++this.uniqueNumber;
    }

    private _loadService<T>(serviceName: string, serviceDefinition: IServiceDefinition): T {
        let registeredService: IRapService;

        if (this.initializationInProgress[serviceName]) {
            throw new Error("Unable to initialize service due to cyclic dependency: " + serviceName);
        }

        try {
            // Mark this service as initialization in progress to detect cyclic dependencies in _serviceStart.
            this.initializationInProgress[serviceName] = true;

            // Create and initialize a new instance of the service.
            registeredService = new serviceDefinition.serviceFactory();
            if (serviceDefinition.options && (serviceDefinition.options & ServiceOptions.Remotable) === ServiceOptions.Remotable) {
                setRemoteSerializationSettings(registeredService, {
                    proxyFunctions: true,
                    ignoredProperties: { pageContext: true }
                });
            }
            if (registeredService._serviceStart) {
                registeredService._serviceStart(this);
            }
        } catch (exception) {
            delete this.initializationInProgress[serviceName];
            throw exception;
        }

        // Save this service in the registered services.
        this.serviceInstances[serviceName] = registeredService;
        delete this.initializationInProgress[serviceName];

        return registeredService as T;
    }
}

/**
 * Sets the remote serialization settings used when the specified object is serialized
 * across iframe boundaries
 *
 * @param obj The object to set serialization settings on
 * @param settings Settings used when serializing the object across iframe boundaries
 */
export function setRemoteSerializationSettings(obj: any, settings: IRemoteSerializationSettings) {
    obj[RemoteSerializationSettingsKey] = settings;
}

/**
 * Context for the currently executing page
 */
const PageContext: IRapPageContext = new RapPageContext(Services, RestClients);
