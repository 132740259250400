import React, { FC } from "react";
import { IconButton, Panel, PanelType } from "@fluentui/react";
import { connect } from "react-redux";

import { UserSettings } from "../../../UserSettings/UserSettings";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../Layout";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { PageActions } from "../../../Page/redux/PageActions";
import { PageFeature } from "../../../../../common/Constants";


import * as PageSelectors from "../../../Page/redux/PageSelectors";


interface IUserSettingsPanelProvidedProps extends IRapComponentProperties {
 
}

interface IUserSettingsPanelOwnProps extends IUserSettingsPanelProvidedProps {
    displayUserSettingsPanel: boolean;
}

export type IUserSettingsPanelProps = IUserSettingsPanelOwnProps & typeof ActionsToDispatch;

const UserSettingsPanelInitializer: FC<IUserSettingsPanelProps> = (props) => {
    
    const _onCloseUserSettingsPanel = () => {
        props.logTelemetry(PageFeature.ClientelingCommandBar, "Close user settings panel clicked");
        props.updatePanels({ displayUserSettingsPanel: !props.displayUserSettingsPanel });
    };

    const _renderUserSettingsPanelHeader = (): JSX.Element => {
        return (
            <div className="c-header-nav flex-row">
                <div className="c-panel-header flex-start flex-grow" role="heading" aria-level={1}>{localizedStrings.CommandBar.userSettingsPanelTitle}</div>
                <IconButton
                    iconProps={{ iconName: "Cancel", style: { margin: "10px" } }}
                    className="c-cancel-button flex-end"
                    onClick={_onCloseUserSettingsPanel}
                    title={localizedStrings.CommandBar.close}
                    ariaLabel={localizedStrings.CommandBar.close}
                ></IconButton>
            </div>
        );
    }

    return (
        <>
            <Panel
                isOpen={props.displayUserSettingsPanel}
                type={PanelType.custom}
                customWidth={"320px"}
                isLightDismiss={true}
                hasCloseButton={false}
                isFooterAtBottom={false}
                className="c-user-settings-panel"
                onDismiss={_onCloseUserSettingsPanel}
                onRenderHeader={_renderUserSettingsPanelHeader}
            >
                <UserSettings />
            </Panel>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IUserSettingsPanelProvidedProps): Partial<IUserSettingsPanelOwnProps> {
    return {
        ...providedProps,
        displayUserSettingsPanel: PageSelectors.getPanels(state)?.displayUserSettingsPanel
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updatePanels: PageActions.updatePanels
};

export const UserSettingsPanel = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(UserSettingsPanelInitializer);

