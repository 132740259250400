import React, { FC, useCallback, useEffect, useState } from "react";
import { IconButton, Modal, PrimaryButton } from "@fluentui/react";
import { connect, ConnectedProps } from "react-redux";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { RunnerRequestsActions } from "../../redux/RunnerRequestsActions";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import AttentionImage from "../../../../styling/images/search-results.png";
import audio from "../../../../styling/sounds/Listen_Teams_Ringtone_02A_Stereo.mp3";
import { RunnerRequestState } from "../../../../common/Constants";

import "./RunnerRequestNotificationModal.scss";

interface IRunnerRequestNotificationModalProvidedProps extends IRapComponentProperties { }

interface IRunnerRequestNotificationModalOwnProps { 
    displayRunnerRequestNotificationModal: boolean;
}

export type IRunnerRequestNotificationModalProps = ConnectedProps<typeof connector> & IRunnerRequestNotificationModalProvidedProps;

const RunnerRequestNotificationModalInitializer: FC<IRunnerRequestNotificationModalProps> = (props) => {
    const [notificationAudio] = useState(new Audio(audio));
    const onAudioEnd = useCallback(() => {
        notificationAudio.currentTime = 0;
        notificationAudio.play();
    }, [])

    useEffect(() => {
        if(props.displayRunnerRequestNotificationModal) {
            notificationAudio.addEventListener("ended", onAudioEnd);
            notificationAudio.play();
        } else {
            notificationAudio.pause();
            notificationAudio.removeEventListener("ended", onAudioEnd);
            notificationAudio.currentTime = 0;
        }
    }, [props.displayRunnerRequestNotificationModal]);

    useEffect(() => {

    })

    const _onHideNotifcationModal = () => {
        props.setSelectedFilterState(RunnerRequestState.ActionNeeded);
        props.updateModals({ displayRunnerRequestNotificationModal: false });
    }

    const {RunnerRequestNotificationModal: locStrings} = localizedStrings.RunnerRequestsView;

    return (
        <>
            <Modal
                isOpen={props.displayRunnerRequestNotificationModal}
                onDismiss={_onHideNotifcationModal}
                containerClassName="c-rr-notification-modal"
            >
                <div className="flex-row c-modal-header">
                    <DialogHeader title={locStrings.activeRequest}/>
                    <IconButton
                        className="c-icon-cancel"
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel={locStrings.close}
                        onClick={_onHideNotifcationModal}
                    />
                </div>
                <div className="flex-row c-modal-row">
                    <img className="c-modal-image" src={AttentionImage} />
                </div>
                <div className="flex-row c-modal-row c-modal-text">
                    {locStrings.needsAttention}
                </div>
                <div className="flex-row">
                    <PrimaryButton 
                        text={locStrings.close}
                        ariaLabel={locStrings.close}
                        onClick={_onHideNotifcationModal} 
                        className="c-close-button"
                    />
                </div>
            </Modal>
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestNotificationModalProvidedProps
): Partial<IRunnerRequestNotificationModalOwnProps> {
    return {
        ...providedProps,
        displayRunnerRequestNotificationModal: PageSelectors.getModals(state)?.displayRunnerRequestNotificationModal
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setSelectedFilterState: RunnerRequestsActions.setSelectedFilterState
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const RunnerRequestNotificationModal = connector(RunnerRequestNotificationModalInitializer);
