import { ActionsUnion, createAction } from "../../../platform/redux/ActionHelper";
import { FeatureFlag } from "../../../contracts/swagger/_generated";

/**
 * Actions for FeatureManagement view
 */
export enum FeatureManagementActionTypes {
    FetchFeatureFlags = "FeatureManagement/FetchFeatureFlags",
    FetchFeatureFlagsSuccess = "FeatureManagement/FetchFeatureFlagsSuccess",
    FetchFeatureFlagsFailure = "FeatureManagement/FetchFeatureFlagsFailure"
}

const FeatureManagementStandardActions = {
    fetchFeatureFlags: (storeNumber?: string) => createAction(FeatureManagementActionTypes.FetchFeatureFlags, storeNumber),
    fetchFeatureFlagsSuccess: (featureFlags: FeatureFlag[]) => createAction(FeatureManagementActionTypes.FetchFeatureFlagsSuccess, featureFlags),
    fetchFeatureFlagsFailure: (error: string) => createAction(FeatureManagementActionTypes.FetchFeatureFlagsFailure, error)
};

const FeatureManagementThunkActions = {

}

const FeatureManagementThunkActionsTypes = {

}

export const FeatureManagementActions = {...FeatureManagementStandardActions, ...FeatureManagementThunkActions};
const AllActionsTypes = {...FeatureManagementStandardActions, ...FeatureManagementThunkActionsTypes};
export type FeatureManagementActionsType = ActionsUnion<typeof AllActionsTypes>;
