import moment from "moment";
import React from "react";
import { IRapComponentProperties, RapComponent } from "../../../platform/Layout";
import { AppointmentStatusReasons } from "../../Constants";
import { localizedStrings } from "../../localization/LocalizedStrings";

export interface IAppointmentStatusProvidedProps extends IRapComponentProperties {
    statusReason: string;
    actualStartDate: Date;
    appointmentDate: Date;
    scheduledEndDate: Date;
    className?: string; 
}

interface IAppointmentStatusOwnProps extends IAppointmentStatusProvidedProps {
}

export interface IAppointmentStatusState {
    timer: string;
    status: string;
    timeInterval: number;
    waitInterval: number;
    timeIntervalClassName: string;
    statusClassName: string;
}

export type IAppointmentStatusProps = IAppointmentStatusOwnProps;

export class AppointmentStatus extends RapComponent<IAppointmentStatusProps, IAppointmentStatusState> {
    
    constructor(props: IAppointmentStatusProps) {
        super(props);
        this.state = { timer: "", status: "", timeInterval: 0, waitInterval: 0, timeIntervalClassName: "", statusClassName: "" };
    }

    componentDidMount() {
        this.setState({
            timeInterval: window.setInterval(() => {
                if (this.props.statusReason == AppointmentStatusReasons.InProgress) {
                    var scheduledEndDate = this.props.scheduledEndDate;
                    var appointmentStartTime = this.props.actualStartDate;

                    const currentTime = moment();
                    const eventStartTime = moment(appointmentStartTime);
                    var duration = moment.duration(currentTime.diff(eventStartTime));
                    if (duration.hours() >= 0 && duration.minutes() >= 0) {
                        var progressTimer = moment.utc(duration.as("milliseconds")).format("HH:mm");
                        var className = currentTime.diff(scheduledEndDate) < 0 ? "c-timewithin-end-time" : "c-timeexceeded-end-time";
                        this.setState({ timer: progressTimer, timeIntervalClassName: className });
                    }
                }
            }, 1000)
        });

        this.setState({
            waitInterval: window.setInterval(() => {
                var appointmentStartTime = this.props.appointmentDate;
                var now = moment();
                var statusReason = this.props.statusReason;
                let apptStatus = "";
                let className = "";
                if (statusReason == AppointmentStatusReasons.InProgress) {
                    apptStatus = localizedStrings.AppointmentStatus.inProgress;
                    className = "c-status-inprogress";
                } else if (statusReason == AppointmentStatusReasons.CheckedIn) {
                    //current time is past the scheduled start time ( i.e customer is waiting and checked in beyond their scheduled time)
                    if (now.date == moment(appointmentStartTime).date && now.diff(appointmentStartTime) > 0) {
                        apptStatus = localizedStrings.AppointmentStatus.waiting;
                        className = "c-status-waiting";
                    } else {
                        apptStatus = localizedStrings.AppointmentStatus.checkedIn;
                        className = "c-status-checkedin";
                    }
                } else if (statusReason == AppointmentStatusReasons.Reserved) {
                    apptStatus = localizedStrings.AppointmentStatus.scheduled;
                    className = "c-status-scheduled";
                } else if (statusReason == AppointmentStatusReasons.Completed) {
                    clearInterval(this.state.timeInterval);
                    apptStatus = localizedStrings.AppointmentStatus.completed;
                    className = "c-status-completed";
                } else if (
                    statusReason == AppointmentStatusReasons.Cancelled ||
                    statusReason == AppointmentStatusReasons.NoShow ||
                    statusReason == AppointmentStatusReasons.Junk ||
                    statusReason == AppointmentStatusReasons.OutOfArea ||
                    statusReason == AppointmentStatusReasons.OutOfScopeGeneral ||
                    statusReason == AppointmentStatusReasons.OutOfScopeCommerialSupport ||
                    statusReason == AppointmentStatusReasons.OutOfScopePersonalSupport ||
                    statusReason == AppointmentStatusReasons.Staffing ||
                    statusReason == AppointmentStatusReasons.TechSupport
                ) {
                    apptStatus = localizedStrings.AppointmentStatus.cancelled;
                    className = "c-status-cancelled";
                }
                this.setState({
                    status: apptStatus,
                    statusClassName: className
                });
            }, 1000)
        });
    }

    componentWillUnmount() {
        if (this.state.timeInterval) {
            clearInterval(this.state.timeInterval);
        }
        if (this.state.waitInterval) {
            clearInterval(this.state.waitInterval);
        }
    }

    public render() {
        return (<div className={this.state.statusClassName + " " + this.props.className}>
            {this._getAppointmentStatusBasedOnStatusReason()}
        </div>);
    }

    private _getAppointmentStatusBasedOnStatusReason = (): JSX.Element => {
        const statusReason = this.props.statusReason;
        if (statusReason == AppointmentStatusReasons.InProgress) {
            return (
                <div className="flex-row">
                    <div className={this.state.statusClassName}>{this.state.status + '\xa0'}</div>
                    <div className={this.state.timeIntervalClassName}>{this.state.timer}</div>
                </div>
            );
        } else {
            return (
                <div className="flex-row">
                    <div className={this.state.statusClassName}>{this.state.status}</div>
                </div>
            );
        }
    };
}
