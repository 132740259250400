import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dialog, DialogType, TextField, DialogFooter, PrimaryButton, SpinnerSize } from "@fluentui/react";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";

import { IClientelingViewState } from "../../../../pages/Contracts";

import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { IAppointmentDetail } from "../../../../contracts/swagger/_generated";

import { AppointmentDetailsActions } from "../../redux/AppointmentDetailsActions";

import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors"
import * as AppointmentDetailsSelectors from "../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";

import * as Constants from "../../../../common/Constants";

import "./EditAssociateNotesModal.scss";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IEditAssociateNotesProvidedProps extends IRapComponentProperties {

}

interface IEditAssociateNotesOwnProps {
    isEditAssociateNotesOpen: boolean;
    selectedAppointment: IAppointmentDetail;
}

interface IEditAssociateNotesState {
    textFieldValue?: string;
    isLoading: boolean;
}

export type IEditAssociateNotesProps = ConnectedProps<typeof connector> & IEditAssociateNotesProvidedProps

class EditAssociateNotesInitializer extends RapComponent<IEditAssociateNotesProps, IEditAssociateNotesState> {
    constructor(props: IEditAssociateNotesProps, context: IRapComponentContext) {
        super(props, context);
        this.state = {
            textFieldValue: this.props.selectedAppointment?.appointmentDetails?.associateNotes,
            isLoading: false
        }
    }

    public componentDidUpdate(prevProps: Readonly<IEditAssociateNotesProps>): void {
        if(prevProps.selectedAppointment?.appointmentDetails?.associateNotes != this.props.selectedAppointment?.appointmentDetails?.associateNotes) {
            this.setState({textFieldValue: this.props.selectedAppointment.appointmentDetails?.associateNotes});
        }
    }

    private _onDismissEditAssociateNotes = () => {
        this.props.updateModals({ displayEditAssociateNotes: false });
    }

    private _onNoteChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        this.setState({textFieldValue: newValue});
    }

    private _onSave = () => {
        this.props.setAppointmentUpdates({ associateNotes: this.state.textFieldValue });
        this.setState({ isLoading: true });
        this.props.updateAppointment().then(() => {
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal.updateSuccess});
        }).catch((error: string) => {
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal.updateError});
        }).finally(() => {
            this.props.updateModals({ displayEditAssociateNotes: false });
            this.setState({ isLoading: false });
        });
    }

    public render() {
        return (
            <Dialog 
                hidden={!this.props.isEditAssociateNotesOpen}
                onDismiss={this._onDismissEditAssociateNotes}
                minWidth={600}
                dialogContentProps={{
                    type: DialogType.close,
                    title: <DialogHeader title={localizedStrings.AppointmentDetailsView.modals.editAssociateNotes.title}/>,
                    closeButtonAriaLabel: localizedStrings.AppointmentDetailsView.modals.editAssociateNotes.close}}
            >
                <TextField 
                    maxLength={Constants.AssociateNotesCharLength}
                    value={this.state.textFieldValue}
                    onChange={this._onNoteChange}
                    multiline
                    rows={10}
                    resizable={false}
                />
                <DialogFooter>
                    <PrimaryButton onClick={this._onSave} disabled={this.state.isLoading}>
                        <LoadingContainer isLoading={this.state.isLoading} spinnerSize={SpinnerSize.small}>
                            <>{localizedStrings.AppointmentDetailsView.modals.editAssociateNotes.save}</>
                        </LoadingContainer>
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IEditAssociateNotesProvidedProps): Partial<IEditAssociateNotesOwnProps> {
    return {
        ...providedProps,
        isEditAssociateNotesOpen: PageSelectors.getModals(state)?.displayEditAssociateNotes,
        selectedAppointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setAppointmentUpdates: AppointmentDetailsActions.setAppointmentUpdates,
    updateAppointment: AppointmentDetailsActions.updateAppointment,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const EditAssociateNotesModal = connector(EditAssociateNotesInitializer)