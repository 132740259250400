import React from "react";
import { connect, ConnectedProps } from "react-redux";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { CreateEditAppointmentModal, ICreateEditAppointmentFormValues  } from "../../../../common/components/CreateEditAppointmentModal/CreateEditAppointmentModal";
import { IAppointmentDto, IAppointmentForCreationDto } from "../../../../contracts/swagger/_generated";
import { ReservationsActions } from "../../redux/ReservationsActions";
import { AppointmentFlows } from "../../../../common/Constants";
import { AppointmentModalActions } from "../../../../common/components/CreateEditAppointmentModal/redux/AppointmentModalActions";

import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import * as ReservationsSelectors from "../../redux/ReservationsSelectors";

import "./CreateAppointment.scss";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

//Props passed by parent component
interface ICreateAppointmentProvidedProps extends IRapComponentProperties {
}

interface ICreateAppointmentOwnProps {
    isCreateAppointmentOpen: boolean;
    storeId: string;
    appointmentForCreation: IAppointmentForCreationDto;
}

interface ICreateAppointmentState {
    isLoading: boolean;
}

export type ICreateAppointmentProps = ConnectedProps<typeof connector> & ICreateAppointmentProvidedProps;

class CreateAppointmentInitializer extends RapComponent<ICreateAppointmentProps, ICreateAppointmentState> {
    constructor(props: ICreateAppointmentProps, context: IRapComponentContext) {
        super(props, context);
        this.state = { isLoading: false }
    }

    public render() {
        return (
            <CreateEditAppointmentModal 
                onDismiss={this._onDismissCreateAppointment}
                onSave={this._onSaveCreateAppointment}
                onUpdate={this._onUpdateCreateAppointment}
                isOpen={this.props.isCreateAppointmentOpen}
                flow={AppointmentFlows.Create}
                isLoading={this.state.isLoading}
            />
        );
    }

    private _onUpdateCreateAppointment = (val: Partial<ICreateEditAppointmentFormValues>, then?: () => void) => {
        let form = val as IAppointmentForCreationDto;
        this.props.updateAppointmentForCreation(form).then(then);
    }

    private _onDismissCreateAppointment = () => {
        this.props.updateModals({ displayCreateAppointmentModal: false });
    }

    private _onSaveCreateAppointment = () => {
        this.setState({ isLoading: true });
        this.props.createAppointment(this.props.storeId, this.props.appointmentForCreation).then((resp: IAppointmentDto) => {
            this.props.updateModals({ displayCreateAppointmentModal: false });
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal?.createSuccess});
        }).catch((error: string) => {
            console.error(error);
            this.props.updateMessages({ toastMessage: localizedStrings.CreateEditAppointmentModal?.createError});
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICreateAppointmentProvidedProps): Partial<ICreateAppointmentOwnProps> {
    return {
        ...providedProps,
        isCreateAppointmentOpen: PageSelectors.getModals(state)?.displayCreateAppointmentModal,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        appointmentForCreation: ReservationsSelectors.getAppointmentForCreation(state),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    updateAppointmentForCreation: ReservationsActions.updateAppointmentForCreation,
    createAppointment: AppointmentModalActions.createAppointment,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CreateAppointmentModal = connector(CreateAppointmentInitializer);