
const enum EmojiFamilyKeys {
    People = "People",
    Nature = "Nature",
    Activities = "Activities",
    Food = "Food",
    Travel = "Travel",
    Symbols = "Symbols",
    Objects = "Objects"
}

export const EmojiLocaleStrings = {
    // Emoji families
    [EmojiFamilyKeys.Activities]: "Activities",
    [EmojiFamilyKeys.Food]: "Food",
    [EmojiFamilyKeys.Nature]: "Nature",
    [EmojiFamilyKeys.Objects]: "Objects",
    [EmojiFamilyKeys.People]: "People",
    [EmojiFamilyKeys.Symbols]: "Symbols",
    [EmojiFamilyKeys.Travel]: "Travel",

    // Emoji names
    emjDMore: "More",

    emjDNoSuggetions: "No suggestions found",

    emjD0270a: "Raised fist",

    emjD0270b: "Raised hand",

    emjD0270c: "Victory hand",

    emjD02764: "Red heart",

    emjD1f440: "Eyes",

    emjD1f442: "Ear",

    emjD1f443: "Nose",

    emjD1f444: "Mouth",

    emjD1f445: "Tongue",

    emjD1f446: "Up-pointing backhand index finger",

    emjD1f447: "Down-pointing backhand index finger",

    emjD1f448: "Left-pointing backhand index finger",

    emjD1f449: "Right-pointing backhand index finger",

    emjD1f44a: "Fist bump",

    emjD1f44b: "Waving hand",

    emjD1f44c: "OK hand",

    emjD1f44d: "Thumbs up",

    emjD1f44e: "Thumbs down",

    emjD1f44f: "Clapping hands",

    emjD1f450: "Open hands",

    emjD1f590: "Raised hand with fingers splayed",

    emjD1f595: "Middle finger",

    emjD1f596: "Raised hand with part between middle and ring fingers",

    emjD1f464: "Bust in silhouette",

    emjD1f466: "Boy",

    emjD1f467: "Girl",

    emjD1f468: "Man",

    emjD1f469: "Woman",

    emjD1f46a: "Family",

    emjD1f46b: "Man and woman holding hands",

    emjD1f46e: "Police officer",

    emjD1f46f: "Woman with bunny ears",

    emjD1f470: "Bride with veil",

    emjD1f471: "Person with blond hair",

    emjD1f472: "Man with gua pi mao",

    emjD1f473: "Man with turban",

    emjD1f474: "Older man",

    emjD1f475: "Older woman",

    emjD1f476: "Baby",

    emjD1f477: "Construction worker",

    emjD1f481: "Information desk person",

    emjD1f482: "Guardsman",

    emjD1f48b: "Kiss mark",

    emjD1f493: "Beating heart",

    emjD1f494: "Broken heart",

    emjD1f495: "Two hearts",

    emjD1f496: "Sparkling heart",

    emjD1f497: "Growing heart",

    emjD1f498: "Heart with arrow",

    emjD1f499: "Blue heart",

    emjD1f49a: "Green heart",

    emjD1f49b: "Yellow heart",

    emjD1f49c: "Purple heart",

    emjD1f49d: "Heart with ribbon",

    emjD1f49e: "Revolving hearts",

    emjD1f49f: "Heart decoration",

    emjD1f601: "Grinning face with smiling eyes",

    emjD1f602: "Face with tears of joy",

    emjD1f603: "Smiling face with open mouth",

    emjD1f604: "Smiling face with open mouth and smiling eyes",

    emjD1f605: "Smiling face with open mouth and cold sweat",

    emjD1f606: "Smiling face with open mouth and tightly closed eyes",

    emjD1f607: "Smiling face with halo",

    emjD1f608: "Smiling face with horns",

    emjD1f609: "Winking face",

    emjD1f60a: "Smiling face with smiling eyes",

    emjD1f60b: "Face savoring delicious food",

    emjD1f60c: "Relieved face",

    emjD1f60d: "Smiling face with heart-shaped eyes",

    emjD1f60e: "Smiling face with sunglasses",

    emjD1f60f: "Smirking face",

    emjD1f610: "Neutral face",

    emjD1f612: "Unamused face",

    emjD1f613: "Face with cold sweat",

    emjD1f614: "Pensive face",

    emjD1f616: "Confounded face",

    emjD1f618: "Face throwing a kiss",

    emjD1f61a: "Kissing face with closed eyes",

    emjD1f61c: "Face with stuck-out tongue and winking eye",

    emjD1f61d: "Face with stuck-out tongue and tightly closed eyes",

    emjD1f61e: "Disappointed face",

    emjD1f620: "Angry face",

    emjD1f621: "Pouting face",

    emjD1f622: "Crying face",

    emjD1f623: "Persevering face",

    emjD1f624: "Face with look of triumph",

    emjD1f625: "Disappointed but relieved face",

    emjD1f628: "Fearful face",

    emjD1f629: "Weary face",

    emjD1f62a: "Sleepy face",

    emjD1f62b: "Tired face",

    emjD1f62d: "Loudly crying face",

    emjD1f630: "Face with open mouth and cold sweat",

    emjD1f631: "Face screaming in fear",

    emjD1f632: "Astonished face",

    emjD1f633: "Flushed face",

    emjD1f635: "Dizzy face",

    emjD1f636: "Face without mouth",

    emjD1f637: "Face with medical mask",

    emjD1f645: "Face with No Good gesture",

    emjD1f646: "Face with OK gesture",

    emjD1f647: "Person bowing deeply",

    emjD1f648: "See-no-evil monkey",

    emjD1f649: "Hear-no-evil monkey",

    emjD1f641: "Slightly frowning face",

    emjD1f642: "Slightly smiling face",

    emjD1f64a: "Speak-no-evil monkey",

    emjD1f64b: "Happy person raising one hand",

    emjD1f64c: "Person raising both hands in celebration",

    emjD1f64d: "Person frowning",

    emjD1f64e: "Person with pouting face",

    emjD1f64f: "Person with folded hands",

    emjD02600: "Sun with rays",

    emjD02601: "Cloud",

    emjD02614: "Umbrella with rain drops",

    emjD0267b: "Recycling symbol",

    emjD026c4: "Snowman without snow",

    emjD026c5: "Sun behind cloud",

    emjD02728: "Sparkles",

    emjD02733: "Eight-spoked asterisk",

    emjD02734: "Eight-pointed star",

    emjD02744: "Snowflake",

    emjD02747: "Sparkle",

    emjD02b50: "White medium star",

    emjD1f300: "Cyclone",

    emjD1f301: "Foggy",

    emjD1f302: "Closed umbrella",

    emjD1f303: "Night with stars",

    emjD1f304: "Sunrise over mountains",

    emjD1f305: "Sunrise",

    emjD1f306: "Cityscape at dusk",

    emjD1f307: "Sunset over buildings",

    emjD1f308: "Rainbow",

    emjD1f309: "Bridge at night",

    emjD1f30a: "Water wave",

    emjD1f30b: "Volcano",

    emjD1f30c: "Milky Way",

    emjD1f311: "New moon",

    emjD1f313: "First quarter moon",

    emjD1f314: "Waxing gibbous moon",

    emjD1f315: "Full moon",

    emjD1f319: "Crescent moon",

    emjD1f31b: "First quarter moon with face",

    emjD1f31f: "Glowing star",

    emjD1f320: "Shooting star",

    emjD1f330: "Chestnut",

    emjD1f331: "Seedling",

    emjD1f334: "Palm tree",

    emjD1f335: "Cactus",

    emjD1f337: "Tulip",

    emjD1f338: "Cherry blossom",

    emjD1f339: "Rose",

    emjD1f33a: "Hibiscus",

    emjD1f33b: "Sunflower",

    emjD1f33c: "Blossom",

    emjD1f33d: "Ear of corn",

    emjD1f33e: "Ear of rice",

    emjD1f33f: "Herb",

    emjD1f340: "Four leaf clover",

    emjD1f341: "Maple leaf",

    emjD1f342: "Fallen leaf",

    emjD1f343: "Leaf fluttering in wind",

    emjD1f344: "Mushroom",

    emjD1f40c: "Snail",

    emjD1f40d: "Snake",

    emjD1f40e: "Horse",

    emjD1f411: "Sheep",

    emjD1f412: "Monkey",

    emjD1f414: "Chicken",

    emjD1f417: "Boar",

    emjD1f418: "Elephant",

    emjD1f419: "Octopus",

    emjD1f41a: "Spiral shell",

    emjD1f41b: "Bug",

    emjD1f41c: "Ant",

    emjD1f41d: "Honeybee",

    emjD1f41e: "Ladybug",

    emjD1f41f: "Fish",

    emjD1f420: "Tropical fish",

    emjD1f421: "Blowfish",

    emjD1f422: "Turtle",

    emjD1f423: "Hatching chick",

    emjD1f424: "Baby chick",

    emjD1f425: "Front-facing baby chick",

    emjD1f426: "Bird",

    emjD1f427: "Penguin",

    emjD1f428: "Koala",

    emjD1f429: "Poodle",

    emjD1f42b: "Bactrian camel",

    emjD1f42c: "Dolphin",

    emjD1f42d: "Mouse face",

    emjD1f42e: "Cow face",

    emjD1f42f: "Tiger face",

    emjD1f430: "Rabbit face",

    emjD1f431: "Cat face",

    emjD1f432: "Dragon face",

    emjD1f433: "Spouting whale",

    emjD1f434: "Horse face",

    emjD1f435: "Monkey face",

    emjD1f436: "Dog face",

    emjD1f437: "Pig face",

    emjD1f438: "Frog face",

    emjD1f439: "Hamster face",

    emjD1f43a: "Wolf face",

    emjD1f43b: "Bear face",

    emjD1f43c: "Panda face",

    emjD1f43d: "Pig nose",

    emjD1f43e: "Paw prints",

    emjD1f638: "Grinning cat face with smiling eyes",

    emjD1f639: "Cat face with tears of joy",

    emjD1f63a: "Smiling cat face with open mouth",

    emjD1f63b: "Smiling cat face with heart-shaped eyes",

    emjD1f63c: "Cat face with wry smile",

    emjD1f63d: "Kissing cat face with closed eyes",

    emjD1f63e: "Pouting cat face",

    emjD1f63f: "Crying cat face",

    emjD1f640: "Weary cat face",

    emjD0260e: "Telephone",

    emjD026bd: "Soccer ball",

    emjD026be: "Baseball",

    emjD1f004: "Mahjong tile red dragon",

    emjD1f380: "Ribbon",

    emjD1f381: "Wrapped present",

    emjD1f382: "Birthday cake",

    emjD1f383: "Jack-o-lantern",

    emjD1f384: "Christmas tree",

    emjD1f385: "Father Christmas",

    emjD1f386: "Fireworks",

    emjD1f387: "Firework sparkler",

    emjD1f388: "Balloon",

    emjD1f389: "Party popper",

    emjD1f38a: "Confetti ball",

    emjD1f38b: "Tanabata tree",

    emjD1f38c: "Crossed flags",

    emjD1f38d: "Pine decoration",

    emjD1f38e: "Japanese dolls",

    emjD1f38f: "Carp streamer",

    emjD1f390: "Wind chime",

    emjD1f391: "Moon-viewing ceremony",

    emjD1f392: "School backpack",

    emjD1f393: "Graduation cap",

    emjD1f3a0: "Carousel horse",

    emjD1f3a1: "Ferris wheel",

    emjD1f3a2: "Roller coaster",

    emjD1f3a3: "Fishing pole and fish",

    emjD1f3a4: "Microphone",

    emjD1f3a5: "Movie camera",

    emjD1f3a6: "Cinema",

    emjD1f3a7: "Headphones",

    emjD1f3a8: "Artist palette",

    emjD1f3a9: "Top hat",

    emjD1f3aa: "Circus tent",

    emjD1f3ab: "Ticket",

    emjD1f3ac: "Clapper board",

    emjD1f3ad: "Performing arts",

    emjD1f3ae: "Video game",

    emjD1f3af: "Direct hit",

    emjD1f3b0: "Slot machine",

    emjD1f3b1: "Billiards",

    emjD1f3b2: "Game die",

    emjD1f3b3: "Bowling",

    emjD1f3b4: "Flower playing cards",

    emjD1f3b5: "Musical note",

    emjD1f3b6: "Multiple musical notes",

    emjD1f3b7: "Saxophone",

    emjD1f3b8: "Guitar",

    emjD1f3b9: "Musical keyboard",

    emjD1f3ba: "Trumpet",

    emjD1f3bb: "Violin",

    emjD1f3bc: "Musical score",

    emjD1f3bd: "Running shirt with sash",

    emjD1f3be: "Tennis racquet and ball",

    emjD1f3bf: "Ski and ski boot",

    emjD1f3c0: "Basketball and hoop",

    emjD1f3c1: "Checkered flag",

    emjD1f3c2: "Snowboarder",

    emjD1f3c3: "Runner",

    emjD1f3c4: "Surfer",

    emjD1f3c6: "Trophy",

    emjD1f3c8: "American football",

    emjD1f3ca: "Swimmer",

    emjD1f478: "Princess",

    emjD1f479: "Japanese ogre",

    emjD1f47a: "Japanese goblin",

    emjD1f47b: "Ghost",

    emjD1f47c: "Baby angel",

    emjD1f47d: "Extraterrestrial alien",

    emjD1f47e: "Alien monster",

    emjD1f47f: "Imp",

    emjD1f480: "Skull",

    emjD1f483: "Dancer",

    emjD1f484: "Lipstick",

    emjD1f485: "Nail polish",

    emjD1f486: "Face massage",

    emjD1f487: "Haircut",

    emjD1f488: "Barber pole",

    emjD1f489: "Syringe",

    emjD1f48a: "Pill",

    emjD1f48c: "Love letter",

    emjD1f48d: "Ring",

    emjD1f48e: "Gemstone",

    emjD1f48f: "Kiss",

    emjD1f490: "Bouquet",

    emjD1f491: "Couple with heart",

    emjD1f492: "Wedding",

    emjD1f4f7: "Camera",

    emjD1f4f9: "Video camera",

    emjD1f4fa: "Television",

    emjD1f4fb: "Radio",

    emjD1f4fc: "Videocassette",

    emjD02615: "Hot beverage",

    emjD02702: "Scissors",

    emjD02709: "Envelope",

    emjD0270f: "Pencil",

    emjD02712: "Nib",

    emjD1f345: "Tomato",

    emjD1f346: "Eggplant",

    emjD1f347: "Grapes",

    emjD1f348: "Melon",

    emjD1f349: "Watermelon",

    emjD1f34a: "Tangerine",

    emjD1f34c: "Banana",

    emjD1f34d: "Pineapple",

    emjD1f34e: "Red apple",

    emjD1f34f: "Green apple",

    emjD1f351: "Peach",

    emjD1f352: "Cherries",

    emjD1f353: "Strawberry",

    emjD1f354: "Hamburger",

    emjD1f355: "Slice of pizza",

    emjD1f356: "Meat on bone",

    emjD1f357: "Poultry leg",

    emjD1f358: "Rice cracker",

    emjD1f359: "Rice ball",

    emjD1f35a: "Cooked rice",

    emjD1f35b: "Curry and rice",

    emjD1f35c: "Steaming bowl",

    emjD1f35d: "Spaghetti",

    emjD1f35e: "Bread",

    emjD1f35f: "French fries",

    emjD1f360: "Roasted sweet potato",

    emjD1f361: "Dango",

    emjD1f362: "Oden",

    emjD1f363: "Sushi",

    emjD1f364: "Fried shrimp",

    emjD1f365: "Fish cake with swirl design",

    emjD1f366: "Soft ice cream",

    emjD1f367: "Shaved ice",

    emjD1f368: "Ice cream",

    emjD1f369: "Doughnut",

    emjD1f36a: "Cookie",

    emjD1f36b: "Chocolate bar",

    emjD1f36c: "Candy",

    emjD1f36d: "Lollipop",

    emjD1f36e: "Custard",

    emjD1f36f: "Honey pot",

    emjD1f370: "Shortcake",

    emjD1f371: "Bento box",

    emjD1f372: "Pot of food",

    emjD1f373: "Cooking",

    emjD1f374: "Fork and knife",

    emjD1f375: "Teacup without handle",

    emjD1f376: "Sake bottle and cup",

    emjD1f377: "Wine glass",

    emjD1f378: "Cocktail glass",

    emjD1f379: "Tropical drink",

    emjD1f37a: "Beer mug",

    emjD1f37b: "Clinking beer mugs",

    emjD1f451: "Crown",

    emjD1f452: "Woman's hat",

    emjD1f453: "Eyeglasses",

    emjD1f454: "Necktie",

    emjD1f455: "T-shirt",

    emjD1f456: "Jeans",

    emjD1f457: "Dress",

    emjD1f458: "Kimono",

    emjD1f459: "Bikini",

    emjD1f45a: "Woman's clothes",

    emjD1f45b: "Purse",

    emjD1f45c: "Handbag",

    emjD1f45d: "Pouch",

    emjD1f45e: "Man's shoe",

    emjD1f45f: "Athletic shoe",

    emjD1f460: "High-heeled shoe",

    emjD1f461: "Woman's sandal",

    emjD1f462: "Woman's boots",

    emjD1f463: "Footprints",

    emjD1f4ba: "Seat",

    emjD1f4bb: "Personal computer",

    emjD1f4bc: "Briefcase",

    emjD1f4bd: "Minidisc",

    emjD1f4be: "Floppy disk",

    emjD1f4bf: "Optical disc",

    emjD1f4c0: "DVD",

    emjD1f4c1: "File folder",

    emjD1f4c2: "Open file folder",

    emjD1f4c3: "Page with curl",

    emjD1f4c4: "Page facing up",

    emjD1f4c5: "Calendar",

    emjD1f4c6: "Tear-off calendar",

    emjD1f4c7: "Card index",

    emjD1f4c8: "Chart with upward trend",

    emjD1f4c9: "Chart with downward trend",

    emjD1f4ca: "Bar chart",

    emjD1f4cb: "Clipboard",

    emjD1f4cc: "Pushpin",

    emjD1f4cd: "Round pushpin",

    emjD1f4ce: "Paper clip",

    emjD1f4cf: "Straight ruler",

    emjD1f4d0: "Triangular ruler",

    emjD1f4d1: "Bookmark tabs",

    emjD1f4d2: "Ledger",

    emjD1f4d3: "Notebook",

    emjD1f4d4: "Notebook with decorative cover",

    emjD1f4d5: "Closed book",

    emjD1f4d6: "Open book",

    emjD1f4d7: "Green book",

    emjD1f4d8: "Blue book",

    emjD1f4d9: "Orange book",

    emjD1f4da: "Books",

    emjD1f4db: "Name badge",

    emjD1f4dc: "Scroll",

    emjD1f4dd: "Memo",

    emjD1f4de: "Telephone receiver",

    emjD1f4df: "Pager",

    emjD1f4e0: "Fax machine",

    emjD1f4e1: "Satellite antenna",

    emjD1f4e2: "Public address loudspeaker",

    emjD1f4e3: "Cheering megaphone",

    emjD1f4e4: "Outbox tray",

    emjD1f4e5: "Inbox tray",

    emjD1f4e6: "Package",

    emjD1f4e7: "Email",

    emjD1f4e8: "Incoming envelope",

    emjD1f4e9: "Envelope with downward-facing arrow above",

    emjD1f4ea: "Closed mailbox with lowered flag",

    emjD1f4eb: "Closed mailbox with raised flag",

    emjD1f4ee: "Postbox",

    emjD1f4f0: "Newspaper",

    emjD1f4f1: "Mobile phone",

    emjD1f4f2: "Mobile phone with right-facing arrow at left",

    emjD1f4f3: "Vibration mode",

    emjD1f4f4: "Mobile phone off",

    emjD1f4f6: "Antenna with bars",

    emjD1f525: "Fire",

    emjD1f526: "Flashlight",

    emjD1f527: "Wrench",

    emjD1f528: "Hammer",

    emjD1f529: "Nut and bolt",

    emjD1f52a: "Hocho",

    emjD1f52b: "Pistol",

    emjD1f52e: "Crystal ball",

    emjD1f52f: "Six-pointed star with middle dot",

    emjD1f531: "Trident emblem",

    emjD1f550: "Clock face one o'clock",

    emjD1f551: "Clock face two o'clock",

    emjD1f552: "Clock face three o'clock",

    emjD1f553: "Clock face four o'clock",

    emjD1f554: "Clock face five o'clock",

    emjD1f555: "Clock face six o'clock",

    emjD1f556: "Clock face seven o'clock",

    emjD1f557: "Clock face eight o'clock",

    emjD1f558: "Clock face nine o'clock",

    emjD1f559: "Clock face ten o'clock",

    emjD1f55a: "Clock face eleven o'clock",

    emjD1f55b: "Clock face twelve o'clock",

    emjD02668: "Hot springs",

    emjD0267f: "Wheelchair",

    emjD02693: "Anchor",

    emjD026a0: "Warning",

    emjD026a1: "High Voltage",

    emjD026d4: "No Entry",

    emjD026ea: "Church",

    emjD026f2: "Fountain",

    emjD026f3: "Flag in hole",

    emjD026f5: "Sailboat",

    emjD026fa: "Tent",

    emjD026fd: "Fuel pump",

    emjD02708: "Airplane",

    emjD1f17f: "Squared Latin capital letter P",

    emjD1f3e0: "House building",

    emjD1f3e1: "House with garden",

    emjD1f3e2: "Office building",

    emjD1f3e3: "Japanese post office",

    emjD1f3e5: "Hospital",

    emjD1f3e6: "Bank",

    emjD1f3e7: "Automated teller machine",

    emjD1f3e8: "Hotel",

    emjD1f3e9: "Love hotel",

    emjD1f3ea: "Convenience store",

    emjD1f3eb: "School",

    emjD1f3ec: "Department store",

    emjD1f3ed: "Factory",

    emjD1f3ee: "Izakaya lantern",

    emjD1f3ef: "Japanese castle",

    emjD1f3f0: "European castle",

    emjD1f530: "Japanese symbol for beginner",

    emjD1f680: "Rocket",

    emjD1f683: "Railway car",

    emjD1f684: "High-speed train",

    emjD1f685: "High-speed train with bullet nose",

    emjD1f687: "Metro",

    emjD1f689: "Station",

    emjD1f68c: "Bus",

    emjD1f68f: "Bus stop",

    emjD1f691: "Ambulance",

    emjD1f692: "Fire engine",

    emjD1f693: "Police car",

    emjD1f695: "Taxi",

    emjD1f697: "Automobile",

    emjD1f699: "Recreational vehicle",

    emjD1f69a: "Delivery truck",

    emjD1f6a2: "Ship",

    emjD1f6a4: "Speedboat",

    emjD1f6a5: "Horizontal traffic light",

    emjD1f6a7: "Construction",

    emjD1f6a8: "Police car's revolving light",

    emjD1f6a9: "Triangular flag on post",

    emjD1f6aa: "Door",

    emjD1f6ab: "No entry",

    emjD1f6ac: "Smoking symbol",

    emjD1f6ad: "No smoking symbol",

    emjD1f6b2: "Bicycle",

    emjD1f6b6: "Pedestrian",

    emjD1f6b9: "Men's symbol",

    emjD1f6ba: "Women's symbol",

    emjD1f6bb: "Restroom",

    emjD1f6bc: "Baby symbol",

    emjD1f6bd: "Toilet",

    emjD1f6be: "Water closet",

    emjD1f6c0: "Bath",

    emjD02049: "Exclamation question mark",

    emjD02139: "Information source",

    emjD021a9: "Left-facing arrow with hook",

    emjD021aa: "Right-facing arrow with hook",

    emjD0231a: "Watch",

    emjD0231b: "Hourglass",

    emjD023e9: "Right-pointing double triangle",

    emjD023ea: "Left-pointing double triangle",

    emjD023eb: "Up-pointing double triangle",

    emjD023ec: "Down-pointing double triangle",

    emjD023f0: "Alarm clock",

    emjD023f3: "Hourglass with flowing sand",

    emjD024c2: "Circled Latin capital letter M",

    emjD025b6: "Black right-pointing triangle",

    emjD025c0: "Black left-pointing triangle",

    emjD025fb: "White medium square",

    emjD025fc: "Black medium square",

    emjD026ce: "Ophiuchus",

    emjD02611: "Ballot box with check",

    emjD0261d: "White up-pointing index",

    emjD02648: "Aries",

    emjD02649: "Taurus",

    emjD0264a: "Gemini",

    emjD0264b: "Cancer",

    emjD0264c: "Leo",

    emjD0264d: "Virgo",

    emjD0264e: "Libra",

    emjD0264f: "Scorpio",

    emjD02650: "Sagittarius",

    emjD02651: "Capricorn",

    emjD02652: "Aquarius",

    emjD02653: "Pisces",

    emjD026aa: "Medium white circle",

    emjD026ab: "Medium black circle",

    emjD02705: "White heavy check mark",

    emjD02714: "Heavy check mark",

    emjD02716: "Heavy multiplication x",

    emjD0274c: "Cross mark",

    emjD0274e: "Squared cross mark",

    emjD02753: "question mark ornament",

    emjD02754: "White question mark ornament",

    emjD02755: "White exclamation mark ornament",

    emjD02757: "Heavy exclamation mark",

    emjD02795: "Heavy plus sign",

    emjD02796: "Heavy minus sign",

    emjD02797: "Heavy division sign",

    emjD027a1: "Black right-facing arrow",

    emjD027b0: "Curly loop",

    emjD027bf: "Double curly loop",

    emjD02934: "Arrow pointing right then curving upward",

    emjD02935: "Arrow pointing right then curving downward",

    emjD02b05: "Black arrow pointing left",

    emjD02b06: "Black arrow pointing right",

    emjD02b07: "Downwards black arrow",

    emjD02b1b: "Black large square",

    emjD02b1c: "White large square",

    emjD02b55: "Heavy large circle",

    emjD03030: "Wavy dash",

    emjD0303d: "Part alternation mark",

    emjD03297: "Circled Ideograph congratulation",

    emjD03299: "Circled Ideograph secret",

    emjD1f0cf: "Playing card Joker",

    emjD1f170: "Squared Latin capital letter A",

    emjD1f171: "Squared Latin capital letter B",

    emjD1f17e: "Squared Latin capital letter O",

    emjD1f18e: "Squared AB",

    emjD1f191: "Squared CL",

    emjD1f192: "Squared COOL",

    emjD1f193: "Squared FREE",

    emjD1f194: "Squared ID",

    emjD1f195: "Squared NEW",

    emjD1f196: "Squared NG",

    emjD1f197: "Squared OK",

    emjD1f198: "Squared SOS",

    emjD1f199: "Squared UP!",

    emjD1f19a: "Squared VS",

    emjD1f201: "Squared Katakana Koko",

    emjD1f202: "Squared Katakana Sa",

    emjD1f21a: "Squared CJK Unified Ideograph-7121",

    emjD1f22f: "Squared CJK Unified Ideograph-6307",

    emjD1f232: "Squared CJK Unified Ideograph-7981",

    emjD1f233: "Squared CJK Unified Ideograph-7a7a",

    emjD1f234: "Squared CJK Unified Ideograph-5408",

    emjD1f235: "Squared CJK Unified Ideograph-6e80",

    emjD1f236: "Squared CJK Unified Ideograph-6709",

    emjD1f237: "Squared CJK Unified Ideograph-6708",

    emjD1f238: "Squared CJK Unified Ideograph-7533",

    emjD1f239: "Squared CJK Unified Ideograph-5272",

    emjD1f23a: "Squared CJK Unified Ideograph-55b6",

    emjD1f250: "Circled Ideograph advantage",

    emjD1f251: "Circled Ideograph accept",

    emjD1f30f: "Earth globe Asia-Australia",

    emjD1f4a0: "Diamond shape with a dot inside",

    emjD1f4a1: "Electric light bulb",

    emjD1f4a2: "Anger",

    emjD1f4a3: "Bomb",

    emjD1f4a4: "Sleeping",

    emjD1f4a5: "Collision",

    emjD1f4a6: "Splashing sweat",

    emjD1f4a7: "Droplet",

    emjD1f4a8: "Dash",

    emjD1f4a9: "Pile of poo",

    emjD1f4aa: "Flexed biceps",

    emjD1f4ab: "Dizzy",

    emjD1f4ac: "Speech balloon",

    emjD1f4ae: "White flower",

    emjD1f4af: "Hundred points",

    emjD1f4b0: "Money bag",

    emjD1f4b1: "Currency exchange",

    emjD1f4b2: "Heavy dollar sign",

    emjD1f4b3: "Credit card",

    emjD1f4b4: "Banknote with yen sign",

    emjD1f4b5: "Banknote with dollar sign",

    emjD1f4b8: "Money with wings",

    emjD1f4b9: "Chart with upwards trend and yen sign",

    emjD1f503: "Clockwise downward and upward open circle arrows",

    emjD1f50a: "Speaker with three sound waves",

    emjD1f50b: "Battery",

    emjD1f50c: "Electric plug",

    emjD1f50d: "Left-leaning magnifying glass",

    emjD1f50e: "Right-leaning magnifying glass",

    emjD1f50f: "Lock with ink pen",

    emjD1f510: "Closed lock with key",

    emjD1f511: "Key",

    emjD1f512: "Lock",

    emjD1f513: "Open lock",

    emjD1f514: "Bell",

    emjD1f516: "Bookmark",

    emjD1f517: "Link",

    emjD1f518: "Radio button",

    emjD1f519: "Back with left-facing arrow above",

    emjD1f51a: "End with left-facing arrow above",

    emjD1f51b: "On with exclamation mark with left right arrow above",

    emjD1f51c: "Soon with right-facing arrow above",

    emjD1f51d: "Top with upward arrow above",

    emjD1f51e: "No one under eighteen",

    emjD1f51f: "Keycap ten",

    emjD1f520: "Input Latin uppercase",

    emjD1f521: "Input Latin lowercase",

    emjD1f522: "Input numbers",

    emjD1f523: "Input symbols",

    emjD1f524: "Input Latin letters",

    emjD1f532: "Black square button",

    emjD1f533: "White square button",

    emjD1f534: "Large red circle",

    emjD1f535: "Large blue circle",

    emjD1f536: "Large orange diamond",

    emjD1f537: "Large blue diamond",

    emjD1f538: "Small orange diamond",

    emjD1f539: "Small blue diamond",

    emjD1f53a: "Up-pointing red triangle",

    emjD1f53b: "Down-pointing red triangle",

    emjD1f53c: "Up-pointing small red triangle",

    emjD1f53d: "Down-pointing small red triangle",

    emjD1f5fb: "Mount Fuji",

    emjD1f5fc: "Tokyo Tower",

    emjD1f5fd: "Statue of Liberty",

    emjD1f5fe: "Silhouette of Japan",

    emjD1f5ff: "Moyai",

    // Search tags
    emjK1f607: "saint angel innocent",

    emjK1f47c: "cherub angel",

    emjK1f34e: "apple",

    emjK1f34f: "apple",

    emjK1f477: "construction",

    emjK1f6a7: "construction detour",

    emjK1f491: "couple engaged married marry marriage",

    emjK1f46b: "couple engaged",

    emjK1f622: "crying sad",

    emjK1f62d: "crying sad",

    emjK1f525: "fire",

    emjK1f692: "fire truck fire engine",

    emjK1f386: "fireworks sparkler july 4th",

    emjK1f387: "fireworks sparkler july 4th",

    emjK1f44a: "punch fist pump chuck norris bam",

    emjK0270a: "fist pump punch",

    emjK1f498: "heart love cupid",

    emjK1f496: "heart love",

    emjK1f497: "love heart",

    emjK1f493: "heart love heartbeat",

    emjK1f368: "ice cream dessert treat sundae sweets",

    emjK1f366: "ice cream dessert treat sweets",

    emjK1f48b: "kiss xoxo love kisses kissing mwah",

    emjK1f444: "kiss mouth",

    emjK1f618: "kiss love kisses kissing",

    emjK1f61a: "kiss love kisses kissing smooch",

    emjK1f48f: "kiss love",

    emjK1f435: "monkey",

    emjK1f64a: "monkey speak no evil",

    emjK1f649: "monkey hear no evil",

    emjK1f648: "monkey see no evil",

    emjK1f3b6: "music melody song singing tune jingle",

    emjK1f3b5: "music musical note melody musical",

    emjK1f44c: "ok okay perfect",

    emjK1f646: "ok awesome",

    emjK1f621: "pouting sad pout",

    emjK1f64e: "pouting sad depressed",

    emjK1f60c: "relieved phew whew relief",

    emjK1f630: "relieved phew whew",

    emjK1f605: "relieved phew whew sheesh",

    emjK1f380: "ribbon",

    emjK1f381: "gift present presents ribbon",

    emjK1f613: "scared yikes scary uh oh",

    emjK1f631: "scared yikes fear whoa",

    emjK1f629: "scared anxious uncertain unsure",

    emjK1f628: "scared fearful",

    emjK1f45e: "shoe shoes",

    emjK1f45f: "shoe shoes",

    emjK1f60a: "smile happy smiling yay",

    emjK1f642: "smile happy smiling",

    emjK1f603: "smiling happy excited woo woohoo woot",

    emjK1f604: "smiling happy grin excited",

    emjK1f6bd: "toilet bathroom potty restroom washroom",

    emjK1f6ba: "toilet bathroom",

    emjK1f6b9: "toilet bathroom",

    emjK1f684: "train light rail monorail",

    emjK1f683: "train caboose",

    emjK1f446: "up click",

    emjK0261d: "up",

    emjK1f64b: "wave hi bye hey aloha",

    emjK1f44b: "wave hi bye waving high five",

    emjK1f601: "happy grin lol funny grinning hehe",

    emjK1f602: "happy lol funny joy lmao rofl",

    emjK1f606: "lol haha laughing lmao",

    emjK1f609: "haha winking wink winky",

    emjK1f60f: "haha winking wink smirking smirk heh",

    emjK1f645: "bad",

    emjK1f44e: "bad thumbs down wrong boo",

    emjK1f60d: "love love you loving love u",

    emjK1f495: "love hearts",

    emjK1f44d: "ok thumbs up",

    emjK1f610: "ok umm",

    emjK1f620: "mad angry grrr",

    emjK1f612: "mad angry unamused not funny amused bleh blah",

    emjK1f47b: "scared halloween ghost boo",

    emjK1f480: "scared skull danger die death poison",

    emjK1f47e: "scared monster",

    emjK1f365: "rice ball fish cake",

    emjK1f61e: "disappointed sad",

    emjK1f64d: "disappointed sad frown",

    emjK1f494: "heartbroken sorrow sad broken heart brokenhearted",

    emjK1f625: "relieved phew",

    emjK1f62b: "sleepy tired yawn",

    emjK1f4a4: "sleepy tired sleep zzz",

    emjK1f62a: "sleepy tired",

    emjK1f632: "wow astonished",

    emjK1f633: "wow flushed",

    emjK1f3c4: "wave surf surfer surfers surfs surfing",

    emjK1f44f: "yay clapping applause clap",

    emjK1f64c: "yay celebrate",

    emjK1f48d: "ring marry fiance engaged engage engagement ring engagement diamond ring bling",

    emjK1f483: "party dancer dance dancing",

    emjK1f389: "party fun congratulations celebrate congrats",

    emjK1f388: "party fun balloon",

    emjK1f38a: "party confetti surprise",

    emjK1f383: "happy halloween trick treat halloween pumpkin",

    emjK1f385: "merry christmas santa claus father xmas",

    emjK1f384: "merry christmas tree happy holidays",

    emjK1f3eb: "school college study teacher learn studying",

    emjK1f392: "backpack school bag back pack",

    emjK1f41f: "fish goldfish",

    emjK1f3a3: "fish fishing fishing pole",

    emjK1f3a5: "video camera film movie movie camera",

    emjK1f4f9: "video camera",

    emjK1f3a6: "theatre theater cinema",

    emjK1f3ad: "performing arts drama shakespeare theatre theater play actor actress",

    emjK1f3bc: "treble clef sheet music music musical score",

    emjK1f3b9: "music piano",

    emjK1f3a7: "music headphones headphone",

    emjK1f3b7: "music saxophone sax",

    emjK1f3ba: "music trumpet",

    emjK1f3b8: "music guitar",

    emjK1f3bb: "music violin",

    emjK1f3ac: "film action movie",

    emjK1f48e: "diamond stone gem",

    emjK1f3be: "wimbledon tennis raquet tennis sports racquet",

    emjK1f3c8: "football superbowl sports nfl",

    emjK1f3bf: "sports winter ski skiing skis",

    emjK1f3c2: "sports winter snowboard snowboarder snowboarding snowboards",

    emjK026c4: "winter snow snowman",

    emjK02744: "winter snow snowflake snowing snowed christmas xmas blizzard",

    emjK1f3c1: "race finish nascar",

    emjK1f3c3: "race run marathon runner sprinting running late",

    emjK1f375: "tea coffee",

    emjK02615: "coffee tea",

    emjK1f377: "alcohol wine",

    emjK1f378: "alcohol cocktail drinks martini happy hour",

    emjK1f379: "alcohol tropical drink",

    emjK1f68c: "bus transit",

    emjK1f687: "bus transit metro",

    emjK1f43b: "bear teddy",

    emjK1f43c: "bear panda",

    emjK1f340: "luck lucky st. patrick's day shamrock",

    emjK1f320: "luck shooting star wish comet asteroid meteor meteroid",

    emjK1f303: "skyline starry stars night",

    emjK1f307: "skyline sunset",

    emjK1f31f: "star twinkle",

    emjK02747: "star sparkle glitter",

    emjK1f4b0: "cash loot",

    emjK1f4b5: "cash money dollar",

    emjK1f4b4: "yen money",

    emjK1f4b3: "money credit debt debit credit card",

    emjK1f60b: "dinner hungry lunch food yummy yum delicious tasty mmmmm",

    emjK1f60e: "cool",

    emjK1f614: "alas thinking sigh",

    emjK1f616: "confused confounded huh",

    emjK1f61c: "goofy wassup nyah kidding",

    emjK1f61d: "gross yuck eww blech",

    emjK1f623: "persevering",

    emjK1f624: "triumph congratulations grats congrats yahoo ftw woot wahoo",

    emjK1f635: "dizzy drunk confused",

    emjK1f636: "unsure",

    emjK1f637: "sick flu",

    emjK1f440: "eyes snoop",

    emjK1f442: "ear listen",

    emjK1f443: "nose smell",

    emjK1f445: "tongue lick taste drool",

    emjK1f447: "down",

    emjK1f448: "left",

    emjK1f449: "right",

    emjK1f64f: "pray praying prayer",

    emjK0270b: "hand",

    emjK0270c: "peace victory",

    emjK1f466: "boy",

    emjK1f467: "girl",

    emjK1f468: "man",

    emjK1f469: "woman",

    emjK1f46a: "family",

    emjK1f46e: "police officer cop",

    emjK1f46f: "bunny ears costume",

    emjK1f470: "bride marriage bridezilla",

    emjK1f471: "blond",

    emjK1f474: "grandpa old man gramps grandfather",

    emjK1f475: "grandma old lady old woman grandmother",

    emjK1f476: "baby kid toddler newborn infant",

    emjK1f382: "birthday cake happy cake",

    emjK1f390: "chime",

    emjK1f393: "graduation college high school graduated graduating graduate grad",

    emjK1f0cf: "cards card joker blackjack poker",

    emjK1f3a0: "carousel carnival",

    emjK1f3a1: "ferris wheel amusement park",

    emjK1f3a2: "roller coaster",

    emjK1f3a4: "microphone sing karaoke singing",

    emjK1f3a8: "paint artist palette artist",

    emjK1f3a9: "top hat black tie",

    emjK1f3aa: "circus",

    emjK1f3ab: "ticket stub ticket stub admission",

    emjK1f3ae: "xbox video game video games controller",

    emjK1f3af: "bullseye archery darts arrow",

    emjK1f3b0: "slot slots machine casino gamble gambling",

    emjK1f3b1: "pool billiards",

    emjK1f3b2: "die dice game craps gambling casino gamble",

    emjK1f3b3: "bowling bowl",

    emjK1f4f7: "camera picture photo",

    emjK1f4fa: "tv television",

    emjK1f4fb: "radio",

    emjK1f4fc: "videotape videocassette cassette vcr",

    emjK1f478: "princess",

    emjK1f47d: "alien ufo",

    emjK1f47f: "imp pixie loki leprechaun",

    emjK1f484: "lipstick makeup",

    emjK1f485: "nail polish manicure",

    emjK1f486: "massage",

    emjK1f487: "haircut",

    emjK1f488: "barber barbershop",

    emjK1f489: "syringe shot needle",

    emjK1f48a: "pill pills drug drugs",

    emjK1f48c: "love letter",

    emjK1f490: "flowers bouquet love",

    emjK1f492: "wedding marry church",

    emjK1f3c0: "basketball bball hoops",

    emjK1f3c6: "win trophy champions champion",

    emjK1f3ca: "swim swimmer swimming swims",

    emjK026bd: "soccer ball",

    emjK026be: "baseball",

    emjK1f451: "crown king queen",

    emjK1f453: "glasses hipster",

    emjK1f454: "tie",

    emjK1f455: "tshirt tshirts",

    emjK1f456: "jeans pants",

    emjK1f457: "dress",

    emjK1f459: "bikini bathing suit swimsuit swim",

    emjK1f45b: "purse",

    emjK1f45c: "handbag purse",

    emjK1f45d: "pouch clutch",

    emjK1f461: "sandal heels heel shoe shoes sandals pumps",

    emjK1f462: "boots boot",

    emjK1f463: "footprints barefoot",

    emjK1f4dd: "memo note",

    emjK1f4de: "telephone call phone",

    emjK1f4df: "pager",

    emjK1f4e0: "fax machine",

    emjK1f4e1: "satellite antenna",

    emjK1f4e3: "megaphone cheering",

    emjK1f4e6: "present package parcel",

    emjK1f4e7: "email mail",

    emjK1f4ee: "mail post",

    emjK1f4f0: "newspaper news",

    emjK1f4f1: "phone cell call",

    emjK1f4f6: "wifi signal",

    emjK1f526: "flashlight",

    emjK1f527: "wrench",

    emjK1f528: "hammer hammers",

    emjK1f529: "nuts bolts",

    emjK1f52a: "knife",

    emjK1f52e: "crystal ball clairvoyance clairvoyant psychic mystic",

    emjK1f531: "trident",

    emjK1f354: "hamburger fast burger hamburgers burgers food hungry",

    emjK1f355: "pizza fast food hungry dinner lunch",

    emjK1f356: "meat dinner lunch hungry food",

    emjK1f357: "chicken leg turkey leg meat chicken turkey hungry dinner lunch food drumstick",

    emjK1f35a: "rice dinner hungry lunch food",

    emjK1f35c: "noodles ramen food dinner lunch",

    emjK1f35d: "spaghetti dinner noodles food hungry",

    emjK1f35e: "bread food",

    emjK1f35f: "fries fast food french fries food",

    emjK1f360: "potato potatoes vegetable food",

    emjK1f363: "sushi food maki",

    emjK1f364: "fried shrimp shrimp seafood food tempura",

    emjK1f369: "doughnut doughnuts donut donuts food sweets",

    emjK1f36a: "cookie cookies food sweets",

    emjK1f36b: "chocolate chocolates candy bar chocolate bar sweets",

    emjK1f36c: "candy sweet sweets treat",

    emjK1f36d: "lollipop candy treat sucker",

    emjK1f36e: "custard",

    emjK1f36f: "honey pot",

    emjK1f370: "pie food hungry cake dessert cheesecake shortcake sweets",

    emjK1f371: "bento box",

    emjK1f372: "soup pot of food stew",

    emjK1f374: "food dinner fork knife eat hungry meal restaurant",

    emjK1f376: "sake alcohol",

    emjK1f37a: "beer alcohol",

    emjK1f37b: "cheers beers alcohol",

    emjK1f345: "food vegetable fruit tomato",

    emjK1f346: "eggplant food vegetable",

    emjK1f347: "grapes fruit food",

    emjK1f348: "melon honeydew food fruit",

    emjK1f349: "watermelon melon food fruit",

    emjK1f34a: "tangerine fruit food orange mandarin clementine slice slices",

    emjK1f34c: "banana fruit food",

    emjK1f34d: "pineapple fruit food",

    emjK1f351: "peach peaches fruit food",

    emjK1f352: "cherry cherries fruit food",

    emjK1f353: "strawberries stawberry fruit food berry berries",

    emjK1f4ba: "seat",

    emjK1f4bb: "pc computer laptop tablet",

    emjK1f4bc: "briefcase brief case",

    emjK1f4be: "floppy disk diskette",

    emjK1f4bf: "cd compact disc",

    emjK1f4c0: "dvd",

    emjK1f4c1: "file folder",

    emjK1f4c5: "calendar",

    emjK1f4cb: "clipboard",

    emjK1f4cc: "pushpin",

    emjK1f4cd: "drawing pin",

    emjK1f4ce: "paperclip clippy",

    emjK1f4cf: "ruler measure",

    emjK1f4d2: "ledger",

    emjK1f4d3: "notebook",

    emjK1f4da: "book books",

    emjK1f4db: "name badge",

    emjK02702: "scissors cut",

    emjK02709: "envelope mail",

    emjK0270f: "pencil",

    emjK1f3e0: "house home",

    emjK1f3e1: "garden greenhouse",

    emjK1f3e2: "office work",

    emjK1f3e6: "bank",

    emjK1f3e7: "atm",

    emjK1f3e8: "hotel motel bed sleep",

    emjK1f3ea: "convenience store",

    emjK1f3ec: "department store shopping",

    emjK1f3ed: "factory",

    emjK1f3f0: "castle",

    emjK02668: "hot springs",

    emjK02693: "anchor",

    emjK026ea: "church temple",

    emjK026f2: "fountain",

    emjK026f3: "golf",

    emjK026f5: "sailboat sailing sail",

    emjK026fd: "gas fuel gasoline pump",

    emjK1f6aa: "door",

    emjK1f6ac: "smoke smoking",

    emjK1f6ad: "no smoking",

    emjK1f6b2: "bike bicycle biking",

    emjK1f6b6: "walking pedestrian",

    emjK1f6c0: "bath bathing bathtub tub",

    emjK0267f: "accessible accessibility wheelchair",

    emjK026a0: "warning attention caution hazzard",

    emjK026a1: "high voltage zap lightning",

    emjK1f680: "rocket spaceship space",

    emjK1f68f: "bus stop",

    emjK1f693: "cop police",

    emjK1f695: "taxi cab",

    emjK1f697: "car vehicle automobile",

    emjK1f6a2: "ship cruise ferry yacht cruise travel vacation",

    emjK02708: "airplane flight airline plane travel vacation",

    emjK1f42d: "mouse eek mice squeak",

    emjK1f42e: "cow moo",

    emjK1f42f: "tiger roar rawr",

    emjK1f430: "rabbit bunny easter",

    emjK1f431: "cat kitty kitten meow",

    emjK1f432: "dragon roar smaug",

    emjK1f433: "whale moby dick",

    emjK1f434: "horse",

    emjK1f436: "dog woof puppy bark doggy",

    emjK1f437: "pig oink piggy piglet",

    emjK1f438: "frog croak ribbit",

    emjK1f439: "hamster guinea pig",

    emjK1f43a: "wolf howl",

    emjK1f341: "maple leaf",

    emjK1f344: "mushroom shroom",

    emjK1f308: "rainbow love",

    emjK02601: "cloud gloomy cloudy",

    emjK02614: "drops raining rain rainy raindrops umbrella",

    emjK02728: "sparkles twinkle twinkling glitter shiny sparkly glittery",

    emjK1f4a9: "poop turd shit",

    emjK1f4aa: "strong work out muscles biceps",

    emjK02648: "aries zodiac",

    emjK02649: "taurus zodiac",

    emjK0264a: "gemini zodiac",

    emjK0264b: "cancer zodiac",

    emjK0264c: "leo zodiac",

    emjK0264d: "virgo zodiac",

    emjK0264e: "libra zodiac",

    emjK0264f: "scorpio zodiac",

    emjK02650: "sagittarius zodiac",

    emjK02651: "capricorn zodiac",

    emjK02652: "aquarius zodiac",

    emjK02653: "pisces zodiac",

    emjK1f411: "bah sheep",

    emjK1f300: "cyclone typhoon hurricane",

    emjK1f301: "foggy",

    emjK1f302: "umbrella",

    emjK1f304: "sunrise",

    emjK1f305: "sunrise",

    emjK1f306: "dusk",

    emjK1f309: "bridge night",

    emjK1f30a: "wave tsunami",

    emjK1f30c: "milky way night sky galaxy universe",

    emjK1f311: "moon",

    emjK1f313: "moon",

    emjK1f314: "moon",

    emjK1f315: "moon full",

    emjK1f319: "moon crescent",

    emjK1f31b: "moon",

    emjK1f330: "chestnut",

    emjK1f331: "seed seedling planting",

    emjK1f334: "palm tree vacation",

    emjK1f335: "cactus hot desert",

    emjK1f337: "tulip flower",

    emjK1f338: "cherry blossom sakura flower",

    emjK1f339: "rose love romance flower",

    emjK1f33a: "hibiscus flower",

    emjK1f33b: "sunflower",

    emjK1f33c: "blossom daisy flower",

    emjK1f33d: "maize corn",

    emjK1f33e: "rice",

    emjK1f33f: "herb herbs",

    emjK1f342: "leaf autumn",

    emjK1f343: "leaf windy",

    emjK1f358: "onigiri",

    emjK1f359: "onigiri",

    emjK1f35b: "curry katsu",

    emjK1f361: "dango",

    emjK1f362: "oden",

    emjK1f373: "frying cooking",

    emjK1f3bd: "exercise exercising",

    emjK1f417: "boar",

    emjK1f418: "elephant",

    emjK1f419: "octopus",

    emjK1f41a: "seashell shell",

    emjK1f41b: "bug insect centipede millipede",

    emjK1f41c: "ant ants insect bug",

    emjK1f41d: "bee bees honeybee honeybees",

    emjK1f41e: "ladybug ladybugs",

    emjK1f420: "fish",

    emjK1f421: "blowfish fish",

    emjK1f422: "turtle",

    emjK1f423: "chick easter",

    emjK1f425: "chick easter",

    emjK1f40c: "snail slow",

    emjK1f40d: "snake",

    emjK1f40e: "horse horsey pony",

    emjK1f412: "monkey",

    emjK1f414: "chicken bawk rooster",

    emjK1f429: "poodle dog",

    emjK1f42b: "camel desert",

    emjK1f426: "bird",

    emjK1f427: "penguin",

    emjK1f42c: "dolphin",

    emjK1f43d: "pig pig noise smelly",

    emjK1f43e: "paw prints paws",

    emjK1f452: "hat sunday best",

    emjK1f458: "kimono",

    emjK1f45a: "clothes",

    emjK1f481: "information desk support desk",

    emjK1f482: "guardsman",

    emjK1f499: "heart",

    emjK1f49a: "heart",

    emjK1f49b: "heart",

    emjK1f49c: "heart",

    emjK1f49d: "heart love",

    emjK1f49e: "heart",

    emjK1f49f: "heart",

    emjK1f4a5: "collision bang traffic accident crash",

    emjK1f4a3: "bomb",

    emjK1f4a1: "light bulb idea",

    emjK1f4a8: "dash gotta go gotta run",

    emjK1f503: "reload refresh loading",

    emjK1f50c: "plug plugged in",

    emjK1f50b: "battery charged",

    emjK1f50d: "search searching",

    emjK1f510: "secure secret",

    emjK1f50f: "privacy",

    emjK1f511: "key",

    emjK1f512: "lock locked",

    emjK1f513: "unlock unlocked",

    emjK1f516: "bookmark",

    emjK1f514: "bell alarm",

    emjK1f550: "1pm 1am early afternoon",

    emjK1f551: "2pm 2am afternoon",

    emjK1f552: "3pm 3am",

    emjK1f553: "4pm 4am",

    emjK1f554: "5pm 5am late afternoon",

    emjK1f555: "6pm 6am",

    emjK1f556: "7pm 7am dinnertime",

    emjK1f557: "8pm 8am morning",

    emjK1f558: "9pm 9am",

    emjK1f559: "10pm 10am bedtime",

    emjK1f55a: "11pm 11am nighttime",

    emjK1f55b: "12pm 12am midnight noon lunchtime",

    emjK1f5fd: "statue liberty nyc new york city",

    emjK1f638: "cat grin happy",

    emjK1f639: "cat rofl lol tears laughter tears joy funny",

    emjK1f63a: "cat smile",

    emjK1f63b: "cat love",

    emjK1f63c: "cat heh",

    emjK1f63d: "cat kiss kisses",

    emjK1f63e: "cat pout pouting sad",

    emjK1f63f: "cat cry cries sad",

    emjK1f4e8: "incoming message mail email letter",

    emjK1f4e9: "mail email letter sending send",

    emjK1f4eb: "mail",

    emjK1f4ea: "empty mailbox",

    emjK1f6bb: "restroom toilet washroom bathroom",

    emjK1f6a5: "traffic traffic light",

    emjK1f6ab: "no entry no admittance",

    emjK1f689: "station",

    emjK1f69a: "delivery truck",

    emjK026fa: "tent camp camping",

    emjK1f6a8: "police siren emergency",

    emjK1f17f: "parking parking spot",

    emjK02733: "star asterisk",

    emjK02734: "star",

    emjK02b50: "star",

    emjK1f4c8: "chart graph record profits trending up upwards skyrocketed",

    emjK1f4c9: "chart graph record losses trending down downwards",

    emjK1f4ca: "chart graph",

    emjK02764: "love heart"
};
