import React, { FormEvent } from "react";
import { connect } from "react-redux";
import { Checkbox, DefaultButton, Dropdown, IDropdownOption, Label, Panel, PanelType, PrimaryButton, Stack, ChoiceGroup, IChoiceGroupOption, SpinnerSize } from "@fluentui/react";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../Layout";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { PageActions } from "../../../Page/redux/PageActions";
import { IAppointmentType, IStoreDto } from "../../../../../contracts/swagger/_generated";
import { PageFeature, AssignTo } from "../../../../../common/Constants";
import { IPanels, ISelectedAppointmentType, IViewOptions, UserRole } from "../../../Page/Contracts";
import { ReservationsActions } from "../../../../../views/ReservationsView/redux/ReservationsActions";

import * as PageSelectors from "../../../Page/redux/PageSelectors";
import * as ReservationsSelectors from "../../../../../views/ReservationsView/redux/ReservationsSelectors";

import "./FilterPanel.scss";
import { ContactsActions } from "../../../../../views/ContactsView/redux/ContactsActions";
import { FeatureManagementActions } from "../../../../../views/FeatureManagement/redux/FeatureManagementActions";
import { PermissionGate } from "../../../../../common/components/PermissionGate/PermissionGate";
import { LoadingContainer } from "../../../../../common/components/LoadingContainer/LoadingContainer";

const clonedeep = require("lodash.clonedeep");

interface IFilterPaneProvidedProps extends IRapComponentProperties {
}

interface IFilterPaneOwnProps extends IFilterPaneProvidedProps {
    appointmentTypes: IAppointmentType[];
    isAppointmentTypesLoading: boolean;
    panels: IPanels;
    viewOptions: IViewOptions;
    stores: IStoreDto[];
}

interface IFilterPaneState {
    appointmentTypeState: ISelectedAppointmentType[];
    assignToKey: string;
    storeNumber: string;
    isApplyDisabled: boolean;
}

export type IFilterPaneProps = IFilterPaneOwnProps & typeof ActionsToDispatch;

export class FilterPanelInitializer extends RapComponent<IFilterPaneProps, IFilterPaneState> {
    constructor(props: IFilterPaneProps, context: IRapComponentContext) {
        super(props, context);
        this.state = {
            appointmentTypeState: !this.props.viewOptions?.selectedAppointmentTypes 
                ? this._getDefaultAppointmentTypes() : this.props.viewOptions?.selectedAppointmentTypes,
            assignToKey: this.props.viewOptions?.selectedAssignStatus,
            storeNumber: this.props.viewOptions?.selectedStore,
            isApplyDisabled: this._isApplyDisabled(),
        };
    }

    public componentDidUpdate(prevProps: IFilterPaneProps) {
        if (JSON.stringify(this.props.appointmentTypes) != JSON.stringify(prevProps.appointmentTypes)) {
            this.setState({ appointmentTypeState: this._getDefaultAppointmentTypes() });
        }
        if (this.props.viewOptions?.selectedStore !== prevProps.viewOptions?.selectedStore) {
            this.setState({ storeNumber: this.props.viewOptions?.selectedStore });
            this.setState({ appointmentTypeState: this._getDefaultAppointmentTypes() });
        }
        if (this.props.viewOptions?.selectedAssignStatus !== prevProps.viewOptions?.selectedAssignStatus) {
            this.setState({ assignToKey: this.props.viewOptions?.selectedAssignStatus });
        }
    }

    public render() {
        const options: IChoiceGroupOption[] = [
            { key: AssignTo.AssignedToMe, text: localizedStrings.FilterPane.assignedToMe },
            { key: AssignTo.Unassigned, text: localizedStrings.FilterPane.unAssigned },
            { key: AssignTo.All, text: localizedStrings.FilterPane.all },
        ];

        if (this.state) {
            return (
                <>
                    <Panel
                        isOpen={this.props.panels?.displayFilterPanel}
                        headerText={localizedStrings.FilterPane.header}
                        type={PanelType.smallFixedFar}
                        closeButtonAriaLabel="Close"
                        onDismiss={this._onCloseFilterPanel}
                        onRenderFooterContent={this._onRenderFooterContent}
                        isFooterAtBottom={true}
                    >
                        <PermissionGate minimumUserRole={UserRole.Admin} disableInteadOfHide>
                            <Dropdown
                                className="c-store-dropdown"
                                label={localizedStrings.FilterPane.store}
                                defaultSelectedKey={this.state.storeNumber}
                                options={this._getStoresList()}
                                onChange={this._onSelectedStoreChanged}
                            />
                        </PermissionGate>
                        <Label className="c-type-label" required>
                            {localizedStrings.FilterPane.type}
                        </Label>
                        <LoadingContainer isLoading={this.props.isAppointmentTypesLoading} spinnerSize={SpinnerSize.small}>
                            {this.state.appointmentTypeState?.map((type, index) => (
                                <div className="flex-row c-type-checkbox" key={index}>
                                    <Checkbox
                                        ariaLabel={type.ariaLabel}
                                        label={type.label}
                                        onChange={() => this._onAppointmentTypesChange(type)}
                                        defaultChecked={type.isChecked}
                                    />
                                </div>
                            ))}
                        </LoadingContainer>
                        <ChoiceGroup className="c-choicegroup" defaultSelectedKey={this.state.assignToKey}
                            options={options} label={localizedStrings.FilterPane.assignTo}
                            onChange={this._onAssignChoiceChange} required={true} />
                    </Panel>
                </>
            );
        } else {
            return (<></>);
        }
    };

    private _onRenderFooterContent = () => {
        return (
            <Stack horizontal className="c-footer-container">
                <Stack.Item>
                    <DefaultButton onClick={this._onCloseFilterPanel} className="c-button-cancel">
                        {localizedStrings.FilterPane.cancel}
                    </DefaultButton>
                </Stack.Item>
                <Stack.Item>
                    <PrimaryButton onClick={this._onClickApply} disabled={this._isApplyDisabled()}>
                        {localizedStrings.FilterPane.apply}
                    </PrimaryButton>
                </Stack.Item>
            </Stack>
        );
    };

    private _onSelectedStoreChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option && option.key) {
            this.setState({
                storeNumber: option.key.toString(),
            });
            this.props.fetchAppointmentTypes(option.key.toString());
        }
    };

    private _onAppointmentTypesChange = (type: ISelectedAppointmentType, event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        if (this.state.appointmentTypeState) {
            const types: ISelectedAppointmentType[] = clonedeep(this.state.appointmentTypeState);
            types.forEach((selectedAppointment: ISelectedAppointmentType) => {
                if (selectedAppointment.id === type.id) {
                    selectedAppointment.isChecked = checked !== undefined ? checked : !type.isChecked;
                }
            });
            this.setState({
                appointmentTypeState: types,
            });
        }
    };

    private _onAssignChoiceChange = (ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option && option.key) {
            this.setState({
                assignToKey: option.key,
            });
        }
    };

    private _onClickApply = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "Apply filter panel clicked", { 'selectedStore': this.state.storeNumber });
        // refetch store associates if store number changes
        if(this.state.storeNumber != this.props.viewOptions.selectedStore) {
            this.props.fetchAssociateData(parseInt(this.state.storeNumber));
            this.props.fetchFeatureFlags(this.state.storeNumber);
        }

        this.props.updateViewOptions({
            selectedStore: this.state.storeNumber, 
            selectedAppointmentTypes: this.state.appointmentTypeState,
            selectedAssignStatus: this.state.assignToKey
        });
        this.props.updatePanels({
            displayFilterPanel: !this.props.panels
        });
    };

    private _onCloseFilterPanel = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "Close filter panel clicked");
        this.setState({
            appointmentTypeState: !this.props.viewOptions?.selectedAppointmentTypes
                ? this._getDefaultAppointmentTypes() : this.props.viewOptions?.selectedAppointmentTypes,
            assignToKey: this.props.viewOptions?.selectedAssignStatus,
            storeNumber: this.props.viewOptions?.selectedStore,
            isApplyDisabled: this._isApplyDisabled(),
        });
        this.props.updatePanels({ displayFilterPanel: !this.props.panels.displayFilterPanel });
    };

    private _getStoresList(): IDropdownOption[] {
        var sortedOptions: IDropdownOption[] = [];
        if (this.props.stores) {
            var options: IDropdownOption[] = [];
            this.props.stores.map((store: IStoreDto) => {
                if (store && store.storeNumber && store.name && store.isVisibleInConcierge === true) {
                    options.push({
                        key: store.storeNumber,
                        text: store.name,
                        isSelected: (this.props.viewOptions.selectedStore === store.storeNumber)
                    });
                }
            });

            sortedOptions = options.slice(0).sort((a, b) => {
                return (a.text > b.text ? 1 : -1);
            });
        }
        return sortedOptions;
    };

    private _isApplyDisabled(): boolean {
        let allUnchecked = true;
        let allChecked = true;
        this.state?.appointmentTypeState?.map((type: ISelectedAppointmentType) => {
            if (type.isChecked === true) {
                allUnchecked = false;
            } else {
                allChecked = false;
            }
        });


        let isAppointmentTypesModified = true;
        if (!this.props.viewOptions?.selectedAppointmentTypes) {
            // edge case to handle no 'SelectedAppointmentTypes' in local storage
            if (allChecked) {
                isAppointmentTypesModified = false;
            }
        } else if (JSON.stringify(this.props.viewOptions?.selectedAppointmentTypes) === JSON.stringify(this.state?.appointmentTypeState)) {
            isAppointmentTypesModified = false;
        }
        isAppointmentTypesModified = (isAppointmentTypesModified && !allUnchecked); //required - must select at least one appointment type

        if (this.props.viewOptions?.selectedStore === this.state?.storeNumber
            && this.props.viewOptions.selectedAssignStatus === this.state?.assignToKey
            && !isAppointmentTypesModified
            || this.props.isAppointmentTypesLoading === true) {
            return true;
        }
       
        return false;
    };

    private _getDefaultAppointmentTypes(): ISelectedAppointmentType[] {
        let types: ISelectedAppointmentType[] = [];
        if (this.props.appointmentTypes) {
            this.props.appointmentTypes.map((type: IAppointmentType) => {
                types.push({
                    ariaLabel: type.name,
                    label: type.name,
                    id: type.appointmentTypeId,
                    isChecked: true
                });
            });
        }
        return types;
    }
};

function mapStateToProps(state: IClientelingViewState, providedProps: IFilterPaneProvidedProps): Partial<IFilterPaneOwnProps> {
    return {
        ...providedProps,
        appointmentTypes: ReservationsSelectors.getResources(state)?.appointmentTypes,
        isAppointmentTypesLoading: ReservationsSelectors.getIsAppointmentTypesLoading(state),
        panels: PageSelectors.getPanels(state),
        viewOptions: PageSelectors.getViewOptions(state),
        stores: PageSelectors.getStores(state),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAppointmentTypes: ReservationsActions.fetchAppointmentTypes,
    logTelemetry: PageActions.logTelemetry,
    updatePanels: PageActions.updatePanels,
    updateViewOptions: PageActions.updateViewOptions,
    fetchAssociateData: ContactsActions.fetchAssociateData,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags
};

export const FilterPanel = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(FilterPanelInitializer);
