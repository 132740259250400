import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, PersonaSize, SpinnerSize } from "@fluentui/react";
import { useParams } from "react-router-dom";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { Card } from "../../../../../../../../common/components/Card/Card";
import { IActivityDto, IAppointmentDto, IContactDto, IPurchaseHistoryDto } from "../../../../../../../../contracts/swagger/_generated";
import { UserPersona } from "../../../../../../../../common/components/UserPersona/UserPersona";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { PermissionGate } from "../../../../../../../../common/components/PermissionGate/PermissionGate";
import { UserRole } from "../../../../../../../../platform/components/Page/Contracts";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { PillHandler } from "../PillHandler/PillHandler";
import { ICustomerInsightsData } from "../../../../../../Contracts";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";
import * as PageSelectors from "../../../../../../../../platform/components/Page/redux/PageSelectors";

import "./ContactDetails.scss";
import "../../../ContactCard.scss";
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IContactDetailsProvidedProps extends IRapComponentProperties {
    className?: string;
}

interface IContactDetailsOwnProps {
    selectedContact?: IContactDto;
    customerInsightsData?: ICustomerInsightsData;
    customerPurchaseData?: IPurchaseHistoryDto[];
    appointmentList?: IAppointmentDto[];
    userRole?: UserRole;
}

export type IContactDetailsProps = ConnectedProps<typeof connector> & IContactDetailsProvidedProps;

const ContactDetailsInitializer: FC<IContactDetailsProps> = (props) => {
    let { contactId } = useParams<any>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.userRole == UserRole.Admin && props.selectedContact?.email && !props.customerInsightsData?.legacyCustomerInsights) {
            setIsLoading(true);
            props.fetchContactAppointmentList(props.selectedContact?.contactId);
            props.fetchContactInsights(props.selectedContact?.email).catch(e => {
                // empty catch just so the error doesnt propogate up and show an error banner
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [props.selectedContact?.contactId, props.userRole]);

    const getLastUserActivity = () => {
        let activityLength = props.customerInsightsData?.legacyCustomerInsights?.activity?.length;

        let lastActivity: IActivityDto = props.customerInsightsData?.legacyCustomerInsights?.activity[activityLength-1];

        let appointmentDate = [];
        if (props.appointmentList) {
            let newestAppointment = getNewestOrOldestDate(props.appointmentList, true);
            if (newestAppointment)
                appointmentDate.push(newestAppointment.appointmentDate.getTime());
        }

        if (lastActivity && lastActivity.activityTime) {
            appointmentDate.push(lastActivity?.activityTime.getTime());
        }

        if (props.customerPurchaseData && props.customerPurchaseData.length !== 0) {
            appointmentDate.push(Math.max(...props.customerPurchaseData.map(x => (x.purchaseDate/1000))));
        }

        if (appointmentDate.length == 0) {
            return localizedStrings.ContactsView?.contactDetails.noValue;
        }
        
        return new Date(Math.max(...appointmentDate)).toLocaleDateString();
    }

    const getCustomerSince = () => {
        let appointmentDate = [];

        if (props.appointmentList) {
            let firstAppointment = getNewestOrOldestDate(props.appointmentList, false);
            if (firstAppointment) {
                appointmentDate.push(firstAppointment.appointmentDate.getTime());
            }
        }

        if (props.customerInsightsData?.legacyCustomerInsights?.customerSince || props.customerInsightsData?.legacyCustomerInsights?.customerSince != undefined) {
            appointmentDate.push(props.customerInsightsData?.legacyCustomerInsights?.customerSince.getTime());
        }

        if (props.customerPurchaseData && props.customerPurchaseData.length !== 0) {
            appointmentDate.push(Math.min(...props.customerPurchaseData.map(x => (x.purchaseDate/1000))));
        }

        if (appointmentDate.length == 0) {
            return localizedStrings.ContactsView?.contactDetails.noValue;
        }

        return new Date(Math.min(...appointmentDate)).toLocaleDateString();
    }

    //Finds the earliest appointment date and most recent appointment date (not in the future but including today)
    const getNewestOrOldestDate = (appointmentList: IAppointmentDto[], returnNewest: boolean) => {
        let appointmentListWithoutFuture: IAppointmentDto[] = [];
        let tomorrowDate = new Date();
        tomorrowDate.setHours(24,0,0,0);

        appointmentList.forEach(function (item) {
            if (item.appointmentDate && item.appointmentDate < tomorrowDate)
            {
                appointmentListWithoutFuture.push(item);
            }
        })

        if (appointmentListWithoutFuture.length === 0)
            return null;

        if (returnNewest) {
            return appointmentListWithoutFuture?.reduce((a, b) => b.appointmentDate > a.appointmentDate ? b : a);
        } else {
            return appointmentListWithoutFuture?.reduce((a, b) => b.appointmentDate < a.appointmentDate ? b : a);
        }
    }

    return (
        <Card className={`${props.className} c-contactDetails c-card`}>
            <div className="c-persona-container">
                <UserPersona 
                    imageUrl={props.selectedContact?.profileImage}
                    imageInitials={`${props.selectedContact?.firstName?.charAt(0)}${props.selectedContact?.lastName?.charAt(0)}`}
                    fullName={`${props.selectedContact?.firstName} ${props.selectedContact?.lastName}`}
                    secondaryText={props.selectedContact?.email}
                    tertiaryText={props.selectedContact?.phone}
                    personaSize={PersonaSize.size100}
                    renderDetails
                />
            </div>
            <PillHandler />
            <PermissionGate minimumUserRole={UserRole.Admin}>
                <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                    <div className="flex-row c-info-container c-card-body">
                        <div className="c-labels">
                            <Text>{localizedStrings.ContactsView?.contactDetails.clientSince}</Text>
                            <Text>{localizedStrings.ContactsView?.contactDetails.lastInteraction}</Text>
                        </div>
                        <div className="flex c-values">
                            <Text>{getCustomerSince()}</Text>
                            <Text>{getLastUserActivity()}</Text>
                        </div>
                    </div>
                </LoadingContainer>
            </PermissionGate>
        </Card>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactDetailsProvidedProps): Partial<IContactDetailsOwnProps> {
    return {
        ...providedProps,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact,
        customerInsightsData: ContactsSelectors.getCustomerInsightsData(state),
        customerPurchaseData: ContactsSelectors.getCustomerInsightsData(state)?.purchaseHistory,
        appointmentList: ContactsSelectors.getSelectedContactInfo(state)?.contactAppointments,
        userRole: PageSelectors.getUserData(state)?.userRole
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactInsights: ContactsActions.fetchContactInsights,
    fetchContactAppointmentList: ContactsActions.fetchContactAppointments
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const ContactDetails = connector(ContactDetailsInitializer);