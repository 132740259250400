import * as React from "react";
import { ScreenContext, ScreenSize } from "./../../util/Screen";
import { Observer } from "./../Observer/Observer";

export interface IScreenSizeConditionalProps {
    condition: (screenSize: ScreenSize) => boolean;
}

export const ScreenSizeObserver = (props: any) => {
    return <ScreenContext.Consumer>{screen => <Observer screenSize={screen.size}>{props.children}</Observer>}</ScreenContext.Consumer>;
};

/**
 * Conditionally renders children based on screen size.
 */
export const ScreenSizeConditional: React.FunctionComponent<IScreenSizeConditionalProps> = props => {
    return (
        <ScreenSizeObserver>
            {(screenSizeProps: { screenSize: ScreenSize }) => props.condition(screenSizeProps.screenSize) && props.children}
        </ScreenSizeObserver>
    );
};
