import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import { ConnectedAppointmentCardItem } from "../AppointmentCardItem/AppointmentCardItem";
import { IAppointmentModel } from "../../../../contracts/swagger/_generated";
import { LoadingSpinner } from "../../../../common/components/LoadingSpinner/LoadingSpinner";
import { NoResults } from "../../../../common/components/NoResults/NoResults";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { ReservationsActions } from "../../redux/ReservationsActions";
import { ErrorBoundaryFallback } from "../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

import "./AppointmentGrid.scss";


//Props passed by parent component
interface IAppointmentGridProvidedProps extends IRapComponentProperties {
    appointments: IAppointmentModel[];
    isLoading: boolean;
    noResultsMessage: string; 
}

//Props mapped from state object
interface IAppointmentGridOwnProps {
   
}

// eslint-disable-next-line
export type IAppointmentGridProps = ConnectedProps<typeof connector> & IAppointmentGridProvidedProps;

export const AppointmentGridInitializer: FC<IAppointmentGridProps> = (props) => {
    if (props.isLoading) {
        return <LoadingSpinner />
    }

    if(props.appointments?.length == 0) {
        return (<NoResults description={props.noResultsMessage} />);
    }

    return (
        <div className="c-flex-container">
            {props.appointments?.map((appointment) => (
                <div
                    key={appointment.appointmentDetails?.appointmentId}
                    className="c-card-item"
                    data-is-focusable={true}>
                    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <ConnectedAppointmentCardItem
                            appointment={appointment}
                            displayImageCol={true}
                        />
                    </ErrorBoundary>
                </div>
            ))}
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentGridProvidedProps): Partial<IAppointmentGridOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchEmployeeInfo: ReservationsActions.fetchEmployeeInfo
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const AppointmentGrid = connector(AppointmentGridInitializer);


