import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "./../../../platform/redux/ActionHelper";
import {
    IRetailStoreDto,
    IRetailStoreForUpdateDto,
    IRunnerRequestDto,
    IRunnerRequestForCancelDto,
    IRunnerRequestForCompleteDto,
    IRunnerRequestForConfirmDto,
    IRunnerRequestForCreationDto
} from "../../../contracts/swagger/_generated";
import { RunnerRequestState, RunnerRequestView } from "../../../common/Constants";

export enum RunnerRequestsActionTypes {
    InitRunnerRequestsView = "RunnerRequests/InitRunnerRequestsView",

    SetErrorMessage = "RunnerRequests/SetErrorMessage",

    FetchRetailStores = "RunnerRequests/FetchRetailStores",
    FetchRetailStoresSuccess = "RunnerRequests/FetchRetailStoresSuccess",
    FetchRetailStoresFailure = "RunnerRequests/FetchRetailStoresFailure",
    
    CreateRunnerRequest = "RunnerRequests/CreateRunnerRequest",
    CreateRunnerRequestSuccess = "RunnerRequests/CreateRunnerRequestSuccess",
    CreateRunnerRequestFailure = "RunnerRequests/CreateRunnerRequestFailure",

    FetchRunnerRequests = "RunnerRequests/FetchRunnerRequests",
    FetchRunnerRequestsSuccess = "RunnerRequests/FetchRunnerRequestsSuccess",
    FetchRunnerRequestsFailure = "RunnerRequests/FetchRunnerRequestsFailure",

    CancelRunnerRequest = "RunnerRequests/CancelRunnerRequest",
    CancelRunnerRequestSuccess = "RunnerRequests/CancelRunnerRequestSuccess",
    CancelRunnerRequestFailure = "RunnerRequests/CancelRunnerRequestFailure",

    ConfirmRunnerRequest = "RunnerRequests/ConfirmRunnerRequest",
    ConfirmRunnerRequestSuccess = "RunnerRequests/ConfirmRunnerRequestSuccess",
    ConfirmRunnerRequestFailure = "RunnerRequests/ConfirmRunnerRequestFailure",

    CompleteRunnerRequest = "RunnerRequests/CompleteRunnerRequest",
    CompleteRunnerRequestSuccess = "RunnerRequests/CompleteRunnerRequestSuccess",
    CompleteRunnerRequestFailure = "RunnerRequests/CompleteRunnerRequestFailure",

    UpdateStoreStatus = "RunnerRequests/UpdateStoreStatus",
    UpdateStoreStatusSuccess = "RunnerRequests/UpdateStoreStatusSuccess",
    UpdateStoreStatusFailure = "RunnerRequests/UpdateStoreStatusFailure",

    FetchStoreByStoreNumber = "RunnerRequests/FetchStoreByStoreNumber",
    FetchStoreByStoreNumberSuccess = "RunnerRequests/FetchStoreByStoreNumberSuccess",
    FetchStoreByStoreNumberFailure = "RunnerRequests/FetchStoreByStoreNumberFailure",

    SetSelectedRunnerRequest = "RunnerRequests/SetSelectedRunnerRequest",
    ResetSelectedRunnerRequest = "RunnerRequests/ResetSelectedRunnerRequest",

    SetSelectedRetailStore = "RunnerRequests/SetSelectedRetailStore",

    SetSelectedFilterState = "RunnerRequests/SetSelectedFilterState",

    SetSelectedView = "RunnerRequests/SetSelectedView",

    UpdateSearchTerm = "RunnerRequests/UpdateSearchTerm"
}

const RunnerRequestsStandardActions = {
    initRunnerRequestsView: () => createAction(RunnerRequestsActionTypes.InitRunnerRequestsView),
    
    setErrorMessage: (errorMessage: string) => createAction(RunnerRequestsActionTypes.SetErrorMessage, errorMessage),

    fetchRetailStores: () => createAction(RunnerRequestsActionTypes.FetchRetailStores),
    fetchRetailStoresSuccess: (retailStores: IRetailStoreDto[]) => createAction(RunnerRequestsActionTypes.FetchRetailStoresSuccess, retailStores),
    fetchRetailStoresFailure: (error: string) => createAction(RunnerRequestsActionTypes.FetchRetailStoresFailure, error),

    createRunnerRequestSuccess: (createdRunnerRequest: IRunnerRequestDto) =>
        createAction(RunnerRequestsActionTypes.CreateRunnerRequestSuccess, createdRunnerRequest),
    createRunnerRequestFailure: (error: string) => 
        createAction(RunnerRequestsActionTypes.CreateRunnerRequestFailure, error),

    fetchRunnerRequestsSuccess: (runnerRequests: IRunnerRequestDto[]) => 
        createAction(RunnerRequestsActionTypes.FetchRunnerRequestsSuccess, runnerRequests),
    fetchRunnerRequestsFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.FetchRunnerRequestsFailure, error),

    cancelRunnerRequestSuccess: (canceledRunnerRequest: IRunnerRequestDto) =>
        createAction(RunnerRequestsActionTypes.CancelRunnerRequestSuccess, canceledRunnerRequest),
    cancelRunnerRequestFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.CancelRunnerRequestFailure, error),

    confirmRunnerRequestSuccess: (confirmedRunnerRequest: IRunnerRequestDto) => 
        createAction(RunnerRequestsActionTypes.ConfirmRunnerRequestSuccess, confirmedRunnerRequest),
    confirmRunnerRequestFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.ConfirmRunnerRequestFailure, error),

    completeRunnerRequestSuccess: (completedRunnerRequest: IRunnerRequestDto) =>
        createAction(RunnerRequestsActionTypes.CompleteRunnerRequestSuccess, completedRunnerRequest),
    completeRunnerRequestFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.CompleteRunnerRequestFailure, error),

    updateStoreStatusSuccess: (updatedRetailStore: IRetailStoreDto) =>
        createAction(RunnerRequestsActionTypes.UpdateStoreStatusSuccess, updatedRetailStore),
    updateStoreStatusFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.UpdateStoreStatusFailure, error),

    fetchStoreByStoreNumberSuccess: (retailStore: IRetailStoreDto) =>
        createAction(RunnerRequestsActionTypes.FetchStoreByStoreNumberSuccess, retailStore),
    fetchStoreByStoreNumberFailure: (error: string) =>
        createAction(RunnerRequestsActionTypes.FetchStoreByStoreNumberFailure, error),

    setSelectedRunnerRequest: (runnerRequest: IRunnerRequestDto) => createAction(RunnerRequestsActionTypes.SetSelectedRunnerRequest, runnerRequest),
    resetSelectedRunnerRequest: () => createAction(RunnerRequestsActionTypes.ResetSelectedRunnerRequest),

    setSelectedRetailStore: (retailStore: IRetailStoreDto) => createAction(RunnerRequestsActionTypes.SetSelectedRetailStore, retailStore),

    setSelectedFilterState: (state: RunnerRequestState) => createAction(RunnerRequestsActionTypes.SetSelectedFilterState, state),

    setSelectedView: (view: RunnerRequestView) => createAction(RunnerRequestsActionTypes.SetSelectedView, view), 

    updateSearchTerm: (searchTerm: string) => createAction(RunnerRequestsActionTypes.UpdateSearchTerm, searchTerm)
};

const RunnerRequestsThunkActions = {
    createRunnerRequest: (runnerRequestForCreation: IRunnerRequestForCreationDto) => 
        createActionWithCallback<IRunnerRequestDto>(RunnerRequestsActionTypes.CreateRunnerRequest, runnerRequestForCreation),
    fetchRunnerRequests: (storeNumber: string) => 
        createActionWithCallback<IRunnerRequestDto[]>(RunnerRequestsActionTypes.FetchRunnerRequests, storeNumber),
    cancelRunnerRequest: (runnerRequestForCancel: IRunnerRequestForCancelDto) =>
        createActionWithCallback<IRunnerRequestDto>(RunnerRequestsActionTypes.CancelRunnerRequest, runnerRequestForCancel),
    confirmRunnerRequest: (runnerRequestForConfirm: IRunnerRequestForConfirmDto) =>
        createActionWithCallback<IRunnerRequestDto>(RunnerRequestsActionTypes.ConfirmRunnerRequest, runnerRequestForConfirm),
    completeRunnerRequest: (runnerRequestForComplete: IRunnerRequestForCompleteDto) =>
        createActionWithCallback<IRunnerRequestDto>(RunnerRequestsActionTypes.CompleteRunnerRequest, runnerRequestForComplete),
    updateStoreStatus: (storeForUpdate: IRetailStoreForUpdateDto) =>
        createActionWithCallback<IRetailStoreDto>(RunnerRequestsActionTypes.UpdateStoreStatus, storeForUpdate),
    fetchStoreByStoreNumber: (storeNumber: string) =>
        createActionWithCallback<IRetailStoreDto>(RunnerRequestsActionTypes.FetchStoreByStoreNumber, storeNumber)
}

const RunnerRequestsThunkActionsTypes = {
    createRunnerRequest: (runnerRequestForCreation: IRunnerRequestForCreationDto) => 
        createThunkActionType(RunnerRequestsActionTypes.CreateRunnerRequest, runnerRequestForCreation),
    fetchRunnerRequests: (storeNumber: string) => 
        createThunkActionType(RunnerRequestsActionTypes.FetchRunnerRequests, storeNumber),
    cancelRunnerRequest: (runnerRequestForCancel: IRunnerRequestForCancelDto) =>
        createThunkActionType(RunnerRequestsActionTypes.CancelRunnerRequest, runnerRequestForCancel),
    confirmRunnerRequest: (runnerRequestForConfirm: IRunnerRequestForConfirmDto) =>
        createThunkActionType(RunnerRequestsActionTypes.ConfirmRunnerRequest, runnerRequestForConfirm),
    completeRunnerRequest: (runnerRequestForComplete: IRunnerRequestForCompleteDto) =>
        createThunkActionType(RunnerRequestsActionTypes.CompleteRunnerRequest, runnerRequestForComplete),
    updateStoreStatus: (retailStoreForUpdate: IRetailStoreForUpdateDto) =>
        createThunkActionType(RunnerRequestsActionTypes.UpdateStoreStatus, retailStoreForUpdate),
    fetchStoreByStoreNumber: (storeNumber: string) =>
        createThunkActionType(RunnerRequestsActionTypes.FetchStoreByStoreNumber, storeNumber)
}

export const RunnerRequestsActions = {...RunnerRequestsStandardActions, ...RunnerRequestsThunkActions};
const AllActionsTypes = {...RunnerRequestsStandardActions, ...RunnerRequestsThunkActionsTypes};
export type RunnerRequestsActionsType = ActionsUnion<typeof AllActionsTypes>;