import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../../platform/redux/ActionHelper";
import { AppointmentsFeature } from "../../../../common/Constants";
import {
    AppointmentCategory,
    IAppointmentDto,
    IAppointmentForUpdateDto,
    IAppointmentForCreationDto,
    ITimeSlotDto,
    AppointmentCustomQuestionDto,
    ICase,
    IAppointmentType,
    IContactDto,
    ContactDto
} from "../../../../contracts/swagger/_generated";

/**
 * Actions for Appointments view
 */
 export enum AppointmentModalActionTypes {
    InitAppointmentModalView = "AppointmentModal/InitAppointmentModalView",
    LogTelemetry = "AppointmentModal/LogTelemetry",

    CreateAppointment = "AppointmentModal/CreateAppointment",
    CreateAppointmentSuccess = "AppointmentModal/CreateAppointmentSuccess",
    CreateAppointmentFailure = "AppointmentModal/CreateAppointmentFailure",

    FetchAppointmentTypes = "AppointmentModal/FetchAppointmentTypes",
    FetchAppointmentTypesSuccess = "AppointmentModal/FetchAppointmentTypesSuccess",
    FetchAppointmentTypesFailure = "AppointmentModal/FetchAppointmentTypesFailure",

    FetchAppointmentCategories = "AppointmentModal/FetchAppointmentCategories",
    FetchAppointmentCategoriesSuccess = "AppointmentModal/FetchAppointmentCategoriesSuccess",
    FetchAppointmentCategoriesFailure = "AppointmentModal/FetchAppointmentCategoriesFailure",

    UpdateAppointment = "AppointmentModal/UpdateAppointment",
    UpdateAppointmentSuccess = "AppointmentModal/UpdateAppointmentSuccess",
    UpdateAppointmentFailure = "AppointmentModal/UpdateAppointmentFailure",

    FetchAppointmentTimeslots = "AppointmentModal/FetchAppointmentTimeslots",
    FetchAppointmentTimeslotsSuccess = "AppointmentModal/FetchAppointmentTimeslotsSuccess",
    FetchAppointmentTimeslotsFailure = "AppointmentModal/FetchAppointmentTimeslotsFailure",

    FetchAppointmentCustomResponses = "AppointmentModal/fetchAppointmentCustomResponses",
    FetchAppointmentCustomResponsesSuccess = "AppointmentModal/fetchAppointmentCustomResponsesSuccess",
    FetchAppointmentCustomResponsesFailure = "AppointmentModal/fetchAppointmentCustomResponsesFailure",
    ClearAppointmentCustomResponses = "AppointmentModal/clearAppointmentCustomResponses",

    FetchDynamicsCases = "AppointmentDetails/FetchDynamicsCases",
    FetchDynamicsCasesSuccess = "AppointmentDetails/FetchDynamicsCasesSuccess",
    FetchDynamicsCasesFailure = "AppointmentDetails/FetchDynamicsCasesFailure",

    FetchContacts = "AppointmentModal/FetchContacts",
    FetchContactsSuccess = "AppointmentModal/FetchContactsSuccess",
    FetchContactsFailure = "AppointmentModal/FetchContactsFailure",
}

const AppointmentModalStandardActions = {
    initAppointmentModalView: () => createAction(AppointmentModalActionTypes.InitAppointmentModalView),
    logTelemetry: (feature: AppointmentsFeature, action: string, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(AppointmentModalActionTypes.LogTelemetry, { feature, action, properties, errorMessage }),

    createAppointmentSuccess: (appointment: IAppointmentDto) => createAction(AppointmentModalActionTypes.CreateAppointmentSuccess, appointment),
    createAppointmentFailure: (error: string) => createAction(AppointmentModalActionTypes.CreateAppointmentFailure, error),

    fetchAppointmentTypes: (storeId: string) => createAction(AppointmentModalActionTypes.FetchAppointmentTypes, storeId),
    fetchAppointmentTypesSuccess: (typesData: IAppointmentType[]) =>
        createAction(AppointmentModalActionTypes.FetchAppointmentTypesSuccess, typesData),
    fetchAppointmentTypesFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchAppointmentTypesFailure, error),

    fetchAppointmentCategories: (storeId: string, typeId: string) =>
        createAction(AppointmentModalActionTypes.FetchAppointmentCategories, { storeId, typeId }),
    fetchAppointmentCategoriesSuccess: (categories: AppointmentCategory[]) =>
        createAction(AppointmentModalActionTypes.FetchAppointmentCategoriesSuccess, categories),
    fetchAppointmentCategoriesFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchAppointmentCategoriesFailure, error),

    updateAppointmentSuccess: () => createAction(AppointmentModalActionTypes.UpdateAppointmentSuccess),
    updateAppointmentFailure: (error: string) => createAction(AppointmentModalActionTypes.UpdateAppointmentFailure, error),

    fetchAppointmentTimeslots: (storeId: string, typeId: string) => createAction(AppointmentModalActionTypes.FetchAppointmentTimeslots, { storeId, typeId }),
    fetchAppointmentTimeslotsSuccess: (timeSlots: ITimeSlotDto[]) => createAction(AppointmentModalActionTypes.FetchAppointmentTimeslotsSuccess, timeSlots),
    fetchAppointmentTimeslotsFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchAppointmentTimeslotsFailure, error),

    fetchAppointmentCustomResponses: (storeId: string, typeId: string, categoryId: string) => createAction(AppointmentModalActionTypes.FetchAppointmentCustomResponses, { storeId, typeId, categoryId }),
    fetchAppointmentCustomResponsesSuccess: (customResponses: AppointmentCustomQuestionDto[]) => createAction(AppointmentModalActionTypes.FetchAppointmentCustomResponsesSuccess, customResponses),
    fetchAppointmentCustomResponsesFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchAppointmentCustomResponsesFailure, error),
    clearAppointmentCustomResponses: () => createAction(AppointmentModalActionTypes.ClearAppointmentCustomResponses),

    fetchDynamicsCasesSuccess: (cases: ICase[]) => createAction(AppointmentModalActionTypes.FetchDynamicsCasesSuccess, cases),
    fetchDynamicsCasesFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchDynamicsCasesFailure, error),

    fetchContactsSuccess: (contacts: IContactDto[]) => createAction(AppointmentModalActionTypes.FetchContactsSuccess, contacts),
    fetchContactsFailure: (error: string) => createAction(AppointmentModalActionTypes.FetchContactsFailure, error),
};

const AppointmentModalThunkActions = {
    createAppointment: (storeId: string, body: IAppointmentForCreationDto) =>
        createActionWithCallback<IAppointmentDto>(AppointmentModalActionTypes.CreateAppointment, { storeId, body }),
    updateAppointment: (storeId: string, appointmentId: string, appt: IAppointmentForUpdateDto, silentFetch: boolean) => 
        createActionWithCallback<void>(AppointmentModalActionTypes.UpdateAppointment, {storeId, appointmentId, appt, silentFetch}),
    fetchDynamicsCases: (contactId: string) => createActionWithCallback<ICase[]>(AppointmentModalActionTypes.FetchDynamicsCases, contactId),
    fetchContacts: (name: string | null, email: string | null, phone: string | null, select: string | null, searchExclusively?: boolean) =>
        createActionWithCallback<ContactDto[]>(AppointmentModalActionTypes.FetchContacts, { name, email, phone, select, searchExclusively }),
}

const AppointmentModalThunkActionsTypes = {
    createAppointment: (storeId: string, body: IAppointmentForCreationDto) =>
        createThunkActionType(AppointmentModalActionTypes.CreateAppointment, { storeId, body }),
    updateAppointment: (storeId: string, appointmentId: string, appt: IAppointmentForUpdateDto, silentFetch: boolean) => 
        createThunkActionType(AppointmentModalActionTypes.UpdateAppointment, {storeId, appointmentId, appt, silentFetch}),
    fetchDynamicsCases: (contactId: string) => createThunkActionType(AppointmentModalActionTypes.FetchDynamicsCases, contactId),
    fetchContacts: (name: string | null, email: string | null, phone: string | null, select: string | null, searchExclusively?: boolean) =>
        createThunkActionType(AppointmentModalActionTypes.FetchContacts, { name, email, phone, select, searchExclusively }),
}

export const AppointmentModalActions = {...AppointmentModalStandardActions, ...AppointmentModalThunkActions};
const AllActionsTypes = {...AppointmentModalStandardActions, ...AppointmentModalThunkActionsTypes};
export type AppointmentModalActionsType = ActionsUnion<typeof AllActionsTypes>;
