import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ActionButton } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";

import { IContactPages } from "../../../../../platform/components/Page/Contracts";

import { PageActions } from "../../../Page/redux/PageActions";
import * as PageSelectors from "../../../Page/redux/PageSelectors";

import { PageFeature } from "../../../../../common/Constants";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";

import { FeatureManagementActions } from "../../../../../views/FeatureManagement/redux/FeatureManagementActions";

import "./ContactPanel.scss";

interface IContactPanelProvidedProps extends IRapComponentProperties {
}

interface IContactPanelOwnProps extends IContactPanelProvidedProps {
    displayContactMenuPanel: boolean;
    contactPage: IContactPages;
}

export type IContactPanelProps = ConnectedProps<typeof connector> & IContactPanelProvidedProps;

const ContactPanelInitializer: FC<IContactPanelProps> = props => {
    return (
        <>
            <div className="flex-column c-contact-panel t-border-color">
                <div className="flex-row">
                    <ActionButton className={"c-contact-panel-button" + (props.contactPage.displayContactProfilePage === true ? " t-list-item-selected" : "")} onClick={(event: any) => _openProfilePage()}>{localizedStrings.ContactsView.contactPanel.profile}</ActionButton>
                </div>
                <div className="flex-row">
                    <ActionButton className={"c-contact-panel-button" + (props.contactPage.displayContactReservationsPage === true ? " t-list-item-selected" : "")} onClick={(event: any) => _openReservationsPage()}>{localizedStrings.ContactsView.contactPanel.reservations}</ActionButton>
                </div>
            </div>
        </>
    );

    function _openProfilePage() {
        props.logTelemetry(PageFeature.ClientelingCommandBar, "Open contact profile page clicked");
        props.changeContactPage({ displayContactProfilePage: true });
        props.changeContactPage({ displayContactReservationsPage: false });
    };

    function _openReservationsPage() {
        props.logTelemetry(PageFeature.ClientelingCommandBar, "Open contact reservations page clicked");
        props.changeContactPage({ displayContactProfilePage: false });
        props.changeContactPage({ displayContactReservationsPage: true });
    };
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactPanelProvidedProps): Partial<IContactPanelOwnProps> {
    return {
        ...providedProps,
        displayContactMenuPanel: PageSelectors.getPanels(state)?.displayContactMenuPanel,
        contactPage: PageSelectors.getContactPage(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    changeContactPage: PageActions.changeContactPage,
    updatePanels: PageActions.updatePanels,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactPanel = connector(ContactPanelInitializer);
