import React from "react";
import { FocusZone, FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { Nav, INavLink } from "@fluentui/react/lib/Nav";
import { NavMenuItem } from "./../../Page/Contracts";

import "./NavMenu.scss";

interface INavMenuProps {
    menuItems: NavMenuItem[];
    direction: FocusZoneDirection;
    selectedMenuItemId: number;
    onSelectedMenuItemChanged(id: number): void;
}

export class NavMenu extends React.Component<INavMenuProps> {
    public render() {
        return (
            <FocusZone direction={this.props.direction}>
                <Nav
                    onLinkClick={this._onLinkClick.bind(this)}
                    selectedKey={this.props.selectedMenuItemId.toString()}
                    selectedAriaLabel="Selected"
                    className="c-nav-menu"
                    groups={[
                        {
                            name: "Clienteling",
                            links: this._createNavLinks()
                        }
                    ]}
                ></Nav>
            </FocusZone>
        );
    }

    private _createNavLinks(): INavLink[] {
        var that = this;
        let navLinks: INavLink[] = [];

        this.props.menuItems.map(menuItem => {
            navLinks.push({
                name: menuItem.title,
                key: menuItem.id.toString(),
                onClick: that._onLinkClick.bind(that),
                url: "",
                iconProps: { iconName: menuItem.iconName }
            });
        });

        return navLinks;
    }

    private _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        if (item) {
            this.props.onSelectedMenuItemChanged(item.key ? Number.parseInt(item.key) : 0);
        }
    }
}
