import * as React from "react";
import { shimRef } from "./../../../core/util/Util";
import { IMeasureProps } from "./Measure.Props";

/**
 * Measures the child component; expects exactly one child
 * Use with caution; improper use can cause severe layout thrashing
 */
export class Measure extends React.Component<IMeasureProps> {
    private childRef: React.RefObject<HTMLElement> = React.createRef<HTMLElement>();
    private lastWidth: number = -1;
    private lastHeight: number = -1;

    public render() {
        const child = React.Children.only(this.props.children) as React.DetailedReactHTMLElement<any, HTMLElement>;

        this.childRef = shimRef(child);

        return React.cloneElement(child, { ...child.props, ref: this.childRef });
    }

    public componentDidMount() {
        window.addEventListener("resize", this.onResize);
        this.onResize();
    }

    public componentDidUpdate() {
        this.onResize();
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", this.onResize);
    }

    private onResize = () => {
        const childElement = this.childRef.current;

        if (childElement) {
            const childRectangle = childElement.getBoundingClientRect();

            // Anti-layout-thrashing - don't pass back up
            if (this.lastWidth != childRectangle.width || this.lastHeight != childRectangle.height) {
                this.props.onMeasure && this.props.onMeasure(childRectangle.width, childRectangle.height);
                this.lastWidth = childRectangle.width;
                this.lastHeight = childRectangle.height;
            }
        }
    };
}
