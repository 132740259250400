import { IRunnerRequestsAwareState } from './../views/RunnerRequestsView/Contracts';
import { IPageAwareState } from "../platform/components/Page/Contracts";
import { IReservationsAwareState } from "../views/ReservationsView/Contracts";
import { IContactsAwareState } from "../views/ContactsView/Contracts";
import { IAppointmentDetailsAwareState } from "../views/AppointmentDetailsView/Contracts";
import { IFeatureManagementAwareState } from "../views/FeatureManagement/Contracts";
import { IAppointmentModalAwareState } from "../common/components/CreateEditAppointmentModal/Contracts";

export interface IClientelingViewState
    extends IPageAwareState,
        IReservationsAwareState,
        IContactsAwareState,
        IAppointmentDetailsAwareState,
        IFeatureManagementAwareState,
        IAppointmentModalAwareState,
        IRunnerRequestsAwareState {}

export class Area {
    public static Reservations = "Reservations";
    public static Dashboard = "Dashboard";
}

export class TelemetryProperties {
    public static Action = "Action";
    public static ErrorMessage = "ErrorMessage";
}
