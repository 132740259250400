import { spawn } from "redux-saga/effects";
import { pageSaga } from "../components/Page/redux/PageSaga";
import { reservationsSaga } from "../../views/ReservationsView/redux/ReservationsSaga";
import { contactsSaga } from "../../views/ContactsView/redux/ContactsSaga";
import { featureManagementSaga } from "../../views/FeatureManagement/redux/FeatureManagementSaga";
import { appointmentDetailsSaga } from "../../views/AppointmentDetailsView/redux/AppointmentDetailsSaga";
import { appointmentModalSaga } from "../../common/components/CreateEditAppointmentModal/redux/AppointmentModelSaga";
import { runnerRequestsSaga } from "../../views/RunnerRequestsView/redux/RunnerRequestsSaga";

export default function* rootSaga() {
    yield spawn(pageSaga);
    yield spawn(reservationsSaga);
    yield spawn(contactsSaga);
    yield spawn(featureManagementSaga);
    yield spawn(appointmentDetailsSaga);
    yield spawn(appointmentModalSaga);
    yield spawn(runnerRequestsSaga)
}
