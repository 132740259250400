import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import ContactContainer from "./components/ContactContainer/ContactContainer";

import { ConnectedContactCommandBar } from "../../platform/components/ContactCommandBar/ContactCommandBar";
import { IRapComponentProperties } from "../../platform/Layout";
import { IClientelingViewState } from "../../pages/Contracts";
import { connect } from "react-redux";
import { AppointmentDetail, IContactDto } from "../../contracts/swagger/_generated";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import { AppointmentDetailsActions } from "../AppointmentDetailsView/redux/AppointmentDetailsActions";
import { ContactsActions } from "./redux/ContactsActions";
import { ModalHandler } from "../../platform/components/ModalHandler/ModalHandler";
import { PanelHandler } from "../../platform/components/PanelHandler/PanelHandler";
import { ToastHandler } from "../../platform/components/ToastHandler/ToastHandler";
import { ErrorHandler } from "../../platform/components/ErrorHandler/ErrorHandler";

import * as AppointmentDetailsSelectors from "../AppointmentDetailsView/redux/AppointmentDetailsSelectors";
import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";
import * as ContactsSelectors from "./redux/ContactsSelectors";

import "./ContactsView.scss";

//Props passed by parent component
interface IContactViewProvidedProps extends IRapComponentProperties {
}

interface IContactViewOwnProps extends IContactViewProvidedProps {
    selectedAppointmentDetails: AppointmentDetail;
    selectedContact: IContactDto;
    storeId: string;
    isLoading: boolean;
    errorMessage: string;
}

export type IContactViewProps = IContactViewOwnProps & typeof ActionsToDispatch;

const ContactView: FC<IContactViewProps> = (props) => {
    let { confirmno, contactId } = useParams<any>();

    //fetch appointment when storeid loads or when selected appointment is changed
    useEffect(() => {
        if(props.storeId && confirmno && props.selectedAppointmentDetails?.appointmentDetails?.confirmationNumber != confirmno) {
            props.fetchAppointmentDetail(props.storeId, confirmno);
        }
    }, [confirmno, props.storeId]);

    useEffect(() => {
        if(props.selectedContact?.contactId != contactId) {
            props.fetchSelectedContact(contactId);
        }
    }, [contactId]);

    if(props.isLoading) {
        return (
            <LoadingSpinner />
        );
    }
    return (
        <>
            <ConnectedContactCommandBar
                selectedAppointment={props.selectedAppointmentDetails}
            />
            <ErrorHandler errorMessage={props.errorMessage} onReset={() => props.setMessages({ errorMessage: undefined })}/>
            <ContactContainer/>
            <ModalHandler/>
            <PanelHandler/>
            <ToastHandler/>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactViewProvidedProps): Partial<IContactViewOwnProps> {
    return {
        ...providedProps,
        selectedAppointmentDetails: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        isLoading: ContactsSelectors.getIsLoading(state),
        errorMessage: ContactsSelectors.getMessages(state)?.errorMessage
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAppointmentDetail: AppointmentDetailsActions.fetchAppointmentDetail,
    fetchSelectedContact: ContactsActions.fetchContactById,
    setMessages: ContactsActions.setMessages
};

export const ConnectedContactView = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ContactView);