import React from "react";
import { connect } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import AppointmentDetails from "./components/AppointmentDetails/AppointmentDetails";
import AppointmentClientView from './components/AppointmentClient/AppointmentClientView';
import AssociateNotes from "./components/AssociateNotes/AssociateNotes";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PermissionGate } from "../../../../common/components/PermissionGate/PermissionGate";
import { UserRole } from "../../../../platform/components/Page/Contracts";
import { ErrorBoundaryFallback } from "../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";


import "./AppointmentContainer.scss";


//Props passed by parent component
interface IAppointmentContainerProvidedProps extends IRapComponentProperties {
}

interface IAppointmentContainerOwnProps extends IAppointmentContainerProvidedProps {
}

interface IAppointmentContainerState {
}

export type IAppointmentContainerProps = IAppointmentContainerOwnProps & typeof ActionsToDispatch;

class AppointmentContainerInitializer extends RapComponent<IAppointmentContainerProps, IAppointmentContainerState> {
    constructor(props: IAppointmentContainerProps, context: IRapComponentContext) {
        super(props, context);
    }

    public render() {
        return (
            <div className="c-appointment-container">
                <div className="c-main-container">
                    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                        <AppointmentDetails/>
                    </ErrorBoundary>
                </div>
                <div className="c-side-container">
                    <div className="c-widget-container size-1">
                        <PermissionGate minimumUserRole={UserRole.Associate}>
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <AppointmentClientView/>  
                            </ErrorBoundary>
                        </PermissionGate>
                    </div>
                    <div className="c-widget-container size-2">
                        <PermissionGate minimumUserRole={UserRole.Associate}>
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <AssociateNotes/>
                            </ErrorBoundary>
                        </PermissionGate>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentContainerProvidedProps): Partial<IAppointmentContainerOwnProps> {
    return {
        ...providedProps,
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentContainerInitializer);