import { IAppointmentForUpdateDto } from "../../../contracts/swagger/_generated";
import { IAppointmentDetailsAwareState, ILeadResources, IMessages, ISelectedAppointmentInfo, IUpdateAppointmentModel } from "../Contracts";


export function getSelectedAppointmentInfo(state: IAppointmentDetailsAwareState): ISelectedAppointmentInfo | undefined {
    if (!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.data) {
        return undefined;
    }

    return state.appointmentDetailsViewState.data.selectedAppointment;
}

export function getIsLoading(state: IAppointmentDetailsAwareState): boolean | undefined {
    if (!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.ux) {
        return undefined;
    }

    return state.appointmentDetailsViewState.ux.isLoading;
}

export function getMessages(state: IAppointmentDetailsAwareState): IMessages | undefined {
    if (!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.ux) {
        return undefined;
    }

    return state.appointmentDetailsViewState.ux.messages;
}

export function getIsEditModeEnabled(state: IAppointmentDetailsAwareState): boolean | undefined {
    if (!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.ux) {
        return undefined;
    }

    return state.appointmentDetailsViewState.ux.isEditModeEnabled;
}

export function getLeadResources(state: IAppointmentDetailsAwareState): ILeadResources | undefined {
    if (!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.data || !state.appointmentDetailsViewState.data.resources) {
        return undefined;
    }

    return state.appointmentDetailsViewState.data.resources.leadResources;
}

export function getAppointmentUpdateModel(state: IAppointmentDetailsAwareState): IUpdateAppointmentModel | undefined {
    if(!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.data) {
        return undefined;
    }

    return state.appointmentDetailsViewState.data.updateAppointment;
}

export function getAppointmentForUpdate(state: IAppointmentDetailsAwareState): IAppointmentForUpdateDto | undefined {
    if(!state || !state.appointmentDetailsViewState || !state.appointmentDetailsViewState.data || !state.appointmentDetailsViewState.data.updateAppointment) {
        return undefined;
    }

    return state.appointmentDetailsViewState.data.updateAppointment.updates;
}