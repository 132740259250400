import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text } from "@fluentui/react";

import { ICustomerInsightsDto, ISurveyResponseDto } from "../../../../../../../../contracts/swagger/_generated";
import { TextSizes } from "../../../../../../../../common/Constants";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { getCSATColor } from "../../../../../../../../common/AppointmentUtils";
import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";

import { SurveyQuestion } from "../SurveyQuestion/SurveyQuestion";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";
import * as  FeatureManagementSelectors from "../../../../../../../FeatureManagement/redux/FeatureManagementSelectors";

//import "../../../ContactCard.scss"
import "./ContactSurveyResults.scss";

interface IContactSurveyResultsProvidedProps extends IRapComponentProperties {
}

interface IContactSurveyResultsOwnProps {
    selectedContactInsights: ICustomerInsightsDto;
    selectedAppointmentId?: string;
    surveys?: ISurveyResponseDto[];
    enableCSAT?: boolean;
}

export type IContactSurveyResultsProps = ConnectedProps<typeof connector> & IContactSurveyResultsProvidedProps;

const ContactSurveyResultsInitializer: FC<IContactSurveyResultsProps> = props => {
    const [selectedSurvey, setSelectedSurvey] = useState<ISurveyResponseDto>(undefined);

    useEffect(() => {
        if(props.surveys && props.surveys.length > 0) {
            let survey: ISurveyResponseDto = props.surveys.find((s: ISurveyResponseDto) => s.appointmentId == props.selectedAppointmentId);
            survey ? setSelectedSurvey(survey) : setSelectedSurvey(undefined);
        }
        else {
            setSelectedSurvey(undefined);
        }
    }, [props.selectedAppointmentId, props.surveys])

    const _calculateCSAT = () => {
        return <span><span style={{"color": getCSATColor(selectedSurvey.csat)}}>{selectedSurvey.csat}</span>/5.0</span>
    }

    const _calculateSentiment = () => {
        let s = parseFloat(selectedSurvey.csat);
        if(s >= 4.5) {
            return localizedStrings.ContactsView?.survey?.positive;
        }
        else if(s < 4.5 && s >= 4) {
            return localizedStrings.ContactsView?.survey?.neutral;
        }
        else {
            return localizedStrings.ContactsView?.survey?.negative;
        }
    }

    if(selectedSurvey == undefined || !props.enableCSAT) {
        return (
            <>
                <div className="flex c-contact-survey-results">
                    <div className="flex-row">
                        {localizedStrings.ContactsView?.survey?.notFound}
                    </div>
                </div>
            </>
        )

    }
    else {
        return (
            <>
                <div className="flex c-contact-survey-results">
                    <div className="flex-row">
                        <div className="flex-column c-condensed-two-column">
                            <div className="flex-row">
                                <Text className="flex c-header" variant={TextSizes.large} nowrap>
                                    {localizedStrings.ContactsView?.survey?.csat}
                                </Text>
                            </div>
    
                            <div className="flex-row">
                                <Text className="flex c-content bold" variant={TextSizes.medium} nowrap>
                                    {_calculateCSAT()}
                                </Text>
                            </div>
                        </div>
    
                        <div className="flex-column">
                            <div className="flex-row">
                                <Text className="flex c-header" variant={TextSizes.large} nowrap>
                                    {localizedStrings.ContactsView?.survey?.sentiment}
                                </Text>
                            </div>
    
                            <div className="flex-row">
                                <Text className="flex c-content" variant={TextSizes.medium} nowrap>
                                    {_calculateSentiment()}
                                </Text>
                            </div>
                        </div>
                    </div>
    
                    <div className="flex-row c-space" />

                    {selectedSurvey.surveyResponses?.map(r => {
                        return (
                            <SurveyQuestion question={r}/>
                        )
                    })}
    
                    <div className="flex-row c-space" />
    
                    {/* TODO: figure out if we need this */}
                    {/* <div className="flex-row">
                        <Text className="flex c-header" variant={TextSizes.large} nowrap>
                            Additional feedback
                        </Text>
                    </div>
    
                    <div className="flex-row">
                        <Text className="flex c-content" variant={TextSizes.medium} nowrap>
                            No additional feedback given.
                        </Text>
                    </div> */}
                </div>
            </>
        );
    }
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactSurveyResultsProvidedProps): Partial<IContactSurveyResultsOwnProps> {
    return {
        ...providedProps,
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights,
        selectedAppointmentId: ContactsSelectors.getSelectedAppointmentId(state),
        surveys: ContactsSelectors.getCustomerInsightsData(state)?.surveys,
        enableCSAT: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableCSAT", false),
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactSurveyResults = connector(ContactSurveyResultsInitializer);
