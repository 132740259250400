import { IRapRestClient, IRapRestClientOptions } from "./Context";

/**
 * Base class that should be used (derived from) to make requests to VSS REST apis
 */
export class RestClientBase implements IRapRestClient {
    private _options: IRapRestClientOptions;

    constructor(options?: IRapRestClientOptions) {
        this._options = options || { authData: null };
    }

    /**
     * Gets the raw id token for the app
     */
    public rawIdToken(): string {
        return this._options.authData ? this._options.authData.idToken : "";
    }

    protected async transformOptions(options: RequestInit): Promise<RequestInit> {
        let options_ = options;
        options_.headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.rawIdToken()}`
        };

        return options_;
    }
}

export const ClientelingRestClientName = "IClientelingApiClient";
