import { FC } from "react";
import { connect } from "react-redux";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import Page from "./../Page/Page";
import ReservationsPage from "../../../pages/Reservations/ReservationsHub";
import AppointmentPage from "../../../pages/Appointment/AppointmentHub";
import ContactsPage from "../../../pages/Contact/ContactHub";
import RunnerRequestsHub from "../../../pages/RunnerRequests/RunnerRequestsHub";

import { IRapComponentProperties } from "../../Layout";
import { ConnectedNavBar } from "../NavBar/NavBar";
import { IClientelingViewState } from "../../../pages/Contracts";

import * as LocaleUtils from "../../core/util/Locale";

import "./Shell.scss";

type RouteParams = {
    locale: string;
};

const Shell: FC<IShellProps> = (props) => {
    return (
        <BrowserRouter>
            <div id="page">
                <div id="main-content" className="main-content flex-column full-size flex-grow">
                    {props.inTeams !== true ? <ConnectedNavBar /> : null}
                    <Routes>
                        <Route 
                            path={'/:locale?/reservations'} 
                            element={<Page title="ReservationsPage"><ReservationsPage/></Page>}
                            key={'reservations'}
                        />
                        <Route 
                            path={'/:locale?/reservations/:confirmno?'} 
                            element={<Page title="AppointmentPage"><AppointmentPage/></Page>}
                            key={'appointment'}
                        />
                        <Route 
                            path={'/:locale?/reservations/:confirmno?/contact/:contactId?'} 
                            element={<Page title="ContactsPage"><ContactsPage/></Page>}
                            key={'contacts'}
                        />
                        <Route 
                            path={'/:locale?/runnerrequests'} 
                            element={<Page title="Runner requests"><RunnerRequestsHub/></Page>} 
                            key={'runnerrequests'}
                        />
                        <Route path="/:locale/" key={'emptywithLocale'} element={<Navigate to={`/${LocaleUtils.getLocale()}/reservations`}/>}/>
                        <Route path="/" key={"empty"} element={<Navigate to={`/${LocaleUtils.getLocale()}/reservations`}/>} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

interface IShellProvidedProps extends IRapComponentProperties {
    inTeams: boolean;
    teamsLocale: string;
}

interface IShellOwnProps extends IShellProvidedProps {
}

export type IShellProps = IShellOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IShellProvidedProps): Partial<IShellProvidedProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(Shell);

