import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dropdown, IconButton, IDropdownOption, MaskedTextField, Modal, PrimaryButton, TextField } from "@fluentui/react";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { PageActions } from "../../../../../platform/components/Page/redux/PageActions";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { DialogHeader } from "../../../../../common/components/DialogHeader/DialogHeader";
import { IContactDto, IContactForCreationDto } from "../../../../../contracts/swagger/_generated";
import { isValidEmail, isValidPhone } from "../../../../../platform/core/util/Util";
import { ContactsActions } from "../../../../../views/ContactsView/redux/ContactsActions";

import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors";
import * as Constants from "../../../../../common/Constants";

import "./AddClientModal.scss";
import { LoadingContainer } from "../../../LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IAddClientModalProvidedProps extends IRapComponentProperties {
    onContactCreated?: (contact: IContactDto) => void;
}

interface IAddClientModalOwnProps {
    isAddClientModalOpen: boolean;
}

interface IAddClientModalState {
    form: IAddClientFormValues;
    isLoading: boolean;
}

export interface IAddClientFormValues extends IContactForCreationDto {
    countryDisplay: string;
}

export type IAddClientModalProps = ConnectedProps<typeof connector> & IAddClientModalProvidedProps

class AddClientModalInitializer extends RapComponent<IAddClientModalProps, IAddClientModalState> {
    constructor(props: IAddClientModalProps, context: IRapComponentContext) {
        super(props, context);
        this.state = {
            form: {
                firstName: "",
                lastName: "",
                email: "",
                countryDisplay: Constants.UnitedStatesDisplay,
            },
            isLoading: false
        }
    }

    private _onSaveConfirmation = (): void => {
        this.setState({ isLoading: true });
        this.props.createContact(this.state.form).then((contact: IContactDto) => {
            this.props.onContactCreated(contact);
            this._onHideAddClientModal();
            this.props.updateMessages({ toastMessage: localizedStrings.AddClient.createSuccess });
        }).catch((resp: {errorCode: number, error: string}) => {
            if(resp.errorCode == Constants.HttpErrorCodes.Duplicate) {
                this.props.updateMessages({ toastMessage: localizedStrings.AddClient.duplicate });
            } else {
                this.props.updateMessages({ toastMessage: localizedStrings.AddClient.createFail });
            }
        }).finally(() => {
            this.setState({ isLoading: false });
        });
    };

    private _onHideAddClientModal = (): void => {
        this.setState({ form: { firstName: '', lastName: '', email: '', countryDisplay: Constants.UnitedStatesDisplay } });
        this.props.updateModals({ displayAddClientModal: false });
    };

    private _getOptions(): IDropdownOption[] {
        let options: IDropdownOption[] = [];
        Constants.PhoneCountryCodes.forEach((value: string, key: string) => {
            options.push({
                key: value,
                text: key,
                isSelected: (key === this.state.form.countryDisplay)
            });
        });
        return options;
    }

    private updateForm = (val: Partial<IAddClientFormValues>) => {
        this.setState({form: {...this.state.form, ...val}});
    }

    public render() {
        const isSaveDisabled = this._isSaveButtonDisabled();
        return (
            <Modal
                containerClassName="c-add-modal"
                isOpen={this.props.isAddClientModalOpen}
                onDismiss={this._onHideAddClientModal}>
                <div className="flex-row c-header-label">
                    <DialogHeader title={localizedStrings.AddClient.title}/>
                    <IconButton
                        className="c-icon-cancel"
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel={localizedStrings.AddClient.back}
                        onClick={this._onHideAddClientModal}
                    />
                </div>
                <div className="flex-row c-row">
                    <TextField
                        className="c-column-wide"
                        required
                        label={localizedStrings.AddClient.firstName}
                        ariaLabel={localizedStrings.AddClient.firstName}
                        onGetErrorMessage={(value: string) => this._validateFirstName(value)}
                        validateOnFocusOut
                        validateOnLoad={false}
                    />
                </div>
                <div className="flex-row c-row">
                    <TextField
                        className="c-column-wide"
                        required
                        label={localizedStrings.AddClient.lastName}
                        ariaLabel={localizedStrings.AddClient.lastName}
                        onGetErrorMessage={(value: string) => this._validateLastName(value)}
                        validateOnFocusOut
                        validateOnLoad={false}
                    />
                </div>
                <div className="flex-row c-row">
                    <TextField
                        className="c-column-wide"
                        required
                        label={localizedStrings.AddClient.email}
                        ariaLabel={localizedStrings.AddClient.email}
                        onGetErrorMessage={(value: string) => this._validateEmail(value)}
                        validateOnFocusOut
                        validateOnLoad={false}
                    />
                </div>
                <div className="flex-row c-row">
                    <Dropdown
                        className="c-column-1"
                        label={localizedStrings.AddClient.country}
                        ariaLabel={localizedStrings.AddClient.country}
                        options={this._getOptions()}
                        onChange={this._onCountryChange}
                    />
                    <MaskedTextField
                        className="c-column-2"
                        mask={Constants.PhoneNumberMasks.get(this.state.form.countryDisplay)}
                        label={localizedStrings.AddClient.mobile}
                        ariaLabel={localizedStrings.AddClient.mobile}
                        onGetErrorMessage={(value: string) => this._validatePhone(value)}
                        validateOnFocusOut
                        validateOnLoad={false}
                    />
                </div>
                <div className="flex-row c-footer-container">
                    <PrimaryButton
                        className="c-save-button" 
                        onClick={this._onSaveConfirmation}
                        ariaLabel={localizedStrings.AddClient.save}
                        disabled={isSaveDisabled || this.state.isLoading}
                    >
                        <LoadingContainer isLoading={this.state.isLoading}>
                            <>{localizedStrings.AddClient.save}</>
                        </LoadingContainer>
                    </PrimaryButton>
                </div>
            </Modal>
        );
    }

    private _onCountryChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        this.updateForm({ countryDisplay: option.text });
    };

    private _isSaveButtonDisabled = () => {
        const isValidFirstName = this._validateName(this.state.form.firstName) === '' && this.state.form.firstName !== '';
        const isValidLastName = this._validateName(this.state.form.lastName) === '' && this.state.form.lastName !== '';
        const isEmailValid = isValidEmail(this.state.form.email) && this.state.form.email !== '';

        if (!isValidFirstName || !isValidLastName || !isEmailValid) {
            return true;
        }
        return false;
    }

    private _validateFirstName = (name: string) => {
        let errorMessage = this._validateName(name);
        this.updateForm({ firstName: name });
        return errorMessage;
    }

    private _validateLastName = (name: string) => {
        let errorMessage = this._validateName(name);
        this.updateForm({ lastName: name });
        return errorMessage;
    }

    private _validateName = (name: string) => {
        name = name.trim();
        let errorMessage = "";
        if (name === "") {
            errorMessage = localizedStrings.AddClient.nameIsEmptyError;
        } else if (name.length > Constants.NAME_MAX_CHAR) {
            errorMessage = localizedStrings.AddClient.nameTooLargeError;
        } else if (name.endsWith(".")) {
            errorMessage = localizedStrings.AddClient.nameEndingInPeriodError;
        } else if (!!Constants.InvalidCharsRegex.test(name)) {
            errorMessage = localizedStrings.AddClient.nameIllegalCharactersError;
        } else if (!!Constants.NonPrintableCharsRegex.test(name)) {
            errorMessage = localizedStrings.AddClient.nameNonPrintableCharactersError;
        }
        return errorMessage;
    };

    private _validateEmail = (value: string) => {
        let errorMessage = "";
        if (isValidEmail(value) !== true) {
            errorMessage = localizedStrings.AddClient.invalidEmail;
        } 
        this.updateForm({ email: value });
        return errorMessage;
    };

    private _validatePhone = (value: string) => {
        let errorMessage = "";
        if (!isValidPhone(value)) {
            errorMessage = localizedStrings.AddClient.invalidPhone;
        } else {
            const unformattedPhone = value ? 
                Constants.PhoneCountryCodes.get(this.state.form.countryDisplay) + value.replace(Constants.NonNumbersRegex, "") : undefined;
            this.updateForm({ phone: unformattedPhone });
        }
        return errorMessage;
    };
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAddClientModalProvidedProps): Partial<IAddClientModalOwnProps> {
    return {
        ...providedProps,
        isAddClientModalOpen: PageSelectors.getModals(state)?.displayAddClientModal,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    createContact: ContactsActions.createContact,
    updateModals: PageActions.updateModals,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const AddClientModal = connector(AddClientModalInitializer)