import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ActionButton, IIconProps } from "@fluentui/react";
import { List } from '@fluentui/react/lib/List';
import { IRapComponentProperties } from "../../../../Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { RunnerRequestState, RunnerRequestStatus } from "../../../../../common/Constants";
import { RunnerRequestsActions } from "../../../../../views/RunnerRequestsView/redux/RunnerRequestsActions";
import * as RunnerRequestsSelectors from "../../../../../views/RunnerRequestsView/redux/RunnerRequestsSelectors";
import { PageActions } from "../../../Page/redux/PageActions";
import { IRetailStoreDto, IRunnerRequestDto } from "../../../../../contracts/swagger/_generated";

import "./RunnerRequestStateFilter.scss";

interface IRunnerRequestStateFilterItem {
    id: RunnerRequestState;
    displayName: string;
    count: number;
    isSelected: boolean;
}

interface IRunnerRequestStateFilterProvidedProps extends IRapComponentProperties {
}

interface IRunnerRequestStateFilterOwnProps {
    runnerRequests: IRunnerRequestDto[];
    selectedFilterState: RunnerRequestState;
    selectedRetailStore: IRetailStoreDto
}

export type IRunnerRequestStateFilterProps = ConnectedProps<typeof connector> & IRunnerRequestStateFilterProvidedProps;

const RunnerRequestStateFilterInitializer: FC<IRunnerRequestStateFilterProps> = props => {
    const [stateFilterItems, setStateFilterItems] = useState<IRunnerRequestStateFilterItem[]>();
    const addIcon: IIconProps = { iconName: 'AddFriend' };
    const closeIcon: IIconProps = { iconName: 'UserRemove' };

    useEffect(() => {
        setStateFilterItems([
            { 
                id: RunnerRequestState.ActionNeeded, 
                displayName: localizedStrings.RunnerRequestStatusFilter.actionNeeded, 
                count: props.runnerRequests 
                    ? props.runnerRequests.filter(runnerRequest => runnerRequest.status === RunnerRequestStatus.Active).length 
                    : 0,
                isSelected: props.selectedFilterState === RunnerRequestState.ActionNeeded
            },
            { 
                id: RunnerRequestState.InProgress, 
                displayName: localizedStrings.RunnerRequestStatusFilter.inProgress, 
                count: props.runnerRequests 
                    ? props.runnerRequests.filter(runnerRequest => runnerRequest.status === RunnerRequestStatus.InProgress).length 
                    : 0,
                isSelected: props.selectedFilterState === RunnerRequestState.InProgress
            },
            { 
                id: RunnerRequestState.Canceled, 
                displayName: localizedStrings.RunnerRequestStatusFilter.canceled, 
                count: props.runnerRequests 
                    ? props.runnerRequests.filter(runnerRequest => runnerRequest.status === RunnerRequestStatus.Canceled).length 
                    : 0,
                isSelected: props.selectedFilterState === RunnerRequestState.Canceled
            },
            { 
                id: RunnerRequestState.Complete, 
                displayName: localizedStrings.RunnerRequestStatusFilter.complete, 
                count: props.runnerRequests 
                    ? props.runnerRequests.filter(runnerRequest => runnerRequest.status === RunnerRequestStatus.Complete).length 
                    : 0,
                isSelected: props.selectedFilterState === RunnerRequestState.Complete
            },
            { 
                id: RunnerRequestState.All, 
                displayName: localizedStrings.RunnerRequestStatusFilter.all, 
                count: props.runnerRequests ? props.runnerRequests.length : 0,
                isSelected: props.selectedFilterState === RunnerRequestState.All
            }
        ]);
    }, [props.runnerRequests, props.selectedFilterState]);

    const _onClickStateButton = (stateVal: RunnerRequestState) => {
        props.setSelectedFilterState(stateVal);
    }

    const onRenderCell = (item: IRunnerRequestStateFilterItem): JSX.Element => {
        return (
            <>
                {item.isSelected ? ( 
                    <ActionButton className="c-state-filters t-list-item-selected">{item.displayName} ({item.count})</ActionButton>
                ) : (
                    <ActionButton className="c-state-filters" onClick={() => _onClickStateButton(item.id)}>{item.displayName} ({item.count})</ActionButton>
                )}
            </>
        );
    };

    const _onEnableDisableRequestsClick = () => {
        props.updateModals({ displayEnableDisableRequestsModal: true });
    }

    return (
        <div className="c-rr-state-filter t-border-color">
            <List items={stateFilterItems} onRenderCell={onRenderCell} />
            {props.selectedRetailStore && (
                <ActionButton 
                    iconProps={props.selectedRetailStore.isAvailable ? closeIcon : addIcon} 
                    className="c-queue-state-button t-brand-color" 
                    onClick={_onEnableDisableRequestsClick}
                >
                    {props.selectedRetailStore.isAvailable 
                        ? localizedStrings.RunnerRequestStatusFilter.disableRequests
                        : localizedStrings.RunnerRequestStatusFilter.enableRequests}
                </ActionButton>
            )}
        </div>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestStateFilterProvidedProps): Partial<IRunnerRequestStateFilterOwnProps> {
    return {
        ...providedProps,
        runnerRequests: RunnerRequestsSelectors.getRunnerRequests(state),
        selectedFilterState: RunnerRequestsSelectors.getSelectedFilterState(state),
        selectedRetailStore: RunnerRequestsSelectors.getSelectedRetailStore(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setSelectedFilterState: RunnerRequestsActions.setSelectedFilterState
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const RunnerRequestStateFilter = connector(RunnerRequestStateFilterInitializer);