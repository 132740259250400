import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

import ErrorBoundary from "../../core/components/LegacyErrorBoundary/ErrorBoundary";
import { IPageDefinition } from "./PageDefinitionTypes";
import { announce } from "../../core/util/Accessibility";
import { css } from "../../core/util/Util";
import { RapComponent } from "../../Layout";
import { ScreenSizeConditional } from "../../core/components/ScreenSize/ScreenSize";
import { ScreenSize } from "../../core/util/Screen";

import * as LocaleUtils from "../../core/util/Locale";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";


interface IPage {
    title: string;
}

const Page: FC<IPage> = (props) => {
    const { locale } = useParams();

    useEffect(() => {        
        document.title = props.title;

        const interfaceLocale = LocaleUtils.getLocale();
        if (interfaceLocale !== locale && locale !== undefined) {
            localizedStrings.setLanguage(locale);
        }

        // React router doesn't trigger navigation so we will need to set the document title and announce when the page has loaded.
        announce(`Loaded ${props.title} page`);
    }, [])

    return (
        <>
            <ScreenSizeConditional condition={screenSize => screenSize <= ScreenSize.small}>
                <div className={css("c-mobile-page flex-grow flex-column")}>
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </div>
            </ScreenSizeConditional>
            <ScreenSizeConditional condition={screenSize => screenSize > ScreenSize.small}>
                <div className={css("c-page flex-grow flex-column scroll-hidden")}>
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </div>
            </ScreenSizeConditional>
        </>
    );
}

export default Page;
