import { produce } from "immer";
import { RunnerRequestDashboardDefaultStore, RunnerRequestState, RunnerRequestView } from "../../../common/Constants";
import { IRunnerRequestsViewState } from "../Contracts";
import { RunnerRequestsActionsType, RunnerRequestsActionTypes } from "./RunnerRequestsActions";

export function runnerRequestsReducer(state: IRunnerRequestsViewState, action: RunnerRequestsActionsType): IRunnerRequestsViewState {
    return produce(state || ({} as IRunnerRequestsViewState), draft => {
        switch(action.type) {
            case RunnerRequestsActionTypes.InitRunnerRequestsView: {
                draft.data = {};
                draft.ux = {
                    selectedFilterState: RunnerRequestState.ActionNeeded,
                    selectedView: RunnerRequestView.Runner
                }
                break;
            }

            case RunnerRequestsActionTypes.SetErrorMessage: {
                draft.ux.errorMessage = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.FetchRetailStoresSuccess: {
                draft.data.retailStores = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.CreateRunnerRequestSuccess: {
                draft.data.createdRunnerRequest = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.FetchRunnerRequestsSuccess: {
                draft.data.runnerRequests = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.CancelRunnerRequestSuccess: {
                draft.data.canceledRunnerRequest = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.ConfirmRunnerRequestSuccess: {
                draft.data.confirmedRunnerRequest = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.CompleteRunnerRequestSuccess: {
                draft.data.completedRunnerRequest = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.UpdateStoreStatusSuccess: {
                draft.data.updatedRetailStore = action.payload;
                // Update selected store with the fresh data
                draft.ux.selectedRetailStore = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.FetchStoreByStoreNumberSuccess: {
                // Update selected retail store if the store's availability has changed
                if(action.payload?.isAvailable !== state.ux.selectedRetailStore.isAvailable) {
                    draft.ux.selectedRetailStore = action.payload;
                }
                break;
            }

            case RunnerRequestsActionTypes.SetSelectedRunnerRequest: {
                draft.ux.selectedRunnerRequest = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.ResetSelectedRunnerRequest: {
                draft.ux.selectedRunnerRequest = undefined;
                break;
            }

            case RunnerRequestsActionTypes.SetSelectedRetailStore: {
                draft.ux.selectedRetailStore = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.SetSelectedFilterState: {
                draft.ux.selectedFilterState = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.SetSelectedView: {
                draft.ux.selectedView = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.UpdateSearchTerm: {
                draft.ux.searchTerm = action.payload;
                break;
            }

            case RunnerRequestsActionTypes.FetchStoreByStoreNumberFailure:
            case RunnerRequestsActionTypes.UpdateStoreStatusFailure:
            case RunnerRequestsActionTypes.CompleteRunnerRequestFailure:
            case RunnerRequestsActionTypes.ConfirmRunnerRequestFailure:
            case RunnerRequestsActionTypes.CancelRunnerRequestFailure:
            case RunnerRequestsActionTypes.FetchRunnerRequestsFailure:
            case RunnerRequestsActionTypes.CreateRunnerRequestFailure:
            case RunnerRequestsActionTypes.FetchRetailStoresFailure: {
                draft.ux.errorMessage = action.payload;
                break;
            }

            default:
                return state;
        }
    });
}