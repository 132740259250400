import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { DetailsList, CheckboxVisibility, SelectionMode, IColumn } from "@fluentui/react";
import moment from "moment";

import { IContactDto, ICustomerInsightsDto, IPurchaseHistoryDto } from "../../../../../../../../contracts/swagger/_generated";

import { DateFormat, DetailListColumnSizes, DetailListColumnSizes_Max } from "../../../../../../../../common/Constants";
import { Card } from "../../../../../../../../common/components/Card/Card";
import { ComingSoon } from "../../../../../../../../common/components/ComingSoon/ComingSoon";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { NoResults } from "../../../../../../../../common/components/NoResults/NoResults";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../../../../../platform/Layout";
import { UserRole } from "../../../../../../../../platform/components/Page/Contracts";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { ContactsActions } from "../../../../../../redux/ContactsActions";

import * as PageSelectors from "../../../../../../../../platform/components/Page/redux/PageSelectors";
import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";
import * as  FeatureManagementSelectors from "../../../../../../../FeatureManagement/redux/FeatureManagementSelectors";

import "../../../ContactCard.scss"
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";
import { IRapAuthenticationService } from "../../../../../../../../platform/services/Authentication";

interface IContactPurchaseHistoryProvidedProps extends IRapComponentProperties {
}

interface IContactPurchaseHistoryOwnProps extends IContactPurchaseHistoryProvidedProps {
    selectedContactInsights: ICustomerInsightsDto;
    enablePurchaseHistory: boolean;
    userRole?: UserRole;
    contactPurchaseHistory: IPurchaseHistoryDto[];
    selectedContact: IContactDto;
}

interface IContactPurchaseHistoryState {
    columns: IColumn[];
    purchaseHistoryItems: IPurchaseHistoryDto[];
    isLoading: boolean;
    announcedMessage: string;
}

export type IContactPurchaseHistoryProps = ConnectedProps<typeof connector> & IContactPurchaseHistoryProvidedProps;

// This component HAS TO BE a class. 
// There are some weird data scope issues
// that happen with the DetailsList when this component is a FC. 
class ContactPurchaseHistoryInitializer extends RapComponent<IContactPurchaseHistoryProps, IContactPurchaseHistoryState> {

    public constructor(props: IContactPurchaseHistoryProps, context: IRapComponentContext) {
        super(props);
        this.state = {
            columns: this.getColumns(),
            purchaseHistoryItems: [],
            isLoading: false,
            announcedMessage :""
        }
    }

    public componentDidMount(): void {
        if(this.props.selectedContact?.email && this.props.enablePurchaseHistory) {
            this.setState({ isLoading: true });
            this.props.fetchPurchaseHistory(this.props.selectedContact?.email).then(h => {
                this.setState({ purchaseHistoryItems: h })
            }).catch(e => {
                // empty catch to stop 404's from propogating upwards.
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } 
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        const newColumns = this._updateColumnsState(column);
        const newItems = this._copyAndSort(this.state.purchaseHistoryItems, column.fieldName!, !column.isSortedDescending);
        this.setState({ columns: newColumns, purchaseHistoryItems: newItems });
    };

    private _updateColumnsState = (column: IColumn): IColumn[] => {
        const newColumns: IColumn[] = this.state.columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({ announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'}` });
            } else {
                newCol.isSorted = false;
            }
        });
        return newColumns;
    }

    private _copyAndSort = (items: IPurchaseHistoryDto[], columnKey: string, isSortedDescending?: boolean) => {
        const key = columnKey as keyof IPurchaseHistoryDto;
        const sortedItems = items.slice(0).sort((a, b) => {
            if (a[key] === b[key]) {
                return ((isSortedDescending ? a.purchaseDate < b.purchaseDate : a.purchaseDate > b.purchaseDate) ? 1 : -1);
            } else {
                return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1);
            }
        });
        return sortedItems;
    }

    private getColumns = (): IColumn[] => {
        return [
            {
                key: 'column1',
                name: 'Purchase date',
                fieldName: 'purchaseDate',
                minWidth: DetailListColumnSizes.xsmall,
                maxWidth: DetailListColumnSizes.medium,
                isRowHeader: true,
                data: 'date',
                onColumnClick: this._onColumnClick,
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1,
                onRender: (item: IPurchaseHistoryDto) => {
                    return moment(Math.floor(item.purchaseDate/1000)).format(DateFormat) // date is in microseconds
                }
            },
            {
                key: 'column2',
                name: 'Product SKU',
                fieldName: 'productSKU',
                minWidth: DetailListColumnSizes.small,
                maxWidth: DetailListColumnSizes_Max.small,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                onColumnClick: this._onColumnClick,
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            },
            {
                key: 'column3',
                name: 'Product name',
                fieldName: 'productTitle',
                minWidth: DetailListColumnSizes.small,
                maxWidth: DetailListColumnSizes_Max.small,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                onColumnClick: this._onColumnClick,
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            },
            {
                key: 'column4',
                name: 'Product variant',
                fieldName: 'productVariant',
                minWidth: DetailListColumnSizes.medium,
                maxWidth: DetailListColumnSizes_Max.large,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                onColumnClick: this._onColumnClick,
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            },
            {
                key: 'column5',
                name: 'Receipt ID',
                fieldName: 'receiptId',
                minWidth: DetailListColumnSizes.small,
                maxWidth: DetailListColumnSizes_Max.small,
                data: 'number',
                onColumnClick: this._onColumnClick,
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            }
        ];
    };

    public render() {
        return (
            <>
                <Card className="flex c-card">
                    <div className="flex-row">
                        <div className="c-card-header">
                            <div>
                                {localizedStrings.ContactsView?.profilePage.purchaseHistory.title}
                            </div>
                        </div>
                    </div>
    
                    <div className="c-card-body">
                        <LoadingContainer isLoading={this.state.isLoading}>
                            {/* TODO: Remove Coming Soon when data is generally available */}
                            {this.props.enablePurchaseHistory ? (
                                <>
                                    {this.state.purchaseHistoryItems.length > 0 ? (
                                        <DetailsList
                                            items={this.state.purchaseHistoryItems}
                                            columns={this.state.columns}
                                            isHeaderVisible={true}
                                            checkboxVisibility={CheckboxVisibility.hidden}
                                            selectionMode={SelectionMode.none}
                                        />
                                    ) : (
                                        <NoResults description={localizedStrings.ContactsView?.profilePage?.purchaseHistory?.notFound}/>
                                    )}
                                </>
                            ) : (
                                <ComingSoon description="Purchase history is coming soon!" />
                            )}
                        </LoadingContainer>
                    </div>
                </Card>
            </>
        );   
    }
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactPurchaseHistoryProvidedProps): Partial<IContactPurchaseHistoryOwnProps> {
    return {
        ...providedProps,
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights,
        enablePurchaseHistory: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnablePurchaseHistory", false),
        userRole: PageSelectors.getUserData(state)?.userRole,
        contactPurchaseHistory: ContactsSelectors.getCustomerInsightsData(state)?.purchaseHistory,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchPurchaseHistory: ContactsActions.fetchContactPurchaseHistory
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactPurchaseHistory = connector(ContactPurchaseHistoryInitializer);
