import React from "react";
import { connect } from "react-redux";
import { allTimezones } from "react-timezone-select";
import { IconButton, Label, Modal, PrimaryButton, Separator, Stack, TextField, SpinnerSize, Link, Checkbox } from "@fluentui/react";
import moment from "moment-timezone";

import AppointmentDatePickerInitializer from "./components/AppointmentDatePicker/AppointmentDatePicker";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../platform/Layout";
import { IClientelingViewState } from "../../../pages/Contracts";
import { AppointmentTypeOptionPickerInitializer } from "./components/AppointmentTypeOptionPicker/AppointmentTypeOptionPicker";
import { AppointmentCategoryOptionPickerInitializer } from "./components/AppointmentTopicOptionPicker/AppointmentCategoryOptionPicker";
import { PageActions } from "../../../platform/components/Page/redux/PageActions";
import { localizedStrings } from "../../localization/LocalizedStrings";
import { DialogHeader } from "../DialogHeader/DialogHeader";
import {
    IAppointmentCustomResponseDto,
    AppointmentCustomResponseDto,
    IAppointmentDetail, 
    IAppointmentForCreationDto, 
    IAppointmentForUpdateDto, 
    IAppointmentType, 
    IContactDto, 
    ITimeSlotDto, 
    User, 
    IAppointmentCategory,
    ICase,
    IMRSEmployee,
    IAssociate
} from "../../../contracts/swagger/_generated";
import { CreateEditFormSections } from "../../Constants";
import { AssignedToSearchBox } from "./components/AssignedToSearchBox/AssignedToSearchBox";
import { ClientSearchBox } from "./components/ClientSearchBox/ClientSearchBox";
import { AppointmentCustomResponse } from "./components/AppointmentResponses/AppointmentResponses";
import { PermissionGate } from "../PermissionGate/PermissionGate";
import { UserRole } from "../../../platform/components/Page/Contracts";
import { AddClientModal } from "./components/AddClientModal/AddClientModal";
import { DynamicsCaseSelectorModal } from "./components/DynamicsCaseSelector/DynamicsCaseSelector";
import { AppointmentModalActions } from "./redux/AppointmentModalActions";
import { DynamicsCase } from "./components/DynamicsCase/DynamicsCase";

import * as Constants from "../../Constants";
import * as AppointmentModalSelectors from "./redux/AppointmentModalSelectors";
import * as PageSelectors from "../../../platform/components/Page/redux/PageSelectors";
import * as FeatureManagementSelectors from "../../../views/FeatureManagement/redux/FeatureManagementSelectors";
import "./CreateEditAppointmentModal.scss";
import { LoadingContainer } from "../LoadingContainer/LoadingContainer";

//Props passed by parent component
interface ICreateEditAppointmentProvidedProps extends IRapComponentProperties {
    onDismiss: () => void;
    onSave: () => void;
    onUpdate: (val: Partial<ICreateEditAppointmentFormValues>, then?: () => void) => void;
    isOpen: boolean;
    defaultValues?: IAppointmentDetail;
    flow: Constants.AppointmentFlows;
    isLoading?: boolean;
}

interface ICreateEditAppointmentOwnProps extends ICreateEditAppointmentProvidedProps {
    appointmentTypes: IAppointmentType[];
    appointmentCategories: IAppointmentCategory[];
    storeId: string;
    selectedDate: Date;
    appointmentCustomResponses: IAppointmentCustomResponseDto[];
    enableBsl: boolean;
    isAutoAssignEnabled: boolean;
}

interface ICreateEditAppointmentState {
    form: ICreateEditAppointmentFormValues;
    queuedFormChanges: Partial<ICreateEditAppointmentFormValues>;
    selectedTimezone?: string;
    selectedTimeKey?: string;
    selectedContact?: IContactDto;
    selectedAssociate?: IAssociate;
    associatesAvailableForAppointment?: User[];
    selectedDynamicsCase?: ICase;
    agreeToAge?: boolean;
}

export interface ICreateEditAppointmentFormValues extends IAppointmentForCreationDto, IAppointmentForUpdateDto {
    appointmentDate: Date | undefined;
    subject: string | undefined;
}

export type ICreateEditAppointmentProps = ICreateEditAppointmentOwnProps & typeof ActionsToDispatch;

/**
 *  How this class is designed/works:
 * 
 *  The point of this class is to manage the modal that creates/edits an appointment. The state/values of the form
 *  components are managed internally in this component. There are two different state properties that keep track of form changes:
 * 
 *      1. form - This is the main state variable for the form. It tracks the current value for every control in the modal. Every time
 *                a control is updated, the form gets updated in state to reflect the most current value. If there are default values for 
 *                the form (when editing an appointment) the form variable is initialized with these values. 
 * 
 *      2. queuedFormChanges - These are all the changes that have occured in the form since the last time onUpdate was called. It does
 *                             not contain all the values of the entire modal, only the ones that have been updated. When onUpdate is called,
 *                             we pass the queuedFormChanges and then reset it. The benefit of this approach is the user of the modal can see
 *                             exactly what fields have been updated each time, instead of passing the entire form to them.
 * 
 *  The form and queuedFormChanges are a combined type comprising IAppointmentForCreationDto and IAppointmentForUpdateDto. This allows us
 *  to have a type that can handle both create and update without knowing specifically which scenario the parent component is using the modal for.
 *  When onUpdate is called, we pass the parent component this combined type, and then they can cast it to either IAppointmentForCreation or
 *  IAppointmentForUpdate depending on which flow they want.
 * 
 *  The form is split into 5 different sections: Type, Topic, Date, Time, and Info. Each of these sections are comprised of
 *  one or more controls. The determining factor for a control or group of controls being classed into a new section is whether
 *  changing that control/section will affect other sections. For example, when you change the type, it requires the topic/date/time 
 *  to be re-selected, so it makes sense that its its own separate section. However, when you update the client, assigned to, or description
 *  fields, it doesnt affect any other sections, so those are all grouped into the Info section.
 * 
 *  There are two basic data flows for each control. Any new controls added should use these same flows:
 * 
 *      Flow 1: The user changes a field value (onChange/onSelect)
 *          1. User updates a field value
 *          2. The onSelect/onChange function calls the updateForm function with its updated values and section.
 *          3. The updateForm function updates both form and queuedFormChanges with the newest values and then calls the resetForm function. 
 *          4. Depending on the section, the resetForm function resets values further down the form. For example, if type is changed,
 *             we need to reset topic, date, and time because all of those depend on the type.
 *          
 *          Note: In this flow only the local state value is updated. We do not hit the onUpdate function to notify the parent component that the 
 *                form has been updated yet. 
 * 
 *      Flow 2: The user changes a field value and un-focuses the component (onBlur)
 *          1. The user updates a field value AND unfocuses the control
 *          2. The onBlur function calls onUpdateCallback, which in turn calls the onUpdate function and provides the queuedFormChanges.
 *          3. The queuedFormChanges are reset.
 * 
 *  The benefit of having two separate flows is we only notify the parent component when the field is done being changed. For example,
 *  if we're typing "test" into a text field, we only want to notify the parent with the new value after "test" is completely typed out, 
 *  not after "t", "te", "tes", "test". 
 */
class CreateEditAppointmentInitializer extends RapComponent<ICreateEditAppointmentProps, ICreateEditAppointmentState> {
    constructor(props: ICreateEditAppointmentProps, context: IRapComponentContext) {
        super(props, context);
        this.state = { 
            form: {
                customerFirstName: props.defaultValues ? props.defaultValues.appointmentDetails?.customerFirstName : "",
                customerLastName: props.defaultValues ? props.defaultValues.appointmentDetails?.customerLastName : "",
                subject: props.defaultValues ? props.defaultValues.appointmentDetails?.subject : "",
                appointmentDate: props.defaultValues ? props.defaultValues.appointmentDetails?.appointmentDate : moment(props.selectedDate).toDate(),
                customerEmail: props.defaultValues ? props.defaultValues.appointmentDetails?.customerEmail : "",
                appointmentTypeId: props.defaultValues ? props.defaultValues.appointmentDetails?.appointmentType?.appointmentTypeId : "",
                appointmentCategoryId: props.defaultValues ? props.defaultValues.appointmentDetails?.appointmentCategory?.categoryId : "",
                descriptionOfIssue: props.defaultValues ? props.defaultValues.appointmentDetails?.descriptionOfIssue : "",
                ownerId: props.defaultValues? props.defaultValues.appointmentDetails?.ownerId : "",
                bookableResourceId: props.defaultValues ? props.defaultValues.assignedTo?.userInfo?.bookableResourceId : "",
                poboOrderId: props.defaultValues ? props.defaultValues.appointmentDetails?.poboOrderId : "",
                customResponses: props.defaultValues ? props.defaultValues.appointmentDetails.customResponses : [],
                bslInterpreterRequested: props.defaultValues ? props.defaultValues.appointmentDetails.bslInterpreterRequested : false,
                accessibilityRequested: props.defaultValues ? props.defaultValues.appointmentDetails.accessibilityRequested : false,
                accessibilityDescription: props.defaultValues ? props.defaultValues.appointmentDetails.accessibilityDescription : ""
            },
            queuedFormChanges: {},
            selectedTimezone: moment.tz.guess(),
            selectedTimeKey: props.defaultValues ? props.defaultValues?.appointmentDetails?.appointmentDate?.toUTCString() : undefined,
            selectedContact: props.defaultValues ? props.defaultValues?.appointmentContact : undefined,
            selectedAssociate: props.defaultValues ? 
                this.mrsEmployeeToAssociate(props.defaultValues?.assignedTo, props.defaultValues?.appointmentDetails?.ownerId) : undefined,
            selectedDynamicsCase: props.defaultValues ? props.defaultValues?.appointmentDetails?.case : undefined,
            agreeToAge: false
        }
    }

    public componentDidUpdate(prevProps: Readonly<ICreateEditAppointmentProps>, prevState: Readonly<ICreateEditAppointmentState>, snapshot?: {}): void {
        // If modal WAS open but is NOW closed -> if modal creating an appt -> reset the form
        if (prevProps.isOpen === true && this.props.isOpen === false) {
            this.resetModalIfCreating();
        }
    }

    private updateForm = (section: CreateEditFormSections, val: Partial<ICreateEditAppointmentFormValues>, then?: () => void) => {
        this.setState({
            form: {...this.state.form, ...val}, 
            queuedFormChanges: {...this.state.queuedFormChanges, ...val}
        }, () => this.resetForm(section, then));
    }

    /*
        The point of this switch is to reset the following sections of the form when a given section is changed.
        For example, if the type is changed, we need to reset the topic, date, etc. because all of those things 
        change with the type.

        The break statements are intentionally left out of this switch. Without the breaks, the switch mimics the 
        desired behavior of the form: resetting everything below the changed section. As such, items in the form
        only need to be reset once, at the lowest point necessary. 
    */
    private resetForm = (section: CreateEditFormSections, then: () => void) => {
        let state: Partial<ICreateEditAppointmentState> = {};
        let form: Partial<ICreateEditAppointmentFormValues> = {};
        switch(section) {
            // @ts-ignore
            case CreateEditFormSections.All: {
                form.appointmentTypeId = undefined;
                state.selectedContact = undefined;
                form.descriptionOfIssue = undefined;
                form.accessibilityRequested = undefined;
                form.bslInterpreterRequested = undefined;
                state.agreeToAge = false;
            }
            // @ts-ignore
            case CreateEditFormSections.Type: {
                form.appointmentCategoryId = undefined;
                form.appointmentDate = undefined;
                state.selectedTimeKey = undefined;
                form.formattedAppointmentDate = undefined;
            }
            // @ts-ignore
            case CreateEditFormSections.Topic: {
                form.customResponses = undefined;
                this.props.clearAppointmentCustomResponses();
            }
            case CreateEditFormSections.Time: {
                form.ownerId = undefined;
                form.bookableResourceId = undefined;
            }
        }
        this.setState({
            ...this.state, 
            ...state, 
            form: {...this.state.form, ...form},
            queuedFormChanges: { ...this.state.queuedFormChanges, ...form}
        }, then);
    }

    private resetModalIfCreating = () => {
        if (this.props.flow == Constants.AppointmentFlows.Create) {
            this.resetForm(CreateEditFormSections.All, null);
        }
    }

    private onUpdateCallback = () => {
        this.props.onUpdate(this.state.queuedFormChanges);
        this.setState({queuedFormChanges: {}});
    }

    private onTypeChanged = (form: Partial<ICreateEditAppointmentFormValues>) => {
        this.updateForm(CreateEditFormSections.Type, form);
    }

    private onCategoryChanged = (form: Partial<ICreateEditAppointmentFormValues>) => {
        this.updateForm(CreateEditFormSections.Topic, form);
        this.props.fetchAppointmentCustomResponses(this.props.storeId, this.state.form.appointmentTypeId, form.appointmentCategoryId);
    }

    private mrsEmployeeToAssociate = (e: IMRSEmployee, ownerId: string) => {
        if(!e) return undefined;
        
        let name = e.userInfo?.fullName.split("\\s");
        return {
            firstName: name[0],
            lastName: name[name.length - 1],
            businessUnitId: e.userInfo?.assignedStoreNumber,
            storeNumber: e.userInfo?.assignedStoreNumber,
            email: e.userInfo?.fullEmail,
            bookableResourceId: e.userInfo?.bookableResourceId,
            profileImage: e.profileImage,
            systemUserId: ownerId
        } as IAssociate
    }

    private onTimeChanged = (time: ITimeSlotDto) => {
        if(!time) {
            this.setState({selectedTimeKey: undefined, form: {...this.state.form, appointmentDate: undefined}});
            return;
        }

        const dateFormat = localizedStrings.CreateEditAppointmentModal?.dateFormat;
        let selectedTimezone = this.state.selectedTimezone;
        const start = moment.tz(time.startTime, selectedTimezone);
        const end = moment.tz(time.endTime, selectedTimezone);
        
        if (time.startTime.valueOf() === time.endTime.valueOf()) { // only schedule auto assign timeslots will return end time
            let service = this.props.appointmentTypes.find(type => type.appointmentTypeId === this.state.form.appointmentTypeId);
            let durationInMinutes = service?.duration;
            end.add(durationInMinutes, 'minutes');
        }

        // Build formatted time
        const timezoneOffset = `(GMT${start.format("Z")})`;
        const timezoneName = allTimezones[selectedTimezone] ? allTimezones[selectedTimezone] : start.zoneAbbr();
        const formattedDate = `${start.format(dateFormat)} ${start.format(Constants.TimeFormat)} - ${end.format(Constants.TimeFormat)} ${timezoneOffset} ${timezoneName}`;

        this.updateForm(CreateEditFormSections.Date, { appointmentDate: time.startTime, formattedAppointmentDate: formattedDate }, () => {
            this.setState({ associatesAvailableForAppointment: time.users, selectedTimeKey: time.startTime.toUTCString() });
        });
    }

    private onContactSelected = (contact: IContactDto) => {
        if(!contact) {
            this.updateForm(CreateEditFormSections.Info, {
                customerFirstName: undefined,
                customerLastName: undefined,
                customerEmail: undefined,
                customerPhone: undefined
            }, () => this.setState({selectedContact: undefined}));
            return; 
        }

        this.updateForm(CreateEditFormSections.Info, { 
            customerFirstName: contact.firstName, 
            customerLastName: contact.lastName,
            customerEmail: contact.email,
            customerPhone: contact.phone,
        }, () => this.setState({ selectedContact: contact })); 
    }

    private onAssociateSelected = (associate: IAssociate) => {
        if(!associate) {
            this.updateForm(CreateEditFormSections.Info, {
                ownerId: undefined,
                bookableResourceId: undefined
            }, () =>  this.setState({ selectedAssociate: associate }));
            return;
        }

        this.updateForm(CreateEditFormSections.Info, {
            ownerId: associate.systemUserId,
            bookableResourceId: associate.bookableResourceId
        }, () => this.setState({ selectedAssociate: associate }));
    }

    private setCustomResponses = (item: IAppointmentCustomResponseDto[]) => {
        this.updateForm(CreateEditFormSections.Info, { customResponses: item as AppointmentCustomResponseDto[] });
    }

    private isSaveButtonEnabled = () => {
        let form = this.state.form;

        let showSaveButton = false;

        showSaveButton = form.appointmentTypeId && 
            form.appointmentCategoryId &&
            form.appointmentDate &&
            form.customerFirstName && // first name, last name, email are all set at the same time, so just checking if one is set
            form.descriptionOfIssue &&
            !this.props.isLoading;

        if (this.props.enableBsl && form.accessibilityRequested === true) {
            showSaveButton = showSaveButton && (form.accessibilityDescription !== "");
        }
        if (form.customResponses) {
            form.customResponses.forEach(item => {
                if (item.isRequired === true) {
                    if (item.response === "" || item.response === undefined) {
                        showSaveButton = false; //If any are not set, then set to false
                    }
                }
            });
        }
        if(this.props.flow === Constants.AppointmentFlows.Create){
            showSaveButton = showSaveButton && this.state.agreeToAge;
        }

        return showSaveButton;
    }

    private onChangeAgreedToAge = () => {
        this.setState({agreeToAge: !this.state.agreeToAge});
    }

    private onSave = () => {
        if(this.props.isAutoAssignEnabled && !this.state.form?.bookableResourceId && this.state.associatesAvailableForAppointment) {
            this.updateForm(
                CreateEditFormSections.Info, 
                { bookableResourceId: this.state.associatesAvailableForAppointment[0]?.bookableResourceId },
                () => this.props.onUpdate(this.state.queuedFormChanges, () => this.props.onSave())
            );
        }
        else {
            this.props.onSave();
        }
    }

    private onCreateContactClick = () => {
        this.props.updateModals({ displayAddClientModal: true });
    }

    private isDynamicsCaseRendered = () => {
        if(this.props.appointmentTypes && this.props.appointmentCategories && this.state.form?.appointmentTypeId && this.state.form?.appointmentCategoryId) {
            let type = this.props.appointmentTypes?.find(x => x.appointmentTypeId == this.state.form?.appointmentTypeId);
            let category = this.props.appointmentCategories?.find(x => x.categoryId == this.state.form?.appointmentCategoryId);

            if(type && category && type.name?.toLowerCase().includes(Constants.AppointmentType_HardwareSupport) && 
                category.name?.toLowerCase().includes(Constants.CategoryType_PickUp)) {
                    return true;
            }
            return false;
        }
        return false;
    }

    private onDynamicsCaseSelected = (val: ICase) => {
        if(val) {
            this.updateForm(
                CreateEditFormSections.Info, 
                { linkedIncidentId: val.caseId }, 
                () => this.setState({ selectedDynamicsCase: val }, () => this.onUpdateCallback())
            );
        }
    }

    private renderAccessibilityDescription = () => {
        if (!this.state.form.accessibilityRequested) {
            return <></>
        }

        return (
            <div className="flex-row c-row">
                <TextField
                    maxLength={Constants.DescriptionCharLength}
                    label={localizedStrings.CreateEditAppointmentModal.accessibilityDesc}
                    className="c-column-wide"
                    multiline
                    rows={3} 
                    required
                    value={this.state.form.accessibilityDescription}
                    disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                    onChange={(event, val) => this.updateForm(CreateEditFormSections.Info, { accessibilityDescription: val })}
                    onBlur={this.onUpdateCallback}
                />
            </div>
        )
    }

    public render() {
        const styles = {
            root: [{
                selectors: {
                    '::before': {
                        background: '#7B83EB',
                    },
                }
            }]
        };
        return (
            <Modal
                containerClassName="c-create-modal"
                isBlocking={false}
                isOpen={this.props.isOpen}
                onDismiss={this.props.onDismiss}
            >
                <div className="flex-row c-header-label">
                    <DialogHeader title={this.props.flow == Constants.AppointmentFlows.Edit ? localizedStrings.CreateEditAppointmentModal.editAppointment : localizedStrings.CreateEditAppointmentModal.createAppointment}/>
                    <IconButton
                        className="c-icon-cancel"
                        iconProps={{ iconName: 'Cancel' }}
                        ariaLabel="Close popup modal"
                        onClick={this.props.onDismiss}
                    />
                </div>
                <Separator styles={styles}></Separator>
                <div className="flex-row">
                    <div className="c-createEdit-modal-column">
                        <div className="flex-row c-row">
                            <div className="flex-column c-column-1">
                                <AppointmentTypeOptionPickerInitializer
                                    onSelect={(type) => this.onTypeChanged({ appointmentTypeId: type.key as string}) }
                                    onBlur={this.onUpdateCallback}
                                    value={this.state.form?.appointmentTypeId}
                                    disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                />
                            </div>
                            <div className="flex-column c-column-2">
                                <AppointmentCategoryOptionPickerInitializer 
                                    onSelect={(category) => this.onCategoryChanged({ appointmentCategoryId: category.key as string }) }
                                    appointmentTypeId={this.state.form.appointmentTypeId}
                                    onBlur={this.onUpdateCallback}
                                    value={this.state.form?.appointmentCategoryId}
                                    disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                />
                            </div>
                        </div>
                        <PermissionGate
                            minimumUserRole={this.props.flow == Constants.AppointmentFlows.Edit ? UserRole.Admin : UserRole.Associate}
                            disableInteadOfHide>
                            <AppointmentDatePickerInitializer
                                onTimezoneSelect={(tz) => this.setState({ selectedTimezone: tz.value })}
                                onTimeSelect={this.onTimeChanged}
                                appointmentTypeId={this.state.form.appointmentTypeId}
                                onBlur={this.onUpdateCallback}
                                defaultTimezone={this.state.selectedTimezone}
                                defaultDate={this.state.form.appointmentDate}
                                defaultTime={this.props.defaultValues?.appointmentDetails?.appointmentDate}
                                flow={this.props.flow}
                                timeValue={this.state.selectedTimeKey}
                            />
                        </PermissionGate>
                        <div className="flex-row c-row">
                            <Stack className="c-column-1">
                                <Stack.Item>
                                    <Label htmlFor="assignedToSearch">
                                        {localizedStrings.CreateEditAppointmentModal.assignedTo}
                                    </Label>
                                </Stack.Item>
                                <Stack.Item>
                                    <AssignedToSearchBox
                                        id="assignedToSearch"
                                        availableAssociates={this.state.associatesAvailableForAppointment}
                                        onSelect={this.onAssociateSelected}
                                        value={this.state.selectedAssociate}
                                        onBlur={this.onUpdateCallback}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack className="c-column-2">
                                <Stack.Item>
                                    <Label htmlFor="clientSearch" required>
                                        {localizedStrings.CreateEditAppointmentModal.client}
                                    </Label>
                                </Stack.Item>
                                <Stack.Item>
                                    <ClientSearchBox
                                        id="clientSearch"
                                        onBlur={this.onUpdateCallback}
                                        onSelect={this.onContactSelected}
                                        value={this.state.selectedContact}
                                        defaultValue={this.props.defaultValues?.appointmentContact}
                                        placeholder={localizedStrings.CreateEditAppointmentModal.clientPlaceholderText}
                                        disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                    />
                                    {this.props.flow == Constants.AppointmentFlows.Create && (
                                        <>
                                            <Link ariaLabel={localizedStrings.CreateEditAppointmentModal.createContact} onClick={this.onCreateContactClick}>
                                                {localizedStrings.CreateEditAppointmentModal.createContact}
                                            </Link>
                                            <AddClientModal onContactCreated={(contact: IContactDto) => this.onContactSelected(contact)}/>
                                        </>

                                    )}
                                </Stack.Item>
                            </Stack>
                        </div>
                        {this.isDynamicsCaseRendered() && (
                            <>
                                <div className="flex-row c-row">
                                    <Stack>
                                        <Stack.Item>
                                            <DynamicsCase case={this.state.selectedDynamicsCase}/>
                                            <Link 
                                                aria-label={localizedStrings.CreateEditAppointmentModal.linkCase} 
                                                onClick={() => this.props.updateModals({ displayDynamicsCaseSelectorModal: true })}
                                                disabled={!this.state.selectedContact}
                                                >
                                                    {localizedStrings.CreateEditAppointmentModal.linkCase}
                                            </Link>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                                <DynamicsCaseSelectorModal 
                                    contactId={this.state.selectedContact?.contactId}
                                    onCaseSelected={this.onDynamicsCaseSelected}
                                />
                            </>
                        )}
                        {this.props.flow == Constants.AppointmentFlows.Edit && (
                            <div className="flex-row c-row">
                                <TextField
                                    maxLength={Constants.PoboCharLength}
                                    label={localizedStrings.CreateEditAppointmentModal.poboLabel}
                                    className="c-column-wide"
                                    onBlur={this.onUpdateCallback}
                                    value={this.state.form.poboOrderId}
                                    onChange={(event, val) => this.updateForm(CreateEditFormSections.Info, { poboOrderId: val })}
                                />
                            </div>
                        )}
                        <div className="flex-row c-row">
                            <TextField
                                required
                                maxLength={Constants.DescriptionCharLength}
                                label={localizedStrings.CreateEditAppointmentModal.description}
                                className="c-column-wide"
                                multiline
                                rows={4} 
                                onChange={(event, val) => this.updateForm(CreateEditFormSections.Info, { descriptionOfIssue: val })}
                                onBlur={this.onUpdateCallback}
                                value={this.state.form.descriptionOfIssue}
                                disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                            />
                        </div>
                        {this.props.enableBsl && (
                            <>
                                <div className="flex-row c-row">
                                    <Checkbox
                                        label={localizedStrings.CreateEditAppointmentModal.bslCheckbox}
                                        ariaLabel={localizedStrings.CreateEditAppointmentModal.bslCheckbox}
                                        onChange={(event, val) => this.updateForm(CreateEditFormSections.Info, { bslInterpreterRequested: val })}
                                        defaultChecked={this.state.form.bslInterpreterRequested}
                                        disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                    />
                                </div>
                                <div className="flex-row c-row">
                                    <Checkbox
                                        label={localizedStrings.CreateEditAppointmentModal.accessibilityRequested}
                                        ariaLabel={localizedStrings.CreateEditAppointmentModal.accessibilityRequested}
                                        defaultChecked={this.state.form.accessibilityRequested}
                                        disabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                        onChange={(event, val) => this.updateForm(CreateEditFormSections.Info, { accessibilityRequested: val })}
                                    />
                                </div>
                                {this.renderAccessibilityDescription()}
                            </>
                        )}
                                {this.props.flow == Constants.AppointmentFlows.Create &&(
                                <div className="flex-row c-row">
                                    <Checkbox
                                        label={localizedStrings.CreateEditAppointmentModal.agreeToAge}
                                        onRenderLabel={() => 
                                            <div className="c-agree-label">
                                                {localizedStrings.CreateEditAppointmentModal.agreeToAge}
                                                <span className="c-required"> *</span>
                                            </div>
                                        }
                                        checked={this.state.agreeToAge}
                                        onChange={this.onChangeAgreedToAge}
                                    />
                                </div>)}
                    </div>

                    <div className="c-createEdit-modal-column">
                        {(this.props.appointmentCustomResponses && this.props.appointmentCustomResponses.length > 0) ? (
                        <>
                            <div className="c-custom-responses">
                                <AppointmentCustomResponse
                                    onBlur={this.onUpdateCallback}
                                    onSelect={this.setCustomResponses}
                                    appointmentCustomResponses={this.props.appointmentCustomResponses}
                                />
                            </div>
                        </>
                        ) : (this.state.form.customResponses && this.state.form.customResponses.length > 0) ? (
                        <>
                            <div className="c-custom-responses">
                                <AppointmentCustomResponse
                                    className="c-custom-responses"
                                    onBlur={this.onUpdateCallback}
                                    onSelect={this.setCustomResponses}
                                    appointmentCustomResponses={this.state.form.customResponses}
                                    isDisabled={this.props.flow == Constants.AppointmentFlows.Edit}
                                />
                            </div>
                        </>
                        ) : null}
                    </div>

                </div>
                    <div className="flex-row c-footer-container">
                        <PrimaryButton 
                            onClick={this.onSave}
                            disabled={!this.isSaveButtonEnabled()}
                            >
                            <LoadingContainer isLoading={this.props.isLoading} spinnerSize={SpinnerSize.small}>
                                <>{localizedStrings.CreateEditAppointmentModal.save}</>
                            </LoadingContainer>
                        </PrimaryButton>
                    </div>
            </Modal>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICreateEditAppointmentProvidedProps): Partial<ICreateEditAppointmentOwnProps> {
    return {
        ...providedProps,
        appointmentTypes: AppointmentModalSelectors.getResources(state)?.appointmentTypes,
        appointmentCategories: AppointmentModalSelectors.getResources(state)?.appointmentCategories,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        selectedDate: PageSelectors.getViewOptions(state)?.selectedDate,
        appointmentCustomResponses: AppointmentModalSelectors.getAppointmentCustomResponses(state),
        enableBsl: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableBsl", false),
        isAutoAssignEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableScheduleAutoAssignment")
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    fetchTimeslots: AppointmentModalActions.fetchAppointmentTimeslots,
    fetchAppointmentCustomResponses: AppointmentModalActions.fetchAppointmentCustomResponses,
    clearAppointmentCustomResponses: AppointmentModalActions.clearAppointmentCustomResponses
};

export const CreateEditAppointmentModal = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(CreateEditAppointmentInitializer);
