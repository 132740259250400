import React, { FC, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Persona, PersonaSize, Text, IconButton, IContextualMenuProps, IContextualMenuItem, Link, Icon, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment-timezone";

import { IRapComponentProperties } from "../../../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../../../pages/Contracts";
import { IContactNote, IStoreAssociateDto } from "../../../../../../../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../../../../../../../../common/localization/LocalizedStrings";
import { ContactsActions } from "../../../../../../../../redux/ContactsActions";
import { PageActions } from "../../../../../../../../../../platform/components/Page/redux/PageActions";

import ContactNoteEditor from "../ContactNoteEditor/ContactNoteEditor";

import * as PageSelectors from "../../../../../../../../../../platform/components/Page/redux/PageSelectors";

import "./ContactNote.scss";

//Props passed by parent component
interface IContactNoteProvidedProps extends IRapComponentProperties {
    contactId?: string
    contactNote?: IContactNote;
    className?: string;
}

interface IContactNoteOwnProps {
    user: IStoreAssociateDto;
}

export type IContactNoteProps = ConnectedProps<typeof connector> & IContactNoteProvidedProps;

const ContactNoteInitializer: FC<IContactNoteProps> = (props) => {

    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [htmlContent, setHtmlContent] = useState(props.contactNote.body);

    const onDelete = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
        setIsLoading(true);
        props.deleteNote(props.contactId, props.contactNote?.noteId).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.deleteSuccess });
        }).catch((error: string) => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.deleteFailure });
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const onSaveUpdate = () => {
        setIsLoading(true);
        props.editNote(props.contactId, props.contactNote?.noteId, { title: props.contactNote?.title, body: htmlContent }).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.updateSuccess });
        }).catch((error: string) => {
            props.updateMessages({ toastMessage: localizedStrings.ContactsView.contactNotes.updateFailure });
            console.error(error);
        }).finally(() => {
            setIsLoading(false);
            setIsEditModeEnabled(false);
        })
    }

    const onEdit = (ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => {
        setIsEditModeEnabled(true);
    }

    const menuItems: IContextualMenuProps = {
        items: [
            {
                key: "editNote",
                text: localizedStrings.ContactsView.contactNotes.edit,
                iconProps: { iconName: "edit"},
                onClick: onEdit
            },
            {
                key: "deleteNote",
                text: localizedStrings.ContactsView.contactNotes.delete,
                iconProps: { iconName: "delete" },
                onClick: onDelete
            }
        ]
    }

    const getDate = () => {
        if(props.contactNote?.createdOn) {
            return moment(props.contactNote?.createdOn).format("MMM Do, YYYY")
        }
        return "";
    }

    const getContent = () => {
        if(isEditModeEnabled) {
            if(isLoading) {
                return (
                    <>
                        <ContactNoteEditor 
                            htmlContent={htmlContent}
                            isLoading={isLoading}
                            onChange={ (val: string) => setHtmlContent(val) }
                        />
                        <div className="flex-column c-edit-controls">
                            <Spinner size={SpinnerSize.medium}/>
                        </div>
                    </>
                )
            }
            else {
                return (
                    <>
                        <ContactNoteEditor 
                            htmlContent={htmlContent}
                            isLoading={isLoading}
                            onChange={ (val: string) => setHtmlContent(val) }
                            isFocused
                        />
                        <div className="flex-column c-edit-controls">
                            <Link className="flex" onClick={ () => onSaveUpdate() }>
                                <Icon iconName="CheckMark"/>
                            </Link>
                            <Link className="flex" onClick={ () => setIsEditModeEnabled(false) }>
                                <Icon iconName="Cancel"/>
                            </Link>
                        </div>
                    </>
                );
            }
        } else {
            if(isLoading) {
                return (
                    <>
                        <div className="c-note-body flex" dangerouslySetInnerHTML={{__html: props.contactNote?.body}}></div>
                        <Spinner size={SpinnerSize.medium}/>
                    </>
                );
            }
            else {
                return (
                    <>
                        <div className="c-note-body flex" dangerouslySetInnerHTML={{__html: props.contactNote?.body}}></div>
                        {true && ( //props.user?.systemUserId == props.contactNote?.creator?.systemUserId && ( // TODO: uncomment once create actually works
                            <IconButton 
                                className="c-dropdown-menu" 
                                menuProps={menuItems} 
                                iconProps={{iconName: "more"}}
                                ariaLabel="Note dropdown"
                            />
                        )}
                    </>
                );
            }
        }
    }

    return (
        <div className={`${props.className} flex-row c-note-container`}>
            <Persona 
                imageInitials={`${props.contactNote?.creator?.firstName?.charAt(0)}${props.contactNote?.creator?.lastName?.charAt(0)}`}
                imageAlt={"user image"}
                imageUrl={props.contactNote?.creator?.profileImage}
                size={PersonaSize.size32}
                className="c-persona"
            />
            <div className="flex-column flex">
                <div className="flex">
                    <Text className="c-note-header">{`${props.contactNote?.creator?.firstName} ${props.contactNote?.creator?.lastName}`}</Text>
                    <Text className="c-note-info">{getDate()}</Text>
                </div>
                <div className="flex-row flex">
                    { getContent() }
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactNoteProvidedProps): Partial<IContactNoteOwnProps> {
    return {
        ...providedProps,
        user: PageSelectors.getUserData(state)?.user
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    deleteNote: ContactsActions.deleteContactNote,
    editNote: ContactsActions.editContactNote,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ContactNoteInitializer)