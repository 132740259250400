import React, {FC} from 'react';
import { connect } from 'react-redux';

import { IClientelingViewState } from "../../../pages/Contracts";
import { UserRole } from '../../../platform/components/Page/Contracts';
import { IRapComponentProperties } from '../../../platform/Layout';

import * as PageSelectors from "../../../platform/components/Page/redux/PageSelectors";


//Props passed by parent component
interface IPermissionGateProvidedProps extends IRapComponentProperties {
    minimumUserRole: UserRole;
    disableInteadOfHide?: boolean;
}

interface IPermissionGateOwnProps extends IPermissionGateProvidedProps {
    userRole: UserRole;
}

export type IPermissionGateProps = IPermissionGateOwnProps & typeof ActionsToDispatch;

const PermissionGateInitializer: FC<IPermissionGateProps> = (props) => {
    if(props.userRole >= props.minimumUserRole) {
        return <>{props.children}</>
    }
    else {
        if(props.disableInteadOfHide && React.isValidElement(props.children)) {
            return <>{ React.cloneElement(props.children, { ...props.children.props, disabled: true }) }</>
        }
        return <></>
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IPermissionGateProvidedProps): Partial<IPermissionGateOwnProps> {
    return {
        ...providedProps,
        userRole: PageSelectors.getUserData(state)?.userRole
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {

};

export const PermissionGate = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(PermissionGateInitializer);