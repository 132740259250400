import React from "react";
import { RapComponent } from "./../../platform/Layout";
import { ConnectedReservationsViewInitializer } from "./ReservationsViewInitializer";

export class ReservationsView extends RapComponent {
    public render() {
        return (
            <ConnectedReservationsViewInitializer />
        );
    }
}