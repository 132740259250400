import * as React from "react";
import $ from "jquery";
import {
    LeanRooster,
    EditorViewState,
    RoosterCommandBar,
    RoosterCommandBarPlugin,
    ImageResize,
    EditorPlugin,
    EmojiPlugin,
    UndoWithImagePlugin,
    ImageManager,
    RoosterCommandBarButton,
    LeanRoosterModes as PackageLeanRoosterModes,
    ImageManagerOptions,
    RoosterCommmandBarButtonKeys,
    RoosterCommandBarPluginOptions,
    EmojiPluginOptions,
    EmojiPaneProps,
    FocusOutShell,
    FocusEventHandler,
    isNodeEmpty,
    fromHtml,
    PasteImagePlugin,
    IgnorePasteImagePlugin,
    ContentChangedPlugin,
    TableResize,
    DoubleClickImagePlugin
} from "roosterjs-react";
import { HtmlSanitizer, HtmlSanitizerOptions } from "roosterjs-html-sanitizer";
import { CommandBarLocaleStrings, SearchForEmoji } from "./HtmlEditor.Strings";
import { IHtmlEditorProps } from "./HtmlEditor.Props";
import { KeyDownPlugin } from "./Plugins/KeyDownPlugin";
import { TimerManagement } from "../../../core/TimerManagement";
import { EmojiLocaleStrings } from "./HtmlEditor.Emoji.Strings";
import { announce } from "../../../core/util/Accessibility";
import { css } from "../../../core/util/Util";
import { format } from "../../../core/util/String";
import "./HtmlEditor.scss";

const Resources = require("./Resources.json");

const SanitizeHtmlOptions: HtmlSanitizerOptions = {
    attributeCallbacks: { style: (value: string) => value.replace(/(height|line-height)\s*:.+?(;[\s]?|$)/g, "") } // remove height and line-height from inline style
};
const HyperlinkShortcut = Resources.CtrlClickToOpen;

// by default, there is HTML bloat/workaround for Edge and Chrome around lists (only when setting specific font)
// to avoid the bloat, we're disabling this until we allow setting font
const DisableListWorkaround = true;

const PlaceholderImageClassName = "html-editor-img-placeholder";
const ExcludePlaceholderSelector = `:not(.${PlaceholderImageClassName})`;
const CommandBarHeight = 44;

const enum LeanRoosterModes {
    View = 0,
    Edit = 1
}

// we currently don't support file drop
const PreventFileDropHandler = (ev: React.DragEvent<HTMLElement>) => {
    const { dataTransfer } = ev.nativeEvent;
    if (!dataTransfer || !dataTransfer.types) {
        return;
    }

    const fileType = "Files";
    const types = dataTransfer.types as any;
    // IE11 has contains but not indexOf
    if ((types.indexOf && types.indexOf(fileType)) || (types.contains && types.contains(fileType))) {
        ev.preventDefault();
        try {
            dataTransfer.dropEffect = "none"; // IE11 throws an error
        } catch (_) {}

        return false;
    }
};

export interface IHtmlEditorState {
    fullScreen: boolean;
    hidden: boolean;
    readonly: boolean;
    defaultHeight?: number;
}

export class HtmlEditor extends React.PureComponent<IHtmlEditorProps, IHtmlEditorState> {
    private _leanRoosterRef = React.createRef<LeanRooster>();
    private _leanRoosterContentDiv: HTMLDivElement | null;
    private _commandBarRef = React.createRef<RoosterCommandBar>();
    private _commandBarPlugin: RoosterCommandBarPlugin;
    private _imageResizePlugin: ImageResize;
    private _timerManagement: TimerManagement | null = new TimerManagement();
    private _throttledOnContentChanged: (updatedContent: string, isInitializing?: boolean) => void;
    private _editorPlugins: any[];
    private _emojiPlugin: EmojiPlugin | null;
    private _undoPlugin: UndoWithImagePlugin;
    private _imageManager: any;
    private _buttonOverrides: RoosterCommandBarButton[];
    private _creationStartTime: number | null;
    private _viewState: EditorViewState;
    private _htmlEditorRef = React.createRef<HTMLDivElement>();

    public constructor(props: IHtmlEditorProps) {
        super(props);

        this._throttledOnContentChanged = this._timerManagement!.throttle(this._handleContentChanged, 100, { trailing: true });

        const { htmlContent, readonly, hidden, fullScreen, height } = props;
        this._viewState = { content: htmlContent, isDirty: false } as EditorViewState;
        this._createAdditionalEditorPlugins();
        this._initializeButtonOverrides();
        this._creationStartTime = Date.now();

        const viewState = this._viewState;
        const santizedContent = HtmlSanitizer.sanitizeHtml(viewState.content, SanitizeHtmlOptions);
        viewState.content = santizedContent;

        this.state = { readonly: !!readonly, hidden: !!hidden, fullScreen: !!fullScreen };
    }

    public render(): JSX.Element {
        const { className, editOnlyMode, height = this.state.defaultHeight } = this.props;
        let styles = {};
        if (height && height > 0) {
            let formattedHeight = format("{0}px", Math.round(height - CommandBarHeight));
            styles = { height: formattedHeight };
        }
        return (
            <div
                className={css(
                    "html-editor",
                    className,
                    this.state.hidden && "is-hidden",
                    this.state.fullScreen && "full-screen",
                    editOnlyMode === true && "edit-only-mode",
                    height == undefined && "auto-grow"
                )}
                style={styles}
                ref={this._htmlEditorRef}
            >
                {this._renderRoosterEditor()}
            </div>
        );
    }

    public setContent(newContent: string): void {
        const { height } = this.props;

        // If the module is not loaded, just set the state, we will sanitize when the module has been loaded
        const content = HtmlSanitizer.sanitizeHtml(newContent, SanitizeHtmlOptions);
        if (this._tryUpdateState(content) && this._leanRoosterRef.current) {
            this._leanRoosterRef.current.reloadContent(true /* triggerContentChangedEvent */, false /* resetUndo */);
            const initialContent = this._leanRoosterRef.current.getContent();
            this._undoPlugin.reset(initialContent);
        }

        const autoGrow = height === undefined;
        if (autoGrow) {
            this.setState({ defaultHeight: undefined });
        }
    }

    public isDirty() {
        return this._viewState.isDirty;
    }

    public get htmlContent(): string {
        return this._viewState.content;
    }

    public focus(): void {
        if (this._leanRoosterRef.current) {
            this._leanRoosterRef.current.focus();
        }
    }

    public selectAll(): void {
        if (this._leanRoosterRef.current) {
            this._leanRoosterRef.current.selectAll();
        }
    }

    public flushChanges(): void {
        if (this._leanRoosterRef.current) {
            // Force the content changed event bypassing the throttling to ensure that all changes are processed before saving the workitem.
            const content = this._leanRoosterRef.current.getContent();
            this._handleContentChanged(content);
        }
    }

    // force update on command bar will cause reflow, debounce here for all callers
    public refreshCommandBar = this._timerManagement!.debounce(() => this._commandBarRef.current && this._commandBarRef.current.forceUpdate(), 100, {
        trailing: true
    });

    public hasFocus(): boolean {
        return this._leanRoosterRef.current != null && this.mode === LeanRoosterModes.Edit;
    }

    public setEnabled(enabled: boolean): void {
        this.setState({ readonly: !enabled });
    }

    public setVisible(visible: boolean): void {
        this.setState({ hidden: !visible });
    }

    public setFullScreen(fullScreen: boolean): void {
        this.setState({ fullScreen });
    }

    public get mode(): LeanRoosterModes {
        return this._leanRoosterRef.current ? (this._leanRoosterRef.current.mode as unknown) as LeanRoosterModes : LeanRoosterModes.View;
    }

    public set mode(value: LeanRoosterModes) {
        if (this._leanRoosterRef.current) {
            this._leanRoosterRef.current.mode = (value as unknown) as PackageLeanRoosterModes;
        }
    }

    public componentDidMount(): void {
        if (this._leanRoosterRef.current && this.props.editOnlyMode) {
            this.mode = LeanRoosterModes.Edit;
        }

        if (this.props.autoFocus) {
            this.focus();
        }

        this._viewState.isDirty = false;
        if (this.props.onIsDirty) this.props.onIsDirty(this.isDirty());
        this._endCreateScenario();

        //accessibility fixes
        setTimeout(() => {
            $('.ms-CommandBar-overflowButton').attr("aria-label", "Menu overflow");
            $('.rooster-command-bar-button').attr("role", "menuitem");
        }, 200);
    }

    public componentWillUnmount(): void {
        const leanRoosterContentDiv = this._leanRoosterContentDiv;
        if (leanRoosterContentDiv) {
            this._leanRoosterContentDiv = null;
            this._emojiPlugin = null; // set to null to avoid race with _loadEmojiStrings(); also note rooster will call dispose on its plugins
        }

        if (this._timerManagement) {
            this._timerManagement.dispose();
            this._timerManagement = null;
        }

        if (this._commandBarPlugin && this._commandBarRef.current) {
            this._commandBarPlugin.unregisterRoosterCommandBar(this._commandBarRef.current);
        }
    }

    private _renderRoosterEditor(): JSX.Element | null {
        const { showChromeBorder, placeholder } = this.props;
        return (
            <FocusOutShell
                className={css("rooster-wrapper", !!showChromeBorder && "with-chrome")}
                allowMouseDown={this._focusOutShellAllowMouseDown}
                onBlur={this._focusOutShellOnBlur}
                onFocus={this._focusOutShellOnFocus}
            >
                {(calloutClassName: string, calloutOnDismiss: FocusEventHandler) => {
                    this._createPluginsWithCallout(calloutClassName, calloutOnDismiss);
                    return [
                        <LeanRooster
                            key="rooster"
                            className={css("rooster-editor", "propagate-keydown-event", "text-element", this._isEmpty(this._viewState.content) ? "empty" : "")}
                            viewState={this._viewState}
                            plugins={this._editorPlugins}
                            // undo={this._undoPlugin}
                            ref={this._leanRoosterRef}
                            updateViewState={this._updateViewState}
                            contentDivRef={this._leanRoosterContentDivOnRef}
                            readonly={this.state.readonly}
                            placeholder={placeholder}
                            hyperlinkToolTipCallback={this._hyperlinkToolTipCallback}
                            onFocus={this._leanRoosterOnFocus}
                            onDragEnter={PreventFileDropHandler}
                            onDragOver={PreventFileDropHandler}
                            aria-label={Resources.InputAriaLabel}
                        />,
                        <RoosterCommandBar
                            key="cmd"
                            className="rooster-command-bar"
                            commandBarClassName="html-command-bar-base"
                            buttonOverrides={this._buttonOverrides}
                            roosterCommandBarPlugin={this._commandBarPlugin}
                            emojiPlugin={this._emojiPlugin!}
                            imageManager={this._imageManager}
                            calloutClassName={css("rooster-callout", calloutClassName)}
                            calloutOnDismiss={calloutOnDismiss}
                            strings={CommandBarLocaleStrings}
                            ref={this._commandBarRef}
                            disableListWorkaround={DisableListWorkaround}
                            overflowMenuProps={{ className: "rooster-command-bar-overflow" }}
                            ellipsisAriaLabel={Resources.Toolbar_More}
                        />
                    ];
                }}
            </FocusOutShell>
        );
    }

    private _createPluginsWithCallout(calloutClassName: string, calloutOnDismiss: FocusEventHandler): any {
        if (!this._emojiPlugin) {
            this._emojiPlugin = new EmojiPlugin({
                calloutClassName: css(calloutClassName, "rooster-emoji-callout"),
                calloutOnDismiss,
                emojiPaneProps: {
                    quickPickerClassName: "rooster-emoji-quick-pick",
                    navBarProps: {
                        className: "rooster-emoji-navbar",
                        buttonClassName: "rooster-emoji-navbar-button",
                        iconClassName: "rooster-emoji-navbar-icon",
                        selectedButtonClassName: "rooster-emoji-navbar-selected"
                    },
                    statusBarProps: {
                        className: "rooster-emoji-status-bar"
                    },
                    emojiIconProps: {
                        className: "rooster-emoji-icon",
                        selectedClassName: "rooster-emoji-selected"
                    },
                    searchPlaceholder: SearchForEmoji,
                    searchInputAriaLabel: SearchForEmoji
                } as EmojiPaneProps,

                strings: { emjDNoSuggetions: "No suggestions found" } //  only load a small portion of required strings first (the rest is async loaded)
            } as EmojiPluginOptions);
            this._editorPlugins.push(this._emojiPlugin);
        }

        if (!this._commandBarPlugin) {
            this._commandBarPlugin = new RoosterCommandBarPlugin({
                strings: CommandBarLocaleStrings,
                disableListWorkaround: DisableListWorkaround,
                calloutClassName,
                calloutOnDismiss
            } as RoosterCommandBarPluginOptions);
            this._editorPlugins.push(this._commandBarPlugin);
        }

        if (this.props.onKeyDown) {
            this._editorPlugins.push(new KeyDownPlugin(this.props.onKeyDown));
        }
    }

    private _initializeButtonOverrides(): void {
        const keys = RoosterCommmandBarButtonKeys;
        const buttonClassName = "html-command-bar-button";
        this._buttonOverrides = [
            { key: keys.Bold, order: 0 },
            { key: keys.Italic, order: 1 },
            { key: keys.Underline, order: 2 },
            { key: keys.BulletedList, order: 3 },
            { key: keys.NumberedList, order: 4 },
            { key: keys.Highlight, order: 5, subMenuPropsOverride: { ariaLabel: Resources.HighlightColorPicker_AriaLabel } },
            { key: keys.FontColor, order: 6, subMenuPropsOverride: { ariaLabel: Resources.FontColorPicker_AriaLabel } },
            { key: keys.Emoji, order: 7, iconProps: { iconName: "Emoji2" }, buttonClassName: css("rooster-emoji-button", buttonClassName) },
            { key: keys.Outdent, order: 8 },
            { key: keys.Indent, order: 9 },
            { key: keys.Strikethrough, order: 10 },
            { key: keys.Header, order: 11, subMenuPropsOverride: { ariaLabel: Resources.HeaderMenu_AriaLabel } },
            { key: keys.Code, order: 12 },
            //TODO: ClearFormat works in the browser, but not in Teams.  Need to figure out why.  Disabling it for now.
            { key: keys.ClearFormat, order: 16, exclude: true },
            { key: keys.InsertImage, order: 17, exclude: true },
            { key: keys.Link, order: 18 },
            { key: keys.Unlink, order: 19 }
        ];

        this._buttonOverrides.forEach(b => {
            b.className = b.className || "html-command-button-root";
            b.buttonClassName = b.buttonClassName || buttonClassName;
        });
    }

    private _createAdditionalEditorPlugins(): void {
        const { uploadImageHandler: uploadImage } = this.props;

        this._imageManager = new ImageManager({
            uploadImage,
            placeholderImageClassName: PlaceholderImageClassName
        } as ImageManagerOptions);
        this._undoPlugin = new UndoWithImagePlugin(this._imageManager);
        this._imageResizePlugin = new ImageResize(undefined, undefined, undefined, undefined, ExcludePlaceholderSelector);

        const supportInsertImage: boolean = !!uploadImage;
        this._editorPlugins = [
            new ContentChangedPlugin(this._throttledOnContentChanged),
            this._imageResizePlugin,
            new TableResize(),
            supportInsertImage ? new PasteImagePlugin(this._imageManager) : IgnorePasteImagePlugin.Instance,
            new DoubleClickImagePlugin(ExcludePlaceholderSelector)
        ];
    }

    private _tryUpdateState(newContent: string, isInitializing: boolean = false): boolean {
        newContent = this._isEmpty(newContent) ? "" : newContent;
        const viewState = this._viewState;
        if (newContent !== viewState.content) {
            viewState.content = newContent;
            viewState.isDirty = true;
            if (this.props.onIsDirty) this.props.onIsDirty(this.isDirty());
            return !isInitializing;
        }

        return false;
    }

    private _isEmpty(html: string): boolean {
        const ISEMPTY_MINIMAL_CONTENT_LENGTH = 500;
        if (html == null || html.length === 0) {
            return true;
        }

        if (html.length >= ISEMPTY_MINIMAL_CONTENT_LENGTH) {
            return false;
        }

        const tempNode = fromHtml(`<div>${html}</div>`, document)[0] as HTMLElement;
        return isNodeEmpty(tempNode);
    }

    private _endCreateScenario() {
        if (this._creationStartTime) {
            this._creationStartTime = null;
        }
    }

    private _handleContentChanged = (content: string, isInitializing: boolean = false): void => {
        if (this._tryUpdateState(content, isInitializing) && this.props.onChange) {
            this.props.onChange(content);
            if (this.props.onIsDirty) this.props.onIsDirty(this.isDirty());
        }
    };

    private _updateViewState = (existingViewState: EditorViewState, content: string, isInitializing: boolean): void => {
        this._throttledOnContentChanged(content, isInitializing);
    };

    private _focusOutShellAllowMouseDown = (element: HTMLElement): boolean => {
        const isContenteditable = !!(this._leanRoosterContentDiv && this._leanRoosterContentDiv.contains(element));

        return isContenteditable;
    };

    private _focusOutShellOnFocus = (ev: React.FocusEvent<HTMLElement>): void => {
        const { height } = this.props;

        if (this._commandBarRef.current) {
            this._commandBarPlugin.registerRoosterCommandBar(this._commandBarRef.current); // re-register command b/c we're changing mode on blur
        }

        if (!this.props.editOnlyMode) {
            this.mode = LeanRoosterModes.Edit;

            const autoGrow = height === undefined ? true : false;
            if (autoGrow) {
                this.setState({ defaultHeight: undefined });
            }
        }
    };

    private _leanRoosterOnFocus = (ev: React.FocusEvent<HTMLElement>): void => {
        const { helpText } = this.props;
        if (helpText && this._leanRoosterRef.current && this._leanRoosterRef.current.hasPlaceholder()) {
            announce(helpText, true);
        }
    };

    private _focusOutShellOnBlur = (ev: React.FocusEvent<HTMLElement>): void => {
        const { height } = this.props;
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }

        if (!this.props.editOnlyMode) {
            this.mode = LeanRoosterModes.View;
            this._imageResizePlugin.hideResizeHandle();

            const autoGrow = height === undefined;
            const rootDiv = this._htmlEditorRef.current;
            if (autoGrow && rootDiv) {
                const height = rootDiv.getBoundingClientRect().height;

                // A height of 0 means that the tab has switched and the root div is no longer visible.
                // This can occur when an @mention popup is present, then switching
                // to the 'history' tab (for example)
            }
        }
    };

    private _leanRoosterContentDivOnRef = (ref: HTMLDivElement): void => {
        this._leanRoosterContentDiv = ref;
        this._loadEmojiStrings();
    };

    private _hyperlinkToolTipCallback = (href: string): string => {
        // roosterjs will support which link to process in the future, for now we don't show tooltip
        // href having just hash (@ mention)
        if (this.hasFocus()) {
            return `${href}\n${HyperlinkShortcut}`;
        }

        return "";
    };

    private _loadEmojiStrings(): void {
        if (this._emojiPlugin) {
            this._emojiPlugin.setStrings(EmojiLocaleStrings);
        }
    }
}
