import { Editor, EditorPlugin, PluginEvent, PluginEventType, PluginDomEvent } from "roosterjs-react";

export class KeyDownPlugin implements EditorPlugin {
    private _keyDownEventHandler: ((event: KeyboardEvent) => void) | null;

    public constructor(handler: (event: KeyboardEvent) => void) {
        this._keyDownEventHandler = handler;
    }

    public getName(): string {
        return "KeyDownPlugin";
    }

    public dispose(): void {
        this._keyDownEventHandler = null;
    }

    public initialize(editor: Editor): void {}

    public onPluginEvent(event: any): void | undefined {
        if (this._keyDownEventHandler && event.eventType === 0) {
            this._keyDownEventHandler(event.rawEvent);
        }
    }
}
