import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner, SpinnerSize, Text, Image } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { Card } from "../../../../../../../../common/components/Card/Card";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";
import { IContactNote } from "../../../../../../../../contracts/swagger/_generated";
import { NoResults } from "../../../../../../../../common/components/NoResults/NoResults";

import formImage from "../../../../../../../../styling/images/form.png";
import ContactNote from "./components/ContactNote/ContactNote";
import CreateNote from "./components/CreateNote/CreateNote";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";

import "./ContactNotes.scss";
import "../../../ContactCard.scss"
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IContactNotesProvidedProps extends IRapComponentProperties {
}

interface IContactNotesOwnProps {
    contactNotes: IContactNote[];
}

export type IContactNotesProps = ConnectedProps<typeof connector> & IContactNotesProvidedProps;

const ContactNotesInitializer: FC<IContactNotesProps> = (props) => {
    let { contactId } = useParams<any>();
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if(contactId && !props.contactNotes) {
            setIsLoading(true);
            props.fetchContactNotes(contactId).finally(() => {
                setIsLoading(false);
            });
        }
    }, [contactId]);

    return (
        <Card className="c-contactNotes c-card flex">            
            <div className="flex-row">
                <div className="c-card-header">
                    {localizedStrings.ContactsView.contactNotes.notes} ({`${props.contactNotes === undefined ? "" : props.contactNotes?.length}`})
                </div>
            </div>

            <div className="c-card-body">
                <div className="c-create-note-padding">
                    <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                        {props.contactNotes?.length === 0 && (
                            <div className="flex-column flex-grow">
                                <NoResults 
                                    description={localizedStrings.ContactsView.contactNotes.notFound}
                                    imageSource={formImage} />
                            </div>
                        )}
                        <div className="c-notes">
                            {props.contactNotes?.map((note: IContactNote) => 
                                <ContactNote 
                                    contactId={contactId}
                                    contactNote={note} 
                                    className="c-note" 
                                    key={note.noteId}
                                />
                            )}
                        </div>
                    </LoadingContainer>
                </div>

                <CreateNote contactId={contactId}/>
            </div>
        </Card>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactNotesProvidedProps): Partial<IContactNotesOwnProps> {
    return {
        ...providedProps,
        contactNotes: ContactsSelectors.getSelectedContactInfo(state)?.contactNotes,
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactNotes: ContactsActions.fetchContactNotes
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ContactNotesInitializer);