import { IRapTelemetryService } from "../platform/services/Telemetry";
import { IRapPageContext } from "../platform/Context";
import * as Bowser from "bowser";

export interface ITelemetryLogger {
    sendTelemetry(area: string, feature: string, action: string, storeId: string, errorMessage?: string): void;
    sendComplexTelemetry(area: string, feature: string, storeId: string, properties: { [key: string]: any }): void;
}

export class TelemetryLogger implements ITelemetryLogger {
    private context: IRapPageContext;

    public constructor(context: IRapPageContext) {
        this.context = context;
    }

    sendTelemetry = (area: string, feature: string, action: string, storeId: string, errorMessage?: string): void => {
        this.sendComplexTelemetry(area, feature, storeId, {
            Action: action,
            ErrorMessage: errorMessage
        });
    };

    sendComplexTelemetry = (area: string, feature: string, storeId: string, properties: { [key: string]: any }): void => {
        //Add device information
        properties["userDevice"] = Bowser.parse(window.navigator.userAgent);

        const telemetryService = this.context.getService<IRapTelemetryService>("IRapTelemetryService");
        telemetryService.publishEvent(area, feature, storeId, properties);
    };
}
