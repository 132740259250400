import * as Culture from "./Culture";

export var empty = "";
export var newLine = "\r\n";
export var tab = "\t";
export var lineFeed = "\n";

function prepareForComparison(value: string, upperCase: boolean): string {
    return value ? (upperCase ? value.toLocaleUpperCase() : value) : "";
}

function comparer(a: string, b: string, ignoreCase: boolean): number {
    // Optimization: if the strings are equal no need to convert and perform a locale compare.
    if (a === b) {
        return 0;
    }

    return prepareForComparison(a, ignoreCase).localeCompare(prepareForComparison(b, ignoreCase));
}

/**
 * String comparer (to use for sorting) which is case-sensitive
 *
 * @param a First string to compare
 * @param b Second string to compare
 */
export function localeComparer(a: string, b: string): number {
    return comparer(a, b, false);
}

/**
 * String comparer (to use for sorting) which is case-insensitive
 *
 * @param a First string to compare
 * @param b Second string to compare
 */
export function localeIgnoreCaseComparer(a: string, b: string): number {
    return comparer(a, b, true);
}

/**
 * Compares 2 strings for equality.
 *
 * @param a First string to compare
 * @param b Second string to compare
 * @param ignoreCase If true, do a case-insensitive comparison.
 */
export function equals(a: string, b: string, ignoreCase?: boolean): boolean {
    if (ignoreCase) {
        return localeIgnoreCaseComparer(a, b) === 0;
    } else {
        return localeComparer(a, b) === 0;
    }
}

/**
 * Checks whether the given string starts with the specified prefix.
 *
 * @param str String to check
 * @param prefix Substring that the {str} argument must start with in order to return true
 * @param ignoreCase If true, do a case insensitive comparison
 */
export function startsWith(str: string, prefix: string, ignoreCase?: boolean): boolean {
    const comparer = ignoreCase ? localeIgnoreCaseComparer : localeComparer;
    return comparer(prefix, str.substr(0, prefix.length)) === 0;
}

/**
 * Checks whether the given string ends with the specified suffix.
 *
 * @param str String to check
 * @param suffix Substring that the {str} argument must end with in order to return true
 * @param ignoreCase If true, do a case insensitive comparison
 */
export function endsWith(str: string, suffix: string, ignoreCase?: boolean): boolean {
    const comparer = ignoreCase ? localeIgnoreCaseComparer : localeComparer;
    return comparer(suffix, str.substr(str.length - suffix.length, suffix.length)) === 0;
}

/**
 * Performs a case-insensitive contains operation
 *
 * @param str String to check if it contains {subStr}
 * @param subStr The string that the {str} argument must contain in order to return true
 */
export function caseInsensitiveContains(str: string, subStr: string): boolean {
    return str.toLocaleLowerCase().indexOf(subStr.toLocaleLowerCase()) !== -1;
}

/**
 * Generate a string using a format string and arguments.
 *
 * @param format Format string
 * @param args Arguments to use as replacements
 */
export function format(format: string, ...args: any[]): string {
    return _stringFormat(false, format, args);
}

/**
 * Generate a string using a format string and arguments, using locale-aware argument replacements.
 *
 * @param format Format string
 * @param args Arguments to use as replacements
 */
export function localeFormat(format: string, ...args: any[]): string {
    return _stringFormat(true, format, args);
}

function _stringFormat(useLocale: boolean, format: string, args: any[]): string {
    let result = "";
    for (let i = 0; ; ) {
        const open = format.indexOf("{", i);
        const close = format.indexOf("}", i);
        if (open < 0 && close < 0) {
            result += format.slice(i);
            break;
        }

        if (close > 0 && (close < open || open < 0)) {
            if (format.charAt(close + 1) !== "}") {
                throw new Error("The format string contains an unmatched opening or closing brace.");
            }
            result += format.slice(i, close + 1);
            i = close + 2;
            continue;
        }

        result += format.slice(i, open);
        i = open + 1;
        if (format.charAt(i) === "{") {
            result += "{";
            i++;
            continue;
        }

        if (close < 0) {
            throw new Error("The format string contains an unmatched opening or closing brace.");
        }

        const brace = format.substring(i, close);
        const colonIndex = brace.indexOf(":");
        const argNumber = parseInt(colonIndex < 0 ? brace : brace.substring(0, colonIndex), 10);

        if (isNaN(argNumber)) {
            throw new Error("The format string is invalid.");
        }

        const argFormat = colonIndex < 0 ? "" : brace.substring(colonIndex + 1);
        let arg = args[argNumber];
        if (typeof arg === "undefined" || arg === null) {
            arg = "";
        }
        if (arg.toFormattedString) {
            result += arg.toFormattedString(argFormat);
        } else if (arg instanceof Date) {
            result += dateToString(arg, useLocale);
        } else if (arg.format) {
            result += arg.format(argFormat);
        } else {
            result += arg.toString();
        }

        i = close + 1;
    }
    return result;
}

const localeFormatters: { [key: string]: Intl.DateTimeFormat } =
    "Intl" in window
        ? {
              date: new Intl.DateTimeFormat(),
              dateTime: new Intl.DateTimeFormat(undefined, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric"
              })
          }
        : {};

/**
 * String representation of the empty guid
 */
export const EmptyGuidString = "00000000-0000-0000-0000-000000000000";

/**
 * Is the given string in the format of a GUID
 *
 * @param str String to check
 */
export function isGuid(str: string): boolean {
    return /^\{?([\dA-F]{8})-?([\dA-F]{4})-?([\dA-F]{4})-?([\dA-F]{4})-?([\dA-F]{12})\}?$/i.test(str);
}

/**
 * Returns a GUID such as xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx.
 * @return New GUID.(UUID version 4 = xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx)
 * @notes Disclaimer: This implementation uses non-cryptographic random number generator so absolute uniqueness is not guarantee.
 */
export function newGuid(): string {
    // c.f. rfc4122 (UUID version 4 = xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx)
    // "Set the two most significant bits (bits 6 and 7) of the clock_seq_hi_and_reserved to zero and one, respectively"
    const clockSequenceHi = (128 + Math.floor(Math.random() * 64)).toString(16);
    return oct(8) + "-" + oct(4) + "-4" + oct(3) + "-" + clockSequenceHi + oct(2) + "-" + oct(12);
}

/**
 * Generated non-zero octet sequences for use with GUID generation.
 *
 * @param length Length required.
 * @return Non-Zero hex sequences.
 */
function oct(length: number): string {
    let result = "";
    for (let i = 0; i < length; i++) {
        result += Math.floor(Math.random() * 0x10).toString(16);
    }

    return result;
}

export function dateToString(value: Date, useLocale?: boolean, format?: string): string {
    return MicrosoftAjaxDateFormatting.dateToString(value, useLocale, format);
}

export function numberToString(value: number, useLocale?: boolean, format?: string): string {
    return MicrosoftAjaxNumberFormatting.numberToString(value, useLocale, format);
}

export function parseDateString(value: string, cultureInfo?: Culture.ICultureInfo, formats?: string[]): Date | null {
    return MicrosoftAjaxDateFormatting.parseDateString(value, cultureInfo, formats);
}

export class MicrosoftAjaxDateFormatting {
    private static _getDateEra(date: Date, eras: any[]): number {
        if (!eras) {
            return 0;
        }
        var start,
            ticks = date.getTime();
        for (var i = 0, l = eras.length; i < l; i += 4) {
            start = eras[i + 2];
            if (start === null || ticks >= start) {
                return i;
            }
        }
        return 0;
    }

    private static _getDateEraYear(date: Date, dtf: Culture.IDateTimeFormatSettings, era: number, sortable?: boolean) {
        var year = date.getFullYear();

        // If we have a locale conversion function then we need to use it to get the year before applying the era
        var convert = dtf.Calendar.convert;
        if (convert) {
            year = convert.fromGregorian(date)[0];
        }

        if (!sortable && dtf.eras) {
            year -= dtf.eras[era + 3];
        }
        return year;
    }

    private static _expandDateFormat(dtf: Culture.IDateTimeFormatSettings, format: string) {
        if (!format) {
            format = "F";
        }
        var len = format.length;
        if (len === 1) {
            switch (format) {
                case "d":
                    return dtf.ShortDatePattern;
                case "D":
                    return dtf.LongDatePattern;
                case "g":
                    return dtf.ShortDatePattern + " " + dtf.ShortTimePattern;
                case "G":
                    return dtf.ShortDatePattern + " " + dtf.LongTimePattern;
                case "t":
                    return dtf.ShortTimePattern;
                case "T":
                    return dtf.LongTimePattern;
                case "f":
                    return dtf.LongDatePattern + " " + dtf.ShortTimePattern;
                case "F":
                    return dtf.FullDateTimePattern;
                case "M":
                case "m":
                    return dtf.MonthDayPattern;
                case "s":
                    return dtf.SortableDateTimePattern;
                case "Y":
                case "y":
                    return dtf.YearMonthPattern;
                case "R":
                case "r":
                    return dtf.RFC1123Pattern;
                case "u":
                    return dtf.UniversalSortableDateTimePattern;
                default:
                    throw new Error("Input string was not in a correct format.");
            }
        } else if (len === 2 && format.charAt(0) === "%") {
            format = format.charAt(1);
        }
        return format;
    }

    public static dateToString(value: Date, useLocale?: boolean, format?: string): string {
        var dtf = useLocale ? Culture.getCurrentCulture().dateTimeFormat : Culture.getInvariantCulture().dateTimeFormat;
        var convert = dtf.Calendar.convert;

        if (!format || !format.length || format === "i") {
            if (useLocale) {
                if (convert) {
                    format = dtf.FullDateTimePattern;
                } else {
                    var eraDate = new Date(value.getTime());
                    var era = this._getDateEra(value, dtf.eras);
                    eraDate.setFullYear(this._getDateEraYear(value, dtf, era));
                    return eraDate.toLocaleString();
                }
            } else {
                return value.toString();
            }
        }
        var eras = dtf.eras;
        var sortable = format === "s";
        format = this._expandDateFormat(dtf, format);
        var ret = new StringBuilder();
        var hour;
        function addLeadingZero(num: number) {
            if (num < 10) {
                return "0" + num;
            }
            return num.toString();
        }
        function addLeadingZeros(num: number) {
            if (num < 10) {
                return "00" + num;
            }
            if (num < 100) {
                return "0" + num;
            }
            return num.toString();
        }
        function padYear(year: number) {
            if (year < 10) {
                return "000" + year;
            } else if (year < 100) {
                return "00" + year;
            } else if (year < 1000) {
                return "0" + year;
            }
            return year.toString();
        }
        function _appendPreOrPostMatch(preMatch: string, strBuilder: StringBuilder) {
            var quoteCount = 0;
            var escaped = false;
            for (var i = 0, il = preMatch.length; i < il; i++) {
                var c = preMatch.charAt(i);
                switch (c) {
                    case "'":
                        if (escaped) strBuilder.append("'");
                        else quoteCount++;
                        escaped = false;
                        break;
                    case "\\":
                        if (escaped) strBuilder.append("\\");
                        escaped = !escaped;
                        break;
                    default:
                        strBuilder.append(c);
                        escaped = false;
                        break;
                }
            }
            return quoteCount;
        }

        var foundDay: boolean,
            checkedDay: boolean,
            dayPartRegExp = /([^d]|^)(d|dd)([^d]|$)/g;
        function hasDay() {
            if (foundDay || checkedDay) {
                return foundDay;
            }
            foundDay = format ? dayPartRegExp.test(format) : false;
            checkedDay = true;
            return foundDay;
        }

        var quoteCount = 0,
            tokenRegExp = /\/|dddd|ddd|dd|d|MMMM|MMM|MM|M|yyyy|yy|y|hh|h|HH|H|mm|m|ss|s|tt|t|fff|ff|f|zzz|zz|z|gg|g/g,
            converted: number[] = [];
        if (!sortable && convert) {
            converted = convert.fromGregorian(value);
        }
        function getPart(date: Date, part: number) {
            if (converted) {
                return converted[part];
            }
            switch (part) {
                case 0:
                    return date.getFullYear();
                case 1:
                    return date.getMonth();
                case 2:
                    return date.getDate();
                default:
                    return date.getDate();
            }
        }
        for (;;) {
            var index = tokenRegExp.lastIndex;
            var ar = tokenRegExp.exec(format);
            var preMatch = format.slice(index, ar ? ar.index : format.length);
            quoteCount += _appendPreOrPostMatch(preMatch, ret);
            if (!ar) break;
            if (quoteCount % 2 === 1) {
                ret.append(ar[0]);
                continue;
            }

            switch (ar[0]) {
                case "dddd":
                    ret.append(dtf.DayNames[value.getDay()]);
                    break;
                case "ddd":
                    ret.append(dtf.AbbreviatedDayNames[value.getDay()]);
                    break;
                case "dd":
                    foundDay = true;
                    ret.append(addLeadingZero(getPart(value, 2)));
                    break;
                case "d":
                    foundDay = true;
                    ret.append(getPart(value, 2));
                    break;
                case "MMMM":
                    ret.append(dtf.MonthGenitiveNames && hasDay() ? dtf.MonthGenitiveNames[getPart(value, 1)] : dtf.MonthNames[getPart(value, 1)]);
                    break;
                case "MMM":
                    ret.append(
                        dtf.AbbreviatedMonthGenitiveNames && hasDay()
                            ? dtf.AbbreviatedMonthGenitiveNames[getPart(value, 1)]
                            : dtf.AbbreviatedMonthNames[getPart(value, 1)]
                    );
                    break;
                case "MM":
                    ret.append(addLeadingZero(getPart(value, 1) + 1));
                    break;
                case "M":
                    ret.append(getPart(value, 1) + 1);
                    break;
                case "yyyy":
                    ret.append(padYear(converted ? converted[0] : this._getDateEraYear(value, dtf, this._getDateEra(value, eras), sortable)));
                    break;
                case "yy":
                    ret.append(
                        addLeadingZero((converted ? converted[0] : this._getDateEraYear(value, dtf, this._getDateEra(value, eras), sortable)) % 100)
                    );
                    break;
                case "y":
                    ret.append((converted ? converted[0] : this._getDateEraYear(value, dtf, this._getDateEra(value, eras), sortable)) % 100);
                    break;
                case "hh":
                    hour = value.getHours() % 12;
                    if (hour === 0) hour = 12;
                    ret.append(addLeadingZero(hour));
                    break;
                case "h":
                    hour = value.getHours() % 12;
                    if (hour === 0) hour = 12;
                    ret.append(hour);
                    break;
                case "HH":
                    ret.append(addLeadingZero(value.getHours()));
                    break;
                case "H":
                    ret.append(value.getHours());
                    break;
                case "mm":
                    ret.append(addLeadingZero(value.getMinutes()));
                    break;
                case "m":
                    ret.append(value.getMinutes());
                    break;
                case "ss":
                    ret.append(addLeadingZero(value.getSeconds()));
                    break;
                case "s":
                    ret.append(value.getSeconds());
                    break;
                case "tt":
                    ret.append(value.getHours() < 12 ? dtf.AMDesignator : dtf.PMDesignator);
                    break;
                case "t":
                    ret.append((value.getHours() < 12 ? dtf.AMDesignator : dtf.PMDesignator).charAt(0));
                    break;
                case "f":
                    ret.append(addLeadingZeros(value.getMilliseconds()).charAt(0));
                    break;
                case "ff":
                    ret.append(addLeadingZeros(value.getMilliseconds()).substr(0, 2));
                    break;
                case "fff":
                    ret.append(addLeadingZeros(value.getMilliseconds()));
                    break;
                case "z":
                    hour = value.getTimezoneOffset() / 60;
                    ret.append((hour <= 0 ? "+" : "-") + Math.floor(Math.abs(hour)));
                    break;
                case "zz":
                    hour = value.getTimezoneOffset() / 60;
                    ret.append((hour <= 0 ? "+" : "-") + addLeadingZero(Math.floor(Math.abs(hour))));
                    break;
                case "zzz":
                    hour = value.getTimezoneOffset() / 60;
                    ret.append(
                        (hour <= 0 ? "+" : "-") +
                            addLeadingZero(Math.floor(Math.abs(hour))) +
                            ":" +
                            addLeadingZero(Math.abs(value.getTimezoneOffset() % 60))
                    );
                    break;
                case "g":
                case "gg":
                    if (dtf.eras) {
                        ret.append(dtf.eras[this._getDateEra(value, eras) + 1]);
                    }
                    break;
                case "/":
                    ret.append(dtf.DateSeparator);
                    break;
                default:
                    new Error("Invalid date format pattern");
                    break;
            }
        }
        return ret.toString();
    }

    public static parseDateString(value: string, cultureInfo?: Culture.ICultureInfo, formats?: string[]): Date | null {
        var custom = false;

        if (cultureInfo) {
            if (formats) {
                for (var i = 0, l = formats.length; i < l; i++) {
                    var format = formats[i];
                    if (format) {
                        custom = true;
                        var date = this._parseExact(value, format, cultureInfo);
                        if (date) {
                            return date;
                        }
                    }
                }
            }

            if (!custom) {
                var allFormats = this._getDateTimeFormats(cultureInfo.dateTimeFormat);
                for (i = 0, l = allFormats.length; i < l; i++) {
                    var date = this._parseExact(value, allFormats[i], cultureInfo);
                    if (date) {
                        return date;
                    }
                }
            }
        }
        return null;
    }

    private static _getDateTimeFormats(dtf: Culture.IDateTimeFormatSettings) {
        return [
            dtf.MonthDayPattern,
            dtf.YearMonthPattern,
            dtf.ShortDatePattern,
            dtf.ShortTimePattern,
            dtf.LongDatePattern,
            dtf.LongTimePattern,
            dtf.FullDateTimePattern,
            dtf.RFC1123Pattern,
            dtf.SortableDateTimePattern,
            dtf.UniversalSortableDateTimePattern,
            `${dtf.ShortDatePattern} ${dtf.LongTimePattern}`,
            `${dtf.LongDatePattern} ${dtf.ShortTimePattern}`,
            `${dtf.ShortDatePattern} ${dtf.ShortTimePattern}`
        ];
    }

    private static _appendPreOrPostMatch(preMatch: string, strBuilder: StringBuilder) {
        var quoteCount = 0;
        var escaped = false;
        for (var i = 0, il = preMatch.length; i < il; i++) {
            var c = preMatch.charAt(i);
            switch (c) {
                case "'":
                    if (escaped) strBuilder.append("'");
                    else quoteCount++;
                    escaped = false;
                    break;
                case "\\":
                    if (escaped) strBuilder.append("\\");
                    escaped = !escaped;
                    break;
                default:
                    strBuilder.append(c);
                    escaped = false;
                    break;
            }
        }
        return quoteCount;
    }

    private static _expandYear(dtf: Culture.IDateTimeFormatSettings, year: number) {
        var now = new Date(),
            era = this._getDateEra(now, dtf.eras);

        if (year < 100) {
            var curr = this._getDateEraYear(now, dtf, era);
            year += curr - (curr % 100);
            if (year > dtf.Calendar.TwoDigitYearMax) {
                year -= 100;
            }
        }
        return year;
    }

    private static _getParseRegExp(dtf: Culture.IDateTimeFormatSettings, format: string) {
        if (!(<any>dtf)._parseRegExp) {
            (<any>dtf)._parseRegExp = {};
        } else if ((<any>dtf)._parseRegExp[format]) {
            return (<any>dtf)._parseRegExp[format];
        }

        var expFormat = this._expandDateFormat(dtf, format);
        expFormat = expFormat.replace(/([\^\$\.\*\+\?\|\[\]\(\)\{\}])/g, "\\\\$1");
        var regexp = new StringBuilder("^");
        var groups = [];
        var index = 0;
        var quoteCount = 0;
        var tokenRegExp = /\/|dddd|ddd|dd|d|MMMM|MMM|MM|M|yyyy|yy|y|hh|h|HH|H|mm|m|ss|s|tt|t|fff|ff|f|zzz|zz|z|gg|g/g;
        var match;
        while ((match = tokenRegExp.exec(expFormat)) !== null) {
            var preMatch = expFormat.slice(index, match.index);
            index = tokenRegExp.lastIndex;
            quoteCount += this._appendPreOrPostMatch(preMatch, regexp);
            if (quoteCount % 2 === 1) {
                regexp.append(match[0]);
                continue;
            }
            switch (match[0]) {
                case "MMMM":
                case "MMM":
                    regexp.append("([\\d\\D]+)");
                    break;
                case "dddd":
                case "ddd":
                case "gg":
                case "g":
                    regexp.append("(\\D+)");
                    break;
                case "tt":
                case "t":
                    regexp.append("(\\D*)");
                    break;
                case "yyyy":
                    regexp.append("(\\d{4})");
                    break;
                case "fff":
                    regexp.append("(\\d{3})");
                    break;
                case "ff":
                    regexp.append("(\\d{2})");
                    break;
                case "f":
                    regexp.append("(\\d)");
                    break;
                case "dd":
                case "d":
                case "MM":
                case "M":
                case "yy":
                case "y":
                case "HH":
                case "H":
                case "hh":
                case "h":
                case "mm":
                case "m":
                case "ss":
                case "s":
                    regexp.append("(\\d\\d?)");
                    break;
                case "zzz":
                    regexp.append("([+-]?\\d\\d?:\\d{2})");
                    break;
                case "zz":
                case "z":
                    regexp.append("([+-]?\\d\\d?)");
                    break;
                case "/":
                    regexp.append("(\\" + dtf.DateSeparator + ")");
                    break;
                default:
                    throw new Error("Invalid date format pattern.");
            }
            groups.push(match[0]);
        }
        this._appendPreOrPostMatch(expFormat.slice(index), regexp);
        regexp.append("$");
        var regexpStr = regexp.toString().replace(/\s+/g, "\\s+");
        var parseRegExp = { regExp: regexpStr, groups: groups };
        (<any>dtf)._parseRegExp[format] = parseRegExp;
        return parseRegExp;
    }

    private static _indexOf(array: any[], item: any): number {
        if (typeof item === "undefined") return -1;
        for (var i = 0, length = array.length; i < length; i++) {
            if (typeof array[i] !== "undefined" && array[i] === item) {
                return i;
            }
        }
        return -1;
    }
    private static _getIndex(value: number, a1: any, a2: any) {
        var upper = this._toUpper(value),
            i = this._indexOf(a1, upper);
        if (i === -1) {
            i = this._indexOf(a2, upper);
        }
        return i;
    }
    private static _getMonthIndex(cultureInfo: Culture.ICultureInfo, value: any) {
        if (!(<any>cultureInfo)._upperMonths) {
            (<any>cultureInfo)._upperMonths = this._toUpperArray(cultureInfo.dateTimeFormat.MonthNames);
            (<any>cultureInfo)._upperMonthsGenitive = this._toUpperArray(cultureInfo.dateTimeFormat.MonthGenitiveNames);
        }
        return this._getIndex(value, (<any>cultureInfo)._upperMonths, (<any>cultureInfo)._upperMonthsGenitive);
    }
    private static _getAbbrMonthIndex(cultureInfo: Culture.ICultureInfo, value: any) {
        if (!(<any>cultureInfo)._upperAbbrMonths) {
            (<any>cultureInfo)._upperAbbrMonths = this._toUpperArray(cultureInfo.dateTimeFormat.AbbreviatedMonthNames);
            (<any>cultureInfo)._upperAbbrMonthsGenitive = this._toUpperArray(cultureInfo.dateTimeFormat.AbbreviatedMonthGenitiveNames);
        }
        return this._getIndex(value, (<any>cultureInfo)._upperAbbrMonths, (<any>cultureInfo)._upperAbbrMonthsGenitive);
    }
    private static _getDayIndex(cultureInfo: Culture.ICultureInfo, value: any) {
        if (!(<any>cultureInfo)._upperDays) {
            (<any>cultureInfo)._upperDays = this._toUpperArray(cultureInfo.dateTimeFormat.DayNames);
        }
        return this._indexOf((<any>cultureInfo)._upperDays, this._toUpper(value));
    }
    private static _getAbbrDayIndex(cultureInfo: Culture.ICultureInfo, value: any) {
        if (!(<any>cultureInfo)._upperAbbrDays) {
            (<any>cultureInfo)._upperAbbrDays = this._toUpperArray(cultureInfo.dateTimeFormat.AbbreviatedDayNames);
        }
        return this._indexOf((<any>cultureInfo)._upperAbbrDays, this._toUpper(value));
    }
    private static _toUpperArray(arr: any) {
        var result = [];
        for (var i = 0, il = arr.length; i < il; i++) {
            result[i] = this._toUpper(arr[i]);
        }
        return result;
    }
    private static _toUpper(value: any) {
        return value
            .split("\u00A0")
            .join(" ")
            .toUpperCase();
    }

    private static _parseExact(value: string, format: string, cultureInfo: Culture.ICultureInfo) {
        value = value.trim();
        var dtf = cultureInfo.dateTimeFormat,
            parseInfo = this._getParseRegExp(dtf, format),
            match = new RegExp(parseInfo.regExp).exec(value);
        if (match === null) return null;

        var groups = parseInfo.groups,
            era = null,
            year = null,
            month = null,
            date = null,
            weekDay = null,
            hour = 0,
            hourOffset,
            min = 0,
            sec = 0,
            msec = 0,
            tzMinOffset = null,
            pmHour = false;
        for (var j = 0, jl = groups.length; j < jl; j++) {
            var matchGroup = match[j + 1];
            if (matchGroup) {
                switch (groups[j]) {
                    case "dd":
                    case "d":
                        date = parseInt(matchGroup, 10);
                        if (date < 1 || date > 31) return null;
                        break;
                    case "MMMM":
                        month = this._getMonthIndex(cultureInfo, matchGroup);
                        if (month < 0 || month > 11) return null;
                        break;
                    case "MMM":
                        month = this._getAbbrMonthIndex(cultureInfo, matchGroup);
                        if (month < 0 || month > 11) return null;
                        break;
                    case "M":
                    case "MM":
                        month = parseInt(matchGroup, 10) - 1;
                        if (month < 0 || month > 11) return null;
                        break;
                    case "y":
                    case "yy":
                        year = this._expandYear(dtf, parseInt(matchGroup, 10));
                        if (year < 0 || year > 9999) return null;
                        break;
                    case "yyyy":
                        year = parseInt(matchGroup, 10);
                        if (year < 0 || year > 9999) return null;
                        break;
                    case "h":
                    case "hh":
                        hour = parseInt(matchGroup, 10);
                        if (hour === 12) hour = 0;
                        if (hour < 0 || hour > 11) return null;
                        break;
                    case "H":
                    case "HH":
                        hour = parseInt(matchGroup, 10);
                        if (hour < 0 || hour > 23) return null;
                        break;
                    case "m":
                    case "mm":
                        min = parseInt(matchGroup, 10);
                        if (min < 0 || min > 59) return null;
                        break;
                    case "s":
                    case "ss":
                        sec = parseInt(matchGroup, 10);
                        if (sec < 0 || sec > 59) return null;
                        break;
                    case "tt":
                    case "t":
                        var upperToken = matchGroup.toUpperCase();
                        pmHour = upperToken === dtf.PMDesignator.toUpperCase();
                        if (!pmHour && upperToken !== dtf.AMDesignator.toUpperCase()) return null;
                        break;
                    case "f":
                        msec = parseInt(matchGroup, 10) * 100;
                        if (msec < 0 || msec > 999) return null;
                        break;
                    case "ff":
                        msec = parseInt(matchGroup, 10) * 10;
                        if (msec < 0 || msec > 999) return null;
                        break;
                    case "fff":
                        msec = parseInt(matchGroup, 10);
                        if (msec < 0 || msec > 999) return null;
                        break;
                    case "dddd":
                        weekDay = this._getDayIndex(cultureInfo, matchGroup);
                        if (weekDay < 0 || weekDay > 6) return null;
                        break;
                    case "ddd":
                        weekDay = this._getAbbrDayIndex(cultureInfo, matchGroup);
                        if (weekDay < 0 || weekDay > 6) return null;
                        break;
                    case "zzz":
                        var offsets = matchGroup.split(/:/);
                        if (offsets.length !== 2) return null;
                        hourOffset = parseInt(offsets[0], 10);
                        if (hourOffset < -12 || hourOffset > 13) return null;
                        var minOffset = parseInt(offsets[1], 10);
                        if (minOffset < 0 || minOffset > 59) return null;
                        tzMinOffset = hourOffset * 60 + (startsWith(matchGroup, "-") ? -minOffset : minOffset);
                        break;
                    case "z":
                    case "zz":
                        hourOffset = parseInt(matchGroup, 10);
                        if (hourOffset < -12 || hourOffset > 13) return null;
                        tzMinOffset = hourOffset * 60;
                        break;
                    case "g":
                    case "gg":
                        var eraName = matchGroup;
                        if (!eraName || !dtf.eras) return null;
                        eraName = eraName.toLowerCase().trim();
                        for (var i = 0, l = dtf.eras.length; i < l; i += 4) {
                            if (eraName === dtf.eras[i + 1].toLowerCase()) {
                                era = i;
                                break;
                            }
                        }
                        if (era === null) return null;
                        break;
                }
            }
        }
        var result = new Date(),
            defaultYear,
            convert = dtf.Calendar.convert;
        if (convert) {
            defaultYear = convert.fromGregorian(result)[0];
        } else {
            defaultYear = result.getFullYear();
        }
        if (year === null) {
            year = defaultYear;
        } else if (dtf.eras) {
            year += dtf.eras[(era || 0) + 3];
        }
        if (month === null) {
            month = 0;
        }
        if (date === null) {
            date = 1;
        }
        if (convert) {
            result = convert.toGregorian(year, month, date);
            if (result === null) return null;
        } else {
            result.setFullYear(year, month, date);
            if (result.getDate() !== date) return null;
            if (weekDay !== null && result.getDay() !== weekDay) {
                return null;
            }
        }
        if (pmHour && hour < 12) {
            hour += 12;
        }
        result.setHours(hour, min, sec, msec);
        if (tzMinOffset !== null) {
            var adjustedMin = result.getMinutes() - (tzMinOffset + result.getTimezoneOffset());
            result.setHours(result.getHours() + parseInt(<any>(adjustedMin / 60), 10), adjustedMin % 60);
        }
        return result;
    }
}

export class MicrosoftAjaxNumberFormatting {
    public static numberToString(value: number, useLocale?: boolean, format?: string): string {
        if (!format || format.length === 0 || format === "i") {
            if (useLocale) {
                return value.toLocaleString();
            } else {
                return value.toString();
            }
        }

        var _percentPositivePattern = ["n %", "n%", "%n"];
        var _percentNegativePattern = ["-n %", "-n%", "-%n"];
        var _numberNegativePattern = ["(n)", "-n", "- n", "n-", "n -"];
        var _currencyPositivePattern = ["$n", "n$", "$ n", "n $"];
        var _currencyNegativePattern = [
            "($n)",
            "-$n",
            "$-n",
            "$n-",
            "(n$)",
            "-n$",
            "n-$",
            "n$-",
            "-n $",
            "-$ n",
            "n $-",
            "$ n-",
            "$ -n",
            "n- $",
            "($ n)",
            "(n $)"
        ];

        function zeroPad(str: string, count: number, left: boolean): string {
            for (var l = str.length; l < count; l++) {
                str = left ? "0" + str : str + "0";
            }
            return str;
        }

        function expandNumber(numToExpand: number, precision: number, groupSizes: number[], sep: string, decimalChar: string): string {
            var curSize = groupSizes[0];
            var curGroupIndex = 1;
            var factor = Math.pow(10, precision);
            var rounded = Math.round(numToExpand * factor) / factor;
            if (!isFinite(rounded)) {
                rounded = numToExpand;
            }
            numToExpand = rounded;

            var numberString = numToExpand.toString();
            var right = "";
            var exponent;

            var split = numberString.split(/e/i);
            numberString = split[0];
            exponent = split.length > 1 ? parseInt(split[1]) : 0;
            split = numberString.split(".");
            numberString = split[0];
            right = split.length > 1 ? split[1] : "";

            var l;
            if (exponent > 0) {
                right = zeroPad(right, exponent, false);
                numberString += right.slice(0, exponent);
                right = right.substr(exponent);
            } else if (exponent < 0) {
                exponent = -exponent;
                numberString = zeroPad(numberString, exponent + 1, true);
                right = numberString.slice(-exponent, numberString.length) + right;
                numberString = numberString.slice(0, -exponent);
            }
            if (precision > 0) {
                if (right.length > precision) {
                    right = right.slice(0, precision);
                } else {
                    right = zeroPad(right, precision, false);
                }
                right = decimalChar + right;
            } else {
                right = "";
            }
            var stringIndex = numberString.length - 1;
            var ret = "";
            while (stringIndex >= 0) {
                if (curSize === 0 || curSize > stringIndex) {
                    if (ret.length > 0) {
                        return numberString.slice(0, stringIndex + 1) + sep + ret + right;
                    } else {
                        return numberString.slice(0, stringIndex + 1) + right;
                    }
                }
                if (ret.length > 0) {
                    ret = numberString.slice(stringIndex - curSize + 1, stringIndex + 1) + sep + ret;
                } else {
                    ret = numberString.slice(stringIndex - curSize + 1, stringIndex + 1);
                }

                stringIndex -= curSize;
                if (curGroupIndex < groupSizes.length) {
                    curSize = groupSizes[curGroupIndex];
                    curGroupIndex++;
                }
            }
            return numberString.slice(0, stringIndex + 1) + sep + ret + right;
        }

        var nf = useLocale ? Culture.getCurrentCulture().numberFormat : Culture.getInvariantCulture().numberFormat;
        var num: string;
        if (!format) {
            format = "D";
        }
        var precision = -1;
        if (format.length > 1) precision = parseInt(format.slice(1), 10);
        var pattern;
        switch (format.charAt(0)) {
            case "d":
            case "D":
                pattern = "n";
                if (precision !== -1) {
                    num = zeroPad("" + Math.abs(value), precision, true);
                    if (value < 0) {
                        num = "-" + num;
                    }
                } else {
                    num = "" + value;
                }
                break;
            case "c":
            case "C":
                if (value < 0) {
                    pattern = _currencyNegativePattern[nf.CurrencyNegativePattern];
                } else {
                    pattern = _currencyPositivePattern[nf.CurrencyPositivePattern];
                }
                if (precision === -1) {
                    precision = nf.CurrencyDecimalDigits;
                }
                num = expandNumber(Math.abs(value), precision, nf.CurrencyGroupSizes, nf.CurrencyGroupSeparator, nf.CurrencyDecimalSeparator);
                break;
            case "n":
            case "N":
                if (value < 0) {
                    pattern = _numberNegativePattern[nf.NumberNegativePattern];
                } else {
                    pattern = "n";
                }
                if (precision === -1) {
                    precision = nf.NumberDecimalDigits;
                }
                num = expandNumber(Math.abs(value), precision, nf.NumberGroupSizes, nf.NumberGroupSeparator, nf.NumberDecimalSeparator);
                break;
            case "p":
            case "P":
                if (value < 0) {
                    pattern = _percentNegativePattern[nf.PercentNegativePattern];
                } else {
                    pattern = _percentPositivePattern[nf.PercentPositivePattern];
                }
                if (precision === -1) {
                    precision = nf.PercentDecimalDigits;
                }
                num = expandNumber(Math.abs(value) * 100, precision, nf.PercentGroupSizes, nf.PercentGroupSeparator, nf.PercentDecimalSeparator);
                break;
            default:
                throw new Error("Format specifier was invalid.");
        }
        var regex = /n|\$|-|%/g;
        var ret = "";
        for (;;) {
            var index = regex.lastIndex;
            var ar = regex.exec(pattern);
            ret += pattern.slice(index, ar ? ar.index : pattern.length);
            if (!ar) break;
            switch (ar[0]) {
                case "n":
                    ret += num;
                    break;
                case "$":
                    ret += nf.CurrencySymbol;
                    break;
                case "-":
                    if (/[1-9]/.test(num)) {
                        ret += nf.NegativeSign;
                    }
                    break;
                case "%":
                    ret += nf.PercentSymbol;
                    break;
                default:
                    throw new Error("Invalid number format pattern");
            }
        }
        return ret;
    }
}

export class StringBuilder {
    private _textBuilder: string[];

    /**
     * Utility class for building strings - similar to the System.Text.StringBuilder .NET class.
     *
     * @param initialText The initial text for the builder
     */
    constructor(initialText?: string) {
        this._textBuilder = [];

        if (initialText) {
            this._textBuilder.push(initialText);
        }
    }

    /**
     * Appends the specified text to the end of the string buffer.
     *
     * @param text The text to append.
     */
    public append(text: string | any) {
        this._textBuilder[this._textBuilder.length] = text;
    }

    /**
     * Appends a new-line to the current text buffer.
     */
    public appendNewLine() {
        this.append(newLine);
    }

    /**
     * Concatenates all text in the string buffer into a single string value.
     *
     * @return The string version of the accumulated text.
     */
    public toString(): string {
        return this._textBuilder.join("");
    }
}
