import { FC } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Text, DefaultButton } from "@fluentui/react";
import { PersonaSize } from "office-ui-fabric-react/lib/Persona";
import { format } from "office-ui-fabric-react/lib/Utilities";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { getInitials } from "../../../../../../platform/core/util/Util";
import * as LocaleUtils from "../../../../../../platform/core/util/Locale";

import { Card } from "../../../../../../common/components/Card/Card";
import { UserPersona } from "../../../../../../common/components/UserPersona/UserPersona";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { TextSizes } from "../../../../../../common/Constants";

import { AppointmentDetail } from "../../../../../../contracts/swagger/_generated";
import { IClientelingViewState } from "../../../../../../pages/Contracts";

import * as AppointmentsSelectors from "../../../../redux/AppointmentDetailsSelectors";
import { AppointmentDetailsActions } from "../../../../redux/AppointmentDetailsActions";

import "./AppointmentClientView.scss";

interface IAppointmentClientViewProvidedProps extends IRapComponentProperties {
}

interface IAppointmentClientViewOwnProps extends IAppointmentClientViewProvidedProps {
    appointment: AppointmentDetail;
}

export type IAppointmentClientViewProps = IAppointmentClientViewOwnProps & typeof ActionsToDispatch;

const AppointmentClientViewInitializer: FC<IAppointmentClientViewProps> = (props) => {
    const navigate = useNavigate();

    const getCustomerFullName = (appointment: AppointmentDetail): string => {
        if (appointment && appointment.appointmentContact && appointment.appointmentContact.firstName && appointment.appointmentContact.lastName) {
            return format("{0} {1}", appointment.appointmentContact.firstName, appointment.appointmentContact.lastName);
        }
        return "";
    };

    const getCustomerInitials = (appointment: AppointmentDetail): string => {
        if (appointment && appointment.appointmentContact && appointment.appointmentContact.firstName && appointment.appointmentContact.lastName) {
            return getInitials(appointment.appointmentContact.firstName, appointment.appointmentContact.lastName).replace(' ', "");
        }
        return "";
    };

    const _onClickViewClientDetails = (appointment: AppointmentDetail) => {
        props.updateClientedAppointmentFieldToTrue(props.appointment.appointmentDetails.storeNumber, props.appointment.appointmentDetails.appointmentId);
        navigate(`/${LocaleUtils.getLocale()}/reservations/${appointment?.appointmentDetails?.confirmationNumber}/contact/${appointment?.appointmentDetails?.customerContactId}`);
    }

    return (
        <Card>                
            <div className="c-appointment-client-container">
                <div className="flex-row">
                    <Text variant={TextSizes.large} className="c-header">{localizedStrings.AppointmentClientView.client}</Text>
                </div>
                <div className="flex-row c-persona">
                    <UserPersona 
                        imageUrl={props.appointment?.customerProfileImage}
                        imageInitials={getCustomerInitials(props.appointment)}
                        fullName={getCustomerFullName(props.appointment)}
                        renderDetails={true}
                        personaSize={PersonaSize.size40}
                        secondaryText={props.appointment?.appointmentContact?.email}
                    />
                </div>
                <div className="flex-row c-detail">
                    <DefaultButton 
                        text={localizedStrings.AppointmentClientView.seeClientDetails}
                        onClick={() => _onClickViewClientDetails(props.appointment)}
                        primary={false}
                    />
                </div>
            </div>
        </Card>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentClientViewProvidedProps): Partial<IAppointmentClientViewProps> {
    return {
        ...providedProps,
        appointment: AppointmentsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails,
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateClientedAppointmentFieldToTrue: AppointmentDetailsActions.updateClientedAppointmentFieldToTrue
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentClientViewInitializer);