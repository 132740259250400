import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { AppointmentUtils } from "../../../../common/AppointmentUtils";
import { IOptionSetAttribute } from "../../../../contracts/swagger/_generated";

import * as ReservationsSelectors from "../../../ReservationsView/redux/ReservationsSelectors";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { AppointmentDetailsActions } from "../../redux/AppointmentDetailsActions";
import { AppointmentStatusReasons } from "../../../../common/Constants";

import "./CancelAppointmentModal.scss";

//Props passed by parent component
interface ICancelAppointmentProvidedProps extends IRapComponentProperties {
}

interface ICancelAppointmentOwnProps {
    isCancelAppointmentOpen: boolean;
    appointmentStatuses: IOptionSetAttribute[];
    appointmentStatusReasons: IOptionSetAttribute[];
}

interface ICancelAppointmentState {
    cancelReason: string;
    isDirty: boolean;
    isLoading: boolean;
}

export type ICancelAppointmentProps = ConnectedProps<typeof connector> & ICancelAppointmentProvidedProps;

class CancelAppointmentInitializer extends RapComponent<ICancelAppointmentProps, ICancelAppointmentState> {
    private appointmentUtils: AppointmentUtils;

    constructor(props: ICancelAppointmentProps, context: IRapComponentContext) {
        super(props, context);
        this.appointmentUtils = new AppointmentUtils();
        this.state = { cancelReason: "", isDirty: false, isLoading: false }
    }

    private _onCancelConfirmation = (): void => {
        const statusId = this.appointmentUtils.getAppointmentStatusId(AppointmentStatusReasons.Cancelled.toString(), this.props.appointmentStatuses);
        const statusReasonId = this.appointmentUtils.getAppointmentStatusReasonId(this.state.cancelReason, this.props.appointmentStatusReasons);

        this.props.setAppointmentUpdates({ statusId: statusId, statusReasonId: statusReasonId });
        this.setState({ isLoading: true });
        this.props.updateAppointment().then(() => {
            this.props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView.modals.cancelAppointment.cancelSuccess });
        }).catch(() => {
            this.props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView.modals.cancelAppointment.cancelError });
        }).finally(() => {
            this.setState({ isLoading: false });
            this._onHideCancelDialog();
        });
    };

    private _onHideCancelDialog = (): void => {
        this.setState({ cancelReason: "", isDirty: false });
        this.props.updateModals({ displayCancelAppointmentModal: false });
    };

    private _onCancellationReasonDropDownChange(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) {
        this.setState({ cancelReason: item.key.toString(), isDirty: true });
    }

    public render() {
        const cancelDropDownOptions = [
            { key: AppointmentStatusReasons.Junk, text: localizedStrings.AppointmentStatusReasons.junk },
            { key: AppointmentStatusReasons.NoShow, text: localizedStrings.AppointmentStatusReasons.noShow },
            { key: AppointmentStatusReasons.OutOfArea, text: localizedStrings.AppointmentStatusReasons.outOfArea },
            { key: AppointmentStatusReasons.OutOfScopeGeneral, text: localizedStrings.AppointmentStatusReasons.outOfScopeGeneral },
            { key: AppointmentStatusReasons.OutOfScopeCommerialSupport, text: localizedStrings.AppointmentStatusReasons.outOfScopeCommercialSupport },
            { key: AppointmentStatusReasons.Staffing, text: localizedStrings.AppointmentStatusReasons.staffing },
            { key: AppointmentStatusReasons.TechSupport, text: localizedStrings.AppointmentStatusReasons.techSupport }
        ];

        return (
            <Dialog
                hidden={!this.props.isCancelAppointmentOpen}
                onDismiss={this._onHideCancelDialog}
                minWidth="360px"
                dialogContentProps={{
                    type: DialogType.close,
                    title: <DialogHeader title={localizedStrings.AppointmentCommandBar.cancel}/>,
                    closeButtonAriaLabel: localizedStrings.AppointmentCommandBar.cancel}}>
                <Dropdown
                    className="c-cancel-dropdown"
                    options={cancelDropDownOptions}
                    placeholder={localizedStrings.AppointmentCommandBar.selectReason}
                    label={localizedStrings.AppointmentCommandBar.reasonForCancellation}
                    selectedKey={this.props.appointmentCancellationReason}
                    onChange={(event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) =>
                        this._onCancellationReasonDropDownChange(event, item)
                    }
                    ariaLabel={localizedStrings.AppointmentCommandBar.reasonForCancellation}
                />
                <DialogFooter>
                    <PrimaryButton 
                        onClick={this._onCancelConfirmation} 
                        text={localizedStrings.AppointmentCommandBar.cancel}
                        disabled={!this.state.isDirty || this.state.isLoading}
                    >
                        {this.state.isLoading && (
                            <Spinner size={SpinnerSize.small}/>
                        )}
                    </PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: ICancelAppointmentProvidedProps): Partial<ICancelAppointmentOwnProps> {
    return {
        ...providedProps,
        isCancelAppointmentOpen: PageSelectors.getModals(state)?.displayCancelAppointmentModal,
        appointmentStatuses: ReservationsSelectors.getResources(state)?.appointmentStatuses,
        appointmentStatusReasons: ReservationsSelectors.getResources(state)?.appointmentStatusReasons,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setAppointmentUpdates: AppointmentDetailsActions.setAppointmentUpdates,
    updateAppointment: AppointmentDetailsActions.updateAppointment,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CancelAppointmentModal = connector(CancelAppointmentInitializer)
