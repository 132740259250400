import React, {FC, useEffect, useState} from "react";
import { IBasePickerSuggestionsProps, IPersonaProps } from "@fluentui/react";
import { connect, ConnectedProps } from "react-redux";

import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../../platform/Layout";
import { BasePeoplePicker, IPeoplePickerObject } from "../BasePeoplePicker/BasePeoplePicker";

import { IAssociate, IMRSEmployee, User } from "../../../../../contracts/swagger/_generated";
import { ContactsActions } from "../../../../../views/ContactsView/redux/ContactsActions";
import { SearchBoxResultsLimit } from "../../../../Constants";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { isNullOrWhitespace } from "../../../../AppointmentUtils";
import { UserRole } from "../../../../../platform/components/Page/Contracts";

import * as FeatureManagementSelectors from "../../../../../views/FeatureManagement/redux/FeatureManagementSelectors";
import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors";
import * as ContactsSelectors from "../../../../../views/ContactsView/redux/ContactsSelectors";

import "./AssignedToSearchBox.scss";
import { toInteger } from "lodash";


//Props passed by parent component
interface IAssignedToSearchBoxProvidedProps extends IRapComponentProperties {
    availableAssociates?: User[];
    value: IAssociate;
    onBlur?: () => void;
    onSelect?: (associate: IAssociate) => void;
    disabled?: boolean;
    defaultValue?: IMRSEmployee;
}

//Props mapped from state object
interface IAssignedToSearchBoxInitializerOwnProps {
    storeAssociates?: IAssociate[];
    storeId?: string;
    enableScheduleAutoAssign: boolean;
    userRole: UserRole;
}

// eslint-disable-next-line
export type IAssignedToSearchBoxInitializerProps = ConnectedProps<typeof connector> & IAssignedToSearchBoxProvidedProps;

const AssignedToSearchBoxInitializer: FC<IAssignedToSearchBoxInitializerProps> = (props) => {
    const [associateList, setAssociateList] = useState<IPeoplePickerObject[]>();
    const [selectedAssociate, setSelectedAssociate] = useState<IPeoplePickerObject>();

    useEffect(() => {
        if(props.value) {
            let defaultValue = { 
                persona: {
                    imageUrl: props.value.profileImage,
                    text: `${props.value.firstName} ${props.value.lastName}`,
                    secondaryText: props.value.email,
                    className: "c-dropdown-option"
                }, 
                id: props.value.systemUserId, 
                bookableResourceId: props.value.bookableResourceId,
                data: { user: props.value } 
            } as IPeoplePickerObject;

            setAssociateList([defaultValue]);
            setSelectedAssociate(defaultValue);
        }
    }, []);

    const suggestionProps: IBasePickerSuggestionsProps = {
        noResultsFoundText: localizedStrings.CreateEditAppointmentModal.noSearchResults,
    };

    const onSelect = (item: IPeoplePickerObject) => {
        setSelectedAssociate(item);
        props.onSelect(item?.data?.user);
    }

    const onFilterChanged = (filterText: string, currentPersonas: IPersonaProps[], limitResults?: number): Promise<IPersonaProps[]> => {
        return new Promise<IPersonaProps[]>((resolve, reject) => {
            if(isNullOrWhitespace(filterText)) {
                resolve([]);
                return;
            }

            if(props.userRole == UserRole.Admin) {
                props.searchForAssociate(filterText).then((results: IAssociate[]) => {
                    processAssociates(results, resolve, reject, limitResults);
                }).catch(() => {
                    reject();
                });
            }
            else {
                props.searchForStoreAssociate(filterText, toInteger(props.storeId)).then((results: IAssociate[]) => {
                    processAssociates(results, resolve, reject, limitResults);
                }).catch(() => {
                    reject();
                });
            }
        });
    }

    const processAssociates = (results: IAssociate[], 
            resolve: (value: IPersonaProps[] | PromiseLike<IPersonaProps[]>) => void, 
            reject: (reason?: any) => void,
            limitResults: number
        ) => {
        // as part of this callback we need to set the associateList, 
        // AS WELL AS return the list as a persona props array for the people picker.   

        // these two arrays are for setting the associateList
        let available: IPeoplePickerObject[] = [];
        let unavailable: IPeoplePickerObject[] = [];

        // this is for returning the personas
        let personaObjects: IPersonaProps[] = [];

        // loop through each associate and determine whether they're available or not
        results.forEach(user => {
            //if auto assign isnt enabled then just show the email as the secondary text
            if(!props.enableScheduleAutoAssign) {
                let persona = {
                    imageUrl: user.profileImage,
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: user.email,
                    className: "c-dropdown-option"
                };
                available.push({ 
                    persona: persona, 
                    id: user.systemUserId, 
                    data: { user: user } 
                });
                personaObjects.push(persona);
                return;
            }

            let isAssociateAvailable = props.availableAssociates?.find(a => {
                return a.bookableResourceId == user.bookableResourceId;
            });

            let persona: IPersonaProps;
            if(isAssociateAvailable) {
                persona = {
                    imageUrl: user.profileImage,
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: "Available",
                    className: "c-dropdown-option available"
                };
                available.push({ 
                    persona: persona,
                    id: user.systemUserId,
                    bookableResourceId: user.bookableResourceId,
                    data: { user: user }
                });
            }
            else {
                persona = {
                    imageUrl: user.profileImage,
                    text: `${user.firstName} ${user.lastName}`,
                    secondaryText: "Unavailable",
                    className: "c-dropdown-option unavailable"
                }
                unavailable.push({
                    persona: persona,
                    id: user.systemUserId,
                    bookableResourceId: user.bookableResourceId,
                    data: { user: user }
                })
            }
            // add each persona to the array
            personaObjects.push(persona);
        });
        // the currently selected associate needs to be in the list in order to show up in the box
        if(selectedAssociate) {
            available.push(selectedAssociate);
        }

        let allAssociates = available.concat(unavailable);
        setAssociateList(allAssociates);

        if(limitResults) {
            resolve(personaObjects.slice(0, limitResults));
        }
        else {
            resolve(personaObjects);
        }
    }

    const onEmptyResolveSuggestions = (currentPersonas: IPersonaProps[]): IPersonaProps[] | Promise<IPersonaProps[]> => {
        let filteredPersonas: IPersonaProps[] = [];
        return filteredPersonas;
    };

    return (
        <BasePeoplePicker
            options={associateList}
            onSelect={onSelect}
            onBlur={props.onBlur}
            value={props.value?.systemUserId}
            limitResults={SearchBoxResultsLimit}
            className={"c-assigned-search"}
            disabled={props.disabled}
            pickerSuggestions={suggestionProps}
            onEmptyResolveSuggestions={onEmptyResolveSuggestions}
            onFilterChanged={onFilterChanged}
        />
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IAssignedToSearchBoxProvidedProps): Partial<IAssignedToSearchBoxInitializerOwnProps> {
    return {
        ...providedProps,
        storeAssociates: ContactsSelectors.getStoreAssociates(state),
        enableScheduleAutoAssign: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableScheduleAutoAssignment", false),
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        userRole: PageSelectors.getUserData(state)?.userRole
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchAssociateData: ContactsActions.fetchAssociateData,
    searchForAssociate: ContactsActions.searchForAssociate,
    searchForStoreAssociate: ContactsActions.searchForStoreAssociate
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const AssignedToSearchBox = connector(AssignedToSearchBoxInitializer);
