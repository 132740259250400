import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TextField } from "@fluentui/react";

import { IRapComponentProperties } from "../../../../../platform/Layout";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { ICase } from "../../../../../contracts/swagger/_generated";

import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";

import "./DynamicsCase.scss";


//Props passed by parent component
interface IDynamicsCaseProvidedProps extends IRapComponentProperties {
    case: ICase;
}

interface IDynamicsCaseOwnProps extends IDynamicsCaseProvidedProps {
    contactCases: ICase[];
}


export type IDynamicsCaseProps = IDynamicsCaseOwnProps & typeof ActionsToDispatch;

const DynamicsCaseInitializer : FC<IDynamicsCaseProps> = (props) => {

    const [selectedCase, setSelectedCase] = useState(props.case);
    
    useEffect(() => {
        // in the scenario where we're editing a case, the case does not load with the appointment (only the caseId is returned)
        // so we need to find the selected case within the list of cases for the contact
        // check if the contact cases are loaded and we have a case with an id but no case number.
        if(props.case && props.contactCases && props.case.caseId && !props.case.caseNumber) {
            let val = props.contactCases.find(x => x.caseId == props.case?.caseId);
            setSelectedCase(val);
        }
        else {
            setSelectedCase(props.case);
        }
    }, [props.case, props.contactCases]);
    

    return (
        <TextField
            label={localizedStrings.CreateEditAppointmentModal?.dynamicsCaseLabel}
            className="c-column-wide"
            value={selectedCase?.caseNumber}
            disabled
        />
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IDynamicsCaseProvidedProps): Partial<IDynamicsCaseOwnProps> {
    return {
        ...providedProps,
        contactCases: AppointmentModalSelectors.getResources(state)?.dynamicsCases
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    
};

export const DynamicsCase = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(DynamicsCaseInitializer);