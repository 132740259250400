import React from "react";
import { connect } from "react-redux";
import { Icon, Link, Text } from "@fluentui/react";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { Card } from "../../../../../../common/components/Card/Card";
import { AppointmentDetail } from "../../../../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { AppointmentStatus, TextSizes } from "../../../../../../common/Constants";
import { PageActions } from "../../../../../../platform/components/Page/redux/PageActions";

import * as AppointmentDetailsSelectors from "../../../../redux/AppointmentDetailsSelectors";

import "./AssociateNotes.scss";

//Props passed by parent component
interface IAssociateNotesProvidedProps extends IRapComponentProperties {
}

interface IAssociateNotesOwnProps extends IAssociateNotesProvidedProps {
    appointment?: AppointmentDetail
}

interface IAssociateNotesState {

}

export type IAssociateNotesProps = IAssociateNotesOwnProps & typeof ActionsToDispatch;

class AssociateNotesInitializer extends RapComponent<IAssociateNotesProps, IAssociateNotesState> {
    constructor(props: IAssociateNotesProps, context: IRapComponentContext) {
        super(props, context);
    }

    private _onEditClick = () => {
        this.props.updateModals({displayEditAssociateNotes: true});
    }

    public render() {
        return (
            <Card className="c-notes-container">
                <div className="flex-row">
                    <div className="flex-grow">
                        <Text variant={TextSizes.large} className="c-header">{localizedStrings.AppointmentDetailsView.AssociateNotes.title}</Text>
                    </div>
                    {this.props.appointment?.appointmentDetails?.status?.value != AppointmentStatus.Cancelled && (
                        <Link 
                            className="c-status-item" 
                            onClick={this._onEditClick} 
                        >
                            <Icon iconName="Edit" ariaLabel={localizedStrings.AppointmentDetailsView.AssociateNotes.editLabel} />
                        </Link>
                    )}
                </div>
                <div className="c-text-container">
                    <Text variant={TextSizes.medium}>{this.props.appointment?.appointmentDetails?.associateNotes}</Text>
                </div>
            </Card>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAssociateNotesProvidedProps): Partial<IAssociateNotesOwnProps> {
    return {
        ...providedProps,
        appointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AssociateNotesInitializer);