import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";

import { ICustomerInsightsDto } from "../../../../../../contracts/swagger/_generated";

import { ContactDetails } from "./components/ContactDetails/ContactDetails";
import ContactNotes from "./components/ContactNotes/ContactNotes";
import { ContactDemographics } from "./components/ContactDemographics/ContactDemographics";
import { ContactProductSuggestions } from "./components/ContactProductSuggestions/ContactProductSuggestions";
import { ContactPurchaseHistory } from "./components/ContactPurchaseHistory/ContactPurchaseHistory";

import { IRapComponentProperties } from "../../../../../../platform/Layout";

import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallback } from "../../../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

//import "./ContactProfile.scss";

interface IContactProfileProvidedProps extends IRapComponentProperties {
}

interface IContactProfileOwnProps extends IContactProfileProvidedProps {
    displayContactMenuProfile: boolean;
    selectedContactInsights: ICustomerInsightsDto;
}

export type IContactProfileProps = ConnectedProps<typeof connector> & IContactProfileProvidedProps;

const ContactProfileInitializer: FC<IContactProfileProps> = props => {
    return (
        <>
            <div className="flex-row c-contact-container">
                <div className="flex-column flex">
                    <div className="flex-row">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactDetails />
                        </ErrorBoundary>
                    </div>

                    <div className="flex-row">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactDemographics />
                        </ErrorBoundary>
                    </div>
                    
                    <div className="flex-row">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactProductSuggestions />
                        </ErrorBoundary>
                    </div>
                </div>

                <div className="flex-column flex-2">
                    <div className="flex-row">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactPurchaseHistory />
                        </ErrorBoundary>
                    </div>

                    <div className="flex-row">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactNotes/>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactProfileProvidedProps): Partial<IContactProfileOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactProfile = connector(ContactProfileInitializer);
