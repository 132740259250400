import React, { FC, FormEvent, useEffect, useState } from "react";
import { connect } from "react-redux";

import { DefaultButton, Dropdown, ICommandBarItemProps, IDropdownOption, PrimaryButton, SearchBox} from "@fluentui/react";
import { IRapComponentProperties } from "../../../../Layout";

import { IClientelingViewState } from "../../../../../pages/Contracts";

import * as PageSelectors from "../../../Page/redux/PageSelectors";


import "./SearchBar.scss";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { PageActions } from "../../../Page/redux/PageActions";
import { ReservationsActions } from "../../../../../views/ReservationsView/redux/ReservationsActions";

interface ISearchBarProvidedProps extends IRapComponentProperties {
    viewDropdownOptions: IDropdownOption[];
    onChangeView: (event: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption<any>, index?: number) => void;
    onSearchSelected: () => void;
}

interface ISearchBarOwnProps extends ISearchBarProvidedProps {
    selectedView?: string;
    storeId?: string;
}

export type ISearchBarProps = ISearchBarOwnProps & typeof ActionsToDispatch;

const SearchBarInitializer: FC<ISearchBarProps> = (props) => {

    useEffect(() => {
        props.updatePanels({ displayAppointmentStateFilter: false });
    }, [])

    const _onSearchTermChanged = (searchTerm: string | undefined): void => {
    };

    const _onSearch = (val: string) => {
        props.searchForAppointments(props.storeId, val);
    };

    const _onClear = () => {
    };
    return (
        <div className="c-clienteling-cmdbar flex-row">
            <div className="flex-column c-nav-item left-item">
                <Dropdown
                    ariaLabel={props.selectedView}
                    selectedKey={props.selectedView}
                    options={props.viewDropdownOptions}
                    onChange={props.onChangeView}
                />
            </div>
            <div className="flex-column flex-grow" />
            <div className="flex-column c-nav-item">
                <SearchBox
                    autoFocus
                    className="c-search-box"
                    placeholder={localizedStrings.CommandBar.searchPlaceholder}
                    onChange={(event: any, query: string) => _onSearchTermChanged(query)}
                    onSearch={_onSearch}
                    value={props.searchTerm}
                    ariaLabel={localizedStrings.CommandBar.searchPlaceholder}
                    onClear={_onClear}
                />
            </div>
            <div className="flex-column c-nav-item">
                <PrimaryButton text={localizedStrings.CommandBar.close} onClick={props.onSearchSelected}/>
            </div>
        </div>
    )
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: ISearchBarProvidedProps): Partial<ISearchBarOwnProps> {
    return {
        ...providedProps,
        selectedView: PageSelectors.getViewOptions(state)?.selectedView,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updatePanels: PageActions.updatePanels,
    searchForAppointments: ReservationsActions.searchForAppointments
};

export const SearchBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(SearchBarInitializer);
