import { FC} from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRapComponentProperties } from "../../../platform/Layout";

import { ActionButton, format, Text } from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";

import * as LocaleUtils from "../../../platform/core/util/Locale";
import AppointmentPrimaryButton from "../../../common/components/AppointmentButtons/components/AppointmentPrimaryButton";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import { AppointmentStatusReasons, DynamicsCaseUrl } from "../../../common/Constants";
import { PermissionGate } from "../../../common/components/PermissionGate/PermissionGate";
import { PageActions } from "../Page/redux/PageActions";
import { UserRole } from "../Page/Contracts";
import { IClientelingViewState } from "../../../pages/Contracts";
import { AppointmentDetailDto } from "./../../../contracts/swagger/_generated";

import * as PageSelectors from "../Page/redux/PageSelectors";
import * as FeatureManagementSelectors from "../../../views/FeatureManagement/redux/FeatureManagementSelectors";

import * as Constants from "../../../common/Constants";

import "./AppointmentCommandBar.scss";

//Props passed by parent component
interface IAppointmentCommandBarProvidedProps extends IRapComponentProperties {
    selectedAppointment?: AppointmentDetailDto;
}

interface IAppointmentCommandBarOwnProps extends IAppointmentCommandBarProvidedProps {
    retailEventsUrl?: string;
    isLeadsEnabled?: boolean;
}

interface IAppointmentCommandBarState {
    
}

export type IAppointmentCommandBarProps = IAppointmentCommandBarOwnProps & typeof ActionsToDispatch;

const AppointmentCommandBar: FC<IAppointmentCommandBarProps> = (props) => {
    const isAppointmentCancellable = (props.selectedAppointment?.status?.label === AppointmentStatusReasons.Scheduled);
    const navigate = useNavigate();

    const _onShareButtonClick = (): void => {
        props.updateModals({ displayShareAppointmentModal: true });
    }

    const _onCancelButtonClick = (): void => {
        props.updateModals({ displayCancelAppointmentModal: true })
    }

    const _getAppointmentTitle = (): string => {
        return props.selectedAppointment?.appointmentType?.name;
    }

    const _isLeadEnabled = (): boolean => {
        let apptType = props.selectedAppointment?.appointmentType?.name?.toLowerCase();
        return (apptType?.includes(Constants.LeadKeyWords.Training) && apptType.includes(Constants.LeadKeyWords.Business)) || apptType?.includes(Constants.LeadKeyWords.Consultation);
    }

    const _onLeadButtonClick = (): void => {
        props.updateModals({ displayCreateLeadModal: true });
    }

    return (
        <div className="c-appointment-commandBar flex-row">
            <div>
                <ActionButton
                    className="c-reservations-button" 
                    ariaLabel={localizedStrings.AppointmentCommandBar.reservations} 
                    onClick={() => navigate(`/${LocaleUtils.getLocale()}/reservations`)}>
                    {localizedStrings.AppointmentCommandBar.reservations} {'>'} 
                </ActionButton>
                <Text className="c-appointment-title">{_getAppointmentTitle()}</Text>
            </div>
            <span className="c-vertical-separator"></span>
            <div className="c-button-container flex-grow flex-row">
                <div className="flex-grow">
                    <ActionButton
                        iconProps={{ iconName: 'Cancel' }}
                        hidden={!isAppointmentCancellable}
                        ariaLabel={localizedStrings.AppointmentCommandBar.cancel}
                        onClick={_onCancelButtonClick}>
                            {localizedStrings.AppointmentCommandBar.cancel}
                    </ActionButton>
                    {props.selectedAppointment?.case?.caseId && (
                        <ActionButton
                            className="c-link"
                            href={format(DynamicsCaseUrl, props.retailEventsUrl, props.selectedAppointment.case.caseId)}
                            target="_blank"
                            ariaLabel={localizedStrings.AppointmentCommandBar.openCase}
                        >
                            <div className="c-appointment-opencase-icon">
                                <Icon iconName={Constants.AppointmentIcon_OpenCaseLink}></Icon>
                            </div>
                            <div className="c-appointment-opencase-text">
                                {localizedStrings.AppointmentCommandBar.openCase}
                            </div>
                        </ActionButton>
                    )}
                    {_isLeadEnabled() && props.isLeadsEnabled && (
                        <ActionButton
                            iconProps={{ iconName: 'PeopleAdd' }}
                            ariaLabel={localizedStrings.AppointmentCommandBar.createLead}
                            onClick={_onLeadButtonClick}
                            disabled={props.selectedAppointment?.leadId != undefined}
                        >
                            {localizedStrings.AppointmentCommandBar.createLead}
                        </ActionButton>
                    )}
                    {props.selectedAppointment?.teamsDialIn && (
                        <ActionButton
                            iconProps={{ iconName: 'Share' }}
                            ariaLabel={localizedStrings.AppointmentCommandBar.share}
                            onClick={_onShareButtonClick}>
                            {localizedStrings.AppointmentCommandBar.share}
                        </ActionButton>  
                    )}
                </div>
            </div>
            {!props.isLoading && (
                <div className="c-button-container flex-grow flex-row">
                    <div className="flex-grow">
                        <PermissionGate minimumUserRole={UserRole.Associate}>
                            <AppointmentPrimaryButton className="c-primary-button" appointment={props.selectedAppointment}/>
                        </PermissionGate>
                    </div>
                </div>
            )}
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentCommandBarProvidedProps): Partial<IAppointmentCommandBarOwnProps> {
    return {
        ...providedProps,
        storeId: PageSelectors.getViewOptions(state)?.selectedStore,
        selectedDate: PageSelectors.getViewOptions(state)?.selectedDate,
        retailEventsUrl: PageSelectors.getResources(state)?.retailEventsUrl,
        isLeadsEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableLeads", false)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals
};

export const ConnectedAppointmentCommandBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentCommandBar);
