import { AppointmentCustomQuestionDto, AppointmentDetail, AppointmentModel, IAppointmentForUpdateDto, ILead, ILeadForCreationDto, ILeadPromoCodeResponse } from "../../../contracts/swagger/_generated";
import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../platform/redux/ActionHelper";
import { IMessages } from "../Contracts";

export enum AppointmentDetailsActionTypes {
    InitAppointmentDetailsView = "AppointmentDetails/InitAppointmentDetailsView",

    CreateLead = "AppointmentDetails/CreateLead",
    CreateLeadFailure = "AppointmentDetails/CreateLeadFailure",
    CreateLeadSuccess = "AppointmentDetails/CreateLeadSuccess",

    FetchAppointmentDetail = "AppointmentDetails/FetchAppointmentDetail",
    FetchAppointmentDetailSuccess = "AppointmentDetails/FetchAppointmentDetailSuccess",
    FetchAppointmentDetailFailure = "AppointmentDetails/FetchAppointmentDetailFailure",

    UpdateAppointment = "AppointmentDetails/UpdateAppointment",
    UpdateAppointmentSuccess = "AppointmentDetails/UpdateAppointmentSuccess",
    UpdateAppointmentFailure = "AppointmentDetails/UpdateAppointmentFailure",

    FetchAppointmentQuestions = "AppointmentDetails/FetchAppointmentQuestions",
    FetchAppointmentQuestionsSuccess = "AppointmentDetails/FetchAppointmentQuestionsSuccess",
    FetchAppointmentQuestionsFailure = "AppointmentDetails/FetchAppointmentQuestionsFailure",

    FetchLeadPromoCodes = "AppointmentDetails/FetchLeadPromoCodes",
    FetchLeadPromoCodesSuccess = "AppointmentDetails/FetchLeadPromoCodesSuccess",
    FetchLeadPromoCodesFailure = "AppointmentDetails/FetchLeadPromoCodesFailure",

    SetAppointmentUpdates = "AppointmentDetails/SetAppointmentUpdates",

    SetMessages = "AppointmentDetails/SetMessages",

    ReceivedAppointmentUpsertMessage = "AppointmentDetails/ReceivedAppointmentUpsertMessage",

    UpdateClientedAppointmentFieldToTrue = "AppointmentDetails/UpdateClientedAppointmentFieldToTrue"
}

const AppointmentDetailsStandardActions = {
    initAppointmentDetailsView: () =>
        createAction(AppointmentDetailsActionTypes.InitAppointmentDetailsView),

    createLeadSuccess: () => createAction(AppointmentDetailsActionTypes.CreateLeadSuccess),
    createLeadFailure: (error: string) => createAction(AppointmentDetailsActionTypes.CreateLeadFailure, error),

    updateAppointmentSuccess: () =>
        createAction(AppointmentDetailsActionTypes.UpdateAppointmentSuccess),
    updateAppointmentFailure: (error: string) => createAction(AppointmentDetailsActionTypes.UpdateAppointmentFailure, error),

    fetchAppointmentDetail: (storeId: string, appointmentId: string, silentFetch?: boolean) =>
        createAction(AppointmentDetailsActionTypes.FetchAppointmentDetail, { storeId, appointmentId, silentFetch }),
    fetchAppointmentDetailSuccess: (appointment: AppointmentDetail) =>
        createAction(AppointmentDetailsActionTypes.FetchAppointmentDetailSuccess, { appointment }),
    fetchAppointmentDetailFailure: (error: string) => createAction(AppointmentDetailsActionTypes.FetchAppointmentDetailFailure, error),

    fetchAppointmentQuestions: (storeId: string, typeId: string, categoryId: string) =>
        createAction(AppointmentDetailsActionTypes.FetchAppointmentQuestions, { storeId, typeId, categoryId }),
    fetchAppointmentQuestionsSuccess: (questions: AppointmentCustomQuestionDto[]) =>
        createAction(AppointmentDetailsActionTypes.FetchAppointmentQuestionsSuccess, questions),
    fetchAppointmentQuestionsFailure: (error: string) => createAction(AppointmentDetailsActionTypes.FetchAppointmentQuestionsFailure, error),

    fetchLeadPromoCodes: () => createAction(AppointmentDetailsActionTypes.FetchLeadPromoCodes),
    fetchLeadPromoCodesSuccess: (response: ILeadPromoCodeResponse) => createAction(AppointmentDetailsActionTypes.FetchLeadPromoCodesSuccess, response),
    fetchLeadPromoCodesFailure: (error: string) => createAction(AppointmentDetailsActionTypes.FetchLeadPromoCodesFailure, error),

    setMessages: (messages: IMessages) => createAction(AppointmentDetailsActionTypes.SetMessages, messages),

    receivedAppointmentUpsertMessage: (body: AppointmentModel) => createAction(AppointmentDetailsActionTypes.ReceivedAppointmentUpsertMessage, body),

    updateClientedAppointmentFieldToTrue: (storeId: string, appointmentId: string) => 
        createAction(AppointmentDetailsActionTypes.UpdateClientedAppointmentFieldToTrue, {storeId, appointmentId})
}

const AppointmentDetailsThunkActions = {
    updateAppointment: () => createActionWithCallback<void>(AppointmentDetailsActionTypes.UpdateAppointment),
    createLead: (lead: ILeadForCreationDto) => 
        createActionWithCallback<ILead>(AppointmentDetailsActionTypes.CreateLead, { lead }),
    setAppointmentUpdates: (appt?: IAppointmentForUpdateDto) => createActionWithCallback<void>(AppointmentDetailsActionTypes.SetAppointmentUpdates, appt)
}  

const AppointmentDetailsThunkActionsTypes = {
    createLead: (lead: ILeadForCreationDto) => 
        createThunkActionType(AppointmentDetailsActionTypes.CreateLead, { lead }),
    updateAppointment: () => createThunkActionType(AppointmentDetailsActionTypes.UpdateAppointment),
    setAppointmentUpdates: (appt: IAppointmentForUpdateDto) => createThunkActionType(AppointmentDetailsActionTypes.SetAppointmentUpdates, appt)
}

export const AppointmentDetailsActions = {...AppointmentDetailsStandardActions, ...AppointmentDetailsThunkActions};
const AllActionsTypes = {...AppointmentDetailsStandardActions, ...AppointmentDetailsThunkActionsTypes};
export type AppointmentDetailsActionsType = ActionsUnion<typeof AllActionsTypes>;
