import React, { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Icon, Text } from "@fluentui/react";
import moment from "moment-timezone";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../../../pages/Contracts";
import { IAppointmentDto } from "../../../../../../../../contracts/swagger/_generated";
import { TextSizes } from "../../../../../../../../common/Constants";
import { AppointmentUtils } from "../../../../../../../../common/AppointmentUtils";

import "./ContactAppointment.scss";

//Props passed by parent component
interface IContactAppointmentProvidedProps extends IRapComponentProperties {
    appointment: IAppointmentDto;
    className?: string;
}

interface IContactAppointmentOwnProps extends IContactAppointmentProvidedProps {
}

export type IContactAppointmentProps = IContactAppointmentOwnProps & typeof ActionsToDispatch;

const ContactAppointmentInitializer:FC<IContactAppointmentProps> = (props) => {

    const appointmentUtils = useRef(new AppointmentUtils());

    const getTimeFrame = () => {
        let startTime = moment(props.appointment?.appointmentDate);
        let endTime = moment(props.appointment?.scheduledEndDate);

        return `${startTime.tz(moment.tz.guess()).format("MMM Do, YYYY h:mm A")} - ${endTime.tz(moment.tz.guess()).format("h:mm A z")}`
    }

    return (
        <div className={`${props.className} flex-row c-appointment-container`}>
            <div className="c-icon-container">
                <div className="c-icon">
                    <Icon 
                        iconName={appointmentUtils.current.getAppointmentIconName(props.appointment?.appointmentType)} 
                    />
                </div>
            </div>
            <div className="flex-column flex">
                <Text className="flex c-appointment-title" variant={TextSizes.medium} nowrap>
                        {props.appointment?.subject}
                </Text>
                <div className="flex-row flex">
                    <Text className="c-appointment-info" variant={TextSizes.smallPlus}>
                        {getTimeFrame()}
                    </Text>
                    <Text className="c-appointment-info right flex" variant={TextSizes.smallPlus}>
                        {props.appointment?.status}
                    </Text>
                </div>
                <Text className="flex c-appointment-info" variant={TextSizes.smallPlus}>
                    {props.appointment?.confirmationNumber}
                </Text>
            </div>
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactAppointmentProvidedProps): Partial<IContactAppointmentOwnProps> {
    return {
        ...providedProps,
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ContactAppointmentInitializer);