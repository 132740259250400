import { produce } from "immer";
import moment from "moment";

import { IPageViewState, NavMenuItem, UserRole } from "../Contracts";
import { PageActionsType, PageActionTypes } from "./PageActions";
import { AssignTo } from "../../../../common/Constants";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";

import * as StorageUtils from "../../../core/util/Storage";
import * as Constants from "../../../../common/Constants";

export function pageReducer(state: IPageViewState, action: PageActionsType): IPageViewState {
    return produce(state || ({} as IPageViewState), draft => {
        switch (action.type) {
            case PageActionTypes.InitPage: {
                let startofDay = new Date();
                startofDay.setHours(0, 0, 0, 0);
                StorageUtils.setUserPreferences(Constants.SelectedDate, startofDay.toString());
                let view = StorageUtils.getUserPreferences(Constants.SelectedView);
                if (!view) {
                    StorageUtils.setUserPreferences(Constants.SelectedView, Constants.ViewType.Card);
                }
                let appointmentState = StorageUtils.getUserPreferences(Constants.SelectedAppointmentState);
                if (!appointmentState) {
                    StorageUtils.setUserPreferences(Constants.SelectedAppointmentState, Constants.AppointmentState.Scheduled.toString());
                }
                draft.ux = {
                    viewOptions: {
                        selectedDate: moment(StorageUtils.getUserPreferences(Constants.SelectedDate)),
                        selectedView: StorageUtils.getUserPreferences(Constants.SelectedView),
                        selectedStore: StorageUtils.getUserPreferences(Constants.SelectedStoreId),
                        selectedAssignStatus: StorageUtils.getUserPreferences(Constants.SelectedAssignStatus),
                        selectedAppointmentState: StorageUtils.getUserPreferences(Constants.SelectedAppointmentState),
                        selectedAppointmentTypes: StorageUtils.getUserPreferencesObject(Constants.SelectedAppointmentTypes)
                    },
                    contactPages: {
                        displayContactProfilePage: true,
                        displayContactReservationsPage: false
                    },
                    modals: {
                        displayAddClientModal: false,
                        displayCreateAppointmentModal: false,
                        displayCancelAppointmentModal: false,
                        displayShareAppointmentModal: false
                    },
                    panels: {
                        displayAppointmentStateFilter: true,
                        displayUserSettingsPanel: false,
                        displayFilterPanel: false,
                        displayContactMenuPanel: true, //opened by default
                        settingsMenuOpen: false,
                        displayRunnerRequestStateFilter: true,
                        displayAboutPanel: false
                    },
                    menuItems: {
                        navMenuItems: createNavMenuItems(),
                        settingsMenuItems: createSettingsMenuItems()
                    },
                    messages: {}
                }
                draft.data = {
                    userData: {},
                    resources: {}
                };
                break;
            }
            case PageActionTypes.UpdateViewOptions: {
                draft.ux.viewOptions = { ...state.ux.viewOptions, ...action.payload };

                if(action.payload.selectedDate) {
                    StorageUtils.setUserPreferences(Constants.SelectedDate, action.payload.selectedDate.toString());
                }

                if(action.payload.selectedAppointmentTypes) {
                    StorageUtils.setUserPreferencesObject(Constants.SelectedAppointmentTypes, action.payload.selectedAppointmentTypes);
                }

                if(action.payload.selectedStore) {
                    StorageUtils.setUserPreferences(Constants.SelectedStoreId, action.payload.selectedStore)
                }

                if(action.payload.selectedAssignStatus) {
                    StorageUtils.setUserPreferences(Constants.SelectedAssignStatus, action.payload.selectedAssignStatus);
                }

                if(action.payload.selectedAppointmentState) {
                    StorageUtils.setUserPreferences(Constants.SelectedAppointmentState, action.payload.selectedAppointmentState);
                }

                if (action.payload.selectedView) {
                    StorageUtils.setUserPreferences(Constants.SelectedView, action.payload.selectedView);
                }
                break;
            }
            case PageActionTypes.ChangeContactPage: {
                draft.ux.contactPages = { ...state.ux.contactPages, ...action.payload };
                break;
            }
            case PageActionTypes.UpdatePanels: {
                draft.ux.panels = { ...state.ux.panels, ...action.payload };
                break;
            }
            case PageActionTypes.UpdateModals: {
                draft.ux.modals = { ...state.ux.modals, ...action.payload };
                break;
            }
            case PageActionTypes.FetchPageDataSuccess: {
                const pageData = action.payload;
                draft.data.userData.pageData = pageData;
                draft.data.resources.retailEventsUrl = pageData.retailEventsUrl;

                let persistedStoreId = StorageUtils.getUserPreferences(Constants.SelectedStoreId);
                if (!persistedStoreId) {
                    persistedStoreId = pageData.userDefaultStore;
                    StorageUtils.setUserPreferences(Constants.SelectedStoreId, persistedStoreId);
                }
                draft.ux.viewOptions.selectedStore = persistedStoreId;

                let assignStatus = StorageUtils.getUserPreferences(Constants.SelectedAssignStatus);
                if (!assignStatus) {
                    assignStatus = pageData.isAdmin ? AssignTo.All : AssignTo.AssignedToMe;
                    StorageUtils.setUserPreferences(Constants.SelectedAssignStatus, assignStatus);
                }
                draft.ux.viewOptions.selectedAssignStatus = assignStatus;
                draft.data.userData.userRole = pageData.isAdmin ? UserRole.Admin : UserRole.Associate;
                break;
            }
            case PageActionTypes.FetchAssociateDetailsSuccess: {
                const associateDetails = action.payload;
                draft.data.userData.user = associateDetails;
                break;
            }
            case PageActionTypes.OverrideUserRole: {
                draft.data.userData.userRole = action.payload;
                break;
            }
            case PageActionTypes.FetchStoresSuccess: {
                draft.data.stores = action.payload;
                break;
            }
            case PageActionTypes.FetchPageDataFailure:
            case PageActionTypes.FetchFeedbackURLFailure:
            case PageActionTypes.FetchStoresFailure: {
                draft.ux.messages.errorMessage = action.payload;
                break;
            }
            case PageActionTypes.UpdateTokenFailure: {
                console.error("Failed to sign in");
                break;
            }
            case PageActionTypes.SetTheme: {
                if(!draft.ux) {
                    draft.ux = { theme: action.payload }
                }
                else {
                    draft.ux.theme = action.payload;
                }
                break;
            }
            case PageActionTypes.UpdateMessages: {
                draft.ux.messages = { ...draft.ux.messages, ...action.payload };
                break;
            }
            case PageActionTypes.UpdateNavMenuItems: {
                draft.ux.menuItems.navMenuItems = action.payload;
                break;
            }
            case PageActionTypes.FetchFeedbackURLSuccess: {
                draft.data.resources.feedbackUrl = action.payload;
                break;
            }
            default:
                draft = state;
        }
    });
}

function createNavMenuItems(): NavMenuItem[] {
    let menuItems: NavMenuItem[] = [];
    menuItems.push({
        id: 0,
        title: "Reservations",
        iconName: "",
        minimumRequiredUserRole: UserRole.Associate,
        pageRoute: "/reservations",
        isDefault: true
    });

    menuItems.push({
        id: 1,
        title: localizedStrings.RunnerRequestsView.runnerRequests,
        iconName: "",
        minimumRequiredUserRole: UserRole.Associate,
        pageRoute: "/runnerrequests",
        isDefault: false
    });

    return menuItems;
}

function createSettingsMenuItems(): NavMenuItem[] {
    let menuItems: NavMenuItem[] = [];

    menuItems.push({
        id: 0,
        title: "Admin Settings",
        iconName: "Admin",
        minimumRequiredUserRole: UserRole.Admin
    });

    return menuItems;
}
