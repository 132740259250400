import React from "react";
import { connect } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorBoundaryFallback } from "../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";
import { ContactPanel } from "../../../../platform/components/PanelHandler/components/ContactPanel/ContactPanel";
import { ContactProfile } from "./components/ContactProfile/ContactProfile";
import { ContactReservations } from "./components/ContactReservations/ContactReservations";
import ContactDetails from "./components/ContactDetails/ContactDetails";
import ContactAppointments from "./components/ContactAppointments/ContactAppointments";
import ContactNotes from "./components/ContactProfile/components/ContactNotes/ContactNotes";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IPanels, IContactPages } from "../../../../platform/components/Page/Contracts";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";

import { IClientelingViewState } from "../../../../pages/Contracts";

import "./ContactContainer.scss";
import * as  FeatureManagementSelectors from "../../../FeatureManagement/redux/FeatureManagementSelectors";

//Props passed by parent component
interface IContactContainerProvidedProps extends IRapComponentProperties {
}

interface IContactContainerOwnProps extends IContactContainerProvidedProps {
    panelState: IPanels;
    contactPage: IContactPages;
    enableContact_V2: boolean;
}

interface IContactContainerState {
}

export type IContactContainerProps = IContactContainerOwnProps & typeof ActionsToDispatch;

class ContactContainerInitializer extends RapComponent<IContactContainerProps, IContactContainerState> {
    constructor(props: IContactContainerProps, context: IRapComponentContext) {
        super(props, context);
    }

    public render() {
        return (
            <>
                {!this.props.enableContact_V2 ? (
                    <div className="flex-row c-contact-container">
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactDetails/>
                        </ErrorBoundary>
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactAppointments/>
                        </ErrorBoundary>
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <ContactNotes/>
                        </ErrorBoundary>
                    </div>
                ) : (
                    <div className="flex-row c-contact-container">
                        {this.props.panelState?.displayContactMenuPanel ? (
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <div className="flex-column">
                                    <ContactPanel />
                                </div>
                            </ErrorBoundary>
                        ) : null}

                        <div className="flex-column flex c-contact-v2">
                            {this.props.contactPage.displayContactProfilePage ? (
                                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                    <ContactProfile />
                                </ErrorBoundary>
                            ) : null}

                            {this.props.contactPage.displayContactReservationsPage ? (
                                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                    <ContactReservations />
                                </ErrorBoundary>
                            ) : null}
                        </div>
                    </div>
                )}
            </>
        );
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactContainerProvidedProps): Partial<IContactContainerOwnProps> {
    return {
        ...providedProps,
        panelState: PageSelectors.getPanels(state),
        contactPage: PageSelectors.getContactPage(state),
        enableContact_V2: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableContact_V2", false),
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ContactContainerInitializer);