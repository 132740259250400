import React from "react";
import $ from "jquery";
import Shell from "./platform/components/Shell/Shell";
import * as microsoftTeams from "@microsoft/teams-js";
import { ReactRootComponent } from "./platform/Layout";
import { RapPageContext, IRapPageContext, Services, RestClients, ServiceOptions } from "./platform/Context";
import { RapTelemetryService, IRapTelemetryService } from "./platform/services/Telemetry";
import { RapPageService, IRapPageService } from "./platform/services/Page";
import { RapAuthenticationService, IRapAuthenticationService } from "./platform/services/Authentication";
import { PageTelemetryLogger } from "./platform/components/Page/PageTelemetryLogger";
import { RestClientOptionsProviderService } from "./platform/services/RestClientOptionsProvider";
import { PageFeature } from "./common/Constants";
import { ClientelingApiClient } from "./contracts/swagger/_generated";
import { EventType, EventMessage, AuthenticationResult, InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import { msalInstance } from "./authProvider";
import { Card } from "@uifabric/react-cards";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import "./styling/_authloading.scss";
import * as Constants from "./../src/common/Constants";
import { format } from "./platform/core/util/String";
import { ClientelingRestClientName } from "./platform/RestClientBase";
import { Provider } from "react-redux";
import createStore from "./platform/redux/CreateStore";
import { PageActionTypes } from "./platform/components/Page/redux/PageActions";
import { localizedStrings } from "./common/localization/LocalizedStrings";
import ThemeProvider from "./platform/components/ThemeProvider/ThemeProvider";
import { ReservationsActionTypes } from "./views/ReservationsView/redux/ReservationsActions";
import { AppointmentDetailsActionTypes } from "./views/AppointmentDetailsView/redux/AppointmentDetailsActions";
import { ContactsActionTypes } from "./views/ContactsView/redux/ContactsActions";
import { AppointmentModalActionTypes } from "./common/components/CreateEditAppointmentModal/redux/AppointmentModalActions";

export interface IAppState {
    accessToken: string;
    theme: string;
    fontSize: number;
    isfullScreenMode: boolean;
    teamsLocale: string;
}

class App extends React.Component<{}, IAppState> {
    private pageContext: IRapPageContext;
    private store: any;

    constructor(props: any) {
        super(props);
        this.state = { accessToken: "", theme: "default", fontSize: 10, isfullScreenMode: false, teamsLocale: "" };
        Services.add("IRapPageService", { serviceFactory: RapPageService });
        Services.add("IRapTelemetryService", { serviceFactory: RapTelemetryService, options: ServiceOptions.Persistant });
        Services.add("IRapAuthenticationService", { serviceFactory: RapAuthenticationService });
        Services.add("IRestClientOptionsProviderService", { serviceFactory: RestClientOptionsProviderService });

        RestClients.add(ClientelingRestClientName, { factory: ClientelingApiClient });

        this.pageContext = new RapPageContext(Services, RestClients);

        this.store = createStore(this.pageContext);
        this.store.dispatch({ type: PageActionTypes.InitPage });
        this.store.dispatch({ type: ReservationsActionTypes.InitReservationsView });
        this.store.dispatch({ type: AppointmentDetailsActionTypes.InitAppointmentDetailsView });
        this.store.dispatch({ type: ContactsActionTypes.InitContacts });
        this.store.dispatch({ type: AppointmentModalActionTypes.InitAppointmentModalView });

        msalInstance.addEventCallback((message: EventMessage) => {
            if (message.eventType == EventType.LOGIN_SUCCESS || message.eventType == EventType.SSO_SILENT_SUCCESS) {
                this.store.dispatch({ type: PageActionTypes.UpdateTokenSuccess, payload: message.payload as AuthenticationResult });
            }
            if (message.eventType == EventType.LOGIN_FAILURE || message.eventType == EventType.SSO_SILENT_FAILURE) {
                this.store.dispatch({ type: PageActionTypes.UpdateTokenFailure, payload: message.payload as AuthenticationResult });
            }
        });
    }

    public async componentDidMount() {
        var that = this;
       
        if (this.inTeams() === true) {
            const authService = that.pageContext.getService<IRapAuthenticationService>("IRapAuthenticationService");

            microsoftTeams.initialize();

            microsoftTeams.getContext(function (context) {
                that.setState({ teamsLocale: context.locale });
                that.setScreenMode(context.isFullScreen ? context.isFullScreen : false);
                authService.setMSTeamsContext(context);
            });

            var location = window.location.toString();
            var appIdURI = location.includes("localhost") ? "api://localhost:44392/eb3c2e95-6504-47d5-9e01-67d107ffa764" : location.includes("dev") ? "api://clienteling-dev.azurewebsites.net/aa9777ec-d50b-4707-842e-4a95edc20cdb" : location.includes("ppe") ? "api://clienteling-ppe.azurewebsites.net/3e463456-e1de-4063-b3b8-5eb7c5dda8c6" : "api://clienteling-app.azurewebsites.net/4078ac87-fa53-41a3-85eb-13534060ea33";
            
            var authTokenRequest = {
                successCallback: function (result: any) {
                    that.setTokenFromTeams(result);
                    microsoftTeams.appInitialization.notifySuccess();
                },
                failureCallback: function (error: string) {
                    microsoftTeams.appInitialization.notifyFailure({
                        reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
                        message: error
                    });
                },

                resources: [appIdURI]
            };
            microsoftTeams.authentication.getAuthToken(authTokenRequest);

        } else {
            //we are not running in teams so get the id token from the authprovider
            const authResult = await getMsalAuthResult();
            if (authResult === null) {
                /*
                * User is not signed in yet.
                  Let MSAL redirect for a valid login.
                */
                return;
            }

            const authService = this.pageContext.getService<IRapAuthenticationService>("IRapAuthenticationService");
            authService.setIdToken(authResult.idToken);
        }
        //Services require the auth token to be available
        setTimeout(this.initializeServices.bind(this), 100);
    }

    private setTokenFromTeams(idToken: string) {
        var that = this;
        var overallPageContext = this.pageContext;
        const authService = that.pageContext.getService<IRapAuthenticationService>("IRapAuthenticationService");
        authService.setIdToken(idToken);
        //this was used for images, keeping it in here for future proofing.
        that._createAuthCookie("msal.idtoken", idToken, 1);
        that.setState({
            accessToken: idToken
        });
        microsoftTeams.getContext(function (context) {
            if (context.loginHint) {
                var telemetryLogger: PageTelemetryLogger = new PageTelemetryLogger(
                    overallPageContext,
                    PageFeature.Authentication,
                    "",
                    "AuthSuccess_LogAlias"
                );
                telemetryLogger.captureTelemetry({ loginHint: context.loginHint.toString() });
            }
        });
    }

    private setScreenMode(isfullScreenMode: boolean) {
        setFullScreenMode(isfullScreenMode);

        this.setState({ isfullScreenMode: isfullScreenMode });
    }

    private initializeServices() {
        //Initialize other services
        this.pageContext.getService<IRapTelemetryService>("IRapTelemetryService");
        this.pageContext.getService<IRapPageService>("IRapPageService");
    }

    private _createAuthCookie(cookieName: string, res: string, durationInHours: number) {
        var date = new Date();
        date.setTime(date.getTime() + durationInHours * 60 * 60 * 1000);
        document.cookie = format("{0}={1};expires={2};SameSite=None; Secure", cookieName, res, date.toUTCString());
    }

    private _getCookie(cookieName: string) {
        var name = cookieName + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // This logic is coming from msteams-react-base-component. https://github.com/wictorwilen/msteams-react-base-component#additional-helper-methods
    // Teams should ideally add this as part of their Javascript library as a method.
    // We have to do this because Teams JS getcontext() is an async call.
    // When not running in teams, the callback doesn't return anything and we cannot tell if the call failed or if we're not in teams.
    private inTeams() {
        const microsoftTeamsLib = microsoftTeams || (window as any)["microsoftTeams"];
        if (!microsoftTeamsLib) {
            return false; // the Microsoft Teams library is for some reason not loaded
        }
        if ((window.parent === window.self && (window as any).nativeInterface) ||
            window.navigator.userAgent.includes("Teams/") ||
            window.name === "embedded-page-container" ||
            window.name === "extension-tab-frame") {
            return true;
        }
        return false;
    }

    render() {
        const request = {
            scopes: ["openid"],
        };
        return (
            <>
                {this.inTeams() === false ? (
                        <MsalProvider instance={msalInstance}>
                            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={request}>
                                <Provider store={this.store}>
                                    <ReactRootComponent pageContext={this.pageContext}>
                                        <ThemeProvider inTeams={false}>
                                            <Shell inTeams={false} teamsLocale={this.state.teamsLocale} />
                                        </ThemeProvider>
                                    </ReactRootComponent>
                                </Provider>
                            </MsalAuthenticationTemplate>
                        </MsalProvider>
                ) : this.state.accessToken.length > 0 ? (
                    <Provider store={this.store}>
                            <ReactRootComponent pageContext={this.pageContext}>
                                <ThemeProvider inTeams={true}>
                                    <Shell inTeams={true} teamsLocale={this.state.teamsLocale} />
                                </ThemeProvider>
                        </ReactRootComponent>
                    </Provider>
                ) : (
                    <div className="c-loading-page">
                        <Card className="c-loading-card">
                            <Spinner label={localizedStrings.ReservationsView.loadingMessage} size={SpinnerSize.large} />
                        </Card>
                    </div>
                )}
            </>
        );
    }
}
export default App;

export function setFullScreenMode(isFullScreen: boolean) {
    let $body = $("body");
    if ($body) {
        $body.removeClass(Constants.Mode_FullScreen);

        if (isFullScreen) {
            $body.addClass(Constants.Mode_FullScreen);
        }
    }
}

export const getMsalAuthResult = (): Promise<AuthenticationResult> | null => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length === 0) {
        return null;
    }

    const request = {
        scopes: ["openid"],
        account: accounts[0]
    };

    return msalInstance.acquireTokenSilent(request);
}