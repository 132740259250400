import React, { FC, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dialog, DialogType, DetailsList, IColumn, Spinner, SpinnerSize, Text, SelectionMode, DialogFooter, PrimaryButton, Selection, CheckboxVisibility } from "@fluentui/react";

import { DialogHeader } from "../../../DialogHeader/DialogHeader";
import { IRapComponentProperties } from "../../../../../platform/Layout";
import { localizedStrings } from "../../../../localization/LocalizedStrings";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { PageActions } from "../../../../../platform/components/Page/redux/PageActions";
import { ICase, IDeviceDto } from "../../../../../contracts/swagger/_generated";
import { AppointmentModalActions } from "../../redux/AppointmentModalActions";

import * as PageSelectors from "../../../../../platform/components/Page/redux/PageSelectors";
import * as AppointmentModalSelectors from "../../redux/AppointmentModalSelectors";
import * as Constants from "../../../../Constants";

import "./DynamicsCaseSelector.scss";
import { LoadingContainer } from "../../../LoadingContainer/LoadingContainer";


//Props passed by parent component
interface IDynamicsCaseSelectorProvidedProps extends IRapComponentProperties {
    onCaseSelected: (val: ICase) => void;
    contactId?: string;
    defaultCase?: ICase;
}

interface IDynamicsCaseSelectorOwnProps {
    displayDynamicsCaseSelectorModal?: boolean;
    contactDynamicsCases: ICase[];
}

interface ICaseWithKey extends ICase {
    key: string;
}


export type IDynamicsCaseSelectorProps = ConnectedProps<typeof connector> & IDynamicsCaseSelectorProvidedProps;

const DynamicsCaseSelectorInitializer : FC<IDynamicsCaseSelectorProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const selectedItem = useRef(props.defaultCase);


    useEffect(() => {
        if(props.contactId) {
            setIsLoading(true);
            props.fetchDynamicsCases(props.contactId).catch((error: string) => {
                console.error(error);
                props.updateMessages({ toastMessage: localizedStrings.AppointmentDetailsView?.modals?.dynamicsCaseSelector?.errorFetching });
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [props.contactId]);

    const getColumns = () => {
        let columns: IColumn[] = [];
        columns.push({
            key: Constants.DynamicsCaseTable_Headers_CaseId,
            name: Constants.DynamicsCaseTable_Headers_CaseId,
            isMultiline: true,
            minWidth: Constants.DetailListColumnSizes.xsmall,
            maxWidth: Constants.DetailListColumnSizes.xsmall,
            fieldName: "caseNumber",
        });
        columns.push({
            key: Constants.DynamicsCaseTable_Headers_Model,
            name: Constants.DynamicsCaseTable_Headers_Model,
            minWidth: Constants.DetailListColumnSizes.xsmall,
            maxWidth: Constants.DetailListColumnSizes.xsmall,
            onRender: (item: ICase) => {
                return (
                    <div>
                        {item.devices.map((device: IDeviceDto) => {
                            return <span className="c-device-info">{device.model}</span>
                        })}
                    </div>
                );
            }
        });
        columns.push({
            key: Constants.DynamicsCaseTable_Headers_SerialNumber,
            name: Constants.DynamicsCaseTable_Headers_SerialNumber,
            minWidth: Constants.DetailListColumnSizes.xsmall,
            maxWidth: Constants.DetailListColumnSizes.small,
            onRender: (item: ICase) => {
                return (
                    <div>
                        {item.devices.map((device: IDeviceDto) => {
                            return <span className="c-device-info">{device.serialNumber}</span>
                        })}
                    </div>
                );
            }
        });
        columns.push({
            key: Constants.DynamicsCaseTable_Headers_Description,
            name: Constants.DynamicsCaseTable_Headers_Description,
            minWidth: Constants.DetailListColumnSizes.large,
            isMultiline: true,
            fieldName: "description"
        });
        columns.push({
            key: Constants.DynamicsCaseTable_Headers_Scope,
            name: Constants.DynamicsCaseTable_Headers_Scope,
            minWidth: Constants.DetailListColumnSizes.large,
            isMultiline: true,
            fieldName: "scopeOfWork"
        });

        return columns;
    }

    const getItems = () => {
        if(!props.contactDynamicsCases || props.contactDynamicsCases.length == 0) {
            return [];
        }

        return props.contactDynamicsCases?.map((item: ICase) => {
            return {...item, key: item.caseId} as ICaseWithKey
        });
    }

    const onItemSelected = (item: ICase) => {
        if(item && item.caseId != props.defaultCase) {
            setIsDirty(true);
            selectedItem.current = item;
        }
        else {
            setIsDirty(false);
        }
    }

    const onSave = () => {
        props.onCaseSelected(selectedItem.current);
        props.updateModals({ displayDynamicsCaseSelectorModal: false });
    }
    

    return (
        <Dialog 
            hidden={!props.displayDynamicsCaseSelectorModal}
            onDismiss={() => props.updateModals({ displayDynamicsCaseSelectorModal: false })}
            minWidth={950}
            dialogContentProps={{
                type: DialogType.close,
                title: <DialogHeader title={localizedStrings.AppointmentDetailsView?.modals?.dynamicsCaseSelector?.title as string}/>,
                closeButtonAriaLabel: localizedStrings.AppointmentDetailsView?.modals?.dynamicsCaseSelector?.close}}
        >
            <LoadingContainer isLoading={isLoading}>
                <DetailsList
                    columns={getColumns()}
                    items={getItems()}
                    selectionMode={SelectionMode.single}
                    onItemInvoked={onItemSelected}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onActiveItemChanged={onItemSelected}
                />
            </LoadingContainer>
            <DialogFooter>
                <PrimaryButton
                    disabled={!isDirty}
                    onClick={onSave}
                >
                    {localizedStrings.AppointmentDetailsView?.modals?.dynamicsCaseSelector?.save}
                </PrimaryButton>
            </DialogFooter>
        </Dialog>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IDynamicsCaseSelectorProvidedProps): Partial<IDynamicsCaseSelectorOwnProps> {
    return {
        ...providedProps,
        displayDynamicsCaseSelectorModal: PageSelectors.getModals(state)?.displayDynamicsCaseSelectorModal,
        contactDynamicsCases: AppointmentModalSelectors.getResources(state)?.dynamicsCases
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    fetchDynamicsCases: AppointmentModalActions.fetchDynamicsCases,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const DynamicsCaseSelectorModal = connector(DynamicsCaseSelectorInitializer);