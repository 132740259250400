import "./Toast.scss";
import * as React from "react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { ICancelablePromise, makeCancelable } from "./../../../core/util/Promise";
import { Callout } from "./../Callout/Callout";
import { ContentJustification, ContentLocation } from "./../Callout/Callout.Props";
import { IToastProps } from "../../Components/Toast/Toast.Props";
import { Measure } from "./../Measure/Measure";
import { css, getSafeId } from "./../../../core/util/Util";
export interface IToastState {
    callToActionWidth?: number;
    fadingOut: boolean;
}

export class Toast extends React.Component<IToastProps, IToastState> {
    private fadeOutPromise: ICancelablePromise<void>;

    constructor(props: IToastProps) {
        super(props);

        this.state = { fadingOut: false };
    }

    public fadeOut(): ICancelablePromise<void> {
        this.fadeOutPromise && this.fadeOutPromise.cancel();
        const basePromise = new Promise<void>(resolve => {
            this.setState({ fadingOut: true });

            setTimeout(() => {
                resolve();
            }, 500);
        });

        this.fadeOutPromise = makeCancelable(basePromise);
        return this.fadeOutPromise;
    }

    public render() {
        const { message, callToAction, className } = this.props;
        const { callToActionWidth, fadingOut } = this.state;

        // Set up class names based on layout
        const renderAsSingleLine = !callToAction || !callToActionWidth || callToActionWidth <= 120;
        const flexLayoutClass = renderAsSingleLine ? "one-line flex-row" : "multi-line flex-column";
        const messageClass = renderAsSingleLine ? "flex-grow" : undefined;

        const fadeOutClass = fadingOut ? "fade-out" : undefined;

        return (
            <Callout
                className={css(className, "bolt-toast no-events")}
                contentClassName={css("bolt-toast-content", flexLayoutClass, fadeOutClass)}
                contentJustification={ContentJustification.Center}
                contentLocation={ContentLocation.End}
            >
                <span className={css("bolt-toast-message flex-shrink", messageClass)} id={getSafeId("toast-message")} role="alert">
                    {message}
                </span>
                {callToAction && (
                    <>
                        <span className={"bolt-toast-separator flex-noshrink"} />
                        <Measure onMeasure={this.onMeasureCallToAction}>
                            <div className="bolt-toast-call-to-action-container">
                                <DefaultButton className={"bolt-toast-call-to-action flex-noshrink"} onClick={this.onCallToActionClick}>
                                    {callToAction}
                                </DefaultButton>
                            </div>
                        </Measure>
                    </>
                )}
            </Callout>
        );
    }

    public componentWillUnmount() {
        this.fadeOutPromise && this.fadeOutPromise.cancel();
    }

    private onCallToActionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (this.props.onCallToActionClick) {
            this.props.onCallToActionClick(event);
        }
    };

    // Be careful to avoid layout thrashing here; the call to action must NOT change width on re-render
    private onMeasureCallToAction = (newWidth: number, newHeight: number) => {
        this.setState({ callToActionWidth: newWidth });
    };
}
