import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import enUS from "./locales/en-us";
import enCA from "./locales/en-ca";
import enAU from "./locales/en-au";
import enGB from "./locales/en-gb";

export interface ILocalizedStrings extends LocalizedStringsMethods {
    App?: {
        unAuthorizedMessage?: string;
        name?: string;
        noResultsAltText?: string;
    };
    About?: {
        detail?: string;
        contact?: string;
        privacyPolicy?: string;
    };
    AddClient?: {
        title?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        country?: string;
        mobile?: string;
        notes?: string;
        back?: string;
        save?: string;
        nameEndingInPeriodError?: string;
        nameIllegalCharactersError?: string;
        nameIsEmptyError?: string;
        nameNonPrintableCharactersError?: string;
        nameTooLargeError?: string;
        invalidEmail?: string;
        invalidPhone?: string;
        createFail?: string;
        createSuccess?: string;
        duplicate?: string;
    };
    AppointmentDetailsView?: {
        modals?: {
            shareModal?: {
                title1?: string;
                title2?: string;
                desktopLabel?: string;
                mobileLabel?: string;
                copy?: string;
                copied?: string;
                checkLater?: string;
            };
            editAssociateNotes?: {
                close?: string;
                title?: string;
                save?: string;
            };
            cancelAppointment?: {
                cancelSuccess?: string;
                cancelError?: string;
            };
            dynamicsCaseSelector?: {
                close?: string;
                title?: string;
                errorFetching?: string;
                save?: string;
            };
            createLeadModal?: {
                title?: string;
                close?: string;
                save?: string;
                topic?: string;
                topicError?: string;
                firstName?: string;
                lastName?: string;
                companyName?: string;
                companyNameError?: string;
                selectCountry?: string;
                mobile?: string;
                mobileError?: string;
                email?: string;
                emailError?: string;
                otherDetails?: string;
                nameEndingInPeriodError?: string;
                nameIllegalCharactersError?: string;
                nameIsEmptyError?: string;
                nameNonPrintableCharactersError?: string;
                nameTooLargeError?: string;
                leadCreatedSuccessfully?: string;
                leadError?: string;
                updateError?: string;
            };
        };
        AssociateNotes?: {
            title?: string;
            editLabel?: string;
        };
        AppointmentDetails?: {
            type?: string;
            topic?: string;
            sms?: string;
            description?: string;
            assignedTo?: string;
            accommodations?: string;
            support?: string;
            bslInterpreterRequested?: string;
            notSet?: string;
            optIn?: string;
            optOut?: string;
            additionalAccommodations?: string;
            poboOrderId?: string;
            editLabel?: string;
            leadId?: string;
        };
    };
    ContactsView?: {
        contactPanel?: {
            profile?: string;
            reservations?: string;
        };
        csat?: {
            header?: string;
            personalAverage?: string;
            overallHighest?: string;
            overallLowest?: string;
            noData?: string;
        };
        survey?: {
            positive?: string;
            negative?: string;
            neutral?: string;
            notFound?: string;
            csat?: string;
            sentiment?: string;
            q?: string;
            a?: string;
        };
        profilePage?: {
            demographics?: {
                title?: string;
                jobTitle?: string;
                company?: string;
                save?: string;
                success?: string;
                error?: string;
            };
            productSuggestions?: {
                title?: string;
                notFound?: string;
                productName?: string;
                productSKU?: string;
            };
            purchaseHistory?: {
                title?: string;
                notFound?: string;
            };
        };
        reservationsPage?: {
            appointmentList?: {
                appointments?: string;
                notFound?: string;
            };
            appointmentDetails?: {
                typeOfService?: string;
                topic?: string;
                description?: string;
                accommodations?: string;
                accessibilityAccommodations?: string;
                associateNotes?: string;
                lastModified?: string;
            };
        };
        contactAppointments?: {
            appointments?: string;
            notFound?: string;
        };
        contactDetails?: {
            clientSince?: string;
            lastInteraction?: string;
            company?: string;
            noValue?: string;
            EduEnding?: string;
        };
        segment: {
            student?: string;
        };
        contactNotes?: {
            notes?: string;
            delete?: string;
            edit?: string;
            notFound?: string;
            createSuccess?: string;
            createFailure?: string;
            updateSuccess?: string;
            updateFailure?: string;
            deleteSuccess?: string;
            deleteFailure?: string;
            createNote?: string;
        };
    };
    AppointmentList?: {
        time?: string;
        confirmationNumber?: string;
        type?: string;
        category?: string;
        assignedTo?: string;
        status?: string;
        actions?: string;
        customerName?: string;
        unassigned?: string;
        noAppointmentsFound?: string;
    };
    AppointmentCardItem?: {
        completed?: string;
        scheduled?: string;
        inProgress?: string;
        waiting?: string;
        checkedIn?: string;
        cancelled?: string;
        noShow?: string;
        junk?: string;
        outOfArea?: string;
        outOfScope?: string;
        staffing?: string;
        techSupport?: string;
        category?: string;
        customer?: string;
        assignedTo?: string;
        confirmation?: string;
        unassigned?: string;
        status?: string;
        assignedToError?: string;
    };
    AppointmentButtons?: {
        customerCheckin?: string;
        startAppointment?: string;
        complete?: string;
        join?: string;
        view?: string;
        updateSuccessful?: string;
    };
    AppointmentStatus?: {
        completed?: string;
        scheduled?: string;
        inProgress?: string;
        waiting?: string;
        checkedIn?: string;
        cancelled?: string;
    };

    ConfirmCompletedModal?: {
        title?: string;
        subtitle?: string;
        cancel?: string;
        confirm?: string;
    };

    ReservationsView?: {
        contactsTitle?: string;
        loadingMessage?: string;
    };
    AppointmentFilters?: {
        appointmentType?: string;
        appointmentStatus?: string;
        pending?: string;
        scheduled?: string;
        inProgress?: string;
        checkedIn?: string;
        requested?: string;
        tentative?: string;
        cancelled?: string;
        noShow?: string;
        completed?: string;
        junk?: string;
        outOfArea?: string;
        outOfScope?: string;
        techSupport?: string;
        staffing?: string;
        checkboxAriaLabelPending?: string;
        checkboxAriaLabelScheduled?: string;
        checkboxAriaLabelInProgress?: string;
        checkboxAriaLabelCheckedIn?: string;
        checkboxAriaLabelCancelled?: string;
        checkboxAriaLabelCompleted?: string;
    };
    CommandBar?: {
        cardView?: string;
        listView?: string;
        close?: string;
        userSettingsPanelTitle?: string;
        aboutPanelTitle?: string;
        searchPlaceholder?: string;
        moreCommands?: string;
        selectADate?: string;
        changeStoreLocation?: string;
        leaveFeedback?: string;
        aboutThisApp?: string;
        searchForContact?: string;
        settings?: string;
        storePickerPanelTitle?: string;
        newAppointment?: string;
        search?: string;
        filter?: string;
        topNav?: string;
        tabs?: string;
        newRunnerRequest?: string;
        find?: string;
        runner?: string;
        frontOfHouse?: string;
        queueIsClosed?: string;
        stateFilter?: string;
        storeSelector?: string;
        selectedView?: string;
        refresh?: string;
        about?: string;
    };
    Search?: {
        noAppointmentsFound?: string;
    };
    AppointmentStatusFilter?: {
        scheduled?: string;
        completed?: string;
        canceled?: string;
        all?: string;
    };
    AppointmentStatusReasons?: {
        noShow?: string;
        junk?: string;
        outOfArea?: string;
        outOfScopeGeneral?: string;
        outOfScopeCommercialSupport?: string;
        outOfScopePersonalSupport?: string;
        techSupport?: string;
        staffing?: string;
    };
    AppointmentCommandBar?: {
        reservations?: string;
        cancel?: string;
        share?: string;
        reasonForCancellation?: string;
        close?: string;
        selectReason?: string;
        openCase?: string;
        createLead?: string;
    };
    CreateEditAppointmentModal?: {
        createAppointment?: string;
        editAppointment?: string;
        type?: string;
        topic?: string;
        appointmentDate?: string;
        appointmentTime?: string;
        timeZone?: string;
        assignedTo?: string;
        assignedToPlaceholderText?: string;
        client?: string;
        clientPlaceholderText?: string;
        poboId?: string;
        description?: string;
        discard?: string;
        save?: string;
        dateFormat?: string;
        createContact?: string;
        removeAriaLabel?: string;
        selectedAriaLabel?: string;
        poboLabel?: string;
        updateSuccess?: string;
        updateError?: string;
        createSuccess?: string;
        createError?: string;
        peoplePickerLabel?: string;
        customResponseTrue?: string;
        customResponseFalse?: string;
        dynamicsCaseLabel?: string;
        linkCase?: string;
        noSearchResults?: string;
        bslCheckbox?: string;
        agreeToAge?: string;
        accessibilityRequested?: string;
        accessibilityDesc?: string;
    };
    ShareAppointment?: {
        title?: string;
        desktopLabel?: string;
        mobileLabel?: string;
        copyButton?: string;
        close?: string;
    };
    FilterPane?: {
        header?: string;
        store?: string;
        type?: string;
        assignTo?: string;
        apply?: string;
        cancel?: string;
        unAssigned?: string;
        all?: string;
        assignedToMe?: string;
    };
    Errors?: {
        serverError?: string;
        contactNotFound?: string;
    };
    AppointmentClientView?: {
        client?: string;
        seeClientDetails?: string;
    };
    RunnerRequestsView?: {
        runnerRequests?: string;
        close?: string;
        RunnerRequestList?: {
            status?: string;
            description?: string;
            numItems?: string;
            location?: string;
            locationNote?: string;
            requester?: string;
            elapsedTime?: string;
            active?: string;
            canceled?: string;
            complete?: string;
            inProgress?: string;
            readyForPickup?: string;
            noRequestsToDisplay?: string;
        };
        CancelRunnerRequest?: {
            selectAReason?: string;
            notInStock?: string;
            onHold?: string;
            other?: string;
            back?: string;
            submit?: string;
        };
        RunnerRequestNotificationModal?: {
            activeRequest?: string;
            needsAttention?: string;
            close?: string;
        };
        RunnerRequestViewModal?: {
            runnerRequest?: string;
            type?: string;
            status?: string;
            cancelReason?: string;
            requestDate?: string;
            requestTime?: string;
            requester?: string;
            owner?: string;
            location?: string;
            locationNote?: string;
            itemDescription?: string;
            qty?: string;
            alias?: string;
            pick?: string;
            cancelRequest?: string;
            complete?: string;
            completeSuccess?: string;
            completeFailure?: string;
            cancelSuccess?: string;
            cancelFailure?: string;
        };
        CreateRunnerRequestModal?: {
            runnerRequest?: string;
            type?: string;
            status?: string;
            requestDate?: string;
            requestTime?: string;
            requester?: string;
            owner?: string;
            location?: string;
            locationNote?: string;
            itemDescription?: string;
            qty?: string;
            alias?: string;
            pick?: string;
            cancel?: string;
            close?: string;
            submit?: string;
            inStoreOrder?: string;
            onlineOrder?: string;
            firstFloor?: string;
            secondFloor?: string;
            thirdFloor?: string;
            fourthFloor?: string;
            incrementQuantity?: string;
            decrementQuantity?: string;
            addItem?: string;
            createSuccess?: string;
            createFailure?: string;
        };
        EnableDisableRequestsModal?: {
            enableRequests?: string;
            disableRequests?: string;
            enableMessage?: string;
            disableMessage?: string;
            enterYourName?: string;
            accept?: string;
        };
    };
    RunnerRequestStatusFilter?: {
        actionNeeded?: string;
        inProgress?: string;
        canceled?: string;
        complete?: string;
        all?: string;
        enableRequests?: string;
        disableRequests?: string;
    };
}

export const localizedStrings: ILocalizedStrings = new LocalizedStrings({
    "en-us": enUS,
    "en-ca": enCA,
    "en-gb": enGB,
    "en-au": enAU
});
