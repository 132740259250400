export default {
    App: {
        unAuthorizedMessage:
            "This tool is intended for Microsoft Retail employees only. You do not have permissions to this tool. Contact Retail Helpdesk for access or email CSMEevent@microsoft.com directly if you are a Retail employee and do not have access.",
        name: "Concierge",
        noResultsAltText: "Empty state illustration"
    },
    About: {
        detail: "Concierge is an internal application for managing digital reservations and other services for Microsoft Stores, built and maintained by Team SIX (Store Insights & Expansion) engineering.",
        contact: "Contact conciergedevs@microsoft.com for access to this tool or more information.",
        privacyPolicy: "Privacy policy",
    },
    AddClient: {
        title: "Add client",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        country: "Select country",
        mobile: "Mobile",
        notes: "Additional notes",
        back: "Back",
        save: "Save",
        nameEndingInPeriodError: "Name must not end in a period.",
        nameIllegalCharactersError: "Name contains illegal characters.",
        nameIsEmptyError: "Name is required.",
        nameNonPrintableCharactersError: "Name contains invalid characters.",
        nameTooLargeError: "Name is too long.",
        invalidEmail: "Please enter a valid email.",
        invalidPhone: "Please enter a valid phone number.",
        createFail: "There was an error creating the contact",
        createSuccess: "Contact created successfully",
        duplicate: "Error creating contact: Contact already exists"
    },
    AppointmentDetailsView: {
        modals: {
            shareModal: {
                title1: "Share appointment",
                title2: "Concierge",
                desktopLabel: "Desktop join link",
                mobileLabel: "Mobile join link",
                copy: "Copy",
                copied: "Copied",
                checkLater: "Teams links are not yet available, please check again later."
            },
            editAssociateNotes: {
                close: "close",
                title: "Edit associate notes",
                save: "Save"
            },
            cancelAppointment: {
                cancelSuccess: "Appointment successfully canceled",
                cancelError: "Error canceling appointment"
            },
            dynamicsCaseSelector: {
                close: "Close",
                title: "Dynamics case",
                errorFetching: "Error fetching cases, please try again",
                save: "Save"
            },
            createLeadModal: {
                title: "Create lead",
                close: "Close",
                save: "Save",
                topic: "Topic",
                topicError: "Please enter a valid topic name.",
                firstName: "First name",
                lastName: "Last name",
                companyName: "Company name",
                companyNameError: "Please enter a valid company name.",
                selectCountry: "Select country",
                mobile: "Mobile",
                mobileError: "Please enter a valid mobile number.",
                email: "Email",
                emailError: "Please enter a valid email.",
                otherDetails: "Any other details to share?",
                nameEndingInPeriodError: "Name must not end in a period.",
                nameIllegalCharactersError: "Name contains illegal characters.",
                nameIsEmptyError: "Name is required.",
                nameNonPrintableCharactersError: "Name contains invalid characters.",
                nameTooLargeError: "Name is too long.",
                leadCreatedSuccessfully: "Lead was created successfully",
                leadError: "There was an error creating the lead",
                updateError: "Lead was created successfully, appointment failed to update"
            }
        },
        AssociateNotes: {
            editLabel: "Edit notes",
            title: "Associate notes"
        },
        AppointmentDetails: {
            type: "Type:",
            topic: "Topic:",
            sms: "SMS Opt-In:",
            description: "Description:",
            assignedTo: "Assigned To:",
            accommodations: "accommodations :",
            support: "I require additional support for this appointment",
            bslInterpreterRequested: "I require a British Sign Language interpreter for this appointment",
            notSet: "Not Set",
            optIn: "Opted-In",
            optOut: "Opted-Out",
            additionalAccommodations: "Additional accommodations ",
            poboOrderId: "POBO Order ID:",
            editLabel: "Edit appointment",
            leadId: "Lead Id:"
        }
    },
    ContactsView: {
        contactPanel: {
            profile: "Profile",
            reservations: "Reservations"
        },
        csat: {
            header: "CSAT",
            personalAverage: "Personal average",
            overallHighest: "Overall highest",
            overallLowest: "Overall lowest",
            noData: "No data"
        },
        survey: {
            positive: "Positive",
            negative: "Negative",
            neutral: "Neutral",
            notFound: "Survey data not found",
            csat: "CSAT",
            sentiment: "Sentiment",
            q: "Q. ",
            a: "A. "
        },
        profilePage: {
            demographics: {
                title: "Demographics",
                jobTitle: "Job title:",
                company: "Company:",
                save: "Save",
                success: "Contact was updated successfully",
                error: "There was an error updating the contact"
            },
            productSuggestions: {
                title: "Product recommendations",
                notFound: "Product recommendations not found",
                productName: "Product name",
                productSKU: "Product SKU"
            },
            purchaseHistory: {
                title: "Purchase history",
                notFound: "Purchase history not found"
            }
        },
        reservationsPage: {
            appointmentList: {
                appointments: "Appointments",
                notFound: "No appointments found"
            },
            appointmentDetails: {
                typeOfService: "Type of service:",
                topic: "Topic:",
                description: "Description:",
                accommodations: "Accommodations:",
                accessibilityAccommodations: "Accessibility accommodations requested",
                associateNotes: "Associate notes:",
                lastModified: "Last modified on"
            }
        },
        contactAppointments: {
            appointments: "Appointments",
            notFound: "No appointments found"
        },
        contactDetails: {
            clientSince: "Client since:",
            lastInteraction: "Last interaction:",
            company: "Company:",
            noValue: "-",
            EduEnding: ".edu"
        },
        segment: {
            student: "Student"
        },
        contactNotes: {
            notes: "Customer notes",
            delete: "Delete",
            edit: "Edit",
            notFound: "No notes found",
            createSuccess: "Note was created successfully",
            createFailure: "There was an error creating the note",
            updateSuccess: "Note was updated successfully",
            updateFailure: "There was an error updating the note",
            deleteSuccess: "Note was deleted successfully",
            deleteFailure: "There was an error deleting the note",
            createNote: "Create note"
        }
    },
    AppointmentList: {
        time: "Time",
        confirmationNumber: "Confirmation",
        type: "Type",
        category: "Topic",
        assignedTo: "Assigned to",
        status: "Status",
        actions: "Actions",
        customerName: "Customer name",
        unassigned: "Unassigned",
        noAppointmentsFound: "No appointments found for the selected date and/or filters"
    },
    AppointmentCardItem: {
        noShow: "No Show",
        junk: "Junk",
        outOfArea: "Out of Area",
        outOfScope: "Out of Scope",
        staffing: "Staffing",
        techSupport: "Tech Support",
        category: "Topic",
        customer: "Customer",
        assignedTo: "Assigned To",
        confirmation: "Confirmation",
        unassigned: "Unassigned",
        status: "Status",
        assignedToError: "Error fetching assignment"
    },
    AppointmentButtons: {
        customerCheckin: "Check in",
        startAppointment: "Start",
        complete: "Complete",
        join: "Join",
        view: "View",
        updateSuccessful: "Appointment was updated successfully."
    },
    AppointmentStatusFilter: {
        scheduled: "Scheduled",
        completed: "Completed",
        canceled: "Canceled",
        all: "All"
    },
    AppointmentStatus: {
        completed: "Completed",
        scheduled: "Scheduled",
        inProgress: "In Progress",
        waiting: "Waiting",
        checkedIn: "Checked In",
        cancelled: "Canceled",
        pending: "Pending",
        requested: "Requested",
        tentative: "Tentative",
        noShow: "No Show",
        junk: "Junk",
        outOfArea: "Out of Area",
        outOfScope: "Out of Scope",
        techSupport: "Tech Support",
        staffing: "Staffing"
    },

    ConfirmCompletedModal: {
        title: "Complete appointment",
        subtitle: "Are you sure you want to complete this appointment?",
        cancel: "Cancel",
        confirm: "Confirm"
    },

    AppointmentClientView: {
        client: "Client",
        seeClientDetails: "See client details"
    },
    ReservationsView: {
        contactsTitle: "Contacts ({0})",
        loadingMessage: "Loading, please wait."
    },
    AppointmentFilters: {
        appointmentType: "Appointment Type",
        appointmentStatus: "Appointment Status",
        checkboxAriaLabelPending: "Display pending appointments",
        checkboxAriaLabelScheduled: "Display scheduled appointments",
        checkboxAriaLabelInProgress: "Display in progress appointments",
        checkboxAriaLabelCheckedIn: "Display checked in appointments",
        checkboxAriaLabelCancelled: "Display cancelled appointments",
        checkboxAriaLabelNoShow: "Display no show appointments",
        checkboxAriaLabelCompleted: "Display completed appointments"
    },
    CommandBar: {
        cardView: "Card view",
        listView: "List view",
        filter: "Filter",
        close: "Close",
        userSettingsPanelTitle: "User Settings",
        aboutPanelTitle: "About this App",
        searchPlaceholder: "Search appointments by confirmation #",
        moreCommands: "More commands",
        selectADate: "Select a date",
        changeStoreLocation: "Change store location",
        leaveFeedback: "Leave feedback",
        aboutThisApp: "About this App",
        searchForContact: "Search for contacts",
        settings: "User settings",
        storePickerPanelTitle: "Change Store Location",
        newAppointment: "New appointment",
        search: "Search",
        topNav: "Top navigation",
        tab: "Tabs",
        newRunnerRequest: "New Runner Request",
        find: "Find",
        runner: "Runner",
        frontOfHouse: "Front of house",
        queueIsClosed: "The runner request queue for store {0}-{1} is currently closed",
        stateFilter: "State filter",
        storeSelector: "Store selector",
        selectedView: "Selected view",
        refresh: "Refresh",
        about: "About"
    },
    Search: {
        noAppointmentsFound: "No appointments found. Please enter a valid confirmation number."
    },
    AppointmentCommandBar: {
        reservations: "Reservations",
        cancel: "Cancel appointment",
        share: "Share appointment",
        reasonForCancellation: "Reason for cancellation",
        close: "Close",
        selectReason: "Select a reason",
        openCase: "Open case",
        createLead: "Create lead"
    },
    CreateEditAppointmentModal: {
        createAppointment: "Create appointment",
        editAppointment: "Edit appointment",
        type: "Type",
        topic: "Topic",
        appointmentDate: "Appointment date",
        appointmentTime: "Appointment time",
        timeZone: "Client time zone",
        assignedTo: "Assigned to",
        assignedToPlaceholderText: "Search associate's name or email",
        client: "Client",
        clientPlaceholderText: "Search client's name or email",
        poboId: "POBO order id",
        description: "Description",
        discard: "Discard",
        save: "Save",
        dateFormat: "dddd, MMMM D, YYYY",
        createContact: "Add client",
        removeAriaLabel: "Remove",
        selectedAriaLabel: "Selected contacts",
        poboLabel: "POBO Order ID",
        updateSuccess: "Appointment updated successfully",
        updateError: "Error updating appointment",
        createSuccess: "Appointment created successfully",
        createError: "Error creating appointment",
        peoplePickerLabel: "Select a contact",
        customResponseTrue: "True",
        customResponseFalse: "False",
        dynamicsCaseLabel: "Dynamics case",
        linkCase: "Link case",
        noSearchResults: "No results found",
        bslCheckbox: "I require a British Sign Language interpreter for this appointment.",
        agreeToAge: "Customer is 18 years or older.",
        accessibilityRequested: "I require additional support for this appointment.",
        accessibilityDesc: "Please describe the additional support you require."
    },
    ShareAppointment: {
        title: "Share Appointment",
        desktopLabel: "Desktop join link",
        mobileLabel: "Mobile join link",
        copyButton: "Copy",
        close: "Close"
    },
    AppointmentStatusReasons: {
        noShow: "No show",
        junk: "Junk",
        outOfArea: "Out of area",
        outOfScopeGeneral: "Out of scope - general",
        outOfScopeCommercialSupport: "Out of scope - needs commercial support",
        outOfScopePersonalSupport: "Out of scope - needs personal support",
        techSupport: "Tech support",
        staffing: "Staffing"
    },
    FilterPane: {
        header: "Filters",
        store: "Store",
        type: "Type",
        assignTo: "Assign",
        apply: "Apply",
        cancel: "Cancel",
        unAssigned: "Unassigned",
        all: "All",
        assignedToMe: "Assigned to me"
    },
    Errors: {
        serverError: "An unexpected error has occurred",
        contactNotFound: "The provided contact could not be found"
    },
    RunnerRequestsView: {
        runnerRequests: "Runner Requests",
        close: "Close",
        RunnerRequestList: {
            status: "Status",
            description: "Description",
            numItems: "# Items",
            location: "Location",
            locationNote: "Location Note",
            requester: "Requester",
            elapsedTime: "Elapsed Time",
            active: "Active",
            canceled: "Canceled",
            complete: "Complete",
            inProgress: "In progress",
            readyForPickup: "Ready for pickup",
            noRequestsToDisplay: "There are no runner requests to display"
        },
        CancelRunnerRequest: {
            selectAReason: "Select a reason for canceling",
            notInStock: "Product not in stock",
            onHold: "Product on hold for another customer",
            other: "Other",
            back: "Back",
            submit: "Submit"
        },
        RunnerRequestNotificationModal: {
            activeRequest: "Active request",
            needsAttention: "There is an active request that needs attention",
            close: "Close"
        },
        RunnerRequestViewModal: {
            runnerRequest: "Runner Request",
            type: "Type",
            status: "Status",
            cancelReason: "Cancel Reason",
            requestDate: "Request Date",
            requestTime: "Request Time",
            requester: "Requester",
            owner: "Owner",
            location: "Location",
            locationNote: "Location Note",
            itemDescription: "Item Description",
            qty: "Qty",
            alias: "Name",
            pick: "Pick",
            cancelRequest: "Cancel request",
            complete: "Complete",
            completeSuccess: "Runner request completed successfully",
            completeFailure: "Unable to complete runner request at this time",
            cancelSuccess: "Runner request canceled successfully",
            cancelFailure: "Unable to cancel runner request at this time",
        },
        CreateRunnerRequestModal: {
            runnerRequest: "Create Runner Request",
            type: "Type",
            status: "Status",
            requestDate: "Request Date",
            requestTime: "Request Time",
            requester: "Requester",
            owner: "Owner",
            location: "Location",
            locationNote: "Location Note",
            itemDescription: "Item Description",
            qty: "Qty",
            alias: "Name",
            pick: "Pick",
            cancel: "Cancel",
            submit: "Submit",
            inStoreOrder: "In store order",
            onlineOrder: "Online order",
            firstFloor: "First floor",
            secondFloor: "Second floor",
            thirdFloor: "Third floor",
            fourthFloor: "Fourth floor",
            incrementQuantity: "Increment quantity by one",
            decrementQuantity: "Decrement quantity by one",
            createSuccess: "Runner request created successfully",
            createFailure: "Unable to create runner request at this time",
            addItem: "Add item"
        },
        EnableDisableRequestsModal: {
            enableRequests: "Enable Requests",
            disableRequests: "Disable Requests",
            enableMessage: "You are about to open the Runner Request service for the entire store",
            disableMessage: "Completing this action will make the Runner Request service unavailable for the entire store",
            enterYourName: "Enter your name",
            accept: "Accept"
        }
    },
    RunnerRequestStatusFilter: {
        actionNeeded: "Action Needed",
        inProgress: "In Progress",
        canceled: "Canceled",
        complete: "Complete",
        all: "All",
        enableRequests: "Enable Requests",
        disableRequests: "Disable Requests"
    }
};
