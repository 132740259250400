import React from "react";
import ReactDOM from "react-dom";
import registerServiceWorker from "./registerServiceWorker";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import App from "./App";
import { loadTheme, registerIcons } from "@fluentui/react";
import "./styling/_base.scss";
import "./styling/_flex.scss";
import { MSTeamsIcon, MSTeamsIconType, MSTeamsIconWeight } from "msteams-ui-icons-react";

initializeIcons(/* optional base url */);

registerIcons({
    icons: {
        MSTeams_ContactAdd_Light: <MSTeamsIcon iconType={MSTeamsIconType.ContactAdd} iconWeight={MSTeamsIconWeight.Light} />,
        MSTeams_Notepad_Light: <MSTeamsIcon iconType={MSTeamsIconType.Notepad} iconWeight={MSTeamsIconWeight.Light} />,
        MSTeams_MojiRecent_Light: <MSTeamsIcon iconType={MSTeamsIconType.MojiRecent} iconWeight={MSTeamsIconWeight.Light} />,
        MSTeams_Contact_Light: <MSTeamsIcon iconType={MSTeamsIconType.Contact} iconWeight={MSTeamsIconWeight.Light} />,
        MSTeams_Mail_Light: <MSTeamsIcon iconType={MSTeamsIconType.Mail} iconWeight={MSTeamsIconWeight.Light} />,
        MSTeams_Topic_Light: <MSTeamsIcon iconType={MSTeamsIconType.Topic} iconWeight={MSTeamsIconWeight.Light} />
    }
});

//Initialize Fabric UI Theme
if (document.body.classList.contains("theme-default")) {
    loadTheme({
        palette: {
            themePrimary: "#6264a7",
            themeLighterAlt: "#f7f7fb",
            themeLighter: "#e1e1f1",
            themeLight: "#c8c9e4",
            themeTertiary: "#989ac9",
            themeSecondary: "#7173b0",
            themeDarkAlt: "#585a95",
            themeDark: "#4a4c7e",
            themeDarker: "#37385d",
            neutralLighterAlt: "#f8f8f8",
            neutralLighter: "#f4f4f4",
            neutralLight: "#eaeaea",
            neutralQuaternaryAlt: "#dadada",
            neutralQuaternary: "#d0d0d0",
            neutralTertiaryAlt: "#c8c8c8",
            neutralTertiary: "#b5b4b2",
            neutralSecondary: "#9d9c9a",
            neutralPrimaryAlt: "#868482",
            neutralPrimary: "#252423",
            neutralDark: "#565453",
            black: "#3e3d3b",
            white: "#ffffff"
        }
    });
} else if (document.body.classList.contains("theme-dark")) {
    loadTheme({
        palette: {
            themePrimary: "#6264a7",
            themeLighterAlt: "#f7f7fb",
            themeLighter: "#e1e1f1",
            themeLight: "#c8c9e4",
            themeTertiary: "#989ac9",
            themeSecondary: "#7173b0",
            themeDarkAlt: "#585a95",
            themeDark: "#4a4c7e",
            themeDarker: "#37385d",
            neutralLighterAlt: "#11100f",
            neutralLighter: "#11100f",
            neutralLight: "#100f0e",
            neutralQuaternaryAlt: "#0f0e0d",
            neutralQuaternary: "#0f0e0d",
            neutralTertiaryAlt: "#0e0d0c",
            neutralTertiary: "#a2a1a1",
            neutralSecondary: "#888786",
            neutralPrimaryAlt: "#6d6c6c",
            neutralPrimary: "#f3f2f1",
            neutralDark: "#383737",
            black: "#1d1d1d",
            white: "#11100f"
        }
    });
}

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
