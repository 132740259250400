import React, { FC } from "react";
import { Text } from "@fluentui/react";
import { TextSizes } from "../../Constants";
import { localizedStrings } from "../../localization/LocalizedStrings";
import logoWhite from "../../../styling/images/clienteling-icon-white.png";

import "./DialogHeader.scss";

export interface IDialogHeaderProps {
    title: string;
}

export const DialogHeader: FC<IDialogHeaderProps> = props => {
    return (
        <div className="flex-row c-title-container">
            <div className="c-logo-color-bkgrd">
                <img alt="logo" className="c-logo-only-image is-MobileView-Logo" src={logoWhite} />
            </div>
            <div className="flex-column">
                <Text className="c-title top" variant={TextSizes.medium}>
                    {props.title}
                </Text>
                <Text className="c-title middle" variant={TextSizes.medium}>
                    {localizedStrings.AppointmentDetailsView.modals.shareModal.title2}
                </Text>
            </div>
        </div>
    );
};
