import { IContactsAwareState, ICustomerInsightsData, IMessages, ISelectedContactInfo } from "./../Contracts";
import { IAssociate } from "../../../contracts/swagger/_generated";

export function getStoreAssociates(state: IContactsAwareState): IAssociate[] {
    if (!state || !state.contactsViewState || !state.contactsViewState.data) {
        return [];
    }

    return state.contactsViewState.data.storeAssociates;
}

export function getIsLoading(state: IContactsAwareState): boolean {
    if (!state || !state.contactsViewState || !state.contactsViewState.ux) {
        return undefined;
    }

    return state.contactsViewState.ux.isLoading;
}

export function getMessages(state: IContactsAwareState): IMessages | undefined {
    if (!state || !state.contactsViewState || !state.contactsViewState.ux) {
        return undefined;
    }

    return state.contactsViewState.ux.messages;
}

export function getSelectedContactInfo(state: IContactsAwareState): ISelectedContactInfo | undefined {
    if (!state || !state.contactsViewState || !state.contactsViewState.data) {
        return undefined;
    }

    return state.contactsViewState.data.selectedContactInfo;
}

export function getCustomerInsightsData(state: IContactsAwareState): ICustomerInsightsData | undefined {
    if (!state || !state.contactsViewState || !state.contactsViewState.data.customerInsightsData) {
        return undefined;
    }

    return state.contactsViewState.data.customerInsightsData;
}

export function getSelectedAppointmentId(state: IContactsAwareState): string | undefined {
    if (!state || !state.contactsViewState || !state.contactsViewState.data) {
        return undefined;
    }

    return state.contactsViewState.data.selectedAppointmentId;
}
