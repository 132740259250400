import { combineReducers } from "redux";
import { pageReducer } from "../components/Page/redux/PageReducer";
import { reservationsReducer } from "../../views/ReservationsView/redux/ReservationsReducer";
import { contactsReducer } from "../../views/ContactsView/redux/ContactsReducer";
import { featureManagementReducer } from "../../views/FeatureManagement/redux/FeatureManagementReducer";
import { appointmentDetailsReducer } from "../../views/AppointmentDetailsView/redux/AppointmentDetailsReducer";
import { appointmentModalReducer } from "../../common/components/CreateEditAppointmentModal/redux/AppointmentModalReducer";
import { runnerRequestsReducer } from "../../views/RunnerRequestsView/redux/RunnerRequestsReducer";

export default combineReducers({
    pageViewState: pageReducer, 
    reservationsViewState: reservationsReducer,
    appointmentDetailsViewState: appointmentDetailsReducer,
    contactsViewState: contactsReducer,
    featureManagementViewState: featureManagementReducer,
    appointmentModalViewState: appointmentModalReducer,
    runnerRequestsViewState: runnerRequestsReducer
});