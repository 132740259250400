import * as React from "react";
import { CommandBar } from "@fluentui/react/lib/CommandBar";
import { IContextualMenuItem } from "@fluentui/react/lib/ContextualMenu";
import { NavMenuItem } from "./../../Page/Contracts";
import * as String_Util from "./../../../core/util/String";

import "./TopNavMenu.scss";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
export interface ITopNavMenuProps {
    navMenuItems: NavMenuItem[];
    selectedMenuItemId: number;
    onSelectedMenuItemChanged(id: number): void;
}

export class TopNavMenu extends React.Component<ITopNavMenuProps, {}> {
    public render(): JSX.Element {
        var menuItems = this._generateMenuItems();
        return (
            <CommandBar
                items={menuItems}
                className={"c-top-nav"}
                ariaLabel={localizedStrings.CommandBar.topNav}
                primaryGroupAriaLabel={localizedStrings.CommandBar.tabs}
            />
        );
    }

    private _generateMenuItems(): IContextualMenuItem[] {
        var menuItems: IContextualMenuItem[] = [];

        this.props.navMenuItems.map(navItem => {
            menuItems.push({
                key: navItem.id.toString(),
                iconProps: { iconName: navItem.iconName },
                name: navItem.title,
                onClick: this._onMenuItemClick.bind(this, navItem.pageRoute),
                className: this._getClassName(navItem),
                ariaLabel: this._getAriaLabel(navItem)
            });
        });

        return menuItems;
    }

    private _onMenuItemClick(
        pageRoute: string,
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: IContextualMenuItem
    ): boolean | void {
        if (pageRoute) {
            window.location.href = pageRoute;
        }
    }

    private _getClassName = (menuItem: NavMenuItem) => {
        let className = "c-top-nav-link";

        if (menuItem.pageRoute && String_Util.caseInsensitiveContains(window.location.href, menuItem.pageRoute)) {
            className += " focused";
        } else if (window.location.pathname.split("/")[1] === "" && menuItem.isDefault) {
            className += " focused";
        }

        return className;
    };

    private _getAriaLabel = (menuItem: NavMenuItem) => {
        if (
            (menuItem.pageRoute && String_Util.caseInsensitiveContains(window.location.href, menuItem.pageRoute)) ||
            (window.location.pathname.split("/")[1] === "" && menuItem.isDefault)
        ) {
            return "Current item " + menuItem.title;
        }

        return menuItem.title;
    };
}
