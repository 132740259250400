export default {
    AppointmentStatus: {
        cancelled: "Cancelled"
    },
    AppointmentStatusFilter: {
        canceled: "Cancelled"
    },
    RunnerRequestsView: {
        CancelRunnerRequest: {
            selectAReason: "Select a reason for cancelling"
        }
    },
    RunnerRequestStatusFilter: {
        canceled: "Cancelled",
    }
}
