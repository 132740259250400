import React from "react";
import { RapComponent } from "../../../platform/Layout";

import placeholderImg from "../../../styling/images/placeholder.png";
import "./ComingSoon.scss";

export interface IComingSoonProps {
    description: string;
}

export class ComingSoon extends RapComponent<IComingSoonProps> {
    public render() {
        return (
            <div className="c-coming-soon flex-column flex-grow">
                <div className="c-coming-soon-img-box">
                    <img alt="Coming Soon" className="c-coming-soon-img" src={placeholderImg} />
                </div>
                <div className="c-coming-soon-text">{this.props.description}</div>
            </div>
        );
    }
}
