import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../platform/Layout";
import { IClientelingViewState } from "../Contracts";
import { ConnectedContactView } from "../../views/ContactsView/ContactsView";
import { PageActions } from "../../platform/components/Page/redux/PageActions";

import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";

import "./ContactHub.scss";

interface IContactHubInitializerProvidedProps extends IRapComponentProperties { }

interface IContactHubInitializerOwnProps extends IContactHubInitializerProvidedProps { 
    selectedStore?: string;
}

export type IContactHubInitializerProps = IContactHubInitializerOwnProps & typeof ActionsToDispatch;

export class ContactHubInitializer extends RapComponent<IContactHubInitializerProps> {
    constructor(props: IContactHubInitializerProps, context: IRapComponentContext) {
        super(props, context);
        if(!this.props.selectedStore) {
            this.props.fetchPageData();
        }    
    }

    public render() {
        return (
            <>
                <ConnectedContactView />
            </>
        );
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(
    state: IClientelingViewState,
    providedProps: IContactHubInitializerProvidedProps
): Partial<IContactHubInitializerOwnProps> {
    return {
        ...providedProps,
        selectedStore: PageSelectors.getViewOptions(state)?.selectedStore
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchPageData: PageActions.fetchPageData
};

export const ConnectedContactHubInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ContactHubInitializer);
