import { ActionsUnion, createAction, createActionWithCallback, createThunkActionType } from "../../../platform/redux/ActionHelper";
import { AppointmentsFeature } from "../../../common/Constants";
import {
    AppointmentCountDto,
    AppointmentsView,
    IAppointmentDto,
    OptionSetAttribute,
    IAppointmentForUpdateDto,
    IAppointmentForCreationDto,
    IAppointmentsView,
    IAppointmentModel,
    AppointmentModel,
    IAppointmentType,
    IMRSEmployee
} from "../../../contracts/swagger/_generated";
import { IMessages } from "../Contracts";

/**
 * Actions for Appointments view
 */
 export enum ReservationsActionTypes {
    InitReservationsView = "Reservations/InitReservationsView",
    LogTelemetry = "Reservations/LogTelemetry",

    FetchAppointmentsData = "Reservations/FetchAppointmentsData",
    FetchAppointmentsDataSuccess = "Reservations/FetchAppointmentsDataSuccess",
    FetchMoreAppointmentsDataSuccess = "Reservations/FetchMoreAppointmentsDataSuccess",
    FetchAppointmentsDataFailure = "Reservations/FetchAppointmentsDataFailure",

    FetchAppointmentCountByState = "Reservations/FetchAppointmentCountByState",
    FetchAppointmentCountByStateSuccess = "Reservations/FetchAppointmentCountByStateSuccess",
    FetchAppointmentCountByStateFailure = "Reservations/FetchAppointmentCountByStateFailure",

    FetchAppointmentTypes = "Reservations/FetchAppointmentTypes",
    FetchAppointmentTypesSuccess = "Reservations/FetchAppointmentTypesSuccess",
    FetchAppointmentTypesFailure = "Reservations/FetchAppointmentTypesFailure",

    FetchAppointmentStatus = "Reservations/FetchAppointmentStatus",
    FetchAppointmentStatusSuccess = "Reservations/FetchAppointmentStatusSuccess",
    FetchAppointmentStatusFailure = "Reservations/FetchAppointmentStatusFailure",

    FetchAppointmentStatusReason = "Reservations/FetchAppointmentStatusReason",
    FetchAppointmentStatusReasonSuccess = "Reservations/FetchAppointmentStatusReasonSuccess",
    FetchAppointmentStatusReasonFailure = "Reservations/FetchAppointmentStatusReasonFailure",

    OnAppointmentSelected = "Reservations/OnAppointmentSelected",

    UpdateAppointment = "Reservations/UpdateAppointment",
    UpdateAppointmentSuccess = "Reservations/UpdateAppointmentSuccess",
    UpdateAppointmentFailure = "Reservations/UpdateAppointmentFailure",

    UpdateAppointmentForCreation = "Reservations/UpdateAppointmentForCreation",
    UpdateAppointmentsView = "Reservations/UpdateAppointmentsView",

    SearchForAppointments = "Reservations/SearchForAppointments",
    SearchForAppointmentsSuccess = "Reservations/SearchForAppointmentsSuccess",
    SearchForAppointmentsFailure = "Reservations/SearchForAppointmentsFailure",

    SetMessages = "Reservations/SetMessages",

    ReceivedAppointmentUpsertMessage = "Reservations/ReceivedAppointmentUpsertMessage",

    FetchEmployeeInfo = "Reservations/FetchEmployeeInfo",
    FetchEmployeeInfoSuccess = "Reservations/FetchEmployeeInfoSuccess",
    FetchEmployeeInfoFailure = "Reservations/FetchEmployeeInfoFailure"
}

const ReservationsStandardActions = {
    initReservationsView: () => createAction(ReservationsActionTypes.InitReservationsView),
    
    logTelemetry: (feature: AppointmentsFeature, action: string, properties?: { [key: string]: any }, errorMessage?: string) =>
        createAction(ReservationsActionTypes.LogTelemetry, { feature, action, properties, errorMessage }),

    fetchAppointmentsData: (silentFetch?: boolean) => createAction(ReservationsActionTypes.FetchAppointmentsData, { silentFetch }),
    fetchAppointmentsDataSuccess: (appointmentsData: AppointmentsView) =>
        createAction(ReservationsActionTypes.FetchAppointmentsDataSuccess, { appointmentsData }),
    fetchAppointmentsDataFailure: (error: string) => createAction(ReservationsActionTypes.FetchAppointmentsDataFailure, error),
    fetchMoreAppointmentsDataSuccess: (appointmentsData: AppointmentsView, appointmentId: string) =>
        createAction(ReservationsActionTypes.FetchMoreAppointmentsDataSuccess, { appointmentsData, appointmentId }),

    fetchAppointmentCountByState: () => createAction(ReservationsActionTypes.FetchAppointmentCountByState),
    fetchAppointmentCountByStateSuccess: (appointmentCountByState: AppointmentCountDto[]) => 
        createAction(ReservationsActionTypes.FetchAppointmentCountByStateSuccess, {appointmentCountByState}),
    fetchAppointmentCountByStateFailure: (error: string) => createAction(ReservationsActionTypes.FetchAppointmentCountByStateFailure, error),

    fetchAppointmentTypes: (storeId: string) => createAction(ReservationsActionTypes.FetchAppointmentTypes, storeId),
    fetchAppointmentTypesSuccess: (typesData: IAppointmentType[]) =>
        createAction(ReservationsActionTypes.FetchAppointmentTypesSuccess, typesData),
    fetchAppointmentTypesFailure: (error: string) => createAction(ReservationsActionTypes.FetchAppointmentTypesFailure, error),

    fetchAppointmentStatus: () => createAction(ReservationsActionTypes.FetchAppointmentStatus),
    fetchAppointmentStatusSuccess: (statuses: OptionSetAttribute[]) => createAction(ReservationsActionTypes.FetchAppointmentStatusSuccess, statuses),
    fetchAppointmentStatusFailure: (error: string) => createAction(ReservationsActionTypes.FetchAppointmentStatusFailure, error),

    fetchAppointmentStatusReasons: () => createAction(ReservationsActionTypes.FetchAppointmentStatusReason),
    fetchAppointmentStatusReasonSuccess: (statusReasons: OptionSetAttribute[]) =>
        createAction(ReservationsActionTypes.FetchAppointmentStatusReasonSuccess, statusReasons),
    fetchAppointmentStatusReasonFailure: (error: string) => createAction(ReservationsActionTypes.FetchAppointmentStatusReasonFailure, error),

    onAppointmentSelected: (appointment: IAppointmentDto) => createAction(ReservationsActionTypes.OnAppointmentSelected, appointment),

    updateAppointmentSuccess: () => createAction(ReservationsActionTypes.UpdateAppointmentSuccess),
    updateAppointmentFailure: (error: string) => createAction(ReservationsActionTypes.UpdateAppointmentFailure, error),

    updateAppointmentsView: (view: IAppointmentsView) => createAction(ReservationsActionTypes.UpdateAppointmentsView, view),

    searchForAppointments: (storeId: string, searchText: string) => createAction(ReservationsActionTypes.SearchForAppointments, {storeId, searchText}),
    searchForAppointmentsSuccess: (appts: IAppointmentModel[]) => createAction(ReservationsActionTypes.SearchForAppointmentsSuccess, appts),
    searchForAppointmentsFailure: (error: string) => createAction(ReservationsActionTypes.SearchForAppointmentsFailure, error),

    setMessages: (messages: IMessages) => createAction(ReservationsActionTypes.SetMessages, messages),

    receivedAppointmentUpsertMessage: (body: AppointmentModel) => createAction(ReservationsActionTypes.ReceivedAppointmentUpsertMessage, body),

    fetchEmployeeInfoSuccess: (info: IMRSEmployee, appointmentId: string) => createAction(ReservationsActionTypes.FetchEmployeeInfoSuccess, {info, appointmentId}),
    fetchEmployeeInfoFailure: (error: string) => createAction(ReservationsActionTypes.FetchEmployeeInfoFailure, error)
};

const ReservationsThunkActions = {
    updateAppointmentForCreation: (appt: IAppointmentForCreationDto) => createActionWithCallback<void>(ReservationsActionTypes.UpdateAppointmentForCreation, appt),
    updateAppointment: (storeId: string, appointmentId: string, appt: IAppointmentForUpdateDto, silentFetch: boolean) => 
        createActionWithCallback<void>(ReservationsActionTypes.UpdateAppointment, {storeId, appointmentId, appt, silentFetch}),
    fetchEmployeeInfo: (userObjectIdOrEmail: string, appointmentId: string) => 
        createActionWithCallback<IMRSEmployee>(ReservationsActionTypes.FetchEmployeeInfo, {userObjectIdOrEmail, appointmentId})
}

const ReservationsThunkActionsTypes = {
    updateAppointmentForCreation: (appt: IAppointmentForCreationDto) => createThunkActionType(ReservationsActionTypes.UpdateAppointmentForCreation, appt),
    updateAppointment: (storeId: string, appointmentId: string, appt: IAppointmentForUpdateDto, silentFetch: boolean) => 
        createThunkActionType(ReservationsActionTypes.UpdateAppointment, {storeId, appointmentId, appt, silentFetch}),
    fetchEmployeeInfo: (userObjectIdOrEmail: string, appointmentId: string) => 
        createThunkActionType(ReservationsActionTypes.FetchEmployeeInfo, {userObjectIdOrEmail, appointmentId})
}

export const ReservationsActions = {...ReservationsStandardActions, ...ReservationsThunkActions}
const AllActionsTypes = {...ReservationsStandardActions, ...ReservationsThunkActionsTypes}
export type ReservationsActionsType = ActionsUnion<typeof AllActionsTypes>;
