import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../platform/Layout";
import { PageActions } from "../../platform/components/Page/redux/PageActions";
import { IClientelingViewState } from "../Contracts";
import { RunnerRequestsView } from "../../views/RunnerRequestsView/RunnerRequestsView";

import "./RunnerRequestsHub.scss";


class RunnerRequestsHub extends RapComponent<IRunnerRequestsHubProps> {
    constructor(props: IRunnerRequestsHubProps, context: IRapComponentContext) {
        super(props, context)
    }

    public render() {
        return (
            <>
                <RunnerRequestsView />
            </>
        );
    }
}

interface IRunnerRequestsHubProvidedProps extends IRapComponentProperties { }

interface IRunnerRequestsHubOwnProps extends IRunnerRequestsHubProvidedProps {
}

export type IRunnerRequestsHubProps = IRunnerRequestsHubOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestsHubProvidedProps
): Partial<IRunnerRequestsHubOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry
};

export default connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(RunnerRequestsHub);
