import React, {FC, useEffect, useState} from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, Link, Icon, TextField, DetailsList, IColumn, CheckboxVisibility, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";

import { IContactDto, ICustomerInsightsDto, IProductRecommendationsItem } from "../../../../../../../../contracts/swagger/_generated";

import { Card } from "../../../../../../../../common/components/Card/Card";
import { localizedStrings } from "../../../../../../../../common/localization/LocalizedStrings";

import { IRapComponentProperties } from "../../../../../../../../platform/Layout";

import { IClientelingViewState } from "../../../../../../../../pages/Contracts";

import * as ContactsSelectors from "../../../../../../redux/ContactsSelectors";

import "../../../ContactCard.scss"
import { DetailListColumnSizes, DetailListColumnSizes_Max, NumberOfRecommendations } from "../../../../../../../../common/Constants";
import { ContactsActions } from "../../../../../../redux/ContactsActions";
import { NoResults } from "../../../../../../../../common/components/NoResults/NoResults";
import { LoadingContainer } from "../../../../../../../../common/components/LoadingContainer/LoadingContainer";
//import "./ContactProductSuggestions.scss";

interface IContactProductSuggestionsProvidedProps extends IRapComponentProperties {
}

interface IContactProductSuggestionsOwnProps extends IContactProductSuggestionsProvidedProps {
    selectedContactInsights: ICustomerInsightsDto;
    productRecommendations: IProductRecommendationsItem[];
    selectedContact: IContactDto;
}

export type IContactProductSuggestionsProps = ConnectedProps<typeof connector> & IContactProductSuggestionsProvidedProps;

const ContactProductSuggestionsInitializer: FC<IContactProductSuggestionsProps> = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [productRecommendationsItems, setProductRecommendationsItems] = useState<IProductRecommendationsItem[]>([]);
    const {productSuggestions: locStrings} = localizedStrings.ContactsView.profilePage;

    useEffect(()=>{
        if (props.selectedContact?.email){
            setIsLoading(true);
            props.fetchProductRecommendations(props.selectedContact?.email, NumberOfRecommendations, true).then(x => {
                setProductRecommendationsItems(x);
                }).finally(() => {
                    setIsLoading(false);
                });
        }
        
    }, [props.selectedContact?.email]);

    const columns : IColumn[] =
        [
            {
                key: 'column1',
                name: locStrings.productName,
                fieldName: 'title',
                minWidth: DetailListColumnSizes.small,
                maxWidth: DetailListColumnSizes_Max.small,
                data: 'string',
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            },

            {
                key: 'column2',
                name: locStrings.productSKU,
                fieldName: 'id',
                minWidth: DetailListColumnSizes.small,
                maxWidth: DetailListColumnSizes_Max.small,
                data: 'string',
                isCollapsible: true,
                isPadded: true,
                isResizable: true,
                flexGrow: 1
            }
        ];
    
    
    return (
        <>
            <Card className="flex c-card">
                <div className="flex-row">
                    <div className="c-card-header">
                        <div>
                        {locStrings.title}
                        </div>
                    </div>
                </div>

                <div className="c-card-body">
                    <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                        <>                
                            {productRecommendationsItems && productRecommendationsItems.length > 0 ? (
                                 <DetailsList
                                     items={productRecommendationsItems}
                                     columns={columns}
                                     isHeaderVisible={true}
                                     checkboxVisibility={CheckboxVisibility.hidden}
                                     selectionMode={SelectionMode.none}
                                  />
                             ) : (
                                <NoResults description={locStrings.notFound}></NoResults>
                             )}
                          </>
                    </LoadingContainer>
                </div>
            </Card>
        </>
    );
};

function mapStateToProps(state: IClientelingViewState, providedProps: IContactProductSuggestionsProvidedProps): Partial<IContactProductSuggestionsOwnProps> {
    return {
        ...providedProps,
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact

    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchProductRecommendations: ContactsActions.fetchProductRecommendations
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const ContactProductSuggestions = connector(ContactProductSuggestionsInitializer);
