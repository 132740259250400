import React, { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IClientelingViewState } from "../../../pages/Contracts";
import { EnableDisableRequestsModal } from "../../../views/RunnerRequestsView/modals/EnableDisableRequestsModal/EnableDisableRequestsModal";
import { RunnerRequestNotificationModal } from "../../../views/RunnerRequestsView/modals/RunnerRequestNotificationModal/RunnerRequestNotificationModal";
import { ViewRunnerRequestModal } from "../../../views/RunnerRequestsView/modals/ViewRunnerRequestModal/ViewRunnerRequestModal";
import { CreateRunnerRequestModal } from "../../../views/RunnerRequestsView/modals/CreateRunnerRequestModal/CreateRunnerRequestModal";
import { IRapComponentProperties } from "../../Layout";

interface IRunnerRequestModalHandlerProvidedProps extends IRapComponentProperties {
}

interface IRunnerRequestModalHandlerOwnProps {
}

export type IRunnerRequestModalHandlerProps = ConnectedProps<typeof connector> & IRunnerRequestModalHandlerProvidedProps;

const RunnerRequestModalHandlerInitializer: FC<IRunnerRequestModalHandlerProps> = (props) => {


    return (
        <>
            <EnableDisableRequestsModal />
            <RunnerRequestNotificationModal />
            <ViewRunnerRequestModal />
            <CreateRunnerRequestModal />
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestModalHandlerProvidedProps): Partial<IRunnerRequestModalHandlerOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const RunnerRequestModalHandler = connector(RunnerRequestModalHandlerInitializer);