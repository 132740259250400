import "./Portal.scss";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { ObservableValue } from "./../../../core/Observable";
import { Observer } from "./../../../core/components/Observer/Observer";
import { IPortalProps } from "./Portal.Props";

/**
 * The Portal component is used to create a React Portal through a well known component.
 * This component allows the platform to control where portals are rooted in the document
 * and ensure these are managed properly.
 */
export class Portal extends React.Component<IPortalProps> {
    private parentElement: HTMLElement;
    private hostElement: HTMLDivElement;
    private mounted: ObservableValue<boolean> = new ObservableValue<boolean>(false);

    constructor(props: IPortalProps) {
        super(props);

        // Determine the element that will host the portal.
        let parentElement = this.props.portalElement;
        if (!parentElement && this.props.portalSelector) {
            parentElement = document.querySelector(this.props.portalSelector) as HTMLElement;
        }
        if (!parentElement) {
            parentElement = document.querySelector(".bolt-portal-host") as HTMLElement;
            if (!parentElement) {
                parentElement = document.createElement("div");
                parentElement.className = "bolt-portal-host absolute-fill no-events scroll-hidden";
                document.body.appendChild(parentElement);
            }
        }
        this.parentElement = parentElement;

        // Create the hosting element for the portal.
        this.hostElement = document.createElement("div");
        this.hostElement.classList.add("bolt-portal");
        this.hostElement.classList.add("absolute-fill");

        // If custom class's are supplied add them (1 at a time since IE doesnt support multiple args).
        if (props.className) {
            const classNames = props.className.split(" ");
            for (let className of classNames) {
                this.hostElement.classList.add(className);
            }
        }
    }

    public render(): React.ReactPortal {
        // NOTE: We dont render the children until after we have mounted the portal.
        //  If the caller needs to access the document while mounting the content this
        //  will ensure the children of the portal are not mounted until the portal
        //  is attached to the DOM.
        return ReactDOM.createPortal(
            <Observer mounted={this.mounted}>{(props: { mounted: boolean }) => (props.mounted ? this.props.children : null)}</Observer>,
            this.hostElement
        );
    }

    public componentDidMount() {
        this.parentElement.appendChild(this.hostElement);
        this.mounted.value = true;
    }

    public componentWillUnmount() {
        this.parentElement.removeChild(this.hostElement);
    }
}
