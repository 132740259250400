import { IStoreDto, Page, FeatureFlag, StoreAssociateDto } from "../../../contracts/swagger/_generated";
import { Themes } from "../../../common/Constants";
import { IMessageBarStyleProps } from "@fluentui/react";

export interface IPageAwareState {
    pageViewState?: IPageViewState;
}

export interface IPageViewState {
    data?: IPageDataState;
    ux?: IPageUxState;
}

export interface IPageUxState {
    viewOptions?: IViewOptions;
    contactPages?: IContactPages;
    panels?: IPanels;
    modals?: IModals;
    theme?: Themes;
    menuItems?: IMenuItems;
    messages?: IMessages;
}

export interface IMessages {
    errorMessage?: string;
    toastMessage?: string;
}

export interface IPageDataState {
    stores?: IStoreDto[];
    resources?: IPageResources;
    userData?: IUserData;
}

export interface IUserData {
    pageData?: Page;
    userRole?: UserRole;
    user?: StoreAssociateDto;
}

export interface IPageResources {
    retailEventsUrl?: string;
    feedbackUrl?: string;
}

export interface IMenuItems {
    settingsMenuItems?: NavMenuItem[];
    navMenuItems?: NavMenuItem[];
}

export interface IViewOptions {
    selectedDate?: any;
    selectedAssignStatus?: string;
    selectedView?: string;
    selectedStore?: string;
    selectedAppointmentState?: string;
    selectedAppointmentTypes?: ISelectedAppointmentType[];
}

export interface IContactPages {
    displayContactProfilePage?: boolean;
    displayContactReservationsPage?: boolean;
}

export interface IPanels {
    displayAppointmentStateFilter?: boolean;
    displayUserSettingsPanel?: boolean;
    displayFilterPanel?: boolean;
    displayContactMenuPanel?: boolean;
    settingsMenuOpen?: boolean;
    displayRunnerRequestStateFilter?: boolean;
    displayAboutPanel?: boolean;
}

export interface IModals {
    displayAddClientModal?: boolean;
    displayCreateAppointmentModal?: boolean;
    displayShareAppointmentModal?: boolean;
    displayCancelAppointmentModal?: boolean;
    displayEditAssociateNotes?: boolean;
    displayEditAppointmentModal?: boolean;
    displayDynamicsCaseSelectorModal?: boolean;
    displayCreateLeadModal?: boolean;
    displayRunnerRequestNotificationModal?: boolean;
    displayViewRunnerRequestModal?: boolean;
    displayEnableDisableRequestsModal?: boolean;
    displayCreateRunnerRequestModal?: boolean;
}

export interface ISelectedAppointmentType {
    isChecked: boolean;
    id: string;
    label: string;
    ariaLabel: string;
}
export interface NavMenuItem {
    id: number;
    title: string;
    iconName?: string;
    minimumRequiredUserRole: UserRole;
    pageRoute?: string;
    isDefault?: boolean;
}

//Note: user roles should be listed in ascending order of precedence: ie if you have level 3 you get access to everything below that level as well.
export enum UserRole {
    None = 0,
    CSS = 1,
    Associate = 2,
    Admin = 3
}

export interface IFeatureManagementState {
    featureFlags: FeatureFlag[];
}
