import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../platform/Layout";
import { PageActions } from "../../platform/components/Page/redux/PageActions";
import { IClientelingViewState } from "../Contracts";
import { ReservationsView } from "../../views/ReservationsView/ReservationsView";

import "./ReservationsHub.scss";


export class ReservationsHubInitializer extends RapComponent<IReservationsHubInitializerProps> {
    constructor(props: IReservationsHubInitializerProps, context: IRapComponentContext) {
        super(props, context);
        this.props.listenToAppointmentUpsertMessage();
    }

    public render() {
        return (
            <>
                <ReservationsView />
            </>
        );
    }
}

interface IReservationsHubInitializerProvidedProps extends IRapComponentProperties { }

interface IReservationsHubInitializerOwnProps extends IReservationsHubInitializerProvidedProps {
}

export type IReservationsHubInitializerProps = IReservationsHubInitializerOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IReservationsHubInitializerProvidedProps
): Partial<IReservationsHubInitializerOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    listenToAppointmentUpsertMessage: PageActions.listenToAppointmentUpsertMessage,
};

export const ConnectedReservationsHubInitializer = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ReservationsHubInitializer);
