import React from "react";
import { connect } from "react-redux";
import { Dropdown, IDropdownOption, IDropdownStyles } from "@fluentui/react";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../Layout";
import { IClientelingViewState } from "../../../pages/Contracts";
import { Themes } from "../../../common/Constants";
import { PageActions } from "../Page/redux/PageActions";

import * as Constants from "../../../common/Constants";
import * as PageSelectors from "../Page/redux/PageSelectors";

import "./UserSettings.scss";

const themeColorOptions: IDropdownOption[] = [
    { key: Themes.Default, text: "Light" },
    { key: Themes.Dark, text: "Dark" },
    { key: Themes.HighContrast, text: "High Contrast" }
];

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 250 }
};

class UserSettingsInitializer extends RapComponent<IUserSettingsProps> {
    constructor(props: IUserSettingsProps, context: IRapComponentContext) {
        super(props, context);
    }

    public render() {
        return (
            <div>
                <div className="c-setting-row">
                    <div className="c-setting-control">
                        <Dropdown
                            label="Override selected theme"
                            defaultSelectedKey={this.props.theme}
                            options={themeColorOptions}
                            styles={dropdownStyles}
                            onChange={this._onSelectedThemeChanged}
                        />
                    </div>
                </div>
            </div>
        );
    }

    private _onSelectedThemeChanged = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
        if (option) {
            this.props.setTheme(option.key as Themes);
        }
    }
}

interface IUserSettingsProvidedProps extends IRapComponentProperties {}

interface IUserSettingsOwnProps extends IUserSettingsProvidedProps {
    theme: Themes;
}

export type IUserSettingsProps = IUserSettingsOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IUserSettingsProvidedProps): Partial<IUserSettingsProvidedProps> {
    return {
        ...providedProps,
        theme: PageSelectors.getTheme(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    setTheme: PageActions.setTheme
};

export const UserSettings = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(UserSettingsInitializer);
