import React, { FC } from "react";
import { connect } from "react-redux";

import { IClientelingViewState } from "../../../pages/Contracts";
import { CreateAppointmentModal } from "../../../views/ReservationsView/modals/CreateAppointmentModal/CreateAppointment";
import { ShareAppointmentModal } from "../../../views/AppointmentDetailsView/modals/ShareAppointmentModal/ShareAppointment";
import { CancelAppointmentModal } from "../../../views/AppointmentDetailsView/modals/CancelAppointmentModal/CancelAppointmentModal";
import { EditAssociateNotesModal } from "../../../views/AppointmentDetailsView/modals/EditAssociateNotesModal/EditAssociateNotesModal";
import { EditAppointmentModal } from "../../../views/AppointmentDetailsView/modals/EditAppointmentModal/EditAppointmentModal";
import { CreateLeadModal } from "../../../views/AppointmentDetailsView/modals/CreateLeadModal/CreateLeadModal";
import { IRapComponentProperties } from "../../Layout";

interface IModalHandlerProvidedProps extends IRapComponentProperties {
 
}

interface IModalHandlerOwnProps extends IModalHandlerProvidedProps {
    
}

export type IModalHandlerProps = IModalHandlerOwnProps & typeof ActionsToDispatch;

export const ModalHandlerInitializer: FC<IModalHandlerProps> = (props) => {


    return (
        <>
            <CreateAppointmentModal/>
            <EditAppointmentModal/>
            <ShareAppointmentModal/>
            <CancelAppointmentModal/>
            <EditAssociateNotesModal/>
            <CreateLeadModal/>
        </>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IModalHandlerProvidedProps): Partial<IModalHandlerOwnProps> {
    return {
        ...providedProps,
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
};

export const ModalHandler = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ModalHandlerInitializer);