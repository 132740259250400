import createSagaMiddleware from "redux-saga";
import thunkMiddleware, { ThunkDispatch } from 'redux-thunk';
import { AnyAction, applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { IRapPageContext } from "../Context";
import { getClientelingStoreProviderProps } from "../core/components/StoreProvider/StoreProvider"; 
import rootReducer from "./RootReducer";
import rootSaga from "./RootSaga";


export default (context: IRapPageContext) => {

    const providerProps = getClientelingStoreProviderProps(context);
    const sagaContext = providerProps.sagaContext;

    const sagaMiddleware = createSagaMiddleware({context: sagaContext});

    type StateType = ReturnType<typeof rootReducer>
    type DispatchFunctionType = ThunkDispatch<StateType, undefined, AnyAction>

    const composeEnhancers = composeWithDevTools({
        name: "testreduxtools",
        maxAge: 500,
        trace: true
    });

    const store = createStore(
        rootReducer, 
        providerProps.initialState,
        composeEnhancers(
            applyMiddleware<DispatchFunctionType, StateType>(thunkMiddleware, sagaMiddleware),
        )
    );

    sagaMiddleware.run(rootSaga);

    return store;
}