import React from "react";
import { connect } from "react-redux";

import { FocusZoneDirection } from "@fluentui/react/lib/FocusZone";
import { Callout, DirectionalHint } from "@fluentui/react/lib/Callout";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../Layout";

import { ScreenSize } from "../../../platform/core/util/Screen";
import { ScreenSizeConditional } from "../../../platform/core/components/ScreenSize/ScreenSize";

import { NavMenu } from "./NavMenu/NavMenu";
import { TopNavMenu } from "./TopNavMenu/TopNavMenu";

import { IClientelingViewState } from "../../../pages/Contracts";
import { PageActions } from "../Page/redux/PageActions";
import { PageFeature } from "../../../common/Constants";
import { NavMenuItem, UserRole } from "../Page/Contracts";
import logoWhite from "../../../styling/images/clienteling-icon-white.png";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import "./NavBar.scss";

import * as PageSelectors from "../Page/redux/PageSelectors";
import * as FeatureManagementSelectors from "../../../views/FeatureManagement/redux/FeatureManagementSelectors";

export class NavBar extends RapComponent<INavBarProps, {}> {
    private _menuButtonElement: HTMLDivElement | null;
    private _settingsButtonElement: HTMLDivElement | null;

    constructor(props: INavBarProps, context: IRapComponentContext) {
        super(props, context);
    }

    public componentDidMount() {
        this.props.logTelemetry(PageFeature.NavBar, "loaded", {});
    }

    public render() {
        return (
            <React.Fragment>
                <div className="c-navbar" role="banner">
                    <div className="flex-row">
                        <div className="c-logo-color-bkgrd">
                            <img alt="Home" className="c-logo-only-image is-MobileView-Logo" src={logoWhite} />
                        </div>
                        <div className="c-my-store">{localizedStrings.App.name}</div>
                        <ScreenSizeConditional condition={screenSize => screenSize > ScreenSize.xsmall}>
                            <div className="c-top-nav-row">
                                <TopNavMenu
                                    navMenuItems={this.props.menuItems}
                                    selectedMenuItemId={this.props.selectedNavMenuItemId}
                                    onSelectedMenuItemChanged={this._onSelectedMenuItemChanged}
                                />
                            </div>
                        </ScreenSizeConditional>
                    </div>
                    <ScreenSizeConditional condition={screenSize => screenSize === ScreenSize.xsmall}>
                        <div className="c-mobile-nav-row flex-row">
                            <TopNavMenu
                                navMenuItems={this.props.menuItems}
                                selectedMenuItemId={0}  //to-do: handle multiple tabs
                                onSelectedMenuItemChanged={this._onSelectedMenuItemChanged}
                            />
                        </div>
                    </ScreenSizeConditional>
                </div>
                {this.props.sideDrawerOpen && (
                    <Callout
                        className=""
                        gapSpace={0}
                        target={this._menuButtonElement}
                        isBeakVisible={false}
                        onDismiss={this._onCalloutDismiss}
                        directionalHint={DirectionalHint.bottomLeftEdge}
                        setInitialFocus={true}
                    >
                        <div className="flex-column flex-grow">
                            <NavMenu
                                menuItems={this.props.menuItems}
                                selectedMenuItemId={this.props.selectedNavMenuItemId}
                                direction={FocusZoneDirection.vertical}
                                onSelectedMenuItemChanged={this._onSelectedMenuItemChanged}
                            />
                        </div>
                    </Callout>
                )}
            </React.Fragment>
        );
    }

    private _onSelectedMenuItemChanged = (id: number) => {
        this.props.logTelemetry(PageFeature.NavMenu, "Menu item clicked", { menuItemId: id });
        this.props.updateSelectedNavItem(id);
    };

    private _onCalloutDismiss = () => {
        this.props.logTelemetry(PageFeature.NavBar, "Close nav callout clicked", {});
        this.props.toggleSideDrawer();
    };
}

interface INavBarProvidedProps extends IRapComponentProperties {}

interface INavBarOwnProps extends INavBarProvidedProps {
    menuItems: NavMenuItem[];
    isRunnerRequestsEnabled: boolean;
}

export type INavBarProps = INavBarOwnProps & typeof ActionsToDispatch;

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: INavBarProvidedProps): Partial<INavBarOwnProps> {
    return {
        ...providedProps,
        menuItems: PageSelectors.getMenuItems(state),
        isRunnerRequestsEnabled: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableRunnerRequests", false)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    fetchPageData: PageActions.fetchPageData,
    updateNavMenuItems: PageActions.updateNavMenuItems
};

export const ConnectedNavBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(NavBar);
