import * as React from "react";
import { IObservableValue, ObservableValue } from "./../Observable";

export enum ScreenBreakpoints {
    /**
     * Smallest breakpoint used to react which usually corresponds to a mobile screen < 375px.
     */
    xsmall = 1,

    /**
     * Medium breakpoint used to react when the screen size >= 375px and less than
     * the next breakpoint if exists.
     */
    small = 375,

    /**
     * Medium breakpoint used to react when the screen size >= 768px and less than
     * the next breakpoint if exists.
     */
    medium = 768,

    /**
     * Large breakpoint used to react when the screen size >= 946px and less than
     * the next breakpoint if exists.
     */
    large = 946,

    /**
     * Extra large breakpoint used to react when the screen size >= 1100px and less than
     * the next breakpoint if exists.
     */
    xlarge = 1100,

    /**
     * Largest breakpoint used to react when the screen size >= 1366px and less than
     * the next breakpoint if exists.
     */
    xxlarge = 1366
}

export enum ScreenSize {
    xsmall = 0,
    small = 1,
    medium = 2,
    large = 3,
    xlarge = 4,
    xxlarge = 5
}

export interface IScreenContext {
    /**
     * Specifies the size of the current window
     */
    size: IObservableValue<ScreenSize>;
}

class ScreenContextImp implements IScreenContext {
    public size: IObservableValue<ScreenSize>;

    constructor() {
        this.size = new ObservableValue<ScreenSize>(this.getCurrentSize());
        window.addEventListener("resize", this.onResize);
    }

    private getCurrentSize() {
        if (window.innerWidth >= ScreenBreakpoints.xxlarge) {
            return ScreenSize.xxlarge;
        } else if (window.innerWidth >= ScreenBreakpoints.xlarge) {
            return ScreenSize.xlarge;
        } else if (window.innerWidth >= ScreenBreakpoints.large) {
            return ScreenSize.large;
        } else if (window.innerWidth >= ScreenBreakpoints.medium) {
            return ScreenSize.medium;
        } else if (window.innerWidth >= ScreenBreakpoints.small) {
            return ScreenSize.small;
        } else {
            return ScreenSize.xsmall;
        }
    }

    private onResize = () => {
        const size = this.getCurrentSize();
        if (this.size.value !== size) {
            this.size.value = size;
        }
    };
}

export const ScreenContext = React.createContext<IScreenContext>(new ScreenContextImp());
