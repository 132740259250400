import React, { FormEvent } from "react";
import { connect } from "react-redux";
import { ICommandBarItemProps, CommandBar, DatePicker, DefaultButton, IconButton, Dropdown, IDropdownOption, ActionButton} from "@fluentui/react";
import moment from "moment";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../Layout";
import { IClientelingViewState } from "../../../../../pages/Contracts";
import { IStoreDto } from "./../../../../../contracts/swagger/_generated";
import { FeatureManagementActions } from "../../../../../views/FeatureManagement/redux/FeatureManagementActions";
import { PageFeature, dayPickerStrings } from "../../../../../common/Constants";
import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { IAuthenticationData, IRapAuthenticationService } from "../../../../services/Authentication";
import { IModals, IPanels, IViewOptions } from "../../../Page/Contracts";
import { PageActions } from "../../../Page/redux/PageActions";

import * as PageSelectors from "../../../Page/redux/PageSelectors";

import "./StandardBar.scss";

interface IStandardBarProvidedProps extends IRapComponentProperties {
    commandBarItems: ICommandBarItemProps[];
    viewDropdownOptions: IDropdownOption[];
    onSelectedDateChanged: (newDate: any) => void;
    onSearchSelected: () => void;
    onChangeView: (event: FormEvent<HTMLDivElement>, selectedOption?: IDropdownOption<any>, index?: number) => void;
    autoFocusOnSearch: boolean;
}

interface IStandardBarOwnProps extends IStandardBarProvidedProps {
    viewOptions: IViewOptions;
    panels: IPanels;
    modals: IModals;
    stores: IStoreDto[];
    isUserSettingsPanelOpen: boolean;
    isAdmin: boolean;
    feedbackURL: string;
}

export type IStandardBarProps = IStandardBarOwnProps & typeof ActionsToDispatch;

class StandardBarInitializer extends RapComponent<IStandardBarProps> {
    constructor(props: IStandardBarProps, context: IRapComponentContext) {
        super(props, context);
    }

    public componentDidMount() {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "loaded", {});
    }

    public render() {
        return (
            <div className="c-clienteling-cmdbar flex-row">
                <div className="flex-column">
                    <IconButton
                        ariaLabel={this.props.viewOptions?.selectedView}
                        className="c-hamburger-icon"
                        iconProps={{ iconName: 'GlobalNavButton' }}
                        onClick={this._onAppointmentStateFilterMenuClick} />
                </div>
                <div className="flex-column c-calendar-picker" role="navigation">
                    <DatePicker
                        strings={dayPickerStrings}
                        initialPickerDate={this._getTodaysDate()}
                        value={moment(this.props.viewOptions?.selectedDate).toDate()}
                        onSelectDate={this._onDateChanged}
                        isMonthPickerVisible={false}
                        ariaLabel={localizedStrings.CommandBar.selectADate}
                    />
                </div>
                <div className="flex-column c-dropdown">
                    <Dropdown
                        ariaLabel={this.props.viewOptions?.selectedView}
                        selectedKey={this.props.viewOptions?.selectedView}
                        options={this.props.viewDropdownOptions}
                        onChange={this.props.onChangeView}
                    />
                </div>
                <div className="flex-column flex-grow" />
                <div className="flex-column c-store-selection">
                    <DefaultButton 
                        text={this._getSelectedStoreName()}
                        ariaLabel={this._getSelectedStoreName()}
                        onClick={this._onFilterClicked}
                        checked />
                </div>
                <div className="flex-column c-filter-button">
                    <ActionButton
                        iconProps={{ iconName: 'Filter' }}
                        onClick={this._onFilterClicked}
                    >
                        {localizedStrings.CommandBar.filter}
                    </ActionButton>
                </div>
                <div className="flex-column c-search-button">
                    <ActionButton
                        iconProps={{ iconName: 'Search' }}
                        onClick={this.props.onSearchSelected}
                        autoFocus={this.props.autoFocusOnSearch}
                    >
                        {localizedStrings.CommandBar.search}
                    </ActionButton>
                </div>
                <div className="flex-column c-newappt-button">
                    <ActionButton
                        iconProps={{ iconName: 'Add' }}
                        onClick={this._onClickNewAppointment}
                    >
                        {localizedStrings.CommandBar.newAppointment}
                    </ActionButton>   
                </div>
                <div className="flex-column">
                    <CommandBar
                        items={[]}
                        overflowItems={this._getCommandBarItems()}
                        className={"c-command-bar"}
                        overflowButtonProps={{
                            ariaLabel: localizedStrings.CommandBar.moreCommands
                        }}
                    />
                </div>
            </div>
        );
    }

    // This function finds the name of the currently selected store
    private _getSelectedStoreName = (): string => {
        var curStore = this.props.viewOptions?.selectedStore;
        var stores = this.props.stores?.find(store => store.storeNumber === curStore);
        if (stores === undefined) {
            return "";
        }
        var storeName = stores.name;
        return storeName;
    };

    private _isRunningInTeams = (): boolean => {
        var authData: IAuthenticationData;
        authData = this.context.pageContext.getService<IRapAuthenticationService>("IRapAuthenticationService").getData();
        return authData.isRunningInTeams;
    };

    private _getCommandBarItems(): ICommandBarItemProps[] {
        let items: ICommandBarItemProps[] = [];
        if (this.props.commandBarItems) {
            items = items.concat(this.props.commandBarItems);
        }
        let commonItems: ICommandBarItemProps[] = [
            {
                key: "feedback",
                name: localizedStrings.CommandBar.leaveFeedback,
                onClick: this._onFeedbackClick,
                className: "c-stackpanel-button",
                iconOnly: true,
                iconProps: { iconName: "Emoji2" },
                role: "menuitem",
                ariaLabel: localizedStrings.CommandBar.leaveFeedback
            }
        ];
        items = items.concat(commonItems);
        if (!this._isRunningInTeams()) {
            items.push({
                key: "settings",
                name: localizedStrings.CommandBar.settings,
                onClick: this._onUserSettingsClick,
                className: "c-stackpanel-button",
                iconOnly: true,
                iconProps: { iconName: "Settings" },
                role: "menuitem",
                ariaLabel: localizedStrings.CommandBar.settings
            });
        }
        // About Page
        items.push({
            key: "settings",
            name: localizedStrings.CommandBar.about,
            onClick: this._onAboutClick,
            className: "c-stackpanel-button",
            iconOnly: true,
            iconProps: { iconName: "Info" },
            role: "menuitem",
            ariaLabel: localizedStrings.CommandBar.about
        })
        return items;
    }

    private _onAppointmentStateFilterMenuClick = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "Appointment state filter clicked");
        this.props.updatePanels({ displayAppointmentStateFilter: !this.props.panels.displayAppointmentStateFilter });
    }

    private _onFeedbackClick = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "Leave feedback clicked");
        if (this.props.feedbackURL) {
            window.open(this.props.feedbackURL);
        }
    };

    private _onAboutClick = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "About panel clicked");
        this.props.updatePanels({ displayAboutPanel: !this.props.panels.displayAboutPanel });
    };

    private _onUserSettingsClick = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "User settings panel clicked");
        this.props.updatePanels({ displayUserSettingsPanel: !this.props.panels.displayUserSettingsPanel });
    };

    private _onFilterClicked = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "Filter panel clicked");
        this.props.updatePanels({ displayFilterPanel: !this.props.panels.displayFilterPanel });
    };

    private _onDateChanged = (date: Date | null | undefined): void => {
        this.props.onSelectedDateChanged(date);
    };

    private _onClickNewAppointment = () => {
        this.props.logTelemetry(PageFeature.ClientelingCommandBar, "New appointment clicked");
        this.props.updateModals({ displayCreateAppointmentModal: !this.props.modals.displayCreateAppointmentModal });
    };

    private _getTodaysDate(): Date {
        return moment().toDate();
    }
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IStandardBarProvidedProps): Partial<IStandardBarOwnProps> {
    return {
        ...providedProps,
        viewOptions: PageSelectors.getViewOptions(state),
        panels: PageSelectors.getPanels(state),
        modals: PageSelectors.getModals(state),
        stores: PageSelectors.getStores(state),
        isAdmin: PageSelectors.getPageData(state)?.isAdmin,
        feedbackURL: PageSelectors.getResources(state)?.feedbackUrl
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    logTelemetry: PageActions.logTelemetry,
    updateViewOptions: PageActions.updateViewOptions,
    updatePanels: PageActions.updatePanels,
    updateModals: PageActions.updateModals,
    fetchFeatureFlags: FeatureManagementActions.fetchFeatureFlags
};

export const StandardBar = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(StandardBarInitializer);
