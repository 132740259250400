import React, { FC } from "react";

import "./Card.scss";

export interface ICardProps {
    className?: string;
    id?: string;
}

export const Card: FC<ICardProps> = (props) => {
    return (
        <div className={`c-card-container ${props.className ? props.className : ""}`} id={props.id}>
            { props.children }
        </div>
    );
}