import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Text, PersonaSize, Spinner, SpinnerSize } from "@fluentui/react";
import moment from "moment";
import { useParams } from "react-router-dom";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { Card } from "../../../../../../common/components/Card/Card";
import { IActivityDto, IContactDto, ICustomerInsightsDto } from "../../../../../../contracts/swagger/_generated";
import { UserPersona } from "../../../../../../common/components/UserPersona/UserPersona";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { PermissionGate } from "../../../../../../common/components/PermissionGate/PermissionGate";
import { UserRole } from "../../../../../../platform/components/Page/Contracts";
import { ContactsActions } from "../../../../redux/ContactsActions";

import * as ContactsSelectors from "../../../../redux/ContactsSelectors";
import * as PageSelectors from "../../../../../../platform/components/Page/redux/PageSelectors";

import "./ContactDetails.scss";
import { LoadingContainer } from "../../../../../../common/components/LoadingContainer/LoadingContainer";

//Props passed by parent component
interface IContactDetailsProvidedProps extends IRapComponentProperties {
    className?: string;
}

interface IContactDetailsOwnProps {
    selectedContact?: IContactDto;
    selectedContactInsights?: ICustomerInsightsDto;
    userRole?: UserRole;
}

export type IContactDetailsProps = ConnectedProps<typeof connector> & IContactDetailsProvidedProps;

const ContactDetailsInitializer: FC<IContactDetailsProps> = (props) => {
    let { contactId } = useParams<any>();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.userRole == UserRole.Admin && props.selectedContact?.contactId == contactId && props.selectedContact?.email) {
            setIsLoading(true);
            props.fetchContactInsights(props.selectedContact?.email).catch(e => {
                // empty catch to stop 404's from propogating upwards
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [props.selectedContact?.contactId, props.userRole]);

    const getLastUserActivity = () => {
        let activityLength = props.selectedContactInsights?.activity?.length;
        let lastActivity: IActivityDto = props.selectedContactInsights?.activity[activityLength-1];
        
        if(lastActivity) {
            return moment(lastActivity?.activityTime).fromNow();
        }
        return localizedStrings.ContactsView?.contactDetails.noValue;
    }

    const getCustomerSince = () => {
        if(props.selectedContactInsights?.customerSince) {
            return moment(props.selectedContactInsights?.customerSince).format("LL");
        }
        return localizedStrings.ContactsView?.contactDetails.noValue;
    }

    const getCompany = () => {
        if(props.selectedContactInsights?.company) {
            return props.selectedContactInsights.company;
        }
        return localizedStrings.ContactsView?.contactDetails.noValue;
    }

    return (
        <Card className={`${props.className} c-contactDetails`}>
            <div className="c-persona-container">
                <UserPersona 
                    imageUrl={props.selectedContact?.profileImage}
                    imageInitials={`${props.selectedContact?.firstName?.charAt(0)}${props.selectedContact?.lastName?.charAt(0)}`}
                    fullName={`${props.selectedContact?.firstName} ${props.selectedContact?.lastName}`}
                    secondaryText={props.selectedContact?.email}
                    tertiaryText={props.selectedContact?.phone}
                    personaSize={PersonaSize.size100}
                    renderDetails
                />
            </div>
            <PermissionGate minimumUserRole={UserRole.Admin}>
                <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                    <div className="flex-row c-info-container">
                        <div className="c-labels">
                            <Text>{localizedStrings.ContactsView?.contactDetails.clientSince}</Text>
                            <Text>{localizedStrings.ContactsView?.contactDetails.lastInteraction}</Text>
                            <Text>{localizedStrings.ContactsView?.contactDetails.company}</Text>
                        </div>
                        <div className="flex c-values">
                            <Text>{getCustomerSince()}</Text>
                            <Text>{getLastUserActivity()}</Text>
                            <Text>{getCompany()}</Text>
                        </div>
                    </div>
                </LoadingContainer>
            </PermissionGate>
        </Card>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactDetailsProvidedProps): Partial<IContactDetailsOwnProps> {
    return {
        ...providedProps,
        selectedContact: ContactsSelectors.getSelectedContactInfo(state)?.selectedContact,
        selectedContactInsights: ContactsSelectors.getCustomerInsightsData(state)?.legacyCustomerInsights,
        userRole: PageSelectors.getUserData(state)?.userRole
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactInsights: ContactsActions.fetchContactInsights
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ContactDetailsInitializer);