import * as Cookie_Util from "../util/Cookie";

/**
 * Best-effort attempt to retrieve a string value from local storage
 *
 * @param key Storage key
 * @param defaultValue Optional value to return if the key was not present or there was an error retrieving it
 */
export function tryGetLocalStorageValue(key: string, defaultValue: string | null = null): string | null {
    let result: string | null = null;
    try {
        result = localStorage.getItem(key);
    } catch {}

    return result === null ? defaultValue : result;
}

/**
 * Best-effort attempt to retrieve an object from local storage (using JSON.parse)
 *
 * @param key Storage key
 * @param defaultValue Optional value to return if the key was not present or there was an error retrieving it
 */
export function tryGetLocalStorageObject<T>(key: string, defaultValue?: T): T | undefined {
    const stringValue = tryGetLocalStorageValue(key);
    if (!stringValue) {
        return defaultValue;
    } else {
        try {
            return JSON.parse(stringValue);
        } catch {
            return defaultValue;
        }
    }
}

/**
 * Best-effort attempt to write a string value to local storage
 *
 * @param key Storage key
 * @param value Storage value
 */
export function trySetLocalStorageValue(key: string, value: string | undefined): boolean {
    try {
        if (value === null || value === undefined) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, value);
        }
        return true;
    } catch {
        return false;
    }
}

/**
 * Best-effort attempt to write an object to local storage (stored using JSON.stringify)
 *
 * @param key Storage key
 * @param value Storage value
 */
export function trySetLocalStorageObject<T>(key: string, value: T): boolean {
    const valueToWrite = value ? JSON.stringify(value) : undefined;
    return trySetLocalStorageValue(key, valueToWrite);
}

/**
 * Use this method to store user preferences
 * Write to local storage. If that fails use a cookie with 2 years in the future expiration. 
 * @param key Storage key
 * @param value Storage value
 */

export function setUserPreferences(key: string, value: string) {
    if (!trySetLocalStorageValue(key, value)) {
        Cookie_Util.createCookie(key, value, 17520);
    }
}

/**
 * Use this method to get user preferences.
 * @param key Storage key
 * @returns value Storage value
 */

export function getUserPreferences(key: string): string {
    const localStorageValue = tryGetLocalStorageValue(key);
    if (localStorageValue) {
        return localStorageValue;
    }
    return Cookie_Util.getCookie(key);
}

export function getUserPreferencesObject<T>(key: string, defaultValue?: T): T | undefined {
   return tryGetLocalStorageObject(key, defaultValue);
}

export function setUserPreferencesObject<T>(key: string, value: T) {
    trySetLocalStorageObject(key, value);
}