import { FeatureManagementActions } from "./../../FeatureManagement/redux/FeatureManagementActions";
import { RunnerRequestsActionTypes, RunnerRequestsActionsType, RunnerRequestsActions } from "./RunnerRequestsActions";
import { SagaIterator } from "redux-saga";
import * as Effects from "redux-saga/effects";
import { ActionsOfType } from "../../../platform/redux/ActionHelper";
import { IRapPageContext } from "../../../platform/Context";
import { IClientelingApiClient, IRunnerRequestDto, RetailStoreDto, RunnerRequestDto } from "../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";
import * as Constants from "../../../common/Constants";
import * as PageSelectors from "../../../platform/components/Page/redux/PageSelectors";
import { IUserData } from "../../../platform/components/Page/Contracts";
import { RunnerRequestDashboardDefaultStore } from "../../../common/Constants";
import moment from "moment";

const takeEvery: any = Effects.takeEvery;
const call: any = Effects.call;
const put: any = Effects.put;
const getContext: any = Effects.getContext;
const select: any = Effects.select;

export function* runnerRequestsSaga(): SagaIterator {
    yield takeEvery(RunnerRequestsActionTypes.InitRunnerRequestsView, initRunnerRequestsView);
    yield takeEvery(RunnerRequestsActionTypes.FetchRetailStores, fetchRetailStores);
    yield takeEvery(RunnerRequestsActionTypes.CreateRunnerRequest, createRunnerRequest);
    yield takeEvery(RunnerRequestsActionTypes.FetchRunnerRequests, fetchRunnerRequests);
    yield takeEvery(RunnerRequestsActionTypes.CancelRunnerRequest, cancelRunnerRequest);
    yield takeEvery(RunnerRequestsActionTypes.ConfirmRunnerRequest, confirmRunnerRequest);
    yield takeEvery(RunnerRequestsActionTypes.CompleteRunnerRequest, completeRunnerRequest);
    yield takeEvery(RunnerRequestsActionTypes.UpdateStoreStatus, updateStoreStatus);
    yield takeEvery(RunnerRequestsActionTypes.FetchStoreByStoreNumber, fetchStoreByStoreNumber);
}

export function* initRunnerRequestsView(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.InitRunnerRequestsView>): SagaIterator {
    const pageContext = yield getContext("pageContext");

    if (pageContext) {
        try {
            yield put(FeatureManagementActions.fetchFeatureFlags());  
            yield put(RunnerRequestsActions.fetchRetailStores());
        } catch (error) {
            console.error(error);
        }
    }
}

export function* fetchRetailStores(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.FetchRetailStores>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const retailStores: RetailStoreDto[] = ((yield call(
                [clientelingClient, clientelingClient.getRetailStores]
            )) as unknown) as RetailStoreDto[];

            // If user data is already loaded, we can set the user's store here
            // Otherwise, we set in RunnerRequestsView component
            const userData = ((yield select(PageSelectors.getUserData)) as unknown) as IUserData;
            if (userData?.user) {
                const userStore = retailStores.find(retailStore => retailStore.storeNumber === userData.user.storeNumber);
                yield put(
                    userStore 
                    ? RunnerRequestsActions.setSelectedRetailStore(userStore)
                    : RunnerRequestsActions.setSelectedRetailStore(retailStores.find(retailStore => retailStore.storeNumber === RunnerRequestDashboardDefaultStore))
                );
            }

            yield put(RunnerRequestsActions.fetchRetailStoresSuccess(retailStores));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            yield put(RunnerRequestsActions.fetchRetailStoresFailure(message));
        }
    }
}

export function* createRunnerRequest(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.CreateRunnerRequest>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const runnerRequestForCreation = action.payload;
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const createdRunnerRequest: RunnerRequestDto = ((yield call(
                [clientelingClient, clientelingClient.createRunnerRequest],
                runnerRequestForCreation
            )) as unknown) as RunnerRequestDto;

            action.resolve(createdRunnerRequest);
            yield put(RunnerRequestsActions.createRunnerRequestSuccess(createdRunnerRequest));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.createRunnerRequestFailure(message));
        }
    }
}

export function* fetchRunnerRequests(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.FetchRunnerRequests>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const storeNumber = action.payload;

    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const runnerRequests: IRunnerRequestDto[] = ((yield call (
                [clientelingClient, clientelingClient.getRunnerRequests],
                storeNumber,
                moment().startOf("day").toDate()
            )) as unknown) as IRunnerRequestDto[];
            
            action.resolve(runnerRequests);
            yield put(RunnerRequestsActions.fetchRunnerRequestsSuccess(runnerRequests));
        } catch (error) {
            console.log(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.fetchRunnerRequestsFailure(message));
        }
    }
}

export function* cancelRunnerRequest(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.CancelRunnerRequest>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const runnerRequestForCancel = action.payload;
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const canceledRunnerRequest: RunnerRequestDto = ((yield call(
                [clientelingClient, clientelingClient.cancelRunnerRequest],
                runnerRequestForCancel
            )) as unknown) as RunnerRequestDto;

            action.resolve(canceledRunnerRequest);
            yield put(RunnerRequestsActions.cancelRunnerRequestSuccess(canceledRunnerRequest));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.cancelRunnerRequestFailure(message));
        }
    }
}

export function* confirmRunnerRequest(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.ConfirmRunnerRequest>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const runnerRequestForConfirm = action.payload;
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const confirmedRunnerRequest: RunnerRequestDto = ((yield call(
                [clientelingClient, clientelingClient.confirmRunnerRequest],
                runnerRequestForConfirm
            )) as unknown) as RunnerRequestDto;

            action.resolve(confirmedRunnerRequest);
            yield put(RunnerRequestsActions.confirmRunnerRequestSuccess(confirmedRunnerRequest));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.confirmRunnerRequestFailure(message));
        }
    }
}

export function* completeRunnerRequest(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.CompleteRunnerRequest>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const runnerRequestForComplete = action.payload;
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const completedRunnerRequest: RunnerRequestDto = ((yield call(
                [clientelingClient, clientelingClient.completeRunnerRequest],
                runnerRequestForComplete
            )) as unknown) as RunnerRequestDto;

            action.resolve(completedRunnerRequest);
            yield put(RunnerRequestsActions.completeRunnerRequestSuccess(completedRunnerRequest));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.completeRunnerRequestFailure(message));
        }
    }
}

export function* updateStoreStatus(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.UpdateStoreStatus>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const retailStoreForUpdate = action.payload;
    
    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const updatedRetailStore: RetailStoreDto = ((yield call(
                [clientelingClient, clientelingClient.updateStoreStatus],
                retailStoreForUpdate
            )) as unknown) as RetailStoreDto;

            action.resolve(updatedRetailStore);
            yield put(RunnerRequestsActions.updateStoreStatusSuccess(updatedRetailStore));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.updateStoreStatusFailure(message));
        }
    }
}

export function* fetchStoreByStoreNumber(action: ActionsOfType<RunnerRequestsActionsType, RunnerRequestsActionTypes.FetchStoreByStoreNumber>): SagaIterator {
    const pageContext: any = yield getContext("pageContext");
    const storeNumber = action.payload;

    if (pageContext) {
        try {
            const context = pageContext as IRapPageContext;
            const clientelingClient: IClientelingApiClient = ((yield call([context, context.getRestClient], "IClientelingApiClient")) as unknown) as IClientelingApiClient;
            const retailStore: RetailStoreDto = ((yield call(
                [clientelingClient, clientelingClient.getRetailStoreByStoreNumber],
                storeNumber
            )) as unknown) as RetailStoreDto;

            action.resolve(retailStore);
            yield put(RunnerRequestsActions.fetchStoreByStoreNumberSuccess(retailStore));
        } catch (error) {
            console.error(error);
            var errorCode = (error as any).status;
            let message: string = localizedStrings.Errors.serverError;
            if (errorCode) message = errorCode == Constants.HttpErrorCodes.UnAuthorized ? localizedStrings.App.unAuthorizedMessage : localizedStrings.Errors.serverError;
            action.reject(message);
            yield put(RunnerRequestsActions.fetchStoreByStoreNumberFailure(message));
        }
    }
}