import React, {FC, useEffect} from 'react';
import { connect } from 'react-redux';

import { IClientelingViewState } from "../../../pages/Contracts";
import { IRapComponentProperties } from '../../Layout';
import { ObservableValue } from "../../core/Observable";
import { Toast } from "../../widgets/components/Toast/Toast";

import * as PageSelectors from "../Page/redux/PageSelectors";
import { Observer } from '../../core/components/Observer/Observer';
import { PageActions } from '../Page/redux/PageActions';


//Props passed by parent component
interface IToastHandlerProvidedProps extends IRapComponentProperties {

}

interface IToastHandlerOwnProps extends IToastHandlerProvidedProps {
    toastMessage?: string;
}

export type IToastHandlerProps = IToastHandlerOwnProps & typeof ActionsToDispatch;

const ToastHandlerInitializer: FC<IToastHandlerProps> = (props) => {
    const _toastRef: React.RefObject<Toast> = React.createRef<Toast>();
    const _toastMessage = new ObservableValue<string>("");

    useEffect(() => {
        if(props.toastMessage && props.toastMessage != "") {
            _toastMessage.value = props.toastMessage;
            setTimeout(() => {
                if (_toastRef.current) {
                    _toastRef.current.fadeOut().promise.then(() => {
                        _toastMessage.value = "";
                        props.updateMessages({ toastMessage: "" });
                    });
                }
            }, 3000);
        }
    }, [props.toastMessage]);

    return (
        <Observer toastMessage={_toastMessage}>
            {(observedProps: { toastMessage: string }) => {
                return observedProps.toastMessage !== "" && <Toast message={observedProps.toastMessage} ref={_toastRef} />;
            }}
        </Observer>
    )
}

function mapStateToProps(state: IClientelingViewState, providedProps: IToastHandlerProvidedProps): Partial<IToastHandlerOwnProps> {
    return {
        ...providedProps,
        toastMessage: PageSelectors.getMessages(state)?.toastMessage
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateMessages: PageActions.updateMessages
};

export const ToastHandler = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ToastHandlerInitializer);