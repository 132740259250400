import React from "react";
import { connect } from "react-redux";

import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../../Layout";
import { IViewOptions } from "../../../Page/Contracts";
import { PageActions } from "../../../Page/redux/PageActions";
import * as PageSelectors from "../../../Page/redux/PageSelectors";

import { localizedStrings } from "../../../../../common/localization/LocalizedStrings";
import { AppointmentsFeature, AppointmentState } from "../../../../../common/Constants";

import { AppointmentCountDto } from "../../../../../contracts/swagger/_generated";

import { IClientelingViewState } from "../../../../../pages/Contracts";

import * as ReservationsSelectors from "../../../../../views/ReservationsView/redux/ReservationsSelectors";
import { ReservationsActions } from "../../../../../views/ReservationsView/redux/ReservationsActions";

import { List } from '@fluentui/react/lib/List';

import "./AppointmentStateFilter.scss";
import { ActionButton } from "@fluentui/react";

interface IStateFilterListItems //Used only for this component to set up the Fluent UI List
{
    id: AppointmentState;
    displayName: string;
    count: number;
}

interface IAppointmentStateProvidedProps extends IRapComponentProperties {
    appointmentCount: AppointmentCountDto[];
}

interface IAppointmentStateFilterOwnProps extends IAppointmentStateProvidedProps
{
    selectedOption: AppointmentState;
    viewOptions: IViewOptions;
}

export type IAppointmentStateFilterInitializerProps = IAppointmentStateFilterOwnProps & typeof ActionsToDispatch;

class AppointmentStateFilter extends RapComponent<IAppointmentStateFilterInitializerProps, {}> {
    constructor(props: IAppointmentStateFilterInitializerProps, context: IRapComponentContext) {
        super(props, context);
    }

    public render() {
        let appointmentCount = this.props.appointmentCount;

        // All Appointment Count is not part of original object
        let allAppointmentCount = 0;
        if (appointmentCount !== undefined && appointmentCount.length !== 0) {
            appointmentCount.forEach(item => {
                allAppointmentCount += item.count;
            })
        }
                
        const items: Array<IStateFilterListItems> = [
            { id: AppointmentState.Scheduled, displayName: localizedStrings.AppointmentStatusFilter.scheduled, count: appointmentCount?.find(x => x.stateCode === 3)?.count ?? 0 },
            { id: AppointmentState.Closed, displayName: localizedStrings.AppointmentStatusFilter.completed, count: appointmentCount?.find(x => x.stateCode === 1)?.count ?? 0 },
            { id: AppointmentState.Canceled, displayName: localizedStrings.AppointmentStatusFilter.canceled, count: appointmentCount?.find(x => x.stateCode === 2)?.count ?? 0 },
            { id: AppointmentState.All, displayName: localizedStrings.AppointmentStatusFilter.all, count: allAppointmentCount }
        ]
        const onRenderCell = (item: IStateFilterListItems): JSX.Element => {
            return (
                <>
                    {this.props.viewOptions.selectedAppointmentState === item.id.toString() ? ( 
                        <ActionButton className="c-state-filters t-list-item-selected" onClick={(event: any) => this._setAppointmentStateFilter(item.id)}>{item.displayName} ({item.count})</ActionButton>
                    ) : (
                        <ActionButton className="c-state-filters" onClick={(event: any) => this._setAppointmentStateFilter(item.id)}>{item.displayName} ({item.count})</ActionButton>
                    )}
                </>
            );
        };

        return (
            <List className="c-state-filter-location t-border-color" items={items} onRenderCell={onRenderCell} />
        );
    }
    private _setAppointmentStateFilter = (appointmentStateId: AppointmentState): void => 
    {
        this.props.logTelemetry(AppointmentsFeature.AppointmentStateFilter, "Changed view to " + AppointmentState[appointmentStateId]);
        this.props.updateViewOptions({ selectedAppointmentState: appointmentStateId.toString() });
    }
}


// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IAppointmentStateProvidedProps): Partial<IAppointmentStateFilterOwnProps> {
    return {
        ...providedProps,
        viewOptions: PageSelectors.getViewOptions(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateViewOptions: PageActions.updateViewOptions,
    logTelemetry: ReservationsActions.logTelemetry,
};

export const AppointmentStateFilterComponent = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(AppointmentStateFilter);