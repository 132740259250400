import React from "react";
import { connect } from "react-redux";

import { IRapComponentContext, IRapComponentProperties, RapComponent } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { Dialog, DialogType, PrimaryButton, TextField, Label, Text } from "@fluentui/react";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { IAppointmentDetail } from "../../../../contracts/swagger/_generated";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";

import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors"
import * as AppointmentDetailsSelectors from "../../../AppointmentDetailsView/redux/AppointmentDetailsSelectors";

import "./ShareAppointment.scss";
import { TextSizes } from "../../../../common/Constants";


//Props passed by parent component
interface IShareAppointmentProvidedProps extends IRapComponentProperties {

}

interface IShareAppointmentOwnProps extends IShareAppointmentProvidedProps {
    isShareAppointmentOpen: boolean;
    selectedAppointment: IAppointmentDetail;
}

interface IShareAppointmentState {
    desktopButton?: IButtonState;
    mobileButton?: IButtonState;
}

interface IButtonState {
    clicked?: boolean;
}

export type IShareAppointmentProps = IShareAppointmentOwnProps & typeof ActionsToDispatch;

class ShareAppointmentInitializer extends RapComponent<IShareAppointmentProps, IShareAppointmentState> {
    constructor(props: IShareAppointmentProps, context: IRapComponentContext) {
        super(props, context);
        this.state = {
            desktopButton: {
                clicked: false
            },
            mobileButton: {
                clicked: false,
            }
        }
    }

    public render() {
        return (
            <Dialog 
                hidden={!this.props.isShareAppointmentOpen}
                onDismiss={this._onDismissShareAppointment}
                minWidth={500}
                dialogContentProps={{
                    type: DialogType.close,
                    title: <DialogHeader title={localizedStrings.AppointmentDetailsView.modals.shareModal.title1}/>,
                    closeButtonAriaLabel: localizedStrings.ShareAppointment.close}}
            >
                {this.props.selectedAppointment?.appointmentDetails?.teamsDialIn && this.props.selectedAppointment?.appointmentDetails?.teamsDialInMobile ? (
                    <>
                        <div className="c-link-container">
                            <Label htmlFor="link1">{localizedStrings.AppointmentDetailsView.modals.shareModal.desktopLabel}</Label>
                            <div className="flex-row">
                                <div className="flex-grow">
                                    <TextField 
                                        id="link1" 
                                        value={this.props.selectedAppointment?.appointmentDetails?.teamsDialIn}
                                        disabled
                                    />
                                </div>
                                <PrimaryButton 
                                    className="c-copy-button" 
                                    onClick={this._onDesktopClicked}
                                    disabled={this.state.desktopButton.clicked}
                                >
                                    {this.state.desktopButton.clicked ? 
                                        localizedStrings.AppointmentDetailsView.modals.shareModal.copied :
                                        localizedStrings.AppointmentDetailsView.modals.shareModal.copy
                                    }
                                </PrimaryButton>
                            </div>
                        </div>
                        <div className="c-link-container bottom">
                            <Label htmlFor="link2">{localizedStrings.AppointmentDetailsView.modals.shareModal.mobileLabel}</Label>
                            <div className="flex-row">
                                <div className="flex-grow">
                                    <TextField 
                                        id="link2" 
                                        value={this.props.selectedAppointment?.appointmentDetails?.teamsDialInMobile}
                                        disabled
                                    />
                                </div>
                                <PrimaryButton 
                                    className="c-copy-button" 
                                    onClick={this._onMobileClicked}
                                    disabled={this.state.mobileButton.clicked}
                                >
                                    {this.state.mobileButton.clicked ? 
                                        localizedStrings.AppointmentDetailsView.modals.shareModal.copied :
                                        localizedStrings.AppointmentDetailsView.modals.shareModal.copy
                                    }
                                </PrimaryButton>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Text variant={TextSizes.medium}>{localizedStrings.AppointmentDetailsView.modals.shareModal.checkLater}</Text>
                    </>
                )}
            </Dialog>
        );
    }

    private _onDismissShareAppointment = () => {
        this.props.updateModals({ displayShareAppointmentModal: false });
    }

    private _onMobileClicked = () => {
        this._fallbackCopyText(this.props.selectedAppointment?.appointmentDetails?.teamsDialInMobile);
        // This is the old write text method
        //navigator.clipboard.writeText(this.props.selectedAppointment?.appointmentDetails?.teamsDialInMobile);
        this.setState({ mobileButton: { clicked: true } });
        this.setTimeout(() => {
            this.setState({ mobileButton: { clicked: false } });
        }, 1500);
    }

    private _onDesktopClicked = () => {
        this._fallbackCopyText(this.props.selectedAppointment?.appointmentDetails?.teamsDialIn);
        // This is the old write text method
        //navigator.clipboard.writeText(this.props.selectedAppointment?.appointmentDetails?.teamsDialIn);
        this.setState({ desktopButton: { clicked: true } });
        this.setTimeout(() => {
            this.setState({ desktopButton: { clicked: false } });
        }, 1500);
    }
    
    // This is a workaround that uses the document.execCommand (which is deprecated but unavoidable to use in this case)
    private _fallbackCopyText = (text: string) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;
  
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
         textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }
}

function mapStateToProps(state: IClientelingViewState, providedProps: IShareAppointmentProvidedProps): Partial<IShareAppointmentOwnProps> {
    return {
        ...providedProps,
        isShareAppointmentOpen: PageSelectors.getModals(state)?.displayShareAppointmentModal,
        selectedAppointment: AppointmentDetailsSelectors.getSelectedAppointmentInfo(state)?.selectedAppointmentDetails
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
};

export const ShareAppointmentModal = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)(ShareAppointmentInitializer);