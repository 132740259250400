import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { SpinnerSize } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";
import { RunnerRequestDashboardDefaultStore, RunnerRequestRefreshTime } from "../../common/Constants";
import { IRapComponentProperties } from "../../platform/Layout";
import { IClientelingViewState } from "../../pages/Contracts";
import { RunnerRequestsActions } from "./redux/RunnerRequestsActions";
import { ComingSoon } from "../../common/components/ComingSoon/ComingSoon";
import { LoadingSpinner } from "../../common/components/LoadingSpinner/LoadingSpinner";
import { RunnerRequestCommandBar } from "../../platform/components/RunnerRequestCommandBar/RunnerRequestCommandBar";
import { RunnerRequestStateFilter } from "../../platform/components/PanelHandler/components/RunnerRequestStateFilter/RunnerRequestStateFilter";
import { RunnerRequestList } from "./components/RunnerRequestList/RunnerRequestList";
import { RunnerRequestModalHandler } from "../../platform/components/RunnerRequestModalHandler/RunnerRequestModalHandler";
import { LoadingContainer } from "../../common/components/LoadingContainer/LoadingContainer";
import { IRetailStoreDto, IRunnerRequestDto } from "../../contracts/swagger/_generated";
import { ErrorBoundaryFallback } from "../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";
import { IUserData } from "../../platform/components/Page/Contracts";
import audio from "../../styling/sounds/Teams_UI_Sound_Completion.mp3";

import * as PageSelectors from "../../platform/components/Page/redux/PageSelectors";
import * as FeatureManagementSelectors from "../FeatureManagement/redux/FeatureManagementSelectors";
import * as RunnerRequestsSelectors from "./redux/RunnerRequestsSelectors";

import "./RunnerRequestsView.scss";
import { ToastHandler } from "../../platform/components/ToastHandler/ToastHandler";
import { ErrorHandler } from "../../platform/components/ErrorHandler/ErrorHandler";


interface IRunnerRequestsViewProvidedProps extends IRapComponentProperties { }

interface IRunnerRequestsViewOwnProps {
    displayRunnerRequestStateFilter: boolean;
    userData: IUserData
    enableRunnerRequests: boolean;
    isFeatureFlagLoading: boolean;
    selectedRetailStore: IRetailStoreDto;
    createdRunnerRequest: IRunnerRequestDto;
    canceledRunnerRequest: IRunnerRequestDto;
    confirmedRunnerRequest: IRunnerRequestDto;
    completedRunnerRequest: IRunnerRequestDto;
    runnerRequests: IRunnerRequestDto[];
    retailStores: IRetailStoreDto[];
    errorMessage: string;
}

export type IRunnerRequestsViewProps = ConnectedProps<typeof connector> & IRunnerRequestsViewProvidedProps;


const RunnerRequestsViewInitializer: FC<IRunnerRequestsViewProps> = (props) => {
    const [areRunnerRequestsLoading, setAreRunnerRequestsLoading] = useState(false);
    const newRequestAudio = new Audio(audio);

    useEffect(() => {
        props.initRunnerRequestsView();
    }, []);

    useEffect(() => {
        // If user data loads after retail stores, we will set the user's store here
        if(props.retailStores && !props.selectedRetailStore) {
            const userStore = props.userData?.user?.storeNumber
                ? props.retailStores.find(retailStore => retailStore.storeNumber === props.userData.user.storeNumber)
                : undefined;
                
            props.setSelectedRetailStore(
                userStore 
                ? userStore
                : props.retailStores.find(retailStore => retailStore.storeNumber === RunnerRequestDashboardDefaultStore)
            );
        }
    }, [props.userData])

    useEffect(() => {
        if(props.selectedRetailStore) {
            setAreRunnerRequestsLoading(true);
            props.fetchRunnerRequests(props.selectedRetailStore.storeNumber).finally(() => {
                setAreRunnerRequestsLoading(false);
            });
        }
    }, [
        props.selectedRetailStore, 
        props.canceledRunnerRequest, 
        props.createdRunnerRequest, 
        props.confirmedRunnerRequest,
        props.completedRunnerRequest
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            if(props.selectedRetailStore) {
                props.fetchRunnerRequests(props.selectedRetailStore.storeNumber).then(runnerRequests => {
                    // Play notification audio if there are any new requests
                    if(props.runnerRequests?.length < runnerRequests.length) {
                        newRequestAudio.play();
                    }
                });
                props.fetchStoreByStoreNumber(props.selectedRetailStore.storeNumber);
            }
        }, RunnerRequestRefreshTime);

        return () => {
            clearInterval(interval);
        };
    }, [props.selectedRetailStore, props.runnerRequests]);

    if(props.isFeatureFlagLoading) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <>
            {props.enableRunnerRequests ? (
                <>
                    <ErrorHandler errorMessage={props.errorMessage} onReset={() => props.setErrorMessage(undefined)}/>
                    <LoadingContainer isLoading={props.selectedRetailStore === undefined} spinnerClassName={"c-spinner"} spinnerSize={SpinnerSize.large}>
                        <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                            <RunnerRequestCommandBar />
                        </ErrorBoundary>
                        <div className="c-rr-container">
                            {props.displayRunnerRequestStateFilter && (
                                <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                    <div className="flex-column">
                                        <RunnerRequestStateFilter />
                                    </div>
                                </ErrorBoundary>
                            )}
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <LoadingContainer isLoading={areRunnerRequestsLoading} spinnerClassName={"c-spinner"} spinnerSize={SpinnerSize.large}>
                                    <RunnerRequestList />
                                </LoadingContainer>
                            </ErrorBoundary>
                            <RunnerRequestModalHandler />
                            <ToastHandler/>
                        </div>
                    </LoadingContainer>
                </>
            ) : (
                <>
                    <ComingSoon description="Runner request dashboard coming soon!"/>
                </>
            )}
        </>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestsViewProvidedProps
): Partial<IRunnerRequestsViewOwnProps> {
    return {
        ...providedProps,
        displayRunnerRequestStateFilter: PageSelectors.getPanels(state)?.displayRunnerRequestStateFilter,
        userData: PageSelectors.getUserData(state),
        enableRunnerRequests: FeatureManagementSelectors.isFeatureFlagEnabled(state, "EnableRunnerRequests", false),
        isFeatureFlagLoading: FeatureManagementSelectors.isFeatureFlagLoading(state),
        selectedRetailStore: RunnerRequestsSelectors.getSelectedRetailStore(state),
        createdRunnerRequest: RunnerRequestsSelectors.getCreatedRunnerRequest(state),
        canceledRunnerRequest: RunnerRequestsSelectors.getCanceledRunnerRequest(state),
        confirmedRunnerRequest: RunnerRequestsSelectors.getConfirmedRunnerRequest(state),
        completedRunnerRequest: RunnerRequestsSelectors.getCompletedRunnerRequest(state),
        runnerRequests: RunnerRequestsSelectors.getRunnerRequests(state),
        retailStores: RunnerRequestsSelectors.getRetailStores(state),
        errorMessage: RunnerRequestsSelectors.getErrorMessage(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    initRunnerRequestsView: RunnerRequestsActions.initRunnerRequestsView,
    fetchRunnerRequests: RunnerRequestsActions.fetchRunnerRequests,
    fetchStoreByStoreNumber: RunnerRequestsActions.fetchStoreByStoreNumber,
    setSelectedRetailStore: RunnerRequestsActions.setSelectedRetailStore,
    setErrorMessage: RunnerRequestsActions.setErrorMessage
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const RunnerRequestsView = connector(RunnerRequestsViewInitializer);
