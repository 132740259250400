import { produce } from "immer";
import moment from "moment";

import { IReservationsViewState } from "../Contracts";
import { ReservationsActionsType, ReservationsActionTypes } from "./ReservationsActions";
import {
    AppointmentsView,
    AppointmentModel,
    IAppointmentsView,
    MRSEmployee,
    AppointmentDto,
} from "../../../contracts/swagger/_generated";
import { localizedStrings } from "../../../common/localization/LocalizedStrings";


export function reservationsReducer(state: IReservationsViewState, action: ReservationsActionsType): IReservationsViewState {
    return produce(state || ({} as IReservationsViewState), draft => {
        switch (action.type) {
            case ReservationsActionTypes.InitReservationsView: {
                draft.data = {
                    resources: {},
                    createAppointmentModel: {
                        appointment: {
                            customerEmail: undefined,
                            customerFirstName: undefined,
                            customerLastName: undefined,
                            appointmentTypeId: undefined,
                            appointmentCategoryId: undefined,
                            appointmentDate: undefined
                        }
                    }
                };

                draft.ux = {
                    messages: {}
                };
                break;
            }

            case ReservationsActionTypes.FetchAppointmentsData: {
                draft.ux.isLoading = action.payload.silentFetch ? false : true;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentsDataSuccess: {
                let { appointmentsData } = action.payload;
                let data: IAppointmentsView = {
                    appointments: _addLoadMoreRow(appointmentsData),
                    paging: appointmentsData.paging
                };

                draft.data.appointments = new AppointmentsView(data);
                draft.data.loadedPages = [1];
                draft.ux.isLoading = false;
                draft.ux.messages.noResultsMessage = localizedStrings.AppointmentList.noAppointmentsFound;
                break;
            }

            case ReservationsActionTypes.FetchMoreAppointmentsDataSuccess: {
                let { appointmentsData } = action.payload;

                //Check if this page is already loaded
                let isLoaded = false;
                let pageMatches = draft.data.loadedPages.filter(x => x === appointmentsData.paging.currentPage);
                isLoaded = pageMatches && pageMatches.length > 0;

                let data: IAppointmentsView = {
                    appointments: isLoaded ? draft.data.appointments.appointments : _mergeAppointments(draft.data.appointments, appointmentsData),
                    paging: appointmentsData.paging
                };

                draft.data.appointments = new AppointmentsView(data);
                draft.data.loadedPages = draft.data.loadedPages.concat([data.paging.currentPage]);
                draft.ux.isLoading = false;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentCountByStateSuccess: {
                let { appointmentCountByState } = action.payload;
                draft.ux.appointmentCounts = appointmentCountByState;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentTypesSuccess: {
                draft.data.resources.appointmentTypes = action.payload;
                draft.ux.isAppointmentTypesLoading = false;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentTypes: {
                draft.ux.isAppointmentTypesLoading = true;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentStatusSuccess: {
                draft.data.resources.appointmentStatuses = action.payload;
                break;
            }

            case ReservationsActionTypes.FetchAppointmentStatusReasonSuccess: {
                draft.data.resources.appointmentStatusReasons = action.payload;
                break;
            }

            case ReservationsActionTypes.UpdateAppointmentSuccess: {
                draft.ux.isLoading = false;
                break;
            }

            case ReservationsActionTypes.SearchForAppointments: {
                draft.ux.isLoading = true;
                break;
            }

            case ReservationsActionTypes.SearchForAppointmentsSuccess: {
                draft.ux.isLoading = false;
                draft.ux.messages.noResultsMessage = localizedStrings.Search.noAppointmentsFound;
                draft.data.appointments = { ...state.data.appointments, appointments: action.payload as AppointmentModel[] };
                break;
            }

            case ReservationsActionTypes.UpdateAppointmentsView: {
                draft.data.appointments = {...state.data.appointments, ...action.payload}
                break;
            }
            case ReservationsActionTypes.FetchEmployeeInfoFailure:
            case ReservationsActionTypes.UpdateAppointmentFailure:
            case ReservationsActionTypes.SearchForAppointmentsFailure:
            case ReservationsActionTypes.FetchAppointmentsDataFailure:
            case ReservationsActionTypes.FetchAppointmentCountByStateFailure:
            case ReservationsActionTypes.FetchAppointmentTypesFailure: {
                draft.ux.messages.errorMessage = action.payload;
                draft.ux.isLoading = false;
                draft.ux.isAppointmentTypesLoading = false;
                break;
            }

            case ReservationsActionTypes.UpdateAppointmentForCreation: {
                draft.data.createAppointmentModel.appointment = {...state.data.createAppointmentModel.appointment, ...action.payload};
                action.resolve();
                break;
            }

            case ReservationsActionTypes.SetMessages: {
                draft.ux.messages = {...state.ux.messages, ...action.payload};
                break;
            }

            case ReservationsActionTypes.FetchEmployeeInfoSuccess: {
                const { info, appointmentId } = action.payload;
                const index = state.data.appointments?.appointments?.findIndex((val) => {
                    return val.appointmentDetails?.appointmentId == appointmentId;
                });

                if(draft.data.appointments.appointments[index] != undefined) {
                    draft.data.appointments.appointments[index].assignedTo = new MRSEmployee(info);
                }
                break;
            }

            case ReservationsActionTypes.ReceivedAppointmentUpsertMessage: {
                const updatedAppointmentModel: AppointmentModel = action.payload;

                //Checks
                if (!updatedAppointmentModel) break;

                let appointmentList = [...draft.data.appointments.appointments];
                let appointmentIndex = appointmentList.findIndex(x => x.appointmentDetails.appointmentId === updatedAppointmentModel.appointmentDetails.appointmentId);
                
                //No Appointments in List OR New appointment to be added to list
                if (!draft.data.appointments.appointments || draft.data.appointments.appointments.length === 0 || appointmentIndex === -1)
                {
                    appointmentList.push(new AppointmentModel({
                        appointmentDetails: new AppointmentDto(),
                        customerProfileImage: '',
                        selectedAppointmentId: null,
                        operationType: null,
                        assignedTo: updatedAppointmentModel.assignedTo
                    }));

                    //New empty appointment created at the end of the array
                    let appointmentToReplace: AppointmentModel = appointmentList[appointmentList.length - 1];
                    appointmentToReplace = _mapAppointmentModelToAppointmentDto(updatedAppointmentModel, appointmentToReplace);

                    appointmentToReplace.assignedTo = updatedAppointmentModel.assignedTo;

                    appointmentList[appointmentList.length - 1] = appointmentToReplace;

                    appointmentList = sortAppointments(appointmentList);

                    return { 
                        ...state,
                        data: { ...state.data, appointments: { ...state.data.appointments, appointments: appointmentList } }
                    }
                }
                //Appointments Exists so update card/list (if time is updated then must sort it)
                //OR appointment Date has changed (remove appointment from list)
                else if (appointmentIndex !== -1)
                {
                    let appointmentToReplace: AppointmentModel = appointmentList[appointmentIndex];
                    let tempDateForComparison  = new Date(updatedAppointmentModel.appointmentDetails.appointmentDate);

                    if (tempDateForComparison.getDate() == appointmentList[appointmentIndex].appointmentDetails.appointmentDate.getDate())
                    {
                        //Date has not changed, replace appointment
                        appointmentToReplace = _mapAppointmentModelToAppointmentDto(updatedAppointmentModel, appointmentToReplace);
                        appointmentList[appointmentIndex] = appointmentToReplace;
                    }
                    else 
                    {
                        appointmentList.splice(appointmentIndex, 1);
                    }
                    
                    appointmentList = sortAppointments(appointmentList);

                    return { 
                        ...state,
                        data: { ...state.data, appointments: { ...state.data.appointments, appointments: appointmentList } }
                    }
                }

                break;
            }

            default:
                return state;
        }
    });
}

function sortAppointments(appointmentList: AppointmentModel[]): AppointmentModel[] {
    if (appointmentList.length > 1) {
        appointmentList = appointmentList.sort((a, b) => 
        moment(a.appointmentDetails.appointmentDate).valueOf() - moment(b.appointmentDetails.appointmentDate).valueOf());
    }

    return appointmentList;
}

//There are some ambiguous differences between what is received from the signalr backend and what is expected
// Example: Date fields and strings that contain dates 
function _mapAppointmentModelToAppointmentDto(updatedAppointmentModel: AppointmentModel, newAppointment: AppointmentModel): AppointmentModel {
    newAppointment.appointmentDetails.appointmentId = updatedAppointmentModel.appointmentDetails.appointmentId;
    newAppointment.appointmentDetails.storeNumber = updatedAppointmentModel.appointmentDetails.storeNumber;
    newAppointment.appointmentDetails.status = updatedAppointmentModel.appointmentDetails.status;
    newAppointment.appointmentDetails.statusReason = updatedAppointmentModel.appointmentDetails.statusReason;
    newAppointment.appointmentDetails.subject = updatedAppointmentModel.appointmentDetails.subject;
    newAppointment.appointmentDetails.scheduledEndDate = new Date(updatedAppointmentModel.appointmentDetails.scheduledEndDate);
    newAppointment.appointmentDetails.appointmentDate = new Date(updatedAppointmentModel.appointmentDetails.appointmentDate);
    newAppointment.appointmentDetails.formattedAppointmentDate = updatedAppointmentModel.appointmentDetails.formattedAppointmentDate;
    newAppointment.appointmentDetails.confirmationNumber = updatedAppointmentModel.appointmentDetails.confirmationNumber;
    newAppointment.appointmentDetails.appointmentCategory = updatedAppointmentModel.appointmentDetails.appointmentCategory;
    newAppointment.appointmentDetails.appointmentCategoryId = updatedAppointmentModel.appointmentDetails.appointmentCategoryId;
    newAppointment.appointmentDetails.appointmentType = updatedAppointmentModel.appointmentDetails.appointmentType;
    newAppointment.appointmentDetails.appointmentTypeId = updatedAppointmentModel.appointmentDetails.appointmentTypeId;
    newAppointment.appointmentDetails.customerContactId = updatedAppointmentModel.appointmentDetails.customerContactId;
    newAppointment.appointmentDetails.customerFirstName = updatedAppointmentModel.appointmentDetails.customerFirstName;
    newAppointment.appointmentDetails.customerLastName = updatedAppointmentModel.appointmentDetails.customerLastName;
    newAppointment.appointmentDetails.customerEmail = updatedAppointmentModel.appointmentDetails.customerEmail;

    //newAppointment.assignedTo = updatedAppointmentModel.assignedTo;

    return newAppointment;
}

function _addLoadMoreRow(appointmentsData: AppointmentsView): AppointmentModel[] {
    let mergedAppointments: AppointmentModel[] = appointmentsData.appointments;
    if (appointmentsData.paging && appointmentsData.paging.totalPages > appointmentsData.paging.currentPage) {
        mergedAppointments.push(null); //Indication to render more rows
    }
    return mergedAppointments;
}

function _mergeAppointments(currentAppointments: IAppointmentsView, appointmentsData: AppointmentsView): AppointmentModel[] {
    let mergedAppointments: AppointmentModel[] = [];
    if (currentAppointments && currentAppointments.appointments) {
        mergedAppointments = currentAppointments.appointments
            .slice(0, currentAppointments.appointments.length - 1) //Remove loading row from last page
            .concat(appointmentsData.appointments);
    } else {
        mergedAppointments = mergedAppointments.concat(appointmentsData.appointments);
    }

    if (appointmentsData.paging && appointmentsData.paging.totalPages > appointmentsData.paging.currentPage) {
        mergedAppointments.push(null); //Indication to render more rows
    }

    return mergedAppointments;
}
