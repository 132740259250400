import moment from "moment-timezone";
import { IAppointmentModel, IOptionSetAttribute } from "../contracts/swagger/_generated";
import * as Constants from "./Constants";

export class AppointmentUtils {
    public getAppointmentTime = (item: IAppointmentModel): string => {
        if (item && item.appointmentDetails && item.appointmentDetails.appointmentDate && item.appointmentDetails.scheduledEndDate) {
            let startDate = item.appointmentDetails.appointmentDate;
            let endDate = item.appointmentDetails.scheduledEndDate;
            return moment(startDate).format("hh:mm A") + "-" + moment(endDate).tz(moment.tz.guess()).format("hh:mm A z");
        } 
        return "";
    };

    public getCustomerName = (item: IAppointmentModel): string => {
        if (item && item.appointmentDetails) {
            return item.appointmentDetails.customerFirstName + " " + item.appointmentDetails.customerLastName;
        }
        return "";
    };

    public getAppointmentCategory = (item: IAppointmentModel): string => {
        if (item && item.appointmentDetails) {
            return item.appointmentDetails.appointmentCategory;
        }
        return "";
    };

    public getAppointmentType = (item: IAppointmentModel): string => {
        if (item && item.appointmentDetails) {
            return item.appointmentDetails.appointmentType;
        }
        return "";
    };

    public getAppointmentConfirmationNumber = (item: IAppointmentModel): string => {
        if (item && item.appointmentDetails) {
            return item.appointmentDetails.confirmationNumber;
        }
        return "";
    };

    public getAppointmentTypeIcon = (appointmentType: string): string => {
        const serviceCategory = this.getServiceNameCategory(appointmentType);
        if (serviceCategory) {
            switch (serviceCategory) {
                case Constants.AppointmentType_HardwareSupport:
                    return Constants.AppointmentTypeIcon_HardwareSupport;
                case Constants.AppointmentType_PersonalTrainingForBusiness:
                    return Constants.AppointmentTypeIcon_PersonalTrainingForBusiness;
                case Constants.AppointmentType_PersonalTraining:
                    return Constants.AppointmentTypeIcon_PersonalTraining;
                case Constants.AppointmentType_BusinessConsultation:
                    return Constants.AppointmentTypeIcon_BusinessConsultation;
                case Constants.AppointmentType_PersonalShopping:
                    return Constants.AppointmentTypeIcon_PersonalShopping;
                case Constants.AppointmentType_PersonalSetup:
                    return Constants.AppointmentTypeIcon_PersonalSetup;
                case Constants.AppointmentType_EducationConsulation:
                    return Constants.AppointmentTypeIcon_EducationConsulation;
                default:
                    return "Error";
            }
        }
        return "Error";
    };

    public getAppointmentIconName = (appointmentType: string): string => {
        const serviceCategory = this.getServiceNameCategory(appointmentType);
        if (serviceCategory) {
            switch (serviceCategory) {
                case Constants.AppointmentType_HardwareSupport:
                    return "HandsFree";
                case Constants.AppointmentType_PersonalTrainingForBusiness:
                case Constants.AppointmentType_PersonalTraining:
                    return "Headset";
                case Constants.AppointmentType_BusinessConsultation:
                    return "Suitcase";
                case Constants.AppointmentType_PersonalShopping:
                    return "Shop";
                case Constants.AppointmentType_PersonalSetup:
                    return "LaptopSecure";
                default:
                    return "Error";
            }
        }
        return "Error";
    };


    public getServiceNameCategory = (serviceName: string | undefined) => {
        var serviceNameLower = serviceName?.toLowerCase();

        if (serviceNameLower?.includes("hardware support")) {
            return Constants.AppointmentType_HardwareSupport;
        }
        else if (serviceNameLower?.includes("business consultation")) {
            return Constants.AppointmentType_BusinessConsultation;
        }
        else if (serviceNameLower?.includes("education consultation")) {
            return Constants.AppointmentType_EducationConsulation;
        }
        else if (serviceNameLower?.includes("setup") || serviceNameLower?.includes("set-up")) {
            return Constants.AppointmentType_PersonalSetup;
        }
        else if (serviceNameLower?.includes("personal shopping")) {
            return Constants.AppointmentType_PersonalShopping;
        }
        else if (serviceNameLower?.includes("training") && serviceNameLower.includes("business")) {
            return Constants.AppointmentType_PersonalTrainingForBusiness;
        }
        else if (serviceNameLower?.includes("training")) {
            return Constants.AppointmentType_PersonalTraining;
        }
        else {
            return "Error";
        }
    }


    public getAppointmentStatusId = (statusReason: string, appointmentStatuses: IOptionSetAttribute[]): number => {
        if (appointmentStatuses) {
            if (
                statusReason === Constants.AppointmentStatusReasons.Reserved ||
                statusReason === Constants.AppointmentStatusReasons.Pending ||
                statusReason === Constants.AppointmentStatusReasons.InProgress ||
                statusReason === Constants.AppointmentStatusReasons.CheckedIn
            )
                return appointmentStatuses ? appointmentStatuses.filter(st => st.value === Constants.AppointmentStatus.Scheduled)[0].value : 0;
            else if (
                statusReason === Constants.AppointmentStatusReasons.Cancelled ||
                statusReason === Constants.AppointmentStatusReasons.NoShow ||
                statusReason === Constants.AppointmentStatusReasons.Junk ||
                statusReason === Constants.AppointmentStatusReasons.OutOfArea ||
                statusReason === Constants.AppointmentStatusReasons.OutOfScopeGeneral ||
                statusReason === Constants.AppointmentStatusReasons.OutOfScopeCommerialSupport ||
                statusReason === Constants.AppointmentStatusReasons.OutOfScopePersonalSupport ||
                statusReason === Constants.AppointmentStatusReasons.TechSupport ||
                statusReason === Constants.AppointmentStatusReasons.Staffing
            )
                return appointmentStatuses ? appointmentStatuses.filter(st => st.value === Constants.AppointmentStatus.Cancelled)[0].value : 0;
            else if (statusReason === Constants.AppointmentStatusReasons.Completed)
                return appointmentStatuses ? appointmentStatuses.filter(st => st.value === Constants.AppointmentStatus.Closed)[0].value : 0;
        }
        return 0;
    };

    public getAppointmentStatusReasonId(statusReason: string, appointmentStatuses: IOptionSetAttribute[]): number {
        if (appointmentStatuses) {
            return appointmentStatuses.filter(reasons => reasons.label === statusReason)[0].value;
        }
        return 0;
    }
}

export const timeoutFunction = (condition: () => boolean, execute: () => void, initialWait: number, retryWait: number, retries: number, errorMessage: string) => {
    setTimeout(() => {
        if(condition()) {
            execute();
        } else if(retries > 0) {
            timeoutFunction(condition, execute, retryWait, retryWait, retries-1, errorMessage);
        }
        else {
            console.error(errorMessage);
        }
    }, initialWait);
}

export const getCSATColor = (score: string) => {
    let s = parseFloat(score);
    if(s >= 4.5) {
        return "green";
    } else if(s < 4.5 && s >= 4) {
        return "orange";
    } else {
        return "red";
    }
}

export const isNullOrWhitespace = (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
}
