import React, { FC, FormEvent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import { RunnerRequestsActions } from "../../redux/RunnerRequestsActions";
import { DialogHeader } from "../../../../common/components/DialogHeader/DialogHeader";
import { IDropdownOption, Dropdown, IconButton, Modal, TextField, DatePicker, PrimaryButton, SpinButton, Position, SpinnerSize, DefaultButton } from "@fluentui/react";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import * as RunnerRequestsSelectors from "../../../RunnerRequestsView/redux/RunnerRequestsSelectors";
import { MaxItemsInRequest, RunnerRequestOrderType, TimeFormat } from "../../../../common/Constants";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import moment from "moment";
import { IUserData } from "../../../../platform/components/Page/Contracts";
import { IRetailStoreDto, IRunnerRequestForCreationDto, SkuItemForRequestDto } from "../../../../contracts/swagger/_generated";
import { LoadingContainer } from "../../../../common/components/LoadingContainer/LoadingContainer";

import "./CreateRunnerRequestModal.scss";

interface IRunnerRequestItem {
    description: string;
    quantity: string;
}

interface ICreateRunnerRequestModalProvidedProps extends IRapComponentProperties {}
interface ICreateRunnerRequestModalOwnProps {
    displayCreateRunnerRequestModal: boolean;
    userData: IUserData;
    selectedRetailStore: IRetailStoreDto;
}

export type ICreateRunnerRequestModalProps = ConnectedProps<typeof connector> & ICreateRunnerRequestModalProvidedProps;

const CreateRunnerRequestModalInitializer: FC<ICreateRunnerRequestModalProps> = (props) => {
    const [ currentTime, setCurrentTime] = useState(moment().format(TimeFormat));
    const [ orderType, setOrderType ] = useState<number>();
    const [ requester, setRequester ] = useState("");
    const [ location, setLocation ] = useState<number>();
    const [ locationNote, setLocationNote ] = useState("");
    const [ runnerRequestItems, setRunnerRequestItems ] = useState<IRunnerRequestItem[]>([{description: "", quantity: "1"}]);
    const [ isCreateInProgress, setIsCreateInProgress ] = useState(false);

    useEffect(() => {
        if(!props.displayCreateRunnerRequestModal) {
            // When the modal is no longer being displayed, reset state values to defaults
            setOrderType(0);
            setLocation(0);
            setLocationNote("");
            setRunnerRequestItems([{description: "", quantity: "1"}]);
        }
    }, [props.displayCreateRunnerRequestModal]);

    useEffect(() => {
        const interval = setInterval(() => setCurrentTime(moment().format(TimeFormat)), 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setRequester(props.userData?.user ? `${props.userData.user.firstName} ${props.userData.user.lastName}` : "");
    }, [props.userData.user]);

    const {CreateRunnerRequestModal: locStrings} = localizedStrings.RunnerRequestsView;

    const requestTypeDropdownOptions: IDropdownOption[] = [
        { key: RunnerRequestOrderType.InStoreOrder, text: locStrings.inStoreOrder }
     ]

    const locationTypeDropdownOptions: IDropdownOption[] = [
        { key: 1, text: locStrings.firstFloor },
        { key: 2, text: locStrings.secondFloor },
        { key: 3, text: locStrings.thirdFloor },
        { key: 4, text: locStrings.fourthFloor }
    ]

    const _onChangeOrderType = (event: FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        setOrderType(item.key as number);
    }

    const _onChangeLocation = (event: FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        setLocation(item.key as number);
    }

    const _onChangeLocationNote = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setLocationNote(newValue);
    }

    const _onChangeItemDescription = (index: number, newValue?: string) => {
        let itemData = [...runnerRequestItems];
        itemData[index].description = newValue;
        setRunnerRequestItems(itemData);
    }

    const _onChangeItemQuantity = (index: number, newValue?: string) => {
        let itemData = [...runnerRequestItems];
        itemData[index].quantity = newValue;
        setRunnerRequestItems(itemData);
    }

    const _onClickDeleteItem = (index: number) => {
        let itemData = [...runnerRequestItems];
        itemData.splice(index, 1);
        setRunnerRequestItems(itemData);
    }

    const _isSubmitButtonDisabled = () => {
        return !location || runnerRequestItems.find(item => item.description.length === 0) !== undefined;
    }

    const _onClickSubmit = () => {
        setIsCreateInProgress(true);
        props.createRunnerRequest(_mapInputsToRunnerRequest()).then(() => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.createSuccess});  
        }).catch((resp: {errorCode: number, error: string}) => {
            props.updateMessages({ toastMessage: localizedStrings.RunnerRequestsView.CreateRunnerRequestModal.createFailure});  
        }).finally(() => {
            props.updateModals({ displayCreateRunnerRequestModal: false });
            setIsCreateInProgress(false);
        }); 
    }

    const _mapInputsToRunnerRequest = () => {
        const runnerRequestToCreate: IRunnerRequestForCreationDto = {
            storeNumber: props.selectedRetailStore.storeNumber,
            fohAlias: requester,
            floorLevel: location,
            location: locationNote,
            orderType: orderType,
            skuItems: runnerRequestItems.map(runnerRequestItem => {
                return new SkuItemForRequestDto({
                    skuDescription: runnerRequestItem.description,
                    quanitity: parseInt(runnerRequestItem.quantity)
                });
            })
        }

        return runnerRequestToCreate;
    }

    const _onCloseCreateRunnerRequestModal = () => {
        props.updateModals({ displayCreateRunnerRequestModal: false });
    }

    return (
            <Modal
                isOpen={props.displayCreateRunnerRequestModal}
                containerClassName="c-rr-create-modal"
                scrollableContentClassName={isCreateInProgress ? "c-scrollable-container" : ""}
            >
                <LoadingContainer isLoading={isCreateInProgress} spinnerClassName={"c-spinner"} spinnerSize={SpinnerSize.large}>
                    <div className="c-modal-content">
                        <div className="flex-row c-modal-header">
                            <DialogHeader title={locStrings.runnerRequest}/>
                            <IconButton
                                className="c-icon-cancel"
                                iconProps={{ iconName: 'Cancel' }}
                                ariaLabel={"Close"}
                                onClick={_onCloseCreateRunnerRequestModal}
                            />
                        </div>
                        <div className="flex-row c-modal-row">
                            <Dropdown
                                label={locStrings.type}
                                className="c-modal-field"
                                options={ requestTypeDropdownOptions }
                                onChange={_onChangeOrderType}
                                required={true}
                                defaultSelectedKey={RunnerRequestOrderType.InStoreOrder}
                            />

                        </div>
                        <div className="flex-row c-modal-row">
                            <DatePicker
                                label={locStrings.requestDate}
                                disabled
                                className="c-modal-field"
                                value={ moment().toDate() }
                            />
                            <TextField
                                label={locStrings.requestTime}
                                disabled
                                className="c-modal-field"
                                value={currentTime}
                            />
                        </div>
                        <div className="flex-row c-modal-row">
                            <TextField
                                label={locStrings.requester}
                                disabled
                                className="c-modal-field"
                                value={requester}
                            />
                        </div>
                        <div className="flex-row c-modal-row">
                            <Dropdown
                                label={locStrings.location}
                                className="c-modal-field"
                                options={ locationTypeDropdownOptions }
                                onChange={_onChangeLocation}
                                required={true}
                            />
                            <TextField
                                label={locStrings.locationNote}
                                className="c-modal-field"
                                value={locationNote}
                                onChange={_onChangeLocationNote}
                            />
                        </div>
                        <div className="c-item-list">
                            {runnerRequestItems.map((runnerRequestItem, index) => {
                                return (
                                    <div key={index} className="flex-row c-modal-row">
                                        <TextField
                                            label={locStrings.itemDescription}
                                            className="c-modal-field c-modal-large-field"
                                            value={runnerRequestItem.description}
                                            onChange={(event, newValue) => _onChangeItemDescription(index, newValue)}
                                            required
                                        />
                                        <SpinButton
                                            label={locStrings.qty}
                                            labelPosition={Position.top}
                                            className="c-modal-field"
                                            value={runnerRequestItem.quantity}
                                            onChange={(event, newValue) => _onChangeItemQuantity(index, newValue)}
                                            min={1}
                                            max={100}
                                            incrementButtonAriaLabel={locStrings.incrementQuantity}
                                            decrementButtonAriaLabel={locStrings.decrementQuantity}
                                        />
                                        {index > 0 && (
                                            <IconButton
                                                className="c-delete-button"
                                                iconProps={{ iconName: "Delete" }}
                                                onClick={() => _onClickDeleteItem(index)}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        {runnerRequestItems.length < MaxItemsInRequest && (
                            <div className="flex-row c-modal-row">
                                <DefaultButton
                                    text={locStrings.addItem}
                                    iconProps={{ iconName: "Add" }}
                                    className=""
                                    onClick={() => setRunnerRequestItems(prev => [...prev, {description: "", quantity: "1"}])}
                                />
                            </div>
                        )}
                        <PrimaryButton
                            text={locStrings.submit}
                            disabled={_isSubmitButtonDisabled()}
                            onClick={_onClickSubmit}
                            allowDisabledFocus
                            className="c-modal-field c-submit-button"
                        />
                    </div>
                </LoadingContainer>                
            </Modal>
    );
}

// Update component props whenever the store's state changes
function mapStateToProps(state: IClientelingViewState, providedProps: ICreateRunnerRequestModalProvidedProps
): Partial<ICreateRunnerRequestModalOwnProps> {
    return {
        ...providedProps,
        displayCreateRunnerRequestModal: PageSelectors.getModals(state)?.displayCreateRunnerRequestModal,
        userData: PageSelectors.getUserData(state),
        selectedRetailStore: RunnerRequestsSelectors.getSelectedRetailStore(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    createRunnerRequest: RunnerRequestsActions.createRunnerRequest,
    updateMessages: PageActions.updateMessages
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export const CreateRunnerRequestModal = connector(CreateRunnerRequestModalInitializer);
