import * as React from "react";
import { getSafeId, noop } from "./../../../core/util/Util";
import { IFocusGroupContext, IFocusGroupProps, IFocusGroup } from "./FocusGroup.Props";

export const FocusGroupContext = React.createContext<IFocusGroupContext>({
    onFocus: noop
});

export interface IFocusGroupState {
    focusedElementId?: string;
}

export class FocusGroup extends React.Component<IFocusGroupProps, IFocusGroupState> implements IFocusGroup {
    public constructor(props: Readonly<IFocusGroupProps>) {
        super(props);
        this.state = { focusedElementId: props.defaultElementId };
    }

    public render() {
        return (
            <FocusGroupContext.Provider
                value={{
                    focusedElementId: this.state.focusedElementId,
                    onFocus: this.onFocus
                }}
            >
                {this.props.children}
            </FocusGroupContext.Provider>
        );
    }

    public focus(elementId?: string) {
        let id = getSafeId(elementId || this.state.focusedElementId);
        if (id) {
            const element = document.getElementById(id);
            if (element) {
                element.focus();
            }
        }
    }

    private onFocus = (id: string) => {
        this.setState({ focusedElementId: id });
    };
}
