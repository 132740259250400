import {
    AppointmentCountDto,
    IAppointmentForCreationDto,
    IAppointmentType,
    IOptionSetAttribute,
    IAppointmentsView,
} from "./../../contracts/swagger/_generated";

// Appointments view contracts here
export interface IReservationsAwareState {
    reservationsViewState: IReservationsViewState;
}

export interface IReservationsViewState {
    ux?: IReservationsUxViewState;
    data?: IReservationsDataViewState;
}

export interface IReservationsUxViewState {
    appointmentCounts?: AppointmentCountDto[];
    isLoading?: boolean;
    isAppointmentTypesLoading?: boolean;
    messages?: IMessages;
}

export interface IMessages {
    errorMessage?: string;
    noResultsMessage?: string;
}

export interface IReservationsDataViewState {
    appointments?: IAppointmentsView;
    resources?: IAppointmentResources;
    loadedPages?: number[];
    createAppointmentModel?: ICreateAppointmentModel;
}

//constants that will be used in the appointments view
export interface IAppointmentResources {
    appointmentTypes?: IAppointmentType[];
    appointmentStatuses?: IOptionSetAttribute[];
    appointmentStatusReasons?: IOptionSetAttribute[];
}

export interface ICreateAppointmentModel {
    appointment?: IAppointmentForCreationDto;
}

export class Area {
    public static Appointments = "Appointments";
}

export class TelemetryProperties {
    public static Action = "Action";
    public static ErrorMessage = "ErrorMessage";
}
