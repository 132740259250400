import React, { FC, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router-dom";
import { SpinnerSize, Text } from "@fluentui/react";
import { ErrorBoundary } from "react-error-boundary";

import { IRapComponentProperties } from "../../../../../../platform/Layout";
import { IClientelingViewState } from "../../../../../../pages/Contracts";
import { Card } from "../../../../../../common/components/Card/Card";
import { ContactsActions } from "../../../../redux/ContactsActions";
import { localizedStrings } from "../../../../../../common/localization/LocalizedStrings";
import { IAppointmentDto } from "../../../../../../contracts/swagger/_generated";
import { NoResults } from "../../../../../../common/components/NoResults/NoResults";
import { LoadingContainer } from "../../../../../../common/components/LoadingContainer/LoadingContainer";
import { ErrorBoundaryFallback } from "../../../../../../platform/core/components/ErrorBoundaryFallback/ErrorBoundaryFallback";

import ContactAppointment from "./components/ContactAppointment/ContactAppointment";

import * as ContactsSelectors from "../../../../redux/ContactsSelectors";

import "./ContactAppointments.scss";

//Props passed by parent component
interface IContactAppointmentsProvidedProps extends IRapComponentProperties {
    className?: string;
}

interface IContactAppointmentsOwnProps {
    contactAppointments: IAppointmentDto[];
}

export type IContactAppointmentsProps = ConnectedProps<typeof connector> & IContactAppointmentsProvidedProps;

const ContactAppointmentsInitializer:FC<IContactAppointmentsProps> = (props) => {
    let { contactId } = useParams<any>();
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if(contactId && !props.contactAppointments) {
            setIsLoading(true);
            props.fetchContactAppointments(contactId).finally(() => {
                setIsLoading(false);
            }); 
        }
    }, [contactId]);

    return (
        <Card className={`${props.className} c-contactAppointments`}>
            <LoadingContainer isLoading={isLoading} spinnerSize={SpinnerSize.large}>
                <div className="c-appointments-container">
                    <Text className="c-header">{`${localizedStrings.ContactsView.contactAppointments.appointments} (${props.contactAppointments?.length})`}</Text>
                    {props.contactAppointments?.length === 0 && (
                        <div className="flex-column flex-grow">
                            <NoResults description={localizedStrings.ContactsView.contactAppointments.notFound} />
                        </div>
                    )}
                    <div className="c-appointments">
                        {props.contactAppointments?.map((appt: IAppointmentDto) =>
                            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
                                <ContactAppointment appointment={appt} className="c-appointment" key={appt.appointmentId}/>
                            </ErrorBoundary>
                        )}
                    </div>
                </div>
            </LoadingContainer>
        </Card>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IContactAppointmentsProvidedProps): Partial<IContactAppointmentsOwnProps> {
    return {
        ...providedProps,
        contactAppointments: ContactsSelectors.getSelectedContactInfo(state)?.contactAppointments
 };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    fetchContactAppointments: ContactsActions.fetchContactAppointments
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
);

export default connector(ContactAppointmentsInitializer);