import React from "react";
import { RapComponent, IRapComponentContext, IRapComponentProperties } from "../../../platform/Layout";
import { Persona, PersonaSize, PersonaInitialsColor } from "@fluentui/react/lib/Persona";

export interface IUserPersonaProps extends IRapComponentProperties {
    imageUrl: string;
    imageInitials: string;
    fullName: string;
    renderDetails: boolean;
    personaSize?: PersonaSize;
    secondaryText?: string;
    tertiaryText?: string;
}

export class UserPersona extends RapComponent<IUserPersonaProps> {
    constructor(props: IUserPersonaProps, context: IRapComponentContext) {
        super(props, context);
    }

    public render() {
        return (
            <Persona
                imageUrl={this.props.imageUrl}
                imageInitials={this.props.imageInitials}
                text={this.props.fullName}
                size={this.props.personaSize ? this.props.personaSize : PersonaSize.size32}
                hidePersonaDetails={!this.props.renderDetails}
                imageAlt={this.props.fullName}
                secondaryText={this.props.secondaryText}
                tertiaryText={this.props.tertiaryText}
            />
        );
    }
}
