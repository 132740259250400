import { CheckboxVisibility, DetailsList, DetailsRow, IColumn, Icon, IDetailsRowProps, SelectionMode } from "@fluentui/react";
import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IClientelingViewState } from "../../../../pages/Contracts";
import { IRapComponentProperties } from "../../../../platform/Layout";
import { localizedStrings } from "../../../../common/localization/LocalizedStrings";
import { 
    DetailListColumnSizes, 
    DetailListColumnSizes_Max, 
    RunnerRequestStatusLabels, 
    RunnerRequestFloorLabels, 
    RunnerRequestState, 
    RunnerRequestStatus,
    ActiveRequestTimer,
    RunnerRequestView
} from "../../../../common/Constants";
import { findSubstringInRunnerRequest, getRunnerRequestState } from "../../../../common/RunnerRequestUtils";
import { PageActions } from "../../../../platform/components/Page/redux/PageActions";
import * as PageSelectors from "../../../../platform/components/Page/redux/PageSelectors";
import { RunnerRequestsActions } from "../../redux/RunnerRequestsActions";
import * as RunnerRequestsSelectors from "../../redux/RunnerRequestsSelectors";
import { IRunnerRequestDto } from "../../../../contracts/swagger/_generated";
import moment from "moment";

import "./RunnerRequestList.scss";

interface IRunnerRequestListItem {
    data: IRunnerRequestDto;
    elapsedTime: number;
}

interface IRunnerRequestListInitializerProvidedProps extends IRapComponentProperties {
}

interface IRunnerRequestListInitializerOwnProps {
    runnerRequests: IRunnerRequestDto[];
    selectedFilterState: RunnerRequestState;
    displayRunnerRequestNotificationModal: boolean;
    displayViewRunnerRequestModal: boolean;
    selectedView: RunnerRequestView;
    searchTerm: string;
}

export type IRunnerRequestListInitializerProps = ConnectedProps<typeof connector> & IRunnerRequestListInitializerProvidedProps;

const RunnerRequestListInitializer: FC<IRunnerRequestListInitializerProps> = props => {
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [sortedColumn, setSortedColumn] = useState<IColumn>();
    const [runnerRequestListItems, setRunnerRequestListItems] = useState<IRunnerRequestListItem[]>([]);
    const [filteredListItems, setFilteredListItems] = useState<IRunnerRequestListItem[]>([]);
    const [notificationTimer, setNotificationTimer] = useState(0);

    useEffect(() => {
        let listColumns: IColumn[] = [];
        const { RunnerRequestList: locStrings } = localizedStrings.RunnerRequestsView;
        listColumns.push({
            key: "column1",
            name: locStrings.status,
            minWidth: DetailListColumnSizes.xsmall,
            maxWidth: DetailListColumnSizes_Max.small,
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return (
                    <span>
                        {RunnerRequestStatusLabels[item.data.status]}
                        {item.data.status === RunnerRequestStatus.Active && <Icon className="t-red-color" iconName="Important"/>}
                    </span>
                );
            }
        });
        listColumns.push({
            key: "column2",
            name: locStrings.description,
            minWidth: DetailListColumnSizes.large,
            maxWidth: DetailListColumnSizes_Max.large,
            data: "string",
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return <div className="c-description">{item.data.skuItems.map(item => item.skuDescription).join(" | ")}</div>
            }
        });
        listColumns.push({
            key: "column3",
            name: locStrings.numItems,
            minWidth: DetailListColumnSizes.xsmall,
            maxWidth: DetailListColumnSizes_Max.small,
            data: 'number',
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return <span>{item.data.skuItems.reduce((sum, currentItem) => sum + currentItem.quanitity, 0)}</span>
            }
        });
        listColumns.push({
            key: "column4",
            name: locStrings.location,
            minWidth: DetailListColumnSizes.small,
            maxWidth: DetailListColumnSizes_Max.small,
            data: 'string',
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                // Floor levels are one-based in the notification service
                return <span>{RunnerRequestFloorLabels[item.data.floorLevel - 1]}</span>
            }
        });
        listColumns.push({
            key: "column5",
            name: locStrings.locationNote,
            minWidth: DetailListColumnSizes.large,
            maxWidth: DetailListColumnSizes_Max.large,
            data: 'string',
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return <span>{item.data.location}</span>
            }
        });
        listColumns.push({
            key: "column6",
            name: locStrings.requester,
            minWidth: DetailListColumnSizes.small,
            maxWidth: DetailListColumnSizes_Max.small,
            data: 'string',
            isResizable: true,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return <span>{item.data.fohAlias}</span>
            }
        });
        listColumns.push({
            key: "column7",
            name: locStrings.elapsedTime,
            minWidth: DetailListColumnSizes.small,
            data: 'string',
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            flexGrow: 1,
            onRender: (item: IRunnerRequestListItem) => {
                return <span>{_formatElapsedTime(item.elapsedTime)}</span>
            }
        });

        setColumns(listColumns);
        // Sorting by elapsed time by default
        setSortedColumn(listColumns[listColumns.length - 1]);
    }, []);

    useEffect(() => {
        // Sort and filter requests
        if(props.runnerRequests) {
            const sortedListItems = _sortRunnerRequestListItems(props.runnerRequests.map(runnerRequest => {
                return {
                    data: runnerRequest,
                    elapsedTime: runnerRequest.status === RunnerRequestStatus.Active || runnerRequest.status === RunnerRequestStatus.InProgress
                        ? moment().diff(moment(runnerRequest.actionNeededStartTime), "seconds")
                        : moment(runnerRequest.endTime).diff(moment(runnerRequest.actionNeededStartTime), "seconds")
                } as IRunnerRequestListItem
            }), sortedColumn ? sortedColumn.isSortedDescending : false);
            // RunnerRequestListItems keeps track of all runner request items
            setRunnerRequestListItems(sortedListItems);

            const filteredListItems = props.selectedFilterState === RunnerRequestState.All && (!props.searchTerm || props.searchTerm.length === 0)
                ? [...sortedListItems] 
                : sortedListItems.filter(runnerRequestListItem => {
                    let shouldKeep = false;
                    shouldKeep = getRunnerRequestState(runnerRequestListItem.data.status) === props.selectedFilterState;
                    shouldKeep = props.searchTerm && props.searchTerm.length > 0
                        ? findSubstringInRunnerRequest(runnerRequestListItem.data, props.searchTerm)
                        : shouldKeep;
                    return shouldKeep;
                });
            // FilteredListItems contains only the list items that match the user's selected filter state & search term
            setFilteredListItems(filteredListItems);
        }
    }, [props.runnerRequests, props.selectedFilterState, props.searchTerm]);

    useEffect(() => {
        const interval = setInterval(() => {
            setFilteredListItems(prev => {
                return prev.map(listItem => {
                    return { 
                        ...listItem, 
                        elapsedTime: listItem.data.status === RunnerRequestStatus.Active || listItem.data.status === RunnerRequestStatus.InProgress
                            ? moment().diff(moment(listItem.data.actionNeededStartTime), "seconds")
                            : listItem.elapsedTime
                    } 
                })
            })
        }, 1000);
        return () => {
            clearInterval(interval);
        };
        
    }, []);

    useEffect(() => {
        // Show notification modal if:
        // 1. It's not currently being shown
        // 2. A runner request is not currently being viewed
        // 3. The selected view is set to 'Runner'
        // 4. It hasn't already been shown within the determined time (i.e. ActiveRequestTimer)
        // 5. There is an active request with an elapsed time greater than the determined time
        if(!props.displayRunnerRequestNotificationModal
            && !props.displayViewRunnerRequestModal
            && props.selectedView === RunnerRequestView.Runner
            && notificationTimer > ActiveRequestTimer
            && runnerRequestListItems.find(listItem => listItem.data.status === RunnerRequestStatus.Active && listItem.elapsedTime > ActiveRequestTimer)
        )    {
            props.updateModals({ displayRunnerRequestNotificationModal: true });
        }
    }, [notificationTimer]);

    useEffect(() => {
        if(!props.displayRunnerRequestNotificationModal) {
            // When the notification modal is closed, reset the timer so that we don't show it again within the determined time
            setNotificationTimer(0);
        }
        
        const interval = setInterval(() => {
            setNotificationTimer(prev => prev + 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [props.displayRunnerRequestNotificationModal]);

    const _sortRunnerRequestListItems = (listItems: IRunnerRequestListItem[], descending: boolean) => {
        return listItems.slice().sort(({ elapsedTime: a }, {elapsedTime: b }) => {
            return ((descending ? a > b : a < b) ? 1 : -1);
        })
    }

    const _formatElapsedTime = (elapsedTime: number) => {     
        return elapsedTime > 60 ? `${Math.floor(elapsedTime/60)}m ${elapsedTime%60}s` : `${elapsedTime}s`;
    }

    const _onClickColumnHeader = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
        if(!column.isSorted) { return; }

        const newColumns = columns.slice();
        const selectedColumn = newColumns.find(curColumn => column.key === curColumn.key);
        newColumns.forEach(curColumn => {
            if(curColumn === selectedColumn) {
                curColumn.isSortedDescending = !curColumn.isSortedDescending;
            }
        });
        const newItems = _sortRunnerRequestListItems(filteredListItems, selectedColumn.isSortedDescending);
        setColumns(newColumns);
        setSortedColumn(selectedColumn);
        setFilteredListItems(newItems);
    }

    const _onListRowClick = (item: IRunnerRequestListItem) => {
        props.setSelectedRunnerRequest(item.data);
        props.updateModals({displayViewRunnerRequestModal: true});
    }

    const _onRenderRow = (props: IDetailsRowProps) => {
        return (
            <div onClick={() => _onListRowClick(props.item)}>
                <DetailsRow className="c-rr-list-row" {...props} />
            </div>
        );
    }

    return (
        <div className="c-rr-list">
            {(props.runnerRequests && filteredListItems.length > 0) ? (
                <DetailsList
                    items={filteredListItems}
                    columns={columns}
                    checkButtonAriaLabel="checkbox"
                    checkboxVisibility={CheckboxVisibility.hidden}
                    checkboxCellClassName="c-listitem-checkbox"
                    selectionMode={SelectionMode.multiple}
                    isHeaderVisible={true}
                    onRenderRow={_onRenderRow}
                    onColumnHeaderClick={_onClickColumnHeader}
                />
            ) : (
                <div className="c-no-requests t-secondary-color">
                    {localizedStrings.RunnerRequestsView.RunnerRequestList.noRequestsToDisplay}
                </div>
            )}     
        </div>
    );
}

function mapStateToProps(state: IClientelingViewState, providedProps: IRunnerRequestListInitializerProvidedProps): Partial<IRunnerRequestListInitializerOwnProps> {
    return {
        ...providedProps,
        runnerRequests: RunnerRequestsSelectors.getRunnerRequests(state),
        selectedFilterState: RunnerRequestsSelectors.getSelectedFilterState(state),
        displayRunnerRequestNotificationModal: PageSelectors.getModals(state)?.displayRunnerRequestNotificationModal,
        displayViewRunnerRequestModal: PageSelectors.getModals(state)?.displayViewRunnerRequestModal,
        selectedView: RunnerRequestsSelectors.getSelectedView(state),
        searchTerm: RunnerRequestsSelectors.getSearchTerm(state)
    };
}

// Hook up action creators to reducer
const ActionsToDispatch = {
    updateModals: PageActions.updateModals,
    setSelectedRunnerRequest: RunnerRequestsActions.setSelectedRunnerRequest
};

const connector = connect(
    mapStateToProps,
    ActionsToDispatch,
    null,
    { forwardRef: true }
)

export const RunnerRequestList = connector(RunnerRequestListInitializer);