import { IAppointmentModalAwareState, IAppointmentModalResources, IMessages } from "./../Contracts";
import {
    AppointmentsView,
    AppointmentCountDto,
    IAppointmentForCreationDto,
    IAppointmentsView,
    IAppointmentCustomResponseDto
} from "./../../../../contracts/swagger/_generated";

export function getIsLoading(state: IAppointmentModalAwareState): boolean | undefined {
    if (!state || !state.appointmentModalViewState || !state.appointmentModalViewState.ux) {
        return false;
    }

    return state.appointmentModalViewState.ux.isLoading;
}

export function getResources(state: IAppointmentModalAwareState): IAppointmentModalResources | undefined {
    if (!state || !state.appointmentModalViewState || !state.appointmentModalViewState.data) {
        return undefined;
    }

    return state.appointmentModalViewState.data.resources;
}

export function getMessages(state: IAppointmentModalAwareState): IMessages | undefined {
    if(!state || !state.appointmentModalViewState || !state.appointmentModalViewState.ux) {
        return undefined;
    }

    return state.appointmentModalViewState.ux.messages;
}

export function getAppointmentCustomResponses(state: IAppointmentModalAwareState): IAppointmentCustomResponseDto[] | undefined {
    if(!state || !state.appointmentModalViewState || !state.appointmentModalViewState.data) {
        return undefined;
    }

    return state.appointmentModalViewState.data.createAppointmentResponses;
}
